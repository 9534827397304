/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// @mui packages

import { Box, Tooltip } from "@mui/material";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
//custom component & css
import "./PropertyCardstyle.css";
import { settings } from "../../Config/Settings";
// Api
import { getPaymentStatus } from "../../Api/Payment";
import { getVisitPropertyById, visitProperty } from "../../Api/AuthAPI";
// auth
import Auth from "../../Config/helper/Auth/index";
import VisibilityIcon from "@mui/icons-material/Visibility";
import RoomPreferencesOutlinedIcon from '@mui/icons-material/RoomPreferencesOutlined';
import BedOutlinedIcon from '@mui/icons-material/BedOutlined';
import ReviewsIcon from '@mui/icons-material/Reviews';
import SquareFootOutlinedIcon from '@mui/icons-material/SquareFootOutlined';
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';

export default function PropertyCard(props) {
  const { auction = {}, propertyType = "" } = props;
  const userId = Auth?.user?.userData?._id
  const navigate = useNavigate();

  const [getBuyStatus, setGetBuyStatus] = useState(true);
  const [visitCount, setVisitCount] = useState(0);


  const renderStars = () => {
    const rating = auction?.reviews?.Rating || 0;
    const stars = [];

    for (let i = 0; i < rating; i++) {
      stars.push(<span key={i} className="star">
        &#9733;
      </span>);
    }
    return stars;
  };


  // useEffect
  useEffect(() => {
    getBuyStatusCall();
    getVisits();
  }, []);

  // Already book or not
  const getBuyStatusCall = async () => {
    let id = auction?._id;
    getPaymentStatus(id)
      .then((res) => {
        if (res.data.code === 200) {
          if (res.data.isPaid === true) {
            setGetBuyStatus(res.data.isPaid);
          } else {
            setGetBuyStatus(res.data.isPaid);
          }
        } else {
          setGetBuyStatus(res.data.isPaid);
        }
      })
      .catch((e) => {
        setGetBuyStatus(false);
        console.log("====================================");
        console.log(e, "error in get payments");
        console.log("====================================");
      });
  };

  // getVisitCount
  const getVisits = () => {
    getVisitPropertyById(auction._id)
      .then((res) => {
        if (res.data.status === 1) {
          setVisitCount(res?.data?.data?.uniqueVisits);
        } else {
          setVisitCount(0);
        }
      })
      .catch((err) => {
        console.log(err, "error in get Visit");
      });
  };

  // visit property post call
  const clickVisit = (proId) => {
    let propertyId = proId;
    visitProperty(propertyId, userId)
      .then((res) => {
        if (res.data.status === 1) {
          getVisits();
          console.log(res?.data?.message, "visited successfully");
        } else {
          console.log("not visited");
        }
      })
      .catch((err) => {
        console.log(err, "error in clickVisit function");
      });
  };

  // viewmore handlechange
  const viewMore = (data) => {
    clickVisit(data._id);
    navigate(`/fulltab/${propertyType}/${data._id}`, {
      state: { yourData: data, propertyType: propertyType },
    });
  };

  // number seprated with comma's
  const NumberSeparator = (input) => {
    return new Intl.NumberFormat("en-IN", {
      minimumFractionDigits: 2,
    }).format(input);
  };

  const formatCash = (n) => {
    if (n < 1e3) return n.toString();
    if (n >= 1e3 && n < 1e5) return +(n / 1e3).toFixed(2) + "K";
    if (n >= 1e5 && n < 1e7) return +(n / 1e5).toFixed(2) + "L";
    if (n >= 1e7 && n < 1e9) return +(n / 1e7).toFixed(2) + "cr";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(2) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(2) + "T";
  };


  return (
    <>


      {propertyType === "Construction" ?
        <>
          {/* ------------------------------- For New Project (start here) --------------------------------------   */}
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            key={`mainGrid-${auction?._id}`}
            className="m-3"
          >
            <div className="property-card-box">
              <Box className="ribbon-box" elevation={1} square={false}>
                {getBuyStatus ? (
                  <div className="ribbon-2">
                    <span>Already Booked</span>
                  </div>
                ) : (
                  <div></div>
                )}

                <Box className="content" key={`titleCardHeader-${auction?._id}`} />
                <Grid className="property-wrapper-home">
                  <div className="overflow-hidden">
                    {/* <img
                      src={
                        propertyType === "Commercial" || propertyType === "Residential"
                          ? `${auction?.exteriorView[0]?.name}`
                          : propertyType === "Construction"
                            ? `${auction?.photos?.projectImages[0]?.name}`
                            : `${settings.IMAGE_URL}/${auction?.propertyImg2}`
                      }
                      className="img-fluid"
                      alt="team member"
                    /> */}
                    <img
                      src={
                        (propertyType === "Commercial" || propertyType === "Residential") && auction?.exteriorView?.[0]?.name
                          ? auction.exteriorView[0].name
                          : propertyType === "Construction" && auction?.photos?.projectImages?.[0]?.name
                            ? auction.photos.projectImages[0].name
                            : auction?.propertyImg2
                              ? `${settings.IMAGE_URL}/${auction.propertyImg2}`
                              : "https://api.equireal.co.in/defaultProperty.jpg"
                      }
                      className="img-fluid"
                      alt="Property"
                    />

                  </div>

                  <div className="property-info">
                    <div className="d-flex justify-content-between">
                      <div>
                        <h5 className="new-project-hd">{auction?.nameOfProject}  </h5>

                        <h6 className="new-project-loc">
                          {auction?.subLocation}, {auction?.Location}
                        </h6>
                        </div>
                      <Tooltip
                        title="People viewing this property"
                        slotProps={{
                          popper: {
                            modifiers: [
                              {
                                name: "offset",
                                options: {
                                  offset: [0, -14],
                                },
                              },
                            ],
                          },
                        }}
                      >
                        <p>
                          <VisibilityIcon /> {visitCount}
                        </p>
                      </Tooltip>

                    </div>
                    <h4 className="property-price">
                      {propertyType === "Commercial" || propertyType === "Residential"
                        ? auction?.purposeOfProperty === "Buy" ? `₹${formatCash(Number(auction?.bookingAmount))}/-` : `₹${formatCash(Number(auction?.PropertyRent))}/-`
                        : propertyType === "Construction"
                          ? `₹${formatCash(Number(auction?.minPrice))}-₹${formatCash(Number(auction?.maxPrice))}`
                          : `₹${formatCash(Number(auction?.Price))}/-`}
                    </h4>


                    <p className="property-desc">
                      {auction?.Amenities.slice(0, 5).join(", ")}</p>
                  </div>
                </Grid>

                <div className="property-footer d-flex justify-content-center align-items-center">
                  <div>
                    <span className="property-starts">
                      <Button
                        size="sm"
                        variant="secondary"
                        onClick={() => viewMore(auction)}
                        className="property-more"
                      >
                        View Project
                      </Button>
                    </span>
                  </div>
                </div>

              </Box>
            </div>
          </Grid>
        </>
        :


        //------------------------------- For Rent and Commercial Property (start here) --------------------------------------
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          key={`mainGrid-${auction?._id}`}
          className="m-3"
        >
          <div className="property-card-box">
            <Box className="ribbon-box" elevation={1} square={false}>
              {getBuyStatus ? (
                <div className="ribbon-2">
                  <span>Already Booked</span>
                </div>
              ) : (
                <div></div>
              )}

              <Box className="content" key={`titleCardHeader-${auction?._id}`} />
              <Grid className="property-wrapper-home">
                <div className="overflow-hidden">
                  <img
                    src={
                      propertyType === "Commercial" || propertyType === "Residential"
                        ? `${auction?.exteriorView[0]?.name}`
                        : propertyType === "Construction"
                          ? `${auction?.photos?.projectImages[0]?.name}`
                          : `${settings.IMAGE_URL}/${auction?.propertyImg2}`
                    }
                    className="img-fluid"
                    alt="team member"
                  />
                </div>
                {/* {propertyType === "Construction" ?
                <>
                  <h3 className="text-center mt-3">{auction?.nameOfProject}</h3>
                  
                  </>
                  : null} */}

                <div className="property-info">
                  <div className="d-flex justify-content-between p-tle">
                    <div> {auction?.reviews?.Rating ? (
                      <>
                        {/* <ReviewsIcon className="property-icon" /> */}
                        <span className="rating">{renderStars()}</span>
                      </>
                    ) : null}</div>
                    <Tooltip
                      title="People viewing this property"
                      slotProps={{
                        popper: {
                          modifiers: [
                            {
                              name: "offset",
                              options: {
                                offset: [0, -14],
                              },
                            },
                          ],
                        },
                      }}
                    >

                      <VisibilityIcon /> {visitCount}

                    </Tooltip>

                  </div>
                  <h4 className="property-name"> {auction?.selectNosofBedrooms} BHK {auction?.propertyType}
                    at {auction?.subLocation}, {auction?.Location}</h4>
                  <h4 className="property-price">
                    {propertyType === "Commercial" || propertyType === "Residential"
                      ? auction?.purposeOfProperty === "Buy" ? `₹${formatCash(Number(auction?.bookingAmount))}/-` : `₹${formatCash(Number(auction?.PropertyRent))}/-`
                      : propertyType === "Construction"
                        ? `₹${formatCash(Number(auction?.minPrice))}-₹${formatCash(Number(auction?.maxPrice))}`
                        : `₹${formatCash(Number(auction?.Price))}/-`}
                  </h4>
                  {/* <h4 className="property-name mb-0 pt-3">
                    <BedOutlinedIcon className="property-icon" />  Flat at {auction?.subLocation}, {auction?.Location}
                  </h4> */}
                  <div className="d-flex gap-5  prop-desc">
                    {propertyType === "Residential" ?
                      <>
                        <p  >
                          <SquareFootOutlinedIcon className="property-icon" />
                          <span> {auction?.areainSqft}Sq.Ft</span>
                        </p>
                        <p >
                          <RoomPreferencesIcon className="property-icon" />
                          <span> {auction?.selectNosofBedrooms} BHK</span>
                        </p>
                      </> :
                      <p>
                        <SquareFootOutlinedIcon className="property-icon" />
                        <span> {auction?.carpetArea} Sq.Ft</span>
                      </p>
                    }
                  </div>

                  <p className="property-desc h-4">{auction?.Amenities.slice(0, 5).join(", ")}</p>



                  {/* <p className="property-desc">
                    {auction?.reviews?.Rating ? (
                      <>
                        <ReviewsIcon className="property-icon" />
                        <span className="rating ps-2">{renderStars()}</span>
                      </>
                    ) : null}
                  </p> */}
                </div>
              </Grid>

              <div className="property-footer d-flex justify-content-center align-items-center">
                <div>
                  <span className="property-starts">
                    <Button
                      size="sm"
                      variant="secondary"
                      onClick={() => viewMore(auction)}
                      className="property-more"
                    >
                      View More
                    </Button>
                  </span>
                </div>
              </div>

            </Box>
          </div>
        </Grid>

      }
    </>
  );
}
