import React from "react";
import html2canvas from "html2pdf.js";
import jsPDF from "jspdf";
import Modal from "react-bootstrap/Modal";
import { Button } from "@mui/material";
const ReceiptModal = ({ paymentData, showModal, onClose }) => {
  const ReceiptPdf = () => {
    const page = document.getElementById("divToPrint");
    html2canvas(page).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = pdfWidth * 0.8; // Adjust as needed
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const x = (pdfWidth - imgWidth) / 2;
      const y = (pdfHeight - imgHeight) / 2;

      pdf.addImage(imgData, "JPEG", x, y, imgWidth, imgHeight);

      window.open(pdf.output("bloburl"), "_blank");
    });
    onClose();
  };
  return (
    <div>
      <Modal show={showModal} onHide={onClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title> Receipt </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            id="divToPrint"
            className="d-flex align-items-center justify-content-center"
          >
            <div
              style={{
                width: "100%",
                // height: "100vh",
                margin: "0 auto",
                padding: "20px",
                border: "1px solid #ccc",
                borderRadius: "8px",
                fontFamily: "Arial, sans-serif",
                background: "#fff",
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "2px solid #3498db",
                  paddingBottom: "10px",
                }}
              >
                <img
                  src="assets/images/payment_done.jpg"
                  alt="Company Logo"
                  style={{
                    height: "100px",
                    width: "100px",
                    borderRadius: "50%",
                  }}
                />
                <div>
                  <p style={{ fontSize: "18px", fontWeight: "bold" }}>
                    Softlabs Group
                  </p>
                  <p>Malvan</p>
                  <p>Sindhudurg</p>
                  <p>Malvan, Maharashtra, 416606</p>
                  <p>9652436352</p>
                  <p>abc@softlabsgroup.com</p>
                </div>
              </div>

              <div
                style={{
                  marginBottom: "20px",
                  background: "#f9f9f9",
                  padding: "20px",
                  borderRadius: "8px",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                }}
              >
                <h2
                  style={{
                    color: "#3498db",
                    fontSize: "28px",
                    marginBottom: "20px",
                    textAlign: "center",
                  }}
                >
                  🛒 RECEIPT
                </h2>
                <div className="receipt-info" style={{ marginBottom: "20px" }}>
                  <p>
                    <strong>Amount:</strong> {paymentData.amount}
                  </p>
                  <p>
                    <strong>OrderId:</strong> {paymentData.orderId}
                  </p>
                  <p>
                    <strong>PaymentId:</strong> {paymentData.paymentID}
                  </p>
                </div>

                {/* <div className="items-list">
                  <table style={{ width: "100%", borderCollapse: "collapse" }}>
                    <thead>
                      <tr>
                        <th>Description</th>
                        <th>Quantity</th>
                        <th>Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {receiptData.items.map((item, index) => (
                      <tr key={"index"}>
                        <td>{"item.description"}</td>
                        <td>{"item.quantity"}</td>
                        <td>${"item.price.toFixed(2)"}</td>
                      </tr>
                       ))}
                    </tbody>
                  </table>
                </div> */}

                {/* <div className="totals" style={{ marginTop: "20px" }}>
                  <p>
                    <strong>Subtotal:</strong> $
                    {"receiptData.subtotal.toFixed(2)"}
                  </p>
                  <p>
                    <strong>Tax ({"receiptData.taxRate"}%):</strong> $
                    {"receiptData.tax.toFixed(2)"}
                  </p>
                  <p>
                    <strong>Total:</strong> ${"receiptData.total.toFixed(2)"}
                  </p>
                </div> */}
              </div>

              <div className="payment-info" style={{ marginTop: "20px" }}>
                <h3
                  style={{
                    color: "#3498db",
                    fontSize: "22px",
                    marginBottom: "10px",
                  }}
                >
                  Payment Information
                </h3>
                <p>
                  <strong>Payment Method:</strong> {"Card"}
                </p>
                <p>
                  <strong>Card Number:</strong> {"4111 1111 1111 1111"}
                </p>
                <p>
                  <strong>Expiry Date:</strong> {"08/2024"}
                </p>
                {/* <p>
                  <strong>Authorization:</strong> {"receiptData.authorization"}
                </p> */}
              </div>

              <div className="thank-you" style={{ marginTop: "20px" }}>
                <h3
                  style={{
                    color: "#27ae60",
                    fontSize: "22px",
                    marginBottom: "10px",
                  }}
                >
                  Thank You for Your Business!
                </h3>
                {/* <p>{"receiptData.additionalNotes"}</p> */}
                <p>Your Website: {"www.softlabsgroup.in"}</p>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => ReceiptPdf()}>
            DownLoad
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ReceiptModal;
