/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// @mui packages
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Grid from "@mui/material/Grid";
import ReactPaginate from "react-paginate";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import "../../Admin/pagination.css";
//icons
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
//Api
import { getAllProjectDetails } from "../../../Api/BuilderProjectAPI";
import { getVisitPropertyById } from "../../../Api/AuthAPI";
// custom components
import PropertyCard from "../../../Components/Common/PropertyCard";
import PropertyService from "../../../Components/Common/PropertyService";

function NewProject({
  location,
  propertyType,
  minPrice,
  maxPrice,
}) {
  const navigate = useNavigate();
  const [projectData, setProjectData] = useState([]);

  const [pageNumber, setPageNumber] = useState(0);
  const itemsPerPage = 3;
  const pageCount = Math.ceil(projectData.length / itemsPerPage);
  const offset = pageNumber * itemsPerPage;
  const currentPageData = projectData.slice(offset, offset + itemsPerPage);
  //css
  const noDataMessageStyle = {
    textAlign: "center",
    padding: "20px",
    backgroundColor: "#f8d7da",
    color: "#721c24",
    border: "1px solid #f5c6cb",
    borderRadius: "5px",
    margin: "10px",
  };
  
   // slider setting code
   var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: projectData.length >= 3 ? 3 : projectData.length,
    slidesToScroll: 3,
    autoplay: true,
    pauseOnHover: true,
    autoplaySpeed: 4000,
    cssEase: "linear",
    arrows: projectData.length >= 3 ? true : false,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  //useEffects
  useEffect(() => {
    getAllProjectDetailsData();
  }, []);

  const getAllProjectDetailsData = () => {
    getAllProjectDetails()
      .then((res) => {
        if (res.data.status === 1) {
          const details = res.data.data.filter((verifiedData)=> verifiedData.isVerified === "Verified")
          setProjectData(details);
        } else {
          setProjectData([])
        }
      })
      .catch((err) => {
        console.log(err, "error in newproject get Data");
        setProjectData([])
      });
  };

  // allproperties navigate this data..
  const AllProperties = (data) => {
    navigate("/filterProperties", {
      state: { properties: data, propertyType: "Construction" },
    });
  };

  const handlePageChange = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <>
      <div  className='flex-grow-1'>
        <div id="feat-properties">
          <div className="container">
            <div
              className="content-box wow animated fadeIn"
              data-wow-duration=".5s"
              data-wow-delay=".2s"
            >
              <div className="content-title ">
                <div className="content-name">
                  <h3>Project</h3>

                  {/* {projectData?.length >= 3 && (
                    <div
                      className=""
                      style={{ cursor: "pointer" }}
                      onClick={() => AllProperties(projectData)}
                    >
                      All Properties
                      <ArrowForwardIcon />
                    </div>
                  )} */}
                </div>
                <hr className="content-title-underline" />
              </div>

              <div className="container">
                {projectData.length === 0 ? (
                  <p className="errormsg">No data found</p>
                ) : (
                  <>
                  <Slider {...settings}>
                  {/* <Grid container spacing={1}>
                    <Grid container item spacing={3}> */}
                      {projectData.map((list) => (
                        <PropertyCard
                          auction={list}
                          propertyType="Construction"
                        />
                      ))}
                      </Slider>
                    {/* </Grid>
                  </Grid> */}
                   {/* <div className="container">
                   <ReactPaginate
                     previousLabel={<NavigateBeforeIcon />}
                     nextLabel={<NavigateNextIcon />}
                     breakLabel={"..."}
                     pageCount={pageCount}
                     onPageChange={handlePageChange}
                     containerClassName={"pagination"}
                     activeClassName={"activepagination"}
                     pageClassName={"pagination-item"}
                   />
                  </div> */}
                  </>
                )}
              </div>

              {/* <div>
                <PropertyService />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewProject;
