import axios from "axios";
import { settings } from "../Config/Settings";

const RegisterUser = (data) => {
  return axios({
    method: "POST",
    url: `${settings.API_URL}/register/registerUser`,
    data: data,
  });
};

const VerifyEmail = (token) => {
  return axios({
    method: "GET",
    url: `${settings.API_URL}/register/verifyuser/${token}`,
  });
};

const LoginUser = (data) => {
  return axios({
    method: "POST",
    url: `${settings.API_URL}/register/Login`,
    data: data,
  });
};

const getProfileById = (id) => {
  return axios({
    method: "GET",
    url: `${settings.API_URL}/register/getProfile/${id}`,
  });
};

const updateProfile = (data, id) => {
  return axios({
    method: "PUT",
    url: `${settings.API_URL}/register/updateProfile/${id}`,
    data: data,
  });
};

const getyourProperties = (id) =>{
  return axios({
    method: "GET",
    url: `${settings.API_URL}/paymentRoute/buyPropertiesByUser/${id}`
  })
}

// admin
const allUserRoleList = (role) => {
  return axios({
    method: "GET",
    url: `${settings.API_URL}/register/agentList/${role}`,
  });
};

const updateAgentStatus = (id, data) => {
  return axios({
    method: "PATCH",
    url: `${settings.API_URL}/register/updateAgentStatus/${id}`,
    data,
  });
};

const agentCounts = () => {
  return axios({
    method: "GET",
    url: `${settings.API_URL}/admin/agentCount`,
  });
};

const propertiesCount = () =>{
  return axios({
    method: "GET",
    url: `${settings.API_URL}/admin/getPropertyCount`,
  })
}

const paymentCount = () =>{
  return axios({
    method: "GET",
    url: `${settings.API_URL}/admin/getPaymentCount`,
  })
}

const receiptCount = () =>{
  return axios({
    method: "GET",
    url: `${settings.API_URL}/admin/reciptCount`,
  })
}

const sendEnquiryData = (data) => {
  return axios({
    method: "POST",
    url: `${settings.API_URL}/user/sendDetails`,
    data: data,
  });
};

const getEnquiryData = () => {
  return axios({
    method: "GET",
    url: `${settings.API_URL}/user/getDetails`,
  });
};

const ApprovedRejectSendMail = (id, data)=>{
  return axios({
    method: "POST",
    url:`${settings.API_URL}/admin/sendingEnquiry/${id}`,
    data: data,
  })
}

const forgetPassword = (data) => {
  return axios({
    method: "POST",
    url: `${settings.API_URL}/register/forgot-password`,
    data: data,
  });
};

const resetPassword = (data) => {
  return axios({
    method: "POST",
    url: `${settings.API_URL}/register/reset-password`,
    data: data,
  });
};

const AddLocation = (data) =>{
  return axios({
    method:"POST",
    url: `${settings.API_URL}/register/addLocation`,
    data:data,
  })
}

const getLocationData = () =>{
  return axios({
    method: "GET",
    url: `${settings.API_URL}/register/getLocations`,
  });
}

//new location master
const NewAddLocation = (data)=>{
  return axios({
    method:"POST",
    url:`${settings.API_URL}/register/addAreaLocation`,
    data:data,
  })
}

const NewGetAreaLocations = ()=>{
  return axios({
    method:"GET",
    url:`${settings.API_URL}/register/getAreaLocations`,
  })
}

const NewUpdateAreaLocation = (data) =>{
  return axios({
    method:"PUT",
    url:`${settings.API_URL}/register/updateAreaLocation`,
    data: data,
  })
}

const getLocationById = (id)=>{
  return axios({
    method:"GET",
    url:`${settings.API_URL}/register/getlocation/${id}`,
  })
}

const deleteLocation = (id)=>{
  return axios({
    method: "DELETE",
    url: `${settings.API_URL}/register/deletelocation/${id}`,
  });
}

const updateLocation = (data) => {
  return axios({
    method: "PUT",
    url: `${settings.API_URL}/register/updateLOcation`,
    data: data,
  });
};

const getSubLocationById = (id)=>{
  return axios({
    method:"GET",
    url:`${settings.API_URL}/register/getSubLocation/${id}`,
  })
}

const AddAminities = (data) =>{
  return axios({
    method:"POST",
    url: `${settings.API_URL}/register/addAmenities`,
    data:data,
  })
}

const getAminitiesData = () =>{
  return axios({
    method: "GET",
    url: `${settings.API_URL}/register/getAllAmenities`,
  });
}

const updateAminities = (data, id) =>{
  return axios({
    method: "PUT",
    url: `${settings.API_URL}/register/updateAmenitiesById/${id}`,
    data:data,
  })
}

const updateAmenitiesStatusById = (data,id) =>{
  return axios({
    method:"PUT",
    url:`${settings.API_URL}/register/updateAmenitiesStatusById/${id}`,
    data:data,
  })
}

const AmenitiesList = (propertyType) => {
  return axios({
    method: "GET",
    url: `${settings.API_URL}/register/filterAmenities/${propertyType}`,
  });
};

const visitProperty = (propertyId,userId) => {
  return axios({
    method:"POST",
    url: `${settings.API_URL}/admin/addVisit/${propertyId}/${userId}`,

  })
}

const getVisitPropertyById = (propertyId) => {
  return axios({
    method:"GET",
    url: `${settings.API_URL}/admin/getPropertyVisits/${propertyId}`,
  })
}
const AddPropertyType = (data) =>{
  return axios({
    method:"POST",
    url: `${settings.API_URL}/admin/addAreaType`,
    data:data,
  })
}

const getPropertytypeData = () =>{
  return axios({
    method: "GET",
    url: `${settings.API_URL}/admin/getAreaType`,
  });
}

const DeletePropertyType = (id)=>{
  return axios({
    method: "DELETE",
    url: `${settings.API_URL}/admin/deleteAreaTypes/${id}`,
  });
}

const updatePropertyType = (data, id) =>{
  return axios({
    method: "PUT",
    url: `${settings.API_URL}/admin/updateAreaTypeById/${id}`,
    data:data,
  })
}
const getPropertytypeByTypeData = (type) =>{
  return axios({
    method: "GET",
    url: `${settings.API_URL}/admin/getAreaTypeByType/${type}`,
  });
}

export {
  RegisterUser,
  VerifyEmail,
  LoginUser,
  getProfileById,
  updateProfile,
  getyourProperties,
  allUserRoleList,
  updateAgentStatus,
  agentCounts,
  forgetPassword,
  resetPassword,
  sendEnquiryData,
  getEnquiryData,
  AddLocation,
  NewAddLocation,
  NewGetAreaLocations,
  getLocationData,
  getLocationById,
  updateLocation,
  NewUpdateAreaLocation,
  deleteLocation,
  getSubLocationById,
  AddAminities,
  getAminitiesData,
  updateAminities,
  updateAmenitiesStatusById,
  AmenitiesList,
  propertiesCount,
  paymentCount,
  receiptCount,
  visitProperty,
  getVisitPropertyById,
  ApprovedRejectSendMail,
  AddPropertyType,
  getPropertytypeData,
  DeletePropertyType,
  updatePropertyType,
  getPropertytypeByTypeData
};
