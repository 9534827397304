import axios from "axios";
import { settings } from "../Config/Settings";

const addReview = (data) => {
  return axios({
    method: "POST",
    url: `${settings.API_URL}/review/addReview`,
    data: data,
  });
};

const getAllReview = (propertyId) => {
  return axios({
    method: "GET",
    url: `${settings.API_URL}/review/getReviewByPropertyId/${propertyId}`,
  });
};

const getAllReviewbyUserId = (userId,type) => {
  return axios({
    method: "GET",
    url: `${settings.API_URL}/review/getReviewByUserId/${userId}/${type}`,
  });
};

const getAllReviewList = () =>{
  return axios({
    method:"GET",
    url: `${settings.API_URL}/review/allBlockReviewList`,
  })
}

// block review
const addBlockReview = (data) => {
  return axios({
    method: "POST",
    url: `${settings.API_URL}/review/addBlockReview`,
    data: data,
  });
};

const blockReviewList = () => {
  return axios({
    method: "GET",
    url: `${settings.API_URL}/review/allBlockReviewList`,
  });
};

const updateReviewStatus = (data) => {
  return axios({
    method: "PUT",
    url: `${settings.API_URL}/review/updateReviewBlockstatus`,
    data,
  });
};

export {
  addReview,
  getAllReview,
  getAllReviewbyUserId,
  addBlockReview,
  blockReviewList,
  updateReviewStatus,
  getAllReviewList,
};
