import React, { useEffect, useState } from 'react';
//custom components
import AdminSidebar from "../../Components/Sidebar/AdminSidebar";
import { Container } from "react-bootstrap";
//icons
import SquareFootOutlinedIcon from "@mui/icons-material/SquareFootOutlined";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import ChairOutlinedIcon from "@mui/icons-material/ChairOutlined";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import CorporateFareOutlinedIcon from "@mui/icons-material/CorporateFareOutlined";
import EmojiTransportationOutlinedIcon from "@mui/icons-material/EmojiTransportationOutlined";
import { MdOutlineCabin } from "react-icons/md";
import BedOutlinedIcon from "@mui/icons-material/BedOutlined";
import { MdMiscellaneousServices } from "react-icons/md";
import { MdOutlineAdUnits } from "react-icons/md";
import { getAllProjectDetails, updateStatusOfProject, updateStatusOfProperty } from "../../Api/BuilderProjectAPI";
import Swal from "sweetalert2";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import NewProjectSearchProperty from './NewProjectSearchProperty';

const PendingProjects = () => {
    const navigate = useNavigate();
    const [projectDetails, setProjectDetails] = useState([])
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        GetAllProject();
    }, []);

    const GetAllProject = () => {
        setLoading(true)
        // getAllProjectDetails()
        getAllProjectDetails()
            .then((res) => {
                setLoading(false);
                if (res.data.status === 1) {
                    let details = res.data.data;
                    let verifiedDetails = details.filter((details) => details.isVerified === "pending")
                    setProjectDetails(verifiedDetails);
                } else {
                    Swal.fire({
                        icon: "warning",
                        title: "Data not found",
                    });
                }
            })
            .catch((err) => {
                setLoading(false);
                console.log(err, "error in get all project builder details.");
            });
    };

    const formatCash = (n) => {
        if (n < 1e3) return n;
        if (n >= 1e3 && n < 1e5) return +(n / 1e3).toFixed(1) + "K";
        if (n >= 1e5 && n < 1e7) return +(n / 1e5).toFixed(1) + "L";
        if (n >= 1e7 && n < 1e9) return +(n / 1e7).toFixed(1) + "cr";
        if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
        if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
    };

    const verificationStatus = [
        // { value: "pending", label: "Pending" },
        { value: "Verified", label: "Verified", spanclass: "verifiedspanlook" },
        { value: "Rejected", label: "Rejected", spanclass: 'rejectedspanlook' },
    ];

    const updateStatus = async (projectId, status, i) => {
        let updateData = {
            isVerified: status,
        };
        setLoading(true);
        await updateStatusOfProject(projectId, updateData)
            .then((res) => {

                if (res.data.status === 1) {
                    let newData = [...projectDetails];
                    newData[i]["isVerified"] = status;
                    setProjectDetails(newData);
                    setLoading(false);
                    GetAllProject();
                }
            })
            .catch((e) => {
                setLoading(false);
                console.log("====================================");
                console.log(e, "error in updating verification status");
                console.log("====================================");
            });

    };

    return (
        <>
            <div>
                <AdminSidebar />
            </div>
            <div className="topNav-m ">
                <Container>
                    <NewProjectSearchProperty isVerified="pending" />
                    <div className=" content-title">
                        <h3>Pending Projects</h3>
                        <hr className="content-title-underline" />
                    </div>
                </Container>
                <Container>
                    <div className="a-card-content">
                        {projectDetails.map((listData, i) => (
                            <div>
                                <div className="a-card">
                                    <div className="a-img">
                                        {listData?.photos?.projectImages[0]?.name ?

                                            <img
                                                src={`${listData?.photos?.projectImages[0]?.name}`}
                                                alt="Image not available"
                                                className="property-image"
                                            />
                                            : <img
                                                src="https://api.equireal.co.in/defaultProperty.jpg"
                                                alt="Property"
                                                className="property-image"
                                            />
                                        }
                                    </div>
                                    <div className="p-info">
                                        <div className="p-tle">
                                            <div>
                                                <h4>{listData.nameOfProject}</h4>
                                            </div>
                                        </div>
                                        <div className="a-info">
                                            <ul>
                                                <li>
                                                    <PeopleOutlineOutlinedIcon />
                                                    <h6>{listData?.propertyOwner}</h6>
                                                </li>
                                                <li>
                                                    <BedOutlinedIcon />
                                                    <h6> {listData?.areaType[0]}</h6>
                                                </li>

                                                <li>
                                                    <MdOutlineAdUnits />
                                                    <h6>
                                                        <h6> {listData?.totalUnits} units</h6>
                                                    </h6>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>
                                    <div className="p-pri2">
                                        <div>
                                            <h4>₹{formatCash(listData?.minPrice)}/- - ₹{formatCash(listData?.maxPrice)}/-</h4>
                                            <p>Project Price</p>
                                        </div>

                                        <div>
                                            <h4>{moment(listData?.launchDate).format("DD/MM/YYYY")}</h4>
                                            <p>
                                                <p href="">Launch Date</p>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="text-center ">
                                        {verificationStatus.map((option, index) => (
                                            <div key={index} className="cat1 search-sel1">
                                                <label>
                                                    <input
                                                        type="checkbox"
                                                        checked={
                                                            option.value === listData.isVerified
                                                        }
                                                        onChange={(e) =>
                                                            updateStatus(
                                                                listData._id,
                                                                option.value,
                                                                i
                                                            )
                                                        }
                                                    />
                                                    <span className={option.spanclass}>{option.value}</span>
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="text-center">
                                        <a
                                            className="view-more-link"
                                            onClick={() =>
                                                navigate(
                                                    `/fulltab/${"Construction"}/${listData?._id}`,
                                                    {
                                                        state: {
                                                            yourData: listData,
                                                            propertyType: "Construction",
                                                            propertyProjectType : "PendingProject",
                                                        },
                                                    }
                                                )
                                            }
                                        >
                                            View More
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                </Container>
            </div>
        </>
    )
}
export default PendingProjects;