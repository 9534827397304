// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.square {
  width:120px ; /* Adjust width as needed */
  height: 120px; /* Adjust height as needed */
border: 1px solid #ccc;
}
.cardprofile {
  width: 350px;
  padding: 10px;
  border-radius: 20px;
  background: #fff;
  border: none;
  height: 350px;
  position: relative;
}



.mobile-text {
  color: #989696b8;
  font-size: 15px;
}

.form-control {
  margin-right: 12px;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #ff8880;
  outline: 0;
  box-shadow: none;
}

.cursor {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/Pages/ProfileMenu/profile.css"],"names":[],"mappings":"AAAA;EACE,YAAY,EAAE,2BAA2B;EACzC,aAAa,EAAE,4BAA4B;AAC7C,sBAAsB;AACtB;AACA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,YAAY;EACZ,aAAa;EACb,kBAAkB;AACpB;;;;AAIA;EACE,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,sBAAsB;EACtB,qBAAqB;EACrB,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".square {\n  width:120px ; /* Adjust width as needed */\n  height: 120px; /* Adjust height as needed */\nborder: 1px solid #ccc;\n}\n.cardprofile {\n  width: 350px;\n  padding: 10px;\n  border-radius: 20px;\n  background: #fff;\n  border: none;\n  height: 350px;\n  position: relative;\n}\n\n\n\n.mobile-text {\n  color: #989696b8;\n  font-size: 15px;\n}\n\n.form-control {\n  margin-right: 12px;\n}\n\n.form-control:focus {\n  color: #495057;\n  background-color: #fff;\n  border-color: #ff8880;\n  outline: 0;\n  box-shadow: none;\n}\n\n.cursor {\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
