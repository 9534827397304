/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
// import image from '../../Assets/property-not-found.png'
import TopNavigation from "../../Components/Navigation/TopNavigation";
import "./ViewMore.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Auth from "../../Config/helper/Auth";
import Swal from "sweetalert2";
import { deleteproject } from "../../Api/ProjectAPI";
import {
  RentPropertyDetails,
  deleteRentProperty,
} from "../../Api/RentpropertyAPI";
import {
  OrderPayment,
  VerifyPayment,
  getPaymentStatus,
} from "../../Api/Payment";
import PhoneIcon from '@mui/icons-material/Phone';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { RiSecurePaymentLine } from "react-icons/ri";
import { FaMapLocationDot } from "react-icons/fa6";
import { MdConfirmationNumber } from "react-icons/md";
import SquareFootOutlinedIcon from '@mui/icons-material/SquareFootOutlined';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import { MdEmail } from "react-icons/md";
import { BsFillPersonLinesFill } from "react-icons/bs";
import { MdContactPhone } from "react-icons/md";
import { FaAddressCard } from "react-icons/fa6";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import InfoTwoToneIcon from "@mui/icons-material/InfoTwoTone";
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import HolidayVillageOutlinedIcon from '@mui/icons-material/HolidayVillageOutlined';
import Tooltip from "@mui/material/Tooltip";
import { settings } from "../../Config/Settings";
import CustomerReviewSection from "./CustomerReviewSection";
import PostComments from "./PostComments";
import { Container, Row, Col } from "react-bootstrap";
import { Button, FormControl, IconButton } from "@mui/material";
import "react-slideshow-image/dist/styles.css";
import { emphasize, styled } from "@mui/material/styles";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Chip from "@mui/material/Chip";
import HomeIcon from "@mui/icons-material/Home";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ImageGallery from "react-image-gallery";
import Description from "../description.js";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { pink } from "@material-ui/core/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import BitcoinImg from "../../Assets/bitcoin.svg";
import FiatcurrencyImg from "../../Assets/fiatcurrency.svg";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { CommercialPropertyDetails } from "../../Api/Commercial.js";
import {
  getBuilderProjectByPropertyId,
  getDocumentType,
  getCertificatebyDocumenttypeID,
} from "../../Api/BuilderProjectAPI.js";
import { sendEnquiryData } from "../../Api/AuthAPI.js";
import { Checkbox, TextField } from "@material-ui/core";
import axios from "axios";
import moment from "moment";
import { addProprtyToWish, getwishlist } from "../../Api/WishlistAPI.js";
import { IoMdArrowRoundBack } from "react-icons/io";
import { FaRegFilePdf } from "react-icons/fa";
import Accordion from 'react-bootstrap/Accordion';

import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import LoginImg from "../../Assets/lock.svg";
import EmailImg from "../../Assets/email.svg"

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { fade } from "material-ui/utils/colorManipulator";


import theatreImg from "../../Assets/amenities/amphitheatre.png";
import balconyImg from "../../Assets/amenities/balcony.png";
 import buddhaImg from "../../Assets/amenities/buddha.png";
 import barcounterImg from "../../Assets/amenities/toast.png";
 import playgroundImg from "../../Assets/amenities/park.png";
import liftImg from "../../Assets/amenities/elevator.png";
// import cheerImg from "../../Assets/amenities/toast.png";
import waterImg from "../../Assets/amenities/water-cycle.png";
import gymImg from "../../Assets/amenities/weight-lifting.png";
import poolImg from "../../Assets/amenities/pool.png";
import spaImg from "../../Assets/amenities/spa.png";
import wifiImg from "../../Assets/amenities/wi-fi.png";
import acImg from "../../Assets/amenities/air.png";
import PowerImg from "../../Assets/amenities/supply.png"
import yogaImg from "../../Assets/amenities/meditation.png";
import parkingImg from "../../Assets/amenities/parking-area.png";
import clubImg from "../../Assets/amenities/club.png";
import BilliardImg from "../../Assets/amenities/billiard-ball.png"
import vaastuImg from "../../Assets/amenities/compass.png"
import loungeImg from "../../Assets/amenities/lounge.png"
import metroImg from "../../Assets/amenities/railroad.png"
import swimmingImg from "../../Assets/amenities/pool1.png";
import gardenImg from "../../Assets/amenities/gardening.png";
import OpencinemaImg from "../../Assets/amenities/cinema.png";

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

// currency dropdown list
const currencies = [
  {
    value: "USA",
    label: "USA +1",
  },
  {
    value: "IND",
    label: "IND +91",
  },
  {
    value: "ARE",
    label: "ARE +971",
  },
  {
    value: "GBR",
    label: "GBR +44",
  },
];

export default function ViewMore() {
  const navigate = useNavigate();
  // let userId = Auth?.getUserDetails()?._id;
  let userData = {
    Name: Auth?.getUserDetails()?.fullName,
    email: Auth?.getUserDetails()?.email,
    Phone: Auth?.getUserDetails()?.phone,
    terms: false
  };


  const location = useLocation();
  const params = useParams();
  const [yourData, setYourData] = useState([]);
  const [open, setOpen] = useState(false);

  const [show, setShow] = useState(false);
  const [Amount, setAmount] = useState("");
  const [proId, setProId] = useState("");
  const [userId, setUserId] = useState("");
  const [getBuyStatus, setGetBuyStatus] = useState(true);
  const [postData, setPostData] = useState(null);
  const [activeWish, setActiveWish] = useState(false);
  const [error, setError] = useState([]);

  const [Eshow, setEShow] = useState(false);

  const [useContactDetails, setUseContactDetails] = useState({
    Name: "",
    email: "",
    Phone: "",
    terms: false
  });
  const [propertyData, setPropertyData] = useState({});
  const [paymentType, setPaymentType] = useState("");
  const [certificateType, setCertificateType] = useState([]);
  const [certificateDatas, setCertificateDatas] = useState([]);
  const [value, setValue] = React.useState("");

  useEffect(() => {
    if (uniqueAttachments?.length > 0) {
      setValue(uniqueAttachments[0]?.documentTypeName);
    }
  }, [yourData]);

  const filteredAttachments = yourData?.certificates?.attachment?.filter(
    (attach) => attach.documentTypeName === value
  );

  const uniqueAttachments = yourData?.certificates?.attachment?.reduce((acc, current) => {
    const x = acc.find((item) => item.documentTypeName === current.documentTypeName);
    if (!x) {
      return acc.concat([{ ...current, count: 1 }]);
    } else {
      return acc.map((item) =>
        item.documentTypeName === current.documentTypeName
          ? { ...item, count: item.count + 1 }
          : item
      );
    }
  }, []);

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };

  // const handleChangeTab = (event, newValue) => {
  //   setValue(newValue);

  //   getCertificatebyDocumenttypeID(newValue, yourData._id)
  //     .then((res) => {
  //       if (res.data.status === 1) {
  //         debugger
  //         let result = res.data.certificatesData;
  //         setCertificateDatas(result);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setShow(false);
    setOpen(false);
  };

  useEffect(() => {
    getCertificateType();
  }, []);

  const getCertificateType = () => {
    getDocumentType()
      .then((res) => {
        setCertificateType(res.data.data);
        const list = res.data.data;
        const result = list.map(() => { });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updatePostData = (data) => {
    setPostData(data);
  };

  const handleShow = () => setShow(true);
  // useEffect(() => {
  //   setYourData(location.state?.yourData);
  // }, []);
  useEffect(() => {
    getBuyStatusCall();
    propertyInfo();
    let userId = Auth?.getUserDetails()?._id;
    if (userId) {
      getistdetails();
    }

  }, [userId]);

  const getBuyStatusCall = async () => {
    let id = location.state?.yourData._id;
    let paramId = params.id;
    let propertyId = id ? id : paramId;
    getPaymentStatus(propertyId)
      .then((res) => {
        if (res.data.code === 200) {
          if (res.data.isPaid === true) {
            setGetBuyStatus(res.data.isPaid);
          } else {
            setGetBuyStatus(res.data.isPaid);
          }
        } else {
          setGetBuyStatus(res.data.isPaid);
        }
      })
      .catch((e) => {
        setGetBuyStatus(false);
        console.log("====================================");
        console.log(e, "error in get payments");
        console.log("====================================");
      });
  };

  const openModal = (amt, propertyId, userId) => {
    setAmount(amt);
    setProId(propertyId);
    setUserId(userId);
    handleShow();
  };

  const NumberSeparator = (input) => {
    return new Intl.NumberFormat("en-IN", {
      minimumFractionDigits: 2,
    }).format(input);
  };

  const DeleteProperty = (rentPropertyId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You are about to delete this record.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it",
      cancelButtonText: "No, cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteproject(rentPropertyId)
          .then((res) => {
            if (res.data.status === 1) {
              Swal.fire({
                icon: "success",
                title: "Project Deleted",
                text: "Your project has been successfully deleted.",
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK",
                timer: 3000,
                timerProgressBar: true,
                backdrop: true,
              });
              // GetAllProperty();
              navigate("/");
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops! Something went wrong.",
                text: "We encountered an unexpected error. Please try again later.",
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                // cancelButtonColor: '#d33',
                confirmButtonText: "ok",
              });
            }
          })
          .catch((err) => {
            console.log(err, "err in delete rent property.");
          });
      }
    });
  };
  const DeleteRentProperty = (rentPropertyId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You are about to delete this record.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it",
      cancelButtonText: "No, cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteRentProperty(rentPropertyId)
          .then((res) => {
            if (res.data.status === 1) {
              Swal.fire({
                icon: "success",
                title: "Rent property deleted successfully.",
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                // cancelButtonColor: '#d33',
                confirmButtonText: "ok",
              });
              // GetAllRentProperty();
              navigate("/");
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops! Something went wrong.",
                text: "We encountered an unexpected error. Please try again later.",
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                // cancelButtonColor: '#d33',
                confirmButtonText: "ok",
              });
            }
          })
          .catch((err) => {
            console.log(err, "err in delete rent property.");
          });
      }
    });
  };
  const renderIcon = (value) => {
    return value ? (
      <CheckIcon style={{ color: "green" }} />
    ) : (
      <CloseIcon style={{ color: "red" }} />
    );
  };

console.log(yourData,"yourData");

  const p = location.state?.propertyType
    ? location.state?.propertyType
    : params.proprty;
    console.log(p,"p");

  const propertyImages =
    p === "Commercial"
      ? [
        {
          original: `${yourData?.exteriorView?.[0]?.name || "https://api.equireal.co.in/defaultProperty.jpg"}`,
          thumbnail: `${yourData?.exteriorView?.[0]?.name || "https://api.equireal.co.in/defaultProperty.jpg"}`,
        },
        {
          original: `${yourData?.InteririorView?.[0]?.name || "https://api.equireal.co.in/defaultProperty.jpg"}`,
          thumbnail: `${yourData?.InteririorView?.[0]?.name || "https://api.equireal.co.in/defaultProperty.jpg"}`,
        },
        ...(yourData?.kitchenImage?.[0]?.name
          ? [{
            original: `${yourData?.kitchenImage?.[0]?.name}`,
            thumbnail: `${yourData?.kitchenImage?.[0]?.name}`,
          }]
          : []),
        ...(yourData?.bedRoomImage?.[0]?.name
          ? [{
            original: `${yourData?.bedRoomImage?.[0]?.name}`,
            thumbnail: `${yourData?.bedRoomImage?.[0]?.name}`,
          }]
          : []),

      ]
      : location.state?.propertyType === "Construction"
        ? yourData?.photos?.projectImages?.map((data) => ({
          original: data.name || 'https://api.equireal.co.in/defaultProperty.jpg',
          thumbnail: data.name || 'https://api.equireal.co.in/defaultProperty.jpg',
        })) || []
        : [
          {
            original: `${yourData?.exteriorView?.[0]?.name || "https://api.equireal.co.in/defaultProperty.jpg"}`,
            thumbnail: `${yourData?.exteriorView?.[0]?.name || "https://api.equireal.co.in/defaultProperty.jpg"}`,
          },
          {
            original: `${yourData?.livingRoomImage?.[0]?.name || "https://api.equireal.co.in/defaultProperty.jpg"}`,
            thumbnail: `${yourData?.livingRoomImage?.[0]?.name || "https://api.equireal.co.in/defaultProperty.jpg"}`,
          },
          ...(yourData?.kitchenImage?.[0]?.name
            ? [{
              original: `${yourData?.kitchenImage?.[0]?.name}`,
              thumbnail: `${yourData?.kitchenImage?.[0]?.name}`,
            }]
            : []),
          ...(yourData?.bedRoomImage?.[0]?.name
            ? [{
              original: `${yourData?.bedRoomImage?.[0]?.name}`,
              thumbnail: `${yourData?.bedRoomImage?.[0]?.name}`,
            }]
            : []),
        ];

  const propertyInfo = async () => {
    let id = location.state?.yourData?._id;
    let paramId = params.id;
    let propertyId = id ? id : paramId;
    let type = location.state?.propertyType
      ? location.state.propertyType
      : params.proprty;
    
    

    if (type === "Commercial") {
      CommercialPropertyDetails(propertyId)
        .then((res) => {
          if (res.data.status === 200) {
            setYourData(res.data.RentCommercialPropertyDetails);
          } else {
            setYourData([]);
          }
        })
        .catch((e) => {
          console.log("Error in getting commercial property:", e);
        });
    } else if (type === "Construction") {
      getBuilderProjectByPropertyId(propertyId)
        .then((res) => {
          if (res.data.status === 1) {
            setYourData(res.data.data);
          } else {
            setYourData([]);
          }
        })
        .catch((e) => {
          console.log("Error in getting new project:", e);
        });
    } else {
      // Assuming "type" is for other property types (e.g., "Residential")
      const res = await RentPropertyDetails(propertyId)
        .then((res) => {
          if (res.data.status === 200) {
            setYourData(res.data.rentDetail);
          } else {
            setYourData([]);
          }
        })
        .catch((e) => {
          console.log("Error in getting property details:", e);
        });


    }
  };

  const DownloadCertificate = (certificateData) => {
    window.open(certificateData.attachment[0].name, "_blank");
  };

  const downloadBrochure = (brochureData) => {
    // Create a new instance of jsPDF
    const { brochureTitle, name, _id } = brochureData;
    window.open(name, "_blank");
  };

  // enquiry

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUseContactDetails({ ...useContactDetails, [name]: value });

    if (name === "Name" && value !== null && value !== "") {
      setError({
        ...error,
        Name: "Name field can't be left blank. Please enter your name!",
      });
    }

    if (name === "email" && value !== null && value !== "") {
      setError({
        ...error,
        email: "Email ID field can't be left blank. Please enter",
      });
    }
    if (name === "Phone" && value !== null && value !== "") {
      setError({
        ...error,
        Phone: "Please enter a 10-digit Mobile number!",
      });
    }
  };

  const validation = () => {
    let errors = {};
    let isValid = true;

    if (useContactDetails.Name === "" || useContactDetails.Name === null) {
      isValid = false;
      errors["Name"] =
        "Name field can't be left blank. Please enter your name!";
    }

    if (useContactDetails.email === "" || useContactDetails.email === null) {
      isValid = false;
      errors["email"] = "Email ID field can't be left blank. Please enter!";
    }

    if (useContactDetails.Phone === "" || useContactDetails.Phone === null) {
      isValid = false;
      errors["Phone"] = "Please enter a 10-digit Mobile number!";
    } else if (useContactDetails.terms === false) {
      isValid = false;
      Swal.fire({
        icon: "warning",
        title: "Please Agree to EquiReal Terms",
        text: "By clicking 'OK', you confirm that you agree to the terms and conditions of EquiReal.",
        showConfirmButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      });
    }
    return isValid;
  };

  const submitContactDetails = (e) => {
    e.preventDefault();
    const isValid = validation();
    if (isValid) {
      let dataToSend = {
        fullName: useContactDetails.Name,
        email: useContactDetails.email,
        phone: useContactDetails.Phone,
        PropertyId: propertyData.PropertyId,
        agentId: propertyData.ownerId._id,
      };
      sendEnquiryData(dataToSend)
        .then((res) => {
          if (res.data.status === true) {
            Swal.fire({
              icon: "success",
              title: "Enquiry Added Successfully!",
              text: "Your enquiry has been submitted and our team will get back to you shortly.",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK",
            });
            setShow(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const handleCloseE = () => {
    setEShow(false);
    setUseContactDetails({
      Name: "",
      email: "",
      Phone: "",
      terms: false
    });
  };

  const handleRazorpay = (data, propertyId, userId) => {
    const option = {
      key: "rzp_test_ftfKAFOFwJu6pH",
      amount: data.amount,
      currency: data.currency,
      name: "Real Estate",
      description: "Test Transaction",
      image: "https://example.com/your_logo",
      order_id: data.id,

      handler: function (response) {
        let paymentData = {
          orderId: response.razorpay_order_id,
          paymentID: response.razorpay_payment_id,
          propertyId: propertyId,
          userId: userId,
          amount: data.amount,
          razorpaySignature: response.razorpay_signature,
        };
        VerifyPayment(paymentData)
          .then((res) => {
            if (res.data.code === 200) {
              handleClose();
              Swal.fire({
                icon: "success",
                title: "Payment Successful!",
                text: "Your payment has been processed successfully.",
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK",
              });
            } else if (res.data.code === 400) {
              Swal.fire({
                icon: "error",
                title: "Payment Error",
                text: "It looks like this invoice has already been paid.",
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK",
              });
            }
          })
          .catch((err) => {
            console.log(err, "error in buy");
          });
      },
    };

    const paymentObject = new window.Razorpay(option);
    paymentObject.open();
  };

  const payAmount = (amt, propertyId, userId) => {
    const data = {
      amount: Number(amt),
      userId: userId,
      propertyId: propertyId,
    };
    OrderPayment(data)
      .then((res) => {
        handleRazorpay(res.data.data, propertyId, userId);
      })
      .catch((err) => {
        console.log(err, "error in payAmount");
      });
  };

  const payCryptoCurrency = (amt) => {
    const amount = {
      amount: Number(amt),
      currency: "USD",
    };
    axios
      .post("https://api.equireal.co.in/CoinbaseRoute/checkout", amount)
      .then((res) => {
        window.open(res.data.charge.hosted_url);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const payNow = (Amount, proId, userId) => {
    if (paymentType === "Razorpay") {
      payAmount(Amount, proId, userId);
    } else {
      payCryptoCurrency(Amount);
    }
  };

  const addToWishist = (data, wish) => {
    let userId = Auth?.getUserDetails()?._id;
    let propertyType = location.state.propertyType;
    let dataToend = {
      userId: userId,
      PropertyId: data._id,
      PropertyType: propertyType,
    };
    addProprtyToWish(dataToend)
      .then((res) => {
        if (res.data.status === true) {
          // let wishlisttatus = res.data.wishlist.Properties;
          // let status = wishlisttatus.map((wishlist, index) => {
          //   if (wishlist.PropertyId === data._id) {
          //     return true;
          //   } else {
          //     return false;
          //   }
          // });
          setActiveWish({
            ...activeWish,
            [data._id]: true,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getistdetails = async () => {
    let userId = Auth?.getUserDetails()?._id;

    await getwishlist(userId)
      .then((res) => {
        let list = res.data.userWishlist.Properties;

        let newActiveWish = {};
        yourData?.forEach((property) => {
          let isPropertyInList = list.some(
            (item) => item.PropertyId === property._id
          );
          newActiveWish[property._id] = isPropertyInList;
        });
        setActiveWish(newActiveWish);
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleChangeChecbox = (event) => {
    const { name, value, type, checked } = event.target;
    setUseContactDetails({
      ...useContactDetails,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const formatCash = (n) => {
    if (n < 1e3) return n.toString();
    if (n >= 1e3 && n < 1e5) return +(n / 1e3).toFixed(2) + "K";
    if (n >= 1e5 && n < 1e7) return +(n / 1e5).toFixed(2) + "L";
    if (n >= 1e7 && n < 1e9) return +(n / 1e7).toFixed(2) + "cr";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(2) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(2) + "T";
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const [currentIndex, setCurrentIndex] = useState(0);

  const settings = {
    dots: false,
    thumbnail: true,
    infinite: true,
    fade: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    beforeChange: (oldIndex, newIndex) => setCurrentIndex(newIndex),
  };



  return (
    <>
      <div className="topNav-m">
        <TopNavigation />
        <div className="prop-title">
          <Container>
            <Row>
              {/* {location?.state?.propertyType === "Construction" ?

                <Col>
                  <StyledBreadcrumb
                    component="a"
                    href="/ConstructionSection"
                    icon={<IoMdArrowRoundBack fontSize="20px" />}
                    label="Back"
                  />
                </Col>
                :
                <Col className="breadcrums">
                  <Breadcrumbs aria-label="breadcrumb" onClick={handleBackClick}>
                    <StyledBreadcrumb
                      component="a"
                      href="/ConstructionSection"
                      icon={<IoMdArrowRoundBack fontSize="20px" />}
                      label="Back"
                    />

                    <StyledBreadcrumb
                      component="a"
                      href="/filterProperties"
                      label={`Flats for ${yourData?.purposeOfProperty ? capitalizeFirstLetter(yourData?.purposeOfProperty) : 'All Properties'} in ${yourData?.Location ? capitalizeFirstLetter(yourData?.Location) : "All Locations"}`}
                    />
                    <StyledBreadcrumb
                    label={`2 bhk Flat for Rent in vashi`}
                    deleteIcon={<ExpandMoreIcon />}
                  />
                  </Breadcrumbs>
                </Col>
              } */}

              {Auth?.user?.userData?.role === "Admin" && location?.state?.propertyType === "Construction"  && location?.state?.propertyProjectType ==="PendingProject" ? (
                <Col>
                  <StyledBreadcrumb
                    component="a"
                    href="/pendingProjects"
                    icon={<IoMdArrowRoundBack fontSize="20px" />}
                    label="Back"
                  />
                </Col>
              ) :
                location?.state?.propertyType === "Construction" ? (
                  <Col>
                    <StyledBreadcrumb
                      component="a"
                      href="/ConstructionSection"
                      icon={<IoMdArrowRoundBack fontSize="20px" />}
                      label="Back"
                    />
                  </Col>
                ) : (
                  <Col className="breadcrums">
                    <Breadcrumbs aria-label="breadcrumb" onClick={handleBackClick}>
                      <StyledBreadcrumb
                        component="a"
                        href="/ConstructionSection"
                        icon={<IoMdArrowRoundBack fontSize="20px" />}
                        label="Back"
                      />
                      <StyledBreadcrumb
                        component="a"
                        href="/filterProperties"
                        label={`Flats for ${yourData?.purposeOfProperty ? capitalizeFirstLetter(yourData?.purposeOfProperty) : 'All Properties'} in ${yourData?.Location ? capitalizeFirstLetter(yourData?.Location) : "All Locations"}`}
                      />
                    </Breadcrumbs>
                  </Col>
                )}
            </Row>
            <Row className="pt-1">
              <Col lg={4} sm={12} className="p-head">
                { }
                <h5>
                  {/* {yourData?.selectNosofBedrooms} BHK{" "} */}
                  {location?.state?.propertyType === "Construction"
                    ? `${capitalizeFirstLetter(yourData?.nameOfProject)}`
                    : `${yourData?.propertyType && capitalizeFirstLetter(yourData.propertyType)} For ${capitalizeFirstLetter(yourData.purposeOfProperty)}`}
                </h5>

                <p>
                  in {yourData?.subLocation && capitalizeFirstLetter(yourData?.subLocation)},{" "}
                  {yourData?.Location && capitalizeFirstLetter(yourData?.Location)}
                  {/* <a href='/#'><PlaceOutlinedIcon fontSize='2' />View on map</a> */}
                </p>
              </Col>
              <Col lg={8} sm={12}>
                <div className="prop-title-div">
                  <ul>
                    {yourData?.purposeOfProperty === "Rent" ?
                      yourData?.PropertyRent && (
                        <li>
                          <p>{capitalizeFirstLetter(yourData?.purposeOfProperty)}</p>
                          <h4>
                            ₹
                            {yourData?.PropertyRent &&
                              NumberSeparator(yourData?.PropertyRent)}
                            /-
                          </h4>
                        </li>
                      )
                      : yourData?.propertyCost && (
                        <li>
                          <p>{capitalizeFirstLetter(yourData?.purposeOfProperty)}</p>
                          <h4>
                            ₹
                            {yourData?.propertyCost &&
                              NumberSeparator(yourData?.propertyCost)}
                            /-
                          </h4>
                        </li>
                      )
                    }

                    {location?.state.propertyType !== "Construction" ? (
                      <li>
                        <p>Carpet Area</p>
                        <h4>
                          {yourData && yourData?.areainSqft !== undefined
                            ? `${yourData?.areainSqft} SqFt.`
                            : `${yourData?.carpetArea} SqFt.`}
                        </h4>
                      </li>
                    ) : null}

                    {yourData?.purposeOfProperty === "Rent" ?
                      yourData?.SecurityDeposit && (
                        <li>
                          <p>Security Deposit</p>
                          <h4>
                            ₹
                            {yourData?.SecurityDeposit &&
                              NumberSeparator(yourData?.SecurityDeposit)}
                            /-
                          </h4>
                        </li>
                      ) :
                      yourData?.bookingAmount && (
                        <li>
                          <p>Booking Price</p>
                          <h4>
                            ₹
                            {yourData?.bookingAmount &&
                              NumberSeparator(yourData?.bookingAmount)}
                            /-
                          </h4>
                        </li>
                      )}


                    <li className="d-flex g-2 justify-content-center align-items-center">
                      {Auth?.user?.userData?.role === "Owner" ||
                        Auth?.user?.userData?.role === "Agent" ||
                        Auth?.user?.userData?.role === "Admin" ||
                        Auth?.user?.userData?.role === "Builder" ||

                        (
                          <Button
                            variant="contained"
                            className="btn-add"
                            onClick={() => {
                              if (Auth.getUserDetails()) {
                                setEShow(true);
                                setUseContactDetails(userData);
                                setPropertyData({
                                  PropertyId: yourData?._id,
                                  ownerId: yourData?.userId,
                                });
                              } else {
                                handleOpen();
                              }
                            }}
                          >
                            {location?.state?.propertyType === "Construction"
                              ? "Contact Builder"
                              : "Contact Owner"}
                          </Button>
                        )}
                      &nbsp;
                      <div>
                        <span className="property-starts">

                          {Auth?.user?.userData?.role === "Owner" ||
                            Auth?.user?.userData?.role === "Agent" ||
                            Auth?.user?.userData?.role === "Admin" ||
                            Auth?.user?.userData?.role === "Builder" ?
                            (
                              <div>
                                {(((location?.state?.yourData.userId._id || location?.state?.yourData.userId) === Auth?.user?.userData?._id)) && (
                                  <>
                                    <Button
                                      size="sm"
                                      variant="secondary"
                                      className="btn-add"
                                      onClick={() =>
                                        Auth?.user?.userData?.role === "Builder"
                                          ? navigate("/addBuilderProject", { state: yourData })
                                          : navigate("/newRent", { state: yourData })
                                      }
                                    >
                                      Edit
                                    </Button>

                                    {yourData?.PropertyRent ? (
                                      <Button onClick={() => DeleteRentProperty(yourData?._id)}>
                                        Delete
                                      </Button>
                                    ) : (
                                      <Button onClick={() => DeleteProperty(yourData?._id)}>
                                        Delete
                                      </Button>
                                    )}
                                  </>
                                )}
                              </div>
                            ) : getBuyStatus === true ? (
                              <Button size="sm" variant="success" disabled>
                                Already Booked
                              </Button>
                            ) : null}
                        </span>
                      </div>
                      {/* // :
                            // location?.state?.propertyType !== "Construction" ? (
                            //   <Button
                            //     size="sm"
                            //     className="btn-add"
                            //     variant="secondary"
                            //     onClick={() =>
                            //       Auth.getUserDetails()
                            //         ? openModal(
                            //           yourData?.Price
                            //             ? yourData?.Price
                            //             : yourData?.SecurityDeposit,
                            //           yourData?._id,
                            //           Auth?.getUserDetails()?._id
                            //         )
                            //         : handleOpen()
                            //     }
                            //   >
                            //     Pay Rent
                            //   </Button>
                            // ) : (
                            //   <Button
                            //     size="sm"
                            //     className="btn-add"
                            //     variant="secondary"
                            //     onClick={() =>
                            //       Auth.getUserDetails()
                            //         ? openModal(
                            //           yourData?.Price
                            //             ? yourData?.Price
                            //             : yourData?.SecurityDeposit,
                            //           yourData?._id,
                            //           Auth?.getUserDetails()?._id
                            //         )
                            //         : handleOpen()
                            //     }
                            //   >
                            //     Pay
                            //   </Button>


                            // ) */}



                      {Auth?.user?.userData?.role === "Owner" ||
                        Auth?.user?.userData?.role === "Agent" ||
                        Auth?.user?.userData?.role === "Admin" ? null : (
                        <div>
                          <IconButton
                            title="Add to Favourite"
                            onClick={() => {
                              // yourData?.userId
                              //  ? addToWishist(yourData)
                              //   : handleOpen();

                              Auth?.getUserDetails()?._id == undefined
                                ? handleOpen()
                                : addToWishist(yourData)
                            }}
                          >
                            {activeWish ? (
                              <FavoriteIcon
                                sx={{ color: pink[500] }}
                                style={{ fill: pink[500], cursor: "pointer" }}
                              />
                            ) : (
                              <FavoriteBorderIcon />
                            )}
                          </IconButton>
                        </div>
                      )}
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {location?.state?.propertyType === "Construction" ?
          <Container className="topNav-m">
            {/* <Col lg={12} md={12} sm={12}>
              <div className="img-gallery h-80">
                <ImageGallery
                  items={propertyImages}
                  showIndex={"true"}
                  autoPlay={"true"}
                  className="img-fluid"
                />
              </div>
            </Col> */}
            <div className="container mt-5">
              <div className="row">
                <div className="col-md-12 gallery-slider__images">
                  <Slider {...settings}>

                    {propertyImages.map((image, index) => (
                      <img src={image.original} alt={`Slide ${index + 1}`} className="img-fluid" />
                    ))}
                  </Slider>

                  {/* Thumbnails */}
                  <div className="gallery-slider__thumbnails">
                    <div className="d-flex justify-content-center">
                      {propertyImages.map((image, index) => (
                        <div
                          key={index}
                          className={`thumbnail ${currentIndex === index ? 'active' : ''}`}
                          onClick={() => setCurrentIndex(index)}
                          style={{ cursor: 'pointer', margin: '0 5px' }}
                        >
                          <img
                            src={image.thumbnail}
                            alt={`Thumbnail ${index + 1}`}
                            className="img-thumbnail"
                            style={{ width: '100px', height: 'auto' }}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <Col lg={12} md={12} sm={12}>
                <div className="img-gallery">
                  <ImageGallery
                    items={propertyImages}
                    showIndex={"true"}
                    autoPlay={"true"}
                    className="img-fluid"
                  />
                </div>
              </Col> */}
          </Container>
          :
          <>
            <div className="pt-8">&nbsp;</div>
          </>
        }
        <Container className="prop-details mt-0">
          <Row className="p-0 m-0 border-0">
            {location?.state?.propertyType === "Construction" ?
              <></>
              // <div className="builder-img">
              //   <img src={yourData?.photos?.projectImages[0]?.name} alt="" />
              // </div>
              :
              <Col lg={6} md={6} sm={12}>
                <div className="img-gallery">
                  <ImageGallery
                    items={propertyImages}
                    showIndex={"true"}
                    autoPlay={"true"}
                    className="img-fluid"
                  />
                </div>
              </Col>
            }
            <Col lg={6} md={6} sm={12} >
              <div className="basic-info">
                <ul>
                  {
                    location?.state?.propertyType === "Construction" ? null :
                      //   <>
                      //     <li>
                      //       <p>Property Owner</p>
                      //       <h6>{yourData?.propertyOwner}</h6>
                      //     </li>
                      //     <li>
                      //       <p>Project Name</p>
                      //       <h6>{yourData?.nameOfProject}</h6>
                      //     </li>
                      //     <li>
                      //       <p>Launch Date</p>
                      //       <h6>
                      //         {moment(yourData?.launchDate).format("DD/MM/YYYY")}
                      //       </h6>
                      //     </li>
                      //     <li>
                      //       <p>Contact No.</p>
                      //       <h6>{yourData?.phone}</h6>
                      //     </li>

                      //     <li>
                      //       <p>Price Range</p>
                      //       <h6>
                      //         {`₹ ${yourData?.minPrice} - ₹ ${yourData?.maxPrice} /- `}
                      //       </h6>
                      //     </li>
                      //     <li>
                      //       <p>Area Type</p>
                      //       <h6>{yourData?.areaType}</h6>
                      //     </li>
                      //     <li>
                      //       <p>Amenities</p>
                      //       <h6>{yourData?.Amenities?.length > 0 ? yourData.Amenities.join(", ") : yourData?.Amenities}</h6>
                      //     </li>
                      //     <li>
                      //       <p>Address</p>
                      //       <h6>{yourData?.Address}</h6>
                      //     </li>
                      //     <li>
                      //       <p>Description</p>
                      //       <h6>{yourData?.projectDescriptions}</h6>
                      //     </li>
                      //   </>
                      // ) :
                      location?.state?.propertyType == "Residential" || location?.state?.yourData?.type == "residential" ? (
                        <>
                          <li>
                            <p>Property Type</p>
                            <h6>{capitalizeFirstLetter(yourData?.propertyType)}</h6>
                          </li>
                          <li>
                            <p>PostedBy</p>
                            {Auth?.user?.userData?.role === "Admin" ?
                              <h6>
                                {capitalizeFirstLetter(yourData?.postedBy)}{' '}
                                <span style={{ fontSize: 'small' }}>({yourData?.userId?.fullName})</span>
                              </h6> :
                              <h6>{capitalizeFirstLetter(yourData?.postedBy)}</h6>
                            }
                          </li>

                          <li>
                            <p>Posted on</p>
                            <h6>
                              {moment(yourData?.createdAt).format("DD/MM/YYYY")}
                            </h6>
                          </li>
                          <li>
                            <p>Notice Period</p>
                            <h6>{yourData?.noticePeriod}</h6>
                          </li>
                          <li>
                            <p>No of Bedrooms</p>
                            <h6>{yourData?.selectNosofBedrooms}</h6>
                          </li>
                          <li>
                            <p>No of Balconies</p>
                            <h6>{yourData?.selectNosofBalconies}</h6>
                          </li>
                          <li>
                            <p>No of Bathrooms</p>
                            <h6>{yourData?.selectNosofBathrooms}</h6>
                          </li>
                          <li>
                            <p>CCTV</p>
                            <h6>{yourData?.isCCTVinstalled ? "Yes" : "No"}</h6>
                          </li>
                          <li>
                            <p>Floor Position</p>
                            <h6>{yourData?.selectFloor}</h6>
                          </li>
                          <li>
                            <p>Property Direction</p>
                            <h6>{yourData?.propertyDirection}</h6>
                          </li>
                          {/* <li>
                          <p>Heavy Deposite</p>
                          <h6>{yourData?.heavyDeposite}</h6>
                          </li> */}
                          {yourData?.heavyDeposite === "yes" ?
                          <li>
                          <h6>{" This property has Heavy Deposite " }</h6>
                          </li>: null}
                        </>
                      ) : (
                        <>
                          <li>
                            <p>Property Type</p>
                            <h6>{capitalizeFirstLetter(yourData?.propertyType)}</h6>
                          </li>
                          <li>
                            <p>PostedBy</p>
                            <h6>{capitalizeFirstLetter(yourData?.PostedBy)}{' '}
                              <span style={{ fontSize: 'small' }}>({yourData?.userId?.fullName})</span>
                            </h6>
                          </li>
                          <li>
                            <p>Posted on</p>
                            <h6>
                              {moment(yourData?.createdAt).format("DD/MM/YYYY")}
                            </h6>
                          </li>
                          <li>
                            <p>Lock in Period</p>
                            <h6>{yourData?.LockinPeriod}</h6>
                          </li>
                          <li>
                            <p>Complex Name</p>
                            <h6>{yourData?.ComplexName ? capitalizeFirstLetter(yourData?.ComplexName) : " - "}</h6>
                          </li>
                          <li>
                            <p>No of Cabins</p>
                            <h6>{yourData?.NosOfCabins ? yourData?.NosOfCabins : " - "}</h6>
                          </li>
                          <li>
                            <p>No Of Seats</p>
                            <h6>{yourData?.NosOfSeats ? yourData?.NosOfSeats : " - "} </h6>
                          </li>
                          <li>
                            <p>Pantry</p>
                            <h6>{yourData?.isPantryAvailable ? "Yes" : "No"}</h6>
                          </li>
                          <li>
                            <p>CCTV</p>
                            <h6>{yourData?.isCCTVinstalled ? "Yes" : "No"}</h6>
                          </li>
                          <li>
                            <p>Leed</p>
                            <h6>{yourData?.LeedStatus ? "Yes" : "No"}</h6>
                          </li>

                          <li>
                            <p>Maintenance Charges</p>
                            <h6>{yourData?.MaintanceCharges}</h6>
                          </li>
                          <li>
                            <p>Floor Position</p>
                            <h6>{yourData?.selectFloor}</h6>
                          </li>
                          <li>
                            <p>No of Floor</p>
                            <h6>{yourData?.totalFloor}</h6>
                          </li>
                          <li>
                            <p>Amenities</p>
                            <h6>{yourData?.Amenities?.length > 0 ? yourData.Amenities.join(", ") : yourData?.Amenities}</h6>
                          </li>
                          {yourData?.heavyDeposite === "yes" ?
                          <li>
                          <h6>{" This property has Heavy Deposite " }</h6>
                          </li>: null}
                        </>
                      )}
                </ul>
                {/* <ul>
                  {location.state?.propertyType
                    ? location.state?.propertyType
                    : params.proprty === "Residential" && (
                        <li>
                          <p>No. of Bedroom</p>
                          <h6>
                            {yourData?.selectNosofBedrooms}
                            &nbsp;Bedroom
                          </h6>
                        </li>
                      )}
                </ul> */}
              </div>
            </Col>
          </Row>
          {/* <Row>
            <Col className="description">
              <h4>Description</h4>
              <Description
                description={
                  location?.state.propertyType == "Construction"
                    ? yourData?.projectDescriptions
                    : yourData?.descriptionAboutCommercialProperty
                }
              />
            </Col>
          </Row> */}

          {location?.state.propertyType == "Construction" ? (
            <>
              <Row className="py-2">
                <Col md={6} sm={12}>
                  <div className="d-flex gap-5">
                    <div className="b-det">
                      <span class="badge badge-project">Premium Project</span>
                      <h1>{capitalizeFirstLetter(yourData?.nameOfProject)}</h1>
                      <p>By {capitalizeFirstLetter(yourData?.propertyOwner)}</p>
                      <p className="proj-info__address">
                        <LocationOnIcon /> {capitalizeFirstLetter(yourData?.subLocation)},{" "}
                        {capitalizeFirstLetter(yourData?.Location)}
                      </p>

                      {/* <p>
                        Possession by{" "}
                        {moment(yourData?.launchDate).format("MMM YYYY")}
                        <Tooltip
                          title=" This data is fetched &amp; verified by RERA"
                          placement="right"
                          arrow
                          slotProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, -14],
                                  },
                                },
                              ],
                            },
                          }}
                        >
                          <InfoTwoToneIcon fontSize="12" />
                        </Tooltip>
                      </p> */}

                      <Row className="pt-4">
                        <Col >
                          <Accordion>
                            <Accordion.Item eventKey="0">
                              <Accordion.Header>Contact Person :   <b>  {yourData?.FullName}</b></Accordion.Header>
                              <Accordion.Body>
                                <div className="">
                                  <p>
                                    Name : {capitalizeFirstLetter(yourData?.FullName)}
                                  </p>
                                  <p>
                                    Address : {capitalizeFirstLetter(yourData?.Address)}
                                  </p>
                                  <p>
                                    <EmailOutlinedIcon /><a href={`mailto:${yourData?.email}`}>{yourData?.email}</a>
                                  </p>
                                  <p>
                                    <PhoneIcon /> <a href={`tel:${yourData?.phone}`}>{yourData?.phone}</a>
                                  </p>
                                </div>
                              </Accordion.Body>

                            </Accordion.Item>
                          </Accordion>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
                <Col md={6} sm={12}>
                  <div className="price-card ">
                    <div>
                      <h4 className="fw-bold">
                        {" "}
                        ₹{formatCash(yourData?.minPrice)} Cr - ₹{formatCash(yourData?.maxPrice)}{" "}
                        Cr
                      </h4>
                      <p>
                        {yourData?.areaType
                          ?.map((areatype) => parseInt(areatype.split(" ")[0]))
                          .join(", ")}{" "}
                        BHK Flats{" "}
                        <span class="badge badge-project mx-4">
                          {yourData?.totalUnits} units available
                        </span>{" "}
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="py-2 border-0">
                <Col >
                  <h3>About {yourData?.nameOfProject}</h3>
                  <div className="pr-about">
                    <div className="pr-card"><div className="d-flex align-items-center">
                      <AspectRatioIcon />  Status</div>
                      <span>{yourData?.isVerified}</span>
                    </div>
                    <div className="pr-card">
                      <div className="d-flex align-items-center">
                        <EventOutlinedIcon />

                        Launch Date</div>
                      <span>
                        {moment(yourData?.launchDate).format("MMM YYYY")}
                      </span>
                    </div>
                    <div className="pr-card">
                      <div className="d-flex align-items-center"><HomeOutlinedIcon />
                        Total Units</div>
                      <span>{yourData?.totalUnits}</span>
                    </div>
                    {/* <div className="pr-card">Total Towers

                <span>2</span>
              </div> */}
                    <div className="pr-card">
                      <div className="d-flex align-items-center">
                        <HolidayVillageOutlinedIcon />

                        BHK</div>
                      <span>
                        {yourData?.areaType
                          ?.map((areatype) => parseInt(areatype.split(" ")[0]))
                          .join(", ")}
                      </span>
                    </div>

                    <div className="pr-card">
                      <div className="d-flex align-items-center"><MdConfirmationNumber />
                        Total ReraNo</div>
                      <span>{yourData?.reraNo}</span>
                    </div>

                    <div className="pr-card">
                      <div className="d-flex align-items-center"><EventOutlinedIcon />
                        Possesion Date</div>
                      <span>{moment(yourData?.possessionDate).format("MMM YYYY")}</span>
                    </div>

                    <div className="pr-card">
                      <div className="d-flex align-items-center"><RiSecurePaymentLine />
                        Payment Scheme</div>
                      <span>{capitalizeFirstLetter(yourData?.paymentScheme)}</span>
                    </div>

                    {/* <div className="pr-card">
                      <div className="d-flex align-items-center"><FaMapLocationDot />
                        location Benefits</div>
                      <span>{yourData?.locationBenefits}</span>
                    </div> */}
                  </div>
                </Col>
              </Row>


              <Row className="py-2">
                <Col >
                  <h3>Amenities of {yourData?.nameOfProject}</h3>
                  {/* <div>
                    <ul  className="b-amenities-box-icon">
                   <li><img src={gymImg} alt=""/> Fitness Centre</li>
                      <li><img src={BilliardImg} alt=""/> Billiards Room</li>
                       <li><img src={loungeImg} alt=""/>Adult Lounge</li>
                    <li><img src={balconyImg} alt=""/> Viewing Deck</li> 
                      <li><img src={buddhaImg} alt=""/> Buddha Garden</li>
                      <li><img src={barcounterImg} alt=""/>Bar Counter</li>
                      <li><img src={theatreImg} alt=""/> Kids Amphitheatre</li>  
                      <li><img src={swimmingImg} alt=""/> Roof Top Consist of Infinity Sky Pool</li>
                      <li><img src={gardenImg} alt=""/> Sky Garden</li>
                      <li><img src={OpencinemaImg} alt=""/> Open Air Cinema</li>
                     <li><img src={playgroundImg} alt=""/>Play Area</li>
                      <li><img src={spaImg} alt=""/>Gym & Spa</li>
                      <li><img src={clubImg} alt=""/>Club House</li>
                      <li><img src={metroImg} alt=""/>Metro and Railway station 2mins away</li>
                     <li><img src={waterImg} alt=""/>Water</li>
                      <li><img src={liftImg} alt=""/>Lift</li>  
                      <li><img src={PowerImg} alt=""/>Power Back Up</li>
                      <li><img src={acImg} alt=""/>Air Conditioned</li>
                      <li><img src={vaastuImg} alt=""/>Vaastu Compliant</li>
                      <li><img src={parkingImg} alt=""/>Reserved Parking</li>
                      <li><img src={wifiImg} alt=""/>Internet/Wi-Fi</li>
                      <li><img src={playgroundImg} alt=""/>Kids play area</li>
                      <li><img src={poolImg} alt=""/>Swimming Pool</li>
                      <li><img src={yogaImg} alt=""/>Yoga and Meditation zone</li>
                    </ul>
                  </div> */}


                  <div className="b-amenities-box">
                    {yourData?.Amenities?.map((amintities) => (
                      <div className="b-amenities-card">{amintities}</div>
                    ))}
                  </div>
                </Col>
              </Row>



              {/* {yourData?.configuration?.length > 0 ?
                <Row className="py-1">
                  <Col>
                    <h3>Project Configuration</h3>

                    {yourData?.configuration?.map((configData) => (
                      <>
                        <div className="b-floorplan-box pt-2">
                          <div className="b-floorplan-card">
                            <div className="b-floorplan-content">
                              <h5 className="fw-bold">
                                PropertyType : {configData?.areatypeId?.AreaType}
                              </h5>
                            </div>

                            <Col md={6} sm={12}>
                              <div className="d-flex align-items-center">
                                Area Title : {configData.areaTitle}
                              </div>
                              <div>
                                <div className="d-flex align-items-center">
                                  AreaSqft : {configData.areaSqft}
                                </div>
                              </div>
                            </Col>
                            <Col md={6} sm={12}>
                              <div>
                                <div className="d-flex align-items-center">
                                  Cost :{configData.cost}
                                </div>
                              </div>
                              <div>
                                <div className="d-flex align-items-center">
                                  Count : {configData.counter}
                                </div>
                              </div>
                            </Col>
                          </div>
                        </div>
                      </>
                    ))}

                  </Col>
                </Row>
                :

                <Row className="msg-container">
                  <div className="d-flex align-items-center gap-4">
                    <h4 className="m-0">Project Configuration</h4>
                    <p className="sorry-msg">Sorry, the information is currently unavailable.</p>
                  </div>
                </Row>
              } */}


              {yourData?.brochurePdf?.length > 0 ?

                <Row>
                  <h4>Brochure</h4>
                  <a href={yourData?.brochurePdf[0].name} target="_blank" rel="noopener noreferrer">
                    <div>
                      <div>
                        <span className="iconMagnifier"></span>
                        <embed src={yourData?.brochurePdf[0].name} width="195" height="112" alt="Certificate" />
                      </div>
                    </div>
                  </a>
                </Row>
                :
                // <Row className="msg-container">
                //   <div className="d-flex align-items-center gap-4">
                //     <h4 className="m-0">Brochure</h4>
                //     <p className="sorry-msg">Sorry, the information is currently unavailable.</p>
                //   </div>
                // </Row>
                null

              }


              {uniqueAttachments?.length > 0 ?
                <Row className="py-2">
                  <Box sx={{ width: "100%", typography: "body1" }}>
                    <TabContext value={value}>
                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <h4>
                          Certificates
                          <p
                            title="Total count of certificates"
                            style={{
                              marginLeft: '8px',
                              padding: '0px 6px',
                              backgroundColor: 'red',
                              borderRadius: '50%',
                              color: 'white',
                              display: 'inline-block',
                              textAlign: 'center',
                              fontSize: '1rem',
                            }}
                          >
                            {yourData?.certificates?.attachment?.length || 0}
                          </p>
                        </h4>

                        <TabList onChange={handleChangeTabs} aria-label="lab API tabs example">
                          {uniqueAttachments?.map((a, index) => {
                            const count = yourData.certificates.attachment.filter(
                              (attachment) => attachment.documentTypeName === a.documentTypeName
                            ).length;
                            return (
                              <Tab
                                key={index}
                                label={`${a.documentTypeName} (${count})`}
                                value={a.documentTypeName}
                              />
                            );
                          })}
                        </TabList>

                      </Box>
                      <TabPanel value={value}>
                        <div className="attachments-container">

                          {filteredAttachments?.map((attach) => (
                            <div className="builder-pics" key={attach._id}>
                              <div className="b-pic">
                                <div>
                                  <a href={attach.name} target="_blank" rel="noopener noreferrer">
                                    <div>
                                      <span className="iconMagnifier"></span>
                                      <embed src={attach?.name} width="195" height="112" alt="Certificate" />
                                    </div>
                                  </a>
                                </div>
                                <FormControl variant="outlined" fullWidth size="small">
                                  <span style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                    <span>{attach?.documentTypeName}</span>
                                    <span style={{ marginLeft: '10px' }}>{attach?.attachmentName}</span>
                                  </span>
                                </FormControl>
                              </div>
                            </div>
                          ))}
                        </div>
                      </TabPanel>
                    </TabContext>
                  </Box>
                </Row>
                :
                // <Row className="msg-container">
                //   <div className="d-flex align-items-center gap-4">
                //     <h4 className="m-0">Certificates</h4>
                //     <p className="sorry-msg">Sorry, the information is currently unavailable.</p>
                //   </div>
                // </Row>
                null
              }

              {/* {yourData?.photos?.projectImages.length > 0 ?
                <Row className="py-2">
                  <Col>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                      }}
                    >
                      <h4> Project Images</h4>
                      <div style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
                        {yourData?.photos?.projectImages?.map(
                          (a, index) => (
                            <img
                              key={`project-${index}`}
                              src={a.name}
                              height={200}
                              width={300}
                            />
                          )
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
                :
                <div>
                  <h4>Project Images</h4>
                  <p style={{
                    margin: 0,
                    padding: 0,
                    lineHeight: 1.2,
                    color: '#6c757d',
                  }}>Sorry, the information is currently unavailable.</p>
                </div>
                null
              } */}

              {yourData?.photos?.uploadVideo.length > 0 ?
                <Row className="py-2">
                  <Col>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                      }}
                    >
                      <h4> Project Videos</h4>
                      <div style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
                        {
                          yourData?.photos?.uploadVideo?.map((a, index) => (
                            <iframe
                              key={`project-${index}`}
                              src={a.name}
                              height={200}
                              width={300}
                            />
                          ))
                        }
                      </div>
                    </div>
                  </Col>
                </Row>
                :
                // <Row className="msg-container">
                //   <div className="d-flex align-items-center gap-4 mt-3">
                //     <h4 className="m-0">Project Videos</h4>
                //     <p className="sorry-msg">Sorry, the information is currently unavailable.</p>
                //   </div>
                // </Row>
                null
              }

              {yourData?.photos?.masterPlanImages.length > 0 ?
                <Row className="py-2">
                  <Col>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                      }}
                    >
                      <h4>Master Plan Images</h4>

                      <div style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>

                        {yourData?.photos?.masterPlanImages?.map((a, index) => (
                          <img
                            key={`project-${index}`}
                            src={a.name}
                            height={200}
                            width={300}
                          />
                        ))}
                      </div>
                    </div>
                  </Col>
                </Row>
                :
                // <Row className="msg-container">
                //   <div className="d-flex align-items-center gap-4">
                //     <h4 className="m-0">Master Plan Images</h4>
                //     <p className="sorry-msg">Sorry, the information is currently unavailable.</p>
                //   </div>
                // </Row>
                null
              }

              {yourData?.photos?.locationPlanImages.length > 0 ?
                <Row className="py-2">
                  <Col>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                      }}
                    >
                      <h4> Location Plan Images</h4>
                      <div style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
                        {yourData?.photos?.locationPlanImages?.map(
                          (a, index) => (
                            <img
                              key={`project-${index}`}
                              src={a.name}
                              height={200}
                              width={300}
                            />
                          )
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
                :
                // <Row className="msg-container">
                //   <div className="d-flex align-items-center gap-4">
                //     <h4 className="m-0">Location Plan Images</h4>
                //     <p className="sorry-msg">Sorry, the information is currently unavailable.</p>
                //   </div>
                // </Row>
                null
              }

              {/* {yourData?.photos?.floorPlanImages.length > 0 ? */}
              {yourData?.configuration?.length > 0 ?
                <Row className="py-2">
                  <Col>
                    <>
                      <h3>{yourData?.nameOfProject} Floor Plan & Units</h3>
                      <div className="b-floorplan-box pt-3">
                        {yourData?.areaType?.map((areatype, index) => {
                          let filterData = yourData?.configuration?.filter((newData) => newData?.areatypeId?.AreaType === areatype);
                          const images = yourData?.photos?.floorPlanImages.filter(image => image?.areatypeId?.AreaType === areatype);
                          if (filterData.length > 0) {
                            return filterData.map((configData, configIndex) => (
                              <React.Fragment key={`${index}-${configIndex}`}>
                                <div className="b-floorplan-card">
                                  <div>
                                  <div className="b-floorplan-content mb-3">
                                    <h5>{areatype} Flat</h5>
                                  </div>
                                  {/* <div className="b-floorplan-content"> */}
                                  <div className="b-floorplan-content-info">
                                    <h6>{capitalizeFirstLetter(configData.areaTitle)}</h6>
                                    <h6><SquareFootOutlinedIcon /> {configData.areaSqft} Sqft</h6>
                                    <h6><SellOutlinedIcon />₹{configData.cost}/-</h6>
                                    <h6><ApartmentOutlinedIcon /> {configData.counter} units</h6>
                                  </div>
</div>
                                  <div className="b-floorplan-img">
                                    {images.length > 0 ? (
                                      images.map((image, imgIndex) => (
                                        <img
                                          key={imgIndex}
                                          src={image.name}
                                          height="100px"
                                          width="100px"
                                          className="img-project"
                                          alt=""
                                        />
                                      ))
                                    ) : (
                                      <p>No Image Available</p>
                                    )}
                                  </div>
                                  {/* </div> */}
                                </div>
                              </React.Fragment>
                            ));
                          } else {
                            <div>
                            </div>
                            // <Row className="msg-container">
                            //   <div className="d-flex align-items-center gap-4">
                            //     <h4 className="m-0">Floor Plan & Units</h4>
                            //     <p className="sorry-msg">Sorry, the information is currently unavailable.</p>
                            //   </div>
                            // </Row>
                          }
                        })}
                      </div>
                    </>
                  </Col>
                </Row>
                : null}
              {/* :
                //   <Row className="msg-container">
                //   <div className="d-flex align-items-center gap-4">
                //     <h4  className="m-0">Floor Plan & Units</h4>
                //     <p className="sorry-msg">Sorry, the information is currently unavailable.</p>
                //   </div>
                // </Row>
              //   null
              // } */}
              {yourData?.locationBenefits ?
                <Row className="py-2">
                  <Col>
                    <h3> Advantages of This Location</h3>
                    <div>
                      <span>{capitalizeFirstLetter(yourData?.locationBenefits)}</span>
                    </div>
                  </Col>
                </Row>
                : null}

            </>
          ) : null}

          {/* <Reviews /> */}
          {/* {Auth?.user?.userData?.role === "Owner" ||
            Auth?.user?.userData?.role === "Agent" ||
            Auth?.user?.userData?.role === "Builder" ||
            Auth?.user?.userData?.role === "Admin" ? (
            <CustomerReviewSection propertyId={location?.state?.yourData._id} />
          ) : Auth?.user?.userData?._id ? (
            <div>
              <PostComments
                propertiesId={location?.state?.yourData._id}
                updatePostData={updatePostData}
              />
              <CustomerReviewSection
                propertyId={location?.state?.yourData._id}
                postData={postData}
              />
            </div>
          ) : (
            <CustomerReviewSection propertyId={location?.state?.yourData._id} />
          )} */}

          {/* {Auth?.user?.userData?.role === "Owner" ||
            Auth?.user?.userData?.role === "Agent" ||
            Auth?.user?.userData?.role === "Builder" ||
            Auth?.user?.userData?.role === "Admin" ?
            (
            <>

              {location?.state?.yourData.userId?._id === Auth?.user?.userData?._id ? null : (
                <PostComments
                  propertiesId={location?.state?.yourData._id}
                  updatePostData={updatePostData}
                />
              )}
              <CustomerReviewSection propertyId={location?.state?.yourData._id} />
            </>
          ) : Auth?.user?.userData?._id ? (
            <>
              {yourData.userId !== Auth?.user?.userData?._id && (
                <PostComments
                  propertiesId={location?.state?.yourData._id}
                  updatePostData={updatePostData}
                />
              )}
              <CustomerReviewSection
                propertyId={location?.state?.yourData._id}
                postData={postData}
              />
            </>
          ) : (
            <CustomerReviewSection propertyId={location?.state?.yourData._id} />
          )} */}

          {Auth?.user?.userData?.role === "Owner" ||
            Auth?.user?.userData?.role === "Agent" ||
            Auth?.user?.userData?.role === "Builder" ? (
            <>
              {location?.state?.yourData.userId?._id === Auth?.user?.userData?._id ? null : (
                <PostComments
                  propertiesId={location?.state?.yourData._id}
                  updatePostData={updatePostData}
                />
              )}
              <CustomerReviewSection propertyId={location?.state?.yourData._id} />
            </>
          ) : Auth?.user?.userData?._id && Auth?.user?.userData?.role !== "Admin" ? (
            <>
              {yourData.userId !== Auth?.user?.userData?._id && (
                <PostComments
                  propertiesId={location?.state?.yourData._id}
                  updatePostData={updatePostData}
                />
              )}
              <CustomerReviewSection
                propertyId={location?.state?.yourData._id}
                postData={postData}
              />
            </>
          ) : (
            <CustomerReviewSection propertyId={location?.state?.yourData._id} />
          )}

        </Container>{" "}
      </div>

      <Modal show={show} onHide={handleClose} id="payment" size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Choose Payment Method Below</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mb-3 payment-width">
          <div className="grid-wrapper grid-col-auto">
            <label htmlFor="radio-card-1" className="radio-card">
              <input
                type="radio"
                name="radio-card"
                id="radio-card-1"
                value={"bitcoin"}
                onChange={(e) => setPaymentType(e.target.value)}
              />
              <div className="card-content-wrapper">
                <span className="check-icon"></span>
                <div className="card-content">
                  <img src={BitcoinImg} className="img-fluid" alt="" />
                  <h4>Crypto Currency</h4>
                  <h5>Pay with Crypto Currency.</h5>
                </div>
              </div>
            </label>

            <label htmlFor="radio-card-2" className="radio-card">
              <input
                type="radio"
                name="radio-card"
                value={"Razorpay"}
                onChange={(e) => setPaymentType(e.target.value)}
                id="radio-card-2"
              />
              <div className="card-content-wrapper">
                <span className="check-icon"></span>
                <div className="card-content">
                  <img src={FiatcurrencyImg} className="img-fluid" alt="" />
                  <h4>Fiat Currency</h4>
                  <h5>Pay with RazorPay.</h5>
                </div>
              </div>
            </label>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex gap-2 justify-content-center py-3">
          <Button
            className="btn-add"
            onClick={() => payNow(Amount, proId, userId)}
          >
            Pay Now
          </Button>
          {/* <Button variant="outlined" onClick={() => payCryptoCurrency(Amount)}>
            Crypto currency
          </Button>
          <Button
            variant="outlined"
            onClick={() => payAmount(Amount, proId, userId)}
          >
            Fiat currency
          </Button> */}
        </Modal.Footer>
      </Modal>

      {/* login please modal */}
      <Modal show={open} onHide={handleClose} size="lg" centered closeButton>
        <Box sx={style} padding={2} textAlign={'center'} >
          <img src={LoginImg} alt="" className="img-fluid" width={300} />
          <h5>
            Access to this feature requires authentication.
          </h5>
          <p>  Kindly log in to continue.</p>


          <Button onClick={() => navigate("/login")} className="btn-add mt-2 w-150">Login</Button>
        </Box>
      </Modal>

      {/* contact agent modal */}
      <Modal
        show={Eshow}
        onHide={() => handleCloseE()}
        disableBackdropClick={false}
        disableEscapeKeyDown={false}
        centered
      >
        <Modal.Header closeButton className="headerBlue">
          <Modal.Title
            className=" text-center loginTitle"
            style={{ margin: "0 auto" }}
          >
            <h5 class="m-0">Fill out this one-time form</h5>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="p-0">
          <div className="text-center bg-c-skyblue">
            <img src={EmailImg} alt="" className="img-fluid" width={200} />
          </div>

          <div className="text-center px-5 py-4">
            <p className="text-gray-700 mb-4 text-center">
              Get Agent's details over email
            </p>
            <form>
              <div>
                <TextField
                  id="name"
                  label="Your Name"
                  variant="standard"
                  fullWidth
                  size="small"
                  value={useContactDetails.Name}
                  name="Name"
                  onChange={handleChange}
                // helperText={error.Name !== "" ? error.Name : null}
                // error={error.Name !== "" ? true : false}
                />
              </div>
              <div>
                <TextField
                  id="email"
                  label="Email"
                  variant="standard"
                  type="email"
                  name="email"
                  size="small"
                  onChange={handleChange}
                  value={useContactDetails.email}
                  fullWidth
                // helperText={error.email !== "" ? error.email : null}
                // error={error.email !== "" ? true : false}
                />
              </div>
              <div>
                <div className="d-flex">
                  <TextField
                    color="grey"
                    id="standard-select-currency-native"
                    select
                    size="small"
                    label="Country"
                    //   defaultValue="EUR"
                    SelectProps={{
                      native: true,
                    }}
                    variant="standard"
                  >
                    {currencies?.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                  <TextField
                    id="standard-basic"
                    label="Mobile Number"
                    variant="standard"
                    color="grey"
                    size="small"
                    fullWidth
                    name="Phone"
                    value={useContactDetails.Phone}
                    onChange={handleChange}
                  // helperText={error.Phone !== "" ? error.Phone : null}
                  // error={error.Phone !== "" ? true : false}
                  />
                </div>
              </div>
              <div className="mb-6">
                <label className="" fullWidth>
                  <Checkbox
                    color="primary"
                    name="terms"
                    checked={useContactDetails.terms}
                    onChange={handleChangeChecbox}
                  />
                  <span className="ml-2">
                    I agree to EquiReal{" "}
                    terms of use
                  </span>
                </label>
              </div>
              <div className="flex items-center justify-center mt-2">
                <Button
                  variant="contained"
                  color="error"
                  type="submit"
                  fullWidth
                  className="btn-add"
                  onClick={submitContactDetails}
                >
                  Get Contact Details
                </Button>
              </div>
            </form>
            <div>

            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
