import React, { useState,useEffect } from "react";
//mui packages
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import moment from "moment";
import ReactPaginate from "react-paginate";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import CircleIcon from '@mui/icons-material/Circle';
//custom components
import { settings } from "../../Config/Settings";
import AdminSidebar from "../../Components/Sidebar/AdminSidebar";
//api
import { ApprovedRejectSendMail, getEnquiryData } from "../../Api/AuthAPI";

const Enquirylist = () => {
  const [enquiryData, setEnquiryData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [viewPropertyModal, setViewPropertyModal] = useState(false);
  const [propertyDetails, setPropertyDetails] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const itemsPerPage = 10;
  
  const filteredData = enquiryData.filter((data) => {
    const searchTermLowerCase = searchTerm.toLowerCase();
    const createdAtDate = new Date(data.createdAt).toLocaleDateString();
  
    return (
      data.fullName.toLowerCase().includes(searchTermLowerCase) ||
      data.email.toLowerCase().includes(searchTermLowerCase) ||
      data.phone.toLowerCase().includes(searchTermLowerCase) ||
      data.agentName.toLowerCase().includes(searchTermLowerCase) ||
      createdAtDate.toLowerCase().includes(searchTermLowerCase)
    );
  });

  const pageCount = Math.ceil(enquiryData.length / itemsPerPage);
  const offset = pageNumber * itemsPerPage;
  const currentPageData = filteredData.slice(offset, offset + itemsPerPage);

  const columns = [
    { id: "id", label: "Sr. No.", minWidth: 20 },
    { id: "fullName", label: "Enquired Name", minWidth: 100 },
    {
      id: "email",
      label: "Email",
      minWidth: 100,
    },
    {
      id: "phone",
      label: "Phone",
      minWidth: 100,
    },
    {
      id: "createdAt",
      label: "Enquiry Date",
      minWidth: 100,
    },
    {
      id: "agentName",
      label: "Agent/Owner",
      minWidth: 100,
    },
    {
      id: "PropertyId",
      label: "Property Id",
      minWidth: 100,
    },
  ];

  useEffect(() => {
    getEnquiryDataList();
  }, []);

  const getEnquiryDataList = () => {
    getEnquiryData()
      .then((res) => {
        if (res.status === 200) {
        setEnquiryData(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePageChange = ({ selected }) => {
    setPageNumber(selected);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPageNumber(0);
  };

  const viewPropertyDetails = (propertyData) => {
    setViewPropertyModal(true);
    setPropertyDetails(propertyData);
  };
  const handleClose = () => {
    setViewPropertyModal(false);
  };

  const sendApprovedMail = (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to send the approval mail?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, send it!",
      cancelButtonText: "No, cancel!"
    }).then((result) => {
      if (result.isConfirmed) {
        let enquiryId = data._id;
        let dataToSend = {
          enquiryStatus: "Approve"
        };
        ApprovedRejectSendMail(enquiryId, dataToSend)
          .then((res) => {
            if (res.data.status === 200) {
              Swal.fire({
                icon: "success",
                title: "Enquiry Details Mail Sent Successfully.",
                text: 'Please Check Mail.',
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "ok",
              });
              getEnquiryDataList();
            } else {
              Swal.fire({
                icon: "warning",
                title: "Enquiry Details Mail Not Sent.",
                text: 'Please Try Again!',
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "ok",
              });
            }
          }).catch((err) => {
            console.log(err, 'Error in Enquiry send mail');
          });
      }
    });
  };

  const sendRejectedMail = (data) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "Do you want to reject this enquiry?",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, reject it!",
      cancelButtonText: "No, cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        let enquiryId = data._id;
        let dataToSend = {
          enquiryStatus: "Reject",
        };
        ApprovedRejectSendMail(enquiryId, dataToSend)
          .then((res) => {
            if (res.data.status === 200) {
              Swal.fire({
                icon: "success",
                title: "Enquiry Details Mail Sent Successfully.",
                text: "Please Check Mail.",
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "ok",
              });
              getEnquiryDataList();
            } else {
              Swal.fire({
                icon: "warning",
                title: "Enquiry Details Mail Not Sent.",
                text: "Please Try Again!",
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "ok",
              });
            }
          })
          .catch((err) => {
            console.log(err, 'Error in Enquiry Reject mail');
          });
      }
    });
  };

  return (
    <div>
      <AdminSidebar />
      <div className="container topNav-m">
        <div className=" content-title">
          <h3>Enquiry Details</h3>
          <hr className="content-title-underline" />
        </div>

        <div className="d-flex justify-content-end mb-2">
          <input
            type="text"
            placeholder="Search"
            value={searchTerm}
            onChange={handleSearchChange}
            style={{ padding: "5px", width: "250px" }}
          />
        </div>

        <Paper
          sx={{ width: "100%", overflow: "hidden", justifyContent: "center" }}
        >
          <TableContainer sx={{ maxHeight: 700, overflowX: "auto" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <>
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    </>
                  ))}
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentPageData?.length > 0 ?
                currentPageData.map((data, index) => {
                  const serialNumber = offset + index + 1;
                  return (
                    <TableRow key={index}>
                      <TableCell >{serialNumber}</TableCell>
                      <TableCell>{data.fullName}</TableCell>
                      <TableCell>{data.email}</TableCell>
                      <TableCell>{data.phone}</TableCell>
                      <TableCell>
                        {moment(data.createdAt).format("DD/MM/YYYY")}
                      </TableCell>
                      <TableCell>{data.agentName}</TableCell>
                      <TableCell className="tdlink"
                        onClick={() => viewPropertyDetails(data)}

                      >
                        {data.PropertyId}
                      </TableCell>
                      <TableCell >
                        {data.enquiryStatus === 'pending' ? (
                          <div className="fl-center">
                            <button className="bluesharebutton" onClick={() => sendApprovedMail(data)}>
                              Approved{" "}
                              <span>
                                {/* <FcApproval /> */}
                              </span>
                            </button>

                            <button className="rejectsharebutton" onClick={() => sendRejectedMail(data)}>
                              Reject{" "}
                              <span>
                                {/* <FcDisapprove /> */}
                              </span>
                            </button>
                          </div>
                        ) :

                          data.enquiryStatus === 'Approve' ?
                            <h6 className="approvestatus">
                              <CircleIcon fontSize="small" /> sent{" "}
                            </h6>
                            :
                            <h6 className="rejectstatus">
                              <CircleIcon />  Rejected{" "}
                            </h6>
                        }
                      </TableCell>
                    </TableRow>
                  );
                })
                :
                <TableRow>
                  Data not found
                </TableRow>
              }
              </TableBody>
            </Table>
            <div
              className="container py-3"

            >
              <ReactPaginate
                previousLabel={<NavigateBeforeIcon />}
                nextLabel={<NavigateNextIcon />}
                breakLabel={"..."}
                pageCount={pageCount}
                onPageChange={handlePageChange}
                containerClassName={"pagination"}
                activeClassName={"activepagination"}
                pageClassName={"pagination-item"}
              />
            </div>
          </TableContainer>
        </Paper>
      </div>
      {propertyDetails && (
        <Modal show={viewPropertyModal} onHide={handleClose} centered className="modal-lg ">
          <Modal.Header closeButton className="headerBlue">
            <Modal.Title
              className="loginTitle"

            >
              <h5 className="m-0">Property Details</h5>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body >
            <div className="pl-5 pr-5  pb-4  d-flex">

              <div className="enq-img">
                {propertyDetails?.propertyData?.exteriorView ?
                  <img
                    src={`${propertyDetails?.propertyData?.exteriorView[0]?.name}`}
                    alt="Property" className="img-fluid"
                  />
                  :
                  
                  <img
                  src={`${propertyDetails?.propertyData?.photos[0]?.projectImages?.length > 0 ? propertyDetails?.propertyData?.photos[0]?.projectImages[0]?.name : "image not available"}`}
                  alt="Property" className="img-fluid"
                  />
                }
              </div>
              <div className="modal-enquiry-details">
                <div className="transactionData">
                  <div className="transactionDataTitle">PropertyId : </div>
                  <div className="transactionDataValue">
                    {propertyDetails?.propertyData?._id}
                  </div>
                </div>

                {propertyDetails?.propertyData?.propertyType ?
                  <div className="transactionData  ">
                    <div className="transactionDataTitle">PropertyType : </div>
                    <div className="transactionDataValue">
                      {propertyDetails?.propertyData?.propertyType}
                    </div>
                  </div>
                  :
                  <div className="transactionData  ">
                    <div className="transactionDataTitle">AreaType : </div>
                    <div className="transactionDataValue">
                      {propertyDetails?.propertyData?.areaType.join(", ")}
                    </div>
                  </div>
                }

                <div className="transactionData">
                  <div className="transactionDataTitle">Location : </div>
                  <div className="transactionDataValue">
                    {propertyDetails?.propertyData?.Location}
                  </div>
                </div>
                <div className="transactionData  ">
                  <div className="transactionDataTitle">SubLocation : </div>
                  <div className="transactionDataValue">
                    {propertyDetails?.propertyData?.subLocation}
                  </div>
                </div>
                {propertyDetails?.propertyData?.purposeOfProperty ? (
                  <>
                    <div className="transactionData">
                      <div className="transactionDataTitle">purposeOfProperty : </div>
                      <div className="transactionDataValue">
                        {propertyDetails?.propertyData?.purposeOfProperty}
                      </div>
                    </div>


                    {propertyDetails?.propertyData?.purposeOfProperty === "Buy" ? (
                      <>
                        <div className="transactionData">
                          <div className="transactionDataTitle">Property Cost : </div>
                          <div className="transactionDataValue">
                            {propertyDetails?.propertyData?.propertyCost}
                          </div>
                        </div>
                        <div className="transactionData">
                          <div className="transactionDataTitle">Booking Amount : </div>
                          <div className="transactionDataValue">
                            {propertyDetails?.propertyData?.bookingAmount}
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="transactionData">
                          <div className="transactionDataTitle">PropertyRent : </div>
                          <div className="transactionDataValue">
                            {propertyDetails?.propertyData?.PropertyRent}
                          </div>
                        </div>
                        <div className="transactionData">
                          <div className="transactionDataTitle">Security Deposit : </div>
                          <div className="transactionDataValue">
                            {propertyDetails?.propertyData?.SecurityDeposit}
                          </div>
                        </div>
                      </>
                    )
                    }
                  </>
                ) : (
                  <div className="transactionData">
                    <div className="transactionDataTitle">PriceRange : </div>
                    <div className="transactionDataValue">
                      {`${propertyDetails?.propertyData?.minPrice} - ${propertyDetails?.propertyData?.maxPrice}`}
                    </div>
                  </div>
                )
                }
                {propertyDetails?.propertyData?.nameOfProject ?
                  <div className="transactionData  ">
                    <div className="transactionDataTitle">Project Name : </div>
                    <div className="transactionDataValue">
                      {propertyDetails?.propertyData?.nameOfProject}
                    </div>
                  </div>
                  : null}
                {propertyDetails?.propertyData?.launchDate ?
                  <div className="transactionData  ">
                    <div className="transactionDataTitle">launch Date : </div>
                    <div className="transactionDataValue">
                      {moment(propertyDetails?.propertyData?.launchDate).format("DD/MM/YYYY")}
                    </div>
                  </div>
                  : null}

                {propertyDetails?.propertyData?.PropertyStatus ?
                  <div className="transactionData  ">
                    <div className="transactionDataTitle">PropertyStatus : </div>
                    <div className="transactionDataValue">
                      {propertyDetails?.propertyData?.PropertyStatus}
                    </div>
                  </div>
                  :
                  <div className="transactionData  ">
                    <div className="transactionDataTitle">totalUnits : </div>
                    <div className="transactionDataValue">
                      {propertyDetails?.propertyData?.totalUnits}
                    </div>
                  </div>
                }
                {propertyDetails?.propertyData?.postedBy ?
                  <div className="transactionData">
                    <div className="transactionDataTitle">postedBy : </div>
                    <div className="transactionDataValue">
                      {propertyDetails?.propertyData?.postedBy}
                    </div>
                  </div>
                  :
                  <div className="transactionData">
                    <div className="transactionDataTitle">Amenities : </div>
                    <div className="transactionDataValue">
                      {propertyDetails?.propertyData?.Amenities.join(", ")}
                    </div>
                  </div>
                }

              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default Enquirylist;
