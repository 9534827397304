import React, { useState } from "react";
import "../Components/Common/search-filter.css";
import Topnavigation from "./Navigation/TopNavigation";
import Footer from "./Layouts/Footer";
import { Container, Row, Col } from "react-bootstrap";
import {
  Button,
  FormControl,
  Grid,
  TextField,
  IconButton,
  Tooltip,
  Checkbox,
} from "@mui/material";
import "react-slideshow-image/dist/styles.css";
import { emphasize, styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PropertyImg from "../Assets/img1.avif";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import BedOutlinedIcon from "@mui/icons-material/BedOutlined";
import SquareFootOutlinedIcon from "@mui/icons-material/SquareFootOutlined";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import ChairOutlinedIcon from "@mui/icons-material/ChairOutlined";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import CorporateFareOutlinedIcon from "@mui/icons-material/CorporateFareOutlined";
import EmojiTransportationOutlinedIcon from "@mui/icons-material/EmojiTransportationOutlined";
import { Modal } from "react-bootstrap";
import Auth from "../Config/helper/Auth";
import Swal from "sweetalert2";
function valuetext(value) {
  return `${value}°C`;
}

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
}); // TypeScript only: need a type cast here because https://github.com/Microsoft/TypeScript/issues/26591

const DemoAll = () => {
  let userData = {
    Name: Auth?.getUserDetails().fullName,
    email: Auth?.getUserDetails().email,
    Phone: Auth?.getUserDetails().phone,
    terms:false
  };

  // currency dropdown list
  const currencies = [
    {
      value: "USA",
      label: "USA +1",
    },
    {
      value: "IND",
      label: "IND +91",
    },
    {
      value: "ARE",
      label: "ARE +971",
    },
    {
      value: "GBR",
      label: "GBR +44",
    },
  ];
  const [show, setShow] = useState(false);
  const [useContactDetails, setUseContactDetails] = useState({
    Name: "",
    email: "",
    Phone: "",
    terms: false,
  });
  const [error, setError] = useState([]);

  const handleClose = () => {
    setShow(false);
    setUseContactDetails({
      Name: "",
      email: "",
      Phone: "",
      terms:false
    });
    setError([]);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUseContactDetails({ ...useContactDetails, [name]: value });

    if (name === "Name" && value !== null && value !== "") {
      setError({
        ...error,
        Name: "Name field can't be left blank. Please enter your name!",
      });
    }

    if (name === "email" && value !== null && value !== "") {
      setError({
        ...error,
        email: "Email ID field can't be left blank. Please enter",
      });
    }
    if (name === "Phone" && value !== null && value !== "") {
      setError({ ...error, Phone: "Please enter a 10-digit Mobile number!" });
    }
  };

  const validation = () => {
    let errors = {};
    let isValid = true;

    if (useContactDetails.Name === "" || useContactDetails.Name === null) {
      isValid = false;
      errors["Name"] =
        "Name field can't be left blank. Please enter your name!";
    }

    if (useContactDetails.email === "" || useContactDetails.email === null) {
      isValid = false;
      errors["email"] = "Email ID field can't be left blank. Please enter!";
    }

    if (useContactDetails.Phone === "" || useContactDetails.Phone === null) {
      isValid = false;
      errors["Phone"] = "Please enter a 10-digit Mobile number!";
    }else if(useContactDetails.terms === false){
      isValid = false;
      Swal.fire({
        icon: "warning",
        title: "Please Agree to EquiReal Terms",
        text: "By clicking 'OK', you confirm that you agree to the terms and conditions of EquiReal.",
        showConfirmButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      });
    }
    setError(errors);
    return isValid;
  };

  const submitContactDetails = (e) => {
    e.preventDefault();
    const isValid = validation();
    if (isValid) {
    }
  };

  const handleChangeChecbox = (event) => {
    const { name, value, type, checked } = event.target;
    setUseContactDetails({
      ...useContactDetails,
      [name]: type === 'checkbox' ? checked : value,
    });
  };


  return (
    <div className="topNav-m">
      <Topnavigation />
      <Container>
        <Row>
          <Col>
            <div className="p-3">
              <Breadcrumbs aria-label="breadcrumb">
                <StyledBreadcrumb
                  component="a"
                  href="#"
                  icon={<HomeIcon fontSize="small" />}
                />
                <StyledBreadcrumb
                  component="a"
                  href="#"
                  label="Flats for Rent in Mumbai"
                />
                <StyledBreadcrumb
                  label=" 2 bhk Flat for Rent in Vashi"
                  deleteIcon={<ExpandMoreIcon />}
                />
              </Breadcrumbs>
            </div>
            <div></div>
            <div className="allproperties">
              <div className="search-content">
                <div className="search-filter">
                  <div className="filters">
                    Filters{" "}
                    <Button variant="text" className="btn-clear">
                      clear{" "}
                    </Button>
                  </div>

                  <div class="cat-bhk">
                    <h5>BHK Type</h5>
                    <div class="cat search-sel">
                      <label>
                        <input type="checkbox" value="1" />
                        <span>1 RK</span>
                      </label>
                    </div>

                    <div class="cat search-sel">
                      <label>
                        <input type="checkbox" value="1" />
                        <span>1 BHK</span>
                      </label>
                    </div>

                    <div class="cat search-sel">
                      <label>
                        <input type="checkbox" value="1" />
                        <span>2 BHK</span>
                      </label>
                    </div>

                    <div class="cat search-sel">
                      <label>
                        <input type="checkbox" value="1" />
                        <span>3 BHK</span>
                      </label>
                    </div>

                    <div class="cat search-sel">
                      <label>
                        <input type="checkbox" value="1" />
                        <span>4 BHK</span>
                      </label>
                    </div>

                    <div class="cat search-sel">
                      <label>
                        <input type="checkbox" value="1" />
                        <span>4+ BHK</span>
                      </label>
                    </div>
                  </div>

                  <div>
                    <h5>Price Range</h5>
                  </div>

                  <div>
                    <h5>Availability</h5>
                    <div class="boxes">
                      <input type="checkbox" id="avail-1" />
                      <label for="avail-1">Immediate</label>

                      <input type="checkbox" id="avail-2" />
                      <label for="avail-2">Within 15 days </label>

                      <input type="checkbox" id="avail-3" />
                      <label for="avail-3">Within 30 days</label>

                      <input type="checkbox" id="avail-4" />
                      <label for="avail-5">After 45 days</label>
                    </div>
                  </div>

                  <div>
                    <h5>Preferred Tenants</h5>
                    <div class="boxes">
                      <input type="checkbox" id="PreTen-1" />
                      <label for="PreTen-1">Family</label>

                      <input type="checkbox" id="PreTen-2" />
                      <label for="PreTen-2">Company </label>

                      <input type="checkbox" id="PreTen-3" />
                      <label for="PreTen-3">Bachelor Male</label>
                      <input type="checkbox" id="PreTen-4" />
                      <label for="PreTen-4">Bachelor Female</label>
                    </div>
                  </div>

                  <div>
                    <h5>Furnishing</h5>
                    <div class="boxes">
                      <input type="checkbox" id="box-1" />
                      <label for="box-1">Full</label>

                      <input type="checkbox" id="box-2" />
                      <label for="box-2">Semi </label>

                      <input type="checkbox" id="box-3" />
                      <label for="box-3">None</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-card-content">
                <div className="p-card">
                  <div className="p-img">
                    <img src={PropertyImg} alt="Property" />
                  </div>
                  <div className="p-info">
                    <div className="p-tle">
                      <div>
                        <h4>6 BHK Residential House For Rent</h4>
                        <p>
                          in Marathon Nexzone, Panvel, Navi Mumbai{" "}
                          <a href="/#">
                            <PlaceOutlinedIcon fontSize="2" />
                            View on map
                          </a>
                        </p>
                      </div>
                      <div className="d-flex">
                        <Tooltip
                          title="Add to Favourite"
                          slotProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, -14],
                                  },
                                },
                              ],
                            },
                          }}
                        >
                          <IconButton title="Add to Favourite">
                            {" "}
                            <FavoriteBorderIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title="Share"
                          slotProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, -14],
                                  },
                                },
                              ],
                            },
                          }}
                        >
                          <IconButton>
                            <ShareOutlinedIcon />{" "}
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                    <div className="pr-info">
                      <ul>
                        <li>
                          <ChairOutlinedIcon />
                          <h6>Unfurnished</h6>
                        </li>
                        <li>
                          <BedOutlinedIcon />
                          <h6>6 BHK</h6>
                        </li>
                        <li>
                          <PeopleOutlineOutlinedIcon />
                          <h6>Family</h6>
                        </li>

                        <li>
                          <CorporateFareOutlinedIcon />
                          <h6> 1 of 10th Floor</h6>
                        </li>
                        <li>
                          <EmojiTransportationOutlinedIcon />
                          <h6> Immediate</h6>
                        </li>
                        <li>
                          <SquareFootOutlinedIcon />
                          <h6> 2000 Sq.Ft</h6>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="p-pri">
                    <div>
                      <h4>₹50,000/-</h4>
                      <p>
                        <a href="demoDetail.js">Rent</a>
                      </p>
                    </div>
                    <div>
                      <h4>₹100,000/-</h4>
                      <p>
                        <a href="demoDetail.js">Security Deposit</a>
                      </p>
                    </div>
                    <div>
                      <p>Owner: Rupali Ganguli</p>
                      <Button
                        variant="contained"
                        className="btn-p-owner"
                        onClick={() => {
                          setShow(true);
                          setUseContactDetails(userData);
                        }}
                      >
                        Contact Owner
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="p-card">
                  <div className="p-img">
                    <img src={PropertyImg} alt="Property" />
                  </div>
                  <div className="p-info">
                    <div className="p-tle">
                      <div>
                        <h4>6 BHK Residential House For Rent</h4>
                        <p>
                          in Marathon Nexzone, Panvel, Navi Mumbai{" "}
                          <a href="/#">
                            <PlaceOutlinedIcon fontSize="2" />
                            View on map
                          </a>
                        </p>
                      </div>
                      <div className="d-flex">
                        <Tooltip
                          title="Add to Favourite"
                          slotProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, -14],
                                  },
                                },
                              ],
                            },
                          }}
                        >
                          <IconButton title="Add to Favourite">
                            {" "}
                            <FavoriteBorderIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title="Share"
                          slotProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, -14],
                                  },
                                },
                              ],
                            },
                          }}
                        >
                          <IconButton>
                            <ShareOutlinedIcon />{" "}
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                    <div className="pr-info">
                      <ul>
                        <li>
                          <ChairOutlinedIcon />
                          <h6>Unfurnished</h6>
                        </li>
                        <li>
                          <BedOutlinedIcon />
                          <h6>6 BHK</h6>
                        </li>
                        <li>
                          <PeopleOutlineOutlinedIcon />
                          <h6>Family</h6>
                        </li>

                        <li>
                          <CorporateFareOutlinedIcon />
                          <h6> 1 of 10th Floor</h6>
                        </li>
                        <li>
                          <EmojiTransportationOutlinedIcon />
                          <h6> Immediate</h6>
                        </li>
                        <li>
                          <SquareFootOutlinedIcon />
                          <h6> 2000 Sq.Ft</h6>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="p-pri">
                    <div>
                      <h4>₹50,000/-</h4>
                      <p>
                        <a href="demoDetail.js">Rent</a>
                      </p>
                    </div>
                    <div>
                      <h4>₹100,000/-</h4>
                      <p>
                        <a href="demoDetail.js">Security Deposit</a>
                      </p>
                    </div>
                    <div>
                      <p>Owner: Rupali Ganguli</p>
                      <Button
                        variant="contained"
                        className="btn-p-owner"
                        onClick={() => {
                          setShow(true);
                          setUseContactDetails(userData);
                        }}
                      >
                        Contact Owner
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="p-card">
                  <div className="p-img">
                    <img src={PropertyImg} alt="Property" />
                  </div>
                  <div className="p-info">
                    <div className="p-tle">
                      <div>
                        <h4>6 BHK Residential House For Rent</h4>
                        <p>
                          in Marathon Nexzone, Panvel, Navi Mumbai{" "}
                          <a href="/#">
                            <PlaceOutlinedIcon fontSize="2" />
                            View on map
                          </a>
                        </p>
                      </div>
                      <div className="d-flex">
                        <Tooltip
                          title="Add to Favourite"
                          slotProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, -14],
                                  },
                                },
                              ],
                            },
                          }}
                        >
                          <IconButton title="Add to Favourite">
                            {" "}
                            <FavoriteBorderIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title="Share"
                          slotProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, -14],
                                  },
                                },
                              ],
                            },
                          }}
                        >
                          <IconButton>
                            <ShareOutlinedIcon />{" "}
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                    <div className="pr-info">
                      <ul>
                        <li>
                          <ChairOutlinedIcon />
                          <h6>Unfurnished</h6>
                        </li>
                        <li>
                          <BedOutlinedIcon />
                          <h6>6 BHK</h6>
                        </li>
                        <li>
                          <PeopleOutlineOutlinedIcon />
                          <h6>Family</h6>
                        </li>

                        <li>
                          <CorporateFareOutlinedIcon />
                          <h6> 1 of 10th Floor</h6>
                        </li>
                        <li>
                          <EmojiTransportationOutlinedIcon />
                          <h6> Immediate</h6>
                        </li>
                        <li>
                          <SquareFootOutlinedIcon />
                          <h6> 2000 Sq.Ft</h6>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="p-pri">
                    <div>
                      <h4>₹50,000/-</h4>
                      <p>
                        <a href="demoDetail.js">Rent</a>
                      </p>
                    </div>
                    <div>
                      <h4>₹100,000/-</h4>
                      <p>
                        <a href="demoDetail.js">Security Deposit</a>
                      </p>
                    </div>
                    <div>
                      <p>Owner: Rupali Ganguli</p>
                      <Button variant="contained" className="btn-p-owner">
                        Contact Owner
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="p-card">
                  <div className="p-img">
                    <img src={PropertyImg} alt="Property" />
                  </div>
                  <div className="p-info">
                    <div className="p-tle">
                      <div>
                        <h4>6 BHK Residential House For Rent</h4>
                        <p>
                          in Marathon Nexzone, Panvel, Navi Mumbai{" "}
                          <a href="/#">
                            <PlaceOutlinedIcon fontSize="2" />
                            View on map
                          </a>
                        </p>
                      </div>
                      <div className="d-flex">
                        <Tooltip
                          title="Add to Favourite"
                          slotProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, -14],
                                  },
                                },
                              ],
                            },
                          }}
                        >
                          <IconButton title="Add to Favourite">
                            {" "}
                            <FavoriteBorderIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title="Share"
                          slotProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, -14],
                                  },
                                },
                              ],
                            },
                          }}
                        >
                          <IconButton>
                            <ShareOutlinedIcon />{" "}
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                    <div className="pr-info">
                      <ul>
                        <li>
                          <ChairOutlinedIcon />
                          <h6>Unfurnished</h6>
                        </li>
                        <li>
                          <BedOutlinedIcon />
                          <h6>6 BHK</h6>
                        </li>
                        <li>
                          <PeopleOutlineOutlinedIcon />
                          <h6>Family</h6>
                        </li>

                        <li>
                          <CorporateFareOutlinedIcon />
                          <h6> 1 of 10th Floor</h6>
                        </li>
                        <li>
                          <EmojiTransportationOutlinedIcon />
                          <h6> Immediate</h6>
                        </li>
                        <li>
                          <SquareFootOutlinedIcon />
                          <h6> 2000 Sq.Ft</h6>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="p-pri">
                    <div>
                      <h4>₹50,000/-</h4>
                      <p>
                        <a href="demoDetail.js">Rent</a>
                      </p>
                    </div>
                    <div>
                      <h4>₹100,000/-</h4>
                      <p>
                        <a href="demoDetail.js">Security Deposit</a>
                      </p>
                    </div>
                    <div>
                      <p>Owner: Rupali Ganguli</p>
                      <Button variant="contained" className="btn-p-owner">
                        Contact Owner
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="p-card">
                  <div className="p-img">
                    <img src={PropertyImg} alt="Property" />
                  </div>
                  <div className="p-info">
                    <div className="p-tle">
                      <div>
                        <h4>6 BHK Residential House For Rent</h4>
                        <p>
                          in Marathon Nexzone, Panvel, Navi Mumbai{" "}
                          <a href="/#">
                            <PlaceOutlinedIcon fontSize="2" />
                            View on map
                          </a>
                        </p>
                      </div>
                      <div className="d-flex">
                        <Tooltip
                          title="Add to Favourite"
                          slotProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, -14],
                                  },
                                },
                              ],
                            },
                          }}
                        >
                          <IconButton title="Add to Favourite">
                            {" "}
                            <FavoriteBorderIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title="Share"
                          slotProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, -14],
                                  },
                                },
                              ],
                            },
                          }}
                        >
                          <IconButton>
                            <ShareOutlinedIcon />{" "}
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                    <div className="pr-info">
                      <ul>
                        <li>
                          <ChairOutlinedIcon />
                          <h6>Unfurnished</h6>
                        </li>
                        <li>
                          <BedOutlinedIcon />
                          <h6>6 BHK</h6>
                        </li>
                        <li>
                          <PeopleOutlineOutlinedIcon />
                          <h6>Family</h6>
                        </li>

                        <li>
                          <CorporateFareOutlinedIcon />
                          <h6> 1 of 10th Floor</h6>
                        </li>
                        <li>
                          <EmojiTransportationOutlinedIcon />
                          <h6> Immediate</h6>
                        </li>
                        <li>
                          <SquareFootOutlinedIcon />
                          <h6> 2000 Sq.Ft</h6>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="p-pri">
                    <div>
                      <h4>₹50,000/-</h4>
                      <p>
                        <a href="demoDetail.js">Rent</a>
                      </p>
                    </div>
                    <div>
                      <h4>₹100,000/-</h4>
                      <p>
                        <a href="demoDetail.js">Security Deposit</a>
                      </p>
                    </div>
                    <div>
                      <p>Owner: Rupali Ganguli</p>
                      <Button variant="contained" className="btn-p-owner">
                        Contact Owner
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="p-card">
                  <div className="p-img">
                    <img src={PropertyImg} alt="Property" />
                  </div>
                  <div className="p-info">
                    <div className="p-tle">
                      <div>
                        <h4>6 BHK Residential House For Rent</h4>
                        <p>
                          in Marathon Nexzone, Panvel, Navi Mumbai{" "}
                          <a href="/#">
                            <PlaceOutlinedIcon fontSize="2" />
                            View on map
                          </a>
                        </p>
                      </div>
                      <div className="d-flex">
                        <Tooltip
                          title="Add to Favourite"
                          slotProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, -14],
                                  },
                                },
                              ],
                            },
                          }}
                        >
                          <IconButton title="Add to Favourite">
                            {" "}
                            <FavoriteBorderIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title="Share"
                          slotProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, -14],
                                  },
                                },
                              ],
                            },
                          }}
                        >
                          <IconButton>
                            <ShareOutlinedIcon />{" "}
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                    <div className="pr-info">
                      <ul>
                        <li>
                          <ChairOutlinedIcon />
                          <h6>Unfurnished</h6>
                        </li>
                        <li>
                          <BedOutlinedIcon />
                          <h6>6 BHK</h6>
                        </li>
                        <li>
                          <PeopleOutlineOutlinedIcon />
                          <h6>Family</h6>
                        </li>

                        <li>
                          <CorporateFareOutlinedIcon />
                          <h6> 1 of 10th Floor</h6>
                        </li>
                        <li>
                          <EmojiTransportationOutlinedIcon />
                          <h6> Immediate</h6>
                        </li>
                        <li>
                          <SquareFootOutlinedIcon />
                          <h6> 2000 Sq.Ft</h6>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="p-pri">
                    <div>
                      <h4>₹50,000/-</h4>
                      <p>
                        <a href="demoDetail.js">Rent</a>
                      </p>
                    </div>
                    <div>
                      <h4>₹100,000/-</h4>
                      <p>
                        <a href="demoDetail.js">Security Deposit</a>
                      </p>
                    </div>
                    <div>
                      <p>Owner: Rupali Ganguli</p>
                      <Button variant="contained" className="btn-p-owner">
                        Contact Owner
                      </Button>
                    </div>
                  </div>
                </div>

                <div className="p-card">
                  <div className="p-img">
                    <img src={PropertyImg} alt="Property" />
                  </div>
                  <div className="p-info">
                    <div className="p-tle">
                      <div>
                        <h4>6 BHK Residential House For Rent</h4>
                        <p>
                          in Marathon Nexzone, Panvel, Navi Mumbai{" "}
                          <a href="/#">
                            <PlaceOutlinedIcon fontSize="2" />
                            View on map
                          </a>
                        </p>
                      </div>
                      <div className="d-flex">
                        <Tooltip
                          title="Add to Favourite"
                          slotProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, -14],
                                  },
                                },
                              ],
                            },
                          }}
                        >
                          <IconButton title="Add to Favourite">
                            {" "}
                            <FavoriteBorderIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title="Share"
                          slotProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, -14],
                                  },
                                },
                              ],
                            },
                          }}
                        >
                          <IconButton>
                            <ShareOutlinedIcon />{" "}
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                    <div className="pr-info">
                      <ul>
                        <li>
                          <ChairOutlinedIcon />
                          <h6>Unfurnished</h6>
                        </li>
                        <li>
                          <BedOutlinedIcon />
                          <h6>6 BHK</h6>
                        </li>
                        <li>
                          <PeopleOutlineOutlinedIcon />
                          <h6>Family</h6>
                        </li>

                        <li>
                          <CorporateFareOutlinedIcon />
                          <h6> 1 of 10th Floor</h6>
                        </li>
                        <li>
                          <EmojiTransportationOutlinedIcon />
                          <h6> Immediate</h6>
                        </li>
                        <li>
                          <SquareFootOutlinedIcon />
                          <h6> 2000 Sq.Ft</h6>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="p-pri">
                    <div>
                      <h4>₹50,000/-</h4>
                      <p>
                        <a href="demoDetail.js">Rent</a>
                      </p>
                    </div>
                    <div>
                      <h4>₹100,000/-</h4>
                      <p>
                        <a href="demoDetail.js">Security Deposit</a>
                      </p>
                    </div>
                    <div>
                      <p>Owner: Rupali Ganguli</p>
                      <Button variant="contained" className="btn-p-owner">
                        Contact Owner
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="p-card">
                  <div className="p-img">
                    <img src={PropertyImg} alt="Property" />
                  </div>
                  <div className="p-info">
                    <div className="p-tle">
                      <div>
                        <h4>6 BHK Residential House For Rent</h4>
                        <p>
                          in Marathon Nexzone, Panvel, Navi Mumbai{" "}
                          <a href="/#">
                            <PlaceOutlinedIcon fontSize="2" />
                            View on map
                          </a>
                        </p>
                      </div>
                      <div className="d-flex">
                        <Tooltip
                          title="Add to Favourite"
                          slotProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, -14],
                                  },
                                },
                              ],
                            },
                          }}
                        >
                          <IconButton title="Add to Favourite">
                            {" "}
                            <FavoriteBorderIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title="Share"
                          slotProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, -14],
                                  },
                                },
                              ],
                            },
                          }}
                        >
                          <IconButton>
                            <ShareOutlinedIcon />{" "}
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                    <div className="pr-info">
                      <ul>
                        <li>
                          <ChairOutlinedIcon />
                          <h6>Unfurnished</h6>
                        </li>
                        <li>
                          <BedOutlinedIcon />
                          <h6>6 BHK</h6>
                        </li>
                        <li>
                          <PeopleOutlineOutlinedIcon />
                          <h6>Family</h6>
                        </li>

                        <li>
                          <CorporateFareOutlinedIcon />
                          <h6> 1 of 10th Floor</h6>
                        </li>
                        <li>
                          <EmojiTransportationOutlinedIcon />
                          <h6> Immediate</h6>
                        </li>
                        <li>
                          <SquareFootOutlinedIcon />
                          <h6> 2000 Sq.Ft</h6>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="p-pri">
                    <div>
                      <h4>₹50,000/-</h4>
                      <p>
                        <a href="demoDetail.js">Rent</a>
                      </p>
                    </div>
                    <div>
                      <h4>₹100,000/-</h4>
                      <p>
                        <a href="demoDetail.js">Security Deposit</a>
                      </p>
                    </div>
                    <div>
                      <p>Owner: Rupali Ganguli</p>
                      <Button variant="contained" className="btn-p-owner">
                        Contact Owner
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="p-card">
                  <div className="p-img">
                    <img src={PropertyImg} alt="Property" />
                  </div>
                  <div className="p-info">
                    <div className="p-tle">
                      <div>
                        <h4>6 BHK Residential House For Rent</h4>
                        <p>
                          in Marathon Nexzone, Panvel, Navi Mumbai{" "}
                          <a href="/#">
                            <PlaceOutlinedIcon fontSize="2" />
                            View on map
                          </a>
                        </p>
                      </div>
                      <div className="d-flex">
                        <Tooltip
                          title="Add to Favourite"
                          slotProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, -14],
                                  },
                                },
                              ],
                            },
                          }}
                        >
                          <IconButton title="Add to Favourite">
                            {" "}
                            <FavoriteBorderIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title="Share"
                          slotProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, -14],
                                  },
                                },
                              ],
                            },
                          }}
                        >
                          <IconButton>
                            <ShareOutlinedIcon />{" "}
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                    <div className="pr-info">
                      <ul>
                        <li>
                          <ChairOutlinedIcon />
                          <h6>Unfurnished</h6>
                        </li>
                        <li>
                          <BedOutlinedIcon />
                          <h6>6 BHK</h6>
                        </li>
                        <li>
                          <PeopleOutlineOutlinedIcon />
                          <h6>Family</h6>
                        </li>

                        <li>
                          <SquareFootOutlinedIcon />
                          <h6> 2000 Sq.Ft</h6>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="p-pri">
                    <div>
                      <h4>₹50,000/-</h4>
                      <p>
                        <a href="demoDetail.js">Security Deposit</a>
                      </p>
                    </div>
                    <div>
                      <p>Owner: Rupali Ganguli</p>
                      <Button variant="contained" className="btn-p-owner">
                        Contact Owner
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="p-card">
                  <div className="p-img">
                    <img src={PropertyImg} alt="Property" />
                  </div>
                  <div className="p-info">
                    <div className="p-tle">
                      <div>
                        <h4>6 BHK Residential House For Rent</h4>
                        <p>
                          in Marathon Nexzone, Panvel, Navi Mumbai{" "}
                          <a href="/#">
                            <PlaceOutlinedIcon fontSize="2" />
                            View on map
                          </a>
                        </p>
                      </div>
                      <div className="d-flex">
                        <Tooltip
                          title="Add to Favourite"
                          slotProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, -14],
                                  },
                                },
                              ],
                            },
                          }}
                        >
                          <IconButton title="Add to Favourite">
                            {" "}
                            <FavoriteBorderIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title="Share"
                          slotProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, -14],
                                  },
                                },
                              ],
                            },
                          }}
                        >
                          <IconButton>
                            <ShareOutlinedIcon />{" "}
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                    <div className="pr-info">
                      <ul>
                        <li>
                          <ChairOutlinedIcon />
                          <h6>Unfurnished</h6>
                        </li>
                        <li>
                          <BedOutlinedIcon />
                          <h6>6 BHK</h6>
                        </li>
                        <li>
                          <PeopleOutlineOutlinedIcon />
                          <h6>Family</h6>
                        </li>

                        <li>
                          <SquareFootOutlinedIcon />
                          <h6> 2000 Sq.Ft</h6>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="p-pri">
                    <div>
                      <h4>₹50,000/-</h4>
                      <p>
                        <a href="demoDetail.js">Security Deposit</a>
                      </p>
                    </div>
                    <div>
                      <p>Owner: Rupali Ganguli</p>
                      <Button variant="contained" className="btn-p-owner">
                        Contact Owner
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="p-card">
                  <div className="p-img">
                    <img src={PropertyImg} alt="Property" />
                  </div>
                  <div className="p-info">
                    <div className="p-tle">
                      <div>
                        <h4>6 BHK Residential House For Rent</h4>
                        <p>
                          in Marathon Nexzone, Panvel, Navi Mumbai{" "}
                          <a href="/#">
                            <PlaceOutlinedIcon fontSize="2" />
                            View on map
                          </a>
                        </p>
                      </div>
                      <div className="d-flex">
                        <Tooltip
                          title="Add to Favourite"
                          slotProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, -14],
                                  },
                                },
                              ],
                            },
                          }}
                        >
                          <IconButton title="Add to Favourite">
                            {" "}
                            <FavoriteBorderIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title="Share"
                          slotProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, -14],
                                  },
                                },
                              ],
                            },
                          }}
                        >
                          <IconButton>
                            <ShareOutlinedIcon />{" "}
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                    <div className="pr-info">
                      <ul>
                        <li>
                          <ChairOutlinedIcon />
                          <h6>Unfurnished</h6>
                        </li>
                        <li>
                          <BedOutlinedIcon />
                          <h6>6 BHK</h6>
                        </li>
                        <li>
                          <PeopleOutlineOutlinedIcon />
                          <h6>Family</h6>
                        </li>

                        <li>
                          <SquareFootOutlinedIcon />
                          <h6> 2000 Sq.Ft</h6>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="p-pri">
                    <div>
                      <h4>₹50,000/-</h4>
                      <p>
                        <a href="demoDetail.js">Security Deposit</a>
                      </p>
                    </div>
                    <div>
                      <p>Owner: Rupali Ganguli</p>
                      <Button variant="contained" className="btn-p-owner">
                        Contact Owner
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="p-card">
                  <div className="p-img">
                    <img src={PropertyImg} alt="Property" />
                  </div>
                  <div className="p-info">
                    <div className="p-tle">
                      <div>
                        <h4>6 BHK Residential House For Rent</h4>
                        <p>
                          in Marathon Nexzone, Panvel, Navi Mumbai{" "}
                          <a href="/#">
                            <PlaceOutlinedIcon fontSize="2" />
                            View on map
                          </a>
                        </p>
                      </div>
                      <div className="d-flex">
                        <Tooltip
                          title="Add to Favourite"
                          slotProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, -14],
                                  },
                                },
                              ],
                            },
                          }}
                        >
                          <IconButton title="Add to Favourite">
                            {" "}
                            <FavoriteBorderIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title="Share"
                          slotProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, -14],
                                  },
                                },
                              ],
                            },
                          }}
                        >
                          <IconButton>
                            <ShareOutlinedIcon />{" "}
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                    <div className="pr-info">
                      <ul>
                        <li>
                          <ChairOutlinedIcon />
                          <h6>Unfurnished</h6>
                        </li>
                        <li>
                          <BedOutlinedIcon />
                          <h6>6 BHK</h6>
                        </li>
                        <li>
                          <PeopleOutlineOutlinedIcon />
                          <h6>Family</h6>
                        </li>

                        <li>
                          <SquareFootOutlinedIcon />
                          <h6> 2000 Sq.Ft</h6>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="p-pri">
                    <div>
                      <h4>₹50,000/-</h4>
                      <p>
                        <a href="demoDetail.js">Security Deposit</a>
                      </p>
                    </div>
                    <div>
                      <p>Owner: Rupali Ganguli</p>
                      <Button variant="contained" className="btn-p-owner">
                        Contact Owner
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="p-card">
                  <div className="p-img">
                    <img src={PropertyImg} alt="Property" />
                  </div>
                  <div className="p-info">
                    <div className="p-tle">
                      <div>
                        <h4>6 BHK Residential House For Rent</h4>
                        <p>
                          in Marathon Nexzone, Panvel, Navi Mumbai{" "}
                          <a href="/#">
                            <PlaceOutlinedIcon fontSize="2" />
                            View on map
                          </a>
                        </p>
                      </div>
                      <div className="d-flex">
                        <Tooltip
                          title="Add to Favourite"
                          slotProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, -14],
                                  },
                                },
                              ],
                            },
                          }}
                        >
                          <IconButton title="Add to Favourite">
                            {" "}
                            <FavoriteBorderIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title="Share"
                          slotProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, -14],
                                  },
                                },
                              ],
                            },
                          }}
                        >
                          <IconButton>
                            <ShareOutlinedIcon />{" "}
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                    <div className="pr-info">
                      <ul>
                        <li>
                          <ChairOutlinedIcon />
                          <h6>Unfurnished</h6>
                        </li>
                        <li>
                          <BedOutlinedIcon />
                          <h6>6 BHK</h6>
                        </li>
                        <li>
                          <PeopleOutlineOutlinedIcon />
                          <h6>Family</h6>
                        </li>

                        <li>
                          <SquareFootOutlinedIcon />
                          <h6> 2000 Sq.Ft</h6>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="p-pri">
                    <div>
                      <h4>₹50,000/-</h4>
                      <p>
                        <a href="demoDetail.js">Security Deposit</a>
                      </p>
                    </div>
                    <div>
                      <p>Owner: Rupali Ganguli</p>
                      <Button variant="contained" className="btn-p-owner">
                        Contact Owner
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="p-card">
                  <div className="p-img">
                    <img src={PropertyImg} alt="Property" />
                  </div>
                  <div className="p-info">
                    <div className="p-tle">
                      <div>
                        <h4>6 BHK Residential House For Rent</h4>
                        <p>
                          in Marathon Nexzone, Panvel, Navi Mumbai{" "}
                          <a href="/#">
                            <PlaceOutlinedIcon fontSize="2" />
                            View on map
                          </a>
                        </p>
                      </div>
                      <div className="d-flex">
                        <Tooltip
                          title="Add to Favourite"
                          slotProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, -14],
                                  },
                                },
                              ],
                            },
                          }}
                        >
                          <IconButton title="Add to Favourite">
                            {" "}
                            <FavoriteBorderIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title="Share"
                          slotProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, -14],
                                  },
                                },
                              ],
                            },
                          }}
                        >
                          <IconButton>
                            <ShareOutlinedIcon />{" "}
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                    <div className="pr-info">
                      <ul>
                        <li>
                          <ChairOutlinedIcon />
                          <h6>Unfurnished</h6>
                        </li>
                        <li>
                          <BedOutlinedIcon />
                          <h6>6 BHK</h6>
                        </li>
                        <li>
                          <PeopleOutlineOutlinedIcon />
                          <h6>Family</h6>
                        </li>

                        <li>
                          <SquareFootOutlinedIcon />
                          <h6> 2000 Sq.Ft</h6>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="p-pri">
                    <div>
                      <h4>₹50,000/-</h4>
                      <p>
                        <a href="demoDetail.js">Security Deposit</a>
                      </p>
                    </div>
                    <div>
                      <p>Owner: Rupali Ganguli</p>
                      <Button variant="contained" className="btn-p-owner">
                        Contact Owner
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="p-card">
                  <div className="p-img">
                    <img src={PropertyImg} alt="Property" />
                  </div>
                  <div className="p-info">
                    <div className="p-tle">
                      <div>
                        <h4>6 BHK Residential House For Rent</h4>
                        <p>
                          in Marathon Nexzone, Panvel, Navi Mumbai{" "}
                          <a href="/#">
                            <PlaceOutlinedIcon fontSize="2" />
                            View on map
                          </a>
                        </p>
                      </div>
                      <div className="d-flex">
                        <Tooltip
                          title="Add to Favourite"
                          slotProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, -14],
                                  },
                                },
                              ],
                            },
                          }}
                        >
                          <IconButton title="Add to Favourite">
                            {" "}
                            <FavoriteBorderIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title="Share"
                          slotProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, -14],
                                  },
                                },
                              ],
                            },
                          }}
                        >
                          <IconButton>
                            <ShareOutlinedIcon />{" "}
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                    <div className="pr-info">
                      <ul>
                        <li>
                          <ChairOutlinedIcon />
                          <h6>Unfurnished</h6>
                        </li>
                        <li>
                          <BedOutlinedIcon />
                          <h6>6 BHK</h6>
                        </li>
                        <li>
                          <PeopleOutlineOutlinedIcon />
                          <h6>Family</h6>
                        </li>

                        <li>
                          <SquareFootOutlinedIcon />
                          <h6> 2000 Sq.Ft</h6>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="p-pri">
                    <div>
                      <h4>₹50,000/-</h4>
                      <p>
                        <a href="demoDetail.js">Security Deposit</a>
                      </p>
                    </div>
                    <div>
                      <p>Owner: Rupali Ganguli</p>
                      <Button variant="contained" className="btn-p-owner">
                        Contact Owner
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="p-card">
                  <div className="p-img">
                    <img src={PropertyImg} alt="Property" />
                  </div>
                  <div className="p-info">
                    <div className="p-tle">
                      <div>
                        <h4>6 BHK Residential House For Rent</h4>
                        <p>
                          in Marathon Nexzone, Panvel, Navi Mumbai{" "}
                          <a href="/#">
                            <PlaceOutlinedIcon fontSize="2" />
                            View on map
                          </a>
                        </p>
                      </div>
                      <div className="d-flex">
                        <Tooltip
                          title="Add to Favourite"
                          slotProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, -14],
                                  },
                                },
                              ],
                            },
                          }}
                        >
                          <IconButton title="Add to Favourite">
                            {" "}
                            <FavoriteBorderIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title="Share"
                          slotProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, -14],
                                  },
                                },
                              ],
                            },
                          }}
                        >
                          <IconButton>
                            <ShareOutlinedIcon />{" "}
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                    <div className="pr-info">
                      <ul>
                        <li>
                          <ChairOutlinedIcon />
                          <h6>Unfurnished</h6>
                        </li>
                        <li>
                          <BedOutlinedIcon />
                          <h6>6 BHK</h6>
                        </li>
                        <li>
                          <PeopleOutlineOutlinedIcon />
                          <h6>Family</h6>
                        </li>

                        <li>
                          <SquareFootOutlinedIcon />
                          <h6> 2000 Sq.Ft</h6>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="p-pri">
                    <div>
                      <h4>₹50,000/-</h4>
                      <p>
                        <a href="demoDetail.js">Security Deposit</a>
                      </p>
                    </div>
                    <div>
                      <p>Owner: Rupali Ganguli</p>
                      <Button variant="contained" className="btn-p-owner">
                        Contact Owner
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="p-card">
                  <div className="p-img">
                    <img src={PropertyImg} alt="Property" />
                  </div>
                  <div className="p-info">
                    <div className="p-tle">
                      <div>
                        <h4>6 BHK Residential House For Rent</h4>
                        <p>
                          in Marathon Nexzone, Panvel, Navi Mumbai{" "}
                          <a href="/#">
                            <PlaceOutlinedIcon fontSize="2" />
                            View on map
                          </a>
                        </p>
                      </div>
                      <div className="d-flex">
                        <Tooltip
                          title="Add to Favourite"
                          slotProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, -14],
                                  },
                                },
                              ],
                            },
                          }}
                        >
                          <IconButton title="Add to Favourite">
                            {" "}
                            <FavoriteBorderIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title="Share"
                          slotProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, -14],
                                  },
                                },
                              ],
                            },
                          }}
                        >
                          <IconButton>
                            <ShareOutlinedIcon />{" "}
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                    <div className="pr-info">
                      <ul>
                        <li>
                          <ChairOutlinedIcon />
                          <h6>Unfurnished</h6>
                        </li>
                        <li>
                          <BedOutlinedIcon />
                          <h6>6 BHK</h6>
                        </li>
                        <li>
                          <PeopleOutlineOutlinedIcon />
                          <h6>Family</h6>
                        </li>

                        <li>
                          <SquareFootOutlinedIcon />
                          <h6> 2000 Sq.Ft</h6>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="p-pri">
                    <div>
                      <h4>₹50,000/-</h4>
                      <p>
                        <a href="demoDetail.js">Security Deposit</a>
                      </p>
                    </div>
                    <div>
                      <p>Owner: Rupali Ganguli</p>
                      <Button variant="contained" className="btn-p-owner">
                        Contact Owner
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="p-card">
                  <div className="p-img">
                    <img src={PropertyImg} alt="Property" />
                  </div>
                  <div className="p-info">
                    <div className="p-tle">
                      <div>
                        <h4>6 BHK Residential House For Rent</h4>
                        <p>
                          in Marathon Nexzone, Panvel, Navi Mumbai{" "}
                          <a href="/#">
                            <PlaceOutlinedIcon fontSize="2" />
                            View on map
                          </a>
                        </p>
                      </div>
                      <div className="d-flex">
                        <Tooltip
                          title="Add to Favourite"
                          slotProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, -14],
                                  },
                                },
                              ],
                            },
                          }}
                        >
                          <IconButton title="Add to Favourite">
                            {" "}
                            <FavoriteBorderIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title="Share"
                          slotProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, -14],
                                  },
                                },
                              ],
                            },
                          }}
                        >
                          <IconButton>
                            <ShareOutlinedIcon />{" "}
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                    <div className="pr-info">
                      <ul>
                        <li>
                          <ChairOutlinedIcon />
                          <h6>Unfurnished</h6>
                        </li>
                        <li>
                          <BedOutlinedIcon />
                          <h6>6 BHK</h6>
                        </li>
                        <li>
                          <PeopleOutlineOutlinedIcon />
                          <h6>Family</h6>
                        </li>

                        <li>
                          <SquareFootOutlinedIcon />
                          <h6> 2000 Sq.Ft</h6>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="p-pri">
                    <div>
                      <h4>₹50,000/-</h4>
                      <p>
                        <a href="demoDetail.js">Security Deposit</a>
                      </p>
                    </div>
                    <div>
                      <p>Owner: Rupali Ganguli</p>
                      <Button variant="contained" className="btn-p-owner">
                        Contact Owner
                      </Button>
                    </div>
                  </div>
                </div>

                <div className="p-card">
                  <div className="p-img">
                    <img src={PropertyImg} alt="Property" />
                  </div>
                  <div className="p-info">
                    <div className="p-tle">
                      <div>
                        <h4>6 BHK Residential House For Rent</h4>
                        <p>
                          in Marathon Nexzone, Panvel, Navi Mumbai{" "}
                          <a href="/#">
                            <PlaceOutlinedIcon fontSize="2" />
                            View on map
                          </a>
                        </p>
                      </div>
                      <div className="d-flex">
                        <Tooltip
                          title="Add to Favourite"
                          slotProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, -14],
                                  },
                                },
                              ],
                            },
                          }}
                        >
                          <IconButton title="Add to Favourite">
                            {" "}
                            <FavoriteBorderIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title="Share"
                          slotProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, -14],
                                  },
                                },
                              ],
                            },
                          }}
                        >
                          <IconButton>
                            <ShareOutlinedIcon />{" "}
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                    <div className="pr-info">
                      <ul>
                        <li>
                          <ChairOutlinedIcon />
                          <h6>Unfurnished</h6>
                        </li>
                        <li>
                          <BedOutlinedIcon />
                          <h6>6 BHK</h6>
                        </li>
                        <li>
                          <PeopleOutlineOutlinedIcon />
                          <h6>Family</h6>
                        </li>

                        <li>
                          <SquareFootOutlinedIcon />
                          <h6> 2000 Sq.Ft</h6>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="p-pri">
                    <div>
                      <h4>₹50,000/-</h4>
                      <p>
                        <a href="demoDetail.js">Security Deposit</a>
                      </p>
                    </div>
                    <div>
                      <p>Owner: Rupali Ganguli</p>
                      <Button variant="contained" className="btn-p-owner">
                        Contact Owner
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="p-card">
                  <div className="p-img">
                    <img src={PropertyImg} alt="Property" />
                  </div>
                  <div className="p-info">
                    <div className="p-tle">
                      <div>
                        <h4>6 BHK Residential House For Rent</h4>
                        <p>
                          in Marathon Nexzone, Panvel, Navi Mumbai{" "}
                          <a href="/#">
                            <PlaceOutlinedIcon fontSize="2" />
                            View on map
                          </a>
                        </p>
                      </div>
                      <div className="d-flex">
                        <Tooltip
                          title="Add to Favourite"
                          slotProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, -14],
                                  },
                                },
                              ],
                            },
                          }}
                        >
                          <IconButton title="Add to Favourite">
                            {" "}
                            <FavoriteBorderIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title="Share"
                          slotProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, -14],
                                  },
                                },
                              ],
                            },
                          }}
                        >
                          <IconButton>
                            <ShareOutlinedIcon />{" "}
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                    <div className="pr-info">
                      <ul>
                        <li>
                          <ChairOutlinedIcon />
                          <h6>Unfurnished</h6>
                        </li>
                        <li>
                          <BedOutlinedIcon />
                          <h6>6 BHK</h6>
                        </li>
                        <li>
                          <PeopleOutlineOutlinedIcon />
                          <h6>Family</h6>
                        </li>

                        <li>
                          <SquareFootOutlinedIcon />
                          <h6> 2000 Sq.Ft</h6>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="p-pri">
                    <div>
                      <h4>₹50,000/-</h4>
                      <p>
                        <a href="demoDetail.js">Security Deposit</a>
                      </p>
                    </div>
                    <div>
                      <p>Owner: Rupali Ganguli</p>
                      <Button variant="contained" className="btn-p-owner">
                        Contact Owner
                      </Button>
                    </div>
                  </div>
                </div>

                <div className="p-card">
                  <div className="p-img">
                    <img src={PropertyImg} alt="Property" />
                  </div>
                  <div className="p-info">
                    <div className="p-tle">
                      <div>
                        <h4>6 BHK Residential House For Rent</h4>
                        <p>
                          in Marathon Nexzone, Panvel, Navi Mumbai{" "}
                          <a href="/#">
                            <PlaceOutlinedIcon fontSize="2" />
                            View on map
                          </a>
                        </p>
                      </div>
                      <div className="d-flex">
                        <Tooltip
                          title="Add to Favourite"
                          slotProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, -14],
                                  },
                                },
                              ],
                            },
                          }}
                        >
                          <IconButton title="Add to Favourite">
                            {" "}
                            <FavoriteBorderIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title="Share"
                          slotProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, -14],
                                  },
                                },
                              ],
                            },
                          }}
                        >
                          <IconButton>
                            <ShareOutlinedIcon />{" "}
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                    <div className="pr-info">
                      <ul>
                        <li>
                          <ChairOutlinedIcon />
                          <h6>Unfurnished</h6>
                        </li>
                        <li>
                          <BedOutlinedIcon />
                          <h6>6 BHK</h6>
                        </li>
                        <li>
                          <PeopleOutlineOutlinedIcon />
                          <h6>Family</h6>
                        </li>

                        <li>
                          <SquareFootOutlinedIcon />
                          <h6> 2000 Sq.Ft</h6>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="p-pri">
                    <div>
                      <h4>₹50,000/-</h4>
                      <p>
                        <a href="demoDetail.js">Security Deposit</a>
                      </p>
                    </div>
                    <div>
                      <p>Owner: Rupali Ganguli</p>
                      <Button variant="contained" className="btn-p-owner">
                        Contact Owner
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />

      {/* contact agent modal */}
      <Modal
        show={show}
        onHide={() => handleClose()}
        disableBackdropClick={false}
        disableEscapeKeyDown={false}
        centered
      >
        <Modal.Header closeButton className="headerBlue">
          <Modal.Title
            className=" text-center loginTitle"
            style={{ margin: "0 auto" }}
          >
            <h5 class="m-0">Fill out this one-time form</h5>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p className="text-gray-700 mb-4 text-center">
            Get Agent's details over email
          </p>
          <div className="d-flex justify-content-center align-items-center">
            <form>
              <div className="mb-4">
                <TextField
                  id="name"
                  label="Your Name"
                  variant="standard"
                  fullWidth
                  value={useContactDetails.Name}
                  name="Name"
                  onChange={handleChange}
                  // helperText={error.Name !== "" ? error.Name : null}
                  // error={error.Name !== "" ? true : false}
                />
              </div>
              <div className="mb-4">
                <TextField
                  id="email"
                  label="Email"
                  variant="standard"
                  type="email"
                  name="email"
                  onChange={handleChange}
                  value={useContactDetails.email}
                  fullWidth
                  // helperText={error.email !== "" ? error.email : null}
                  // error={error.email !== "" ? true : false}
                />
              </div>
              <div className="mb-4">
                <div className="flex">
                  <TextField
                    color="grey"
                    id="standard-select-currency-native"
                    select
                    label="Country"
                    //   defaultValue="EUR"
                    SelectProps={{
                      native: true,
                    }}
                    variant="standard"
                  >
                    {currencies.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                  <TextField
                    id="standard-basic"
                    label="Mobile Number"
                    variant="standard"
                    color="grey"
                    name="Phone"
                    value={useContactDetails.Phone}
                    onChange={handleChange}
                    // helperText={error.Phone !== "" ? error.Phone : null}
                    // error={error.Phone !== "" ? true : false}
                  />
                </div>
              </div>
              <div className="mb-6">
                <label className="inline-flex items-center">
                <Checkbox
                    color="primary"
                    name="terms"
                    checked={useContactDetails.terms}
                    onChange={handleChangeChecbox}
                  />
                  <span className="ml-2">
                    I Agree to EquiReal'{" "}
                    <a href="#" className="text-indigo-200 underline">
                      Terms of Use
                    </a>
                  </span>
                </label>
              </div>
              <div className="flex items-center justify-center mt-2">
                <Button
                  variant="contained"
                  color="error"
                  type="submit"
                  fullWidth
                  onClick={submitContactDetails}
                >
                  Get Contact Details
                </Button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default DemoAll;
