import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Badge from 'react-bootstrap/Badge';


const reviews = () => {
    return (
        <div>
            <h4>
                Ratings & Reviews

            </h4>
            <p>of Marathon Nexzone, Panvel, Navi Mumbai</p>
            <Container >
                <Row>
                    <Col lg={3} sm={12} className='text-center '>

                        <Stack spacing={1} alignItems={'center'} className='ratings'>
                            <h2>4.0/5</h2>
                            <Rating name="half-rating-read" defaultValue={4} precision={0.5} readOnly />
                            <h6>
                                Average Rating</h6>
                            <p>(6 Total Reviews)</p>

 
                        </Stack>
                        <div className='my-2'>
                            <div className='d-flex'><ProgressBar now={100} />5</div>
                            <div className='d-flex'> <ProgressBar now={60} />4</div>
                            <div className='d-flex'><ProgressBar now={80} />3</div>
                            <div className='d-flex'><ProgressBar now={5} />2</div>
                            <div className='d-flex'> <ProgressBar now={10} />1</div>
                        </div>
                    </Col>

                    <Col lg={9} sm={12} className='px-5'>
                        Popular Mentions
                        <div className='d-flex'><ProgressBar now={100} />100% Positive Mentions</div>
                        <Stack direction="horizontal" gap={1} flexWrap={'wrap'} marginTop={3}>
                            <Badge pill>
                                No Power Cuts (5)
                            </Badge>
                            <Badge pill >
                                No Water Shortage (4)
                            </Badge>

                            <Badge pill >
                                Easy Cab/Auto Availability (4)
                            </Badge>
                            <Badge pill >
                                Markets at a walkable distance (4)
                            </Badge>
                            <Badge pill >
                                Good Schools are nearby (4)
                            </Badge>

                            <Badge pill >
                                Good Hospitals are nearby (4)
                            </Badge>

                        </Stack>






                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default reviews