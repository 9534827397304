import React from 'react'
import { Link } from 'react-router-dom'
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import XIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
const Footer = () => {
  return (
    <div>
         <div id="contact" className="content-box">
        <div
            className="container wow animated fadeIn"
            data-wow-duration="1s"
            data-wow-delay=".5s"
        >
            <div className="row">
                <div className="col-md-2  col-sm-12">
                    <div className="equireal_logo">
                        <img
                            src="assets/images/equireal-logo.png"
                            className="img-fluid"
                            alt=""
                        />
                    </div>
                    <div className="social-media">
                        <div className="content-title-footer">
                            Follow Us
                            <hr className="content-title-underline-footer" />
                            
                            <ul>
                                <li>
                                    <Link to={""}>
                                        <FacebookOutlinedIcon/>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={""}>
                                        <XIcon/>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={""}>
                                       <LinkedInIcon/>
                                    </Link>
                                </li>
                                
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-sm-12">
                    <div className="footer-links">
                        <div className="content-title-footer footer-divider">
                            Company
                            <hr className="content-title-underline-footer " />
                            <ul className="content-footer">
                                <li>
                                    <Link to={""}>About Us</Link>
                                </li>
                                <li>
                                    <Link to={""}>Careers</Link>
                                </li>
                                <li>
                                    {" "}
                                    <Link to={""}>Services</Link>
                                </li>
                                <li>
                                    {" "}
                                    <Link to={""}>Contact Us</Link>
                                </li>
                                <li>
                                    <Link to={""}>Terms & Conditions</Link>
                                </li>
                                <li>
                                    {" "}
                                    <Link to={""}>Privacy Policy</Link>
                                </li>
                                <li>
                                    {" "}
                                    <Link to={""}>Sitemap</Link>
                                </li>
                                <li>
                                    {" "}
                                    <Link to={""}>Blog</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="content-title-footer">
                            Services
                            <hr className="content-title-underline-footer" />
                            <ul className="content-footer text-dark">
                                <li>
                                    <Link to={""}>Due Diligence</Link>
                                </li>
                                <li>
                                    <Link to={""}>Leave & License Agreement</Link>
                                </li>
                                <li>
                                    <Link to={""}>EMI Calculator</Link>
                                </li>
                                <li>
                                    <Link to={""}>Interiors</Link>
                                </li>
                                <li>
                                    <Link to={""}>Painting</Link>
                                </li>
                                <li>
                                    <Link to={""}>Electric Wiring</Link>
                                </li>
                                <li>
                                    <Link to={""}>Plumbing</Link>
                                </li>
                                <li>
                                    <Link to={""}>Pay Rent</Link>
                                </li>
                                <li>
                                    <Link to={""}>Book Property Online</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-md-4  col-sm-12">
                    <div className="content-box-grey">
                        <h4>Newsletter</h4>
                        <p>Signup for Newsletter</p>
                        <input
                            type="email"
                            value="Email ID"
                            className="textfield-dark"
                        />
                        <br />
                        <input type="button" value="Sign up" className="btn-dark " />
                    </div>
                    <div className="content-box-grey">
                        <h4>Browse on the go</h4>
                        <p>Download our app and browse for more options on the go.</p>
                        <div className="apps">
                            <Link to={""}>
                                <img
                                    src="assets/images/googleplay.png"
                                    className="img-fluid"
                                    alt=""
                                />
                            </Link>
                            <Link to={""}>
                                <img
                                    src="assets/images/appstore.png "
                                    className="img-fluid"
                                    alt=""
                                />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
   
</div>
  )
}

export default Footer