import React from "react";
import Topnavigation from "./Navigation/TopNavigation.js";
import Footer from "./Layouts/Footer.js";
import { Container, Row, Col } from "react-bootstrap";
import { Button, Grid } from "@mui/material";
import "react-slideshow-image/dist/styles.css";
import { emphasize, styled } from "@mui/material/styles";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Chip from "@mui/material/Chip";
import HomeIcon from "@mui/icons-material/Home";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import ImageGallery from "react-image-gallery";
import Description from "./description.js";
import Reviews from "./reviews.js";

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
}); // TypeScript only: need a type cast here because https://github.com/Microsoft/TypeScript/issues/26591

const slideImages = [
  {
    url: "https://images.unsplash.com/photo-1616486029423-aaa4789e8c9a?q=80&w=3432&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    // caption: 'Slide 1'
  },
  {
    url: "https://images.unsplash.com/photo-1484154218962-a197022b5858?q=80&w=3548&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    //caption: 'Slide 2'
  },
  {
    url: "https://images.unsplash.com/photo-1615873968403-89e068629265?q=80&w=3432&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    // caption: 'Slide 3'
  },
];

const images = [
  {
    original:
      "https://images.unsplash.com/photo-1616486029423-aaa4789e8c9a?q=80&w=3432&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    thumbnail:
      "https://images.unsplash.com/photo-1616486029423-aaa4789e8c9a?q=80&w=3432&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    original:
      "https://images.unsplash.com/photo-1484154218962-a197022b5858?q=80&w=3548&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    thumbnail:
      "https://images.unsplash.com/photo-1484154218962-a197022b5858?q=80&w=3548&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    original:
      "https://images.unsplash.com/photo-1615873968403-89e068629265?q=80&w=3432&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    thumbnail:
      "https://images.unsplash.com/photo-1615873968403-89e068629265?q=80&w=3432&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    original:
      "https://plus.unsplash.com/premium_photo-1661962952618-031d218dd040?q=80&w=3592&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    thumbnail:
      "https://plus.unsplash.com/premium_photo-1661962952618-031d218dd040?q=80&w=3592&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    original:
      "https://plus.unsplash.com/premium_photo-1661928005866-864c961775ee?q=80&w=3540&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    thumbnail:
      "https://plus.unsplash.com/premium_photo-1661928005866-864c961775ee?q=80&w=3540&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    original:
      "https://plus.unsplash.com/premium_photo-1670076720537-bde4646fa221?q=80&w=3544&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    thumbnail:
      "https://plus.unsplash.com/premium_photo-1670076720537-bde4646fa221?q=80&w=3544&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    original:
      "https://images.unsplash.com/photo-1613685302957-3a6fc45346ef?q=80&w=3432&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    thumbnail:
      "https://images.unsplash.com/photo-1613685302957-3a6fc45346ef?q=80&w=3432&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
];

const DemoDetail = () => {
  return (
    <div className="topNav-m">
      <Topnavigation />
      <div className="prop-title">
        <Container>
          <Row>
            <Col className="breadcrums">
              <Breadcrumbs aria-label="breadcrumb">
                <StyledBreadcrumb
                  component="a"
                  href="#"
                  icon={<HomeIcon fontSize="small" />}
                />
                <StyledBreadcrumb
                  component="a"
                  href="#"
                  label="Flats for Rent in Mumbai"
                />
                <StyledBreadcrumb
                  label=" 2 bhk Flat for Rent in Vashi"
                  deleteIcon={<ExpandMoreIcon />}
                />
              </Breadcrumbs>
            </Col>
          </Row>
          <Row className="pt-1">
            <Col lg={5} className="p-head">
              <h5>2 BHK Flat/Apartment For Rent</h5>
              <p>
                in Marathon Nexzone, Panvel, Navi Mumbai{" "}
                <a href="/#">
                  <PlaceOutlinedIcon fontSize="2" />
                  View on map
                </a>
              </p>
            </Col>
            <Col lg={7}>
              <div className="prop-title-div">
                <ul>
                  <li>
                    <p>Rent</p>
                    <h4>₹50,000/-</h4>
                  </li>
                  <li>
                    <p>Carpet Area</p>
                    <h4>500SqFt.</h4>
                  </li>
                  <li>
                    <p>Security Deposit</p>
                    <h4>₹1,00,000/-</h4>
                  </li>
                  <li>
                    <Button variant="contained" className="btn-add">
                      Contact Owner
                    </Button>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="prop-details">
        <Row className="prop-title-margin">
          <Col lg={6} md={6} sm={12}>
            <div className="img-gallery">
              {" "}
              <ImageGallery
                items={images}
                showIndex={"true"}
                autoPlay={"true"}
                className="img-fluid"
              />
            </div>
          </Col>
          <Col lg={6} md={6} sm={12}>
            <div className="basic-info">
              <ul>
                <li>
                  <p>No. of Bedroom</p>
                  <h6>2 Bedroom</h6>
                </li>
                <li>
                  <p>Property Type</p>
                  <h6>Flat/Apartment</h6>
                </li>
                <li>
                  <p>Preferred Tenant</p>
                  <h6>Family</h6>
                </li>
                <li>
                  <p>Possession</p>
                  <h6>Immediately</h6>
                </li>
                <li>
                  <p>Parking</p>
                  <h6>Car/Bike</h6>
                </li>
                <li>
                  <p>Age of Building</p>
                  <h6>5 - 10 years</h6>
                </li>
                <li>
                  <p>Balcony</p>
                  <h6>2 Balcony</h6>
                </li>
                <li>
                  <p>Posted on</p>
                  <h6>Mar 13, 2024</h6>
                </li>
              </ul>
            </div>
            {/* <Grid container padding={2} className='basic-info mx-2'>
                            <Grid item md={6}>
                                <p>No. of Bedroom</p>
                                <h6>2 Bedroom</h6>
                            </Grid>
                            <Grid item md={6}>
                                <p>Property Type</p>
                                <h6>Flat/Apartment</h6>
                            </Grid>
                            <Grid item md={6}>
                                <p>Preferred Tenant</p>
                                <h6>Family</h6>
                            </Grid>
                            <Grid item md={6}>
                                <p>Possession</p>
                                <h6>Immediately</h6>
                            </Grid>
                            <Grid item md={6}>
                                <p>Parking</p>
                                <h6>Car/Bike</h6>
                            </Grid>
                            <Grid item md={6}>
                                <p>Age of Building</p>
                                <h6>5 - 10 years</h6>
                            </Grid>
                            <Grid item md={6}>
                                <p>Balcony</p>
                                <h6>2 Balcony</h6>
                            </Grid>
                            <Grid item md={6}>
                                <p>Posted on</p>
                                <h6>Mar 13, 2024</h6>
                            </Grid>


                        </Grid> */}
            <div className="basic-info">
              <ul>
                <li>
                  <p>Furnishing Status</p>
                  <h4>Full-Furnished</h4>
                </li>
                <li>
                  <p>Facing</p>
                  <h4>South</h4>
                </li>
                <li>
                  <p>Water Supply</p>
                  <h4>Corporation</h4>
                </li>
                <li>
                  <p>Floor</p>
                  <h4>
                    2<sup>nd</sup> of 7 floors
                  </h4>
                </li>
                <li>
                  <p>Bathroom</p>
                  <h4>2</h4>
                </li>
                <li>
                  <p>Non-Veg Allowed</p>
                  <h4>Yes</h4>
                </li>
                <li>
                  <p>Gated Security</p>
                  <h4>No</h4>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="description">
            <h4>Description</h4>
            <Description />
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="amenities">
              <h4>Amenities</h4>
              <ul>
                <li className="active"> Power Back Up</li>
                <li className="active">1 Lift</li>
                <li className="active">Club House</li>
                <li className="active">Park</li>
                <li className="active">Reserved Parking</li>
                <li className="active">Security</li>
                <li>Vaastu Compliant</li>
                <li>No Air Conditioned</li>
                <li>No Visitor Parking</li>
                <li>Intercom Facility</li>
                <li className="active">Maintenance Staff</li>
                <li>Flower Gardens</li>
                <li>Kids Play Area</li>
                <li className="active">Fire Fighting Equipment</li>
              </ul>
            </div>
          </Col>
        </Row>

        <Reviews />
      </Container>
      <Footer />
    </div>
  );
};

export default DemoDetail;
