/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// @mui packages
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Grid from "@mui/material/Grid";
import ReactPaginate from "react-paginate";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import "../../Admin/pagination.css";
// icons
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
// custom components
import PropertyCard from "../../../Components/Common/PropertyCard";
//Api 
import {
  getCommercialProperty,
  filterByAllData,
} from "../../../Api/Commercial";

const Commercial = ({
  location,
  propertyType,
  noofSeats,
  noofCabins,
  minPrice,
  maxPrice,
  minArea,
  maxArea,
}) => {
  const navigate = useNavigate();

  const [commercialData, setCommercialData] = useState([]);
  const [allCommercialData, setAllCommercialData] = useState([]);

  const [pageNumber, setPageNumber] = useState(0);
  const itemsPerPage = 3;
  const pageCount = Math.ceil(commercialData.length / itemsPerPage);
  const offset = pageNumber * itemsPerPage;
  const currentPageData = commercialData.slice(offset, offset + itemsPerPage);

  // slider setting code
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: commercialData.length >= 3 ? 3 : commercialData.length,
    slidesToScroll: 3,
    autoplay: true,
    pauseOnHover: true,
    autoplaySpeed: 4000,
    cssEase: "linear",
    arrows: commercialData.length >= 3 ? true : false,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  // css
  const noDataMessageStyle = {
    textAlign: "center",
    padding: "20px",
    backgroundColor: "#f8d7da",
    color: "#721c24",
    border: "1px solid #f5c6cb",
    borderRadius: "5px",
    margin: "10px",
  };

  // useEffects
  useEffect(() => {
    getCommercialPropertyAll();
  }, [])

  const getCommercialPropertyAll = () => {
    getCommercialProperty()
      .then((res) => {
        if (res.data.status === 1) {
          let details = res.data.RentCommercialPropertyDetails.filter((verifiedData)=> verifiedData.isVerified === "Verified");
          setCommercialData(details);
          setAllCommercialData(details);
        } else {
          setCommercialData([]);
          setAllCommercialData([]);
        }
      })
      .catch((err) => {
        console.log(err, "error in get all commercial property.");
      });
  };

  // allproperties navigation
  const AllProperties = (data) => {
    navigate("/filterProperties", {
      state: { allPropertyData: data, propertyType: "Commercial" },
    });
  };

  const handlePageChange = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <>
      <div className='flex-grow-1'>
        <div id="feat-properties">
          <div className="container">
            <div
              className="content-box wow animated fadeIn"
              data-wow-duration=".5s"
              data-wow-delay="0s"
            >
              <div className="content-title">
                <div className=" content-name">
                  <h3>Commercial Properties</h3>

                  {/* {commercialData?.length >= 3 && (
                    <div
                      className=""
                      style={{ cursor: "pointer" }}
                      onClick={() => AllProperties(commercialData)}
                    >
                      All Properties
                      <ArrowForwardIcon />
                    </div>
                  )} */}
                </div>
                <hr className="content-title-underline" />
              </div>

              <div className="container">
                {commercialData?.length === 0 ? (
                  <p className="errormsg">No data found</p>
                ) : (
                  <>
                  <Slider {...settings}>
                    {/* <Grid container spacing={1}>
                      <Grid container item spacing={3}> */}
                        {commercialData.map((data) => (
                          <PropertyCard
                            auction={data}
                            propertyType="Commercial"
                          />
                        ))}
                      {/* </Grid>
                    </Grid> */}
                   </Slider>
                    {/* <div className="container">
                      <ReactPaginate
                        previousLabel={<NavigateBeforeIcon />}
                        nextLabel={<NavigateNextIcon />}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        onPageChange={handlePageChange}
                        containerClassName={"pagination"}
                        activeClassName={"activepagination"}
                        pageClassName={"pagination-item"}
                      />
                    </div> */}
                  </>
                )}
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Commercial;
