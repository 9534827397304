import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//bootstrap
import { Container } from "react-bootstrap";
//@mui packages
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
// icons
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import IconButton from "@mui/material/IconButton";

import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import EditIcon from "@mui/icons-material/ModeEditOutlined";
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { MdOutlineAdUnits } from "react-icons/md";
import InsertInvitationOutlinedIcon from '@mui/icons-material/InsertInvitationOutlined';
// import BedOutlinedIcon from "@mui/icons-material/BedOutlined";
// import BedIcon from '@mui/icons-material/Bed'; // Import the BedIcon
// import HomeIcon from '@mui/icons-material/Home'; // Import HomeIcon
import NightShelterIcon from '@mui/icons-material/NightShelter';

// other packages
import Swal from "sweetalert2";
import moment from "moment";
// custom components
import Loader from "../../Components/Common/Loader";
import AdminSidebar from "../../Components/Sidebar/AdminSidebar";

//pagination css
import ReactPaginate from "react-paginate";
import "../Admin/pagination.css";

//Api
import {
  getAllProjectDetails,
  deleteBuilderProject,
  getAllProjectDetailsByUserId,
} from "../../Api/BuilderProjectAPI";
//Auth
import Auth from "../../Config/helper/Auth";
import NewProjectSearchProperty from "../Admin/NewProjectSearchProperty";
import { Button, Tooltip } from "@mui/material";


import AddIcon from '@mui/icons-material/Add';
import { Grid } from "react-bootstrap-icons";

const ProjectBuilderDetails = () => {
  const navigate = useNavigate();
  let userId = Auth?.getUserDetails()._id;
  const [projectDetails, setProjectDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  //pagination
  const itemsPerPage = 10;

  // search functinality
  const filteredListData = projectDetails.filter(
    (data) =>
      data.propertyOwner.toLowerCase().includes(searchTerm.toLowerCase()) ||
      data.nameOfProject.toLowerCase().includes(searchTerm.toLowerCase()) ||
      data.Location.toLowerCase().includes(searchTerm.toLowerCase()) ||
      data.subLocation.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (data.minPrice !== undefined && data.minPrice.toString().includes(searchTerm)) ||
      (data.maxPrice !== undefined && data.maxPrice.toString().includes(searchTerm)) ||
      (new Date(data.launchDate).toLocaleDateString('en-GB') && new Date(data.launchDate).toLocaleDateString('en-GB').includes(searchTerm))
  );

  //pagination states
  const [page, setPage] = useState(0);
  const pageCount = Math.ceil(filteredListData.length / itemsPerPage);
  const offset = page * itemsPerPage;
  const currentPageData = filteredListData.slice(offset, offset + itemsPerPage);
  // useEffects
  useEffect(() => {
    GetAllProject();
  }, []);

  const GetAllProject = () => {
    setLoading(true)
    // getAllProjectDetails()
    getAllProjectDetailsByUserId(userId)
      .then((res) => {
        setLoading(false);
        if (res.data.status === 1) {
          let details = res.data.data;
          setProjectDetails(details);
        } else {
          Swal.fire({
            icon: "warning",
            title: "Data not found",
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err, "error in get all project builder details.");
      });
  };

  // table columns
  const columns = [
    { id: "id", label: "Sr. No.", minWidth: 20 },
    { id: "propertyOwner", label: "Builder Name", minWidth: 100 },
    { id: "nameOfProject", label: "Project Name", minWidth: 100 },
    { id: "Location", label: "Location", minWidth: 100 },
    { id: "subLocation", label: "subLocation", minWidth: 100 },
    { id: "minPrice", label: "Project Price(min - max)", minWidth: 100 },
    { id: "launchDate", label: "Date", minWidth: 100 },
    { id: "viewdetails", label: "View Details", minWidth: 100 },
    { id: "action", label: "Actions", minWidth: 100 },
  ];

  //Delete handleChange
  const DeleteCommProperty = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You are about to delete this record.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it",
      cancelButtonText: "No, cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteBuilderProject(id)
          .then((res) => {
            if (res.data.status === 1) {
              Swal.fire({
                icon: "success",
                title: "Project Deleted Successfully",
                text: "The project has been removed from your list.",
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK"
              });
              GetAllProject();
            } else {
              Swal.fire({
                icon: "error",
                title: "Something went wrong.",
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "ok",
              });
            }
          })
          .catch((err) => {
            console.log(err, "err in delete  property.");
          });
      }
    });
  };

  //pagination handles
  const handleChangePage = ({ selected }) => {
    setPage(selected);
  };
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // First letter caps
  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const formatCash = (n) => {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e5) return +(n / 1e3).toFixed(1) + "K";
    if (n >= 1e5 && n < 1e7) return +(n / 1e5).toFixed(1) + "L";
    if (n >= 1e7 && n < 1e9) return +(n / 1e7).toFixed(1) + "cr";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
  };

  return (
    <>
      <AdminSidebar />

      <Container className="topNav-m">
        <div className="content-title pt-5">
          <h3>Project List</h3>
          <hr class="content-title-underline"></hr>
        </div>

        <div id="builder-projectlist">

          <NewProjectSearchProperty userId={userId} />
          <div className="d-flex justify-content-end mb-3" ><Button className="add-btn" onClick={() => { navigate("/addBuilderProject") }}><AddIcon className="me-2" />Add Project</Button></div>


          {currentPageData && currentPageData.map((listData, ind) => {
            return (
              <>
                <div className="p-card-content">
                  <div className="p-card">
                    <div className="p-img">

                      {listData?.photos?.projectImages?.[0]?.name ? (
                        <img
                          src={`${listData?.photos?.projectImages[0]?.name}`}
                          alt="Property"
                          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                        />
                      ) : (
                        <img
                          src="https://api.equireal.co.in/defaultProperty.jpg"
                          alt="Property"
                          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                        />
                      )}
                    </div>

                    <div className="p-info">
                      <div className="p-tle d-flex">
                        <div>
                          <h4>{listData?.nameOfProject}</h4>
                        </div>
                        <div className="launch-date">
                          <InsertInvitationOutlinedIcon />
                          Launch Date
                          <span>{moment(listData?.launchDate).format("DD/MM/YYYY")}</span>
                        </div>
                      </div>


                      <div className="pr-info">
                        <ul>
                          <li>
                            <PeopleOutlineOutlinedIcon />
                            <h6>{listData?.propertyOwner}</h6>
                          </li>
                          <li>
                            <NightShelterIcon />
                            <h6> {listData?.areaType[0]}</h6>
                          </li>

                          <li>
                            <MdOutlineAdUnits />
                            <h6>
                              <h6> {listData?.totalUnits} units</h6>
                            </h6>
                          </li>
                          {/* <li>
                                <MdMiscellaneousServices />
                                <h6>
                                  <h6>{listData?.Amenities.join(', ')}</h6>
                                </h6>
                              </li> */}
                        </ul>
                      </div>

                      {/* <div className="d-flex justify-content-end">
                          <button
                            class="cta"
                            onClick={() =>
                              navigate(
                               
                                `/fulltab/${"Construction"}/${listData?._id}`,
                                {
                                  state: {
                                    yourData: listData,
                                    propertyType: "Construction",
                                  },
                                }
                              )
                            }
                          >
                            <span class="hover-underline-animation"> View More </span>
                            <svg
                              id="arrow-horizontal"
                              xmlns="http://www.w3.org/2000/svg"
                              width="30"
                              height="10"
                              viewBox="0 0 46 16"
                            >
                              <path
                                id="Path_10"
                                data-name="Path 10"
                                d="M8,0,6.545,1.455l5.506,5.506H-30V9.039H12.052L6.545,14.545,8,16l8-8Z"
                                transform="translate(30)"
                              ></path>
                            </svg>
                          </button>
                       
                        </div> */}
                    </div>

                    <div className="p-pri">
                      <div>
                        <h4>₹{formatCash(listData?.minPrice)}/- To ₹{formatCash(listData?.maxPrice)}/-</h4>
                        <p>
                          <p href="">Project Price</p>
                        </p>
                      </div>


                      <div>
                        <Tooltip title="View Project" arrow slotProps={{
                          popper: {
                            modifiers: [
                              {
                                name: 'offset',
                                options: {
                                  offset: [0, -14],
                                },
                              },
                            ],
                          },
                        }}>
                          <IconButton >
                            <RemoveRedEyeOutlinedIcon
                              onClick={() =>
                                navigate(`/builderProjectFulltab/${listData._id}`, {
                                  state: {
                                    projectData: listData,
                                  },
                                })
                              }
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Edit Project" arrow slotProps={{
                          popper: {
                            modifiers: [
                              {
                                name: 'offset',
                                options: {
                                  offset: [0, -14],
                                },
                              },
                            ],
                          },
                        }}>
                          <IconButton
                            onClick={() =>
                              navigate("/addBuilderProject", {
                                state: listData,
                              })
                            }
                          >
                            <ModeEditOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete Project" arrow slotProps={{
                          popper: {
                            modifiers: [
                              {
                                name: 'offset',
                                options: {
                                  offset: [0, -14],
                                },
                              },
                            ],
                          },
                        }}>
                          <IconButton
                            onClick={() => DeleteCommProperty(listData._id)}
                          >
                            <DeleteOutlineOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )
          })}
        </div>
      </Container>

    </>
  );
};

export default ProjectBuilderDetails;

{/* <div className="container topNav-m">
<div className=" content-title">
  <h3>Project Details</h3>
  <hr className="content-title-underline" />
</div>
<div className="d-flex justify-content-end mb-2">
  <input
    type="text"
    placeholder="Search"
    value={searchTerm}
    onChange={handleSearchChange}
    style={{ padding: "5px", width: "250px" }}
  />
</div>

<Paper
  sx={{
    width: "100%",
    overflow: "hidden",
    justifyContent: "center",
  }}
>
  {loading && <Loader />}
  <TableContainer sx={{ maxHeight: 700, overflowX: "auto" }}>
    <Table stickyHeader aria-label="sticky table">
      <TableHead>
        <TableRow>
          {columns.map((column) => (
            <>
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                {column.label}
              </TableCell>
            </>
          ))}
        </TableRow>
      </TableHead>

      <TableBody>
        {currentPageData.map((list, i) => {
          // const serialNumber = offset + i + 1;
          return (
            <TableRow key={i}>
              <TableCell>{i + 1}</TableCell>
              <TableCell>{capitalizeFirstLetter(list.propertyOwner)}</TableCell>
              <TableCell>{capitalizeFirstLetter(list.nameOfProject)}</TableCell>
              <TableCell>{capitalizeFirstLetter(list.Location)}</TableCell>
              <TableCell>{capitalizeFirstLetter(list.subLocation)}</TableCell>
              <TableCell>{`${list.minPrice} - ${list.maxPrice}`}</TableCell>
              <TableCell>
                {moment(list.launchDate).format("DD/MM/YYYY")}
              </TableCell>
              <TableCell>
                <IconButton>
                  <VisibilityIcon
                    color="primary"
                    onClick={() =>
                      navigate(`/builderProjectFulltab/${list._id}`, {
                        state: {
                          projectData: list,
                        },
                      })
                    }
                  />
                </IconButton>
              </TableCell>
              <TableCell>
                <IconButton>
                  <EditIcon
                    color="primary"
                    onClick={() =>
                      navigate("/addBuilderProject", {
                        state: list,
                      })
                    }
                  />
                </IconButton>

                <IconButton aria-label="settings">
                  <DeleteOutlineOutlinedIcon
                    color="error"
                    onClick={() => DeleteCommProperty(list._id)}
                  />
                </IconButton>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  </TableContainer>
  <ReactPaginate
    previousLabel={<NavigateBeforeIcon />}
    nextLabel={<NavigateNextIcon />}
    breakLabel={"..."}
    pageCount={pageCount}
    onPageChange={handleChangePage}
    containerClassName={"pagination"}
    activeClassName={"activepagination"}
    pageClassName={"pagination-item"}
  />
</Paper>
</div> */}