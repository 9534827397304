import React, { Component } from "react";
import ShowMoreText from "react-show-more-text";

class Description extends Component {
    constructor(props) {
        super(props);
        this.executeOnClick = this.executeOnClick.bind(this);
    }

    executeOnClick(isExpanded) {
        console.log(isExpanded);
    }

    render() {
        const { description } = this.props;
        return (
            <div>
                <ShowMoreText
                    /* Default options */
                    lines={3}
                    more="Show more"
                    less="Show less"
                    className="content-css"
                    anchorClass="show-more-less-clickable"
                    onClick={this.executeOnClick}
                    expanded={false}

                    truncatedEndingComponent={"... "}
                >
                    {/* <p>You get ample & dedicated space for parking of bike with this home.</p>

                    <p> If you need amenities such as security you will be happy to note that this home has this & more.
                    </p>
                    <p> If you are a frequent traveller, then you'll be happy to note that train station is less than 10 minutes from this house.
                    </p>
                    <p>
                        As Dcreight Studio, Jaas Lifestyle & Amusement Pvt Ltd & INOX Raghuleela Mall are in close proximity to this house, you can catch the latest movies at any time. If you are looking for gifts, or just want to spoil yourself, Raghuleela Mall, Saksham and Xtreme Destinations Pvt. Ltd have a wide variety of things that you can choose from.</p> */}
                {description}
                </ShowMoreText>
            </div>
        );
    }
}

export default Description;
