import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import swal from "sweetalert";
import { useNavigate, useParams } from "react-router-dom";
import { VerifyEmail } from "../../Api/AuthAPI";

const EmailVerification = () => {
  let navigate = useNavigate();
  let {token} = useParams();
  console.log(token,'searchParams...')

  const [verifyEmail, setVerifyEmail] = useState(true);
  const [data, setData] = useState({
    verifyEmail: true,
    token: "",
  });

  // useEffect(() => {
  //   const user = searchParams.get("user");
  //   setData({ ...data, token: user });
  // }, []);

  const checkEmailVerification = async (e) => {
    e.preventDefault();
    try {
      const response = await VerifyEmail(token);
      if (response.status === 200) {
        navigate("/login");
        swal("Email Verified successfully", "", "success");
        setVerifyEmail(false);
      } else {
        swal("Not verified", "", "error");
      }
    } catch (error) {
      console.error("Error occurred while verifying email:", error);
      swal("Error", "Failed to verify email", "error");
    }
  };

  return (
    <>
      <div>
        <Modal
          show={verifyEmail}
          id="verifyEmail"
          className="modal-min verifyEmailModal animate__animated animate__zoomIn"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body className="text-center">
            <i className="flaticon-secure-shield d-block" />
            <h1>Verify your email</h1>
            <form onSubmit={checkEmailVerification}>
              <button type="submit" className="btn btn-primary shadow-none">
                Click here to Verify Email
              </button>
            </form>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default EmailVerification;
