/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import { TabContext, TabList } from "@mui/lab";

import RentProperty from "./RentProperty";
import { getAllRentProperty } from "../../Api/RentpropertyAPI";
import { getCommercialProperty } from "../../Api/Commercial";
import AdminSidebar from "../../Components/Sidebar/AdminSidebar";
import { Container } from "react-bootstrap";

const AllPostProperties = () => {
  const [value, setValue] = useState("3");
  const [data, setData] = useState([]);
  const [cdata, setcdata] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    GetAllRentProperty();
  };
  useEffect(() => {
    GetAllRentProperty();
  }, [value]);

  const GetAllRentProperty = () => {
    if (value === "3") {
      getAllRentProperty()
        .then((res) => {
          if (res.data.status === 1) {
            let details = res.data.newRentProprtyDetails;
            setData(details);
          }
        })
        .catch((err) => {
          console.log(err, "error in get all rent property.");
        });
    } else {
      getCommercialProperty()
        .then((res) => {
          if (res.data.status === 1) {
            let details = res.data.RentCommercialPropertyDetails;
            setcdata(details);
          }
        })
        .catch((err) => {
          console.log(err, "error in get all commercial property.");
        });
    }
  };

  return (
    <>
      <div>
        <AdminSidebar />
      </div>

      <>
        <div className="topNav-m ">
          <Container>
            <div className=" content-title">
              <h3>Pending Properties</h3>
              <hr className="content-title-underline" />
            </div>
          </Container>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                centered
              >
                {/* <Tab label="Buy" value="1" />
              <Tab label="Sell" value="2" /> */}
                <Tab label="Residential" value="3" />
                <Tab label="Commercial" value="4" />
              </TabList>
            </Box>
            {/* <TabPanel value="1">Item Three</TabPanel>
          <TabPanel value="2">Item Two</TabPanel> */}

            <TabPanel value="3" className="d-flex justify-content-center">
              <RentProperty propertyData={data} propertyType="Residential" isVerifiedDatas="pending" GetAllRentProperty={GetAllRentProperty} />
            </TabPanel>
            <TabPanel value="4" className="d-flex justify-content-center">
              <RentProperty propertyData={cdata} propertyType="Commercial" isVerifiedDatas="pending" getCommercialProperty={GetAllRentProperty} />
            </TabPanel>
          </TabContext>
        </div>
      </>
    </>
  );
};

export default AllPostProperties;
