import React from "react";
import { Col, Row, Container } from "react-bootstrap";

//redux
//bootstrap component
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
//custom component
import EditProfile from "./EditProfile";
import Topnavigation from "../../Components/Navigation/TopNavigation";
import AdminSidebar from "../../Components/Sidebar/AdminSidebar";
import EstateList from "./ESTATE/EstateList";
import ShortlistProperties from "./ShortlistProperties";
import Auth from "../../Config/helper/Auth";
import {
  Avatar,
  Button,
  ButtonProps
} from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { styled } from '@mui/material/styles';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import profilePreview from "../../../src/Assets/user.png"
import IconButton from '@mui/material/IconButton';





const MyProfile = () => {

  let profileInfo = Auth?.getUserDetails();
  console.log(profileInfo,"profileInfo");

  return (
    <>
    <div>
        <AdminSidebar />
      </div>
      <Container  className="topNav-m  ">
      {/* <div className="d-flex gap-4 align-items-center price-card ">
          <div >
          <label htmlFor="contained-button-file">
                      <input
                        accept="image/*"
                        id="contained-button-file"
                        name="image"
                        type="file"
                        style={{ display: "none" }}
                        // onChange={onImageChange}
                      />

                      <Avatar
                        src={profileInfo?.profileImage[0]?.name ? profileInfo?.profileImage[0]?.name : profilePreview}
                        // src={profilePreview}
                        style={{
                          width: 120,
                          height: 120,
                          borderRadius: 200 / 2,
                          // marginTop: 20,
                          borderWidth: 5,
                          borderColor: "#000",
                        }}
                      ></Avatar>
                    </label>
          </div>
          <div>
          <h3>{profileInfo.fullName} </h3>
          <i>{profileInfo.role}</i>
          </div>
        </div> */}
     
     
      <Tab.Container id="left-tabs-example" defaultActiveKey="first" variant="vertical">


        <Row className="py-5 edit-profile">
          {/* <Col sm={3} className="side-tab p-4 text-center">
            <div className="p-3 text-start profile-header">
              <p className="profile-heading">Manage your Account</p>
            </div>
            <Nav variant="pills"  className="flex-column gap-4 mt-2 ">
              <Nav.Item>
                <Nav.Link eventKey="first">Edit Profile</Nav.Link>
              </Nav.Item>
              { Auth?.user?.userData?.role === "Tenant" || Auth?.user?.userData?.role === "Buyer" ?
              <Nav.Item>
                <Nav.Link eventKey="second">Shortlists</Nav.Link>
              </Nav.Item>
              :null
              }
              <Nav.Item>
                <Nav.Link eventKey="third">Owners you contacted</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="four">Payments</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="five">Properties</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="six">
                  Interested in your Properties
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col> */}
          <Col sm={9} >
         
            <Tab.Content>
              <Tab.Pane eventKey="first">
                <Container>
                  <div className="profile-header">
                    <p className="profile-heading">{profileInfo.fullName}</p>
                    <i>{profileInfo.email}</i>
                  </div>
                  <br />
                  <EditProfile />
                </Container>
              </Tab.Pane>
            </Tab.Content>
            {/* <Tab.Content>
              <Tab.Pane eventKey="second">
                <Container>
                  <div className="profile-header">
                    <p className="profile-heading">Shortlists</p>
                  </div>
                  <br />
                  { Auth?.user?.userData?.role === "Agent" || Auth?.user?.userData?.role === "Buyer" ?  <ShortlistProperties />:null}
                </Container>

              </Tab.Pane>
            </Tab.Content>
            <Tab.Content>
              <Tab.Pane eventKey="five">
                <Container>
                <div className="profile-header">
                    <p className="profile-heading">Properties</p>
                  </div>
                  <br />
                  <EstateList />
                </Container>
              </Tab.Pane>
            </Tab.Content> */}
          </Col>
          </Row>
     
      </Tab.Container>
      </Container>
    </>
  );
};

export default MyProfile;
