import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//bootstrap
import { Container } from "react-bootstrap";
//@mui packages
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
// icons
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditIcon from "@mui/icons-material/Edit";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

// other packages
import Swal from "sweetalert2";
import moment from "moment";
// custom components
import Loader from "../../Components/Common/Loader";
import AdminSidebar from "../../Components/Sidebar/AdminSidebar";

//pagination css
import ReactPaginate from "react-paginate";
import "../Admin/pagination.css";

//Api
import {
  getAllProjectDetails,
  deleteBuilderProject,
} from "../../Api/BuilderProjectAPI";
//Auth
import Auth from "../../Config/helper/Auth";

const BuilderProjectDetails = () => {
  const navigate = useNavigate();
  let userId = Auth?.getUserDetails()._id;
  const [projectDetails, setProjectDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  //pagination
  const itemsPerPage = 10;

  // search functinality
  const filteredListData = projectDetails.filter(
    (data) =>
      data.propertyOwner.toLowerCase().includes(searchTerm.toLowerCase()) ||
      data.nameOfProject.toLowerCase().includes(searchTerm.toLowerCase()) ||
      data.Location.toLowerCase().includes(searchTerm.toLowerCase()) ||
      data.subLocation.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (data.minPrice !== undefined && data.minPrice.toString().includes(searchTerm)) ||
      (data.maxPrice !== undefined && data.maxPrice.toString().includes(searchTerm)) ||
      (new Date(data.launchDate).toLocaleDateString('en-GB') && new Date(data.launchDate).toLocaleDateString('en-GB').includes(searchTerm))
  );

  //pagination states
  const [page, setPage] = useState(0);
  const pageCount = Math.ceil(filteredListData.length / itemsPerPage);
  const offset = page * itemsPerPage;
  const currentPageData = filteredListData.slice(offset, offset + itemsPerPage);
  // useEffects
  useEffect(() => {
    GetAllProject();
  }, []);

  const GetAllProject = () => {
    setLoading(true)
    // getAllProjectDetails()
    getAllProjectDetails()
      .then((res) => {
        setLoading(false);
        if (res.data.status === 1) {
          let details = res.data.data;
          setProjectDetails(details);
        } else {
          Swal.fire({
            icon: "warning",
            title: "Data not found",
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err, "error in get all project builder details.");
      });
  };

  // table columns
  const columns = [
    { id: "id", label: "Sr. No.", minWidth: 20 },
    { id: "propertyOwner", label: "Builder Name", minWidth: 100 },
    { id: "nameOfProject", label: "Project Name", minWidth: 100 },
    { id: "Location", label: "Location", minWidth: 100 },
    { id: "subLocation", label: "subLocation", minWidth: 100 },
    { id: "minPrice", label: "Project Price(min - max)", minWidth: 100 },
    { id: "launchDate", label: "Date", minWidth: 100 },
    // { id: "viewdetails", label: "View Details", minWidth: 100 },
    // { id: "action", label: "Actions", minWidth: 100 },
  ];

  //Delete handleChange
  const DeleteCommProperty = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You are about to delete this record.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it",
      cancelButtonText: "No, cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteBuilderProject(id)
          .then((res) => {
            if (res.data.status === 1) {
              Swal.fire({
                icon: "success",
                title: "property deleted successfully.",
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "ok",
              });
              GetAllProject();
            } else {
              Swal.fire({
                icon: "error",
                title: "Something went wrong.",
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "ok",
              });
            }
          })
          .catch((err) => {
            console.log(err, "err in delete  property.");
          });
      }
    });
  };

  //pagination handles
  const handleChangePage = ({ selected }) => {
    setPage(selected);
  };
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // First letter caps
  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <>
      <AdminSidebar />
      <Container>
        <div className="container topNav-m">
          <div className=" content-title">
            <h3>Project Details</h3>
            <hr className="content-title-underline" />
          </div>
          <div className="d-flex justify-content-end mb-2">
            <input
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearchChange}
              style={{ padding: "5px", width: "250px" }}
            />
          </div>

         
            {loading && <Loader />}
            <TableContainer sx={{ maxHeight: 700, overflowX: "auto" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <>
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                      </>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {currentPageData.map((list, i) => {
                    // const serialNumber = offset + i + 1;
                    return (
                      <TableRow key={i}>
                        <TableCell>{i + 1}</TableCell>
                        <TableCell>{capitalizeFirstLetter(list.propertyOwner)}</TableCell>
                        <TableCell>{capitalizeFirstLetter(list.nameOfProject)}</TableCell>
                        <TableCell>{capitalizeFirstLetter(list.Location)}</TableCell>
                        <TableCell>{capitalizeFirstLetter(list.subLocation)}</TableCell>
                        <TableCell>{`${list.minPrice} - ${list.maxPrice}`}</TableCell>
                        <TableCell>
                          {moment(list.launchDate).format("DD/MM/YYYY")}
                        </TableCell>
                        {/* <TableCell>
                          <IconButton>
                            <VisibilityIcon
                              color="primary"
                              onClick={() =>
                                navigate(`/builderProjectFulltab/${list._id}`, {
                                  state: {
                                    projectData: list,
                                  },
                                })
                              }
                            />
                          </IconButton>
                        </TableCell>
                        <TableCell>
                          <IconButton>
                            <EditIcon
                              color="primary"
                              onClick={() =>
                                navigate("/addBuilderProject", {
                                  state: list,
                                })
                              }
                            />
                          </IconButton>

                          <IconButton aria-label="settings">
                            <DeleteOutlineOutlinedIcon
                              color="error"
                              onClick={() => DeleteCommProperty(list._id)}
                            />
                          </IconButton>
                        </TableCell> */}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <ReactPaginate
              previousLabel={<NavigateBeforeIcon />}
              nextLabel={<NavigateNextIcon />}
              breakLabel={"..."}
              pageCount={pageCount}
              onPageChange={handleChangePage}
              containerClassName={"pagination"}
              activeClassName={"activepagination"}
              pageClassName={"pagination-item"}
            />
         
        </div>
      </Container>
    </>
  );
};

export default BuilderProjectDetails;