import React, { useEffect, useState } from "react";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import { getAllRentProperty } from "../../../Api/RentpropertyAPI";
import { getallproject } from "../../../Api/ProjectAPI";
import "./EstateList.css";
import { Row, Col } from "react-bootstrap";
import { settings } from "../../../Config/Settings";
import { Navigate, useNavigate } from "react-router-dom";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import SquareFootOutlinedIcon from "@mui/icons-material/SquareFootOutlined";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import ChairOutlinedIcon from "@mui/icons-material/ChairOutlined";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import CorporateFareOutlinedIcon from "@mui/icons-material/CorporateFareOutlined";
import EmojiTransportationOutlinedIcon from "@mui/icons-material/EmojiTransportationOutlined";
import { MdOutlineCabin } from "react-icons/md";
import { getyourProperties } from "../../../Api/AuthAPI";
import Auth from "../../../Config/helper/Auth";


export default function EstateList() {
  const navigate = useNavigate();
  const userData = Auth.getUserDetails();
  const [value, setValue] = useState("1");
  const [data, setData] = useState([]);
  const [propertiesData, setPropertiesData] = useState([]);

  useEffect(() => {
    GetAllProperties();
    GetAllRentProperty();
  }, []);

  const GetAllRentProperty = () => {
    getAllRentProperty()
      .then((res) => {
        if (res.data.status === 1) {
          let details = res.data.rentpropertydetails;
          setData(details);
        }
      })
      .catch((err) => {
        console.log(err, "error in get all rent property.");
      });
  };
  const GetAllProperties = () => {
    getyourProperties(userData._id)
      .then((res) => {
        if (res.data.payments.length > 0) {
          let details = res.data.payments;
          setPropertiesData(details);
        }
      })
      .catch((err) => {
        console.log(err, "error in get all rent property.");
      });
  };
  

  const handleChange = (event, newValue) => {
    if (newValue === 1) {
      GetAllProperties();
    } else if (newValue === 2) {
      GetAllRentProperty();
    } else if (newValue === 3) {
    }
    setValue(newValue);
  };
  const NumberSeperator = (input) => {
    return new Intl.NumberFormat("en-IN", {
      minimumFractionDigits: 2,
    }).format(input);
  };

  const formatCash = (n) => {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
  };

  return (
    <TabContext value={value}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <TabList onChange={handleChange} aria-label="lab API tabs example">
          <Tab label="Buy" value="1" />
          {/* <Tab label="Rent" value="2" />
          <Tab label="Sell" value="3" /> */}
        </TabList>
      </Box>
      <TabPanel value="1" className="tabarea">
        <div className="p-card-content">
          {propertiesData &&
            propertiesData.map((propertiesData, ind) => {
              let project = propertiesData.propertyData
              console.log(project,"project,,,,,,")
              return(
              <div
                className="p-card-content"
                onClick={() =>
                  navigate(`/fulltab/${project.type}/${project._id}`, {
                    state: {
                      yourData: project,
                      propertyType: project.propertyType,
                    },
                  })
                }
              >
                <div className="p-card">
                  <div className="p-img">
                    <img
                      src={`${settings.IMAGE_URL}/${project?.exteriorView[0].value}`}
                      alt=""
                      className="table-image"
                    />
                  </div>

                  <div className="p-info">
                    <div className="p-tle">
                      <div>
                        <h4>Properties</h4>
                      </div>
                    </div>

                    <div className="pr-info">
                    <ul>
                      {/* <li>
                        <ChairOutlinedIcon />
                        <h6>{project.furnishedStatus}</h6>
                      </li> */}
                      <li>
                        <MdOutlineCabin />
                        <h6> {project?.Location}</h6>
                      </li>
                      <li>
                        <PeopleOutlineOutlinedIcon />
                        <h6>Family</h6>
                      </li>

                      <li>
                        <CorporateFareOutlinedIcon />
                        <h6>
                          {" "}
                          {project?.selectFloor} of {project?.totalFloor}
                          th Floor
                        </h6>
                      </li>
                      {/* <li>
                        <EmojiTransportationOutlinedIcon />
                        <h6> {project.Availibilty}</h6>
                      </li> */}
                      <li>
                        <SquareFootOutlinedIcon />
                        <h6> {project?.areainSqft} Sq.Ft</h6>
                      </li>
                    </ul>





                        {/* <div>
                          Rent{" "}
                          <span className="price-tag">
                            ₹ {NumberSeperator(project?.Price)}/-
                          </span>
                        </div>
                        <div className="mt-1">
                          {project?.Location} , {project?.subLocation} ,{" "}
                          {project?.Area}
                        </div>
                        <p className="desc-tag">
                          {project?.Amenities.join(" , ")}
                        </p>
                        <p className="desc-tag">
                          {" "}
                          Property Age : {project?.propertyAge}
                        </p> */}
                      </div>
                  </div>
                  <div className="p-pri">
                  <div>
                    <h4>₹{formatCash(project?.PropertyRent)}/-</h4>
                    <p>Rent</p>
                  </div>

                  <div>
                    <h4>₹{formatCash(project?.SecurityDeposit)}/-</h4>
                    <p>Security Deposit</p>
                  </div>
                    </div>

                  {/* <div
                        className="d-flex justify-content-end"
                        onClick={() =>
                          Navigate(`/fulltab/${"Commercial"}/${project._id}`, {
                            state: {
                              yourData: project,
                              propertyType: "Commercial",
                            },
                          })
                        }
                      >
                        <button class="cta">
                          <span class="hover-underline-animation">
                            {" "}
                            View More{" "}
                          </span>
                          <svg
                            id="arrow-horizontal"
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            height="10"
                            viewBox="0 0 46 16"
                          >
                            <path
                              id="Path_10"
                              data-name="Path 10"
                              d="M8,0,6.545,1.455l5.506,5.506H-30V9.039H12.052L6.545,14.545,8,16l8-8Z"
                              transform="translate(30)"
                            ></path>
                          </svg>
                        </button>
                        <ArrowForwardIosTwoToneIcon />
                      </div> */}
                </div>
              </div>
            )}
          )}
        </div>
      </TabPanel>

      <TabPanel value="2" className="tabarea">
        <div className="p-card-content">
          {data &&
            data.map((rent, ind) => (
              <div className="table-row">
                <Row>
                  <div className="p-card">
                    <Col xs={12} md={3}>
                      <div className="p-img">
                        <img
                          src={`${settings.IMAGE_URL}/${rent?.propertyImg2}`}
                          alt=""
                          className="table-image"
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={9}>
                      <div className="p-info">
                        <div className="p-tle">
                          <div className="table-description pt-3">
                            <div>
                              Rent{" "}
                              <span className="price-tag">
                                ₹ {NumberSeperator(rent?.PropertyRent)}/-
                              </span>
                            </div>
                            <div className="mt-1">
                              {rent?.Location} , {rent?.subLocation} ,{" "}
                              {rent?.Area}
                            </div>
                            <p className="desc-tag">
                              {rent?.Amenities.join(" , ")}
                            </p>
                            <p className="desc-tag">
                              {" "}
                              Property Age : {rent?.propertyAge}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </div>
                </Row>
              </div>
            ))}
        </div>
      </TabPanel>

      <TabPanel value="3" className="tabarea">
        <div className="p-card-content">
          <div className="table-row">
            <Row>
              <div className="p-card">
                <Col xs={12} md={3}>
                  <div className="p-img">
                    <p>image</p>
                    {/* <img
                          src={`${settings.IMAGE_URL}/${rent?.propertyImg2}`}
                          alt=""
                          className="table-image"
                        /> */}
                  </div>
                </Col>
                <Col xs={12} md={9}>
                  <div className="p-info">
                    <div className="p-tle">
                      <div className="table-description pt-3">
                        <div>
                          Rent{" "}
                          <span className="price-tag">
                            ₹ {NumberSeperator(10)}/-
                          </span>
                        </div>
                        <div className="mt-1">
                          {/* {rent?.Location} , {rent?.subLocation} , {rent?.Area} */}
                        </div>
                        <p className="desc-tag">
                          {/* {rent?.Amenities.join(" , ")} */}
                        </p>
                        <p className="desc-tag">
                          {/* Property Age : {rent?.propertyAge} */}
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>
              </div>
            </Row>
          </div>
        </div>
      </TabPanel>
    </TabContext>
  );
}
