import React, { useState } from "react";

import { Container, Row, Col, Button, Toast, ToastContainer } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import { Link, useNavigate } from "react-router-dom";
// api calls
import { LoginUser } from "../../Api/AuthAPI";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Swal from "sweetalert2";
import Topnavigation from "../../Components/Navigation/TopNavigation";

import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const LoginNew = () => {
  const [newUser, setNewUser] = useState({
    email: "",
    password: "",
  });
  const [newUserErr, setNewUserErr] = useState({});

  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);
  const [showToast, setShowToast] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowCPassword = () => setShowCPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const validation = () => {
    const emailRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    let newUserErr = {};
    let isValid = true;

    if (newUser.email === "" || newUser.email === null) {
      newUserErr["email"] = "Please enter email!";
      isValid = false;
    } 
    
    else if (emailRegex.test(newUser.email) === false) {
      newUserErr["email"] = "please enter valid email";
      isValid = false;
    }else if(newUser.password === null || newUser.password === ""){
      newUserErr["password"] = "Please enter password!";
      isValid = false;
    }
    if(!isValid){
    setNewUserErr(newUserErr);
    setShowToast(true);
    }
    return isValid;
  };
  // handle change for form
  const handleChangeReg = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setNewUser({ ...newUser, [name]: value });
    if (name === "email" && value !== null) {
      setNewUserErr({ ...newUserErr, email: "" });
    }

    if (name === "password" && value !== null) {
      setNewUserErr({ ...newUserErr, password: "" });
    }
  };

  const handleLogin = (e) => {
    e.preventDefault();
    let data = {
      email: newUser.email,
      password: newUser.password,
    };
    const isValid = validation();
    if (isValid) {
      LoginUser(data)
        .then((res) => {
          let details = {
            userData: { ...res.data.userData },
            token: res.data.token,
          };
          // Swal.fire({
          //   icon: "success",
          //   title: "Login Successful",
          //   showConfirmButton: true,
          //   confirmButtonColor: "#3085d6",
          //   confirmButtonText: "ok",
          // });
          localStorage.setItem("User", JSON.stringify(details));
          if (
            res.data.userData.role === "Owner" ||
            res.data.userData.role === "Agent"
          ) {
            window.location.href = "/dashboard";
          } else if (res.data.userData.role === "Admin") {
            window.location.href = "/adminDashboard";
          } else if(res.data.userData.role === "Builder"){
            window.location.href = "/dashboardbuild";
          }else {
            window.location.href = "/";
          }
        })
        .catch((err) => {
          if (err && err.response) {
            Swal.fire({
              title: "Warning",
              text: err.response.data.message,
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "ok",
            });
          } else {
            console.log(err, "error in login");
          }
        });
    }
  };
  return (
    <>
      <section className="lg-bcgrnd">
        <Container>
          <Topnavigation />

          <Row>
            <Col md={5}>
              <div className="lg-card">
                <div className="">
                  
                  <p className="lg-heading">Login</p>
                </div>
                <div>
                  <TextField
                    id="standard-basic"
                    label="Enter Email ID"
                    variant="standard"
                    fullWidth
                    color="grey"
                    name="email"
                    onChange={handleChangeReg}
                    value={newUser.email}
                    autoComplete="off"
                    className="mb-3"
                  />
                  {/* <TextField
                    id="standard-basic"
                    label="Password"
                    variant="standard"
                    color="grey"
                    type="password"
                    name="password"
                    onChange={handleChangeReg}
                    value={newUser.password}
                    fullWidth
                  /> */}
                  <TextField
                    fullWidth
                    color="grey"
                    variant="standard"
                    id="password-field"
                    type={showPassword ? "text" : "password"}
                    name="password"
                    label="Enter Password"
                    onChange={handleChangeReg}
                    className="mb-4"
                    value={newUser.password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="py-4">
                  <Button
                    variant="danger"
                    type="submit"
                    onClick={handleLogin}
                    className="w-100 m-0"
                  >
                    <b>LOGIN</b>
                  </Button>
                </div>

                <ToastContainer position="start-center">
                  <Toast
                    show={showToast}
                    onClose={() => setShowToast(false)}
                    delay={3000}
                    autohide
                    className="bg-danger text-white"
                  >
                    <Toast.Header>
                      <strong className="me-auto">Validation Error</strong>
                    </Toast.Header>
                    <Toast.Body>
                      {Object.values(newUserErr).join(". ")}
                    </Toast.Body>
                  </Toast>
                </ToastContainer>

                <div className="text-center">
                  <p>
                    New to EquiReal?{" "}
                    <Link to={"/signup"} className="lg-a-link">
                      Sign Up!
                    </Link>
                  </p>
                  <p>
                    <Link to={"/forgetPassword"} className="lg-a-link">
                      Forget Password
                    </Link>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default LoginNew;
