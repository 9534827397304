import { configureStore} from '@reduxjs/toolkit';
import {persistReducer,persistStore} from "redux-persist";
import storage from 'redux-persist/lib/storage';
import reducers from './reducers/index';
import {thunk} from 'redux-thunk';
const middleware = [thunk];

const persistConfig = {
    key:'persist-store',
    whitelist:['loginreducer'],
    storage
}
const persistedReducer = persistReducer(persistConfig,reducers)

const store = configureStore({
    reducer:persistedReducer,
    middleware
})

export const persistor = persistStore(store);
export default store;