import React, { useState, useEffect } from "react";
import { deleteproperty, getallproperty } from "../../../../Api/PropertyAPI";
import { settings } from "../../../../Config/Settings";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Swal from "sweetalert2";
import "../property.css";
import AdminSidebar from "../../../../Components/Sidebar/AdminSidebar";

const PropertyDetails = () => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  useEffect(() => {
    GetAllProperty();
  }, []);
  const GetAllProperty = () => {
    getallproperty()
      .then((res) => {
        if (res.data.status === 200) {
          const responstData = JSON.parse(res.data.resultOutput);
          setData(responstData);
        }
      })
      .catch((err) => {
        console.log(err, "error in get all property.");
      });
  };

  const DeleteProperty = (rentpropertyid) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You are about to delete this record.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it",
      cancelButtonText: "No, cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteproperty(rentpropertyid)
          .then((res) => {
            if (res.data.status === 1) {
              Swal.fire({
                icon: "success",
                title: "Property deleted successfully.",
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                // cancelButtonColor: '#d33',
                confirmButtonText: "ok",
              });
              GetAllProperty();
            } else {
              Swal.fire({
                icon: "error",
                title: "Something went wrong.",
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                // cancelButtonColor: '#d33',
                confirmButtonText: "ok",
              });
            }
          })
          .catch((err) => {
            console.log(err, "err in delete rent property.");
          });
      }
    });
  };

  return (
    <>
      {/* <AdminHeader /> */}
      <AdminSidebar />
      <div className="feat-properties">
        <div className="container mt-2">
          {/* <div className="row">
          <div className="col-md-11"> */}
          <div
            className="content-box wow animated fadeIn"
            data-wow-duration="1s"
            data-wow-delay="1s"
          >
            <div className="content-title">
              <div className="content-name">
                <h3>Property List</h3>
              </div>
              <hr className="content-title-underline" />
            </div>

            <div className="container">
              <div className="row mb-3">
                {data &&
                  data.map((list, key) => (
                    <div className="Property_Details-card">
                      <div className="property">
                        <div className="property-wrapper-home_detials">
                          <img
                            src={`${settings.IMAGE_URL}/${list.Record.propertyImg1}`}
                            //{`${settings.IMAGE_URL}/${list.propertyImg1}`}
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                        <div className="property-info ">
                          <h4 className="property-name">
                            Made for usage, commonly searched for. Fork, like
                            and use it. Just move the carousel div above the col
                            containing the text for left alignment of images
                          </h4>
                          <div className="projectlist-infodesc">
                            <div className="row p-2">
                              <div className="col-md-6">
                                <span className="propertyInfoTitle">Price</span>
                              </div>
                              <div className="col-md-6">
                                <h6 className="property-price_details">
                                  &#x20B9;
                                  {/* {"" + list.Price} */}
                                  {"" + list.Record.Price}
                                </h6>
                              </div>
                            </div>

                            <div className="row p-2">
                              <div className="col-md-6">
                                <span className="propertyInfoTitle">Area</span>
                              </div>
                              <div className="col-md-6">
                                <p className="property-price_details">
                                  {/* {list.Area} */}
                                  {list.Record.Area}
                                </p>
                              </div>
                            </div>

                            <div className="row p-2">
                              <div className="col-md-6">
                                <span className="propertyInfoTitle">
                                  Property Type
                                </span>
                              </div>
                              <div className="col-md-6">
                                <p className="property-price_details">
                                  {list.Record.propertyType}
                                  {/* {list.propertyType} */}
                                </p>
                              </div>
                            </div>
                            <div className="row p-2">
                              <div className="col-md-6">
                                <span className="propertyInfoTitle">
                                  Property Age
                                </span>
                              </div>
                              <div className="col-md-6">
                                <p className="property-price_details">
                                  {list.Record.propertyAge}
                                  {/* {list.propertyAge} */}
                                </p>
                              </div>
                            </div>

                            <div className="row p-2">
                              <div className="col-md-6">
                                <span className="propertyInfoTitle">
                                  Location
                                </span>
                              </div>
                              <div className="col-md-6">
                                <p className="property-price_details">
                                  {list.Record.Location}
                                  {/* {list.Location} */}
                                </p>
                              </div>
                            </div>

                            <div className="row p-2">
                              <div className="col-md-6">
                                <span className="propertyInfoTitle">
                                  Sub Location
                                </span>
                              </div>
                              <div className="col-md-6">
                                <p className="property-price_details">
                                  {list.Record.subLocation}
                                  {/* {list.subLocation} */}
                                </p>
                              </div>
                            </div>

                            <div className="row p-2">
                              <div className="col-md-6">
                                <span className="propertyInfoTitle">
                                  Amenities
                                </span>
                              </div>
                              <div className="col-md-6">
                                <p className="property-price_details">
                                  {list.Record.Amenities}
                                  {/* {list.Amenities} */}
                                </p>
                              </div>
                            </div>

                            <div className="row p-2">
                              <div className="col-md-6">
                                <span className="propertyInfoTitle">
                                  Posted By
                                </span>
                              </div>
                              <div className="col-md-6">
                                <p className="property-price_details">
                                  {list.Record.postedBy}
                                  {/* {list.postedBy} */}
                                </p>
                              </div>
                            </div>
                            <div className="property-footer">
                              <Button
                                size="sm"
                                variant="secondary"
                                onClick={() =>
                                  navigate("/UpdatePropertyDetails", {
                                    state: list.Record,
                                  })
                                }
                              >
                                Edit
                              </Button>
                              <Button
                                onClick={() => DeleteProperty(list.Record.Id)}
                              >
                                Delete
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PropertyDetails;
