/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TopNavigation from "../../Components/Navigation/TopNavigation";
import Grid from "@mui/material/Grid";
import PropertyCard from "../../Components/Common/PropertyCard";
import TablePagination from "@mui/material/TablePagination";
import { MdOutlineArrowBack } from "react-icons/md";
const AllProperties = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [propertiesData, setPropertiesData] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(6);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    setPropertiesData(location.state?.properties);
  }, []);

  const noDataMessageStyle = {
    textAlign: "center",
    padding: "20px",
    backgroundColor: "#f8d7da", // Light red background color
    color: "#721c24", // Dark red text color
    border: "1px solid #f5c6cb", // Border color
    borderRadius: "5px", // Rounded corners
    margin: "10px",
  };
  return (
    <div className="flex-grow-1">
      <TopNavigation />

      <div id="feat-properties">
        <div className="container">
          <div className="">
            <MdOutlineArrowBack
              size={30}
              onClick={() => {
                navigate("/");
              }}
            />
            {/* <h5>back</h5> */}
          </div>
          <div
            className="content-box wow animated fadeIn"
            data-wow-duration=".5s"
            data-wow-delay=".0s"
          >
            <div className=" content-title">
              <h3>{location.state?.type} Properties</h3>
            </div>
            <hr className="content-title-underline" />
            <div className="container">
              {propertiesData && propertiesData.length === 0 ? (
                <p className="errormsg">No data found</p>
              ) : (
                <Grid container spacing={1}>
                  <Grid container item spacing={3}>
                    {propertiesData &&
                      propertiesData
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((data) => (
                          <PropertyCard
                            auction={data}
                            propertyType={location.state?.type}
                          />
                        ))}
                  </Grid>
                </Grid>
              )}
              <div className="d-flex justify-content-center align-content-center m-2">
                <TablePagination
                  rowsPerPageOptions={[6, 12, 18, 24]}
                  component="div"
                  count={propertiesData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllProperties;
