import React from "react";
import { Container, Row } from "react-bootstrap";
// import OwlCarousel from "react-owl-carousel";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";
import AvtarImg from "../../Assets/avtar.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import StarIcon from '@mui/icons-material/Star';

export const Testimonial = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, 
    slidesToScroll: 1,
    autoplay: true,
    pauseOnHover: true,
    autoplaySpeed: 4000,
    cssEase: "linear",
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  // const options = {
  //   margin: 30,
  //   responsiveClass: true,
  //   nav: true,
  //   dots: false,
  //   autoplay: false,
  //   navText: [
  //     "<i class='fa fa-long-arrow-left'></i>",
  //     "<i class='fa fa-long-arrow-right'></i>",
  //   ],
  //   smartSpeed: 1000,
  //   responsive: {
  //     0: {
  //       items: 1,
  //     },
  //     400: {
  //       items: 1,
  //     },
  //     600: {
  //       items: 2,
  //     },
  //     700: {
  //       items: 2,
  //     },
  //     1000: {
  //       items: 3,
  //     },
  //   },
  // };

  return (
    <div>
      <div id="testimonial">
        <div
          className="content-box  wow animated fadeIn"
          data-wow-duration="1s"
          data-wow-delay=".5s"
        >
          <Container>
            <div className="text-center">
              <h3>Testimonial</h3>
            </div>
            <Row>
              {/* <OwlCarousel
                className="owl-theme"
                loop
                margin={10}
                nav
                {...options}
              > */}
               <Slider {...settings}>
                <div class="item">
                  <div className="testimonial-cover">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore gna
                      a. Ut enim ad minim veniam,
                    </p>
                    <div className="testimonial-author">
                      <span>
                        <img src={AvtarImg} alt="" />
                      </span>
                      <h5>Ichiro Tasaka</h5>
                      <p >
                       <StarIcon className="text-warning"/>
                        <StarIcon className="text-warning"/>
                        <StarIcon className="text-warning"/>
                        <StarIcon className="text-warning"/>
                        <StarIcon className="text-warning"/>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div className="testimonial-cover">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore gna
                      a. Ut enim ad minim veniam,
                    </p>
                    <div className="testimonial-author">
                      <span>
                        <img src={AvtarImg} alt="" />
                      </span>
                      <h5>Ichiro Tasaka</h5>
                      <p>
                        <StarIcon className="text-warning"/>
                        <StarIcon className="text-warning"/>
                        <StarIcon className="text-warning"/>
                        <StarIcon className="text-warning"/>
                        <StarIcon className="text-warning"/>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div className="testimonial-cover">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore gna
                      a. Ut enim ad minim veniam,
                    </p>
                    <div className="testimonial-author">
                      <span>
                        <img src={AvtarImg} alt="" />
                      </span>
                      <h5>Ichiro Tasaka</h5>
                      <p>
                        <StarIcon className="text-warning"/>
                        <StarIcon className="text-warning"/>
                        <StarIcon className="text-warning"/>
                        <StarIcon className="text-warning"/>
                        <StarIcon className="text-warning"/>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div className="testimonial-cover">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore gna
                      a. Ut enim ad minim veniam,
                    </p>
                    <div className="testimonial-author">
                      <span>
                        <img src={AvtarImg} alt="" />
                      </span>
                      <h5>Ichiro Tasaka</h5>
                      <p>
                        <StarIcon className="text-warning"/>
                        <StarIcon className="text-warning"/>
                        <StarIcon className="text-warning"/>
                        <StarIcon className="text-warning"/>
                        <StarIcon className="text-warning"/>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div className="testimonial-cover">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore gna
                      a. Ut enim ad minim veniam,
                    </p>
                    <div className="testimonial-author">
                      <span>
                        <img src={AvtarImg} alt="" />
                      </span>
                      <h5>Ichiro Tasaka</h5>
                      <p>
                        <StarIcon className="text-warning"/>
                        <StarIcon className="text-warning"/>
                        <StarIcon className="text-warning"/>
                        <StarIcon className="text-warning"/>
                        <StarIcon className="text-warning"/>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div className="testimonial-cover">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore gna
                      a. Ut enim ad minim veniam,
                    </p>
                    <div className="testimonial-author">
                      <span>
                        <img src={AvtarImg} alt="" />
                      </span>
                      <h5>Ichiro Tasaka</h5>
                      <p>
                        <StarIcon className="text-warning"/>
                        <StarIcon className="text-warning"/>
                        <StarIcon className="text-warning"/>
                        <StarIcon className="text-warning"/>
                        <StarIcon className="text-warning"/>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div className="testimonial-cover">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore gna
                      a. Ut enim ad minim veniam,
                    </p>
                    <div className="testimonial-author">
                      <span>
                        <img src={AvtarImg} alt="" />
                      </span>
                      <h5>Ichiro Tasaka</h5>
                      <p>
                        <StarIcon className="text-warning"/>
                        <StarIcon className="text-warning"/>
                        <StarIcon className="text-warning"/>
                        <StarIcon className="text-warning"/>
                        <StarIcon className="text-warning"/>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div className="testimonial-cover">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore gna
                      a. Ut enim ad minim veniam,
                    </p>
                    <div className="testimonial-author">
                      <span>
                        <img src={AvtarImg} alt="" />
                      </span>
                      <h5>Ichiro Tasaka</h5>
                      <p>
                        <StarIcon className="text-warning"/>
                        <StarIcon className="text-warning"/>
                        <StarIcon className="text-warning"/>
                        <StarIcon className="text-warning"/>
                        <StarIcon className="text-warning"/>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div className="testimonial-cover">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore gna
                      a. Ut enim ad minim veniam,
                    </p>
                    <div className="testimonial-author">
                      <span>
                        <img src={AvtarImg} alt="" />
                      </span>
                      <h5>Ichiro Tasaka</h5>
                      <p>
                        <StarIcon className="text-warning"/>
                        <StarIcon className="text-warning"/>
                        <StarIcon className="text-warning"/>
                        <StarIcon className="text-warning"/>
                        <StarIcon className="text-warning"/>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div className="testimonial-cover">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore gna
                      a. Ut enim ad minim veniam,
                    </p>
                    <div className="testimonial-author">
                      <span>
                        <img src={AvtarImg} alt="" />
                      </span>
                      <h5>Ichiro Tasaka</h5>
                      <p>
                        <StarIcon className="text-warning"/>
                        <StarIcon className="text-warning"/>
                        <StarIcon className="text-warning"/>
                        <StarIcon className="text-warning"/>
                        <StarIcon className="text-warning"/>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div className="testimonial-cover">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore gna
                      a. Ut enim ad minim veniam,
                    </p>
                    <div className="testimonial-author">
                      <span>
                        <img src={AvtarImg} alt="" />
                      </span>
                      <h5>Ichiro Tasaka</h5>
                      <p>
                        <StarIcon className="text-warning"/>
                        <StarIcon className="text-warning"/>
                        <StarIcon className="text-warning"/>
                        <StarIcon className="text-warning"/>
                        <StarIcon className="text-warning"/>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div className="testimonial-cover">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore gna
                      a. Ut enim ad minim veniam,
                    </p>
                    <div className="testimonial-author">
                      <span>
                        <img src={AvtarImg} alt="" />
                      </span>
                      <h5>Ichiro Tasaka</h5>
                      <p>
                        <StarIcon className="text-warning"/>
                        <StarIcon className="text-warning"/>
                        <StarIcon className="text-warning"/>
                        <StarIcon className="text-warning"/>
                        <StarIcon className="text-warning"/>
                      </p>
                    </div>
                  </div>
                </div>
                </Slider>
              {/* </OwlCarousel> */}
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};
