import React, { useEffect, useState } from "react";
//@mui packages
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import ReactPaginate from "react-paginate";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Modal } from "react-bootstrap";
import swal from "sweetalert";
import moment from "moment";
//icons
import { MdDoubleArrow } from "react-icons/md";
import { MdOutlineKeyboardDoubleArrowLeft } from "react-icons/md";
// custom components
import AdminSidebar from "../../Components/Sidebar/AdminSidebar";
import Loader from "../../Components/Common/Loader";
// api
import { blockReviewList, updateReviewStatus } from "../../Api/ReviewAPI";


const BlockRequest = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedValues, setSelectedValues] = useState({});
  const [data, setData] = useState([]);
  const [expandedComments, setExpandedComments] = useState({});
  const [loading, setLoading] = useState(false);
  const [viewReviewModel, setViewReviewModel] = useState(false);
  const [reviewDetails, setReviewDetails] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [pageNumber, setPageNumber] = useState(0);
  const itemsPerPage = 5;

  const filteredData = data.filter(
    (item) =>
      item.userName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.ownerdetails.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.PropertyId.toLowerCase().includes(searchTerm.toLowerCase()) ||
      moment(item.createdAt)
        .format("DD-MM-YYYY HH:mm:ss")
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      item.blockComment.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.requestForBlock.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const pageCount = Math.ceil(data.length / itemsPerPage);
  const offset = pageNumber * itemsPerPage;
  const currentPageData = filteredData.slice(offset, offset + itemsPerPage);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const columns = [
    { id: "id", label: "Sr. No.", minWidth: 20 },
    { id: "userId", label: "User Name", minWidth: 100 },
    { id: "ownerId", label: "Agent/Owner", minWidth: 100 },
    { id: "createdAt", label: "Date & Time", minWidth: 100 },

    {
      id: "blockComment",
      label: "review comment",
      minWidth: 100,
    },

    {
      id: "requestForBlock",
      label: "Status",
      minWidth: 100,
    },

    {
      id: "requestForBlock",
      label: "Action",
      minWidth: 100,
    },

    { id: "PropertyId", label: "PropertyId", minWidth: 100 },

  ];
  useEffect(() => {
    getAllReviewBlockList();
  }, []);

  const getAllReviewBlockList = () => {
    blockReviewList()
      .then((res) => {
        if (res.data.status === 1) {
          let result = res.data.data;
          setData(result);
          const initialSelectedValues = {};
          result.forEach((item, index) => {
            initialSelectedValues[index] = item.requestForBlock;
          });
          setSelectedValues(initialSelectedValues);
        } else {
          swal("data not found");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePageChange = ({ selected }) => {
    setPageNumber(selected);
  };

  const verificationStatus = [
    { value: "Block", label: "Reject" },
    { value: "Unblock", label: "Accept" },
  ];

  const updateStatus = async (userID, status, i) => {
    let updateData = {
      id: userID,
      requestForBlock: status,
    };
    setLoading(true);
    updateReviewStatus(updateData)
      .then((res) => {
        if (res.data.status === 1) {
          let newdata = [...data];
          newdata[i]["requestForBlock"] = status;
          setData(newdata);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err, "error in update review status");
      });
  };

  const handleToggleExpand = (index) => {
    setExpandedComments((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const OnView = (listData) => {
    setViewReviewModel(true);
    setReviewDetails(listData);
  };

  const handleClose = () => {
    setViewReviewModel(false);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div>
      <AdminSidebar />
      <div className="topNav-m">
        <div className="container mb-4">
          <div className=" content-title">
            <h3>Review Requests</h3>
            <hr className="content-title-underline" />
          </div>
          <div className="d-flex justify-content-end mb-2">
            <input
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearchChange}
              style={{ padding: "5px", width: "250px" }}
            />
          </div>

          <Paper
            sx={{
              width: "100%",
              overflow: "hidden",
              justifyContent: "center",
            }}
          >
            {loading && <Loader />}
            <TableContainer sx={{ maxHeight: 700, overflowX: "auto" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <>
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                      </>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {currentPageData?.length > 0 ?
                   currentPageData.map((list, i) => {
                    const serialNumber = offset + i + 1;
                    return (
                      <TableRow key={i}>
                        <TableCell>{serialNumber}</TableCell>
                        <TableCell>{list.userName}</TableCell>
                        <TableCell>{list.ownerdetails}</TableCell>
                        <TableCell>
                          {moment(list.createdAt).format("DD-MM-YYYY")} Time:
                          {moment(list.createdAt).format("HH:mm:ss")}
                        </TableCell>
                        
                        <TableCell>
                          {expandedComments[i]
                            ? list.blockComment
                            : `${list.blockComment.substring(0, 50)}`}
                          {list.blockComment.length > 50 && (
                            <a
                              href="#!"
                              onClick={() => handleToggleExpand(i)}
                              style={{
                                marginLeft: "5px",
                                color: "blue",
                                cursor: "pointer",
                              }}
                            >
                              {expandedComments[i] ? (
                                <MdOutlineKeyboardDoubleArrowLeft
                                  fontSize={18}
                                  fontWeight={700}
                                />
                              ) : (
                                <MdDoubleArrow fontSize={18} />
                              )}
                            </a>
                          )}
                        </TableCell>

                        <TableCell>{list?.requestForBlock}</TableCell>

                        <TableCell>
                          <div className="d-flex gap-2">
                            {verificationStatus.map((option, index) => (
                              <div class="containerblockedradio" key={index}>
                                <form>
                                  <label className={option.value === 'Block' ? 'blocklabel' : 'unblocklabel'}>
                                    <input type="radio" name="radio"
                                      checked={
                                        option.value === list.requestForBlock
                                      }
                                      onChange={(e) =>
                                        updateStatus(list._id, option.value, i)
                                      } />
                                    <span>{option.label}</span>
                                  </label>

                                </form>
                              </div>
                             
                            ))}
                          </div>

                        </TableCell>
                        <TableCell
                          onClick={() => OnView(list)}
                          className="tdlink"
                        >{list.PropertyId}
                        </TableCell>
                       
                      </TableRow>
                    );
                  })
                :
                <TableRow>
                  Data not found
                </TableRow>
                }
                </TableBody>
              </Table>
              <div className="container">
                <ReactPaginate
                  previousLabel={<NavigateBeforeIcon />}
                  nextLabel={<NavigateNextIcon />}
                  breakLabel={"..."}
                  pageCount={pageCount}
                  onPageChange={handlePageChange}
                  containerClassName={"pagination"}
                  activeClassName={"activepagination"}
                  pageClassName={"pagination-item"}
                />
              </div>
            </TableContainer>
          </Paper>
        </div>
      </div>
      {reviewDetails && (
        <Modal show={viewReviewModel} onHide={handleClose} centered className="modal-lg ">
          <Modal.Header closeButton className="headerBlue">
            <Modal.Title
              className="loginTitle"
            >
              <h5 className="m-0">Property Details</h5>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="pl-5 pr-5  pb-4 d-flex">
              <>
                {/* <div className="enq-img">
                  <img
                    src={`${reviewDetails?.propertyData?.exteriorView ? reviewDetails?.propertyData?.exteriorView[0]?.name:reviewDetails?.propertyData?.exteriorView?.name}`}
                    alt="Property"
                    className="img-fluid"
                  />
                </div> */}
                <div className="enq-img">
                  {reviewDetails?.propertyData?.exteriorView ?
                    <img
                      src={`${reviewDetails?.propertyData?.exteriorView[0]?.name}`}
                      alt="Property11"
                      className="img-fluid"
                    />
                    :
                    <img
                      src={`${reviewDetails?.propertyData?.photos[0]?.projectImages?.length > 0 ? reviewDetails?.propertyData?.photos[0]?.projectImages[0]?.name : "image not available"}`}
                      alt="Property" className="img-fluid"
                    />
                  }
                </div>
                <div className="modal-enquiry-details">
                  <div className="transactionData">
                    <div className="transactionDataTitle">PropertyId : </div>
                    <div className="transactionDataValue">
                      {reviewDetails?.propertyData?._id}
                    </div>
                  </div>
                  {reviewDetails?.propertyData?.propertyType ?
                    <div className="transactionData ">
                      <div className="transactionDataTitle">PropertyType : </div>
                      <div className="transactionDataValue">
                        {reviewDetails?.propertyData?.propertyType}
                      </div>
                    </div>
                    :
                    <div className="transactionData  ">
                      <div className="transactionDataTitle">AreaType : </div>
                      <div className="transactionDataValue">
                        {reviewDetails?.propertyData?.areaType.join(",")}
                      </div>
                    </div>
                  }
                  <div className="transactionData">
                    <div className="transactionDataTitle">Location : </div>
                    <div className="transactionDataValue">
                      {reviewDetails?.propertyData?.Location}
                    </div>
                  </div>
                  <div className="transactionData ">
                    <div className="transactionDataTitle">SubLocation : </div>
                    <div className="transactionDataValue">
                      {reviewDetails?.propertyData?.subLocation}
                    </div>
                  </div>

                  {reviewDetails?.propertyData?.purposeOfProperty ? (
                    <>
                      <div className="transactionData">
                        <div className="transactionDataTitle">PurposeOfProperty : </div>
                        <div className="transactionDataValue">
                          {reviewDetails?.propertyData?.purposeOfProperty}
                        </div>
                      </div>
                      {reviewDetails?.propertyData?.purposeOfProperty === "Buy" ?
                        <div>
                          <div className="transactionData">
                            <div className="transactionDataTitle">BookingAmount : </div>
                            <div className="transactionDataValue">
                              {reviewDetails?.propertyData?.bookingAmount}
                            </div>
                          </div>
                          <div className="transactionData">
                            <div className="transactionDataTitle">PropertyCost : </div>
                            <div className="transactionDataValue">
                              {reviewDetails?.propertyData?.propertyCost}
                            </div>
                          </div>
                        </div>
                        :
                        <div>
                          <div className="transactionData">
                            <div className="transactionDataTitle">SecurityDeposit : </div>
                            <div className="transactionDataValue">
                              {reviewDetails?.propertyData?.SecurityDeposit}
                            </div>
                          </div>
                          <div className="transactionData">
                            <div className="transactionDataTitle">PropertyRent : </div>
                            <div className="transactionDataValue">
                              {reviewDetails?.propertyData?.PropertyRent}
                            </div>
                          </div>
                        </div>
                      }
                    </>) : (
                    <div className="transactionData">
                      <div className="transactionDataTitle">PriceRange : </div>
                      <div className="transactionDataValue">
                        {`${reviewDetails?.propertyData?.minPrice} - ${reviewDetails?.propertyData?.maxPrice}`}
                      </div>
                    </div>
                  )

                  }

                  {/* <div className="transactionData">
                    <div className="transactionDataTitle">PropertyRent : </div>
                    <div className="transactionDataValue">
                      {reviewDetails?.propertyData?.PropertyRent}
                    </div>
                  </div> */}

                  {reviewDetails?.propertyData?.nameOfProject ?
                  <div className="transactionData  ">
                    <div className="transactionDataTitle">Project Name : </div>
                    <div className="transactionDataValue">
                      {reviewDetails?.propertyData?.nameOfProject}
                    </div>
                  </div>
                  : null}

                  {reviewDetails?.propertyData?.launchDate ?
                  <div className="transactionData  ">
                    <div className="transactionDataTitle">launch Date : </div>
                    <div className="transactionDataValue">
                      {moment(reviewDetails?.propertyData?.launchDate).format("DD/MM/YYYY")}
                    </div>
                  </div>
                  : null}
                  {reviewDetails?.propertyData?.PropertyStatus ?
                    <div className="transactionData ">
                      <div className="transactionDataTitle">PropertyStatus : </div>
                      <div className="transactionDataValue">
                        {reviewDetails?.propertyData?.PropertyStatus}
                      </div>
                    </div>
                    :
                    <div className="transactionData  ">
                      <div className="transactionDataTitle">totalUnits : </div>
                      <div className="transactionDataValue">
                        {reviewDetails?.propertyData?.totalUnits}
                      </div>
                    </div>
                  }

                  {reviewDetails?.propertyData?.postedBy ?
                    <div className="transactionData">
                      <div className="transactionDataTitle">postedBy : </div>
                      <div className="transactionDataValue">
                        {reviewDetails?.propertyData?.postedBy}
                      </div>
                    </div>
                    :
                    <div className="transactionData">
                      <div className="transactionDataTitle">Amenities : </div>
                      <div className="transactionDataValue">
                        {reviewDetails?.propertyData?.Amenities.join(", ")}
                      </div>
                    </div>
                  }
                </div>
              </>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default BlockRequest;
