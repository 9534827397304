import React, { useState } from "react";
import Card from "@mui/material/Card";

import {
  Button,
  TextField,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import swal from "sweetalert";
import { forgetPassword } from "../../Api/AuthAPI";
import Swal from "sweetalert2";
import { Navigate, useNavigate } from "react-router-dom";
import Topnavigation from "../../Components/Navigation/TopNavigation";
import { Col, Container, Row } from "react-bootstrap";

const customTheme = (outerTheme) =>
  createTheme({
    palette: {
      mode: outerTheme.palette.mode,
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "--TextField-brandBorderColor": "#E0E3E7",
            "--TextField-brandBorderHoverColor": "#B2BAC2",
            "--TextField-brandBorderFocusedColor": "#6F7E8C",
            "& label.Mui-focused": {
              color: "var(--TextField-brandBorderFocusedColor)",
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: "var(--TextField-brandBorderColor)",
          },
          root: {
            [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: "var(--TextField-brandBorderHoverColor)",
            },
            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: "var(--TextField-brandBorderFocusedColor)",
            },
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            "&::before, &::after": {
              borderBottom: "2px solid var(--TextField-brandBorderColor)",
            },
            "&:hover:not(.Mui-disabled, .Mui-error):before": {
              borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
            },
            "&.Mui-focused:after": {
              borderBottom:
                "2px solid var(--TextField-brandBorderFocusedColor)",
            },
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            "&::before": {
              borderBottom: "2px solid var(--TextField-brandBorderColor)",
            },
            "&:hover:not(.Mui-disabled, .Mui-error):before": {
              borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
            },
            "&.Mui-focused:after": {
              borderBottom:
                "2px solid var(--TextField-brandBorderFocusedColor)",
            },
          },
        },
      },
    },
  });

const ForgetPassword = () => {
  const navigate = useNavigate()
  const outerTheme = useTheme();
  const [email, setEmail] = useState("");
  const [formError, setFormError] = useState({ email: "" });

  const handlePasswordChange = () => {
    const emailRegex =
      /^(([^<>()[\]\.,;:\s@"]+(\.[^<>()[\]\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!email) {
      setFormError({ email: "Please enter email" });
      return;
    } else if (!emailRegex.test(email)) {
      setFormError({ email: "Please enter a valid email !" });
      return;
    }else{
      let data ={
        email:email
      };
      forgetPassword(data)
      .then((res)=>{
       if(res.status === 200){
          Swal.fire({
            icon:"success",
            title:"Email Sent Successfully. Please check!",
            showConfirmButton: true,
            confirmButtonColor:"#3085d6",
            confirmButtonText:"ok",
          })
          navigate('/login')
        }else{
          Swal.fire({
            icon:"warning",
            title:"Email not sent. Please Retry!",
            showConfirmButton: true,
            confirmButtonColor:"#3085d6",
            confirmButtonText:"ok",
          })
        }
      })
      .catch((err)=>{
         Swal.fire({
            icon:"warning",
            title:"Email not sent. Please Retry!",
            showConfirmButton: true,
            confirmButtonColor:"#3085d6",
            confirmButtonText:"ok",
          })
      })
    }
  };

  return (
    <>
    <section className="lg-bcgrnd">
    <Container>
    <Topnavigation/>
    <Row>
    <Col md={4}>
      <div className="lg-card">
        <div className="">
          <p className="lg-heading">Forget Password</p>
        </div>
    {/* <div className="d-flex justify-content-center align-content-center mt-5 align-items-center"> */}
      <div>
      {/* <Card sx={{ maxWidth: 600 }}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Forgot your password?
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Enter your email address and we'll send you a link to reset your
            password.
          </Typography>
          <div className="p-4">
            <ThemeProvider theme={customTheme(outerTheme)}> */}
            <div>
              {/* <label>Email Address</label> */}
              <TextField
                id="standard-basic"
                label='Enter Email'
                fullWidth
                color="grey"
                name='email'
                type="email"
                value={email}
                autoComplete="off"
                onChange={(e) => {
                  setEmail(e.target.value);
                  setFormError((prevErrors) => ({
                    ...prevErrors,
                    email: "",
                  }));
                }}
                error={!!formError.email}
                helperText={formError.email}
              />
            </div>
            {/* </ThemeProvider>
          </div>
        </CardContent> */}
        <CardActions>
          <Button
            fullWidth
            variant="outlined"
            type="submit"
            onClick={handlePasswordChange}
          >
            Submit
          </Button>
        </CardActions>
      {/* </Card> */}
      </div>
      </div>
    {/* </div> */}
    </Col>
    </Row>
    </Container>
    </section>
    </>
  );
};

export default ForgetPassword;
