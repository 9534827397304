import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import Auth from "../Config/helper/Auth";

const AfterLoginAdmin = () => {
  const userRole = Auth.getUserDetails();
  const token = Auth.getToken();

  return token && (userRole.role === "Owner" || userRole.role === "Agent") ? (
    <Outlet />
  ) : (
    <Navigate to="/login" />
  );
};

export default AfterLoginAdmin;
