import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";

// landing page
import HomePage from "../Pages/Landing_Page/HomePage";

// auth pages
import NewMainUser from "../Pages/Auth/NewMainUser";
import Login from "../Pages/Auth/Login";
import Register from "../Pages/Auth/RegisterUser";
import EmailVerification from "../Pages/Auth/EmailVerification";
import LoginNew from "../Pages/Auth/LoginNew";
// Owner/Agent
import Dashboard from "../Pages/OwnerAgent/Dashboard/Dashboard";

// admin
import AdminDashboard from "../Pages/Admin/Dashboard";

// property
import AddProperty from "../Pages/OwnerAgent/Property/PropertyMaster/AddProperty";
import PropertyDetails from "../Pages/OwnerAgent/Property/PropertyDetails/PropertyDetails";

//project
import AddProject from "../Pages/OwnerAgent/Project/AddProject";
import ProjectDetails from "../Pages/OwnerAgent/Project/ProjectDetails";

// rent
import AddRent from "../Pages/OwnerAgent/Rent/AddRent";
import RentDetails from "../Pages/OwnerAgent/Rent/RentDetails";

//myprofile
import MyProfile from "../Pages/ProfileMenu/MyProfile";
import FogetPassword from "../Pages/Auth/ForgetPassword";
import ResetPassword from "../Pages/Auth/ResetPassword";
import GenReceipt from "../Pages/ProfileMenu/GenReceipt";

import Auth from "../Config/helper/Auth";
import ViewMore from "../Components/Common/ViewMore";
import NewRent from "../Pages/OwnerAgent/Rent/NewRent";

// commercial  master
import CommercialDetails from "../Pages/OwnerAgent/Commercial/CommercialDetails";
import NewCommercial from "../Pages/OwnerAgent/Commercial/NewCommercial";

// agreement
import Agreement from "../Pages/OwnerAgent/Agreement/Agreement";
import ListAgreement from "../Pages/OwnerAgent/Agreement/ListAgreement";
import AgreementPdf from "../Pages/OwnerAgent/Agreement/AgreementPdf";

// private routes
import AfterLoginUser from "./AfterLoginUser";
import AfterLoginAdmin from "./AfterLoginAdmin";
import AfterLoginMainAdmin from "./AfterLoginMainAdmin";

import AllProperties from "../Pages/Customer/AllProperties";
// MainAdmin
import AllPostProperties from "../Pages/Admin/AllPostProperties";
import PaymentDetails from "../Pages/Admin/PaymentDetails";
import ListedOwners from "../Pages/Admin/ListedOwners";
import ListedAgents from "../Pages/Admin/ListedAgents";
import ReceiptDetails from "../Pages/ProfileMenu/ReceiptDetails";
import ReceiptAdmin from "../Pages/Admin/ReceiptAdmin";
import SignupNew from "../Pages/Auth/SignupNew";
import Demo from "../Components/demo";
import DemoAll from "../Components/demoAll";
import DemoDetail from "../Components/DemoDetail";
import FilterProperties from "../Pages/Customer/FilterProperties";
import Enquirylist from "../Pages/Admin/Enquirylist";
import LocationMaster from "../Pages/Admin/LocationMaster";
import AmenitiesMaster from "../Pages/Admin/AmenitiesMaster";
import ReviewProperty from "../Pages/OwnerAgent/ReviewProperty/ReviewProperty";
import BlockRequest from "../Pages/Admin/BlockRequest";
import VerifiedProperties from "../Pages/Admin/VerifiedProperties";
import RejectedProperties from "../Pages/Admin/RejectedProperties";
import BuilderAdminDetails from "../Pages/Admin/BuilderProjectDetails";
import BuilderProjectFulltab from "../Pages/BuilderProject/BuilderProjectFulltab";
import BuilderProjectDetails from "../Pages/BuilderProject/ProjectBuilderDetails";
import BuilderDashboard from "../Pages/BuilderProject/BuilderDashboard";
import AddBuilderProject from "../Pages/BuilderProject/AddBuilderProject";
import AfterLoginBuilder from "./AfterLoginBuilder";
import PendingProjects from "../Pages/Admin/PendingProject";
import VerifiedProjects from "../Pages/Admin/VerifiedProject";
import RejectedProjects from "../Pages/Admin/RejectedProject";
import ReviewPropertyBuilder from "../Pages/BuilderProject/ReviewPropertyBuilder";
import PropertyTypeMaster from "../Pages/Admin/PropertyTypeMaster";
import MorePropertyReview from "../Pages/OwnerAgent/ReviewProperty/MorePropertyReview";
import MoreReviewPropertyBuilder from "../Pages/BuilderProject/MoreReviewPropertyBuilder";
import EnquiryDetailsBuilderList from "../Pages/BuilderProject/EnquiryDetailsBuilderList";
import EnquieyDetailsOwnerList from "../Pages/OwnerAgent/EnquieyDetails/EnquieyDetailsOwnerList";
import ShortlistProperties from "../Pages/ProfileMenu/ShortlistProperties";
import NewLoctionMaster from "../Pages/Admin/NewLoctionMaster";

export default function Content() {
  const userRole = Auth?.getUserRole();
  return (
    <div>
      <Routes>
        {/* auth rutes */}
        <Route>
          <Route exact path="/agreement" element={<Agreement />} />
          <Route exact path="/listagreement" element={<ListAgreement />} />
          <Route exact path="/agreementPdf" element={<AgreementPdf />} />

          <Route exact path="/Demo" element={<Demo />} />
          <Route path="/demoAll" element={<DemoAll />} />
          <Route path="/demoDetail" element={<DemoDetail />} />

          <Route exact path="/login" element={<LoginNew />} />
          <Route exact path="/signup" element={<SignupNew />} />
          <Route path="/filterProperties" element={<FilterProperties />} />

          <Route path="/verifyuser/:token" element={<EmailVerification />} />
          <Route exact path="/" element={<HomePage />} />
          <Route path="/forgetPassword" element={<FogetPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />

          <Route path="/fulltab/:proprty/:id" element={<ViewMore />} />
          <Route path="/allproperty" element={<AllProperties />} />
          <Route path="/ResidentialSection" element={<HomePage />} />
          <Route path="/CommercialSection" element={<HomePage />} />
          {/* <Route path="/NewProjectSection" element={<HomePage />} /> */}
          <Route path="/ConstructionSection" element={<HomePage />} />
          <Route  path="/builderProjectFulltab/:id" element={<BuilderProjectFulltab />}/>
          <Route path="/MorePropertyReview/:id" element={<MorePropertyReview/>}/>
          <Route path="/MoreReviewPropertyBuilder/:id" element={<MoreReviewPropertyBuilder/>}/>
          <Route path="/EnquieyDetailsOwnerList" element={<EnquieyDetailsOwnerList/>}/>



        </Route>
        {/* buyer / seller  */}
        {userRole === "Seller" ||
          userRole === "Buyer" ||
          userRole === "tenant" ? (
          <Route element={<AfterLoginUser />}>
            <Route path="/myprofile" element={<MyProfile />} />
            <Route path="/genreceipt" element={<GenReceipt />} />
            <Route path="/receiptdetails" element={<ReceiptDetails />} />
            <Route path="/ShortlistProperties" element={<ShortlistProperties/>}/>
          </Route>
        ) : userRole === "Owner" || userRole === "Agent" ? (
          <Route element={<AfterLoginAdmin />}>
            <Route path="/myprofile" element={<MyProfile />} />
            <Route exact path="/dashboard" element={<Dashboard />} />

            {/* project master routes*/}
            <Route exact path="/addProject" element={<AddProject />} />
            <Route exact path="/projectDetails" element={<ProjectDetails />} />
            <Route
              exact
              path="/UpdateProjectDetails"
              element={<AddProject />}
            />

            {/* rent master routes */}
            <Route exact path="/newRent" element={<NewRent />} />
            <Route exact path="/rentDetails" element={<RentDetails />} />
            <Route exact path="/UpdateRentDetails" element={<AddRent />} />

            {/* property master routes*/}
            <Route exact path="/AddProperty" element={<AddProperty />} />
            <Route
              exact
              path="/propertyDetails"
              element={<PropertyDetails />}
            />
            <Route
              exact
              path="/UpdatePropertyDetails"
              element={<AddProperty />}
            />

            {/* agreement */}
            {/* <Route exact path="/agreement" element={<Agreement />} />
            <Route exact path="/listagreement" element={<ListAgreement />} />
            <Route exact path="/agreementPdf" element={<AgreementPdf />} /> */}
            <Route exact path="/reviewproperty" element={<ReviewProperty />} />


            {/* commercial master routes */}
            <Route exact path="/addComm" element={<NewCommercial />} />
            <Route exact path="/commDetails" element={<CommercialDetails />} />

            {/* Receipt Generate */}
            <Route path="/genreceipt" element={<GenReceipt />} />
            <Route path="/receiptdetails" element={<ReceiptDetails />} />
            <Route path="/MorePropertyReview/:id" element={<MorePropertyReview/>}/>
            <Route path="/EnquieyDetailsOwnerList" element={<EnquieyDetailsOwnerList/>}/>
          </Route>
        ) : userRole === "Admin" ? (
          <Route element={<AfterLoginMainAdmin />}>
            <Route path="/adminDashboard" element={<AdminDashboard />} />

            <Route path="/properties" element={<AllPostProperties />} />
            <Route
              path="/verifiedProperties"
              element={<VerifiedProperties />}
            />
            <Route
              path="/rejectedProperties"
              element={<RejectedProperties />}
            />
            <Route
              path="/pendingProjects"
              element={<PendingProjects />}
            />
            <Route
              path="/verifiedProjects"
              element={<VerifiedProjects />}
            />
            <Route
              path="/rejectedProjects"
              element={<RejectedProjects />}
            />
            <Route path="/myprofile" element={<MyProfile />} />

            <Route path="/paymentList" element={<PaymentDetails />} />
            <Route path="/ownerList" element={<ListedOwners />} />
            <Route path="/agentList" element={<ListedAgents />} />
            <Route path="/receiptList" element={<ReceiptAdmin />} />
            <Route path="/enquieyDetails" element={<Enquirylist />} />
            <Route path="/locationMaster" element={<LocationMaster />} />
            <Route path="/amenitiesMaster" element={<AmenitiesMaster />} />
            <Route path="/blockRequest" element={<BlockRequest />} />
            <Route path="/builderDetails" element={<BuilderAdminDetails />} />
            <Route path="/propertytypeMaster" element={<PropertyTypeMaster/>}/>
            <Route exact path="/builderProjectDetails" element={<BuilderProjectDetails />}/>
            <Route exact path="/newLocationMaster" element={<NewLoctionMaster/>}/>

          </Route>
        ) : userRole === "Builder" ? (
          <>
            <Route element={<AfterLoginBuilder />}>
              <Route
                exact
                path="/dashboardbuild"
                element={<BuilderDashboard />}
              />
              <Route
                exact
                path="/addBuilderProject"
                element={<AddBuilderProject />}
              />
              <Route
                exact
                path="/builderProjectDetails"
                element={<BuilderProjectDetails />}
              />
              <Route
                exact
                path="/builderProjectFulltab/:id"
                element={<BuilderProjectFulltab />}
              />
              <Route
                exact
                path="/ReviewPropertyBuilder"
                element={<ReviewPropertyBuilder />}
              />
              <Route 
              path="/myprofile" element={<MyProfile />} />
            </Route>
            <Route path="/ReviewPropertyBuilder" element={<ReviewPropertyBuilder/>}> </Route>
            <Route path="/MoreReviewPropertyBuilder/:id" element={<MoreReviewPropertyBuilder/>}/>
            <Route path="/EnquiryDetailsBuilderList" element={<EnquiryDetailsBuilderList/>}/>

          </>
        ) 
        :null}
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
    </div>
  );
}
