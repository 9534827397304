// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination {
    display: flex;
    list-style: none;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding:2rem 0;
}

.pagination-item {
    margin: 0 5px;
    padding: 2px 10px;
    border: 1px solid #ccc;
    border-radius: 50px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.pagination-item:hover {
    background-color: #be3030;
    border: 1px solid #be3030;
    color: #fff;
}

.activepagination {
    background-color: #007bff;
    color: #fff;
    font-weight: 700;
    border-color: #007bff;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Admin/pagination.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gBAAgB;IAChB,uBAAuB;IACvB,mBAAmB;IACnB,SAAS;IACT,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,iBAAiB;IACjB,sBAAsB;IACtB,mBAAmB;IACnB,eAAe;IACf,sCAAsC;AAC1C;;AAEA;IACI,yBAAyB;IACzB,yBAAyB;IACzB,WAAW;AACf;;AAEA;IACI,yBAAyB;IACzB,WAAW;IACX,gBAAgB;IAChB,qBAAqB;AACzB","sourcesContent":[".pagination {\n    display: flex;\n    list-style: none;\n    justify-content: center;\n    align-items: center;\n    margin: 0;\n    padding:2rem 0;\n}\n\n.pagination-item {\n    margin: 0 5px;\n    padding: 2px 10px;\n    border: 1px solid #ccc;\n    border-radius: 50px;\n    cursor: pointer;\n    transition: background-color 0.3s ease;\n}\n\n.pagination-item:hover {\n    background-color: #be3030;\n    border: 1px solid #be3030;\n    color: #fff;\n}\n\n.activepagination {\n    background-color: #007bff;\n    color: #fff;\n    font-weight: 700;\n    border-color: #007bff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
