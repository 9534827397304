import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { useNavigate, useLocation } from "react-router-dom";
import { settings } from "../../Config/Settings";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import BedOutlinedIcon from "@mui/icons-material/BedOutlined";
import SquareFootOutlinedIcon from "@mui/icons-material/SquareFootOutlined";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import ChairOutlinedIcon from "@mui/icons-material/ChairOutlined";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CorporateFareOutlinedIcon from "@mui/icons-material/CorporateFareOutlined";
import EmojiTransportationOutlinedIcon from "@mui/icons-material/EmojiTransportationOutlined";
import ArrowForwardIosTwoToneIcon from "@mui/icons-material/ArrowForwardIosTwoTone";
import { MdOutlineCabin } from "react-icons/md";
import { pink } from "@material-ui/core/colors";
import { Modal } from "react-bootstrap";
import ButtonR from "react-bootstrap/Button";
import { LuTableProperties } from "react-icons/lu";
import Form from "react-bootstrap/Form";
import Rating from "@mui/material/Rating";
import InputGroup from "react-bootstrap/InputGroup";
// api
import { addProprtyToWish, getwishlist } from "../../Api/WishlistAPI";
import Auth from "../../Config/helper/Auth";

// social icons and share
import {
  FacebookIcon,
  WhatsappShareButton,
  TwitterShareButton,
  EmailShareButton,
  TwitterIcon,
  WhatsappIcon,
  FacebookShareButton,
  EmailIcon,
} from "react-share";
import Snackbar from "@mui/material/Snackbar";
import {
  getVisitPropertyById,
  sendEnquiryData,
  visitProperty,
} from "../../Api/AuthAPI";
import Swal from "sweetalert2";
import { getAllReview } from "../../Api/ReviewAPI";
import { OrderPayment, VerifyPayment } from "../../Api/Payment";
import RecommendedProject from "../BuilderProject/RecommendedProject";
// currency dropdown list
const currencies = [
  {
    value: "USA",
    label: "USA +1",
  },
  {
    value: "IND",
    label: "IND +91",
  },
  {
    value: "ARE",
    label: "ARE +971",
  },
  {
    value: "GBR",
    label: "GBR +44",
  },
];



const CommercialFilter = ({ allPropertyData,Location }) => {
  const navigate = useNavigate();
  const [warnLogin, setWarnLogin] = useState(false);
  const [open, setOpen] = useState(false);
  let userData = {
    Name: Auth?.getUserDetails()?.fullName,
    email: Auth?.getUserDetails()?.email,
    Phone: Auth?.getUserDetails()?.phone,
    terms: false
  };
  const userId = Auth?.user?.userData?._id;

  const [activeWish, setActiveWish] = useState(false);
  const [Wishlistdata, setWishlistdata] = useState([]);

  const [show, setShow] = useState(false);
  const [showSocial, setShowSocial] = useState(false);
  const [socialUrl, setSocialUrl] = useState("");
  const [copySuccess, setCopySuccess] = useState("");
  const textAreaRef = useRef(null);
  const [propertyData, setPropertyData] = useState({});
  const [useContactDetails, setUseContactDetails] = useState({
    Name: "",
    email: "",
    Phone: "",
    terms: false
  });
  const [error, setError] = useState([]);
  const [ratings, setRatings] = useState(0);
  const [visitCount, setVisitCount] = useState(0);
  const handleOpen = () => setWarnLogin(true);

  // slider setting code
  const settingsslider = {
    vertical: true,
    verticalSwiping: true,
    dots: true,
    infinite: true,
    speed: 500, // Adjust speed as needed
    slidesToShow: allPropertyData.length >= 3 ? 3 : allPropertyData.length,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 7000, // Adjust speed as needed
    pauseOnHover: false,
    cssEase: "linear",
    initialSlide: 0,
    arrows: allPropertyData.length >= 3 ? true : false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const handleClose = () => {
    setShow(false);
    setUseContactDetails({
      Name: "",
      email: "",
      Phone: "",
      terms: false
    });
    setShowSocial(false);
    setError([]);
    setOpen(false);
    setWarnLogin(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUseContactDetails({ ...useContactDetails, [name]: value });

    if (name === "Name" && value !== null && value !== "") {
      setError({
        ...error,
        Name: "Name field can't be left blank. Please enter your name!",
      });
    }

    if (name === "email" && value !== null && value !== "") {
      setError({
        ...error,
        email: "Email ID field can't be left blank. Please enter",
      });
    }
    if (name === "Phone" && value !== null && value !== "") {
      setError({
        ...error,
        Phone: "Please enter a 10-digit Mobile number!",
      });
    }
  };

  const validation = () => {
    let errors = {};
    let isValid = true;

    if (useContactDetails.Name === "" || useContactDetails.Name === null) {
      isValid = false;
      errors["Name"] =
        "Name field can't be left blank. Please enter your name!";
    }

    if (useContactDetails.email === "" || useContactDetails.email === null) {
      isValid = false;
      errors["email"] = "Email ID field can't be left blank. Please enter!";
    }

    if (useContactDetails.Phone === "" || useContactDetails.Phone === null) {
      isValid = false;
      errors["Phone"] = "Please enter a 10-digit Mobile number!";
    } else if (useContactDetails.terms === false) {
      isValid = false;
      Swal.fire({
        icon: "warning",
        title: "Please Agree to EquiReal Terms",
        text: "By clicking 'OK', you confirm that you agree to the terms and conditions of EquiReal.",
        showConfirmButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      });
    }
    setError(errors);
    return isValid;
  };

  const submitContactDetails = (e) => {
    e.preventDefault();
    const isValid = validation();
    if (isValid) {
      let dataToSend = {
        fullName: useContactDetails.Name,
        email: useContactDetails.email,
        phone: useContactDetails.Phone,
        PropertyId: propertyData.PropertyId,
        agentId: propertyData.ownerId,
      };
      sendEnquiryData(dataToSend)
        .then((res) => {
          if (res.data.status === true) {
            Swal.fire({
              icon: "success",
              title: "Enquiry Added Successfully!",
              text: "Your enquiry has been submitted and our team will get back to you shortly.",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK",
            });
            setShow(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const NumberSeparator = (input) => {
    return new Intl.NumberFormat("en-IN", {
      minimumFractionDigits: 2,
    }).format(input);
  };

  useEffect(() => {
    let userId = Auth?.getUserDetails()?._id;
    if (userId) {
      getistdetails();
    }
    getVisits();
  }, [allPropertyData, userId]);

  const noDataMessageStyle = {
    textAlign: "center",
    padding: "20px",
    backgroundColor: "#f8d7da", // Light red background color
    color: "#721c24", // Dark red text color
    border: "1px solid #f5c6cb", // Border color
    borderRadius: "5px", // Rounded corners
    margin: "10px",
    width: "30rem",
  };

  const addToWishist = (data, wish) => {
    let userId = Auth?.getUserDetails()?._id;
    let dataToend = {
      userId: userId,
      PropertyId: data._id,
      PropertyType: "Commercial",
    };
    addProprtyToWish(dataToend)
      .then((res) => {
        if (res.data.status === true) {
          // let wishlisttatus = res.data.wishlist.Properties;
          // let status = wishlisttatus.map((wishlist, index) => {
          //   if (wishlist.PropertyId === data._id) {
          //     return true;
          //   } else {
          //     return false;
          //   }
          // });
          setActiveWish({
            ...activeWish,
            [data._id]: true,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getistdetails = async () => {
    let userId = Auth?.getUserDetails()?._id;

    await getwishlist(userId)
      .then((res) => {
        let list = res.data.userWishlist.Properties;
        let newActiveWish = {};

        allPropertyData.forEach((property) => {
          let isPropertyInList = list.some(
            (item) => item.PropertyId === property._id
          );

          // Assign the appropriate value for each property ID
          newActiveWish[property._id] = isPropertyInList;
        });

        // Update the state once with the new object
        setActiveWish(newActiveWish);
        setWishlistdata(list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const formatCash = (n) => {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
  };

  // copy link to share
  const copyToClipboard = (e) => {
    textAreaRef.current.select();
    document.execCommand("copy");
    // This is just personal preference.
    // I prefer to not show the whole text area selected.
    e.target.focus();
    setOpen(true);
    // setCopySuccess("Copied!");
  };

  const bookProperty = (amount, propertyId, userId) => {
    const data = {
      amount: amount,
      PropertyId: propertyId,
      userId: userId,
    };
    OrderPayment(data)
      .then((res) => {
        handleRazorpay(res.data.data, propertyId, userId);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleRazorpay = (data, propertyId, userId) => {
    const option = {
      key: "rzp_test_ftfKAFOFwJu6pH",
      amount: data.amount,
      currency: data.currency,
      name: "Real Estate",
      description: "Test Transaction",
      image: "https://example.com/your_logo",
      order_id: data.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1

      handler: function (response) {
        let paymentData = {
          orderId: response.razorpay_order_id,
          paymentID: response.razorpay_payment_id,
          propertyId: propertyId,
          userId: userId,
          amount: data.amount,
          razorpaySignature: response.razorpay_signature,
        };
        VerifyPayment(paymentData)
          .then((res) => {
            if (res.data.code === 200) {
              handleClose();
              Swal.fire({
                icon: "success",
                title: "Payment Successful.",
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                // cancelButtonColor: '#d33',
                confirmButtonText: "ok",
              });
            } else if (res.data.code === 400) {
              Swal.fire({
                icon: "error",
                title: "Already Paid.",
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                // cancelButtonColor: '#d33',
                confirmButtonText: "ok",
              });
            }
          })
          .catch((err) => {
            console.log(err, "error in buy");
          });
      },
    };

    const paymentObject = new window.Razorpay(option);
    paymentObject.open();
  };

  const getAllReviews = async (id) => {
    getAllReview(id)
      .then((res) => {
        if (res.data.status === 200) {
          const val =
            res.data.overallRating === "NaN" ? 0.0 : res.data.overallRating;
          setRatings(val);
        }
      })
      .catch((e) => {
        console.log(e, "error in get reviews");
      });
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const VisitClickProperty = (data) => {
    // clickVisit(data._id)
  };
  const clickVisit = (proId) => {
    let propertyId = proId;
    visitProperty(propertyId, userId)
      .then((res) => {
        if (res.status === 201) {
          console.log(res.data);
          getVisits();
        } else {
          console.log(res.data);
        }
      })
      .catch((err) => {
        console.log(err, "error in clickVisit function");
      });
  };

  const getVisits = () => {
    allPropertyData?.forEach((property) => {
      getVisitPropertyById(property?._id)
        .then((res) => {
          if (res?.data?.status === 1) {
            setVisitCount((prevCounts) => ({
              ...prevCounts,
              [property._id]: res?.data?.data?.uniqueVisits,
            }));
          } else {
            setVisitCount((prevCounts) => ({
              ...prevCounts,
              [property._id]: 0,
            }));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const handleChangeChecbox = (event) => {
    const { name, value, type, checked } = event.target;
    setUseContactDetails({
      ...useContactDetails,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  return (
    <div className='flex-grow-1'>
      {allPropertyData.length === 0 ? (
        <p className="errormsg">No data found</p>
      ) : (
        <Slider {...settingsslider}>
          {allPropertyData &&
            allPropertyData.map((listData) => {
              return (
                <div
                  className="p-card-content"
                // onClick={() => clickVisit(listData._id)}
                >
                  <div
                    className="p-card"
                  // onClick={() =>
                  //   navigate(`/fulltab/${"Commercial"}/${listData._id}`, {
                  //     state: {
                  //       yourData: listData,
                  //       propertyType: "Commercial",
                  //     },
                  //   })
                  // }
                  >
                    <div className="p-img">
                      {listData?.exteriorView[0]?.name ?
                        (<img
                          src={`${listData?.exteriorView[0]?.name}`}
                          alt="Property"
                          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                       
                        />) :
                        (<img
                          src="https://api.equireal.co.in/defaultProperty.jpg"
                          alt="Property"
                          className="property-image"
                        />
                        )}

                      {/* <div className="contact-button-container mt-3">
                    <Button
                      variant="contained"
                      className="btn-p-owner"
                      onClick={() =>
                        bookProperty(
                          listData.SecurityDeposit,
                          listData._id,
                          listData.userId
                        )
                      }
                    >
                      Book Now
                    </Button>
                  </div> */}
                    </div>

                    <div className="p-info">
                      <div className="p-tle">
                        <div>
                          <h4>Commercial Properties for {listData.purposeOfProperty}</h4>
                          {/* <h4 style={{ color: "red" }}>
                        {visitCount[listData._id] || 0}
                      </h4> */}
                          <p>
                            in {listData.subLocation},{listData.Location}{" "}
                            <a href="/#">
                              <PlaceOutlinedIcon fontSize="2" />
                              View on map
                            </a>
                          </p>
                          {/* <p>
                        <Rating
                          name="read-only"
                          readOnly
                          value={ratings}
                          size="small"
                        />
                      </p> */}
                        </div>

                        <div className="d-flex align-items-center">
                          {/* like icon */}
                          {Auth?.user?.userData?.role === "Owner" ||
                            Auth?.user?.userData?.role === "Agent" ||
                            Auth?.user?.userData?.role === "Admin" ||
                            Auth?.user?.userData?.role === "Builder" ? null : (
                            <Tooltip
                              title="Add to Favourite"
                              slotProps={{
                                popper: {
                                  modifiers: [
                                    {
                                      name: "offset",
                                      options: {
                                        offset: [0, -14],
                                      },
                                    },
                                  ],
                                },
                              }}
                            >
                              <IconButton
                                title="Add to Favourite"
                                onClick={() => {
                                  userId ? addToWishist(listData) : handleOpen();
                                }}
                              // title="Add to Favourite"
                              // onClick={() => {
                              //   userId ? addToWishist(listData) : handleOpen();
                              // }}
                              // disabled={activeWish ? true : false}
                              >
                                {activeWish[listData._id] ? (
                                  <FavoriteIcon
                                    sx={{ color: pink[500] }}
                                    style={{ fill: pink[500], cursor: "pointer" }}
                                  />
                                ) : (
                                  <FavoriteBorderIcon />
                                )}
                              </IconButton>
                            </Tooltip>
                          )}

                          {/* share icon */}
                          <Tooltip
                            title="Share"
                            slotProps={{
                              popper: {
                                modifiers: [
                                  {
                                    name: "offset",
                                    options: {
                                      offset: [0, -14],
                                    },
                                  },
                                ],
                              },
                            }}
                          >
                            <IconButton
                              onClick={() => {
                                setShowSocial(true);
                                setSocialUrl(
                                  `https://equireal.co.in/fulltab/${"Commercial"}/${listData._id
                                  }`
                                );
                              }}
                            >
                              <ShareOutlinedIcon />
                            </IconButton>
                          </Tooltip>

                          <Tooltip
                            title="People viewing this property"
                            slotProps={{
                              popper: {
                                modifiers: [
                                  {
                                    name: "offset",
                                    options: {
                                      offset: [0, -14],
                                    },
                                  },
                                ],
                              },
                            }}
                          >
                            <p>
                              <VisibilityIcon /> {visitCount[listData._id] || 0}
                            </p>
                          </Tooltip>
                        </div>
                      </div>

                      {/* details */}
                      <div className="pr-info">
                        <ul>
                          <li>
                            <LuTableProperties />
                            <h6>{listData.propertyType}</h6>
                          </li>
                          <li>
                            <MdOutlineCabin />
                            <h6> {listData.ComplexName}</h6>
                          </li>

                          <li>
                            <ChairOutlinedIcon />
                            <h6>{listData.furnishedStatus}</h6>
                          </li>

                          <li>
                            <EmojiTransportationOutlinedIcon />
                            <h6> {listData.Availibilty}</h6>
                          </li>


                          <li>
                            <SquareFootOutlinedIcon />
                            <h6> {listData.carpetArea} Sq.Ft</h6>
                          </li>

                          <li>
                            <CorporateFareOutlinedIcon />
                            <h6>
                              {" "}
                              {listData.selectFloor} of {listData.totalFloor}
                              th Floor
                            </h6>
                          </li>
                          {/* <li>
                        <PeopleOutlineOutlinedIcon />
                        <h6>{listData.Amenities.join(" ,")}</h6>
                      </li> */}
                        </ul>
                      </div>
                      <div className="d-flex justify-content-end">
                        <button
                          class="cta"
                          onClick={() => {
                            navigate(`/fulltab/${"Commercial"}/${listData._id}`, {
                              state: {
                                yourData: listData,
                                propertyType: "Commercial",
                              },
                            })
                            clickVisit(listData._id);
                          }
                          }
                        >
                          <span class="hover-underline-animation"> View More </span>
                          <svg
                            id="arrow-horizontal"
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            height="10"
                            viewBox="0 0 46 16"
                          >
                            <path
                              id="Path_10"
                              data-name="Path 10"
                              d="M8,0,6.545,1.455l5.506,5.506H-30V9.039H12.052L6.545,14.545,8,16l8-8Z"
                              transform="translate(30)"
                            ></path>
                          </svg>
                        </button>
                        {/* <ArrowForwardIosTwoToneIcon /> */}
                      </div>
                    </div>

                    <div className="p-pri">
                      <div>
                        <h4>₹{formatCash(listData?.purposeOfProperty === "Buy" ? listData.propertyCost : listData.PropertyRent)}/-</h4>
                        {listData?.purposeOfProperty}
                      </div>
                      <div>
                        <h4>₹{formatCash(listData?.purposeOfProperty === "Buy" ? listData.bookingAmount : listData.SecurityDeposit)}/-</h4>
                        <p>
                          {listData?.purposeOfProperty === "Buy" ? "Booking Amount" : "Security Deposit"}
                        </p>
                      </div>

                      <div>
                        <p>
                          {listData?.PostedBy} : {listData.nameOfPropertyOwner}
                        </p>

                        {Auth?.user?.userData?.role === "Owner" ||
                          Auth?.user?.userData?.role === "Agent" ||
                          Auth?.user?.userData?.role === "Admin" ||
                          Auth?.user?.userData?.role === "Builder" ||
                          Auth?.user?.userData?.role === undefined ? null : (
                          <Button
                            variant="contained"
                            className="btn-p-owner"
                            onClick={() => {
                              setShow(true);
                              setUseContactDetails(userData);
                              setPropertyData({
                                PropertyId: listData._id,
                                ownerId: listData.userId,
                              });
                            }}
                          >
                            Contact Owner
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </Slider>
      )}

      <div className="py-5">
        <RecommendedProject propertyType="Commercial" Location={Location}/>
      </div>

      {/* contact agent modal */}
      <Modal
        show={show}
        onHide={() => handleClose()}
        disableBackdropClick={false}
        disableEscapeKeyDown={false}
        centered
      >
        <Modal.Header closeButton className="headerBlue">
          <Modal.Title
            className=" text-center loginTitle"
            style={{ margin: "0 auto" }}
          >
            <h5 class="m-0">Fill out this one-time form</h5>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p className="text-gray-700 mb-4 text-center">
            Get Agent's details over email
          </p>
          <div className="d-flex justify-content-center align-items-center">
            <form>
              <div className="mb-4">
                <TextField
                  id="name"
                  label="Your Name"
                  variant="standard"
                  fullWidth
                  value={useContactDetails.Name}
                  name="Name"
                  onChange={handleChange}
                // helperText={error.Name !== "" ? error.Name : null}
                // error={error.Name !== "" ? true : false}
                />
              </div>
              <div className="mb-4">
                <TextField
                  id="email"
                  label="Email"
                  variant="standard"
                  type="email"
                  name="email"
                  onChange={handleChange}
                  value={useContactDetails.email}
                  fullWidth
                // helperText={error.email !== "" ? error.email : null}
                // error={error.email !== "" ? true : false}
                />
              </div>
              <div className="mb-4">
                <div className="flex">
                  <TextField
                    color="grey"
                    id="standard-select-currency-native"
                    select
                    label="Country"
                    //   defaultValue="EUR"
                    SelectProps={{
                      native: true,
                    }}
                    variant="standard"
                  >
                    {currencies.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                  <TextField
                    id="standard-basic"
                    label="Mobile Number"
                    variant="standard"
                    color="grey"
                    name="Phone"
                    value={useContactDetails.Phone}
                    onChange={handleChange}
                  // helperText={error.Phone !== "" ? error.Phone : null}
                  // error={error.Phone !== "" ? true : false}
                  />
                </div>
              </div>
              <div className="mb-6">
                <label className="inline-flex items-center">
                  <Checkbox
                    color="primary"
                    name="terms"
                    checked={useContactDetails.terms}
                    onChange={handleChangeChecbox}
                  />
                  <span className="ml-2">
                    I Agree to EquiReal'{" "}
                    <a className="text-indigo-200 underline">Terms of Use</a>
                  </span>
                </label>
              </div>
              <div className="flex items-center justify-center mt-2">
                <Button
                  variant="contained"
                  color="error"
                  type="submit"
                  fullWidth
                  onClick={submitContactDetails}
                >
                  Get Contact Details
                </Button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>

      {/* social share */}
      <Modal
        show={showSocial}
        onHide={() => handleClose()}
        disableBackdropClick={false}
        disableEscapeKeyDown={false}
        centered
      >
        <Modal.Header closeButton className="headerBlue">
          <Modal.Title
            className=" text-center loginTitle"
            style={{ margin: "0 auto" }}
          >
            <h5 class="m-0">Share Property</h5>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="d-flex justify-content-evenly align-items-center">
            <FacebookShareButton url={socialUrl}>
              <FacebookIcon size={40} round />
            </FacebookShareButton>

            <TwitterShareButton url={socialUrl}>
              <TwitterIcon size={40} round />
            </TwitterShareButton>

            <WhatsappShareButton url={socialUrl}>
              <WhatsappIcon size={40} round />
            </WhatsappShareButton>

            <EmailShareButton url={socialUrl}>
              <EmailIcon size={40} round />
            </EmailShareButton>
          </div>
          <InputGroup className="mt-3">
            <Form.Control
              placeholder="Property link"
              aria-label="Property link"
              aria-describedby="basic-addon2"
              value={socialUrl}
              ref={textAreaRef}
            />
            <ButtonR
              variant="outline-secondary"
              id="button-addon2"
              onClick={copyToClipboard}
            >
              copy
            </ButtonR>
          </InputGroup>
          {open && (
            <Snackbar open={open} autoHideDuration={2000} message="copied!" />
          )}
        </Modal.Body>
      </Modal>

      {/* login please modal */}
      <Modal
        show={warnLogin}
        onHide={handleClose}
        size="lg"
        centered
        closeButton
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6">
            You need to Login to continue. <br />
            Please Login.
          </Typography>
          <Button onClick={() => navigate("/login")}>Login</Button>
        </Box>
      </Modal>
    </div>
  );
};

export default CommercialFilter;
