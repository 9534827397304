// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.radio-button-box {
    display: inline-block;
    /* border: 1px solid #000; */
    padding: 10px;
    margin: 5px;
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.radio-button-box.checked {
    background-color: #666666;
    color: #fff;
}

.radio-button-box:not(.checked):hover {
    background-color: rgb(231, 229, 229);
    /* transition: 2sec; */
}`, "",{"version":3,"sources":["webpack://./src/Components/Common/RadioButtonBox.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,4BAA4B;IAC5B,aAAa;IACb,WAAW;IACX,eAAe;IACf,yBAAiB;YAAjB,iBAAiB;IACjB,kBAAkB;IAClB,sCAAsC;IACtC,4CAA4C;AAChD;;AAEA;IACI,yBAAyB;IACzB,WAAW;AACf;;AAEA;IACI,oCAAoC;IACpC,sBAAsB;AAC1B","sourcesContent":[".radio-button-box {\n    display: inline-block;\n    /* border: 1px solid #000; */\n    padding: 10px;\n    margin: 5px;\n    cursor: pointer;\n    user-select: none;\n    border-radius: 5px;\n    transition: background-color 0.3s ease;\n    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;\n}\n\n.radio-button-box.checked {\n    background-color: #666666;\n    color: #fff;\n}\n\n.radio-button-box:not(.checked):hover {\n    background-color: rgb(231, 229, 229);\n    /* transition: 2sec; */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
