/* eslint-disable import/no-anonymous-default-export */
class Auth {
  constructor() {
    this.user = JSON.parse(localStorage.getItem("User")) || null;
  }

  getToken() {
    if (this.user !== null) {
      return this.user.token;
    }
  }

  getWalletId() {
    if (this.user !== null) {
      return this.user.userData.userId;
    }
  }

  getUserRole() {
    if (this.user !== null) {
      return this.user.userData.role;
    }
  }
  getUserDetails() {
    if (this.user !== null) {
      return this.user.userData;
    }
  }

  logout() {
    localStorage.removeItem("User");
    window.location.replace("/login");
  }
}

export default new Auth();
