import React, { useEffect, useState } from "react";
// import Table from "react-bootstrap/Table";
import { getAllRentReceiptId } from "../../Api/RentpropertyAPI";
import Swal from "sweetalert2";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import "@mui/icons-material";
import AdminSidebar from "../../Components/Sidebar/AdminSidebar";
const PaymentDetails = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const columns = [
    { id: "id", label: "Sr. No.", minWidth: 20 },
    { id: "fullName", label: "Agent Name", minWidth: 100 },
    {
      id: "email",
      label: "Email",
      minWidth: 100,
    },
    {
      id: "agentStatus",
      label: "Agent Status",
      minWidth: 100,
    },
  ];

  return (
    <div>
      <div>
        <AdminSidebar />
        <div className="container topNav-m">
        <div className=" content-title">
              <h3>Payment Details</h3>
              <hr className="content-title-underline" />
            </div>
          <Paper
            sx={{ width: "100%", overflow: "hidden", justifyContent: "center" }}
          >
            <TableContainer sx={{ maxHeight: 700, overflowX: "auto" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <>
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                      </>
                    ))}
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>
          </Paper>
        </div>
      </div>
    </div>
  );
};

export default PaymentDetails;
