import axios from "axios";
import { settings } from "../Config/Settings";

const addRentProperty = (data) => {
  return axios({
    method: "POST",
    // url: `${settings.API_URL}/rentProject/addRentProperty`, => http://213.136.78.83:8800/newRentProperty/AddRentProperty
    url: `${settings.API_URL}/newRentProperty/AddRentProperty`,
    data: data,
  });
};
const getAllRentProperty = () => {
  return axios({
    method: "GET",
    url: `${settings.API_URL}/newRentProperty/getallNewRentProperty`,
  });
};

const getAllRentPropertyByUser = (userId) => {
  return axios({
    method: "GET",
    url: `${settings.API_URL}/newRentProperty/getallNewRentPropertyByuser/${userId}`,
  });
};

const updateRentProperty = (data, rentId) => {
  return axios({
    method: "PATCH",
    url: `${settings.API_URL}/newRentProperty/updateNewRentProperty/${rentId}`,
    data: data,
  });
};
const deleteRentProperty = (rentId) => {
  return axios({
    method: "PATCH",
    url: `${settings.API_URL}/newRentProperty/deleteRentProperty/${rentId}`,
  });
};

const deleteImagesById = (id, data)=>{
  return axios({
    method:"DELETE",
    url: `${settings.API_URL}/newRentProperty/deleteRentImage/${id}`,
    data:data
  })
}

 const deletecommImgById = (id,data)=>{
  return axios({
    method:"DELETE",
    url: `${settings.API_URL}/RentCommercialProperty/deleteCommImage/${id}`,
    data:data
  })
}

const filterByCityRn = (Location) => {
  return axios({
    method: "GET",
    url: `${settings.API_URL}/newRentProperty/getResidentialPropertyByLocation/${Location}`,
  });
};

const filterByPropertyType = (propertyType) => {
  return axios({
    method: "GET",
    url: `${settings.API_URL}/newRentProperty/getResidentialByPropertyType/${propertyType}`,
  });
};
// main admin for residential
const updateStatusOfProperty = (id, data) => {
  return axios({
    method: "PATCH",
    url: `${settings.API_URL}/newRentProperty/updatePropertystaus/${id}`,
    data: data,
  });
};

const updateStatusofCommercialProperty = (id, data) =>{
  return axios({
    method: "PATCH",
    url: `${settings.API_URL}/RentCommercialProperty/updateCommertialPropertystaus/${id}`,
    data: data,
  });
}
const addRentReceipt = (data) => {
  return axios({
    method: "POST",
    url: `${settings.API_URL}/rentReceipt/addRentReceipt`,
    data: data,
  });
};

const getAllRentReceipt = (receiptId) => {
  return axios({
    method: "GET",
    url: `${settings.API_URL}/rentReceipt/getRentReceipt/${receiptId}`,
  });
};

const getAllRentReceiptId = () => {
  return axios({
    method: "GET",
    url: `${settings.API_URL}/rentReceipt/getAllRentReceipt`,
  });
};
const filterByPropertyBudeget = (min, max) => {
  return axios({
    method: "GET",
    url: `${settings.API_URL}/newRentProperty/getResidentialByPropertyBudget/${min}/${max}`,
  });
};

const filterAllResidential = (data) => {
  return axios({
    method: "POST",
    url: `${settings.API_URL}/newRentProperty/filterResidential`,
    data: data,
  });
};

const recommendedPropertiesData = (data)=>{
  return axios({
    method: "POST",
    url: `${settings.API_URL}/recommendation`,
    data: data
  });
}

const RentPropertyDetails = (id) => {
  return axios({
    method: "GET",
    url: `${settings.API_URL}/newRentProperty/getNewRentPropertybyId/${id}`,
  });
};

export {
  addRentProperty,
  getAllRentProperty,
  updateRentProperty,
  deleteRentProperty,
  filterByCityRn,
  updateStatusOfProperty,
  updateStatusofCommercialProperty,
  getAllRentPropertyByUser,
  addRentReceipt,
  getAllRentReceipt,
  getAllRentReceiptId,
  filterByPropertyType,
  filterByPropertyBudeget,
  filterAllResidential,
  RentPropertyDetails,
  deleteImagesById,
  deletecommImgById,
  recommendedPropertiesData
};
