/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./Agreement.css";
import Stamp from "../../../Assets/Stamp.jpg";
import moment from "moment";
import html2canvas from "html2pdf.js";
import jsPDF from "jspdf";
import { useLocation, useNavigate } from "react-router-dom";



export default function AgreementPdf() {
  const navigate = useNavigate();
  const [date, setDate] = useState();
  const props = useLocation();
  
   

  const  numberToWords = (num) => {
    const belowTwenty = ["", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine", "Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];
    const tens = ["", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];
    const units = ["", "Thousand", "Lakh", "Crore"];
  
    if (num === 0) return "Zero";
  
    function helper(n) {
        if (n < 20) return belowTwenty[n];
        else if (n < 100) return tens[Math.floor(n / 10)] + (n % 10 ? " " + belowTwenty[n % 10] : "");
        else return belowTwenty[Math.floor(n / 100)] + " Hundred" + (n % 100 ? " " + helper(n % 100) : "");
    }
  
    let word = "";
    let unitIndex = 0;
  
    while (num > 0) {
        let chunk;
        if (unitIndex === 1 || unitIndex === 2) { 
            chunk = num % 100;
            num = Math.floor(num / 100);
        } else { 
            chunk = num % 1000;
            num = Math.floor(num / 1000);
        }
        if (chunk !== 0) {
            word = helper(chunk) + (units[unitIndex] ? " " + units[unitIndex] : "") + (word ? " " + word : "");
        }
        unitIndex++;
    }
  
    return word.trim();
  }
  
  const MRentAmount = numberToWords(props?.state?.MRentAmount);
  const securityDepositInWords = numberToWords(props?.state?.securityDeposit);
  
  
  const formatDate = () => {
    const date = new Date();
    const options = { day: "numeric", month: "long", year: "numeric" };
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      date
    );
    return formattedDate;
  };
  useEffect(() => {
    setDate(formatDate());
    
  }, []);

  // let getDate = props?.aggrStartDate ? (props?.aggrStartDate).toString() : null;
  const inputString = props?.rentPaymentDate ? props?.rentPaymentDate : null;
  // const match = inputString ? inputString.match(/\d+(st|nd|rd|th)/) : null;
  useEffect(() => {
    AgreementPdf();
  }, []);

  const AgreementPdf = () => {
    const page = document.getElementById("divToPrintAgreement");
    html2canvas(page).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = pdfWidth * 0.8; // Adjust as needed
      const topMargin = 10;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const x = (pdfWidth - imgWidth) / 2;
      const y = topMargin + (pdfHeight - imgHeight - topMargin) / 2;

      pdf.addImage(imgData, "JPEG", x, y, imgWidth, imgHeight);

      window.open(pdf.output("bloburl"), "_blank");
    });
    // if (clearData && typeof clearData === 'function') {
    //   clearData();
    // }
    navigate("/listagreement");
  };

  const getFormattedDateTime = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${day}-${month}-${year}`;
  };
  const modDate = getFormattedDateTime(props?.state?.aggrEndDate)
  const modDateaggrRegistrationDate = getFormattedDateTime(props?.state?.aggrRegistrationDate)
  const modDateaggrStartDate = getFormattedDateTime(props?.state?.aggrStartDate) 
  
 

  return (
    <>
      <div className="container">
        <div id="divToPrintAgreement" style={{ padding: "5px" }}>
          <div style={{ border: "2px solid grey" }}>
            <div>
              <img src={Stamp} alt="stamp" className="stamp-image" />
            </div>
            <>
        {/* <div>
          <img src={Stamp} alt="stamp" className="stamp-image" />
        </div> */}
        <div >
          THIS AGREEMENT is executed at <b>{props?.state?.licensorLocation ? props?.state?.licensorLocation :" ______ "}</b> this day,
          the <b>{ modDateaggrRegistrationDate ? modDateaggrRegistrationDate :"____" }</b> between <b>{props?.state?.licensorTitle ? props?.state?.licensorTitle  : " ______ "}. {props?.state?.licensorName ? props?.state?.licensorName :" ______ "}</b> owner of {" "}
          <b>{props?.state?.licensorAddress ? props?.state?.licensorAddress: " ______ "}, {props?.state?.licensorSubLocation ? props?.state?.licensorSubLocation : " ______ "}, {props?.state?.licensorLocation ? props?.state?.licensorLocation : " ______ "}, {props?.state?.licensorPinCode ? props?.state?.licensorPinCode :" ______ "}</b>.
          (hereinafter called "the Licensor") of the First Part which expression shall unless it be
          repugnant to the context or meaning thereof mean and include their respective
          heirs, executors, administrators and assigns and <b>{props?.state?.LicenseeTitle ? props?.state?.LicenseeTitle: " ______ "}. {props?.state?.LicenseeName ? props?.state?.LicenseeName: " ______ "}</b> (hereinafter called “the Licensee”)
          residing at  <b>{props?.state?.LicenseeAddress ? props?.state?.LicenseeAddress : " ______ "}, {props?.state?.LicenseeSubLocation ? props?.state?.LicenseeSubLocation :" ______ "}, {props?.state?.LicenseeLocation ? props?.state?.LicenseeLocation :" ______ "}, {props?.state?.LicenseePinCode ? props?.state?.LicenseePinCode : " ______ "}</b>.
          which expression shall mean and include legal heirs, successor, legal representatives executors,
          nominee and assignees of the OTHER PART.
        </div>
        <br></br>
        <div>
          WHEREAS the Licensor has declared that he is the owner and in absolute possession of  <b>{props?.state?.propertyAddress ? props?.state?.propertyAddress : " ______ "}, {props?.state?.propertySubLocation ? props?.state?.propertySubLocation :" ______ "}, {props?.state?.propertyLocation ? props?.state?.propertyLocation: " ______ "}, {props?.state?.propertyPinCode ? props?.state?.propertyPinCode: " ______ "}</b>.
          having an area of <b> {props?.state?.squareFeet ? props?.state?.squareFeet: " ______ "} sq.feet </b> Super – comprising of <b> {props?.state?.noOfBedroom}</b> Bed Rooms with, <b> {props?.state?.noOfBathroom}</b> Common Bath Room and Toilet, <b>{props?.state?.drawingdinningroom}</b> drawing-dining room, <b>{props?.state?.kitchenroom}</b> kitchen,
          (Which flat is hereinafter referred to as "the Licensed Premises") bearing the <b> C.S.NO.{props?.state?.csNo ? props?.state?.csNo : " ______ "} </b> of <b>{props?.state?.propertySubLocation ? props?.state?.propertySubLocation :" ______ "}</b> Sewree Division and that the said premises is free from all encumbrances and that the Licensor has an absolute right,
          title and interest in the said premises including the right to give on Leave and License basis.
        </div>
        <br></br>
        <div>
          AND WHEREAS the Licensee being temporarily in need of premises for residential accommodation has approached the Licensor and requested the Licensor to enter into a Leave and License Agreement for residential use of the Licensed Premises by him & his family.
        </div>
        <br></br>
        <div>
          AND WHEREAS it has been arranged that the Licensee shall with the Leave and License of the Licensor have the rights to use the licensed premises upon the terms and conditions hereinafter mentioned.
        </div>
        <br></br>
        <div>
          <b>NOW IT IS HEREBY AGREED by and between the parties hereto as follows:</b>
        </div>
        <br></br>
        <div>
          1. The <b>{props?.state?.licensorName ? props?.state?.licensorName :" ______ "}</b> agree to lease to the <b>{props?.state?.LicenseeName ? props?.state?.LicenseeName: " ______ "}</b>, THE PREMISES regarding which the <b>{props?.state?.LicenseeName ? props?.state?.LicenseeName: " ______ "}</b> has been duly satisfied themselves, for a period commencing on <b>{modDateaggrStartDate ? modDateaggrStartDate :"_____"}</b> for a term of <b>{props?.state?.agreementDuration} </b> ending on <b>{ modDate ? modDate :"____" }</b> for the monthly rent of {props?.state?.MRentAmount ? <b>{props?.state?.MRentAmount}/- (Rupees {MRentAmount ? MRentAmount :"____"} Only).</b> :  " ______ "}The Licensee will enhance the rent by <b style={{ color: "red" }}>{props?.state?.rentpercentage}%</b> after the expiry of <b>{props?.state?.agreementDuration} </b> on the non-deposit portion  The <b>{props?.state?.LicenseeName ? props?.state?.LicenseeName: " ______ "}</b> shall have a right to use and enjoy in common with other residents entitled thereto the entrances, staircases, landings, corridors and passages in the building and the right of ingress to and engress from the <b>PREMISES</b> so far as the same are necessary for the enjoyment of the PREMISES by the <b>{props?.state?.LicenseeName ? props?.state?.LicenseeName: " ______ "}.</b>
        </div>
        <br></br>
        <div>
          2. The Licensee have the right to terminate the agreement by giving a notice of termination of the period of one month at least, and or in lieu of this notice it would become liable to pay and surrender an amount equal to one month rent. However in no circumstances the Licensee will vacate the said premises on or before <b>{props?.state?.noticePeriod} </b> i.e 6 months initial lock in period + 1 months notice period.  However after <b>{props?.state?.noticePeriod}</b> either of the party has the option to terminate the agreement after giving one months notice.
        </div>
        <br></br>
        <div>
          3. For the due performance of the terms of this agreement, the Licensee shall also deposit a <b>sum of Rs.</b> {props?.state?.securityDeposit ? <b>{props?.state?.securityDeposit}/- (Rupees {securityDepositInWords} Only).</b> :  " ______RS "}. The complete {props?.state?.securityDeposit ? <b>{props?.state?.securityDeposit}/- (Rupees {securityDepositInWords} Only).</b> :  " ______RS "}.  shall be refunded by the Licensor to the Licensee without interest only after handing over peaceful and vacant possession of the said premises, in accordance with this agreement, in perfect condition & after deducting dues, if any, incurred during the occupation of the flat by the Licensee.
        </div>
        <br></br>
        <div>
          4. The Licensee shall pay to the Licensor a monthly rent of <b> Rs{props?.state?.MRentAmount}/- </b>{MRentAmount } per month for the permissive use of the said flat.  The said monthly compensation shall be paid by the Licensee on or before the 15th day of each month. Monthly rent receipt for the above monthly rent shall be provided by the Licensor to the Licensee.
        </div>
        <br></br>
        <div>
          5. The Licensee further agrees and undertakes to pay all utilities i.e. <b style={{ color: "red" }}>electricity, gas, telephone bills </b> etc. & any other incidental charges consumed in the occupancy of the Licensee after the receipt of the bill from the concerned authorities without any delay or default. If any of the utilities supply gets disconnected due to non-payment or delay in payment or misuse, then the Licensee shall be bound to get the same restored instantly at his own cost.
        </div>
        <br></br>
        <div>
          6. The Licensee shall not make any alterations, modifications or 	additions in or to the licensed premises without prior permission in writing of the Licensor.
        </div>
        <br></br>
        <div>
          7. A duplicate key of the main entrance door of the licensed premises shall be given by the Licensor to licensee for his convenience. The Licensee shall not alter or suffer to be altered the existing locking devices on the said main entrance door of the licensed premises without prior written permission of the Licensor.
        </div>
        <br></br>
        <div>
          8. In the event of the License committing any breach of the terms and conditions herein contained and failing within 15 days to remedy or make good such breach on receipt of notice in writing from the Licensor specifying the breach complained of the Licensor shall be entitled to revoke this license in which event the Licensee shall vacate and give charge of the licensed premises to the Licensor within 15 days of such revocation.
        </div>
        <br></br>
        <div>
          9. It is agreed by and between the parties hereto that earlier expiration or earlier determination for any reason whatsoever of the said License Agreement the Licensor shall refund (but without interest) the said <b style={{ color: "red" }}>sum of Rs. 150000/-</b>(Rupees One Lack Fifty Thousand Only). to the Licensee after the Licensee vacate the Licensed Premises along with his articles, goods and chattels and giving charge thereof to the Licensor after deducting there from any unpaid amount due and payable by the Licensee in respect of electricity, water, gas, and telephone charges subject to proper verification of the building condition.
        </div>
        <br></br>
        <div>
          10. If applicable, the Licensor will obtain and NOC (No Objection Certificate) from the Society for giving on Leave and License the said licensed premises.
        </div>
        <br></br>
        <div>
          11. The Licensor shall observe and perform all the terms, conditions, Agreements, covenants and provisions on which the Licensor holds the Licensed premises and shall also observe and perform the Rules, Regulations and Bye-laws for the time being and from time to time in force of the said licensed premises and shall discharge all his liabilities (including rates, taxes, assessments, maintenance and other outgoings and non-occupancy charges) and all other amounts payable in respect of the licensed premises to the society or any other authority.
        </div>
        <br></br>
        <div>
          12. The Licensee hereby agrees and confirms that he shall use the licensed premises with due care and caution and observe the terms, conditions and provisions contained in this Agreement  in so far as they relate to the use of the licensed premises and shall not claim any tenancy sub-tenancy or other right or interest therein.
        </div>
        <br></br>
        <div>
          13. Nothing herein contained shall be construed as creating any right, interest, easement, tenancy or sub- tenancy in favour of the Licensee or over or upon the licensed premise or transferring any interest therein in favour of the Licensee other than the permissive right of use hereby granted.

          The Licensor shall be dejure in exclusive right of use hereby granted. The Licensor shall be dejure in exclusive possession and full charge and control of the licensed premises at all times which the de-fasto possession shall remain with the License. It is on the faith of the express assurance given and representations made by the Licensee as above and believing the same to be true that the Licensor has in good faith entered into this License Agreement with the Licensee.
        </div>
        <br></br>
        <div>
          14. Not to bring or store in or about the said flat any goods or merchandise of the hazardous or combustible nature or objectionable prohibited materials.
        </div>
        <br></br>
        <div>
          15. Not to use the premises for any purpose other than the specified one.
        </div>
        <br></br>
        <div>
          16. Not to object to or obstruct the Licensor from inspecting the said flat after fixing a prior appointment in that behalf.
        </div>
        <br></br>
        <div>
          17. Not to hold the Licensor responsible for any loss or damage suffered by the Licensee on account of manmade or natural calamities, disasters, theft, fire or other destruction caused to or in the Licensed premises.
        </div>
        <br></br>
        <div>
          18. Keep the Licensor always indemnified against any demand or demands or loss or damage and incidental costs, charges and expenses that may result on account of the negligence, default or any act of omission or commission on the part of the licensee and /or his servants in the said flat.
        </div>
        <br></br>
        <div>
          19. The Licensor shall not be responsible or liable for any loss or injury caused due to any reasons whatsoever in the said flat or in the said building to the Licensor or persons visiting the said flat whether voluntarily or at the invitation of the Licensee.
        </div>
        <br></br>
        <div>
          20. No pets will be allowed in the Apartment.
        </div>
        <br></br>
        <div>
          21. The Licensee shall pay to the cable TV & Internet charges used by him.
        </div>
        <br></br>
        <div>
          22. The parties hereto acknowledge, declare and confirm that this Agreement represents the entire Agreement between them regarding the subject matter hereof and no alterations, additions or modifications hereto shall be valid and binding unless the same are reduced in writing and signed by both the parties.
        </div>
        <br></br>
        <div>
          23. The cost of registration shall be borne equally by both parties.
        </div>
        <br></br>
        <div>
          24. The Cost of stamp Paper shall be borne equally by both parties.
        </div>
        <br></br>
        <div>
          25. Disputes, if any, shall be settled under Mumbai jurisdiction only.
        </div>
        <br></br>
        <div>
          26. Licensee cannot obtain ration card on Licensed premise.
        </div>
        <br></br>
        <div>
          <b>IN WITNESS WHEREOF the parties hereto have executed this Agreement (in duplicate) the day and year first written. </b>
        </div>
        <br></br>
        <div>
          <b>
            SIGNED & DELIVERED by <br></br>

            the within named Licensor <br></br>

            Mr. Pankaj Kadam <br></br>

            in the presence of <br></br>

            1)							<br></br>
            2)						  <br></br>
          </b>
        </div>
        <br></br>
        <div>
          <b>
          SIGNED & DELIVERED FOR AND <br></br>

          the within named Licensee <br></br>

          Mr. Mithilesh Bandivdekar		 <br></br>

          In the presence of 			<br></br>

          1)							<br></br>

          2)							<br></br>
          </b>
        </div>
     

    </>
          </div>
        </div>
      </div>
    </>
  );
}
