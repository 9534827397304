import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";

import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import { settings } from "../../../Config/Settings";

import { deleteproject, getallproject } from "../../../Api/ProjectAPI";
import AdminSidebar from "../../../Components/Sidebar/AdminSidebar";
function ProjectDetails() {
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  useEffect(() => {
    GetAllProperty();
  }, []);

  const GetAllProperty = () => {
    getallproject()
      .then((res) => {
        if (res.data.status === 1) {
          let details = res.data.projectdetails;
          setData(details);
          //  const responstData = JSON.parse(res.data.resultOutput);
          //  setData(responstData)
        }
      })
      .catch((err) => {
        console.log(err, "error in get all rent property.");
      });
  };

  const DeleteProperty = (rentpropertyid) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You are about to delete this record.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it",
      cancelButtonText: "No, cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteproject(rentpropertyid)
          .then((res) => {
            if (res.data.status === 1) {
              Swal.fire({
                icon: "success",
                title: "Project deleted successfully.",
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                // cancelButtonColor: '#d33',
                confirmButtonText: "ok",
              });
              GetAllProperty();
            } else {
              Swal.fire({
                icon: "error",
                title: "Something went wrong.",
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                // cancelButtonColor: '#d33',
                confirmButtonText: "ok",
              });
            }
          })
          .catch((err) => {
            console.log(err, "err in delete rent property.");
          });
      }
    });
  };
  return (
    <div>
      {/* <AdminHeader /> */}
      <AdminSidebar />

      <div className="feat-properties">
        <div className="container mt-2">
          <div
            className="content-box wow animated fadeIn"
            data-wow-duration="1s"
            data-wow-delay="1s"
          >
            <div className="content-title">
              <div className="content-name">
                <h3>Project List</h3>
              </div>
              <hr className="content-title-underline" />
            </div>
            <div className="container">
              <div className="row mb-3">
                {data &&
                  data.map((list, ind) => (
                    <div className="Property_Details-card">
                      <div className="property">
                        <div className="property-wrapper-home_detials">
                          <img
                            src={`${settings.IMAGE_URL}/${list.propertyImg1}`}
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                        <div className="property-info ">
                          <p className="property-name">
                            Made for usage, commonly searched for. Fork, like
                            and use it. Just move the carousel div above the col
                            containing the text for left alignment of images
                          </p>
                          <div className="projectlist-infodesc">
                            <div className="row p-2">
                              <div className="col-md-6">
                                <span className="propertyInfoTitle">Price</span>
                              </div>
                              <div className="col-md-6">
                                <p className="property-price_details">
                                  &#x20B9;
                                  {"" + list.Price}
                                </p>
                              </div>
                            </div>
                            <div className="row p-2">
                              <div className="col-md-6">
                                <span className="propertyInfoTitle">
                                  Carpet Area
                                </span>
                              </div>
                              <div className="col-md-6">
                                <p className="property-price_details">
                                  450 sqft
                                </p>
                              </div>
                            </div>
                            <div className="row p-2">
                              <div className="col-md-6">
                                <span className="propertyInfoTitle">
                                  Furnished
                                </span>
                              </div>
                              <div className="col-md-6">
                                <p className="property-price_details">
                                  semi Furnished
                                </p>
                              </div>
                            </div>
                            <div className="row p-2">
                              <div className="col-md-6">
                                <span className="propertyInfoTitle">
                                  Car Parking
                                </span>
                              </div>
                              <div className="col-md-6">
                                <p className="property-price_details">1</p>
                              </div>
                            </div>
                            <div className="row p-2">
                              <div className="col-md-6">
                                <span className="propertyInfoTitle">
                                  Location
                                </span>
                              </div>
                              <div className="col-md-6">
                                <p className="property-price_details">
                                  {list.Location}
                                </p>
                              </div>
                            </div>

                            <div className="row p-2">
                              <div className="col-md-6">
                                <span className="propertyInfoTitle">
                                  Sub Location
                                </span>
                              </div>
                              <div className="col-md-6">
                                <p className="property-price_details">
                                  {list.subLocation}
                                </p>
                              </div>
                            </div>

                            <div className="row p-2">
                              <div className="col-md-6">
                                <span>Amenities</span>
                              </div>
                              <div className="col-md-6">
                                <p>{list.Amenities}</p>
                              </div>
                            </div>

                            <div className="row p-2">
                              <div className="col-md-6">
                                <span>Posted By</span>
                              </div>
                              <div className="col-md-6">
                                <p>{list.postedBy}</p>
                              </div>
                            </div>

                            <div className="row p-2">
                              <div className="col-md-6">
                                <span>Others</span>
                              </div>
                              <div className="col-md-6">
                                <p>-</p>
                              </div>
                            </div>

                            <div className="property-footer">
                              <Button
                                size="sm"
                                variant="secondary"
                                onClick={() =>
                                  navigate("/UpdateProjectDetails", {
                                    state: list,
                                  })
                                }
                              >
                                Edit
                              </Button>
                              <Button onClick={() => DeleteProperty(list._id)}>
                                Delete
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectDetails;
