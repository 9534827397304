import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
// react-bootstrap
import { Col, Container, Row, Modal } from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';

// @mui packages
import Tooltip from "@mui/material/Tooltip";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
// react packages
import ImageGallery from "react-image-gallery";
//icons
import { FormControl, IconButton } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SquareFootOutlinedIcon from '@mui/icons-material/SquareFootOutlined';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import InfoTwoToneIcon from "@mui/icons-material/InfoTwoTone";
import { FaRegFilePdf } from "react-icons/fa";
//other packages
import moment from "moment";
//custom components
import AdminSidebar from "../../Components/Sidebar/AdminSidebar";
import builderimg from "../../Assets/img1.avif"
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import { RiSecurePaymentLine } from "react-icons/ri";
import { FaMapLocationDot } from "react-icons/fa6";
import { MdConfirmationNumber } from "react-icons/md";
import { MdEmail } from "react-icons/md";
import { BsFillPersonLinesFill } from "react-icons/bs";
import { MdContactPhone } from "react-icons/md";
import { FaAddressCard } from "react-icons/fa6";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import HolidayVillageOutlinedIcon from '@mui/icons-material/HolidayVillageOutlined';
//Api
import {
  getBuilderProjectByPropertyId,
  getDocumentType,
  getCertificatebyDocumenttypeID,
  getReviewsByUserID
} from "../../Api/BuilderProjectAPI";
//Auth
import Auth from "../../Config/helper/Auth";
import { format } from "date-fns";
import UserImage from "../../Assets/user.png";
import { getAllReview } from "../../Api/ReviewAPI";


import PhoneIcon from '@mui/icons-material/Phone';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
//Tabs functinality
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}
const NumberSeparator = (input) => {
  return new Intl.NumberFormat("en-IN", {
    minimumFractionDigits: 0,
  }).format(input);
};


CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const BuilderProjectFulltab = () => {
  let location = useLocation();
  let userId = Auth?.getUserDetails()._id;

  let projectData = location?.state?.projectData;
  // console.log(projectData, "projectData...")

  const [projectDataById, setProjectDataById] = useState([]);
  const [projectImage, setProjectImage] = useState([]);
  const [showImagesData, setShowImagesData] = useState([]);
  const [showVideo, setShowVideo] = useState([]);
  const [show, setShow] = useState(false);
  const [videoView, setVideoView] = useState(false);
  const [data, setData] = useState([]);
  const [certificateType, setCertificateType] = useState([]);
  const [certificateDatas, setCertificateDatas] = useState([]);
  const [reviews, setReviews] = useState([]);
  console.log(reviews, "reviews....");
  const [value, setValue] = useState(0);



  // const filteredAttachments = projectDataById?.certificates?.attachment?.filter(attach => attach.documentTypeName === value);
  // const uniqueAttachments = projectDataById?.certificates?.attachment?.reduce((acc, current) => {
  //   const x = acc.find((item) => item.documentTypeName === current.documentTypeName);
  //   if (!x) {
  //     return acc.concat([{ ...current, count: 1 }]);
  //   } else {
  //     return acc.map((item) =>
  //       item.documentTypeName === current.documentTypeName
  //         ? { ...item, count: item.count + 1 }
  //         : item
  //     );
  //   }
  // }, []);

  // const handleChangeTabs = (event, newValue) => {
  //   setValue(newValue);
  // };

  useEffect(() => {
    if (uniqueAttachments?.length > 0) {
      setValue(uniqueAttachments[0]?.documentTypeName);
    }
  }, [projectDataById]);

  const filteredAttachments = projectDataById?.certificates?.attachment?.filter(
    (attach) => attach.documentTypeName === value
  );

  const uniqueAttachments = projectDataById?.certificates?.attachment?.reduce((acc, current) => {
    const x = acc.find((item) => item.documentTypeName === current.documentTypeName);
    if (!x) {
      return acc.concat([{ ...current, count: 1 }]);
    } else {
      return acc.map((item) =>
        item.documentTypeName === current.documentTypeName
          ? { ...item, count: item.count + 1 }
          : item
      );
    }
  }, []);

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };

  // useEffects
  useEffect(() => {
    getCertificateType();
    builderDataByPropertyId();
    getReviewsByuserId();
  }, []);

  const builderDataByPropertyId = () => {
    getBuilderProjectByPropertyId(projectData?._id)
      .then((res) => {
        if (res.data.status === 1) {
          setProjectDataById(res.data.data);
          setProjectImage(res.data.data.photos.projectImages);
          setShowImagesData(res.data.data.photos);
        } else {
          console.log("data not found");
        }
      })
      .catch((err) => {
        console.log(err, "error in fulltab");
      });
  }

  const getCertificateType = () => {
    getDocumentType()
      .then((res) => {
        if (res.data.success === 1) {
          setCertificateType(res.data.data);
        } else {
          console.log("data not found in certificate");
          setCertificateType([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getReviewsByuserId = () => {
    getAllReview(projectData._id)
      .then((res) => {
        if (res.data.status === 200) {
          setReviews(res.data.recentReviews)
        } else {
          console.log("review data not found");
          setReviews([])
        }
      }).catch((err) => {
        console.log(err, "error in review get call");
      })
  }

  const showVideos = (a) => {
    setVideoView(true);
    a?.uploadVideo?.map((b) => {
      setShowVideo(b.name);
    });
  };

  const showProjectPhotos = (a) => {
    setVideoView(false);
    a.projectImages.map((b) => {
      setShowImagesData(b.name);
    });
  };

  const showMasterPlanPhotos = (a) => {
    setVideoView(false);
    a?.masterPlanImages?.map((b) => {
      setShowImagesData(b.name);
    });
  };

  const showLocationPlan = (a) => {
    setVideoView(false);
    a?.locationPlanImages?.map((a) => {
      setShowImagesData(a.name);
    });
  };

  const showFloorPlan = (a) => {
    setVideoView(false);
    a?.floorPlanImages?.map((a) => {
      setShowImagesData(a.name);
    });
  };

  const handleClose = () => {
    setShow(false);
    setShowImagesData([]);
    setShowVideo([]);
  };

  const imageData = "";

  const projectImages = projectImage.map((a) => {
    return {
      original: a.name,
      thumbnail: "",
    };
  });

  // Combining both arrays if needed
  const combinedImages = [
    ...projectImages,
    {
      original: imageData,
      thumbnail: "",
    },
  ];

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
    getCertificatebyDocumenttypeID(newValue, projectDataById._id)
      .then((res) => {
        if (res.data.status === 1) {
          let result = res.data.certificatesData;
          setCertificateDatas(result);
        } else {
          console.log("data not found");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const downloadBrochure = (brochureData) => {
    const { brochureTitle, name, _id } = brochureData[0];
    window.open(name, "_blank");
  };

  const DownloadCertificate = (certificateData) => {
    const { name } = certificateData;
    window.open(name, "_blank");
  };

  const openImageGallery = (a) => {
    setShow(true);
    setData(a);
  };

  // console.log(projectDataById, "projectDataById...fulltab...");

  const renderStars = (star) => {
    const stars = [];
    for (let i = 0; i < star; i++) {
      stars.push(
        <span key={i} className="star">
          &#9733;
        </span>
      );
    }
    return stars;
  };
  const formatDate = (dateString) => {
    if (dateString !== undefined) {
      const date = new Date(dateString);
      return format(date, "dd MMMM yyyy");
    }
  };

  const formatCash = (n) => {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e5) return +(n / 1e3).toFixed(1) + "K";
    if (n >= 1e5 && n < 1e7) return +(n / 1e5).toFixed(1) + "L";
    if (n >= 1e7 && n < 1e9) return +(n / 1e7).toFixed(1) + "cr";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
  };

  const propertyImages =
    projectDataById?.photos?.projectImages?.map((data) => ({
      original: data.name || '',
      thumbnail: data.name || '',
    })) || []

  return (
    <div>
      <AdminSidebar />
      <Container fluid className="topNav-m">
        {/* <div className="builder-img position-relative">
          <img src={projectDataById?.photos?.projectImages[0]?.name} alt="" /> */}

        {/* <div className="position-absolute top-50 builder-project-details">
          <div className="container">
            <h1>Project Name</h1>
            </div>
            </div> */}
        {/* </div> */}

        <Col lg={12} md={12} sm={12}>
          <div className="img-gallery">
            {/* <ImageGallery
                    items={propertyImages}
                    showIndex={"true"}
                    autoPlay={"true"}
                    className="img-fluid"
                  /> */}
            {propertyImages && propertyImages.length > 0 ? (
              <ImageGallery
                items={propertyImages}
                showIndex={true}
                autoPlay={true}
                className="img-fluid"
              />
            ) : (
              <ImageGallery
                items={[
                  {
                    original: 'https://api.equireal.co.in/defaultProperty.jpg',
                    thumbnail: 'https://api.equireal.co.in/defaultProperty.jpg',
                  },
                ]}
                showIndex={true}
                autoPlay={true}
                className="img-fluid"
              />
            )}


          </div>
        </Col>

      </Container>
      <Container className=" prop-details">

        {/* <div className="project-details">
          <div className="projheader">
            <div className="img-block">
              {projectImage.length !== 0 ? (
                // projectImage?.map((a) => (
                <div
                  className="img-gallery"
                // onClick={() => openImageGallery(showImagesData)}
                >
                  <div className="img-gallery">
                    <ImageGallery
                      items={projectImages}
                      showIndex={"true"}
                      autoPlay={"true"}
                      className="img-fluid"
                    />
                  </div>
                </div>
              ) : (
                // ))
                <p>Image not found</p>
              )}
            </div>
          </div>
        </div> */}
        <Row className="py-2">
          <Col md={6} sm={12}>
            <div className="d-flex gap-5">
              <div className="b-det">
                <span class="badge badge-project">Premium Project</span>
                <h1>{projectDataById?.nameOfProject}</h1>
                <p>By {projectDataById?.propertyOwner}</p>
                <p className="proj-info__address">
                  <LocationOnIcon /> {projectDataById?.subLocation},{" "}
                  {projectDataById?.Location}
                </p>
                {/* <p>
                  Possession by{" "}
                  {moment(projectDataById?.launchDate).format("MMM YYYY")}
                  <Tooltip
                    title=" This data is fetched &amp; verified by RERA"
                    placement="right"
                    arrow
                    slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: "offset",
                            options: {
                              offset: [0, -14],
                            },
                          },
                        ],
                      },
                    }}
                  >
                    <InfoTwoToneIcon fontSize="12" />
                  </Tooltip>
                </p> */}

                <Row className="pt-4">
                  <Col >
                    <Accordion>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>Contact Person : <b>  {projectDataById?.FullName}</b></Accordion.Header>
                        <Accordion.Body>
                          <div className="">

                            <p>
                              <b>{projectDataById?.FullName}</b>
                            </p>

                            <p>
                              {projectDataById?.Address}
                            </p>
                            <p>
                              <EmailOutlinedIcon /> <a href={`mailto:${projectDataById?.email}`}>{projectDataById?.email}</a>
                            </p>
                            <p>
                              <PhoneIcon /> <a href={`tel:${projectDataById?.phone}`}>{projectDataById?.phone}</a>
                            </p>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>


                    </Accordion>

                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col md={6} sm={12}>
            <div className="price-card ">
              <div>
                <h4 className="fw-bold">
                  {" "}
                  ₹{formatCash(projectDataById?.minPrice)}  - ₹{formatCash(projectDataById?.maxPrice)}{" "}
                </h4>
                <p>
                  {projectDataById?.areaType
                    ?.map((areatype) => parseInt(areatype.split(" ")[0]))
                    .join(", ")}{" "}
                  BHK Flats{" "}
                  <span class="badge badge-project mx-4">
                    {projectDataById?.totalUnits} units available
                  </span>{" "}
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="py-2 border-0">
          <Col >
            <h3>About {projectDataById?.nameOfProject}</h3>
            <div className="pr-about">
              <div className="pr-card"><div className="d-flex align-items-center">
                <AspectRatioIcon />  Status</div>
                <span>{projectDataById?.isVerified}</span>
              </div>
              <div className="pr-card">
                <div className="d-flex align-items-center">
                  <EventOutlinedIcon />

                  Launch Date</div>
                <span>
                  {moment(projectDataById?.launchDate).format("MMM YYYY")}
                </span>
              </div>
              <div className="pr-card">
                <div className="d-flex align-items-center"><HomeOutlinedIcon />
                  Total Units</div>
                <span>{projectDataById?.totalUnits}</span>
              </div>
              {/* <div className="pr-card">Total Towers

                <span>2</span>
              </div> */}
              <div className="pr-card">
                <div className="d-flex align-items-center">
                  <HolidayVillageOutlinedIcon />

                  BHK</div>
                <span>
                  {projectDataById?.areaType
                    ?.map((areatype) => parseInt(areatype.split(" ")[0]))
                    .join(", ")}
                </span>
              </div>

              <div className="pr-card">
                <div className="d-flex align-items-center"><MdConfirmationNumber />
                  Total ReraNo</div>
                <span>{projectDataById?.reraNo}</span>
              </div>
              <div className="pr-card">
                <div className="d-flex align-items-center"><EventOutlinedIcon />
                  Possesion Date</div>
                <span>{moment(projectDataById?.possessionDate).format("MMM YYYY")}</span>
              </div>

              <div className="pr-card">
                <div className="d-flex align-items-center"><RiSecurePaymentLine />
                  Payment Scheme</div>
                <span>{projectDataById?.paymentScheme}</span>
              </div>


            </div>
          </Col>
        </Row>




        <Row className="py-2">
          <Col >
            <h3>Amenities of {projectDataById?.nameOfProject}</h3>
            <div className="b-amenities-box">
              {projectDataById?.Amenities?.map((amintities) => (
                <div className="b-amenities-card">{amintities}</div>
              ))}
            </div>
          </Col>
        </Row>



        {/* {projectDataById?.configuration?.length > 0 ?
          <Row className="py-1">
            <Col>
              <h3>Project Configuration</h3>

              {projectDataById?.configuration?.map((configData) => (
                <>
                  <div className="b-floorplan-box pt-2">
                    <div className="b-floorplan-card">
                      <div className="b-floorplan-content">
                        <h5 className="fw-bold">
                          PropertyType : {configData?.areatypeId?.AreaType}
                        </h5>
                      </div>

                      <Col md={6} sm={12}>
                        <div className="d-flex align-items-center">
                          Area Title : {configData.areaTitle}
                        </div>
                        <div>
                          <div className="d-flex align-items-center">
                            AreaSqft : {configData.areaSqft}
                          </div>
                        </div>
                      </Col>
                      <Col md={6} sm={12}>
                        <div>
                          <div className="d-flex align-items-center">
                            Cost :{configData.cost}
                          </div>
                        </div>
                        <div>
                          <div className="d-flex align-items-center">
                            Count : {configData.counter}
                          </div>
                        </div>
                      </Col>
                    </div>
                  </div>
                </>
              ))}

            </Col>
          </Row>
          :
          <Row className="msg-container">
            <div className="d-flex align-items-center gap-4">
              <h4 className="m-0">Project Configuration</h4>
              <p className="sorry-msg">Sorry, the information is currently unavailable.</p>
            </div>
          </Row>
        } */}


        {projectDataById?.brochurePdf?.length > 0 ?
          <Row>
            <h4>Brochure</h4>
            <div>
              <div>
                <span className="iconMagnifier">

                  <div>
                    <a href={projectDataById?.brochurePdf[0].name} target="_blank" rel="noopener noreferrer">
                      {/* <img src={projectDataById?.brochurePdf[0].name} width="195" height="112" alt="Certificate" /> */}
                      <iframe
                        src={projectDataById?.brochurePdf[0].name}
                        width="195"
                        height="112"
                        style={{ border: 'none' }}
                        title="Certificate"
                      />
                    </a>
                  </div>
                </span>
              </div>
            </div>
          </Row>
          :
          <Row className="msg-container">
            <div className="d-flex align-items-center gap-4">
              <h4 className="m-0">Brochure</h4>
              <p className="sorry-msg">Sorry, the information is currently unavailable.</p>
            </div>
          </Row>
        }


        {uniqueAttachments?.length > 0 ?
          <Row className="py-2">
            <Box sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <h4>
                    Certificates
                    <p
                      title="Total count of certificates"
                      style={{
                        marginLeft: '8px',
                        padding: '0px 6px',
                        backgroundColor: 'red',
                        borderRadius: '50%',
                        color: 'white',
                        display: 'inline-block',
                        textAlign: 'center',
                        fontSize: '1rem',
                      }}
                    >
                      {projectDataById?.certificates?.attachment?.length || 0}
                    </p>
                  </h4>

                  <TabList onChange={handleChangeTabs} aria-label="lab API tabs example">
                    {uniqueAttachments?.map((a, index) => {
                      const count = projectDataById.certificates.attachment.filter(
                        (attachment) => attachment.documentTypeName === a.documentTypeName
                      ).length;
                      return (
                        <Tab
                          key={index}
                          label={`${a.documentTypeName} (${count})`}
                          value={a.documentTypeName}
                        />
                      );
                    })}
                  </TabList>

                </Box>
                <TabPanel value={value}>
                  <div className="attachments-container">

                    {filteredAttachments?.map((attach) => (
                      <div className="builder-pics" key={attach._id}>
                        <div className="b-pic">
                          <div>
                            <a href={attach.name} target="_blank" rel="noopener noreferrer">
                              <div>
                                <span className="iconMagnifier"></span>
                                <embed src={attach?.name} width="195" height="112" alt="Certificate" />
                              </div>
                            </a>
                          </div>
                          <FormControl variant="outlined" fullWidth size="small">
                            <span style={{ fontWeight: 'bold', textAlign: 'center' }}>
                              <span>{attach?.documentTypeName}</span>
                              <span style={{ marginLeft: '10px' }}>{attach?.attachmentName}</span>
                            </span>
                          </FormControl>
                        </div>
                      </div>
                    ))}
                  </div>
                </TabPanel>
              </TabContext>
            </Box>
          </Row>

          :
          <Row className="msg-container">
            <div className="d-flex align-items-center gap-4">
              <h4 className="m-0">Certificates</h4>
              <p className="sorry-msg">Sorry, the information is currently unavailable.</p>
            </div>
          </Row>

        }





        {/* {projectDataById?.photos?.projectImages.length > 0 ?

          <Row className="py24">
            <Col>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <h4> Project Images</h4>
                <div style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
                  {projectDataById?.photos?.projectImages?.map(
                    (a, index) => (
                      <img
                        key={`project-${index}`}
                        src={a.name}
                        height={200}
                        width={300}
                      />
                    )
                  )}
                </div>
              </div>
            </Col>
          </Row>

          : <div>
            <h4>Project Images</h4>
            <p style={{
              margin: 0,
              padding: 0,
              lineHeight: 1.2,
              color: '#6c757d',
            }}>Sorry, the information is currently unavailable.</p>
          </div>
        } */}


        {projectDataById?.photos?.uploadVideo.length > 0 ?
          <Row className="py-2">
            <Col>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <h4> Project Videos</h4>
                <div style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
                  {
                    projectDataById?.photos?.uploadVideo?.map((a, index) => (
                      <iframe
                        key={`project-${index}`}
                        src={a.name}
                        height={200}
                        width={300}
                      />
                    ))
                  }
                </div>
              </div>
            </Col>
          </Row>

          :
          <Row className="msg-container">
            <div className="d-flex align-items-center gap-4">
              <h4 className="m-0">Project Videos</h4>
              <p className="sorry-msg">Sorry, the information is currently unavailable.</p>
            </div>
          </Row>
        }


        {projectDataById?.photos?.masterPlanImages.length > 0 ?
          <Row className="py-2">
            <Col>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <h4>Master plan Images</h4>

                <div style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>

                  {projectDataById?.photos?.masterPlanImages?.map((a, index) => (
                    <img
                      key={`project-${index}`}
                      src={a.name}
                      height={200}
                      width={300}
                    />
                  ))}
                </div>
              </div>
            </Col>
          </Row>
          :
          <Row className="msg-container">
            <div className="d-flex align-items-center gap-4">
              <h4 className="m-0">Master Plan Images</h4>
              <p className="sorry-msg">Sorry, the information is currently unavailable.</p>
            </div>
          </Row>
        }





        {projectDataById?.photos?.locationPlanImages.length > 0 ?

          <Row className="py-2">
            <Col>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <h4> location Plan Images</h4>
                <div style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
                  {projectDataById?.photos?.locationPlanImages?.map(
                    (a, index) => (
                      <img
                        key={`project-${index}`}
                        src={a.name}
                        height={200}
                        width={300} alt=""
                      />
                    )
                  )}
                </div>
              </div>
            </Col>
          </Row>

          :
          <Row className="msg-container">
            <div className="d-flex align-items-center gap-4">
              <h4 className="m-0">Location Plan Images</h4>
              <p className="sorry-msg">Sorry, the information is currently unavailable.</p>
            </div>
          </Row>
        }


        {projectDataById?.configuration?.length > 0 ? (
          <Row className="py-2">
            <Col>

              <>
                <h3>{projectDataById?.nameOfProject} Floor Plan & Units</h3>
                <div className="b-floorplan-box pt-3">

                  {projectDataById?.areaType?.map((areatype, index) => {
                    let filterData = projectDataById?.configuration?.filter((newData) => newData.areatypeId.AreaType === areatype);
                    const images = projectDataById?.photos?.floorPlanImages.filter(image => image.areatypeId.AreaType === areatype);
                    return filterData.map((configData, configIndex) => (
                      <React.Fragment key={`${index}-${configIndex}`}>
                        <div className="b-floorplan-card">
                          <div className="b-floorplan-content">
                            <h5>{areatype} Flat</h5>
                          </div>
                          {/* <div className="b-floorplan-content"> */}
                          <div className="b-floorplan-content-info">
                            <h6>{configData.areaTitle}</h6>
                            <h6><SquareFootOutlinedIcon /> {configData.areaSqft} Sqft</h6>
                            <h6><SellOutlinedIcon />₹{configData.cost}/-</h6>
                            <h6><ApartmentOutlinedIcon /> {configData.counter} units</h6>
                          </div>

                          <div className="b-floorplan-img">
                            {images.length > 0 ? (
                              images.map((image, imgIndex) => (
                                <img
                                  key={imgIndex}
                                  src={image.name}
                                  height="100px"
                                  width="100px"
                                  className="img-project"
                                  alt=""
                                />
                              ))
                            ) : (
                              <p>No image available</p>
                            )}
                          </div>
                          {/* </div> */}
                        </div>
                      </React.Fragment>
                    ));
                  })}
                </div>
              </>
            </Col>
          </Row>
        )
          : <Row className="msg-container">
            <div className="d-flex align-items-center gap-4">
              <h4 className="m-0"> Floor Plan & Units</h4>
              <p className="sorry-msg">Sorry, the information is currently unavailable.</p>
            </div>
          </Row>}
        {projectDataById?.locationBenefits ?
          <Row className="py-2">
            <Col>
              <h3> Advantages of this Location</h3>
              <div>
                <span>{projectDataById?.locationBenefits}</span>
              </div>
            </Col>
          </Row>
          :
          <Row className="msg-container">
            <div className="d-flex align-items-center gap-4">
              <h4 className="m-0"> Advantages of this Location</h4>
              <p className="sorry-msg">Sorry, the information is currently unavailable.</p>
            </div>
          </Row>}


        {reviews.length > 0 ?
          <Row className="py-2">
            <Col className="py-2">
              <h3>Reviews & Ratings</h3>
              {reviews && reviews.map((item, index) => (
                <div key={index} className="customer-review">
                  <div className="user-info">
                    <img
                      src={item.ProjectData?.profileImage ? item.ProjectData?.profileImage : UserImage}
                      alt="Profile"
                      className="profile-photo"
                    />
                    <div className="user-details">
                      <p>{item?.mostRecent?.UserId?.fullName}</p>
                      <div className="rating ps-2">{renderStars(item?.mostRecent?.Rating)}</div>
                    </div>
                  </div>
                  <div className="comment-section">
                    <p className="comment">{item?.mostRecent?.Comment}</p>
                    <div className="review-date">{formatDate(item?.mostRecent?.createdAt)}</div>
                  </div>
                </div>
              ))}
            </Col>
          </Row>
          :
          <Row className="msg-container">
            <div className="d-flex align-items-center gap-4">
              <h4 className="m-0">Review & Ratings</h4>
              <p className="sorry-msg">Sorry, the information is currently unavailable.</p>
            </div>
          </Row>
        }

      </Container>

      <Modal
        show={show}
        onHide={handleClose}
        centered
        backdrop="static"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>The Views</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Col></Col>

          <div className="row d-flex ">
            <div className="col-sm-2 col-md-2">
              <button
                className="btn-black"
                type="submit"
                onClick={() => showVideos(data)}
              >
                Project Videos
              </button>
            </div>
            <div className="col-sm-2 col-md-2">
              <button
                className="btn-black"
                type="submit"
                onClick={() => showProjectPhotos(data)}
              >
                Project photos
              </button>
            </div>
            <div className="col-sm-2 col-md-2">
              <button
                className="btn-black"
                type="submit"
                onClick={() => showMasterPlanPhotos(data)}
              >
                Matster Plan
              </button>
            </div>

            <div className="col-sm-2 col-md-2">
              <button
                className="btn-black"
                type="submit"
                onClick={() => showLocationPlan(data)}
              >
                Location Plan
              </button>
            </div>
            <div className="col-sm-2 col-md-2">
              <button
                className="btn-black"
                type="submit"
                onClick={() => showFloorPlan(data)}
              >
                Floor Plan
              </button>
            </div>
          </div>
          {videoView !== false ? (
            <iframe src={showVideo} />
          ) : (
            <div className="d-flex justify-content-center mt-5">
              <img src={showImagesData} height="200" width="500" />
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default BuilderProjectFulltab;
