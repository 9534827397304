import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// @mui packages
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { TextField, FormControl } from "@mui/material";
import { IconButton } from "@material-ui/core";
//others packages
import moment from "moment";
import { Modal } from "react-bootstrap";
import Swal from "sweetalert2";
//icons
import EditIcon from '@mui/icons-material/Edit';
import PreviewIcon from "@mui/icons-material/Preview";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";

//Api
import { deleteAgreement, listOfAgreement } from "../../../Api/AgreementAPI";
//custom components
import Preview from "./Preview";
import AdminSidebar from "../../../Components/Sidebar/AdminSidebar.js";

const ListAgreement = () => {
  const navigate = useNavigate()
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [listData, setListData] = useState([]);
  const [agreementPrev, setAgreementPrev] = useState(false);
  const [previewData, setPreviewData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const columns = [
    { id: "id", label: "Sr. No.", minWidth: 40 },
    { id: "OwnersFullName", label: "Owner's Name", minWidth: 170 },
    { id: "TenantFullName", label: "Tenant's Name", minWidth: 100 },
    {
      id: "type",
      label: "Property Type",
      minWidth: 170,
    },
    {
      id: "AddressOfRentedPropertyLine1",
      label: "Property Address",
      minWidth: 170,
    },
    {
      id: "AgreementStartDate",
      label: "Agreement Start Date",
      minWidth: 170,
    },
    {
      id: "AgreementDuration",
      label: "Agreement Duration",
      minWidth: 170,
    },
  ];

  // useEffects
  useEffect(() => {
    agreementList();
  }, []);

  const agreementList = () => {
    listOfAgreement()
      .then((res) => {
        if (res.data.status === 1) {
          let result = res.data.RentAgreementDetails;
          setListData(result);
        } else {
          setListData([]);
        }
      })
      .catch((e) => {
        console.log("====================================");
        console.log(e, "error in agreementList");
        console.log("====================================");
      });
  };
  //handleChange
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClose = () => {
    setAgreementPrev(false);
  };

  const editDocument = (rowData) => {
    console.log(rowData,"rowData....")
    navigate('/agreement', { state: rowData })
  }
  // DAta fields
  function createData(
    id,
    OwnersFullName,
    TenantFullName,
    // isPropertyResidential,
    PropertyType,
    AddressOfRentedPropertyLine1,
    AgreementStartDate,
    AgreementDuration
  ) {
    const type = PropertyType;
    AgreementStartDate = moment(AgreementStartDate).format("L");
    return {
      id,
      OwnersFullName,
      TenantFullName,
      type,
      PropertyType,
      AddressOfRentedPropertyLine1,
      AgreementStartDate,
      AgreementDuration,
    };
  }

  //rows search
  const rows = listData
    .filter((list) =>
      list.OwnersFullName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      list.TenantFullName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      list.AddressOfRentedPropertyLine1.toLowerCase().includes(searchQuery.toLowerCase()) ||
      list.PropertyType.toLowerCase().includes(searchQuery.toLowerCase()) ||
      moment(list.AgreementStartDate).format("DD-MM-YYYY HH:mm:ss").toLowerCase().includes(searchQuery.toLowerCase()) ||
      list.AgreementDuration.toString().toLowerCase().includes(searchQuery.toLowerCase())

    )
    .map((list, i) => {
      return createData(
        i + 1,
        list.OwnersFullName,
        list.TenantFullName,
        list.PropertyType,
        list.AddressOfRentedPropertyLine1,
        list.AgreementStartDate,
        list.AgreementDuration
      );
    });


  const viewDocument = (rowData) => {
    setAgreementPrev(true);
    setPreviewData({
      // form 1
      ownerTitle: rowData.OwnersGenderTitle,
      ownerName: rowData.OwnersFullName,
      ownerMobileNo: rowData.OwnersPhoneNumber,
      ownerCity: rowData.OwnersCity,
      ownerState: rowData.OwnersState,
      ownerAddress: rowData.OwnersPermanantAddressLine1,
      ownerEmail: "",
      ownerPinCode: rowData.OwnerPincode,

      // form 2
      tenantTitle: rowData.TenantsGenderTitle,
      tenantName: rowData.TenantFullName,
      tenantMobileNo: rowData.TenantPhoneNumber,
      tenantCity: rowData.TenantsCity,
      tenantState: rowData.TenantState,
      tenantAddress: rowData.TenantPermanantAddressLine1,
      tenantEmail: rowData.TenantEmailAddress,
      tenantPinCode: rowData.TenantPincode,

      // form 3
      property:rowData.PropertyType,
        // rowData.isPropertyResidential === true ? "Residential" : "Commercial",

      selectFloor: rowData.selectFloorNumberofProperty,
      room: rowData.SelectnosofRoomsforProperty,

      otherRoom: "",
      noOfBalcony: rowData.SelectnosofBalcony,
      noOfBathroom: rowData.SelectnosofBathrooms,
      noOfBedroom: null,

      propertyCity: rowData.cityofRentedProperty,
      propertyState: rowData.StateofRentedProperty,
      propertyAddress: rowData.AddressOfRentedPropertyLine1,
      propertyPinCode: rowData.pincodeOfRentedProperty,

      parkingSpace: rowData.isParkingAvailable === false ? "NO" : "YES",
      squareFeet: rowData.PropertCarpetyArea,

      // form 4
      furnishedStatus: rowData.furnishStatus,
      //   furnishingItem: null,

      furnishingItem: {
        AC: rowData.nosOfAirConditioner,
        WashingMachine: rowData.nosOfWashingMachine,
        Sofa: rowData.nosOfSofa,
        Bed: rowData.nosOfBed,
        Wardrobe: rowData.nosOfWardrobe,
        Table: rowData.nosOfTable,
        Geyser: rowData.nosOfgeyser,
        Stove: rowData.nosofSteve,
        Fans: rowData.nosofFans,
        Tubelights: rowData.nosofTubelights,
      },
      // form 5
      aggrStartDate: rowData.AgreementStartDate,
      agreementDuration: rowData.AgreementDuration,
      rentPaymentDate: rowData.MonthlyRentPaymentDate,
      MRentAmount: rowData.MonthlyRentAmount,
      MCharges: rowData.MonthlyMaintainceCharges,
      securityDeposit: rowData.SecurityDeposit,
      noticePeriod: rowData.NoticePeriod,
      terms: "",
    });
  };

  //delete agreement
  const deleteAgreementData = (aggId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You are about to delete this record.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it",
      cancelButtonText: "No, cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteAgreement(aggId)
          .then((res) => {
            if (res.data.status === 1) {
              Swal.fire({
                icon: "success",
                title: "Agreement Data deleted successfully.",
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "ok",
              });
              agreementList();
            } else {
              Swal.fire({
                icon: "error",
                title: "Something went wrong.",
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "ok",
              });
            }
          })
          .catch((err) => {
            console.log(err, "err in delete commercial property.");
          });
      }
    });
  };

  return (
    <>
      <AdminSidebar />
      <div className="topNav-m">
      <div className="mt-5 d-flex justify-content-center">
        <Paper
          sx={{ width: "80%", overflow: "hidden", justifyContent: "center" }}
        >
          <div className="text-center p-2 mt-5">
            <h5>Agreement List </h5>
          </div>
          <div className="search-header p-2">
            <FormControl variant="outlined">
              <TextField
                id="outlined-search"
                className="blackClear"
                label="Search..."
                type="search"
                variant="outlined"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              ></TextField>
            </FormControl>
          </div>

          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <>
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    </>
                  ))}
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, i) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1}>
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format && typeof value === "number"
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        })}
                        <TableCell>
                          <IconButton size="small" color="inherit" title="Edit">
                            <EditIcon
                              onClick={() => {
                                editDocument(listData[i]);
                              }}
                            />
                          </IconButton>

                          <IconButton size="small" color="inherit" title="View">
                          <VisibilityIcon
                             
                              onClick={() => {
                                viewDocument(listData[i]);
                              }}
                            />
                          </IconButton>
                          <IconButton size="small" color="inherit" title="Delete">
                            <DeleteIcon
                              onClick={() => {
                                deleteAgreementData(listData[i]._id);
                              }}
                            />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>

      <Modal
        show={agreementPrev}
        onHide={() => handleClose()}
        disableBackdropClick={false}
        disableEscapeKeyDown={false}
        size="lg"
      >
        <Modal.Header closeButton className="headerBlue">
          <Modal.Title
            className=" text-center loginTitle"
            style={{ margin: "0 auto" }}
          >
            <h5 className="text-center">Agreement Preview</h5>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="pl-5 pr-5  pb-4 LoginContainer LoginModal">
            {previewData && <Preview state={previewData} />}
          </div>
        </Modal.Body>
      </Modal>
      </div>
    </>
  );
};

export default ListAgreement;
