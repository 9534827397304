import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
//@material packages
import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  Autocomplete,
} from "@mui/material";
import ReactSelect from "react-select";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { RxCross2 } from "react-icons/rx";
import InputAdornment from "@mui/material/InputAdornment";
import { Modal } from "react-bootstrap";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';

//icons
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import IconButton from "@mui/material/IconButton";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { MdAddBox, MdDelete } from "react-icons/md";
import { RiVideoAddFill } from "react-icons/ri";
import { BsFillFileEarmarkPdfFill } from "react-icons/bs";
import { PiImageSquareFill } from "react-icons/pi";
import { IoMdImages } from "react-icons/io";
import EditIcon from "@mui/icons-material/Edit";
import { IoMdArrowRoundBack } from "react-icons/io";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { FaFilePdf } from "react-icons/fa6";
import { FaImage } from "react-icons/fa";
import { Add, Delete, PictureAsPdf } from "@mui/icons-material";
import { PiVideoFill } from "react-icons/pi";
import DeleteIcon from "@mui/icons-material/Delete";
// other packages
import swal from "sweetalert";
import Swal from "sweetalert2";
import ImageGallery from "react-image-gallery";

//custom component
import TopNavbar from "../../Components/Navigation/TopNavigation";
import Auth from "../../Config/helper/Auth";
import {
  getAminitiesData,
  getLocationData,
  getPropertytypeByTypeData,
  getSubLocationById,
} from "../../Api/AuthAPI";
import { settings } from "../../Config/Settings";
import {
  addBuilderProject,
  deleteImages,
  getDocumentType,
  updateBuilderProject,
} from "../../Api/BuilderProjectAPI";
import FileLoader from "../../Components/Common/FileLoader";
import { IpfsImgUpload } from "../../modules/helpers/Ipfs";

// tabs packages
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
//css
import "./builderproject.css";
import AdminSidebar from "../../Components/Sidebar/AdminSidebar";
import Slider from "@mui/material/Slider";
import Property from "../../../src/Assets/img1.avif";
import AddIcon from "@mui/icons-material/Add";
import { emphasize, styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
});


// ValueLabelComponent for displaying tooltips
function ValueLabelComponent(props) {
  const { children, open, value } = props;

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired,
};

// Function to format price values
const formatPrice = (value) => {
  if (value >= 1000000000) {
    return (value / 1000000000).toFixed(2) + " B";
  } else if (value >= 10000000) {
    return (value / 10000000).toFixed(2) + " Cr";
  } else if (value >= 100000) {
    return (value / 100000).toFixed(2) + " L";
  } else if (value >= 1000) {
    return (value / 1000).toFixed(2) + " K";
  } else {
    return value;
  }
};

// Function to format numbers with separators
const NumberSeparator = (input) => {
  return new Intl.NumberFormat("en-IN", {
    minimumFractionDigits: 0,
  }).format(input);
};

const minDistance = 500;

const AddBuilderProject = () => {
  const fileInputRef = useRef(null);
  var userDetails = Auth?.getUserDetails();
  const { state } = useLocation();
  const navigate = useNavigate();
  // const minDistance = 500;
  const initialProjectData = {
    projectTitle: "",
    builderName: "",
    locationId: "",
    locationName: "",
    subLocationId: "",
    subLocationName: "",
    minPrice: 5000,
    maxPrice: 1000000000,
    projectArea: [],
    aminities: [],
    projectSize: "",
    totalUnits: "",
    selectFloor: null,
    selectRoom: null,
    description: "",
    //contact person
    fullname: "",
    contactNo: "",
    email: "",
    address: "",
    areatypeId: [],
    areaTitle: "",
    areaSqft: "",
    cost: "",
    counter: "",

    reraNo: "",
    locationBenefits: "",
    paymentScheme: "",

    //images
    videoTitle: "",
    uploadUrl: "",
    brochureTitle: "",
    ImagesTitle: "",
    projectImagesTitle: "",
    masterPlanImagesTitle: "",
    locationPlanImagesTitle: "",
    floorPlanImagesTitle: "",
    projectImages: [],
    masterPlanImages: [],
    locationPlanImages: [],
    floorPlanImages: [],
    projectImagesB: [],
    masterPlanImagesB: [],
    locationPlanImagesB: [],
    floorPlanImagesB: [],

    //document
    documentTypeId: null,
    issuingAuthority: "",
    // attachmentName: "",
    certificateTitle: "",
    documentTypeName: "",
    // attachmentPdf: "",
  };

  const [projectData, setProjectData] = useState({ ...initialProjectData });
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [amenitiesOption, setAmenitiesOption] = useState([]);

  const [uploadVideo, setUploadVideo] = useState([]);
  const [selectedUploadVideo, setSelectedUploadVideo] = useState([]);

  const [uploadBrochure, setUploadBrochure] = useState([]);
  const [selectedBrochure, setSelectedBrochure] = useState([]);

  const [uploadCertificate, setUploadCertificate] = useState([]);
  const [selectedCertificate, setSelectedCertificate] = useState([]);

  const [openbrochurePreview, setOpenbrochurePreview] = useState(false);

  const [locationData, setLocationData] = useState([]);
  const [subLocationData, setSubLocationData] = useState([]);

  const itemsPerPage = 8;
  const [currentTotalPage, setCurrentTotalPage] = useState(1);
  const [currentSelectPage, setCurrentSelectPage] = useState(1);

  const [formError, setFormError] = useState({ ...initialProjectData });
  const [uploadBrochureError, setUploadBrochureError] = useState([]);
  const [uploadVideoError, setUploadVideoError] = useState([]);
  const [fileLoading, setFileLoding] = useState(false);
  const [documentTypeData, setDocumentTypeData] = useState([]);
  const [selectedPossesionDate, setSelectedPossesionDate] = useState(null);
  const [configurationData, setConfigurationData] = useState([])
  const [selectedPropertyType, setSelectedPropertyType] = useState([])
  const [selectedFloorPropertyType, setSelectedFloorPropertyType] = useState([])
  const [propertyAreaList, setPropertyAreaList] = useState([])
  const [dateError, setDateError] = useState("");
  const [constId, setConst] = useState(0);
  const [configDatanew, setnewconfigData] = useState({});
  const [value, setValue] = useState("1");
  const [innerValue, setInnerValue] = useState("1");
  const [areaId, setAreaId] = useState('')

  // get by id
  useEffect(() => {

    if (state && Object.keys(state).length !== 0) {
      setConst(state._id);
      setAreaId(state.configuration._id)
      const launchDate = state?.launchDate?.split("T")[0];
      const possessionDate = state?.possessionDate ? new Date(state.possessionDate) : null;
      const adjustedDate = possessionDate
      setProjectData({
        projectTitle: state?.nameOfProject,
        builderName: state?.propertyOwner,

        locationName: state?.Location,
        locationId: state?.LocationId,
        subLocationName: state?.subLocation,
        subLocationId: state?.subLocationId,

        aminities: state?.Amenities,
        projectSize: state?.ProjectSize,
        totalUnits: state?.totalUnits,
        Amenities: state?.Amenities,

        minPrice: state?.minPrice,
        maxPrice: state?.maxPrice,
        projectArea: state?.areaType,
        selectFloor: state?.totalFloors,
        selectRoom: state?.totalRooms,
        description: state?.projectDescriptions,

        //contact person details
        fullname: state?.FullName,
        contactNo: state?.phone,
        email: state?.email,
        address: state?.Address,

        //project configuration
        locationBenefits: state.locationBenefits,
        paymentScheme: state.paymentScheme,
        // areatypeId: state.configuration.areatypeId,
        reraNo: state?.reraNo,
        // areaTitle: state?.configuration?.areaTitle,
        // cost: state?.configuration?.cost,
        // areaSqft: state?.configuration?.areaSqft,
        // counter: state?.configuration?.counter,


        //images
        projectImages: state?.photos?.projectImages,
        masterPlanImages: state?.photos?.masterPlanImages,
        locationPlanImages: state?.photos?.locationPlanImages,
        floorPlanImages: state?.photos?.floorPlanImages,

        //upload certificates
        certificateTitle: state?.certificates?.attachmentName,
        issuingAuthority: state?.certificates?.issuingAuthority,
        documentTypeId: state?.certificates?.documentTypeId,
        documentTypeName: state?.certificates?.documentTypeName,


        //titles
        videoTitle: state?.photos?.uploadVideoTitle,
        brochureTitle: state?.brochureTitle,
        projectImagesTitle: state?.photos?.projectImagesTitle,
        masterPlanImagesTitle: state?.photos?.masterPlanImagesTitle,
        locationPlanImagesTitle: state?.photos?.locationPlanImagesTitle,
        floorPlanImagesTitle: state?.photos?.floorPlanImagesTitle,
      });
      // let areatypes = state.configuration.areatypeId.map((data) => data.AreaType);
      const configData = state?.configuration
      setConfigurationData(configData)
      setSelectedBrochure(state?.brochurePdf);
      setUploadVideo(state?.photos[0]?.uploadVideo);
      setSelectedUploadVideo(state?.photos[0]?.uploadVideo);
      setSelectedCertificate(state?.certificates?.attachment);
      setSelectedDate(launchDate);
      setSelectedPossesionDate(adjustedDate);
      setSelectedPropertyType(state.configuration.areatypeId)
      getSubLocation(state?.LocationId);
    }
  }, []);

  useEffect(() => {
    let configdatass = [...configurationData]
    configdatass.map((data) => {
      setnewconfigData(data);
    })
  }, [configurationData])

  useEffect(() => {
    getAllLocation();
    AmenitiesDataList();
    getAllDocumentType();
    getpropertyType();
  }, []);

  const getpropertyType = () => {
    getPropertytypeByTypeData("Construction")
      .then((res) => {
        if (res.status === 200) {
          let result = res.data.data;
          let list = result.map((data) => ({ label: data.AreaType, value: data._id }));
          setPropertyAreaList(list);
        }
      }).catch((err) => {
        console.log(err, "error in property get data");
      })
  }

  const handlePDateChange = (date) => {
    const adjustedDate = new Date(date.getFullYear(), date.getMonth(), 1);
    setSelectedPossesionDate(adjustedDate);
    // setSelectedPossesionDate(date);
    // Add your validation logic here
    if (!date) {
      setDateError({ selectedDate: "Date is required" });
    } else {
      setDateError({ selectedDate: "" });
    }
  };

  // All get calls
  //location
  const getAllLocation = () => {
    getLocationData()
      .then((res) => {
        if (res.status === 200) {
          let locationData = res.data.locations;
          setLocationData(locationData);
        }
      })
      .catch((err) => {
        console.log(err, "error in location get call builder project");
      });
  };

  //subLocation
  const getSubLocation = (locationId) => {
    getSubLocationById(locationId)
      .then((res) => {
        if (res.status === 200) {
          setSubLocationData(res.data.locationdata);
        } else {
          swal("warning", "subLocation not found", "warning");
        }
      })
      .catch((err) => {
        console.log(err, "error in subLocation newRent");
      });
  };

  // AmenitiesList
  const AmenitiesDataList = () => {
    getAminitiesData()
      .then((res) => {
        if (res.status === 200) {
          const aminitiesResedentialData = res.data.AmenitiesData;
          const residentialAminitiesData = aminitiesResedentialData.filter(
            (aminititesData) =>
              aminititesData.PropertyType === "Construction" ||
              aminititesData.PropertyType === "All"
          );
          const formattedData = residentialAminitiesData.map((amenity) => ({
            label: amenity.AmenitiesName,
            value: amenity._id,
          }));

          setAmenitiesOption(formattedData);
        } else {
          setAmenitiesOption([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //document type
  const getAllDocumentType = () => {
    getDocumentType()
      .then((res) => {
        if (res.data.success === 1) {
          setDocumentTypeData(res.data.data);
        } else {
          setDocumentTypeData([]);
        }
      })
      .catch((err) => {
        console.log(err, "error in get document type");
      });
  };


  const handleSelectedPropertyType = (selected) => {
    setSelectedPropertyType(selected)
  }

  const handleFloorSelectedPropertyType = (selected) => {
    setSelectedFloorPropertyType(selected)
  }
  //handlechanges
  const handleSelectChange = (name, value) => {
    if (name === "Location") {
      setProjectData({
        ...projectData,
        locationName: value.label,
        locationId: value.value,
        subLocation: "",
      });
      getSubLocation(value.value);
    } else if (name === "subLocation") {
      setProjectData({
        ...projectData,
        subLocationName: value.label,
        subLocationId: value.value,
      });
    } else if (name === "Amenities") {
      setProjectData({ ...projectData, aminities: value });
    } else if (name === "projectArea") {
      setProjectData({ ...projectData, projectArea: value });
    } else if (name === "certificate") {
      setProjectData({
        ...projectData,
        documentTypeName: value.label,
        documentTypeId: value.value,
      });
    } else if (name === "filteredPropertyTypes") {
      setProjectData({
        ...projectData,
        filteredPropertyType: value, // Set the selected property type
      });
    }
  };

  //select floor
  const handlePageChange = (newPage, name) => {
    if (name === "total") {
      setCurrentTotalPage(newPage);
    } else if (name === "select") {
      setCurrentSelectPage(newPage);
    }
  };

  const renderFloorBricks = () => {
    const start = (currentTotalPage - 1) * itemsPerPage + 1;
    const end = Math.min(start + itemsPerPage - 1, 50);

    const bricks = [];
    for (let i = start; i <= end; i++) {
      bricks.push(
        <div
          key={i}
          className={`brick ${projectData.selectFloor === i ? "selected2" : ""
            }`}
          onClick={() => {
            setFormError({ ...formError, selectFloor: "" });
            onChangeHandler("selectFloor", i);
          }}
        >
          {i}
        </div>
      );
    }

    return bricks;
  };

  const renderRoomsBricks = () => {
    const start = (currentTotalPage - 1) * itemsPerPage + 1;
    const end = Math.min(start + itemsPerPage - 1, 50);

    const bricks = [];
    for (let i = start; i <= end; i++) {
      bricks.push(
        <div
          key={i}
          className={`brick ${projectData.selectRoom === i ? "selected2" : ""}`}
          onClick={() => {
            setFormError({ ...formError, selectRoom: "" });
            onChangeHandler("selectRoom", i);
          }}
        >
          {i}
        </div>
      );
    }

    return bricks;
  };

  const onChangeHandler = (name, value) => {
    setProjectData({ ...projectData, [name]: value });
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  //images...
  const ImagesofProject = [
    { name: "projectImages", label: "Project Images", value: "3" },
    { name: "masterPlanImages", label: "Master Plan", value: "4" },
    { name: "locationPlanImages", label: "Location Plan", value: "5" },
    { name: "floorPlanImages", label: "FloorPlan", value: "6" },
  ];

  const handleImageChange = (event, imgname) => {
    if (imgname === "VideoUrl") {
      setProjectData({
        ...projectData,
        uploadUrl: event.target.value,
        [imgname]: event.target.value,
      });
    } else {
      const imageFiles = event.target.files;
      if (
        imageFiles[0]?.type !== "image/jpeg" &&
        imageFiles[0]?.type !== "image/png" &&
        imageFiles[0]?.type !== "image/jpg"
      ) {
        swal({
          title: "Invalid File Type",
          text: "Please select a Image only!",
          icon: "error",
          button: "OK",
        });
      } else {
        setFileLoding(true);
        const existingImages = projectData[imgname] || [];
        const imageArray = [...existingImages];
        var imageArrayB = projectData[`${imgname}B`] || [];
        for (let i = 0; i < imageFiles.length; i++) {
          var imageFile = imageFiles[i];
          var imageObject = {
            imgname: imageFile.imgname,
            name: URL.createObjectURL(imageFile),
            newName: imageFile.name,
            title: projectData[`${imgname}Title`] || "",
            [`${imgname}Title`]: projectData[`${imgname}Title`] || ""
          };
          if (imgname === "floorPlanImages") { // Assuming `isFloorImage` is a property indicating a floor image
            imageObject.areatypeId = selectedFloorPropertyType.value;
          }

          imageArray.push(imageObject);
          imageArrayB.push(imageFile);
        }
        setProjectData({
          ...projectData,
          [imgname]: imageArray,
          [`${imgname}B`]: imageArrayB,
        });

        setFileLoding(false);
        setProjectData((prevState) => ({
          ...prevState,
          [`${imgname}Title`]: "",
        }));
      }
    }
  };

  const removeImages = (imgId, name, index) => {
    if (imgId._id) {
      const data = {
        ProjectId: constId,
        imageId: imgId._id
      };
      deleteImages(data)
        .then((res) => {
          if (res.data.status === 1) {
            let result = res.data.imagedata[name];
            // setProjectData((prev)=> ({...prev,[name]:result}));
            const updatedImages = [...projectData[name]];
            updatedImages.splice(index, 1);
            setProjectData({ ...projectData, [name]: updatedImages });

          }
        })
        .catch((err) => {
          console.log(err, "error...")
        })

    } else {
      const updatedImages = [...projectData[name]];
      updatedImages.splice(index, 1);
      setProjectData({ ...projectData, [name]: updatedImages });
    }

  };

  const fileSelectHandler = (e) => {
    const selectedFile = Array.from(e.target.files);
    if (selectedFile) {
      if (selectedFile[0].type !== "application/pdf") {
        swal({
          title: "Invalid File Type",
          text: "Please select a PDF file!",
          icon: "error",
          button: "OK",
        });
      } else {
        const newBrouchures = selectedFile.map((file) => ({
          brochureTitle: projectData.brochureTitle,
          name: URL.createObjectURL(file),
          newName: file.name,
          type: file.type,
        }));

        // setUploadBrochure((prevBrochure) => [...prevBrochure, ...selectedFile]);
        setUploadBrochure((prevBrochure) => (prevBrochure ? [...prevBrochure, ...selectedFile] : [...selectedFile]));
        setSelectedBrochure([...selectedBrochure, ...newBrouchures]);
      }
    } else {
      setUploadBrochure(null);
      setSelectedBrochure([]);
    }
    setUploadBrochureError([]);
    setProjectData((prevState) => ({
      ...prevState,
      brochureTitle: "",
    }));
  };

  const handleCertificateUpload = (e) => {
    const selectedFile = Array.from(e.target.files);

    if (selectedFile) {
      if (selectedFile[0].type !== "application/pdf") {
        swal({
          title: "Invalid File Type",
          text: "Please select a PDF file!",
          icon: "error",
          button: "OK",
        });
      } else {
        const newCertificates = selectedFile.map((file) => ({
          attachmentName: projectData.certificateTitle,
          documentTypeName: projectData.documentTypeName,
          name: URL.createObjectURL(file),
          newName: file.name,
          type: file.type,
        }));

        setUploadCertificate((prevCertificate) => [
          ...prevCertificate,
          ...selectedFile,
        ]);
        setSelectedCertificate([...selectedCertificate, ...newCertificates]);
        // setProjectData({ ...projectData });
      }
    } else {
      setUploadCertificate(null);
      setSelectedCertificate([]);
    }
    setProjectData((prevState) => ({
      ...prevState,
      certificateTitle: "",
      documentTypeName: "",
      // documentTypeId: "",
    }));
  };

  const removeCertificate = (certificate, index) => {
    if (certificate._id) {
      const data = {
        ProjectId: constId,
        attachmentId: certificate._id
      };
      deleteImages(data)
        .then((res) => {
          if (res.data.status === 1) {
            let result = res.data.attachmentData.attachment
            const updatedCertificates = selectedCertificate.filter(
              (_, i) => i !== index
            );
            setSelectedCertificate(updatedCertificates);
            setUploadCertificate(updatedCertificates);
            // setSelectedCertificate(result);
            // setUploadCertificate(result);
          }
        })
        .catch((err) => {
          console.log(err, "error...")
        })
    } else {
      const updatedCertificates = selectedCertificate.filter(
        (_, i) => i !== index
      );
      setSelectedCertificate(updatedCertificates);
      setUploadCertificate(updatedCertificates);
    }

  };

  const handleUploadVideo = async (e) => {
    const selectedFiles = Array.from(e.target.files);
    if (selectedFiles.length > 0) {
      if (selectedFiles[0].type !== "video/mp4") {
        swal({
          title: "Invalid File Type",
          text: "Please select a Video Only!",
          icon: "error",
          button: "OK",
        });
      } else {
        const videoURLs = selectedFiles.map((file) => ({
          name: URL.createObjectURL(file),
          uploadVideoTitle: projectData.videoTitle,
          newName: file.name,
        }));
        setSelectedUploadVideo((prevVideos) => [
          ...prevVideos,
          ...selectedFiles,
        ]);
        setUploadVideo((prevVideos) => [...prevVideos, ...videoURLs]);
      }
    }
    setProjectData((prevState) => ({
      ...prevState,
      videoTitle: "",
    }));
  };


  const removeVideo = (videourl, index) => {
    if (videourl._id) {
      const data = {
        ProjectId: constId,
        imageId: videourl._id
      };
      deleteImages(data)
        .then((res) => {

          if (res.data.status === 1) {
            let result = res.data.imagedata.uploadVideo
            // setUploadVideo([...uploadVideo, result]);
            // setSelectedUploadVideo((prevSelectedVideos) => [
            //   ...prevSelectedVideos,
            //   result,
            // ]);
            setUploadVideo((prevVideos) => prevVideos.filter((_, i) => i !== index));
            setSelectedUploadVideo((prevVideos) =>
              prevVideos.filter((_, i) => i !== index)
            );
          }
        })
        .catch((err) => {
          console.log(err, "error...")
        })
    } else {
      setUploadVideo((prevVideos) => prevVideos.filter((_, i) => i !== index));
      setSelectedUploadVideo((prevVideos) =>
        prevVideos.filter((_, i) => i !== index)
      );
    }
  };


  const removeBrochure = (brochure, index) => {
    if (brochure._id) {
      const data = {
        ProjectId: constId,
        brochureId: brochure._id
      };
      deleteImages(data)
        .then((res) => {
          if (res.data.status === 1) {
            let result = res.data.brochurePdfData.brochurePdf
            // setUploadBrochure(result);
            // setSelectedBrochure(result);
            setSelectedBrochure((prevBrochure) =>
              prevBrochure.filter((_, i) => i !== index)
            );
            setUploadBrochure((prevBrochure) =>
              prevBrochure.filter((_, i) => i !== index)
            );
          }
        })
        .catch((err) => {
          console.log(err, "error...")
        })

    } else {
      setSelectedBrochure((prevBrochure) =>
        prevBrochure.filter((_, i) => i !== index)
      );
      setUploadBrochure((prevBrochure) =>
        prevBrochure.filter((_, i) => i !== index)
      );
    }

  };

  const handleButtonClick = (name) => {
    let formError = {};
    let isValid = true;
    const imgtitleField = `${name}Title`;
    if (name === "uploadVideo") {
      if (projectData.videoTitle === "") {
        isValid = false;
        formError["videoTitle"] = "Enter Video Title"
        // swal("warning", "Enter Video Title");
      } else {
        fileInputRef.current.click();
      }
    } else if (name === "uploadBrochure") {
      if (projectData.brochureTitle === "") {
        isValid = false;
        formError["brochureTitle"] = "Enter Brochure Title"
        // swal("warning", );
      } else {
        fileInputRef.current.click();
      }
    } else if (name === "uploadAttachment") {
      if (projectData.certificateTitle === "") {
        isValid = false;
        formError["certificateTitle"] = "Enter Certificate Title"
        // swal("warning", "Enter Certificate Title");
      } else {
        fileInputRef.current.click();
      }
      if (projectData.documentTypeName === "") {
        isValid = false;
        formError["documentTypeName"] = " Plaese Select The Type"
      }
    }

    else if (imgtitleField === imgtitleField) {
      if (projectData[imgtitleField] === "") {
        isValid = false;
        formError[imgtitleField] = `Enter ${name.replace(/([A-Z])/g, " $1").trim()} Title`
        // swal(
        //   "warning",
        //   `Enter ${name.replace(/([A-Z])/g, " $1").trim()} Title`
        // );
      } else {
        fileInputRef.current.click();
      }
    } else {
      fileInputRef.current.click();
    }
    setFormError(formError);
    return isValid;
  };

  const handleClear = () => {
    setSelectedPropertyType([]);
    setConfigurationData([]);
    setProjectData({ ...initialProjectData });
    setSelectedDate(new Date());
    setFormError({ ...initialProjectData });
    setUploadBrochure(null);
    setSelectedPossesionDate(null)
    setUploadCertificate(null);
    setSelectedBrochure([]);
    setSelectedCertificate([]);
    setUploadBrochureError([]);
    setUploadVideo([]);
    setUploadVideoError([]);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  //validation
  const validates = () => {
    const emailRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const phoneRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    let formError = {};
    let isValid = true;

    if (projectData.projectTitle === "") {
      Swal.fire({
        icon: "warning",
        title: "Incomplete Form",
        text: "Please fill in all required fields before submitting.",
        showConfirmButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      });
    }
    if (projectData.projectTitle === "") {
      isValid = false;
      formError["projectTitle"] = "Please enter project title.";
    }
    if (projectData.builderName === "") {
      isValid = false;
      formError["builderName"] = "Please enter builder name.";
    }
    if (projectData.locationName === "") {
      isValid = false;
      formError["locationName"] = "Please select location name.";
    }
    if (projectData.subLocationName === "") {
      isValid = false;
      formError["subLocationName"] = "Please select subLocation name.";
    }
    if (projectData.minPrice === "") {
      isValid = false;
      formError["minPrice"] = "Please enter minprice.";
    }
    if (projectData.maxPrice === "") {
      isValid = false;
      formError["maxPrice"] = "Please enter maxprice.";
    }

    if (projectData.projectArea.length === 0) {
      isValid = false;
      formError["projectArea"] = "Please enter area.";
    }
    if (projectData.aminities.length === 0) {
      isValid = false;
      formError["aminities"] = "Please select aminities.";
    }

    if (projectData.totalUnits === "") {
      isValid = false;
      formError["totalUnits"] = "Please enter total units.";
    }

    // if (projectData.description === "") {
    //   isValid = false;
    //   formError["description"] = "Please Enter Description";
    // }

    // if (selectedDate === "") {
    //   isValid = false;
    //   dateError["selectedDate"] = "Please Select Date";
    // }

    if (projectData.fullname === "") {
      isValid = false;
      formError["fullname"] = "Please enter fullname.";
    }
    if (projectData.contactNo === "") {
      isValid = false;
      formError["contactNo"] = "Please enter contact number.";
    } else if (phoneRegex.test(projectData.contactNo) === false) {
      isValid = false;
      formError["contactNo"] = "Please enter valid contact number.";
    }
    if (projectData.email === "") {
      isValid = false;
      formError["email"] = "Please enter email.";
    } else if (emailRegex.test(projectData.email) === false) {
      isValid = false;
      formError["email"] = "Please enter valid email.";
    }

    if (projectData.address === "") {
      isValid = false;
      formError["address"] = "Please enter address.";
    }
    else if (projectData?.projectImages?.length <= 0) {
      isValid = false;
      Swal.fire({
        title: "Project Image Required",
        text: "Please upload an image for your project.",
        icon: "warning",
        button: "OK",
      });
    }

    // if (projectData.uploadVideo.length === 0) {
    //   isValid = false;
    //   formError["uploadVideo"] = "Please Upload Video";
    // }

    if (projectData?.projectImages?.length > 10) {
      isValid = false;
      Swal.fire({
        title: "Upload Limit Exceeded",
        text: "You can only upload a maximum of 10 images. Please remove excess images and try again.",
        icon: "warning",
        button: "OK",
      });
    }

    if (projectData?.masterPlanImages?.length > 3) {
      isValid = false;
      swal({
        title: "Upload Limit Exceeded",
        text: "Please upload only up to 3 master plan images.",
        icon: "warning",
        button: "OK",
      });
    }

    if (projectData?.locationPlanImages?.length > 3) {
      isValid = false;
      swal({
        title: "Upload Limit Exceeded",
        text: "Please upload only up to 3 location plan images.",
        icon: "warning",
        button: "OK",
      });
    }

    if (projectData?.floorPlanImages?.length > 3) {
      isValid = false;
      swal({
        title: "Upload Limit Exceeded",
        text: "Please upload only up to 3 floor plan images.",
        icon: "warning",
        button: "OK",
      });
    }

    // if (uploadVideo.length === 0 || uploadVideo === undefined) {
    //   isValid = false;
    //   uploadVideoError["uploadVideo"] = "Please Upload video Or Url";
    // }

    // if (
    //   uploadBrochure === 0 ||
    //   uploadBrochure === undefined ||
    //   uploadBrochure === "" ||
    //   uploadBrochure === null
    // ) {
    //   isValid = false;
    //   uploadBrochureError["uploadBrochure"] = "Please Upload Pdf";
    // }
    // if (uploadBrochure ? uploadBrochure[0]?.type !== "application/pdf" : "") {
    //   isValid = false;
    //   swal({
    //     title: "Invalid File Type",
    //     text: "Please select a PDF file!",
    //     icon: "error",
    //     button: "OK",
    //   });
    // }

    // if (selectedCertificate ? selectedCertificate[0]?.type !== "application/pdf" : "") {
    //   isValid = false;
    //   swal({
    //     title: "Invalid File Type",
    //     text: "Please select a PDF file!",
    //     icon: "error",
    //     button: "OK",
    //   });
    // }

    setUploadBrochureError(uploadBrochureError);
    setUploadVideoError(uploadVideoError);
    setFormError(formError);
    setDateError(dateError);
    return isValid;
  };

  //handleSubmit
  const submitProject = (e) => {
    e.preventDefault();
    if (validates()) {
      let newconfigData = { ...configurationData }
      const formData = new FormData();
      uploadBrochure?.forEach((file) => {
        formData.append("brochurePdf", file);
      });
      projectData?.projectImagesB &&
        projectData?.projectImagesB.forEach((file, index) => {
          formData.append(`projectImages`, file);
        });
      projectData?.masterPlanImagesB &&
        projectData?.masterPlanImagesB.forEach((file, index) => {
          formData.append(`masterPlanImages`, file);
        });
      projectData?.locationPlanImagesB &&
        projectData?.locationPlanImagesB.forEach((file, index) => {
          formData.append(`locationPlanImages`, file);
        });
      projectData?.floorPlanImagesB &&
        projectData?.floorPlanImagesB.forEach((file, index) => {
          formData.append(`floorPlanImages`, file);
        });

      selectedUploadVideo?.forEach((file) => {
        formData.append("uploadVideo", file);
      });

      uploadCertificate?.forEach((file) => {
        formData.append("attachment", file);
      });
      // formData.append("attachment", uploadCertificate);

      const data = {
        userId: userDetails._id,
        //project details
        nameOfProject: projectData.projectTitle,
        propertyOwner: projectData.builderName,
        Location: projectData.locationName,
        LocationId: projectData.locationId,
        subLocation: projectData.subLocationName,
        subLocationId: projectData.subLocationId,
        minPrice: projectData.minPrice,
        maxPrice: projectData.maxPrice,
        ProjectSize: projectData.projectSize,
        Amenities: projectData.aminities,
        areaType: projectData.projectArea,
        totalUnits: projectData.totalUnits,
        launchDate: new Date(selectedDate),
        projectDescriptions: projectData.description,

        //contact person
        FullName: projectData.fullname,
        phone: projectData.contactNo,
        email: projectData.email,
        Address: projectData.address,

        //project configuration
        configuration: configurationData,
        // areatypeId: configDatanew.areatypeId,
        // areaTitle: configDatanew.areaTitle,
        // areaSqft: configDatanew.areaSqft,
        // cost: configDatanew.cost,
        // counter: configDatanew.counter,

        reraNo: projectData.reraNo,
        locationBenefits: projectData.locationBenefits,
        paymentScheme: projectData.paymentScheme,
        possessionDate: new Date(selectedPossesionDate),

        //images
        // uploadVideoTitle: "kasdksd",
        // brochureTitle: projectData.brochureTitle,
        // projectImagesTitle: projectData.projectImagesTitle,
        // masterPlanImagesTitle: projectData.masterPlanImagesTitle,
        // locationPlanImagesTitle: projectData.locationPlanImagesTitle,
        // floorPlanImagesTitle: projectData.floorPlanImagesTitle,

        uploadVideo: uploadVideo,
        videoUrl: projectData.uploadUrl,
        projectImages: projectData.projectImages,
        masterPlanImages: projectData.masterPlanImages,
        locationPlanImages: projectData.locationPlanImages,
        floorPlanImages: projectData.floorPlanImages,
        brochurePdf: selectedBrochure,

        //certiticates
        documentTypeId: projectData.documentTypeId,
        documentTypeName: projectData.documentTypeName,
        issuingAuthority: projectData.issuingAuthority,
        attachmentName: projectData.certificateTitle,
        attachment: selectedCertificate,
      };
      formData.append("details", JSON.stringify(data));
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      setFileLoding(true);
      addBuilderProject(formData, config)
        .then((res) => {
          setFileLoding(false);
          if (res.data.status === 1) {
            Swal.fire({
              icon: 'success',
              title: 'Project Added Successfully',
              text: 'Your project has been added successfully.',
              showConfirmButton: true,
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'OK',
            });
            handleClear();
          } else {
            Swal.fire({
              icon: "warning",
              title: "Action Required",
              text: "The project could not be added. Please try again later.",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK",
            });
          }
        })
        .catch((err) => {
          setFileLoding(false);
          console.log(err, "error in add builder project");
        });
    }
  };

  const UpdateConstructionProperty = (e) => {
    e.preventDefault();
    if (validates()) {
      const formData = new FormData();
      uploadBrochure?.forEach((file) => {
        formData.append("brochurePdf", file);
      });
      projectData?.projectImagesB &&
        projectData?.projectImagesB.forEach((file, index) => {
          formData.append(`projectImages`, file);
        });
      projectData?.masterPlanImagesB &&
        projectData?.masterPlanImagesB.forEach((file, index) => {
          formData.append(`masterPlanImages`, file);
        });
      projectData?.locationPlanImagesB &&
        projectData?.locationPlanImagesB.forEach((file, index) => {
          formData.append(`locationPlanImages`, file);
        });
      projectData?.floorPlanImagesB &&
        projectData?.floorPlanImagesB.forEach((file, index) => {
          formData.append(`floorPlanImages`, file);
        });

      selectedUploadVideo?.forEach((file) => {
        formData.append("uploadVideo", file);
      });

      uploadCertificate?.forEach((file) => {
        formData.append("attachment", file);
      });

      const updateData = {
        userId: userDetails._id,
        //project details
        nameOfProject: projectData.projectTitle,
        propertyOwner: projectData.builderName,
        Location: projectData.locationName,
        LocationId: projectData.locationId,
        subLocation: projectData.subLocationName,
        subLocationId: projectData.subLocationId,
        minPrice: projectData.minPrice,
        maxPrice: projectData.maxPrice,
        ProjectSize: projectData.projectSize,
        Amenities: projectData.aminities,
        areaType: projectData.projectArea,
        totalUnits: projectData.totalUnits,
        launchDate: new Date(selectedDate),
        projectDescriptions: projectData.description,

        //contact details
        FullName: projectData.fullname,
        phone: projectData.contactNo,
        email: projectData.email,
        Address: projectData.address,

        // project configuration
        configuration: configurationData,
        // areatypeId: configDatanew.areatypeId,
        // areaTitle: configDatanew.areaTitle,
        // areaSqft: configDatanew.areaSqft,
        // cost: configDatanew.cost,
        // counter: configDatanew.counter,

        reraNo: projectData.reraNo,
        locationBenefits: projectData.locationBenefits,
        paymentScheme: projectData.paymentScheme,
        possessionDate: new Date(selectedPossesionDate),


        //images
        // uploadVideoTitle: projectData.videoTitle,
        // brochureTitle: projectData.brochureTitle,
        // projectImagesTitle: projectData.projectImagesTitle,
        // masterPlanImagesTitle: projectData.masterPlanImagesTitle,
        // locationPlanImagesTitle: projectData.locationPlanImagesTitle,
        // floorPlanImagesTitle: projectData.floorPlanImagesTitle,

        uploadVideo: uploadVideo,
        videoUrl: projectData.uploadUrl,
        projectImages: projectData.projectImages,
        masterPlanImages: projectData.masterPlanImages,
        locationPlanImages: projectData.locationPlanImages,
        floorPlanImages: projectData.floorPlanImages,
        brochurePdf: selectedBrochure,

        //certiticates
        documentTypeId: projectData.documentTypeId,
        // documentTypeName: projectData.documentTypeName,
        issuingAuthority: projectData.issuingAuthority,
        // attachmentName: projectData.certificateTitle,
        attachment: selectedCertificate,
      };

      formData.append("details", JSON.stringify(updateData));

      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };

      updateBuilderProject(formData, constId, config)
        .then((res) => {
          if (res.data.status === 1) {
            Swal.fire({
              icon: "success",
              title: "Project Updated Successfully",
              text: "Your project has been updated and all changes have been saved.",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK",
            })

            navigate("/builderProjectDetails");
          } else {
            Swal.fire({
              icon: "error",
              title: "Something went wrong.",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              // cancelButtonColor: '#d33',
              confirmButtonText: "ok",
            });
          }
        })
        .catch((err) => {
          console.log(err, "err in update  property.");
        });
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleInnerChange = (event, newValue) => {
    setInnerValue(newValue);
  };

  // const NumberSeparator = (input) => {
  //   return new Intl.NumberFormat("en-IN", {
  //     minimumFractionDigits: 0,
  //   }).format(input);
  // };

  const currentDate = new Date().toISOString().split("T")[0];

  const [images, setImages] = useState([
    {
      id: "1412_exelsphoto1115804.jpeg",
      url: "https://img.staticmb.com/mbphoto/property/original_images/2024/Jun/12/1412_exelsphoto1115804.jpeg",
      status: "Under Screening",
    },
  ]);
  const [defaultImage, setDefaultImage] = useState(null);

  const setAsDefault = (imageId) => {
    setDefaultImage(imageId);
  };

  const removeImagedd = (imageId) => {
    setImages(images.filter((image) => image.id !== imageId));
  };

  const clickOnBrowse = () => {
    // Logic to handle "Add more photos" click
  };

  const handleChange1 = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setProjectData((prevData) => ({
        ...prevData,
        minPrice: Math.min(newValue[0], prevData.maxPrice - minDistance),
      }));
    } else {
      setProjectData((prevData) => ({
        ...prevData,
        maxPrice: Math.max(newValue[1], prevData.minPrice + minDistance),
      }));
    }
  };

  const handleKeyDown = (event) => {
    const step = 500;
    const { minPrice, maxPrice } = projectData;

    if (event.key === "ArrowLeft") {
      // Decrease maxPrice with Left Arrow, ensure it doesn't go below minPrice + minDistance
      setProjectData((prevData) => ({
        ...prevData,
        maxPrice: Math.max(prevData.maxPrice - step, prevData.minPrice + minDistance),
      }));
    } else if (event.key === "ArrowRight") {
      // Increase maxPrice with Right Arrow, ensure it doesn't exceed the maximum limit
      setProjectData((prevData) => ({
        ...prevData,
        maxPrice: Math.min(prevData.maxPrice + step, 1000000000),
      }));
    }
  };


  // const handleChange1 = (event, newValue, activeThumb) => {
  //   if (!Array.isArray(newValue)) {
  //     return;
  //   }

  //   if (activeThumb === 0) {
  //     setProjectData((prevData) => ({
  //       ...prevData,
  //       minPrice: Math.min(newValue[0], prevData.maxPrice - minDistance),
  //     }));
  //   } else {
  //     setProjectData((prevData) => ({
  //       ...prevData,
  //       maxPrice: Math.max(newValue[1], prevData.minPrice + minDistance),
  //     }));
  //   }
  // };


  // function ValueLabelComponent(props) {
  //   const { children, value } = props;

  //   return (
  //     <Tooltip enterTouchDelay={0} placement="top" title={value}>
  //       {children}
  //     </Tooltip>
  //   );
  // }

  // ValueLabelComponent.propTypes = {
  //   children: PropTypes.element.isRequired,
  //   value: PropTypes.number.isRequired,
  // };

  const handleBackClick = () => {
    navigate(-1);
  };

  // const saveProjectConfiguration = () => {
  //   if (projectData.length <= 0 || projectData.areaTitle === "" || projectData.areaSqft === "" || projectData.cost === "" || projectData.counter === "") {
  //     Swal.fire({
  //       icon: "warning",
  //       title: "Add Project Configuration Details!",
  //       showConfirmButton: true,
  //       confirmButtonColor: "#3085d6",
  //       confirmButtonText: "ok",
  //     });
  //   } else {
  //     const configData = [{
  //       areatypeId: selectedPropertyType.value,
  //       AreaType: selectedPropertyType.label,
  //       areaTitle: projectData.areaTitle,
  //       areaSqft: projectData.areaSqft,
  //       cost: projectData.cost,
  //       counter: projectData.counter,
  //     }]
  //     setConfigurationData([...configurationData, ...configData])
  //     setProjectData({
  //       ...projectData,
  //       areaTitle: "",
  //       areaSqft: "",
  //       cost: "",
  //       counter: "",
  //     });
  //     // setSelectedPropertyType([])
  //     // setConfigurations([...configurations, newConfig]);

  //   }

  // }

  const saveProjectConfiguration = () => {
    if (projectData.projectArea.length <= 0 || projectData.areaTitle === "" || projectData.areaSqft === "" || projectData.cost === "" || projectData.counter === "") {
      Swal.fire({
        icon: "warning",
        title: "Add Project Configuration Details!",
        showConfirmButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "ok",
      });
    } else {
      const configData = [{
        _id: areaId,
        areatypeId: projectData.filteredPropertyType ? projectData.filteredPropertyType.value : "",
        AreaType: projectData.filteredPropertyType ? projectData.filteredPropertyType.label : "", // Use the label here
        // areatypeId: selectedPropertyType.value,
        // AreaType: selectedPropertyType.label,
        areaTitle: projectData.areaTitle,
        areaSqft: projectData.areaSqft,
        cost: projectData.cost,
        counter: projectData.counter,
      }];

      setConfigurationData([...configurationData, ...configData]);
      setProjectData({
        ...projectData,
        areaTitle: "",
        areaSqft: "",
        cost: "",
        counter: "",
        filteredPropertyType: null, // Reset property type
      });
    }
  };


  const formatToCrores = (value) => {
    return `${(value / 10000000).toLocaleString('en-IN')} cr`;
  };

  const deleteConfiguration = (index) => {
    const updatedConfigData = configurationData.filter((_, i) => i !== index);
    setConfigurationData(updatedConfigData);
  };

  const handlePropertyTypeChange = (event) => {
    const { value } = event.target;
    // The value will be an array in a multi-select
    setProjectData(prevData => ({
      ...prevData,
      projectArea: value,
    }));
    setFormError(prevError => ({
      ...prevError,
      projectArea: "",
    }));
  };


  // const formatPrice = (value) => {
  //   if (value >= 1000000000) {
  //     return (value / 1000000000).toFixed(2) + ' B';
  //   } else if (value >= 10000000) {
  //     return (value / 10000000).toFixed(2) + ' Cr';
  //   } else if (value >= 1000000) {
  //     return (value / 1000000).toFixed(2) + ' M';
  //   } else if (value >= 100000) {
  //     return (value / 100000).toFixed(2) + ' L';
  //   } else if (value >= 1000) {
  //     return (value / 1000).toFixed(2) + ' K';
  //   } else {
  //     return value;
  //   }
  // };


  console.log("Project Area:", projectData.projectArea);


  return (
    <>
      {fileLoading && <FileLoader />}
      <AdminSidebar />

      <div className="container topNav-m">
        <div className="col-md-12 vertical pt-4">
          {constId === 0 ? null : (
            <Breadcrumbs aria-label="breadcrumb" onClick={handleBackClick}>
              <StyledBreadcrumb
                component="a"
                // href="/"
                icon={<IoMdArrowRoundBack fontSize="20px" />}
                label="Back"
              />
            </Breadcrumbs>
          )}

          {/* <div class="steps clearfix">
              <ul role="tablist">
                <li
                  role="tab"
                  class="first current"
                  aria-disabled="false"
                  aria-selected="true"
                >
                  <a
                    id="steps-uid-0-t-0"
                    href="#steps-uid-0-h-0"
                    aria-controls="steps-uid-0-p-0"
                  >
                    <span class="current-info audible"> </span>
                    <div class="title">
                      <span class="step-number">1</span>
                      <span class="step-text">Project Details</span>
                    </div>
                  </a>
                </li>

                <li role="tab" class="disabled" aria-disabled="true">
                  <a
                    id="steps-uid-0-t-1"
                    href="#steps-uid-0-h-1"
                    aria-controls="steps-uid-0-p-1"
                  >
                    <div class="title">
                      <span class="step-number">2</span>
                      <span class="step-text">Contact Person Details</span>
                    </div>
                  </a>
                </li>

                <li role="tab" class="disabled" aria-disabled="true">
                  <a
                    id="steps-uid-0-t-2"
                    href="#steps-uid-0-h-2"
                    aria-controls="steps-uid-0-p-2"
                  >
                    <div class="title">
                      <span class="step-number">3</span>
                      <span class="step-text">Project Images</span>
                    </div>
                  </a>
                </li>
              </ul>
            </div> */}
        </div>
        <div className="content-title pt-3 d-flex justify-content-between align-items-center">
          <h3>{constId === 0 ? "Add New Project" : "Update Project"}</h3>
          <Button className="add-btn" onClick={() => { navigate("/builderProjectDetails") }}><RemoveRedEyeOutlinedIcon className="me-2" />View Projects</Button>

        </div>
        <div className=" mb-3" >  <hr class="content-title-underline"></hr></div>
        <div className="row">

          <div className="col-md-12">
            <div className="user__details ">
              <div className="row mt-4">

                <div className="col-md-6 col-sm-12">
                  <div className="input__box p-2">
                    <span className="details">Project Name*</span>
                    <FormControl variant="outlined" fullWidth size="small">
                      <TextField
                        autoComplete="off"
                        type="text"
                        size="small"
                        value={projectData.projectTitle}
                        onChange={(e) => {
                          setFormError({
                            ...formError,
                            projectTitle: "",
                          });
                          onChangeHandler("projectTitle", e.target.value);
                        }}
                      ></TextField>
                      <div style={{ color: "red", fontSize: 13 }}>
                        {formError.projectTitle}
                      </div>
                    </FormControl>
                  </div>
                </div>

                <div className="col-md-6 col-sm-12">
                  <div className="input__box p-2">
                    <span className="details">Builder Name*</span>
                    <FormControl variant="outlined" fullWidth size="small">
                      <TextField
                        autoComplete="off"
                        type="text"
                        size="small"
                        value={projectData.builderName}
                        onChange={(e) => {
                          setFormError({
                            ...formError,
                            builderName: "",
                          });
                          onChangeHandler("builderName", e.target.value);
                        }}
                      ></TextField>
                      <div style={{ color: "red", fontSize: 13 }}>
                        {formError.builderName}
                      </div>
                    </FormControl>
                  </div>
                </div>
                <div className="col-md-12 col-sm-12">
                  <div className="input__box p-2">
                    <span className="details">Project Description</span>
                    <FormControl variant="outlined" fullWidth size="small">
                      <TextField
                        id="outlined-multiline-flexible"
                        // label="Description"
                        multiline
                        size="small"
                        rows={3}
                        name="description"
                        value={projectData.description}
                        onChange={(e) => {
                          onChangeHandler("description", e.target.value);
                          setFormError({
                            ...formError,
                            description: "",
                          });
                        }}
                      />

                      <div style={{ color: "red", fontSize: 13 }}>
                        {formError.description}
                      </div>
                    </FormControl>
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  {/* <div className="input__box p-2">
                    <span className="details">Location*</span>

                    <FormControl variant="outlined" fullWidth size="small">
                      <Select
                        value={projectData.locationId}
                        onChange={(e) => {
                          const selectedOption = locationData.find(
                            (option) => option._id === e.target.value
                          );
                          handleSelectChange("Location", {
                            value: selectedOption._id,
                            label: selectedOption.locationName,
                          });
                          setFormError({
                            ...formError,
                            locationName: "",
                          });
                        }}
                      >
                        {locationData.map((option) => (
                          <MenuItem key={option._id} value={option._id}>
                            {option.locationName}
                          </MenuItem>
                        ))}
                      </Select>
                      <div style={{ color: "red", fontSize: 13 }}>
                        {formError.locationName}
                      </div>
                    </FormControl>
                  </div> */}

                  <div className="input__box p-2">
                    <span className="details">Location*</span>

                    <FormControl variant="outlined" fullWidth size="small">
                      <Autocomplete
                        options={locationData}
                        getOptionLabel={(option) => option.locationName || ''}
                        value={locationData.find(option => option._id === projectData.locationId) || null}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            handleSelectChange("Location", {
                              value: newValue._id,
                              label: newValue.locationName,
                            });
                            setFormError({
                              ...formError,
                              locationName: "",
                            });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            size="small"
                            error={Boolean(formError.locationName)}
                            helperText={formError.locationName}
                          />
                        )}
                        PaperComponent={({ children }) => (
                          <div
                            style={{
                              maxHeight: 200,
                              width: '100%',
                              position: 'absolute',
                              top: 'calc(100% + 8px)',
                              left: 0,
                              zIndex: 9999,
                              overflowY: 'auto',
                              border: '1px solid #ccc',
                              borderRadius: '4px',
                              backgroundColor: '#fff',
                            }}
                          >
                            {children}
                          </div>
                        )}
                      />
                    </FormControl>
                  </div>
                </div>

                <div className="col-md-6 col-sm-12">
                  {/* <div className="input__box p-2">
                    <span className="details"> Sub Location*</span>

                    <FormControl variant="outlined" fullWidth size="small">
                      <Select
                        value={projectData.subLocationId}
                        onChange={(e) => {
                          const selectedOption = subLocationData.find(
                            (option) => option._id === e.target.value
                          );
                          handleSelectChange("subLocation", {
                            value: selectedOption._id,
                            label: selectedOption.subLocationName,
                          });
                          setFormError({
                            ...formError,
                            subLocationName: "",
                          });
                        }}
                      >
                        {subLocationData.map((option) => (
                          <MenuItem key={option._id} value={option._id}>
                            {option.subLocationName}
                          </MenuItem>
                        ))}
                      </Select>
                      <div style={{ color: "red", fontSize: 13 }}>
                        {formError.subLocationName}
                      </div>
                    </FormControl>
                  </div> */}
                  <div className="input__box p-2">
                    <span className="details">Sub Location*</span>

                    <FormControl variant="outlined" fullWidth size="small">
                      <Autocomplete
                        options={subLocationData}
                        getOptionLabel={(option) => option.subLocationName}
                        value={subLocationData.find(option => option._id === projectData.subLocationId) || null}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            handleSelectChange("subLocation", {
                              value: newValue._id,
                              label: newValue.subLocationName,
                            });
                            setFormError({
                              ...formError,
                              subLocationName: "",
                            });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            size="small"
                            error={!!formError.subLocationName}
                            helperText={formError.subLocationName}
                          />
                        )}
                      />
                    </FormControl>
                  </div>
                </div>
                {/* <div className="col-md-4 col-sm-12">
                  <div className="input__box p-2">
                    <span className="details">Property Type*</span>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      size="small"
                      style={{ maxHeight: 200, overflow: "auto" }}
                    >
                      <Select
                        multiple
                        value={projectData.projectArea}
                        onChange={(e) => {
                          handleSelectChange("projectArea", e.target.value);
                          setFormError({
                            ...formError,
                            projectArea: "",
                          });
                        }}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 200,
                            },
                          },
                        }}
                      >
                        {propertyAreaList.map((option) => (
                          <MenuItem key={option.value} value={option.label}>
                            <Checkbox
                              checked={projectData.projectArea.includes(
                                option.label
                              )}
                            />
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                      <div className="helpertxt">  You may select multiple options from the  list. </div>
                      <div style={{ color: "red", fontSize: 13 }}>
                        {formError.projectArea}
                      </div>
                    </FormControl>
                  </div>
                </div> */}
                <div className="col-md-4 col-sm-12">
                  <div className="input__box p-2">
                    <span className="details">Property Type*</span>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      size="small"
                      style={{ maxHeight: 200, overflow: 'auto' }}
                    >
                      <Select
                        multiple
                        value={projectData.projectArea}
                        onChange={handlePropertyTypeChange}
                        renderValue={(selected) => selected.join(', ')}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 200,
                            },
                          },
                        }}
                      >
                        {propertyAreaList.map((option) => (
                          <MenuItem key={option.value} value={option.label}>
                            <Checkbox
                              checked={projectData.projectArea.includes(option.label)}
                            />
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                      <div className="helpertxt">You may select multiple options from the list.</div>
                      <div style={{ color: 'red', fontSize: 13 }}>
                        {formError.projectArea}
                      </div>
                    </FormControl>
                  </div>
                </div>



                {/* <div className="col-md-4 col-sm-12">
                  <div className="input__box p-2">
                    <span className="details">Total Units*</span>
                    <FormControl variant="outlined" fullWidth size="small">
                      <TextField
                        autoComplete="off"
                        type="Number"
                        size="small"
                        value={projectData.totalUnits}
                        onChange={(e) => {
                          setFormError({
                            ...formError,
                            totalUnits: "",
                          });
                          onChangeHandler("totalUnits", e.target.value);
                        }}
                      ></TextField>
                      <div style={{ color: "red", fontSize: 13 }}>
                        {formError.totalUnits}
                      </div>
                    </FormControl>
                  </div>
                </div> */}
                <div className="col-md-4 col-sm-12">
                  <div className="input__box p-2">
                    <span className="details">Total Units*</span>
                    <FormControl variant="outlined" fullWidth size="small">
                      <TextField
                        autoComplete="off"
                        type="number"
                        size="small"
                        value={projectData.totalUnits}
                        onChange={(e) => {
                          setFormError({
                            ...formError,
                            totalUnits: "",
                          });
                          onChangeHandler("totalUnits", e.target.value);
                        }}
                        InputProps={{
                          inputProps: {
                            style: {
                              '-webkit-appearance': 'none', // Hide the spinner for Chrome, Safari, and Opera
                              '-moz-appearance': 'textfield', // Hide the spinner for Firefox
                              'appearance': 'textfield', // Hide the spinner for others
                              'margin': 0, // Reset margin to remove any browser-specific space
                            },
                          },
                        }}
                        sx={{
                          'input::-webkit-outer-spin-button': {
                            '-webkit-appearance': 'none',
                            margin: 0,
                          },
                          'input::-webkit-inner-spin-button': {
                            '-webkit-appearance': 'none',
                            margin: 0,
                          },
                          'input[type=number]': {
                            '-moz-appearance': 'textfield',
                          },
                        }}
                      />
                      <div style={{ color: "red", fontSize: 13 }}>
                        {formError.totalUnits}
                      </div>
                    </FormControl>
                  </div>
                </div>



                {/* <div className="col-md-4 col-sm-12">
                  <div
                    className="input__box p-2"
                    style={{ position: "relative", padding: "10px" }}
                  >
                    <span
                      className="details"
                      style={{ display: "block", marginBottom: "10px" }}
                    >
                      {" "}
                      Price Range*
                    </span>


                    <Slider
                      getAriaLabel={() => "Minimum distance"}
                      value={[projectData.minPrice, projectData.maxPrice]}
                      max={10000000000}
                      min={0}
                      onChange={handleChange1}
                      valueLabelDisplay="on"
                      slots={{
                        valueLabel: ValueLabelComponent,
                      }}
                      getAriaValueText={NumberSeparator}
                      disableSwap
                      step={500}
                      valueLabelFormat={NumberSeparator}
                      size="small"
                    />
                    <div className="d-flex gap-2 property-price-sm justify-content-between">
                      <span> Min ₹{formatPrice(projectData.minPrice)} </span>   <span>Max ₹{formatPrice(projectData.maxPrice)}</span>
                    </div>
                  </div>
                </div> */}

                {/* <div className="col-md-4 col-sm-12"
                //  onKeyDown={handleKeyDown} 
                //  tabIndex="0"
                 > */}
                <div className="col-md-4 col-sm-12" style={{ position: "relative", padding: "10px" }} onKeyDown={handleKeyDown} tabIndex="0">
                  <span className="details" style={{ display: "block", marginBottom: "10px" }}>
                    Price Range*
                  </span>
                  <Slider
                    getAriaLabel={() => "Minimum distance"}
                    value={[projectData.minPrice, projectData.maxPrice]}
                    max={1000000000}
                    min={5000}
                    onChange={handleChange1}
                    valueLabelDisplay="Off"
                    ValueLabelComponent={ValueLabelComponent}
                    getAriaValueText={NumberSeparator}
                    disableSwap
                    step={500}
                    valueLabelFormat={formatPrice}
                    size="small"
                  />
                  <div className="d-flex gap-2 property-price-sm justify-content-between">
                    <span> Min ₹{formatPrice(projectData.minPrice)} </span>
                    <span> Max ₹{formatPrice(projectData.maxPrice)} </span>
                  </div>
                </div>
                {/* </div> */}


                {/* <div className="col-md-3">
                  <div className="input__box p-2">
                    <span className="details">Min Price*</span>
                    <FormControl variant="outlined" fullWidth size="small">
                      <TextField
                        autoComplete="off"
                        type="number"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">₹</InputAdornment>
                          ),
                        }}
                        size="small"
                        value={projectData.minPrice}
                        onChange={(e) => {
                          setFormError({
                            ...formError,
                            minPrice: "",
                          });
                          onChangeHandler("minPrice", e.target.value);
                        }}
                        required
                      />
                      <div style={{ color: "red", fontSize: 13 }}>
                        {formError.minPrice}
                      </div>
                    </FormControl>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="input__box p-2">
                    <span className="details">Max Price*</span>
                    <FormControl variant="outlined" fullWidth size="small">
                      <TextField
                        autoComplete="off"
                        type="number"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">₹</InputAdornment>
                          ),
                        }}
                        size="small"
                        value={projectData.maxPrice}
                        onChange={(e) => {
                          setFormError({
                            ...formError,
                            maxPrice: "",
                          });
                          onChangeHandler("maxPrice", e.target.value);
                        }}
                        required
                      />
                      <div style={{ color: "red", fontSize: 13 }}>
                        {formError.maxPrice}
                      </div>
                    </FormControl>
                  </div>
                </div> */}





                <div className="col-md-3 col-sm-12">
                  <div className="input__box p-2">
                    <span className="details">Expected Launch Date</span>
                    <FormControl variant="outlined" fullWidth size="small">
                      <TextField
                        variant="outlined"
                        fullWidth
                        size="small"
                        // label="Select Date"
                        type="date"
                        name="selectedDate"
                        value={selectedDate}
                        onChange={handleDateChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          min: currentDate,
                        }}
                      />
                      <div style={{ color: "red", fontSize: 13 }}>
                        {dateError.selectedDate}
                      </div>
                    </FormControl>
                  </div>
                </div>
                <div className="col-md-3 col-sm-12">
                  <div className="input__box p-2">
                    <span className="details">Rera Number</span>
                    <FormControl variant="outlined" fullWidth size="small">
                      <TextField
                        autoComplete="off"
                        type="text"
                        size="small"
                        value={projectData.reraNo}
                        onChange={(e) => {
                          setFormError({
                            ...formError,
                            reraNo: "",
                          });
                          onChangeHandler("reraNo", e.target.value);
                        }}
                      ></TextField>
                      <div style={{ color: "red", fontSize: 13 }}>
                        {formError.reraNo}
                      </div>
                    </FormControl>
                  </div>
                </div>

                <div className="col-md-3 col-sm-12">
                  <div className="input__box p-2">
                    <span className="details">Possesion Month & Year</span>
                    <FormControl variant="outlined" fullWidth size="small">
                      <DatePicker
                        selected={selectedPossesionDate}
                        onChange={handlePDateChange}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        minDate={currentDate}
                        // placeholderText="Select Month and Year"
                        customInput={
                          <TextField
                          variant="outlined"
                          fullWidth
                          size="small"
                          helperText="Select Month and Year"
                          InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        }
                      />
                      <div style={{ color: "red", fontSize: 13 }}>
                        {dateError.selectedPossesionDate}
                      </div>
                    </FormControl>
                  </div>
                </div>

                <div className="col-md-3 col-sm-12">
                  <div className="input__box p-2">
                    <span className="details"> Payment Scheme</span>
                    <FormControl variant="outlined" fullWidth size="small">
                      <TextField
                        autoComplete="off"
                        type="text"
                        size="small"
                        value={projectData.paymentScheme}
                        onChange={(e) => {
                          setFormError({
                            ...formError,
                            paymentScheme: "",
                          });
                          onChangeHandler("paymentScheme", e.target.value);
                        }}
                      ></TextField>
                      <div style={{ color: "red", fontSize: 13 }}>
                        {formError.paymentScheme}
                      </div>
                    </FormControl>
                  </div>
                </div>

                {/* <div className="col-md-6 col-sm-12">
                  <div className="input__box p-2">
                    <span className="details">Amenities*</span>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      size="small"
                      style={{ maxHeight: 200, overflow: "auto" }}
                    >
                      <Select
                        multiple

                        value={projectData.aminities}
                        onChange={(e) => {
                          handleSelectChange("Amenities", e.target.value);
                          setFormError({
                            ...formError,
                            aminities: "",
                          });
                        }}
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 200,
                            },
                          },
                        }}
                      >
                        {amenitiesOption.map((option) => (
                          <MenuItem key={option.value} value={option.label} className="normaltxt" >
                            <Checkbox
                              checked={projectData.aminities.includes(
                                option.label
                              )}
                            />
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                      <div className="helpertxt">You may select multiple options from the  list. </div>
                      <div style={{ color: "red", fontSize: 13 }}>

                        {formError.aminities}
                      </div>
                    </FormControl>
                  </div>
                </div> */}
                <div className="col-md-6 col-sm-12">
                  <div className="input__box p-2">
                    <span className="details">Amenities*</span>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      size="small"
                      style={{ maxHeight: 200, overflow: "auto" }}
                    >
                      <Autocomplete
                        multiple
                        value={amenitiesOption.filter(option =>
                          projectData.aminities.includes(option.label)
                        )}
                        onChange={(event, newValue) => {
                          const selectedAmenities = newValue.map(option => option.label);
                          handleSelectChange("Amenities", selectedAmenities);
                          setFormError({
                            ...formError,
                            aminities: "",
                          });
                        }}
                        options={amenitiesOption}
                        getOptionLabel={(option) => option?.label || ""}
                        isOptionEqualToValue={(option, value) => option?.label === value?.label}
                        disableCloseOnSelect
                        renderOption={(props, option, { selected }) => (
                          <MenuItem {...props} key={option.value}>
                            <Checkbox
                              checked={selected}
                              style={{ marginRight: 8 }}
                            />
                            {option.label}
                          </MenuItem>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            size="small"
                            required
                            helperText="You may select multiple options from the list."
                          />
                        )}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 200,
                            },
                          },
                        }}
                      />
                      <div style={{ color: "red", fontSize: 13 }}>
                        {formError.aminities}
                      </div>
                    </FormControl>
                  </div>
                </div>

                <div className="col-md-6 col-sm-12">
                  <div className="input__box p-2">
                    <span className="details">Advantages of this Location</span>
                    <FormControl variant="outlined" fullWidth size="small">
                      <TextField
                        autoComplete="off"
                        type="text"
                        // multiline
                        size="small"
                        // rows={4}
                        name="locationBenefits"
                        value={projectData.locationBenefits}
                        onChange={(e) => {
                          onChangeHandler("locationBenefits", e.target.value);
                          setFormError({
                            ...formError,
                            locationBenefits: "",
                          });
                        }}
                      >
                      </TextField>

                      <div style={{ color: "red", fontSize: 13 }}>
                        {formError.locationBenefits}
                      </div>
                    </FormControl>
                  </div>
                </div>


                <div className="col-12">
                  <h5>  Contact Person Details</h5>
                </div>
                <div className="col-md-4 col-sm-12">
                  <div className="input__box p-2">
                    <span className="details"> Name*</span>
                    <FormControl variant="outlined" fullWidth size="small">
                      <TextField
                        autoComplete="off"
                        type="text"
                        size="small"
                        value={projectData.fullname}
                        onChange={(e) => {
                          setFormError({
                            ...formError,
                            fullname: "",
                          });
                          onChangeHandler("fullname", e.target.value);
                        }}
                      ></TextField>
                      <div style={{ color: "red", fontSize: 13 }}>
                        {formError.fullname}
                      </div>
                    </FormControl>
                  </div>
                </div>

                <div className="col-md-4 col-sm-12">
                  <div className="input__box p-2">
                    <span className="details">Number*</span>
                    <FormControl variant="outlined" fullWidth size="small">
                      <TextField
                        autoComplete="off"
                        type="Number"
                        size="small"
                        value={projectData.contactNo}
                        onChange={(e) => {
                          setFormError({
                            ...formError,
                            contactNo: "",
                          });
                          onChangeHandler("contactNo", e.target.value);
                        }}
                      ></TextField>
                      <div style={{ color: "red", fontSize: 13 }}>
                        {formError.contactNo}
                      </div>
                    </FormControl>
                  </div>
                </div>

                <div className="col-md-4 col-sm-12">
                  <div className="input__box p-2">
                    <span className="details"> Email*</span>
                    <FormControl variant="outlined" fullWidth size="small">
                      <TextField
                        autoComplete="off"
                        type="text"
                        size="small"
                        value={projectData.email}
                        onChange={(e) => {
                          setFormError({
                            ...formError,
                            email: "",
                          });
                          onChangeHandler("email", e.target.value);
                        }}
                      ></TextField>
                      <div style={{ color: "red", fontSize: 13 }}>
                        {formError.email}
                      </div>
                    </FormControl>
                  </div>
                </div>
                <div className="col-md-12 col-sm-12">
                  <div className="input__box p-2">
                    <span className="details"> Address*</span>
                    <FormControl variant="outlined" fullWidth size="small">
                      <TextField
                        autoComplete="off"
                        type="text"
                        size="small"
                        value={projectData.address}
                        onChange={(e) => {
                          setFormError({
                            ...formError,
                            address: "",
                          });
                          onChangeHandler("address", e.target.value);
                        }}
                        rows={3}
                        multiline
                      ></TextField>
                      <div style={{ color: "red", fontSize: 13 }}>
                        {formError.address}
                      </div>
                    </FormControl>
                  </div>
                </div>




                <div id="add-builder-tabs">
                  <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <TabList
                        value={value}
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                      >
                        <Tab label="Project Configuration" value="1" />
                        <Tab label="Project Images" value="2" />

                        <Tab label="Certificate Details" value="3" />
                      </TabList>
                    </Box>
                    <TabPanel value="1" className="tabarea">
                      <Paper>
                        <div className="row mt-4">
                          <div className="col-md-3">
                            {/* <div className="input__box p-0">
                              <span className="details">Property Type</span>

                              <ReactSelect
                                // multiple
                                size="small"
                                name="propertyTypes"
                                value={selectedPropertyType}
                                onChange={handleSelectedPropertyType}
                                options={propertyAreaList}
                                classNamePrefix={"my-custom-react-select"}
                              />
                            </div> */}

                            <div className="input__box p-0">
                              <span className="details">Property Type</span>
                              <ReactSelect
                                name="filteredPropertyTypes"
                                value={projectData.filteredPropertyType}
                                onChange={(selectedOption) => handleSelectChange("filteredPropertyTypes", selectedOption)}
                                options={propertyAreaList.filter(option => projectData.projectArea.includes(option.label))}
                                classNamePrefix={"my-custom-react-select"}
                              />
                            </div>

                            {/* <div style={{ color: "red", fontSize: 13 }}>
                            {formError.projectArea}
                          </div> */}

                          </div>
                          <div className="col-md-2">
                            <div className="input__box p-0">
                              <span className="details">Property Title</span>
                              <FormControl variant="outlined" fullWidth size="small">
                                <TextField
                                  autoComplete="off"
                                  type="text"
                                  size="small"
                                  value={projectData.areaTitle}
                                  onChange={(e) => {
                                    setFormError({
                                      ...formError,
                                      areaTitle: "",
                                    });
                                    onChangeHandler("areaTitle", e.target.value);
                                  }}
                                ></TextField>
                                <div style={{ color: "red", fontSize: 13 }}>
                                  {formError.areaTitle}
                                </div>
                              </FormControl>
                            </div>
                          </div>

                          <div className="col-md-2">
                            <div className="input__box p-0">
                              <span className="details">Carpet Area</span>
                              <FormControl variant="outlined" fullWidth size="small">
                                <TextField
                                  autoComplete="off"
                                  type="Number"
                                  size="small"
                                  value={projectData.areaSqft}
                                  onChange={(e) => {
                                    setFormError({
                                      ...formError,
                                      areaSqft: "",
                                    });
                                    onChangeHandler("areaSqft", e.target.value);
                                  }}
                                ></TextField>
                                <div style={{ color: "red", fontSize: 13 }}>
                                  {formError.areaSqft}
                                </div>
                              </FormControl>
                            </div>
                          </div>

                          <div className="col-md-2">
                            <div className="input__box p-0">
                              <span className="details">Price</span>
                              <FormControl variant="outlined" fullWidth size="small">
                                <TextField
                                  autoComplete="off"
                                  type="Number"
                                  size="small"
                                  value={projectData.cost}
                                  onChange={(e) => {
                                    setFormError({
                                      ...formError,
                                      cost: "",
                                    });
                                    onChangeHandler("cost", e.target.value);
                                  }}
                                ></TextField>
                                <div style={{ color: "red", fontSize: 13 }}>
                                  {formError.cost}
                                </div>
                              </FormControl>
                            </div>
                          </div>

                          <div className="col-md-2">
                            <div className="input__box p-0">
                              <span className="details">No. of Units</span>
                              <FormControl variant="outlined" fullWidth size="small">
                                <TextField
                                  autoComplete="off"
                                  type="Number"
                                  size="small"
                                  value={projectData.counter}
                                  onChange={(e) => {
                                    setFormError({
                                      ...formError,
                                      counter: "",
                                    });
                                    onChangeHandler("counter", e.target.value);
                                  }}
                                ></TextField>
                                <div style={{ color: "red", fontSize: 13 }}>
                                  {formError.counter}
                                </div>
                              </FormControl>
                            </div>
                          </div>

                          <div className="col-md-1 mt-3">
                            <div className="input__box p-0 mt-1">
                              <IconButton
                                onClick={(e) => saveProjectConfiguration(e)}
                              >
                                <MdAddBox fontSize={"30px"} />
                              </IconButton>
                            </div>
                          </div>
                        </div>
                      </Paper>
                      <div className="builder-box text-center p-4">
                        <TableContainer sx={{ maxHeight: 700, overflowX: "auto" }}>
                          <Table aria-label="sticky table">
                            <TableHead>
                              <TableRow>
                                <TableCell>Sr.No.</TableCell>
                                <TableCell>Property Type</TableCell>
                                <TableCell>Property Title</TableCell>
                                <TableCell>Carpet Area</TableCell>
                                <TableCell>Price</TableCell>
                                <TableCell>No. of Units</TableCell>
                                <TableCell>Action</TableCell>
                              </TableRow>
                            </TableHead>

                            <TableBody>
                              {configurationData && configurationData.map((configData, index) => {
                                return (
                                  <TableRow key={index}>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>{configData?.AreaType ? configData?.AreaType : configData.areatypeId.AreaType}</TableCell>
                                    <TableCell>{configData.areaTitle}</TableCell>
                                    <TableCell>{configData.areaSqft}</TableCell>
                                    <TableCell>{configData.cost}</TableCell>
                                    <TableCell>{configData.counter}</TableCell>
                                    <TableCell>
                                      <IconButton onClick={() => deleteConfiguration(index)}>
                                        <MdDelete />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                )
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>


                    </TabPanel>

                    <TabPanel value="2" className="tabarea">
                      <div id="add-builder-tabs">
                        <TabContext value={innerValue}>
                          <Box sx={{ paddingTop: "1rem" }}>
                            <TabList
                              value={innerValue}
                              onChange={handleInnerChange}
                              aria-label="lab API tabs example"
                            >
                              <Tab label="Videos" value="1" />
                              <Tab label="Brochure" value="2" />
                              <Tab label="Project Images" value="3" />
                              <Tab label="Master Plans" value="4" />
                              <Tab label="location Plans" value="5" />
                              <Tab label="floor Plans" value="6" />
                            </TabList>
                          </Box>
                          <TabPanel value="1" className="tabarea">
                            <div className="builder-box text-center p-4">
                              <FormControl variant="outlined" size="small">
                                <div className="d-flex gap-3 align-items-center justify-content-center">
                                  {/* <span className="details">Add Video Title*</span> */}
                                  <FormControl
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                  >
                                    <TextField
                                      autoComplete="off"
                                      type="text"
                                      name="videoTitle"
                                      label="Video Title"
                                      size="small"
                                      value={projectData.videoTitle}
                                      onChange={(e) => {
                                        setFormError({
                                          ...formError,
                                          videoTitle: "",
                                        });
                                        onChangeHandler(
                                          "videoTitle",
                                          e.target.value
                                        );
                                      }}
                                      required
                                    ></TextField>
                                    <div style={{ color: "red", fontSize: 13 }}>
                                      {formError.videoTitle}
                                    </div>
                                  </FormControl>
                                  <input
                                    ref={fileInputRef}
                                    accept="video/*"
                                    type="file"
                                    name="uploadVideo"
                                    onChange={handleUploadVideo}
                                    // multiple={true}
                                    style={{ display: "none" }}
                                  />
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    className="add-builder"
                                    startIcon={<RiVideoAddFill />}
                                    onClick={() =>
                                      handleButtonClick("uploadVideo")
                                    }
                                  >
                                    Upload
                                  </Button>

                                  <span className="font-bold">OR</span>
                                  <TextField
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    label="Video URL"
                                    value={projectData?.uploadUrl}
                                    onChange={(event) => {
                                      handleImageChange(event, "VideoUrl");
                                      setFormError({
                                        ...formError,
                                        [projectData?.uploadUrl]: "",
                                      });
                                    }}
                                  />
                                </div>
                              </FormControl>
                            </div>

                            {uploadVideo?.length !== 0 && (
                              <div className="builder-pic">
                                {uploadVideo?.map((videoUrl, index) => (
                                  <div className="b-pic ">
                                    <div>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "flex-end",
                                        }}
                                      >

                                        <IconButton
                                          onClick={() => removeVideo(videoUrl, index)}
                                        >
                                          <RxCross2 size={20} />
                                        </IconButton>
                                      </div>
                                      <a
                                        href={videoUrl.name}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <video
                                          src={videoUrl.name}
                                          width="195"
                                          height="112"
                                          alt="Property"
                                        />
                                      </a>
                                    </div>
                                    <FormControl
                                      variant="outlined"
                                      fullWidth
                                      size="small"
                                    >
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                          textAlign: "center",
                                        }}
                                      >
                                        {videoUrl.uploadVideoTitle}
                                      </span>
                                    </FormControl>
                                  </div>
                                ))}
                              </div>
                            )}

                            {/* {projectData?.uploadUrl ? (
                               <div className="builder-pic">
                                 <div className="b-pic ">
                                 <div>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "flex-end",
                                        }}
                                      >
                                       
                                        <IconButton
                                          onClick={() => removeVideo(projectData?.uploadUrl)}
                                        >
                                          <RxCross2 size={20}/>
                                        </IconButton>
                                      </div>
                                      <a
                                        href={projectData?.uploadUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <video
                                          src={projectData?.uploadUrl}
                                          width="195"
                                          height="112"
                                          alt="Property"
                                        />
                                      </a>
                                    </div>
                                    <FormControl
                                      variant="outlined"
                                      fullWidth
                                      size="small"
                                    >
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                          textAlign: "center",
                                        }}
                                      >
                                        {projectData?.uploadVideoTitle}
                                      </span>
                                    </FormControl>
                                 </div>
                               </div>
                            )
                            :null} */}

                            {/* <div className="b-pic ">
                            <div>
                              <img src={Property} alt="" />
                            </div>
                            <FormControl
                              variant="outlined"
                              fullWidth
                              size="small"
                            >
                              <TextField
                                autoComplete="off"
                                type="text"
                                size="small"
                                variant="standard"
                                className="b-textbox"
                                label="Video Title"
                                value={projectData.videoTitle}
                                onChange={(e) => {
                                  setFormError({
                                    ...formError,
                                    videoTitle: "",
                                  });
                                  onChangeHandler("videoTitle", e.target.value);
                                }}
                                required
                              ></TextField>
                              <div style={{ color: "red", fontSize: 13 }}>
                                {formError.videoTitle}
                              </div>
                              <div className="text-center p-3">
                                <a href="#">Remove</a>
                              </div>
                            </FormControl>
                          </div> */}
                            {/* <div className="b-pic-add ">
                            <div className="text-center p-3">
                              <a href="#">
                                <AddIcon />
                                <br />
                                Add More
                              </a>
                            </div>
                          </div> */}

                            {/* <div className="builder-box">
                          <div className="row d-flex justify-content-center align-items-center">
                            <div className="col-md-4"></div>

                            <div className="col-md-4 p-3 text-center">
                              <FormControl variant="outlined" size="small">
                                <div className="d-flex gap-3 align-items-center justify-content-center">
                                  <input
                                    ref={fileInputRef}
                                    accept="video/*"
                                    type="file"
                                    name="uploadVideo"
                                    onChange={handleUploadVideo}
                                    multiple={true}
                                    style={{ display: "none" }}
                                  />
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    className="add-builder"
                                    startIcon={<Add />}
                                    onClick={() =>
                                      handleButtonClick("uploadVideo")
                                    }
                                  >
                                    Add Video
                                  </Button>

                                  <span className="details mt-2">Or</span>
                                  <TextField
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    label="Video URL"
                                    value={projectData.uploadUrl}
                                    onChange={(event) => {
                                      handleImageChange(event, "VideoUrl");
                                      setFormError({
                                        ...formError,
                                        [projectData.uploadUrl]: "",
                                      });
                                    }}
                                  />
                                </div>
                              </FormControl>
                            </div>
                          </div>
                        </div> */}

                            {/* {uploadVideo.length !== 0 && (
                          <ul className="uploadImgBlock">
                            <div id="allData" className="allData">
                              {uploadVideo.map((videoUrl, index) => (
                                <li className="abcf" key={index}>
                                  <div className="madeDefault">
                                    {videoUrl.title}
                                  </div>
                                  <div className="uploadImgTemp">
                                    <div className="img">
                                      <a
                                        href={videoUrl.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <span className="iconMagnifier"></span>
                                        <video
                                          src={videoUrl.url}
                                          width="148"
                                          height="112"
                                          alt="Property"
                                        />
                                      </a>
                                    </div>
                                    <div className="removeLink">
                                      <Button
                                        variant="text"
                                        color="secondary"
                                        onClick={() => removeVideo(index)}
                                      >
                                        Remove
                                      </Button>
                                    </div>
                                  </div>
                                </li>
                              ))}
                             
                            </div>
                          </ul>
                        )} */}
                          </TabPanel>

                          <TabPanel value="2" className="tabarea">
                            <div className="builder-box text-center p-4">
                              <FormControl variant="outlined" size="small">
                                <div className="d-flex gap-3 align-items-center justify-content-center">
                                  {/* <span className="details">Add Brochure Title*</span> */}
                                  <FormControl
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                  >
                                    <TextField
                                      autoComplete="off"
                                      type="text"
                                      size="small"
                                      name="brochureTitle"
                                      label="Brochure Title"
                                      value={projectData.brochureTitle}
                                      onChange={(e) => {
                                        setFormError({
                                          ...formError,
                                          brochureTitle: "",
                                        });
                                        onChangeHandler(
                                          "brochureTitle",
                                          e.target.value
                                        );
                                      }}
                                      required
                                    ></TextField>
                                    <div style={{ color: "red", fontSize: 13 }}>
                                      {formError.brochureTitle}
                                    </div>
                                  </FormControl>

                                  <FormControl variant="outlined" size="small">
                                    <input
                                      accept="application/pdf"
                                      type="file"
                                      name="uploadBrochure"
                                      ref={fileInputRef}
                                      onChange={fileSelectHandler}
                                      style={{ display: "none" }}
                                    />
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      className="add-builder"
                                      startIcon={<BsFillFileEarmarkPdfFill />}
                                      onClick={() =>
                                        handleButtonClick("uploadBrochure")
                                      }
                                    >
                                      Upload
                                    </Button>
                                  </FormControl>
                                </div>
                              </FormControl>
                            </div>

                            {selectedBrochure.length > 0 && (
                              <div className="builder-pic">
                                {selectedBrochure.map((brochure, index) => (
                                  <div className="b-pic ">
                                    <div>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "flex-end",
                                        }}
                                      >
                                        <IconButton
                                          onClick={() => removeBrochure(brochure, index)}
                                        >
                                          <RxCross2 color="error" />
                                        </IconButton>
                                      </div>

                                      <a
                                        href={brochure.name}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <span className="iconMagnifier"></span>
                                        <embed
                                          src={brochure.name}
                                          width="195"
                                          height="112"
                                          alt="Property"
                                        />
                                      </a>
                                    </div>
                                    <FormControl
                                      variant="outlined"
                                      fullWidth
                                      size="small"
                                    >
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                          textAlign: "center",
                                        }}
                                      >
                                        {brochure.brochureTitle}
                                      </span>
                                    </FormControl>
                                  </div>
                                ))}
                              </div>
                            )}
                          </TabPanel>

                          {ImagesofProject.map((projectImg, index) => (
                            <TabPanel
                              value={projectImg.value}
                              className="tabarea"
                            >
                              <div className="builder-box text-center p-4">
                                <FormControl variant="outlined" size="small">
                                  <div className="d-flex gap-3 align-items-center justify-content-center">
                                    {/* <span className="details">
                                  Add {projectImg.label} Title*
                                </span> */}
                                    {projectImg.name === "floorPlanImages" ?
                                      <FormControl
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                      >
                                        {/* <span className="details">Property Type</span> */}

                                        <ReactSelect
                                          // multiple
                                          size="small"
                                          name="propertyTypes"
                                          value={selectedFloorPropertyType}
                                          onChange={handleFloorSelectedPropertyType}
                                          options={propertyAreaList}
                                          classNamePrefix={"my-custom-react-select"}
                                        />


                                        {/* <div style={{ color: "red", fontSize: 13 }}>
                                           {formError.projectArea}
                                         </div> */}

                                      </FormControl>
                                      : null}

                                    <FormControl
                                      variant="outlined"
                                      fullWidth
                                      size="small"
                                    >
                                      <TextField
                                        autoComplete="off"
                                        type="text"
                                        size="small"
                                        name={`${projectImg.label} Title`}
                                        label={`${projectImg.label} Title`}
                                        value={
                                          projectData[`${projectImg.name}Title`]
                                        }
                                        onChange={(e) => {
                                          setFormError({
                                            ...formError,
                                            [`${projectImg.name}Title`]: "",
                                          });
                                          onChangeHandler(
                                            `${projectImg.name}Title`,
                                            e.target.value
                                          );
                                        }}
                                        required
                                      ></TextField>
                                      <div
                                        style={{ color: "red", fontSize: 13 }}
                                      >
                                        {formError[`${projectImg.name}Title`]}
                                      </div>
                                    </FormControl>

                                    <FormControl
                                      variant="outlined"
                                      size="small"
                                    >
                                      <input
                                        accept="image/*"
                                        type="file"
                                        ref={fileInputRef}
                                        onChange={(event) =>
                                          handleImageChange(
                                            event,
                                            projectImg.name
                                          )
                                        }
                                        // multiple
                                        style={{ display: "none" }}
                                      />
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        className="add-builder w-150"
                                        startIcon={<IoMdImages />}
                                        onClick={() =>
                                          handleButtonClick(projectImg.name)
                                        }
                                      >
                                        Upload
                                        {/* Add {projectImg.label} */}
                                      </Button>
                                    </FormControl>
                                  </div>
                                </FormControl>
                              </div>

                              {projectData[projectImg.name]?.length > 0 ? (
                                <div className="builder-pic">
                                  {projectData[projectImg.name].map(
                                    (image, indeximage) => (
                                      console.log(image, "images..."),
                                      (
                                        <div className="b-pic">
                                          <div>
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                              }}
                                            >
                                              <IconButton
                                                onClick={() =>
                                                  removeImages(
                                                    image,
                                                    projectImg.name,
                                                    indeximage
                                                  )
                                                }
                                              >
                                                <RxCross2 color="error" />
                                              </IconButton>
                                            </div>

                                            <a
                                              href={image.name}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              <span className="iconMagnifier"></span>
                                              <img
                                                src={image.name}
                                                width="200"
                                                height="112"
                                                alt="Property"
                                              />
                                            </a>
                                          </div>
                                          <FormControl
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                          >
                                            <span
                                              style={{
                                                fontWeight: "bold",
                                                textAlign: "center",
                                              }}
                                            >
                                              {constId === 0
                                                ? image.title
                                                : image[
                                                `${projectImg.name}Title`
                                                ]}
                                            </span>
                                          </FormControl>
                                        </div>
                                      )
                                    )
                                  )}
                                </div>
                              ) : null}
                            </TabPanel>
                          ))}
                        </TabContext>
                      </div>
                    </TabPanel>

                    <TabPanel value="3" className="tabarea">
                      <div className="builder-box text-center p-4">
                        <FormControl variant="outlined" size="small">
                          <div className="d-flex gap-3 align-items-center justify-content-center">
                            {/* <span className="details">
                            Add Certificate Title*
                          </span> */}
                            <FormControl
                              variant="outlined"
                              fullWidth
                              size="small"
                            >
                              <Select
                                value={projectData.documentTypeId}
                                onChange={(e) => {
                                  const selectedOption = documentTypeData.find(
                                    (option) => option._id === e.target.value
                                  );

                                  handleSelectChange("certificate", {
                                    value: selectedOption._id,
                                    label: selectedOption.documentType,
                                  });
                                  setFormError({
                                    ...formError,
                                    documentTypeName: "",
                                  });

                                }}
                              >
                                {documentTypeData.map((option) => (
                                  <MenuItem key={option._id} value={option._id}>
                                    {option.documentType}
                                  </MenuItem>
                                ))}
                              </Select>
                              <div style={{ color: "red", fontSize: 13 }}>
                                {formError.documentTypeName}
                              </div>
                            </FormControl>

                            <FormControl
                              variant="outlined"
                              fullWidth
                              size="small"
                            >
                              <TextField
                                autoComplete="off"
                                type="text"
                                size="small"
                                name="certificateTitle"
                                label="Add Certificate Title"
                                value={projectData.certificateTitle}
                                onChange={(e) => {
                                  setFormError({
                                    ...formError,
                                    certificateTitle: "",
                                  });
                                  onChangeHandler(
                                    "certificateTitle",
                                    e.target.value
                                  );
                                }}
                                required
                              ></TextField>
                              <div style={{ color: "red", fontSize: 13 }}>
                                {formError.certificateTitle}
                              </div>
                            </FormControl>

                            <FormControl
                              variant="outlined"
                              size="small"
                              fullWidth
                            >
                              <input
                                accept="application/pdf"
                                type="file"
                                name="uploadAttachment"
                                ref={fileInputRef}
                                onChange={handleCertificateUpload}
                                style={{ display: "none" }}
                              />
                              <Button
                                variant="contained"
                                color="primary"
                                className="add-builder"
                                startIcon={<BsFillFileEarmarkPdfFill />}
                                onClick={() =>
                                  handleButtonClick("uploadAttachment")
                                }
                              >
                                Upload
                              </Button>
                            </FormControl>
                          </div>
                        </FormControl>
                      </div>

                      {selectedCertificate?.length > 0 && (
                        <div className="builder-pic">
                          {selectedCertificate.map((certificate, index) => (
                            <div className="b-pic ">
                              <div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <IconButton
                                    onClick={() => removeCertificate(certificate, index)}
                                  >
                                    <RxCross2 color="error" />
                                  </IconButton>
                                </div>

                                <a
                                  href={certificate.name}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <div>
                                    <span className="iconMagnifier"></span>
                                    <embed
                                      src={certificate.name}
                                      width="195"
                                      height="112"
                                      alt="Certificate"
                                    />
                                  </div>
                                </a>
                              </div>

                              <FormControl
                                variant="outlined"
                                fullWidth
                                size="small"
                              >
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    textAlign: "center",
                                  }}
                                >
                                  {certificate.documentTypeName}
                                </span>
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    textAlign: "center",
                                  }}
                                >
                                  {certificate.attachmentName}
                                </span>
                              </FormControl>
                            </div>
                          ))}
                        </div>
                      )}
                    </TabPanel>

                    <div className="form-group d-flex justify-content-center mt-0 gap-0">
                      {constId === 0 ? (
                        <div className=" d-flex  gap-3">
                          <button
                            type="submit"
                            value="Submit"
                            className="btn-red"
                            onClick={submitProject}
                          >
                            Submit
                          </button>

                          <button
                            // type="submit"s
                            value="Clear"
                            className="btn-grey"
                            onClick={handleClear}
                          >
                            Clear
                          </button>
                        </div>
                      ) : (
                        <button
                          type="submit"
                          value="Update"
                          className="btn-red"
                          onClick={UpdateConstructionProperty}
                        >
                          Update
                        </button>
                      )}
                    </div>
                  </TabContext>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddBuilderProject;
