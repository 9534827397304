import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

//@mui packages
import { Box, Button, Checkbox, IconButton, TextField, Tooltip, Typography } from "@material-ui/core";
import { Modal } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import ButtonR from "react-bootstrap/Button";
import Snackbar from "@mui/material/Snackbar";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
//icons
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import ChairOutlinedIcon from "@mui/icons-material/ChairOutlined";
import CorporateFareOutlinedIcon from "@mui/icons-material/CorporateFareOutlined";
import EmojiTransportationOutlinedIcon from "@mui/icons-material/EmojiTransportationOutlined";
import BedOutlinedIcon from "@mui/icons-material/BedOutlined";
import { pink } from "@material-ui/core/colors";
import { MdMiscellaneousServices } from "react-icons/md";
import { MdOutlineAdUnits } from "react-icons/md";
import { MdConfirmationNumber } from "react-icons/md";
import { RiSecurePaymentLine } from "react-icons/ri";


// social icons
import {
    FacebookIcon,
    WhatsappShareButton,
    TwitterShareButton,
    EmailShareButton,
    TwitterIcon,
    WhatsappIcon,
    FacebookShareButton,
    EmailIcon,
} from "react-share";

//custom componenets
import Auth from '../../Config/helper/Auth';
import { addProprtyToWish } from '../../Api/WishlistAPI';
import moment from 'moment';
import { getVisitPropertyById, sendEnquiryData, visitProperty } from '../../Api/AuthAPI';
import Swal from 'sweetalert2';
import RecommendedProject from '../BuilderProject/RecommendedProject';

//css
const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};





const FilterNewProject = ({ allProjectData,Location}) => {
    const navigate = useNavigate();
    const textAreaRef = useRef(null);
    let userId = Auth?.getUserDetails()?._id;
    const [activeWish, setActiveWish] = useState(false);
    const [showSocial, setShowSocial] = useState(false);
    const [socialUrl, setSocialUrl] = useState("");
    const [visitCount, setVisitCount] = useState(0);
    const [show, setShow] = useState(false);
    const [warnLogin, setWarnLogin] = useState(false);
    const [error, setError] = useState([]);
    const [open, setOpen] = useState(false);
    const [projectData, setProjectData] = useState({});
    const handleOpen = () => setWarnLogin(true);

    // slider setting code
    const settings = {
        vertical: true,
        verticalSwiping: true,
        dots: true,
        infinite: true,
        speed: 500, // Adjust speed as needed
        slidesToShow: allProjectData.length >= 3 ? 2 : allProjectData.length,
        slidesToScroll: 2,
        autoplay: true,
        autoplaySpeed: 7000, // Adjust speed as needed
        pauseOnHover: false,
        cssEase: "linear",
        arrows: allProjectData.length >= 3 ? true : false,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    let userData = {
        Name: Auth?.getUserDetails()?.fullName,
        email: Auth?.getUserDetails()?.email,
        Phone: Auth?.getUserDetails()?.phone,
        terms: false
    };
    const [useContactDetails, setUseContactDetails] = useState({
        Name: "",
        email: "",
        Phone: "",
        terms: false,
    });

    // currency dropdown list
    const currencies = [
        {
            value: "USA",
            label: "USA +1",
        },
        {
            value: "IND",
            label: "IND +91",
        },
        {
            value: "ARE",
            label: "ARE +971",
        },
        {
            value: "GBR",
            label: "GBR +44",
        },
    ];

    const noDataMessageStyle = {
        textAlign: "center",
        padding: "20px",
        backgroundColor: "#f8d7da", // Light red background color
        color: "#721c24", // Dark red text color
        border: "1px solid #f5c6cb", // Border color
        borderRadius: "5px", // Rounded corners
        margin: "10px",
        width: "30rem",
    };
    useEffect(() => {
        getVisits();
    }, [allProjectData, userId])
    // getVisitCount

    const getVisits = () => {
        allProjectData?.forEach((projectData) => {
            getVisitPropertyById(projectData._id)
                .then((res) => {
                    if (res.data.status === 1) {
                        setVisitCount((prevCounts) => ({
                            ...prevCounts,
                            [projectData._id]: res?.data?.data?.uniqueVisits,
                        }));
                    } else {
                        setVisitCount((prevCounts) => ({
                            ...prevCounts,
                            [projectData._id]: 0,
                        }));
                    }
                    return 0;
                }).catch((err) => {
                    console.log(err, "error in get visits");
                    setVisitCount((prevCounts) => ({
                        ...prevCounts,
                        [projectData._id]: 0,
                    }));
                })
        });
    };

    const clickVisit = (proId) => {
        visitProperty(proId, userId)
            .then((res) => {
                if (res.data.status === 1) {
                    getVisits();
                    console.log(res?.data?.message, "visited successfully");
                } else {
                    console.log("not visited");
                }
            })
            .catch((err) => {
                console.log(err, "error in clickVisit function");
            });
    }

    //add favri
    const addToWishist = (data, wish) => {
        let userId = Auth?.getUserDetails()?._id;
        let dataToend = {
            userId: userId,
            PropertyId: data._id,
            PropertyType: "Residential",
        };
        addProprtyToWish(dataToend)
            .then((res) => {
                if (res.data.status === true) {
                    // let wishlisttatus = res.data.wishlist.Properties;
                    // let status = wishlisttatus.map((wishlist, index) => {
                    //   if (wishlist.PropertyId === data._id) {
                    //     return true;
                    //   } else {
                    //     return false;
                    //   }
                    // });
                    setActiveWish({
                        ...activeWish,
                        [data._id]: true,
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const formatCash = (n) => {
        if (n < 1e3) return n;
        if (n >= 1e3 && n < 1e5) return +(n / 1e3).toFixed(1) + "K";
        if (n >= 1e5 && n < 1e7) return +(n / 1e5).toFixed(1) + "L";
        if (n >= 1e7 && n < 1e9) return +(n / 1e7).toFixed(1) + "cr";
        if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
        if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
    };

    const copyToClipboard = (e) => {
        textAreaRef.current.select();
        document.execCommand("copy");
        // This is just personal preference.
        // I prefer to not show the whole text area selected.
        e.target.focus();
        setOpen(true);
        // setCopySuccess("Copied!");
    };

    //contact enquiry
    const handleChange = (e) => {
        const { name, value } = e.target;
        setUseContactDetails({ ...useContactDetails, [name]: value });

        if (name === "Name" && value !== null && value !== "") {
            setError({
                ...error,
                Name: "Name field can't be left blank. Please enter your name!",
            });
        }

        if (name === "email" && value !== null && value !== "") {
            setError({
                ...error,
                email: "Email ID field can't be left blank. Please enter",
            });
        }
        if (name === "Phone" && value !== null && value !== "") {
            setError({
                ...error,
                Phone: "Please enter a 10-digit Mobile number!",
            });
        }
    };

    const validation = () => {
        let errors = {};
        let isValid = true;

        if (useContactDetails.Name === "" || useContactDetails.Name === null) {
            isValid = false;
            errors["Name"] =
                "Name field can't be left blank. Please enter your name!";
        }

        if (useContactDetails.email === "" || useContactDetails.email === null) {
            isValid = false;
            errors["email"] = "Email ID field can't be left blank. Please enter!";
        }

        if (useContactDetails.Phone === "" || useContactDetails.Phone === null) {
            isValid = false;
            errors["Phone"] = "Please enter a 10-digit Mobile number!";
        } else if (useContactDetails.terms === false) {
            isValid = false;
            Swal.fire({
                icon: "warning",
                title: "Please Agree to EquiReal Terms",
                text: "By clicking 'OK', you confirm that you agree to the terms and conditions of EquiReal.",
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK",
            });
        }
        setError(errors);
        return isValid;
    };

    const submitContactDetails = (e) => {
        e.preventDefault();
        const isValid = validation();
        if (isValid) {
            let dataToSend = {
                fullName: useContactDetails.Name,
                email: useContactDetails.email,
                phone: useContactDetails.Phone,
                PropertyId: projectData.PropertyId,
                agentId: projectData.builderId,
            };
            sendEnquiryData(dataToSend)
                .then((res) => {
                    if (res.data.status === true) {
                        Swal.fire({
                            icon: "success",
                            title: "Enquiry Added Successfully!",
                            text: "Your enquiry has been submitted and our team will get back to you shortly.",
                            showConfirmButton: true,
                            confirmButtonColor: "#3085d6",
                            confirmButtonText: "OK",
                        });
                        setShow(false);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };


    const handleClose = () => {
        setShowSocial(false);
        setWarnLogin(false);
        setUseContactDetails({
            Name: "",
            email: "",
            Phone: "",
            terms: false
        });
        setShow(false);
        setError([]);
    };

    const handleChangeChecbox = (event) => {
        const { name, value, type, checked } = event.target;
        setUseContactDetails({
            ...useContactDetails,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    return (
        <>

            <div className='flex-grow-1'>
                {allProjectData.length === 0 ? (
                    <p className="errormsg">No data found</p>
                ) : (
                    <>
                        <Slider {...settings}>
                            {allProjectData &&
                                allProjectData.map((listData) => {
                                    return (
                                        <div
                                            className="p-card-content"
                                            onClick={() => clickVisit(listData._id)}
                                        >
                                            <div
                                                className="p-card"
                                            // onClick={() =>
                                            //     navigate(`/fulltab/${"Construction"}/${listData._id}`, {
                                            //         state: {
                                            //             yourData: listData,
                                            //             propertyType: "Construction",
                                            //         },
                                            //     })
                                            // }
                                            >
                                                <div className="p-img">
                                                    {listData?.photosData?.projectImages[0]?.name ?
                                                        (<img
                                                            src={`${listData?.photosData?.projectImages[0]?.name}`}
                                                            alt="Property"
                                                            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                                            
                                                        />)
                                                        :
                                                        (
                                                            <img
                                                                src="https://api.equireal.co.in/defaultProperty.jpg"
                                                                alt="Property"
                                                                className="property-image"
                                                            />
                                                        )}
                                                </div>

                                                <div className="p-info">
                                                    <div className="p-tle">
                                                        <div>
                                                            <h4>
                                                                {listData?.nameOfProject} Project
                                                            </h4>

                                                            <p>
                                                                in {listData?.subLocation},{listData?.Location}{" "}
                                                                <a href="/#">
                                                                    <PlaceOutlinedIcon fontSize="2" />
                                                                    View on map
                                                                </a>
                                                            </p>
                                                        </div>
                                                        <div className="d-flex align-items-center">
                                                            {/* like icon */}
                                                            {Auth?.user?.userData?.role === "Owner" ||
                                                                Auth?.user?.userData?.role === "Agent" ||
                                                                Auth?.user?.userData?.role === "Admin" ||
                                                                Auth?.user?.userData?.role === "Builder" ? null : (
                                                                <Tooltip
                                                                    title="Add to Favourite"
                                                                    slotProps={{
                                                                        popper: {
                                                                            modifiers: [
                                                                                {
                                                                                    name: "offset",
                                                                                    options: {
                                                                                        offset: [0, -14],
                                                                                    },
                                                                                },
                                                                            ],
                                                                        },
                                                                    }}
                                                                >
                                                                    <IconButton
                                                                        title="Add to Favourite"
                                                                        onClick={() => {
                                                                            userId ? addToWishist(listData) : handleOpen();
                                                                        }}
                                                                    // disabled={activeWish ? true : false}
                                                                    >
                                                                        {activeWish[listData?._id] ? (
                                                                            <FavoriteIcon
                                                                                sx={{ color: pink[500] }}
                                                                                style={{ fill: pink[500], cursor: "pointer" }}
                                                                            />
                                                                        ) : (
                                                                            <FavoriteBorderIcon />
                                                                        )}
                                                                    </IconButton>
                                                                </Tooltip>
                                                            )}

                                                            {/* share icon */}

                                                            <Tooltip
                                                                title="Share"
                                                                slotProps={{
                                                                    popper: {
                                                                        modifiers: [
                                                                            {
                                                                                name: "offset",
                                                                                options: {
                                                                                    offset: [0, -14],
                                                                                },
                                                                            },
                                                                        ],
                                                                    },
                                                                }}
                                                            >
                                                                <IconButton
                                                                    onClick={() => {
                                                                        setShowSocial(true);
                                                                        setSocialUrl(
                                                                            `https://equireal.co.in/fulltab/${"Construction"}/${listData?._id
                                                                            }`
                                                                        );
                                                                    }}
                                                                >
                                                                    <ShareOutlinedIcon />{" "}
                                                                </IconButton>
                                                            </Tooltip>

                                                            <Tooltip
                                                                title="People viewing this property"
                                                                slotProps={{
                                                                    popper: {
                                                                        modifiers: [
                                                                            {
                                                                                name: "offset",
                                                                                options: {
                                                                                    offset: [0, -14],
                                                                                },
                                                                            },
                                                                        ],
                                                                    },
                                                                }}
                                                            >
                                                                <p>
                                                                    <VisibilityIcon /> {visitCount[listData?._id] || 0}
                                                                </p>
                                                            </Tooltip>
                                                        </div>
                                                    </div>

                                                    {/* details */}
                                                    <div
                                                        className="pr-info"
                                                        onClick={() =>
                                                            navigate(`/fulltab/${"Construction"}/${listData?._id}`, {
                                                                state: {
                                                                    yourData: listData,
                                                                    propertyType: "Construction",
                                                                },
                                                            })
                                                        }
                                                    >
                                                        <ul>
                                                            <li>
                                                                <PeopleOutlineOutlinedIcon />
                                                                <h6>{listData?.propertyOwner}</h6>
                                                            </li>
                                                            <li>
                                                                <BedOutlinedIcon />
                                                                <h6> {listData?.areaType.slice(0, 2).join(" , ")}</h6>
                                                            </li>

                                                            <li>
                                                                <MdOutlineAdUnits />
                                                                <h6>
                                                                    <h6> {listData?.totalUnits} units</h6>
                                                                </h6>
                                                            </li>
                                                            {listData?.reraNo ?
                                                                <li>
                                                                    <MdConfirmationNumber />
                                                                    <h6>
                                                                        <h6>{listData?.reraNo} ReraNo</h6>
                                                                    </h6>
                                                                </li>
                                                                : null}
                                                            {listData?.paymentScheme ?
                                                                <li>
                                                                    <RiSecurePaymentLine />
                                                                    <h6>
                                                                        <h6>{listData?.paymentScheme}</h6>
                                                                    </h6>
                                                                </li>
                                                                : null}
                                                        </ul>
                                                    </div>
                                                    <div className="d-flex justify-content-end">
                                                        <button
                                                            class="cta"
                                                            onClick={() => {
                                                                navigate(`/fulltab/${"Construction"}/${listData?._id}`, {
                                                                    state: {
                                                                        yourData: listData,
                                                                        propertyType: "Construction",
                                                                    },
                                                                });
                                                                clickVisit(listData?._id);
                                                            }}
                                                        >
                                                            <span class="hover-underline-animation"> View More </span>
                                                            <svg
                                                                id="arrow-horizontal"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="30"
                                                                height="10"
                                                                viewBox="0 0 46 16"
                                                            >
                                                                <path
                                                                    id="Path_10"
                                                                    data-name="Path 10"
                                                                    d="M8,0,6.545,1.455l5.506,5.506H-30V9.039H12.052L6.545,14.545,8,16l8-8Z"
                                                                    transform="translate(30)"
                                                                ></path>
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>

                                                <div className="p-pri">
                                                    <div>
                                                        <h4>₹{formatCash(listData?.minPrice)}/- - ₹{formatCash(listData?.maxPrice)}/-</h4>
                                                        <p>
                                                            <p href="">Project Price</p>
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <h4>{moment(listData?.launchDate).format("DD/MM/YYYY")}</h4>
                                                        <p>
                                                            <p href="">Launch Date</p>
                                                        </p>
                                                    </div>

                                                    <div>
                                                        {Auth?.user?.userData?.role === "Owner" ||
                                                            Auth?.user?.userData?.role === "Agent" ||
                                                            Auth?.user?.userData?.role === "Admin" ||
                                                            Auth?.user?.userData?.role === "Builder" ||
                                                            Auth?.user?.userData?.role === undefined ? null : (
                                                            <Button
                                                                variant="contained"
                                                                className="btn-p-owner"
                                                                onClick={() => {
                                                                    setShow(true);
                                                                    setUseContactDetails(userData);
                                                                    setProjectData({
                                                                        PropertyId: listData._id,
                                                                        builderId: listData.userId,
                                                                    });
                                                                }}
                                                            >
                                                                Contact Owner
                                                            </Button>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                        </Slider>
                        {/* <style jsx>{`
                        .slick-prev,
                        .slick-next {
                          display: none !important;
                        }
                      `}</style> */}
                    </>
                )
                }
                <div className="py-5">
                 <RecommendedProject propertyType="Construction" Location={Location}/>
                </div>

                {/* social share */}
                <Modal
                    show={showSocial}
                    onHide={() => handleClose()}
                    disableBackdropClick={false}
                    disableEscapeKeyDown={false}
                    centered
                >
                    <Modal.Header closeButton className="headerBlue">
                        <Modal.Title
                            className=" text-center loginTitle"
                            style={{ margin: "0 auto" }}
                        >
                            <h5 class="m-0">Share Project</h5>
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="d-flex justify-content-evenly align-items-center">
                            <FacebookShareButton url={socialUrl}>
                                <FacebookIcon size={40} round />
                            </FacebookShareButton>

                            <TwitterShareButton url={socialUrl}>
                                <TwitterIcon size={40} round />
                            </TwitterShareButton>

                            <WhatsappShareButton url={socialUrl}>
                                <WhatsappIcon size={40} round />
                            </WhatsappShareButton>

                            <EmailShareButton url={socialUrl}>
                                <EmailIcon size={40} round />
                            </EmailShareButton>
                        </div>
                        <InputGroup className="mt-3">
                            <Form.Control
                                placeholder="Property link"
                                aria-label="Property link"
                                aria-describedby="basic-addon2"
                                value={socialUrl}
                                ref={textAreaRef}
                            />
                            <ButtonR
                                variant="outline-secondary"
                                id="button-addon2"
                                onClick={copyToClipboard}
                            >
                                copy
                            </ButtonR>
                        </InputGroup>
                        {open && (
                            <Snackbar open={open} autoHideDuration={2000} message="copied!" />
                        )}
                    </Modal.Body>
                </Modal>

                {/* login please modal */}
                <Modal
                    show={warnLogin}
                    onHide={handleClose}
                    size="lg"
                    centered
                    closeButton
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6">
                            You need to Login to continue. <br />
                            Please Login.
                        </Typography>
                        <Button onClick={() => navigate("/login")}>Login</Button>
                    </Box>
                </Modal>

                {/* contact agent modal */}
                <Modal
                    show={show}
                    onHide={() => handleClose()}
                    disableBackdropClick={false}
                    disableEscapeKeyDown={false}
                    centered
                >
                    <Modal.Header closeButton className="headerBlue">
                        <Modal.Title
                            className=" text-center loginTitle"
                            style={{ margin: "0 auto" }}
                        >
                            <h5 class="m-0">Fill out this one-time form</h5>
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <p className="text-gray-700 mb-4 text-center">
                            Get Builder details over email
                        </p>
                        <div className="d-flex justify-content-center align-items-center">
                            <form>
                                <div className="mb-4">
                                    <TextField
                                        id="name"
                                        label="Your Name"
                                        variant="standard"
                                        fullWidth
                                        value={useContactDetails.Name}
                                        name="Name"
                                        onChange={handleChange}
                                    // helperText={error.Name !== "" ? error.Name : null}
                                    // error={error.Name !== "" ? true : false}
                                    />
                                </div>
                                <div className="mb-4">
                                    <TextField
                                        id="email"
                                        label="Email"
                                        variant="standard"
                                        type="email"
                                        name="email"
                                        onChange={handleChange}
                                        value={useContactDetails.email}
                                        fullWidth
                                    // helperText={error.email !== "" ? error.email : null}
                                    // error={error.email !== "" ? true : false}
                                    />
                                </div>
                                <div className="mb-4">
                                    <div className="flex">
                                        <TextField
                                            color="grey"
                                            id="standard-select-currency-native"
                                            select
                                            label="Country"
                                            //   defaultValue="EUR"
                                            SelectProps={{
                                                native: true,
                                            }}
                                            variant="standard"
                                        >
                                            {currencies.map((option) => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </TextField>
                                        <TextField
                                            id="standard-basic"
                                            label="Mobile Number"
                                            variant="standard"
                                            color="grey"
                                            name="Phone"
                                            value={useContactDetails.Phone}
                                            onChange={handleChange}
                                        // helperText={error.Phone !== "" ? error.Phone : null}
                                        // error={error.Phone !== "" ? true : false}
                                        />
                                    </div>
                                </div>
                                <div className="mb-6">
                                    <label className="inline-flex items-center">
                                        <Checkbox
                                            color="primary"
                                            name="terms"
                                            checked={useContactDetails.terms}
                                            onChange={handleChangeChecbox}
                                        />
                                        <span className="ml-2">
                                            I Agree to EquiReal'{" "}
                                            <a className="text-indigo-200 underline">Terms of Use</a>
                                        </span>
                                    </label>
                                </div>
                                <div className="flex items-center justify-center mt-2">
                                    <Button
                                        variant="contained"
                                        color="error"
                                        type="submit"
                                        fullWidth
                                        onClick={submitContactDetails}
                                    >
                                        Get Contact Details
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>

        </>
    )
}

export default FilterNewProject;