/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import Auth from "../../Config/helper/Auth";
import { getProfileById, updateProfile } from "../../Api/AuthAPI";
import Swal from "sweetalert2";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import { settings } from "../../Config/Settings";
import { styled } from "@mui/material/styles";
import ButtonM from "@mui/material/Button";
import { IpfsImgUpload } from "../../modules/helpers/Ipfs";
import { RiUploadCloud2Fill } from "react-icons/ri";
import "./profile.css";
import { Select, MenuItem, InputLabel, FormControl } from "@mui/material";
import { Modal } from "react-bootstrap";
import { BubbleChartOutlined } from "@material-ui/icons";
import VerifiedIcon from '@mui/icons-material/Verified';
import NewReleasesOutlinedIcon from '@mui/icons-material/NewReleasesOutlined';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import TextField from '@material-ui/core/TextField';
import { RxCross2 } from "react-icons/rx";
import { BsFillFileEarmarkPdfFill } from "react-icons/bs";
import swal from "sweetalert";
import { TabContext } from '@mui/lab';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from "material-ui";

const EditProfile = () => {
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });
  const genderOptions = [
    { value: "Female", label: "Female" },
    { value: "Male", label: "Male" },
    { value: "Others", label: "Others" },
  ];

  const idProofOptions = [
    { value: "Aadhaar Card", label: "Aadhar Card" },
    { value: "panCard", label: "Pan Card" },
    { value: "other", label: "Other" },
    { value: "driving_license", label: "Driving License" },
  ];
  const userData = Auth.getUserDetails();
  const [profileData, setProfileData] = useState({});
  // const [profileImage, setProfileImage] = useState([]);

  // const [profilePreview, setProfilePreview] = useState([]);

  const [file, setFile] = useState([]);
  const [idProofImage, setIdProofImage] = useState([]);
  const [gender, setGender] = useState([]);
  const [idProof, setIdProof] = useState("");

  const [show, setShow] = useState(false);
  const [otpValue, setOtpValue] = useState({});
  const [numbers, setNumbers] = useState([]);
  const addNumber = (number) => {
    setNumbers((prevNumbers) => [...prevNumbers, number]);
  };

  const handleOtp = () => {
    const newnumber = parseInt(numbers.join(""), 10);
  };
  const handleClose = () => {
    setShow(false);
    setNumbers([]);
  };


  const [profilePreviewFile, setProfilePreviewFile] = useState("");
  const [selectedprofilePreview, setSelectedProfilePreview] = useState([]);
  const [profilePreviewImg, setProfilePreviewImg] = useState([]);

  const [aadharFile, setAadharFile] = useState("");
  const [selectedAdhar, setSelectedAdhar] = useState([]);
  const [selectedPan, setSelectedPan] = useState([]);
  const [panFile, setPanFile] = useState("");
  const [aadharImg, setAadharImg] = useState([]);
  const [panImg, setPanImg] = useState([]);
  // const [AdharID, setAadharCard] = useState([]);
  // const [PanID, setPanCard] = useState([]);
  const [selectDrivingLicense, setSelectDrivingLicense] = useState([])
  const [drivingLicenseFile, setDrivingLicenseFile] = useState("");
  const [selectOther, setSeletctOther] = useState([]);
  const [otherFile, setOtherFile] = useState("");
  const [drivingLicenseImg, setDrivingLicenseImg] = useState([]);
  const [otherImg, setOtherImg] = useState([]);


  const [projectData, setProjectData] = useState({ imageTitle: '' });
  const [formError, setFormError] = useState({ imageTitle: '' });
  const [uploadImages, setUploadImages] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const fileInputRef = useRef(null);
  const [value, setValue] = useState("1");
  const [uploadImagesError, setUploadImagesError] = useState([]);
  const [editProfileDatas, setEditProfileData] = useState(false);

  const onChangeHandler = (name, value) => {
    setProjectData({ ...projectData, [name]: value });
  };

  const fileSelectHandler = (event) => {

    const selectedFile = Array.from(event.target.files);
    if (selectedFile) {
      if (selectedFile[0].type !== "image/jpeg" && selectedFile[0].type !== "application/pdf") {
        swal({
          icon: "error",
          title: "Invalid file type",
          text: "Please upload a JPG or PDF file.",
          button: "OK",
        });
      } else {
        const newBrouchures = selectedFile.map((file) => ({
          imageTitle: projectData.imageTitle,
          name: URL.createObjectURL(file),
          newName: file.name,
          type: file.type,
        }));

        setUploadImages((prevImages) => (prevImages ? [...prevImages, ...selectedFile] : [...selectedFile]));
        setSelectedImages([...selectedImages, ...newBrouchures]);
      }
    }
    else {
      setUploadImages(null);
      setSelectedImages([]);
    }
    setUploadImagesError([]);
    setProjectData({ ...projectData, imageTitle: '' });

  };

  const handleButtonClick = (inputName) => {
    if (inputName === 'uploadImage') {
      if (projectData.imageTitle.trim() === '') {
        setFormError({
          ...formError,
          imageTitle: 'Please enter image title .',
        });
        return;
      }
      fileInputRef.current.click();
    }
  };

  const removeImage = (index) => {
    const updatedImages = selectedImages.filter((_, i) => i !== index);
    setSelectedImages(updatedImages);
  };

  // const handleFile = (event, type) => {
  //   if (event.target.files && event.target.files[0]) {
  //     let file = event.target.files[0];
  //     const selectedFile = Array.from(event.target.files);
  //     const fileType = file.type;
  //     const validTypes = ["image/jpeg", "application/pdf"];

  //     if (!validTypes.includes(fileType)) {
  //       Swal.fire({
  //         icon: "error",
  //         title: "Invalid file type",
  //         text: "Please upload a JPG or PDF file.",
  //       });
  //       return;
  //     }
  //     if (type === "aadhar") {
  //       setAadharImg(file);
  //       const newAdharCardImage = selectedFile.map((file) => ({
  //         newName: file.name,
  //         name: URL.createObjectURL(file),
  //         type: file.type,
  //       }))
  //       setSelectedAdhar(newAdharCardImage)
  //       const reader = new FileReader();
  //       setAadharFile(URL.createObjectURL(file));
  //       reader.onloadend = () => {
  //         setAadharFile(reader.result);
  //       };
  //       reader.readAsDataURL(file);
  //     } else if (type === "pan") {
  //       setPanImg(file);
  //       const newPanCardImage = selectedFile.map((file) => ({
  //         newName: file.name,
  //         name: URL.createObjectURL(file),
  //         type: file.type,
  //       }))
  //       setSelectedPan(newPanCardImage)
  //       setPanFile(URL.createObjectURL(file));
  //       const reader = new FileReader();
  //       reader.onloadend = () => {
  //         setPanFile(reader.result);
  //       };
  //       reader.readAsDataURL(file);
  //     }
  //     else if (type === "driving_license") {
  //       setDrivingLicenseImg(file);
  //       const newDrivingLicenseImage = selectedFile.map((file) => ({
  //         newName: file.name,
  //         name: URL.createObjectURL(file),
  //         type: file.type,
  //       }))
  //       setSelectDrivingLicense(newDrivingLicenseImage)
  //       setDrivingLicenseFile(URL.createObjectURL(file));
  //       const reader = new FileReader();
  //       reader.onloadend = () => {
  //         setDrivingLicenseFile(reader.result);
  //       };
  //       reader.readAsDataURL(file);
  //     }
  //     else if (type === "other") {
  //       setOtherImg(file);
  //       const newOtherImage = selectedFile.map((file) => ({
  //         newName: file.name,
  //         name: URL.createObjectURL(file),
  //         type: file.type,
  //       }))
  //       setSeletctOther(newOtherImage)
  //       setOtherFile(URL.createObjectURL(file));
  //       const reader = new FileReader();
  //       reader.onloadend = () => {
  //         setOtherFile(reader.result);
  //       };
  //       reader.readAsDataURL(file);
  //     }

  //   }
  // };

  //-------


  // const handleFile = async (event) => {
  //   // console.log(e.target.files[0], "file");
  //   // setFile(e.target.files[0]);

  //   if (event.target.files && event.target.files[0]) {
  //     let file = event.target.files[0];
  //     const imageValue = await IpfsImgUpload(file);
  //     const updatedidProofImage = [
  //       // ...idProofImage,
  //       { name: file.name, value: imageValue },
  //     ];
  //     let profileImages = `${settings.IMAGE_URL}/${imageValue}`;
  //     setIdProofImage(updatedidProofImage);
  //     setFile(profileImages);
  //   }
  // };
  const handleGender = (e) => {
    setGender(e.target.value);
    // setError({ ...error, gender: "" });
  };
  const handleIdProof = (e) => {
    setIdProof(e.target.value);
    // setError({ ...error, idProof: "" });
    setFile([]);
  };

  const inputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setProfileData({ ...profileData, [name]: value });
  };
  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = () => {
    getProfileById(userData._id)
      .then((res) => {

        let data = res.data?.Profile;
        setProfileData(data);
        let profileviewcardImg = data?.profileImage[0]?.name;
        // let idProofImage = data?.idProofImage[0]?.value;
        // let adharcardImg = data?.AdharID[0]?.name;
        // let pancardImg = data?.PanID[0]?.name;
        // let drivingLicensecardImg = data?.DrivingLicence[0].name;
        // let otherCardImg = data?.OthersID[0].name;
        setFile(idProofImage)
        // setIdProofImage(data.idProofImage[0])
        setGender(data.gender);
        setIdProof(data.idProof);
        setSelectedProfilePreview(data.profileImage);
        setProfilePreviewImg(data.profileImage);
        setProfilePreviewFile(profileviewcardImg);
        // setAadharFile(adharcardImg);
        // setPanFile(pancardImg);
        // setDrivingLicenseFile(drivingLicensecardImg);
        // setOtherFile(otherCardImg);
        setAadharImg(data.AdharID);
        setSelectedAdhar(data.AdharID);
        setSelectedPan(data.PanID);
        setSelectDrivingLicense(data.DrivingLicence);
        setSeletctOther(data.OthersID);
        setPanImg(data.PanID);
        setDrivingLicenseImg(data.DrivingLicence);
        setOtherImg(data.OthersID);
        setSelectedImages(data?.documents);

        console.log(data?.documents, "data?.documents");
      })
      .catch((err) => {
        console.log(err);
      });

  };
  // const onImageChange = async (event, name) => {
  //   if (event.target.files && event.target.files[0]) {
  //     let file = event.target.files[0];
  //     const imageValue = await IpfsImgUpload(file);
  //     const updatedProfileImage = [
  //       ...profileImage,
  //       { name: file.name, value: imageValue },
  //     ];
  //     let profileImages = `${settings.IMAGE_URL}/${imageValue}`;
  //     setProfileImage(updatedProfileImage);
  //     setProfilePreview(profileImages);
  //   }
  // };

  // const onImageChange = (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setProfilePreviewFile(reader.result);
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };

  // const onImageChange = async (event, name) => {
  //   if (event.target.files && event.target.files[0]) {

  //     let file = event.target.files[0];
  //     const selectedFiles = Array.from(event.target.files);
  //     const fileType = file.type;
  //     const validTypes = ["image/jpeg", "application/pdf"];

  //     if (!validTypes.includes(fileType)) {
  //       Swal.fire({
  //         icon: "error",
  //         title: "Invalid file type",
  //         text: "Please upload a JPG or PDF file.",
  //       });
  //       return;
  //     }
  //     setProfilePreviewImg(file);
  //     const updatedProfileImage = selectedFiles.map((file) => ({
  //       newName: file.name,
  //       name: URL.createObjectURL(file),
  //       type: file.type,
  //     }))
  //     setSelectedProfilePreview(updatedProfileImage)
  //     const reader = new FileReader();
  //     setProfilePreviewFile(URL.createObjectURL(file));
  //     reader.onloadend = () => {
  //       setProfilePreviewFile(reader.result);
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };

  const onImageChange = async (event, name) => {

    if (event.target.files && event.target.files.length > 0) {
      const selectedFiles = Array.from(event.target.files);
      const validTypes = ["image/jpeg", "application/pdf"];

      // Filter out invalid file types
      const validFiles = selectedFiles.filter(file => validTypes.includes(file.type));
      if (validFiles.length !== selectedFiles.length) {
        Swal.fire({
          icon: "error",
          title: "Invalid file type",
          text: "Please upload only JPG or PDF files.",
        });
        return;
      }
      setProfilePreviewImg((prevImage) => ([...validFiles]));
      const updatedProfileImage = validFiles.map((file) => ({
        newName: file.name,
        name: URL.createObjectURL(file),
        type: file.type,
      }));
      setSelectedProfilePreview((prevImage) => ([...updatedProfileImage]));
      const file = validFiles[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfilePreviewFile(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const editProfileData = () => {
    setEditProfileData(true)
  };


  const editProfile = (e) => {

    e.preventDefault();
    const formData = new FormData();

    if (profilePreviewImg && profilePreviewImg.length > 0) {
      profilePreviewImg.forEach((file, index) => {
        formData.append('profileImage', file);
      });
    }

    // Append documents if available
    if (uploadImages && uploadImages.length > 0) {
      uploadImages.forEach((file, index) => {
        formData.append('documents', file);
      });
    }

    const payloadjson = {
      email: userData.email,
      phone: userData.phone,
      fullName: profileData.fullName,
      gender: gender,
      profileImage: selectedprofilePreview,
      documents: selectedImages
    };

    const updateMemberprofile = [payloadjson];

    formData.append("details", JSON.stringify(updateMemberprofile));

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    updateProfile(formData, userData._id, config)
      .then((res) => {
        Swal.fire({
          icon: 'success',
          title: 'Profile Updated Successfully!',
          text: 'Your profile information has been saved.',
          showConfirmButton: true,
          confirmButtonColor: '#3085d6',
          confirmButtonText: "ok",
        });
        setEditProfileData(false);
      })
      .catch((err) => {
        console.log(err);
      });

  };


  // const editProfile = (e) => {
  //   e.preventDefault();
  //   const formData = new FormData();
  //   // formData.append('fullName', profileData.fullName);
  //   // formData.append('gender', gender);
  //   // formData.append('email', userData.email);
  //   if (profilePreviewImg) {
  //     formData.append('profileImage', profilePreviewImg);
  //   }

  //   uploadImages?.forEach((file) => {
  //     formData.append("documents", file);
  //   });


  //   // if (aadharImg) {
  //   //   formData.append('AdharID', aadharImg);
  //   // }
  //   // if (panImg) {
  //   //   formData.append('PanID', panImg);
  //   // }
  //   // if (drivingLicenseImg) {
  //   //   formData.append('DrivingLicence', drivingLicenseImg)
  //   // }
  //   // if (otherImg) {
  //   //   formData.append('OthersID', otherImg)
  //   // }

  //   const payloadjson = {
  //     email: userData.email,
  //     phone: userData.phone,
  //     fullName: profileData.fullName,
  //     gender: gender,
  //   };

  //   const updateMemberprofile = [{
  //     ...payloadjson,
  //     // PanID: selectedPan,
  //     // AdharID: selectedAdhar,
  //     // DrivingLicence: selectDrivingLicense,
  //     // OthersID: selectOther,
  //     profileImage: selectedprofilePreview,
  //     documents: selectedImages
  //   }];
  //   console.log(updateMemberprofile, "updateMemberprofile");
  //   formData.append("details", JSON.stringify(updateMemberprofile));
  //   const config = {
  //     headers: {
  //       "content-type": "multipart/form-data",
  //     },
  //   };

  //   updateProfile(formData, userData._id, config)
  //     .then((res) => {
  //       Swal.fire({
  //         icon: "success",
  //         title: "Profile Updated",
  //         showConfirmButton: true,
  //         confirmButtonColor: "#3085d6",
  //         confirmButtonText: "ok",
  //       });
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };



  // const editProfile = (e) => {
  //   e.preventDefault();

  //   const formData = new FormData();
  //   if (profileImage.length > 0) {
  //     formData.append('profileImage', profileImage[0].value);
  //   }
  //   if (AdharID) {
  //     formData.append('AdharID', aadharImg);
  //   }
  //   if (PanID) {
  //     formData.append('PanID', panImg);
  //   }
  //   const id = userData._id;
  //   let profile = {


  //     email:userData.email,
  //     // phone: payloadjson.phone,
  //     fullName: profileData.fullName,
  //     gender: gender,
  //     PanID: panImg,
  //     AdharID: aadharImg,
  //     profileImage: profileImage[0]?.value,

  //   };

  //   formData.append('deteils',JSON.stringify(profile) );
  //   const config = {
  //     headers: {
  //       "content-type": "multipart/form-data",
  //     },
  //   };

  //   updateProfile(formData, id,config)
  //     .then((res) => {
  //       Swal.fire({
  //         icon: "success",
  //         title: "Profile Updated",
  //         showConfirmButton: true,
  //         confirmButtonColor: "#3085d6",
  //         confirmButtonText: "ok",
  //       });
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  return (
    <>
      <div>
        <div className="p-4">
          <form>
            <Grid container>
              {/* <Grid item xs={12} md={3}>
                <Tooltip title="Edit Profile Image">
                  <label htmlFor="contained-button-file">
                    <input
                      accept="image/*"
                      id="contained-button-file"
                      name="image"
                      type="file"
                      style={{ display: "none" }}
                      onChange={onImageChange}
                    />

                    <Avatar
                      src={profilePreviewFile}
                      variant="square"
                      style={{
                        width: 250,
                        height: 250,
                        // borderRadius: 200 / 2,
                        // marginTop: 20,
                        borderWidth: 5,
                        borderColor: "#000",
                      }}
                    >
                      <AddAPhotoIcon style={{
                        position: 'absolute',
                        bottom: 10,
                        right: 10,
                        fontSize: 30,
                        color: '#000',
                        backgroundColor: '#fff',
                        borderRadius: '50%',
                        padding: 5
                      }} />
                    </Avatar>
                  </label>
                </Tooltip>
              </Grid> */}

              <Grid item xs={12} md={3}>
                {!editProfileDatas ?
                  <Avatar
                    src={profilePreviewFile}
                    variant="square"
                    style={{
                      width: 198,
                      height: 210,
                      outline: 'none',
                      // borderWidth: 5,
                      // borderColor: '',
                    }}
                  /> :

                  <Tooltip title="Edit Profile Image" arrow >
                    <div style={{ position: 'relative' }}>
                      <input
                        accept="image/*"
                        id="profile-image-upload"
                        type="file"
                        style={{ display: 'none' }}
                        onChange={onImageChange}
                      />
                      <div
                        component="span"
                        onClick={() => document.getElementById('profile-image-upload').click()}
                        style={{ cursor: 'pointer' }}
                      >
                        <Avatar
                          src={profilePreviewFile}
                          variant="square"
                          style={{
                            width: 198,
                            height: 210,
                            outline: 'none',
                            // borderWidth: 5,
                            // borderColor: '',
                          }}
                        />
                          <AddAPhotoIcon
                            style={{
                              position: 'absolute',
                              bottom: '7px',
                              right: '15%',
                              fontSize: 30,
                              color: '#000',
                              backgroundColor: '#fff',
                              borderRadius: '50%',
                              padding: 5,
                            }}
                          />
                        
                        {/* </Avatar> */}
                      </div>
                    </div>
                  </Tooltip>
                }
              </Grid>
              <Grid item xs={12} md={8} >
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formHorizontalEmail"
                >

                  {/* <Form.Label column sm={2}>
                    <b> Name</b>
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      placeholder="fullName"
                      value={profileData.fullName}
                      name="fullName"
                      variant="standard"
                      onChange={inputChange}
                    />
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formHorizontalEmail"
                >
                  <Form.Label column sm={2}>
                    <b>Email</b>
                  </Form.Label>
                  <Col sm={10}>
                    {profileData.email}
                    <Tooltip title="Verified" placement="right" arrow>
                      <VerifiedIcon className="ms-3 text-success" />
                    </Tooltip>
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formHorizontalEmail"
                > */}
                  <Form.Label column sm={2}>
                    <b>Mobile</b>
                  </Form.Label>
                  <Col sm={10} className="d-flex align-items-center">
                    {profileData.phone}
                    <Tooltip title="Verification Pending" placement="right" arrow>
                      <span onClick={() => setShow(true)} className="ms-3 text-danger"

                      > <NewReleasesOutlinedIcon />
                        {/* Verification Pending */}
                      </span></Tooltip>
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formHorizontalEmail"
                >
                  <Form.Label column sm={2}>
                    <b>Gender</b>
                  </Form.Label>
                  {!editProfileDatas ?
                    <Col sm={10}>
                      <input
                        style={{ width: '300px', minHeight: '40px', fontSize: '14px' }}
                        required
                        name="gender"
                        value={gender}
                        size="small"
                        variant="outlined"
                      // onChange={handleGender}
                      />
                    </Col> :
                    <Col sm={10}>
                      {/* <InputLabel>Select Gender</InputLabel> */}
                      <Select
                        style={{ width: '300px', minHeight: '40px', fontSize: '14px' }}
                        required
                        name="gender"


                        value={gender}
                        size="small"
                        variant="outlined"
                        onChange={handleGender}
                      >
                        {genderOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </Col>
                  }
                </Form.Group>

                <Form.Group
                  as={Row}
                  className="mb-3 d-flex"
                  controlId="formHorizontalEmail"
                >

                  {/* <Form.Label column sm={2}>
                      <b>ID Proof</b>
                  </Form.Label> */}
                  {/* <Col sm={4}>
                    <Select
                      required
                      fullWidth
                      name="idProof"
                      size="small"
                      value={idProof}
                      variant="outlined"
                      onChange={handleIdProof}
                    >
                      {idProofOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Col> */}
                  {/* <Form.Label column sm={10}>
                      <div className="d-flex align-items-start gap-5">
                        <div className="box-doc">
                          <div className="d-flex justify-content-between gap-5">  Aadhar Card
                            <Tooltip title="Upload Document" placement="right" arrow>
                              <ButtonM
                                component="label"
                                name="aadharCard"
                                role={undefined}
                                variant="outlined"
                                tabIndex={-1}
                                startIcon={<RiUploadCloud2Fill />}
                              >

                               

                                <input type="file" onChange={(e) => handleFile(e, "aadhar")} style={{ display: "none" }} />
                              </ButtonM>
                            </Tooltip>
                          </div>
                          {aadharFile ?
                            <img alt="id_proof" src={aadharFile} height={120} width={120} className="img-thumbnail" />
                            : null
                          }
                        </div>
                        <div className="box-doc">
                          <div className="d-flex justify-content-between gap-5">  Pan Card
                            <Tooltip title="Upload Document" placement="right" arrow>
                              <ButtonM
                                component="label"
                                name="panCard"
                                role={undefined}
                                variant="outlined"
                                tabIndex={-1}
                                startIcon={<RiUploadCloud2Fill />}
                              >
                                
                                <input type="file" onChange={(e) => handleFile(e, "pan")} style={{ display: "none" }} />
                              </ButtonM>
                            </Tooltip>
                          </div>
                          {panFile ?

                            <img alt="id_proof" src={panFile} height={120} width={120} className="img-thumbnail" />

                            : null
                          }
                        </div>
                        <div className="box-doc">
                          <div className="d-flex justify-content-between gap-5">  Driving License
                            <Tooltip title="Upload Document" placement="right" arrow>
                              <ButtonM
                                component="label"
                                name="aadharCard"
                                role={undefined}
                                variant="outlined"
                                tabIndex={-1}
                                startIcon={<RiUploadCloud2Fill />}
                              >


                                <input type="file" onChange={(e) => handleFile(e, "driving_license")} style={{ display: "none" }} />
                              </ButtonM>
                            </Tooltip>
                          </div>
                       
                            <img alt="id_proof" src={aadharFile} height={120} width={120} className="img-thumbnail" />
                            
                        </div>
                        <div className="box-doc">
                          <div className="d-flex justify-content-between gap-5">  Others
                            <Tooltip title="Upload Document" placement="right" arrow>
                              <ButtonM
                                component="label"
                                name="aadharCard"
                                role={undefined}
                                variant="outlined"
                                tabIndex={-1}
                                startIcon={<RiUploadCloud2Fill />}
                              >


                                <input type="file" onChange={(e) => handleFile(e, "other")} style={{ display: "none" }} />
                              </ButtonM>
                            </Tooltip>
                          </div>
                            <img alt="id_proof" src={aadharFile} height={120} width={120} className="img-thumbnail" />
                        </div>
                      </div>


                      <div className="small-text"> Note: Only Jpg and PDF accepted.</div>
                    </Form.Label>  */}

                  {/* <Form.Label column sm={2}>
                      <b>ID Proof</b>
                    </Form.Label>
                    <Col sm={4}>
                      <Select
                        required
                        fullWidth
                        name="idProof"
                        size="small"
                        value={idProof}
                        variant="outlined"
                        onChange={handleIdProof}
                      >
                        {idProofOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </Col>
                    <Form.Label column sm={10}>
                      <div className="d-flex align-items-start gap-5">
                        {idProof === "Aadhaar Card" && (
                          <div className="box-doc">
                            <div className="d-flex justify-content-between gap-5">Aadhar Card
                              <Tooltip title="Upload Document" placement="right" arrow>
                                <ButtonM
                                  component="label"
                                  name="aadharCard"
                                  role={undefined}
                                  variant="outlined"
                                  tabIndex={-1}
                                  startIcon={<RiUploadCloud2Fill />}
                                >
                                  <input type="file" onChange={(e) => handleFile(e, "aadhar")} style={{ display: "none" }} />
                                </ButtonM>
                              </Tooltip>
                            </div>
                            {aadharFile && (
                              <img alt="id_proof" src={aadharFile} height={120} width={120} className="img-thumbnail" />
                            )}
                          </div>
                        )}
                        {idProof === "panCard" && (
                          <div className="box-doc">
                            <div className="d-flex justify-content-between gap-5">Pan Card
                              <Tooltip title="Upload Document" placement="right" arrow>
                                <ButtonM
                                  component="label"
                                  name="panCard"
                                  role={undefined}
                                  variant="outlined"
                                  tabIndex={-1}
                                  startIcon={<RiUploadCloud2Fill />}
                                >
                                  <input type="file" onChange={(e) => handleFile(e, "pan")} style={{ display: "none" }} />
                                </ButtonM>
                              </Tooltip>
                            </div>
                            {panFile && (
                              <img alt="id_proof" src={panFile} height={120} width={120} className="img-thumbnail" />
                            )}
                          </div>
                        )}
                        {idProof === "driving_license" && (
                          <div className="box-doc">
                            <div className="d-flex justify-content-between gap-5">Driving License
                              <Tooltip title="Upload Document" placement="right" arrow>
                                <ButtonM
                                  component="label"
                                  name="driving_license"
                                  role={undefined}
                                  variant="outlined"
                                  tabIndex={-1}
                                  startIcon={<RiUploadCloud2Fill />}
                                >
                                  <input type="file" onChange={(e) => handleFile(e, "driving_license")} style={{ display: "none" }} />
                                </ButtonM>
                              </Tooltip>
                            </div>
                            {drivingLicenseFile && (
                              <img alt="id_proof" src={drivingLicenseFile} height={120} width={120} className="img-thumbnail" />
                            )}
                          </div>
                        )}
                        {idProof === "other" && (
                          <div className="box-doc">
                            <div className="d-flex justify-content-between gap-5">Others
                              <Tooltip title="Upload Document" placement="right" arrow>
                                <ButtonM
                                  component="label"
                                  name="other"
                                  role={undefined}
                                  variant="outlined"
                                  tabIndex={-1}
                                  startIcon={<RiUploadCloud2Fill />}
                                >
                                  <input type="file" onChange={(e) => handleFile(e, "other")} style={{ display: "none" }} />
                                </ButtonM>
                              </Tooltip>
                            </div>
                            {otherFile && (
                              <img alt="id_proof" src={otherFile} height={120} width={120} className="img-thumbnail" />
                            )}
                          </div>
                        )}
                      </div>
                      <div className="small-text">Note: Only Jpg and PDF accepted.</div>
                    </Form.Label> */}


                  <Form.Label column sm={2}>
                    <b>ID Proof</b>
                  </Form.Label>
                  <Col sm={10}>

                    <TabContext value="1" className="tabarea">
                      {!editProfileDatas ? null :
                        <div className="builder-box text-center p-4">
                          <FormControl variant="outlined" size="small">
                            <div className="d-flex gap-3 align-items-center justify-content-center">
                              <FormControl variant="outlined" fullWidth size="small">
                                <TextField
                                  autoComplete="off"
                                  type="text"
                                  size="small"
                                  name="imageTitle"
                                  label="Image Title"
                                  value={projectData.imageTitle}
                                  onChange={(e) => {
                                    setFormError({
                                      ...formError,
                                      imageTitle: '',
                                    });
                                    onChangeHandler(
                                      'imageTitle',
                                      e.target.value);
                                  }}
                                  required
                                ></TextField>
                                <div style={{ color: 'red', fontSize: 13 }}>
                                  {formError.imageTitle}
                                </div>
                              </FormControl>

                              <FormControl variant="outlined" size="small">
                                <input
                                  accept="image/jpeg"
                                  type="file"
                                  name="uploadImage"
                                  ref={fileInputRef}
                                  onChange={fileSelectHandler}
                                  style={{ display: 'none' }}
                                />
                                <Button
                                  variant="contained"
                                  color="primary"
                                  className="add-builder"
                                  startIcon={<BsFillFileEarmarkPdfFill />}
                                  onClick={() => handleButtonClick('uploadImage')}
                                >
                                  Upload
                                </Button>
                              </FormControl>
                            </div>
                          </FormControl>
                        </div>}
                      {/* {selectedImages.length > 0 && (
                        <div
                          className="builder-pic"
                          style={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
                            gap: '16px'
                          }}
                        >
                          {selectedImages.map((image, index) => (
                            <div
                              className="b-pic"
                              key={index}
                              style={{
                                border: '1px solid #ddd',
                                padding: '8px',
                                borderRadius: '8px',
                                backgroundColor: '#fff',
                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                textAlign: 'center'
                              }}
                            >
                              <div>
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                  <Button
                                    variant="contained"
                                    color="error"
                                    onClick={() => removeImage(index)}
                                  >
                                    <DeleteIcon />
                                  </Button>
                                </div>
                                <a href={image.name} target="_blank" rel="noopener noreferrer">
                                  <span className="iconMagnifier"></span>
                                  <img
                                    src={image.name}
                                    alt="Uploaded"
                                    style={{
                                      width: '100%',
                                      height: 'auto',
                                      borderRadius: '4px'
                                    }}
                                  />
                                </a>
                              </div>
                              <FormControl variant="outlined" fullWidth size="small">
                                <span style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                  {image.imageTitle}
                                </span>
                              </FormControl>
                            </div>
                          ))}
                        </div>
                      )} */}
                      {selectedImages.length > 0 && (
                        <div
                          className="builder-pic"
                          style={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
                            gap: '16px'
                          }}
                        >
                          {selectedImages.map((image, index) => (
                            <div
                              className="b-pic"
                              key={index}
                              style={{
                                border: '1px solid #ddd',
                                padding: '8px',
                                borderRadius: '8px',
                                backgroundColor: '#fff',
                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',


                              }}
                            >
                              <div
                                variant="contained"
                                color="error"
                                onClick={() => removeImage(index)}
                                style={{
                                  top: '-1px',
                                  right: '-96px',
                                  position: 'relative',

                                }}
                              >
                                {!editProfileDatas ? null :
                                  <Tooltip title="Delete" arrow placement="right">
                                    <DeleteIcon style={{ cursor: "pointer" }} /></Tooltip>
                                }

                              </div>
                              <a href={image.name} target="_blank" rel="noopener noreferrer">
                                <span className="iconMagnifier"></span>
                                <img
                                  src={image.name}
                                  alt="Uploaded"
                                  style={{
                                    width: '100%',
                                    height: 'auto',
                                    borderRadius: '4px'
                                  }}
                                />
                              </a>
                              <FormControl variant="outlined" fullWidth size="small">
                                <span style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                  {image.imageTitle}
                                </span>
                              </FormControl>
                            </div>
                          ))}
                        </div>
                      )}


                    </TabContext>

                  </Col>


                  {/* <Col sm={4}>
                    <Select
                      required
                      fullWidth
                      name="idProof"
                      size="small"
                      value={idProof}
                      variant="outlined"
                      onChange={handleIdProof}
                    >
                      {idProofOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Col>
                  <Col >
                    <ButtonM
                      component="label"
                      role={undefined}
                      variant="outlined"
                      tabIndex={-1}
                      startIcon={<CloudUploadIcon/>}
                    >
                      {file.length === 0 ? "Upload file" : file.name}
                      <VisuallyHiddenInput type="file" onChange={handleFile} />
                    </ButtonM>
                  </Col>
                  <Col >
                    <div className="square">
                      <img alt="id_proof"  src={file} height={120} width={120} />
                    </div>
                  </Col> */}
                </Form.Group>

                <Form.Group>
                  <Form.Label column sm={4}>
                  </Form.Label>
                  <Form.Label column sm={4}>
                    <div>
                      {!editProfileDatas ?
                        <Button
                          // style={{alignItems:"center"}}
                          type="submit"
                          className="btn-red"
                          onClick={editProfileData}
                        >
                          Edit
                        </Button> :
                        <Button
                          // style={{alignItems:"center"}}
                          type="submit"
                          className="btn-red"
                          onClick={editProfile}
                        >
                          Update
                        </Button>
                      }
                    </div>
                  </Form.Label>
                </Form.Group>
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
      {/* otp verification modal */}
      <Modal
        show={show}
        onHide={() => handleClose()}
        disableBackdropClick={false}
        disableEscapeKeyDown={false}
        centered
      >
        <Modal.Header closeButton className="headerBlue">
          <Modal.Title
            className=" text-center loginTitle"
            style={{ margin: "0 auto" }}
          >
            <h5 class="m-0">Mobile phone verification</h5>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div class="d-flex justify-content-center align-items-center container">
            <div class="cardprofile py-5 px-3">
              <span class="mobile-text">
                Enter the code we just send on your mobile phone{" "}
                <b class="text-danger">+91 86684833</b>
              </span>
              <div class="d-flex flex-row mt-5">
                <input
                  type="text"
                  class="form-control"
                  onChange={(e) => addNumber(e.target.value)}
                  autofocus=""
                />
                <input
                  type="text"
                  class="form-control"
                  onChange={(e) => addNumber(e.target.value)}
                />
                <input
                  type="text"
                  class="form-control"
                  onChange={(e) => addNumber(e.target.value)}
                />
                <input
                  type="text"
                  class="form-control"
                  onChange={(e) => addNumber(e.target.value)}
                />
              </div>
              <div className="text-center mt-4">
                <ButtonM variant="text" tabIndex={-1} onClick={handleOtp}>
                  Send
                </ButtonM>
              </div>
              <div class="text-center mt-3">
                <span class="d-block mobile-text">Don't receive the code?</span>
                <span class="font-weight-bold text-danger cursor">Resend</span>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditProfile;
