/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// @mui packages
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Autocomplete,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
//icons
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import IconButton from "@mui/material/IconButton";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import swal from "sweetalert";
import Swal from "sweetalert2";
// constants
import {
  locationsData,
  // PropertyType,
  FurnishedStatusOption,
  PurposeOfPropertyList,
} from "../../../modules/helpers/constant";
//Api
import { addCommercials, updateCommercials } from "../../../Api/Commercial";
import FileLoader from "../../../Components/Common/FileLoader";
import { AmenitiesList, getLocationData, getPropertytypeByTypeData, getSubLocationById } from "../../../Api/AuthAPI";
import { deleteImagesById, deletecommImgById } from "../../../Api/RentpropertyAPI";
//Auth
import Auth from "../../../Config/helper/Auth";
import AdminSidebar from "../../../Components/Sidebar/AdminSidebar";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";


const initialCommProperty = {
  userId: Auth.getUserDetails() && Auth.getUserDetails()._id,
  propertyType: "",
  ComplexName: "",
  Location: "",
  LocationId: "",
  subLocation: "",
  subLocationId: "",
  SecurityDeposit: "",
  PropertyRent: "",
  carpetArea: null,
  SuperCarpetArea: null,
  MaintanceCharges: null,
  NosLifts: null,
  NosOfCabins: null,
  NosOfSeats: null,
  LockinPeriod: null,
  Amenities: [],
  furnishedStatus: "",
  selectFloor: null,
  totalFloor: null,
  exteriorView: [],
  CabinView: [],
  InteririorView: [],
  PantryView: [],
  leadCertified: [],
  Others: [],

  exteriorViewB: [],
  CabinViewB: [],
  InteririorViewB: [],
  PantryViewB: [],
  leadCertifiedB: [],
  OthersB: [],

  PostedBy: "",
  Availibilty: "",
  isPantryAvailable: null,
  isBoundaryWallMade: null,
  isCCTVinstalled: null,
  isFurnished: null,
  LeedStatus: null,
  descriptionAboutCommercialProperty: "",
  userEmail: Auth?.getUserDetails()?.email,
  fullName: Auth?.getUserDetails()?.fullName,
  purposeOfProperty: PurposeOfPropertyList[0].value,
  propertyCost: null,
  bookingAmount: null,
  heavyDeposite:"",
};

const initialFeatureState = {
  isPantryAvailable: null,
  isBoundaryWallMade: null,
  isCCTVinstalled: null,
  isFurnished: null,
  LeedStatus: null,
};

const NewCommercial = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [commProperty, setCommProperty] = useState({ ...initialCommProperty });
  const [commPropertyError, setCommPropertyError] = useState({
    ...initialCommProperty,
  });

  const [propertyOptions, setPropertyOptions] = useState({
    ...initialFeatureState,
  });

  const [commId, setCommId] = useState(0);
  const [fileLoading, setFileLoding] = useState(false);
  const [locationData, setLocationData] = useState([]);
  const [subLocationData, setSubLocationData] = useState([]);
  const [currentTotalPage, setCurrentTotalPage] = useState(1);
  const itemsPerPage = 10;
  const [currentSelectPage, setCurrentSelectPage] = useState(1);
  const [amenitiesOption, setAmenitiesOption] = useState([]);

  const [propertyTypeOption, setPropertyTypeOption] = useState([]);

  useEffect(() => {
    getpropertyType()
  }, [])

  const getpropertyType = () => {
    getPropertytypeByTypeData("Commercial")
      .then((res) => {
        if (res.status === 200) {
          let result = res.data.data;
          let list = result.map((data) => ({ label: data.AreaType, value: data._id }));
          setPropertyTypeOption(list);
          console.log(list, "list");

        }
      }).catch((err) => {
        console.log(err, "error in property get data");
      })
  }


  const propertyImages = [
    { name: "exteriorView", label: "Exterior View*" },
    { name: "InteririorView", label: "Interior View*" },
    { name: "CabinView", label: "Cabin View" },
    { name: "PantryView", label: "Pantry View" },
    { name: "Others", label: "Other Area" },
  ];

  const propertyOptionsMapping = {
    isPantryAvailable: "Pantry Available",
    isBoundaryWallMade: "Boundary Wall Made",
    isCCTVinstalled: "CCTV Installed",
    isFurnished: "Furnished",
    LeedStatus: "Leed Certified",
  };

  useEffect(() => {
    AmenitiesDataList();
  }, []);

  //getByIdData for update
  useEffect(() => {
    if (state && Object.keys(state).length !== 0) {
      setCommId(state._id);
      setCommProperty({
        ComplexName: state.ComplexName,
        Location: state.Location,
        LocationId: state.LocationId,
        subLocation: state.subLocation,
        subLocationId: state.subLocationId,
        carpetArea: state.carpetArea,
        SuperCarpetArea: state.SuperCarpetArea,
        MaintanceCharges: state.MaintanceCharges,
        SecurityDeposit: state.SecurityDeposit,
        PropertyRent: state.PropertyRent,
        Amenities: state.Amenities,
        propertyType: state.propertyType,
        Availibilty: state.Availibilty,
        NosLifts: state.NosLifts,
        NosOfCabins: state.NosOfCabins,
        NosOfSeats: state.NosOfSeats,
        PostedBy: state.PostedBy,
        isPantryAvailable: state.isPantryAvailable,
        isBoundaryWallMade: state.isBoundrywallMade,
        isGatedColony: state.isGatedColony,
        isCCTVinstalled: state.isCCTVinstalled,
        isFurnished: state.isFurnished,
        exteriorView: state.exteriorView,
        InteririorView: state.InteririorView,
        CabinView: state.CabinView,
        PantryView: state.PantryView,
        commonAreaImage: state.commonAreaImage,
        selectFloor: state.selectFloor,
        totalFloor: state.totalFloor,
        LockinPeriod: state.LockinPeriod,
        furnishedStatus: state.furnishedStatus,
        LeedStatus: state.LeedStatus,
        descriptionAboutCommercialProperty: state.descriptionAboutCommercialProperty,
        purposeOfProperty: state.purposeOfProperty,
        bookingAmount: state.bookingAmount,
        propertyCost: state.propertyCost,

      });
      setPropertyOptions({
        isBoundaryWallMade: state.isBoundrywallMade,
        isCCTVinstalled: state.isCCTVinstalled,
        isFurnished: state.isFurnished,
        isPantryAvailable: state.isPantryAvailable,
        LeedStatus: state.LeedStatus,
      });
      getSubLocation(state.LocationId);
    }
  }, []);

  // useEffect
  useEffect(() => {
    getLocationData()
      .then((res) => {
        if (res.status === 200) {
          let locationData = res.data.locations;
          setLocationData(locationData)
        } else {
          console.log("data not found");
        }
      }).catch((err) => {
        console.log(err, "error in location get call find property")
      })
  }, [])

  // AmenitiesList
  const AmenitiesDataList = () => {
    let propertyType = "Commercial";
    AmenitiesList(propertyType)
      .then((res) => {
        if (res.status === 200) {
          let details = res.data.AmenitiesData;
          const formattedData = details.map((amenity) => ({
            label: amenity.AmenitiesName,
            value: amenity._id,
          }));

          setAmenitiesOption(formattedData);
        } else {
          setAmenitiesOption([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //subLocation 
  const getSubLocation = (locationId) => {
    getSubLocationById(locationId)
      .then((res) => {
        if (res.status === 200) {
          setSubLocationData(res.data.locationdata);
        } else {
          swal("warning", "subLocation not found", 'warning');
        }
      }).catch((err) => {
        console.log(err, "error in subLocation commercial")
      })
  }

  // handleChange with forms
  // const onChangeHandler = (name, value) => {
  //   setCommProperty({ ...commProperty, [name]: value });
  // };

  const onChangeHandler = (name, value) => {
    if (name === "purposeOfProperty") {
      if (value === "Rent") {
        setCommProperty({
          ...commProperty, propertyCost: "", bookingAmount: "", purposeOfProperty: value
        });
      } else if (value === "Buy") {
        setCommProperty({
          ...commProperty, SecurityDeposit: "", PropertyRent: "", purposeOfProperty: value
        });
      } else {
        setCommProperty({
          ...commProperty, purposeOfProperty: value
        });
      }
    } else {
      setCommProperty({ ...commProperty, [name]: value });
    }
  };


  const handleLocationChange = (name, value) => {
    if (name === "Location") {
      setCommProperty({ ...commProperty, Location: value.label, LocationId: value.value, subLocation: "" });
      getSubLocation(value.value)
    } else if (name === "subLocation") {
      setCommProperty({ ...commProperty, subLocation: value.label, subLocationId: value.value });
    } else if (name === "area") {
      setCommProperty({ ...commProperty, area: value });
    }
  };

  const handlePageChange = (newPage, name) => {
    if (name === "total") {
      setCurrentTotalPage(newPage);
    } else if (name === "select") {
      setCurrentSelectPage(newPage);
    }
  };

  const handlePropertyFeatureChange = (property, value) => {
    setPropertyOptions({ ...propertyOptions, [property]: value });
    setCommProperty({ ...commProperty, [property]: value });
  };

  // floor render component
  const renderSelectedBricks = () => {
    const floors = [];
    if (currentSelectPage === 1) {
      floors.push("Ground", "Lower Basement", "Upper Basement");
    } else {
      const start = (currentSelectPage - 2) * itemsPerPage + 1;
      const end = Math.min(start + itemsPerPage - 1, 50);

      for (let i = start; i <= end; i++) {
        floors.push(i);
      }
    }

    const bricks = floors.map((floor, index) => (
      <div
        key={index}
        className={`brick ${commProperty.selectFloor === floor ? "selected2" : ""
          }`}
        onClick={() => {
          setCommPropertyError({ ...commPropertyError, selectFloor: "" });
          onChangeHandler("selectFloor", floor);
        }}
      >
        {floor}
      </div>
    ));

    return bricks;
  };

  const renderTotalBricks = () => {
    const start = (currentTotalPage - 1) * itemsPerPage + 1;
    const end = Math.min(start + itemsPerPage - 1, 50);

    const bricks = [];
    for (let i = start; i <= end; i++) {
      bricks.push(
        <div
          key={i}
          className={`brick ${commProperty.totalFloor === i ? "selected2" : ""
            }`}
          onClick={() => {
            setCommPropertyError({ ...commPropertyError, totalFloor: null });
            onChangeHandler("totalFloor", i);
          }}
        >
          {i}
        </div>
      );
    }

    return bricks;
  };

  const handleImageChange = async (event, name) => {
    setFileLoding(true);
    const imageFiles = event.target.files;
    const existingImages = commProperty[name] || [];
    const imageArray = [...existingImages];
    var imageArrayB = commProperty[`${name}B`] || [];

    for (let i = 0; i < imageFiles.length; i++) {
      // const imageFile = await IpfsImgUpload(imageFiles[i]);
      var imageFile = imageFiles[i];
      imageArray.push({
        // name: imageFiles[i]?.name,
        // value: imageFile 
        imgname: imageFile.name,
        name: URL.createObjectURL(imageFile),
      });
      imageArrayB.push(imageFile);
    }

    setCommProperty({ ...commProperty, [name]: imageArray, [`${name}B`]: imageArrayB });
    setFileLoding(false);
  };
  // remove images
  const removeImage = (img, property, index) => {
    if (img._id) {
      const data = {
        imageId: img._id
      }
      deletecommImgById(commId, data)
        .then((res) => {
          if (res.status === 200) {
            const updatedImages = [...commProperty[property]];
            updatedImages.splice(index, 1);
            setCommProperty({ ...commProperty, [property]: updatedImages });
          } else {
            console.log("error in remove img")
          }
        }).catch((err) => {
          console.log(err, "error in newrent delete");
        })
    } else {
      const updatedImages = [...commProperty[property]];
      updatedImages.splice(index, 1);
      setCommProperty({ ...commProperty, [property]: updatedImages });
    }
  };

  // validation on forms
  const validates = () => {
    let commPropertyError = {};
    let isValid = true;
    if (commProperty.Location === "") {
      Swal.fire({
        icon: "warning",
        title: "Incomplete Form",
        text: "Please fill in all required fields before submitting.",
        showConfirmButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      });
    }
    if (commProperty.Location === "") {
      isValid = false;
      commPropertyError["Location"] = "please Select Location.";
    }

    if (commProperty.subLocation === "") {
      isValid = false;
      commPropertyError["subLocation"] = "please Select SubLocation.";
    }
    if (commProperty.propertyType === "") {
      isValid = false;
      commPropertyError["propertyType"] = "please Select Property Type.";
    }

    if (commProperty.Amenities.length < 1) {
      isValid = false;
      commPropertyError["Amenities"] = "please Select Amenities.";
    }

    if (commProperty.purposeOfProperty.length < 1) {
      isValid = false;
      commPropertyError["purposeOfProperty"] = "Please Select purpose of property.";
    }

    if (commProperty.PostedBy === "") {
      isValid = false;
      commPropertyError["PostedBy"] = "Please select posted by.";
    }

    if (commProperty.carpetArea === null) {
      isValid = false;
      commPropertyError["carpetArea"] = "Please enter carpet Area.";
    }
    if (commProperty.SuperCarpetArea === null) {
      isValid = false;
      commPropertyError["SuperCarpetArea"] = "Please enter Super Area.";
    }

    if (commProperty.purposeOfProperty === "Buy") {
      if (commProperty.propertyCost === null) {
        isValid = false;
        commPropertyError["propertyCost"] = "Please enter property Cost.";
      }
      if (commProperty.bookingAmount === null) {
        isValid = false;
        commPropertyError["bookingAmount"] = "Please enter Booking amount.";
      }
    }
    if (commProperty.purposeOfProperty == "Rent") {
      if (commProperty.SecurityDeposit === "") {
        isValid = false;
        commPropertyError["SecurityDeposit"] = "Please enter Security Deposit.";
      }
      if (commProperty.PropertyRent === "") {
        isValid = false;
        commPropertyError["PropertyRent"] = "Please enter Rent.";
      }
    }

    if (commProperty.MaintanceCharges === null) {
      isValid = false;
      commPropertyError["MaintanceCharges"] =
        "Please enter Maintenance Charges.";
    }
    if (commProperty.LockinPeriod === null) {
      isValid = false;
      commPropertyError["LockinPeriod"] = "Please enter Lock in Period.";
    }
    if (commProperty.exteriorView.length === 0) {
      isValid = false;
      commPropertyError["exteriorView"] = "Please add Exterior View.";
    }
    if (commProperty.InteririorView.length === 0) {
      isValid = false;
      commPropertyError["InteririorView"] = "Please add living Room Image.";
    }

    if (commProperty.propertyType === "Office Space") {
      if (commProperty.ComplexName === "") {
        isValid = false;
        commPropertyError["ComplexName"] = "please Enter Complex Name.";
      }
      if (commProperty.NosOfCabins === null) {
        isValid = false;
        commPropertyError["NosOfCabins"] = "Please select No of Cabins.";
      }
      if (commProperty.NosOfSeats === null) {
        isValid = false;
        commPropertyError["NosOfSeats"] = "Please select No of Seats.";
      }

      if (commProperty.selectFloor === null) {
        isValid = false;
        commPropertyError["selectFloor"] = "Please select Floor.";
      }
      if (commProperty.furnishedStatus === "") {
        isValid = false;
        commPropertyError["furnishedStatus"] = "Please enter Furnished Status.";
      }
    }
    if (commProperty.propertyType === "Shop Showroom") {
      if (commProperty.ComplexName === "") {
        isValid = false;
        commPropertyError["ComplexName"] = "please Enter Complex Name.";
      }
      if (commProperty.totalFloor === null) {
        isValid = false;
        commPropertyError["totalFloor"] = "Please select total Floor.";
      }
      if (commProperty.selectFloor === null) {
        isValid = false;
        commPropertyError["selectFloor"] = "Please select Floor.";
      }
      if (commProperty.furnishedStatus === "") {
        isValid = false;
        commPropertyError["furnishedStatus"] = "Please enter Furnished Status.";
      }
    }
    if (commProperty.Availibilty === "") {
      isValid = false;
      commPropertyError["Availibilty"] = "Please enter Availibilty.";
    }

    // if (commProperty.NosLifts === null) {
    //   isValid = false;
    //   commPropertyError["NosLifts"] = "Please select No of Lifts";
    // }
    if (commProperty.isPantryAvailable === null) {
      isValid = false;
      commPropertyError["isPantryAvailable"] = "Please choose an option.";
    }

    if (commProperty.isBoundaryWallMade === null) {
      isValid = false;
      commPropertyError["isBoundaryWallMade"] = "Please choose an option.";
    }

    if (commProperty.isCCTVinstalled === null) {
      isValid = false;
      commPropertyError["isCCTVinstalled"] = "Please choose an option.";
    }
    if (commProperty.isFurnished === null) {
      isValid = false;
      commPropertyError["isFurnished"] = "Please choose an option.";
    }

    setCommPropertyError(commPropertyError);
    return isValid;
  };
  // submit commercial  property
  const submitCommProperty = (e) => {
    e.preventDefault();
    if (validates()) {
      const formData = new FormData();
      commProperty?.exteriorViewB && commProperty?.exteriorViewB?.forEach((file, index) => {
        formData.append(`exteriorView`, file);
      })
      commProperty?.InteririorViewB && commProperty?.InteririorViewB.forEach((file, index) => {
        formData.append(`InteririorView`, file);
      })
      commProperty?.CabinViewB && commProperty?.CabinViewB?.forEach((file, index) => {
        formData.append(`CabinView`, file);
      })
      commProperty?.PantryViewB && commProperty?.PantryViewB?.forEach((file, index) => {
        formData.append(`PantryView`, file);
      })
      commProperty?.OthersB && commProperty?.OthersB?.forEach((file, index) => {
        formData.append(`Others`, file);
      })
      debugger
      let dataSend = commProperty;
      formData.append("details", JSON.stringify(dataSend));
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      addCommercials(formData, config)
        .then((res) => {
          if (res.data.status === 1) {
            Swal.fire({
              icon: "success",
              title: "Property Added Successfully",
              text: "Your property has been Added and all changes have been saved.",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK",
            })
            refreshData();
            navigate("/commDetails");
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops! Something went wrong.",
              text: "We encountered an unexpected error. Please try again later.",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              // cancelButtonColor: '#d33',
              confirmButtonText: "ok",
            });
          }
        })
        .catch((err) => {
          console.log(err, "err in add commercial property.");
        });
    }
  };

  const UpdateCommProperty = (e) => {
    e.preventDefault();
    if (validates()) {
      const formData = new FormData();
      commProperty?.exteriorViewB && commProperty?.exteriorViewB?.forEach((file, index) => {
        formData.append(`exteriorView`, file);
      })
      commProperty?.InteririorViewB && commProperty?.InteririorViewB?.forEach((file, index) => {
        formData.append(`InteririorView`, file);
      })
      commProperty?.CabinViewB && commProperty?.CabinViewB?.forEach((file, index) => {
        formData.append(`CabinView`, file);
      })
      commProperty?.PantryViewB && commProperty?.PantryViewB?.forEach((file, index) => {
        formData.append(`PantryView`, file);
      })
      commProperty?.OthersB && commProperty?.OthersB?.forEach((file, index) => {
        formData.append(`Others`, file);
      })
      let dataSend = commProperty;
      formData.append("details", JSON.stringify(dataSend));
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      updateCommercials(formData, commId, config)
        .then((res) => {
          if (res.data.status === 1) {
            Swal.fire({
              icon: "success",
              title: "Property Updated Successfully",
              text: "Your Property has been updated and all changes have been saved.",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK",
            })
            refreshData();
            navigate("/commDetails");
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops! Something went wrong.",
              text: "We encountered an unexpected error. Please try again later.",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              // cancelButtonColor: '#d33',
              confirmButtonText: "ok",
            });
          }
        })
        .catch((err) => {
          console.log(err, "err in update Commercial property.");
        });
    }
  };

  // clear data in forms
  const refreshData = () => {
    setCommProperty({ ...initialCommProperty });
    setPropertyOptions({ ...initialFeatureState });
    setCommPropertyError({ ...initialCommProperty });
  };

  return (
    <div>
      {fileLoading && <FileLoader />}
      <AdminSidebar />
      <div className="container topNav-m">
        <div className="row">
          {/* <div className="col-md-3 vertical  p-card-content">
             <div class="steps clearfix">
              <ul role="tablist">
                <li
                  role="tab"
                  class="first current"
                  aria-disabled="false"
                  aria-selected="true"
                >
                  <a
                    id="steps-uid-0-t-0"
                    href="#steps-uid-0-h-0"
                    aria-controls="steps-uid-0-p-0"
                  >
                    <span class="current-info audible"> </span>
                    <div class="title">
                      <span class="step-number">1</span>
                      <span class="step-text">Property Details</span>
                    </div>
                  </a>
                </li>
                <li role="tab" class="disabled" aria-disabled="true">
                  <a
                    id="steps-uid-0-t-1"
                    href="#steps-uid-0-h-1"
                    aria-controls="steps-uid-0-p-1"
                  >
                    <div class="title">
                      <span class="step-number">2</span>
                      <span class="step-text">Property Features</span>
                    </div>
                  </a>
                </li>
                <li role="tab" class="disabled" aria-disabled="true">
                  <a
                    id="steps-uid-0-t-2"
                    href="#steps-uid-0-h-2"
                    aria-controls="steps-uid-0-p-2"
                  >
                    <div class="title">
                      <span class="step-number">3</span>
                      <span class="step-text">Property Images</span>
                    </div>
                  </a>
                </li>
              </ul>
            </div> 
          </div> */}
          <div className="col-md-12">
            <div className="container mt-2">
              <form className="add-update-form">
                <div className="user__details">
                  <div className="row p-2">
                    <h2 className="">
                      {" "}
                      {commId === 0 ? "Add" : "Edit"} Commercial Property
                    </h2>
                    <div className="content-title pt-3 d-flex justify-content-between align-items-center">
                      <p className="desc">
                        Please enter your infomation and proceed to next step
                      </p>
                      <Button className="add-btn" onClick={() => { navigate("/commDetails") }}><RemoveRedEyeOutlinedIcon className="me-2" />View Properties</Button>
                    </div>

                    <div className="col-md-6">
                      <div className="input__box p-2">
                        <span className="details">Location*</span>

                        <FormControl variant="outlined" fullWidth size="small">
                          {/* <InputLabel>select Location</InputLabel> */}

                          {/* <Select
                            // label="Location"
                            value={commProperty.LocationId}
                            required
                            onChange={(e) => {
                              const selectedOption = locationData.find(option => option._id === e.target.value);
                              handleLocationChange("Location", { value: selectedOption._id, label: selectedOption.locationName });
                              setCommPropertyError({
                                ...commPropertyError,
                                Location: "",
                              });
                            }}
                          >
                            {locationData.map((option) => (
                              <MenuItem key={option._id} value={option._id}>
                                {option.locationName}
                              </MenuItem>
                            ))}
                          </Select> */}

                          <Autocomplete
                            size="small"
                            value={locationData.find(option => option._id === commProperty.LocationId) || null}
                            onChange={(event, newValue) => {
                              if (newValue) {
                                handleLocationChange("Location", { value: newValue._id, label: newValue.locationName });
                                setCommPropertyError({
                                  ...commPropertyError,
                                  Location: "",
                                });
                              }
                            }}
                            options={locationData}
                            getOptionLabel={(option) => option.locationName}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                // label="Location"
                                variant="outlined"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            )}
                            style={{
                              zIndex: 9999,
                              position: 'relative',
                              fontSize: '14px',
                              padding: '4px',
                              maxHeight: 100,

                            }}
                          />

                          <div style={{ color: "red", fontSize: 13 }}>
                            {commPropertyError.Location}
                          </div>
                        </FormControl>
                      </div>
                    </div>

                    <div className="col-md-6">
                      {/* <div className="input__box p-2">
                        <span className="details"> Sub Location*</span>

                        <FormControl variant="outlined" fullWidth size="small">
                          <Select
                            
                            value={commProperty.subLocationId}
                            onChange={(e) => {
                              const selectedOption = subLocationData.find(option => option._id === e.target.value);
                              handleLocationChange(
                                "subLocation",
                                { value: selectedOption._id, label: selectedOption.subLocationName }
                              );
                              setCommPropertyError({
                                ...commPropertyError,
                                subLocation: "",
                              });
                            }}
                            required
                          >
                            {subLocationData.map((option) => (
                              <MenuItem key={option._id} value={option._id}>
                                {option.subLocationName}
                              </MenuItem>
                            ))}
                          </Select>
                          <div style={{ color: "red", fontSize: 13 }}>
                            {commPropertyError.subLocation}
                          </div>
                        </FormControl>
                      </div> */}
                      <div className="input__box p-2">
                        <span className="details">Sub Location*</span>
                        <Autocomplete
                          size="small"
                          value={subLocationData.find(option => option._id === commProperty.subLocationId) || null}
                          onChange={(event, newValue) => {
                            if (newValue) {
                              handleLocationChange("subLocation", { value: newValue._id, label: newValue.subLocationName });
                              setCommPropertyError({
                                ...commPropertyError,
                                subLocation: "",
                              });
                            }
                          }}
                          options={subLocationData}
                          getOptionLabel={(option) => option.subLocationName}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                            // placeholder="Select SubLocation"
                            />
                          )}
                          style={{ zIndex: 9999 }}
                        />
                        <div style={{ color: "red", fontSize: 13 }}>
                          {commPropertyError.subLocation}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      {commProperty.propertyType ===
                        "Warehouse /Godown" ? null : (
                        <div className="input__box p-2">
                          <span className="details">
                            Commercial Complex Name
                          </span>

                          <FormControl
                            variant="outlined"
                            fullWidth
                            size="small"
                          >
                            {/* <InputLabel>Property Price</InputLabel> */}

                            <TextField
                              // label="Commercial Complex Name"
                              autoComplete="off"
                              type="text"
                              value={commProperty.ComplexName}
                              onChange={(e) => {
                                onChangeHandler("ComplexName", e.target.value);
                                setCommPropertyError({
                                  ...commPropertyError,
                                  ComplexName: "",
                                });
                              }}
                              required
                            //   renderValue={(selected) => selected.join(', ')} // Display selected items
                            ></TextField>
                            <div style={{ color: "red", fontSize: 13 }}>
                              {commPropertyError.ComplexName}
                            </div>
                          </FormControl>
                        </div>
                      )}
                    </div>





                    <div className="col-md-6">
                      <div className="input__box p-2">
                        <span className="details">Super Area*</span>
                        <FormControl variant="outlined" fullWidth size="small">
                          <TextField
                            // label="Super Area"
                            type="Number"
                            size="small"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  Sq.Ft
                                </InputAdornment>
                              ),
                            }}
                            value={commProperty.SuperCarpetArea}
                            onChange={(e) => {
                              setCommPropertyError({
                                ...commPropertyError,
                                SuperCarpetArea: null,
                              });
                              onChangeHandler(
                                "SuperCarpetArea",
                                e.target.value
                              );
                            }}
                            required
                          ></TextField>
                          <div style={{ color: "red", fontSize: 13 }}>
                            {commPropertyError.SuperCarpetArea}
                          </div>
                        </FormControl>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="input__box p-2">
                        <span className="details">Carpet Area*</span>
                        <FormControl variant="outlined" fullWidth size="small">
                          <TextField
                            // label="Carpet Area"
                            type="Number"
                            size="small"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  Sq.Ft
                                </InputAdornment>
                              ),
                            }}
                            value={commProperty.carpetArea}
                            onChange={(e) => {
                              setCommPropertyError({
                                ...commPropertyError,
                                carpetArea: null,
                              });
                              onChangeHandler("carpetArea", e.target.value);
                            }}
                            required
                          ></TextField>
                          <div style={{ color: "red", fontSize: 13 }}>
                            {commPropertyError.carpetArea}
                          </div>
                        </FormControl>
                      </div>
                    </div>



                    <div className="col-md-6">
                      {/* <div className="input__box p-2">
                        <span className="details"> Property Type*</span>
                        <FormControl variant="outlined" fullWidth size="small">
                          <Select
                            value={commProperty.propertyType}
                            required
                            placeholder="Property Type"
                            onChange={(e) => {
                              onChangeHandler("propertyType", e.target.value);
                              setCommPropertyError({
                                ...commPropertyError,
                                propertyType: "",
                              });
                            }}
                          >
                            {propertyTypeOption.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                          <div style={{ color: "red", fontSize: 13 }}>
                            {commPropertyError.propertyType}
                          </div>
                        </FormControl>
                      </div> */}
                      <div className="input__box p-2">
                        <span className="details"> Property Type*</span>
                        <FormControl variant="outlined" fullWidth size="small">
                          <Autocomplete
                            size="small"
                            value={propertyTypeOption.find(option => option.label === commProperty.propertyType) || null}
                            onChange={(event, newValue) => {
                              if (newValue) {
                                onChangeHandler("propertyType", newValue.label);
                                setCommPropertyError({
                                  ...commPropertyError,
                                  propertyType: "",
                                });
                              }
                            }}
                            options={propertyTypeOption}
                            getOptionLabel={(option) => option.label}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                required
                              />
                            )}
                            style={{ zIndex: 9999 }}
                          />
                          <div style={{ color: "red", fontSize: 13 }}>
                            {commPropertyError.propertyType}
                          </div>
                        </FormControl>
                      </div>

                    </div>


                    <div className="col-md-6">
                      <div className="input__box p-2">
                        <span className="details">Purpose Of Property</span>
                        <FormControl variant="outlined" fullWidth size="small">
                          <Select
                            value={commProperty.purposeOfProperty}
                            onChange={(e) => {
                              onChangeHandler("purposeOfProperty", e.target.value);
                              setCommPropertyError({
                                ...commPropertyError,
                                purposeOfProperty: "",
                              })
                            }}
                          >
                            {
                              PurposeOfPropertyList.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))
                            }
                          </Select>
                          {/* <div style={{ color: "red", fontSize: 13 }}>
                          {commPropertyError.purposeOfProperty}
                        </div> */}
                        </FormControl>
                      </div>
                    </div>
                    {commProperty.purposeOfProperty === "Rent" || commProperty.purposeOfProperty === "RentHeavyDeposite" ?
                      <>
                        <div className="col-md-3">
                          <div className="input__box p-2">
                            <span className="details">Security Deposit*</span>
                            <FormControl variant="outlined" fullWidth size="small">
                              {/* <TextField
                                // label="Security Deposit"
                                type="number"
                                size="small"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      ₹
                                    </InputAdornment>
                                  ),
                                }}
                                value={commProperty.SecurityDeposit}
                                onChange={(e) => {
                                  setCommPropertyError({
                                    ...commPropertyError,
                                    SecurityDeposit: null,
                                  });
                                  onChangeHandler(
                                    "SecurityDeposit",
                                    e.target.value
                                  );
                                }}
                                required
                              ></TextField> */}
                              <TextField
                                type="text"
                                size="small"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      ₹
                                    </InputAdornment>
                                  ),
                                  inputMode: 'numeric',
                                  pattern: "[0-9]*"
                                }}
                                value={commProperty.SecurityDeposit}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  if (/^\d*$/.test(value)) {
                                    if (value === "" || parseInt(value) <= 10000000) {
                                      setCommPropertyError({
                                        ...commPropertyError,
                                        SecurityDeposit: null,
                                      });
                                      onChangeHandler("SecurityDeposit", value);
                                    } else {
                                      setCommPropertyError({
                                        ...commPropertyError,
                                        SecurityDeposit: "Security Deposit cannot exceed ₹10,000,000",
                                      });
                                    }
                                  }
                                }}
                                required
                              />
                              <div style={{ color: "red", fontSize: 13 }}>
                                {commPropertyError.SecurityDeposit}
                              </div>
                            </FormControl>
                          </div>
                        </div>

                        <div className="col-md-3">
                          <div className="input__box p-2">
                            <span className="details">Property Rent*</span>

                            <FormControl variant="outlined" fullWidth size="small">
                              {/* <InputLabel>Property Rent</InputLabel> */}

                              {/* <TextField
                                // label="Property Rent"
                                type="number"
                                size="small"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      ₹
                                    </InputAdornment>
                                  ),
                                }}
                                value={commProperty.PropertyRent}
                                onChange={(e) => {
                                  setCommPropertyError({
                                    ...commPropertyError,
                                    PropertyRent: null,
                                  });
                                  onChangeHandler("PropertyRent", e.target.value);
                                }}
                                required
                              //   renderValue={(selected) => selected.join(', ')} // Display selected items
                              ></TextField> */}
                              <TextField
                                type="text"
                                size="small"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      ₹
                                    </InputAdornment>
                                  ),
                                  inputMode: 'numeric',
                                  pattern: "[0-9]*"
                                }}
                                value={commProperty.PropertyRent}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  if (/^\d*$/.test(value)) {
                                    if (value === "" || parseInt(value) <= 1000000) {
                                      setCommPropertyError({
                                        ...commPropertyError,
                                        PropertyRent: null,
                                      });
                                      onChangeHandler("PropertyRent", value);
                                    } else {
                                      setCommPropertyError({
                                        ...commPropertyError,
                                        PropertyRent: "Property Rent cannot exceed ₹10,00,000",
                                      });
                                    }
                                  }
                                }}
                                required
                              />
                              <div style={{ color: "red", fontSize: 13 }}>
                                {commPropertyError.PropertyRent}
                              </div>
                            </FormControl>
                          </div>
                        </div>
                      </>
                      :
                      <>
                        <div className="col-md-3">
                          <div className="input__box p-2">
                            <span className="details">Property Cost*</span>

                            <FormControl variant="outlined" fullWidth size="small">
                              {/* <InputLabel>Security Deposit</InputLabel> */}

                              {/* <TextField
                                //  label="Security Deposit"
                                type="Number"
                                size="small"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      ₹
                                    </InputAdornment>
                                  ),
                                }}
                                value={commProperty.propertyCost}
                                onChange={(e) => {
                                  setCommPropertyError({
                                    ...commPropertyError,
                                    propertyCost: null,
                                  });
                                  onChangeHandler("propertyCost", e.target.value);
                                }}
                                required
                              //   renderValue={(selected) => selected.join(', ')} // Display selected items
                              ></TextField> */}
                              <TextField
                                type="text"
                                size="small"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      ₹
                                    </InputAdornment>
                                  ),
                                  inputMode: 'numeric',
                                  pattern: "[0-9]*"
                                }}
                                value={commProperty.propertyCost}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  if (/^\d*$/.test(value)) {
                                    if (value === "" || parseInt(value) <= 100000000) {
                                      setCommPropertyError({
                                        ...commPropertyError,
                                        propertyCost: null,
                                      });
                                      onChangeHandler("propertyCost", value);
                                    } else {
                                      setCommPropertyError({
                                        ...commPropertyError,
                                        propertyCost: "Property Cost cannot exceed ₹100,000,000",
                                      });
                                    }
                                  }
                                }}
                                required
                              />
                              <div style={{ color: "red", fontSize: 13 }}>
                                {commPropertyError.propertyCost}
                              </div>
                            </FormControl>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="input__box p-2">
                            <span className="details">Booking Amount*</span>

                            <FormControl variant="outlined" fullWidth size="small">
                              {/* <TextField
                                type="Number"
                                size="small"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      ₹
                                    </InputAdornment>
                                  ),
                                }}
                                value={commProperty.bookingAmount}
                                onChange={(e) => {
                                  setCommPropertyError({
                                    ...commPropertyError,
                                    bookingAmount: null,
                                  });
                                  onChangeHandler("bookingAmount", e.target.value);
                                }}
                                required
                              ></TextField> */}
                              <TextField
                                type="text"
                                size="small"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      ₹
                                    </InputAdornment>
                                  ),
                                  inputMode: 'numeric',
                                  pattern: "[0-9]*"
                                }}
                                value={commProperty.bookingAmount}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  if (/^\d*$/.test(value)) {
                                    if (value === "" || parseInt(value) <= 5000000) {
                                      setCommPropertyError({
                                        ...commPropertyError,
                                        bookingAmount: null,
                                      });
                                      onChangeHandler("bookingAmount", value);
                                    } else {
                                      setCommPropertyError({
                                        ...commPropertyError,
                                        bookingAmount: "Booking Amount cannot exceed ₹50,00,000",
                                      });
                                    }
                                  }
                                }}
                                required
                              />
                              <div style={{ color: "red", fontSize: 13 }}>
                                {commPropertyError.bookingAmount}
                              </div>
                            </FormControl>
                          </div>
                        </div>
                      </>
                    }

                    <div className="col-md-6">
                      <div className="input__box p-2">
                        <span className="details">Maintenance Charges*</span>

                        <FormControl variant="outlined" fullWidth size="small">
                          {/* <InputLabel>Property Rent</InputLabel> */}

                          <TextField
                            // label="Maintenance Charges"
                            type="Number"
                            size="small"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  ₹
                                </InputAdornment>
                              ),
                            }}
                            value={commProperty.MaintanceCharges}
                            onChange={(e) => {
                              setCommPropertyError({
                                ...commPropertyError,
                                MaintanceCharges: null,
                              });
                              onChangeHandler(
                                "MaintanceCharges",
                                e.target.value
                              );
                            }}
                            required
                          //   renderValue={(selected) => selected.join(', ')} // Display selected items
                          ></TextField>
                          <div style={{ color: "red", fontSize: 13 }}>
                            {commPropertyError.MaintanceCharges}
                          </div>
                        </FormControl>
                      </div>
                    </div>

                    

                    <div className="col-md-6">
                      <div className="input__box p-2">
                        <span className="details">Lock in Period*</span>

                        <FormControl variant="outlined" fullWidth size="small">
                          {/* <InputLabel>Property Rent</InputLabel> */}

                          <TextField
                            // label="Lock in Period"
                            type="Number"
                            value={commProperty.LockinPeriod}
                            onChange={(e) => {
                              setCommPropertyError({
                                ...commPropertyError,
                                LockinPeriod: null,
                              });
                              onChangeHandler("LockinPeriod", e.target.value);
                            }}
                            required
                          //   renderValue={(selected) => selected.join(', ')} // Display selected items
                          ></TextField>
                          <div style={{ color: "red", fontSize: 13 }}>
                            {commPropertyError.LockinPeriod}
                          </div>
                        </FormControl>
                      </div>
                    </div>

                    <div className="col-md-6">
                      {/* <div className="input__box p-2">
                        <span className="details">Amenities*</span>
                        <FormControl variant="outlined" fullWidth size="small">
                          <Select
                            multiple
                            value={commProperty.Amenities}
                            onChange={(e) => {
                              onChangeHandler("Amenities", e.target.value);
                              setCommPropertyError({
                                ...commPropertyError,
                                Amenities: "",
                              });
                            }}
                            required
                          // renderValue={(selected) => selected.join(", ")} // Display selected items
                          >
                            {amenitiesOption.map((option) => (
                              <MenuItem key={option.value} value={option.label}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                          <div style={{ color: "red", fontSize: 13 }}>
                            {commPropertyError.Amenities}
                          </div>
                        </FormControl>
                      </div> */}
                      <div className="input__box p-2">
                        <span className="details">Amenities*</span>
                        <FormControl variant="outlined" fullWidth size="small">
                          <Autocomplete
                            multiple
                            size="small"
                            value={amenitiesOption.filter(option => commProperty.Amenities.includes(option.label))}
                            onChange={(event, newValue) => {
                              onChangeHandler("Amenities", newValue.map(item => item.label));
                              setCommPropertyError({
                                ...commPropertyError,
                                Amenities: "",
                              });
                            }}
                            options={amenitiesOption}
                            getOptionLabel={(option) => option.label}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                // placeholder="Select Amenities"
                                required
                              />
                            )}
                            style={{ zIndex: 9999 }}
                          />
                          <div style={{ color: "red", fontSize: 13 }}>
                            {commPropertyError.Amenities}
                          </div>
                        </FormControl>
                      </div>

                    </div>

                    {commProperty.purposeOfProperty === "Buy" ? null :
                      <div className="col-md-3">
                        <div className="input__box p-2">
                          <span className="details">Heavy Deposite</span>

                          <FormControl component="fieldset">
                            <RadioGroup
                              value={commProperty.heavyDeposite}
                              onChange={(e) => {
                                onChangeHandler("heavyDeposite", e.target.value);
                              }}
                              row
                            >
                              <FormControlLabel
                                value="yes"
                                control={<Radio size="small" />}
                                label="Yes"
                              />
                              <FormControlLabel
                                value="no"
                                control={<Radio size="small" />}
                                label="No"
                              />
                            </RadioGroup>
                          </FormControl>
                        </div>
                      </div>
                    }

                    <div className="col-md-3">
                      {commProperty.propertyType ===
                        "Warehouse /Godown" ? null : (
                        <div className="input__box p-2">
                          <span className="details">Furnished Status</span>

                          <FormControl
                            variant="outlined"
                            fullWidth
                            size="small"
                          >
                            {/* <InputLabel>Furnished Status</InputLabel> */}

                            <Select
                              // label="Furnished Status"
                              value={commProperty.furnishedStatus}
                              onChange={(e) => {
                                onChangeHandler(
                                  "furnishedStatus",
                                  e.target.value
                                );
                                setCommPropertyError({
                                  ...commPropertyError,
                                  furnishedStatus: "",
                                });
                              }}
                              required
                            >
                              {FurnishedStatusOption.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                            <div style={{ color: "red", fontSize: 13 }}>
                              {commPropertyError.furnishedStatus}
                            </div>
                          </FormControl>
                        </div>
                      )}
                    </div>

                    

                    {commProperty.propertyType ===
                      "Warehouse /Godown" ? null : (
                      // <p className="desc"></p>
                      <p></p>
                    )}
                    {commProperty.propertyType === "Office Space" ? (
                      <>
                        <div className="col-md-6">
                          <div className="input__box p-2">
                            <span className="details">No. of Cabins</span>

                            <FormControl
                              variant="outlined"
                              fullWidth
                              size="small"
                            >
                              {/* <InputLabel>Security Deposit</InputLabel> */}

                              <TextField
                                // label="No. of Cabins"
                                type="number"
                                size="small"
                                value={commProperty.NosOfCabins}
                                onChange={(e) => {
                                  onChangeHandler(
                                    "NosOfCabins",
                                    e.target.value
                                  );
                                  setCommPropertyError({
                                    ...commPropertyError,
                                    NosOfCabins: null,
                                  });
                                }}
                                required
                              ></TextField>
                              <div style={{ color: "red", fontSize: 13 }}>
                                {commPropertyError.NosOfCabins}
                              </div>
                            </FormControl>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="input__box p-2">
                            <span className="details">No. of Seats</span>

                            <FormControl
                              variant="outlined"
                              fullWidth
                              size="small"
                            >
                              {/* <InputLabel>Security Deposit</InputLabel> */}

                              <TextField
                                // label="No. of Seats"
                                type="number"
                                size="small"
                                value={commProperty.NosOfSeats}
                                onChange={(e) => {
                                  onChangeHandler("NosOfSeats", e.target.value);
                                  setCommPropertyError({
                                    ...commPropertyError,
                                    NosOfSeats: null,
                                  });
                                }}
                                required
                              ></TextField>
                              <div style={{ color: "red", fontSize: 13 }}>
                                {commPropertyError.NosOfSeats}
                              </div>
                            </FormControl>
                          </div>
                        </div>
                      </>
                    ) : null}

                    <div className="col-md-12">
                      {commProperty.propertyType === "Shop Showroom" ? (
                        <div className="input__box p-2">
                          <span className="details">Total Floor</span>
                          <div className="d-flex">
                            <IconButton
                              disabled={currentTotalPage === 1}
                              onClick={() =>
                                handlePageChange(currentTotalPage - 1, "total")
                              }
                            >
                              <ArrowBackIosNewIcon />
                            </IconButton>
                            <div className="wall-selector">
                              {renderTotalBricks()}
                            </div>
                            <IconButton
                              disabled={currentTotalPage * itemsPerPage >= 50}
                              onClick={() =>
                                handlePageChange(currentTotalPage + 1, "total")
                              }
                            >
                              <ArrowForwardIosIcon />
                            </IconButton>
                          </div>{" "}
                          <div style={{ color: "red", fontSize: 13 }}>
                            {commPropertyError.totalFloor}
                          </div>
                        </div>
                      ) : null}
                    </div>

                    <div className="row">
                      {commProperty.propertyType ===
                        "Warehouse /Godown" ? null : (
                        <>
                          <div className="col-md p-0">
                            <div className="input__box m-0"></div>
                          </div>
                          <div className="col-md-12">
                            <div className="input__box p-2">
                              <span className="details">Select Floor</span>
                              <div className="d-flex">
                                {" "}
                                <IconButton
                                  disabled={currentSelectPage === 1}
                                  onClick={() =>
                                    handlePageChange(
                                      currentSelectPage - 1,
                                      "select"
                                    )
                                  }
                                >
                                  <ArrowBackIosNewIcon />
                                </IconButton>
                                <div className="wall-selector">
                                  {renderSelectedBricks()}
                                </div>
                                <IconButton
                                  disabled={
                                    currentSelectPage * itemsPerPage >= 60
                                  }
                                  onClick={() =>
                                    handlePageChange(
                                      currentSelectPage + 1,
                                      "select"
                                    )
                                  }
                                >
                                  <ArrowForwardIosIcon />
                                </IconButton>
                              </div>

                              <div style={{ color: "red", fontSize: 13 }}>
                                {commPropertyError.selectFloor}
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>

                    <h2 className="mt-4"> Property Features</h2>
                    <p className="desc"></p>

                    <div className="col-md-6">
                      <div className="gender__details">
                        <span className="gender__title">Posted By*</span>
                        <span style={{ width: "60%" }}>
                          <div
                            className="form-check"
                            style={{
                              display: "inline-block",
                              marginRight: "52px",
                            }}
                          >
                            <input
                              className="form-check-input"
                              type="radio"
                              name="postedby"
                              id="postedby1"
                              value="Owner"
                              checked={commProperty.PostedBy === "Owner"}
                              onChange={(e) => {
                                setCommPropertyError({
                                  ...commPropertyError,
                                  PostedBy: "",
                                });
                                onChangeHandler("PostedBy", e.target.value);
                              }}
                            // checked
                            />
                            <label
                              className="form-check-label"
                              htmlFor="postedby1"
                            >
                              Owner
                            </label>
                          </div>
                          <div
                            className="form-check"
                            style={{ display: "inline-block" }}
                          >
                            <input
                              className="form-check-input"
                              type="radio"
                              name="postedby"
                              id="postedby2"
                              value="Agent"
                              checked={commProperty.PostedBy === "Agent"}
                              onChange={(e) => {
                                onChangeHandler("PostedBy", e.target.value);
                                setCommPropertyError({
                                  ...commPropertyError,
                                  PostedBy: "",
                                });
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="postedby2"
                            >
                              Agent
                            </label>
                          </div>
                          <div style={{ color: "red", fontSize: 13 }}>
                            {commPropertyError.PostedBy}
                          </div>
                        </span>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="gender__details">
                        <span
                          className="gender__title"
                          style={{ minWidth: "40%" }}
                        >
                          Property Availability*
                        </span>
                        <span style={{ width: "55%" }}>
                          <div
                            className="form-check"
                            style={{
                              display: "inline-block",
                              marginRight: "30px",
                            }}
                          >
                            <input
                              className="form-check-input"
                              type="radio"
                              name="propertystatus"
                              id="propertystatus1"
                              value="Available"
                              checked={commProperty.Availibilty === "Available"}
                              onChange={(e) => {
                                onChangeHandler("Availibilty", e.target.value);
                                setCommPropertyError({
                                  ...commPropertyError,
                                  Availibilty: "",
                                });
                              }}
                            // checked
                            />
                            <label
                              className="form-check-label"
                              htmlFor="propertystatus1"
                            >
                              Available
                            </label>
                          </div>
                          <div
                            className="form-check"
                            style={{ display: "inline-block" }}
                          >
                            <input
                              className="form-check-input"
                              type="radio"
                              name="propertystatus"
                              id="propertystatus2"
                              value="Not Available"
                              checked={
                                commProperty.Availibilty === "Not Available"
                              }
                              onChange={(e) => {
                                onChangeHandler("Availibilty", e.target.value);
                                setCommPropertyError({
                                  ...commPropertyError,
                                  Availibilty: "",
                                });
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="propertystatus2"
                            >
                              Not Available
                            </label>
                          </div>
                          <div style={{ color: "red", fontSize: 13 }}>
                            {commPropertyError.Availibilty}
                          </div>
                        </span>
                      </div>
                    </div>

                    <div className="row">
                      {Object.keys(propertyOptions).map((property) => (
                        <div className="col-md-6" key={property}>
                          <div className="gender__details">
                            <span
                              className="gender__title"
                              style={{ minWidth: "40%" }}
                            >
                              {propertyOptionsMapping[property]}*
                            </span>
                            <span className="d-flex g-2">
                              <div
                                className="form-check"
                                style={{
                                  display: "inline-block",
                                }}
                              >
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name={property}
                                  id={`${property}1`}
                                  checked={propertyOptions[property] === true}
                                  onChange={() => {
                                    handlePropertyFeatureChange(property, true);
                                    setCommPropertyError({
                                      ...commPropertyError,
                                      [property]: "",
                                    });
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`${property}1`}
                                >
                                  YES
                                </label>
                              </div>
                              <div
                                className="form-check"
                                style={{ display: "inline-block" }}
                              >
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name={property}
                                  id={`${property}2`}
                                  checked={propertyOptions[property] === false}
                                  onChange={() => {
                                    handlePropertyFeatureChange(
                                      property,
                                      false
                                    );
                                    setCommPropertyError({
                                      ...commPropertyError,
                                      [property]: "",
                                    });
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`${property}2`}
                                >
                                  No
                                </label>
                              </div>
                            </span>
                          </div>
                          <div style={{ color: "red", fontSize: 13 }}>
                            {commPropertyError[property]}
                          </div>
                        </div>
                      ))}
                    </div>
                    <h2 className="mt-4">Property Images</h2>
                    <p className="desc"></p>

                    <div className="row">
                      {propertyImages.map((property, index) => (
                        <div className="col-md-6" key={index}>
                          <div className="input__box p-2">
                            <span
                              className="details "
                              style={{
                                color:
                                  commProperty[property.name]?.length > 0
                                    ? "green"
                                    : "gray",
                              }}
                            >
                              {property.label}
                            </span>

                            <FormControl
                              variant="outlined"
                              fullWidth
                              size="small"
                            >
                              <input
                                // id={property.name}
                                accept="image/*"
                                type="file"
                                onChange={(event) => {
                                  handleImageChange(event, property.name);
                                  setCommPropertyError({
                                    ...commPropertyError,
                                    [property.name]: "",
                                  });
                                }}
                                required
                                // ref={inputRefrence1}

                                multiple
                              />
                              <div className="upload-img">
                                {commProperty[property.name]?.length > 0 &&
                                  commProperty[property.name].map(
                                    (image, imageIndex) => (
                                      <div className="col-md-6">
                                        <div className="files-list">
                                          <a
                                            href={image.name}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{
                                              display: "inline-block",
                                              width: "95%",
                                              overflow: "hidden",
                                            }}
                                          >
                                            <img
                                              src={image.name}
                                              width="500"
                                              height="100"
                                              alt="Property"
                                            />
                                          </a>
                                          <DeleteOutlineIcon
                                            className="delete-button"
                                            onClick={() => {
                                              removeImage(
                                                image,
                                                property.name,
                                                imageIndex
                                              );
                                            }}
                                          />
                                        </div>
                                      </div>
                                    )
                                  )}
                              </div>
                            </FormControl>
                            <div style={{ color: "red", fontSize: 13 }}>
                              {commPropertyError[property.name]}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>

                    <h2 className="mt-4">Property Description</h2>
                    <p className="desc"></p>
                    <TextField
                      id="outlined-multiline-flexible"
                      label="Description"
                      multiline
                      name="descriptionAboutCommercialProperty"
                      maxRows={4}
                      value={commProperty.descriptionAboutCommercialProperty}
                      onChange={(e) => {
                        setCommProperty({
                          ...commProperty,
                          descriptionAboutCommercialProperty: e.target.value,
                        });
                        setCommPropertyError({
                          ...commPropertyError,
                          descriptionAboutCommercialProperty: "",
                        });
                      }}
                    />
                  </div>
                  <div className="form-group d-flex justify-content-center mt-4">
                    {commId === 0 ? (
                      <div className="d-flex gap-3">
                        <button
                          type="submit"
                          value="Submit"
                          className="btn-red"
                          onClick={submitCommProperty}
                        >
                          Submit
                        </button>

                        <button
                          // type="submit"s
                          value="Clear"
                          className="btn-grey"
                          onClick={refreshData}
                        >
                          Clear
                        </button>

                      </div>
                    ) : (
                      <button
                        type="submit"
                        value="Update"
                        className="btn-red"
                        onClick={UpdateCommProperty}
                      >
                        Update
                      </button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewCommercial;
