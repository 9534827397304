import React, { useEffect, useState } from "react";
import "./CustomerStyle.css";
import { getAllReview, addBlockReview } from "../../Api/ReviewAPI";
import { settings } from "../../Config/Settings";
import UserImage from "../../Assets/user.png";
import { Container, Row, Col } from "react-bootstrap";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import ProgressBar from "react-bootstrap/ProgressBar";
import Badge from "react-bootstrap/Badge";
import Auth from "../../Config/helper/Auth";
import Swal from "sweetalert2";
import MapsUgcIcon from "@mui/icons-material/MapsUgc";
import ReportGmailerrorredOutlinedIcon from '@mui/icons-material/ReportGmailerrorredOutlined';
import { IconButton, Tooltip } from "@mui/material";
import { MdVisibility } from "react-icons/md";

const CustomerReview = ({ review, i }) => {
  const [imageInfo, setImageInfo] = useState([]);
  const [fullName, setFullName] = useState("");
  const [expandedComments, setExpandedComments] = useState({});
  const [isExpanded, setIsExpanded] = useState(false);
  const MAX_LENGTH = 100;

  useEffect(() => {
    if (review?.mostRecent?.UserId) {
      setImageInfo(review?.mostRecent?.UserId?.profileImage);
      setFullName(review?.mostRecent?.UserId?.fullName);
    }

    console.log(review,"review");
    
  }, [review]);

  const handleToggleExpand = (index) => {
    setExpandedComments((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const renderStars = () => {
    const stars = [];
    for (let i = 0; i < review.mostRecent.Rating; i++) {
      stars.push(
        <span key={i} className="star">
          &#9733;
        </span>
      );
    }
    return stars;
  };

  // const formatDate = (dateString) => {
  //   const date = new Date(dateString);
  //   const monthNames = [
  //     "January",
  //     "February",
  //     "March",
  //     "April",
  //     "May",
  //     "June",
  //     "July",
  //     "August",
  //     "September",
  //     "October",
  //     "November",
  //     "December",
  //   ];

  //   const month = monthNames[date.getMonth()];
  //   const year = date.getFullYear();

  //   return `${month} ${year}`;
  // };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
  
    const month = monthNames[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
  
    return `${month} ${day}, ${year} ${hours}:${minutes}`;
  };

  const formattedDate = formatDate(review.mostRecent.createdAt);



  const sendRequestToDelete = (data) => {
    let loginId = Auth?.getUserDetails()._id;
    let dataToSend = {
      UserId: data.mostRecent.UserId._id,
      ownerId: loginId,
      requestForBlock: "Request to Block",
      blockReviewId: data.mostRecent._id,
      blockComment: data.mostRecent.Comment,
      PropertyId: data.mostRecent.PropertyId,
    };
    addBlockReview(dataToSend)
      .then((res) => {
        if (res.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Request to Block Comment Sent Successfully.",
            showConfirmButton: true,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "ok",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const isBlocked =
    review?.mostRecent?.blockStatus &&
    review?.mostRecent?.blockStatus === "Block";

  return (
    <div className="customer-review">

      {
        Auth?.user?.userData?.role === "Owner" ||
          Auth?.user?.userData?.role === "Agent" ||
          Auth?.user?.userData?.role === "Builder" ?
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              textAlign: "right",
            }}
          ><Tooltip title="Report Abuse" arrow placement="right">
              <IconButton
                disabled={isBlocked ? true : false}
                align="right"
                onClick={() => sendRequestToDelete(review)}
              >

                <ReportGmailerrorredOutlinedIcon
                />
              </IconButton></Tooltip>
          </div> : null
      }


      <div className="user-info">
        <img
          src={
            imageInfo[0]?.value
              ? `${settings.IMAGE_URL}/${imageInfo[0]?.value}`
              : UserImage
          }
          alt="Profile"
          className="profile-photo"
        />
        <div className="user-details">
          <p>{fullName}</p>
          <div className=" rating ps-2"><Tooltip title="no. of stars" arrow placement="right">{renderStars()}</Tooltip></div>
        </div>
      </div>

      <div>
        <p className="comment">"{isExpanded ? review.mostRecent.Comment : `${review.mostRecent.Comment.substring(0, MAX_LENGTH)}`}"</p>
        {review.mostRecent.Comment > MAX_LENGTH && (
          <button onClick={handleToggle} className="read-more-btn">
            {isExpanded ? 'Read less' : 'Read more'}
          </button>
        )}
        <div className="review-date">{formattedDate}</div>
      </div>
      {/* <div>
        {review.otherReviews.length > 0 && (
          <a
            href=""
            onClick={() => handleToggleExpand(i)}
            style={{
              marginLeft: "5px",
              color: "blue",
              cursor: "pointer",
            }}
          >
            {expandedComments[i] ? (
              <p className="bluesharebuttonViewAllComment">Hide Comments</p>
            ) : (
              <p className="bluesharebuttonViewAllComment">View All Comments</p>
            )}
          </a>
        )}
        {expandedComments[i]
          ? review.otherReviews.map((rev) => (
            <div key={rev._id}>
              <p>{rev.Comment}</p>
              <div className="review-date">{formattedDate}</div>
            </div>
          ))
          : null}
      </div> */}

      <div>
        {review.otherReviews.length > 0 && (
          <span
            onClick={(e) => {
              e.preventDefault();
              handleToggleExpand(i);
            }}
            style={{
              marginLeft: "5px",
              color: "blue",
              cursor: "pointer",
            }}
          >
            {expandedComments[i] ? (
              <p className="bluesharebuttonViewAllComment">Hide Comments</p>
            ) : (
              <p className="bluesharebuttonViewAllComment">View All Comments</p>
            )}
          </span>
        )}
        {expandedComments[i]
          ? review.otherReviews.map((rev) => (
            <div key={rev._id}>
              <p className="comment">"{rev.Comment}"</p>
              <div className="review-date">{formattedDate} </div>
            </div>
          ))
          : null}
      </div>
    </div>
  );
};

const CustomerReviewSection = ({ propertyId, postData }) => {
  const [reviews, setReviews] = useState([]);
  const [overAllRating, setOverAllRating] = useState(0.0);
  const [totalRetingCount, setTotalRetingCount] = useState();
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [filterData, setFilterData] = useState([
    { key: 1, value: "Most recent" },
    { key: 2, value: "Highest Rated" },
    { key: 3, value: "Lowest Rated" },
  ]);
  const [visibleReviews, setVisibleReviews] = useState(5);

  useEffect(() => {
    getAllReviews();
  }, []);

  useEffect(() => {
    if (postData) getAllReviews();
  }, [postData]);

  const getAllReviews = async () => {
    getAllReview(propertyId)
      .then((res) => {
        if (res.data.status === 200) {
          setReviews(res.data.recentReviews);
          setTotalRetingCount(res.data.count)

          const val =
            // res.data.overallRating === "NaN" ? 0.0 : res.data.overallRating;
            isNaN(res.data.overallRating) ? 0.0 : res.data.overallRating;
          setOverAllRating(val);
        } else {
          setReviews([]);
        }
      })
      .catch((e) => {
        console.log(e, "error in get reviews");
      });
  };

  const handleFilterChange = (event) => {
    setSelectedFilter(event.target.value);
  };

  const filteredReviews =
    selectedFilter === "1"
      ? reviews.slice().reverse()
      : selectedFilter === "2"
        ? reviews
          .slice()
          .sort((a, b) => b.mostRecent.Rating - a.mostRecent.Rating)
        : selectedFilter === "3"
          ? reviews
            .slice()
            .sort((a, b) => a.mostRecent.Rating - b.mostRecent.Rating)
          : reviews;

  const loadMoreReviews = () => {
    setVisibleReviews((prev) => prev + 5);
  };

  const ratingCount = reviews.reduce(
    (acc, review) => {
      acc[review.mostRecent.Rating]++;
      review.otherReviews.forEach(otherReview => {
        acc[otherReview.Rating]++;
      });
      return acc;
    },
    { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 }
  );

  const totalReviewsCount = reviews.reduce(
    (acc, review) => acc + 1 + review.otherReviews.length,
    0
  );


  const calculatePercentage = (count) => {
    if (isNaN(count) || totalReviewsCount === 0) return 0;
    return (count / totalReviewsCount) * 100;
  };

  return (
    <>
      <h4>Ratings & Reviews</h4>
      <Container>
        <Row>
          <Col lg={3} sm={12} className="text-center ">
            <Stack spacing={1} alignItems={"center"} className="ratings">
              <h2>{overAllRating}/5</h2>
              <Rating
                name="half-rating-read"
                value={overAllRating}
                precision={0.5}
                readOnly
              />
              <h6>Average Rating</h6>
              <p>({totalRetingCount} Total Reviews)</p>
            </Stack>
            {/* <div className="my-2">
              <div className="d-flex">
                <ProgressBar now={100} />5
              </div>
              <div className="d-flex">
                {" "}
                <ProgressBar now={60} />4
              </div>
              <div className="d-flex">
                <ProgressBar now={80} />3
              </div>
              <div className="d-flex">
                <ProgressBar now={5} />2
              </div>
              <div className="d-flex">
                {" "}
                <ProgressBar now={10} />1
              </div>
            </div> */}

            {/* <div className="my-2">
              <div className="d-flex align-items-center">
                <ProgressBar now={(ratingCount[5] / reviews.length) * 100} className="flex-grow-1 me-2" />
                5
              </div>
              <div className="d-flex align-items-center">
                <ProgressBar now={(ratingCount[4] / reviews.length) * 100} className="flex-grow-1 me-2" />
                4
              </div>
              <div className="d-flex align-items-center">
                <ProgressBar now={(ratingCount[3] / reviews.length) * 100} className="flex-grow-1 me-2" />
                3
              </div>
              <div className="d-flex align-items-center">
                <ProgressBar now={(ratingCount[2] / reviews.length) * 100} className="flex-grow-1 me-2" />
                2
              </div>
              <div className="d-flex align-items-center">
                <ProgressBar now={(ratingCount[1] / reviews.length) * 100} className="flex-grow-1 me-2" />
                1
              </div>
            </div> */}

            <div className="my-2">
              {Object.keys(ratingCount).reverse().map((rating) => {
                let count = isNaN(ratingCount[rating]) ? 0 : ratingCount[rating];
                return (
                  <div key={rating} className="d-flex align-items-center mb-2">
                    <span className="me-2">{rating} star</span>
                    <ProgressBar
                      now={calculatePercentage(ratingCount[rating])}
                      className="flex-grow-1 me-2"
                      variant="warning"
                    />
                    <span>{calculatePercentage(ratingCount[rating]).toFixed(0)}%</span>
                  </div>
                )
              })}
            </div>


          </Col>

          <Col>
            <div>
              <div className="d-flex mt-2 justify-content-between">
                <p>Recent Comments ({reviews.length})</p>
                <div className="px-2">
                  <select
                    onChange={handleFilterChange}
                    value={selectedFilter}
                    className="bluebtnfilter"
                  >
                    {filterData.map((f) => (
                      <option key={f.key} value={f.key}>
                        {f.value}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="customer-review-section">
                {filteredReviews.slice(0, visibleReviews).map((review, i) => (
                  <CustomerReview review={review} i={i} key={i} />
                ))}
              </div>
              {visibleReviews < filteredReviews.length && (
                <div className="text-center mt-4">
                  <button onClick={loadMoreReviews} className="btn btn-primary">
                    Load More Reviews
                  </button>
                </div>
              )}
            </div>
          </Col>

          {/* <Col lg={9} sm={12} className="px-5">
            Popular Mentions
            <div className="d-flex">
              <ProgressBar now={100} />
              100% Positive Mentions
            </div>
            <Stack
              direction="horizontal"
              gap={1}
              flexWrap={"wrap"}
              marginTop={3}
            >
              <Badge pill>No Power Cuts (5)</Badge>
              <Badge pill>No Water Shortage (4)</Badge>

              <Badge pill>Easy Cab/Auto Availability (4)</Badge>
              <Badge pill>Visitor Parking (4)</Badge>
              <Badge pill>Well-maintained Roads (4)</Badge>
              <Badge pill>Good School Nearby (4)</Badge>
              <Badge pill>Well Lit (4)</Badge>
              <Badge pill>Good Security (4)</Badge>
              <Badge pill>Clean Society (4)</Badge>
            </Stack>
          </Col> */}
        </Row>
      </Container>
      <Container>
        <Row>

        </Row>
      </Container>
    </>
  );
};

export default CustomerReviewSection;
