import React from 'react'
import AppleIcon from '@mui/icons-material/Apple';
import AdbIcon from '@mui/icons-material/Adb';
import appdownImg from "../../Assets/app_hand.png";
export const Downloadapp = () => {
    return (
        <div>

            <div id="downloadapp">
                <div className="content-box">
                    <div
                        className="container-fluid app-bg  wow animated fadeIn"
                        data-wow-duration="1s"
                        data-wow-delay=".5s"
                    >
                        <div className="row">
                            <div className="col-md-12 align-bottom">
                                <div className="container">
                                    <div className="row ">
                                        <div className="col-md-7 col-sm-12">
                                            <div
                                                className="app-download-content wow animated fadeInLeft"
                                                data-wow-duration="1s"
                                                data-wow-delay=".5s"
                                            >
                                                <h3 >Download & Browse Hundreds of Properties</h3>
                                                <p>
                                                    Lorem Ipsum is simply dummy text of printing and type
                                                    setting industry. Lorem Ipsum been industry standard
                                                    dummy text ever since, when an unknown printer took a
                                                    galley of type and scrambled it to make a type
                                                    specimen book. It has survived not only five
                                                    centuries, but also the leap into electronic type
                                                    setting.
                                                </p>
                                                <div className="download-apps">
                                                    <div className="download-app">
                                                        <AppleIcon/>
                                                        
                                                        <h5>App Store</h5>
                                                        <p>Available Now</p>
                                                    </div>
                                                    <div className="download-app">
                                                       <AdbIcon/>
                                                        <h5>Google Play</h5>
                                                        <p>Available Now</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-5 col-sm-12 ">
                                            <div
                                                className="img-margin wow animated fadeInRight "
                                                data-wow-duration="1s"
                                                data-wow-delay=".5s"
                                            >
                                                <img
                                                    src={appdownImg}
                                                    className="img-fluid "
                                                  
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
