/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// @mui packages
import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  Button,
  Autocomplete,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import InputAdornment from "@mui/material/InputAdornment";
//icons
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import IconButton from "@mui/material/IconButton";
//other packages
import swal from "sweetalert";
import Swal from "sweetalert2";
//custom components
import FileLoader from "../../../Components/Common/FileLoader";
import AdminSidebar from "../../../Components/Sidebar/AdminSidebar";
//Constant.js
import {
  locationsData,
  // PropertyTypeOption,
  PropertyStatusOption,
  PropertyAgeOption,
  PropertyDoorDirectionOption,
  FurnishedStatusOption,
  NoticePeriodOption,
  MonthsOption,
  YearsOption,
  PurposeOfPropertyList,
} from "../../../modules/helpers/constant";
//Auth
import Auth from "../../../Config/helper/Auth";
//Api
import {
  addRentProperty,
  deleteImagesById,
  updateRentProperty,
} from "../../../Api/RentpropertyAPI";
import { AmenitiesList, getAminitiesData, getLocationData, getPropertytypeByTypeData, getSubLocationById } from "../../../Api/AuthAPI";
//css
import "./Rent.css";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";



const initialRentProperty = {
  userId: Auth.getUserDetails() && Auth.getUserDetails()._id,
  Location: "",
  LocationId: "",
  subLocation: "",
  subLocationId: "",
  // area: "",
  areainSqft: "",
  SecurityDeposit: "",
  PropertyRent: "",
  Amenities: [],
  propertyType: "",
  PropertyStatus: "",
  // Availibilty: "",
  age: "",
  selectNosofBedrooms: null,
  selectNosofBalconies: null,
  selectNosofBathrooms: null,
  // postedBy: "",
  postedBy: Auth?.user?.userData?.role,
  // isBathroomAttched: null,
  // isBalconyAttched: null,
  isBoundrywallMade: null,
  isGatedColony: null,
  isCCTVinstalled: null,
  // isFurnished: null,
  // isCommonAreaAttched: null,
  exteriorView: [],
  livingRoomImage: [],
  bedRoomImage: [],
  kitchenImage: [],
  bathroomImage: [],
  commonAreaImage: [],
  balconyImage: [],
  //backend send
  exteriorViewB: [],
  livingRoomImageB: [],
  bedRoomImageB: [],
  kitchenImageB: [],
  bathroomImageB: [],
  commonAreaImageB: [],
  balconyImageB: [],

  selectFloor: null,
  totalFloor: null,
  noticePeriod: "",
  propertyDirection: "",
  AvailableYear: null,
  AvailableMonth: null,
  furnishedStatus: [],
  DescriptionAboutResidentialProperty: "",
  purposeOfProperty: PurposeOfPropertyList[0].value,
  bookingAmount: "",
  propertyCost: "",
  heavyDeposite: "",

};
const initialFeatureState = {
  isBoundrywallMade: null,
  isGatedColony: null,
  isCCTVinstalled: null,
};

function NewRent() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const fileInputRef = useRef(null);
  const fileImage1 = useRef(null);
  const fileImage2 = useRef(null);
  const fileImage3 = useRef(null);
  const fileImage4 = useRef(null);
  const fileImage5 = useRef(null);
  const fileImage6 = useRef(null);
  const fileImage7 = useRef(null);

  const [rentpropertyId, setRentpropertyId] = useState(0);
  const [rentProperty, setRentProperty] = useState({ ...initialRentProperty });
  const [rentPropertyError, setRentPropertyError] = useState({
    ...initialRentProperty,
  });
  const [fileLoading, setFileLoding] = useState(false);
  const [currentTotalPage, setCurrentTotalPage] = useState(1);
  const itemsPerPage = 10;
  const [currentSelectPage, setCurrentSelectPage] = useState(1);
  const [amenitiesOption, setAmenitiesOption] = useState([]);
  const [locationData, setLocationData] = useState([])
  const [subLocationData, setSubLocationData] = useState([]);

  const [propertyOptions, setPropertyOptions] = useState({
    ...initialFeatureState,
  });
  //imagesList
  const propertyImages = [
    { name: "exteriorView", label: "Exterior View", ref: fileImage1 },
    { name: "livingRoomImage", label: "Living Room", ref: fileImage2 },
    { name: "bedRoomImage", label: "Bedroom", ref: fileImage3 },
    { name: "kitchenImage", label: "Kitchen", ref: fileImage4 },
    { name: "bathroomImage", label: "Bathroom", ref: fileImage5 },
    { name: "commonAreaImage", label: "Common Area", ref: fileImage6 },
    { name: "balconyImage", label: "Balcony", ref: fileImage7 },
  ];

  const [propertyTypeOption, setPropertyTypeOption] = useState([]);

  useEffect(() => {
    getpropertyType()
  }, [])

  const getpropertyType = () => {
    getPropertytypeByTypeData("Residential")
      .then((res) => {
        if (res.status === 200) {
          let result = res.data.data;
          let list = result.map((data) => ({ label: data.AreaType, value: data._id }));
          setPropertyTypeOption(list);
        }
      }).catch((err) => {
        console.log(err, "error in property get data");
      })
  }

  //useEffects
  useEffect(() => {
    AmenitiesDataList();
  }, []);

  //getByIdData for update
  useEffect(() => {
    console.log(state, "states");

    if (state && Object.keys(state).length !== 0) {
      setRentpropertyId(state._id);
      setRentProperty({
        LocationId: state.LocationId,
        subLocationId: state.subLocationId,
        areainSqft: state.areainSqft,
        SecurityDeposit: state.SecurityDeposit,
        purposeOfProperty: state.purposeOfProperty,
        PropertyRent: state.PropertyRent,
        propertyCost: state.propertyCost,
        bookingAmount: state.bookingAmount,
        Amenities: state.Amenities,
        propertyType: state.propertyType,
        PropertyStatus: state.PropertyStatus,
        // Availibilty: state.Availibilty,
        age: state.age || "",
        selectNosofBedrooms: state.selectNosofBathrooms,
        selectNosofBalconies: state.selectNosofBalconies,
        selectNosofBathrooms: state.selectNosofBedrooms,
        postedBy: state.postedBy,

        // postedBy: Auth?.user?.userData?.role,
        // isBathroomAttched: state.isBathroomAttched,
        // isBalconyAttched: state.isBalconyAttched,
        isBoundrywallMade: state.isBoundrywallMade,
        isGatedColony: state.isGatedColony,
        isCCTVinstalled: state.isCCTVinstalled,
        // isFurnished: state.isFurnished,
        // isCommonAreaAttched: state.isCommonAreaAttched,
        exteriorView: state.exteriorView,
        livingRoomImage: state.livingRoomImage,
        bedRoomImage: state.bedRoomImage,
        kitchenImage: state.kitchenImage,
        bathroomImage: state.bathroomImage,
        commonAreaImage: state.commonAreaImage,
        balconyImage: state.balconyImage,

        selectFloor: state.selectFloor,
        totalFloor: state.totalFloor,
        noticePeriod: state.noticePeriod,
        propertyDirection: state.propertyDirection,
        AvailableYear: state.AvailableYear,
        AvailableMonth: state.AvailableMonth,
        furnishedStatus: state.furnishedStatus,
        DescriptionAboutResidentialProperty:
          state.DescriptionAboutResidentialProperty,
      });
      getSubLocation(state.LocationId);
      setPropertyOptions({
        // isBathroomAttched: state.isBathroomAttched,
        // isBalconyAttched: state.isBalconyAttched,
        isBoundrywallMade: state.isBoundrywallMade,
        isGatedColony: state.isGatedColony,
        isCCTVinstalled: state.isCCTVinstalled,
        // isFurnished: state.isFurnished,
        // isCommonAreaAttched: state.isCommonAreaAttched,
      });
    }
  }, []);

  //ForLocation and sublocation get call
  useEffect(() => {
    getLocationData()
      .then((res) => {
        if (res.status === 200) {
          let locationData = res.data.locations;
          setLocationData(locationData)
        }
      }).catch((err) => {
        console.log(err, "error in location get call find property")
      })
  }, [])

  //subLocation 
  const getSubLocation = (locationId) => {
    getSubLocationById(locationId)
      .then((res) => {
        if (res.status === 200) {
          setSubLocationData(res.data.locationdata);
        } else {
          swal("warning", "subLocation not found", 'warning');
        }
      }).catch((err) => {
        console.log(err, "error in subLocation newRent")
      })
  }

  // AmenitiesList
  const AmenitiesDataList = () => {
    getAminitiesData()
      .then((res) => {
        if (res.status === 200) {
          const aminitiesResedentialData = res.data.AmenitiesData;
          const residentialAminitiesData = aminitiesResedentialData.filter(
            (aminititesData) =>
              aminititesData.PropertyType === "Residential" ||
              aminititesData.PropertyType === "All"
          );
          const formattedData = residentialAminitiesData.map((amenity) => ({
            label: amenity.AmenitiesName,
            value: amenity._id,
          }));

          setAmenitiesOption(formattedData);
        } else {
          setAmenitiesOption([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //property features options
  const propertyOptionsMapping = {
    // isBathroomAttched: "Attached Bathroom",
    // isBalconyAttched: "Attached Balcony",
    isBoundrywallMade: "Boundry Wall Made",
    isGatedColony: "Gated Colony",
    isCCTVinstalled: "CCTV Installed",
    // isFurnished: "Furnished",
    // isCommonAreaAttched: "Attached CommonArea",
  };

  //handleChange

  const handleLocationChange = (name, value) => {
    if (name === "Location") {
      setRentProperty({ ...rentProperty, Location: value.label, LocationId: value.value, subLocation: "" });
      getSubLocation(value.value);
    } else if (name === "subLocation") {
      setRentProperty({ ...rentProperty, subLocation: value.label, subLocationId: value.value });
    } else if (name === "area") {
      setRentProperty({ ...rentProperty, area: value });
    }
  };

  const onChangeHandler = (name, value) => {

    if (name === "purposeOfProperty") {
      if (value === "Rent") {
        setRentProperty({
          ...rentProperty, propertyCost: "", bookingAmount: "", purposeOfProperty: value
        });
      } else if (value === "Buy") {
        setRentProperty({
          ...rentProperty, SecurityDeposit: "", PropertyRent: "", purposeOfProperty: value
        });
      } else {
        setRentProperty({
          ...rentProperty, propertyCost: "", bookingAmount: "", purposeOfProperty: value
        });
      }
    } else {
      
      setRentProperty({
        ...rentProperty, [name]: value
      });
    }
  };

  const onFurnishedChangeHandler = (value) => {
    setRentProperty({ ...rentProperty, furnishedStatus: value });
  };

  const handlePropertyFeatureChange = (property, value) => {
    setPropertyOptions({ ...propertyOptions, [property]: value });
    setRentProperty({ ...rentProperty, [property]: value });
  };

  const renderTotalBricks = () => {
    const start = (currentTotalPage - 1) * itemsPerPage + 1;
    const end = Math.min(start + itemsPerPage - 1, 50);

    const bricks = [];
    for (let i = start; i <= end; i++) {
      bricks.push(
        <div
          key={i}
          className={`brick ${rentProperty.totalFloor === i ? "selected2" : ""
            }`}
          onClick={() => {
            setRentPropertyError({ ...rentPropertyError, totalFloor: "" });
            onChangeHandler("totalFloor", i);
          }}
        >
          {i}
        </div>
      );
    }

    return bricks;
  };

  const renderSelectedBricks = () => {
    const floors = [];
    if (currentSelectPage === 1) {
      floors.push("Ground", "Lower Basement", "Upper Basement");
    } else {
      const start = (currentSelectPage - 2) * itemsPerPage + 1;
      const end = Math.min(start + itemsPerPage - 1, 50);

      for (let i = start; i <= end; i++) {
        floors.push(i);
      }
    }

    const bricks = floors.map((floor, index) => (
      <div
        key={index}
        className={`brick ${rentProperty.selectFloor === floor ? "selected2" : ""
          }`}
        onClick={() => {
          setRentPropertyError({ ...rentPropertyError, selectFloor: "" });
          onChangeHandler("selectFloor", floor);
        }}
      >
        {floor}
      </div>
    ));

    return bricks;
  };

  const handlePageChange = (newPage, name) => {
    if (name === "total") {
      setCurrentTotalPage(newPage);
    } else if (name === "select") {
      setCurrentSelectPage(newPage);
    }
  };

  const handleImageChange = async (event, name) => {
    setFileLoding(true);
    const imageFiles = event.target.files;
    const existingImages = rentProperty[name] || [];
    const imageArray = [...existingImages];
    var imageArrayB = rentProperty[`${name}B`] || [];

    for (let i = 0; i < imageFiles.length; i++) {
      // const imageFile = await IpfsImgUpload(imageFiles[i]);
      var imageFile = imageFiles[i];
      imageArray.push({
        imageName: imageFile.name,
        name: URL.createObjectURL(imageFile),
        //  name: imageFiles[i]?.name, 
        //  value: imageFile 
      });
      imageArrayB.push(imageFile);
    }

    setRentProperty({
      ...rentProperty,
      [name]: imageArray,
      [`${name}B`]: imageArrayB,
    })
    // setRentProperty({ ...rentProperty, [name]: imageArray });
    setFileLoding(false);

  };

  const removeImage = (img, property, index, ref) => {
    if (ref.current) {
      ref.current.value = "";
      ref.current.type = "text";
      ref.current.type = "file";
    }
    if (img._id) {
      const data = {
        imageId: img._id
      };
      deleteImagesById(rentpropertyId, data)
        .then((res) => {
          if (res.status === 200) {
            const updatedImages = [...rentProperty[property]];
            updatedImages.splice(index, 1);
            setRentProperty({ ...rentProperty, [property]: updatedImages });
          } else {
            console.log("error in remove image");
          }
        })
        .catch((err) => {
          console.log(err, "error in newrent delete");
        });
    } else {
      const updatedImages = [...rentProperty[property]];
      updatedImages.splice(index, 1);
      setRentProperty({ ...rentProperty, [property]: updatedImages });

    }
  };


  // const removeImage = (img, property, index) => {

  //   if (img._id) {
  //     const data = {
  //       imageId: img._id
  //     };
  //     deleteImagesById(rentpropertyId, data)
  //       .then((res) => {
  //         if (res.status === 200) {
  //           const updatedImages = [...rentProperty[property]];
  //           updatedImages.splice(index, 1);
  //           setRentProperty({ ...rentProperty, [property]: updatedImages });

  //         } else {
  //           console.log("error in remove image");
  //         }

  //       }).catch((err) => {
  //         console.log(err, "error in newrent delete");
  //       })
  //   } else {
  //     const updatedImages = [...rentProperty[property]];
  //     updatedImages.splice(index, 1);
  //     setRentProperty({ ...rentProperty, [property]: updatedImages });
  //   }
  // };


  //Validation for post&update
  const validates = () => {

    let rentPropertyError = {};
    let isValid = true;
    if (rentProperty.Location === "") {
      Swal.fire({
        icon: "warning",
        title: "Incomplete Form",
        text: "Please fill in all required fields before submitting.",
        showConfirmButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "OK",
      });
    }
    if (rentProperty.Location === "") {
      isValid = false;
      rentPropertyError["Location"] = "Please Select Location";
    }
    if (rentProperty.subLocation === "") {
      isValid = false;
      rentPropertyError["subLocation"] = "Please Select SubLocation";
    }

    if (rentProperty.propertyType === "") {
      isValid = false;
      rentPropertyError["propertyType"] = "Please Select Property Type";
    }

    if (rentProperty.PropertyStatus === "") {
      isValid = false;
      rentPropertyError["PropertyStatus"] = "Please select property status.";
    }

    if (rentProperty.PropertyStatus === "Ready to Move") {
      if (rentProperty.age === "") {
        isValid = false;
        rentPropertyError["age"] = "Please Select Property Age";
      }
    }

    if (rentProperty.areainSqft === "") {
      isValid = false;
      rentPropertyError["areainSqft"] = "Please enter carpet Area";
    }

    if (rentProperty.propertyDirection === "") {
      isValid = false;
      rentPropertyError["propertyDirection"] =
        "Please enter Property Main Door Direction";
    }
    //purposeproper rent validation
    if (rentProperty.purposeOfProperty == "Rent") {
      if (rentProperty.SecurityDeposit === "") {
        isValid = false;
        rentPropertyError["SecurityDeposit"] = "Please enter Security Deposit";
      }
      if (rentProperty.PropertyRent === "") {
        isValid = false;
        rentPropertyError["PropertyRent"] = "Please enter Property Rent";
      }
    }
    //buy validation
    if (rentProperty.purposeOfProperty === "Buy") {
      if (rentProperty.propertyCost === "") {
        isValid = false;
        rentPropertyError["propertyCost"] = "Please enter Property Cost";
      }
      if (rentProperty.bookingAmount === "") {
        isValid = false;
        rentPropertyError["bookingAmount"] = "Please enter Booking Amount";
      }
    }

    if (rentProperty.AvailableMonth === null) {
      isValid = false;
      rentPropertyError["AvailableMonth"] = "Please enter available from month";
    }
    if (rentProperty.AvailableYear === null) {
      isValid = false;
      rentPropertyError["AvailableYear"] = "Please enter available from year";
    }
    // if (rentProperty.noticePeriod === "") {
    //   isValid = false;
    //   rentPropertyError["noticePeriod"] = "Please enter Notice Period";
    // }
    if (rentProperty.Amenities.length < 1) {
      isValid = false;
      rentPropertyError["Amenities"] = "Please Select Aminities";
    }

    if (rentProperty.selectNosofBedrooms === null) {
      isValid = false;
      rentPropertyError["selectNosofBedrooms"] = "Please select No of Bedrooms";
    }
    if (rentProperty.selectNosofBalconies === null) {
      isValid = false;
      rentPropertyError["selectNosofBalconies"] =
        "Please select No of Balconies";
    }
    if (rentProperty.selectNosofBathrooms === null) {
      isValid = false;
      rentPropertyError["selectNosofBathrooms"] =
        "Please select No of Bathrooms";
    }
    if (rentProperty.totalFloor === null) {
      isValid = false;
      rentPropertyError["totalFloor"] = "Please select total floor";
    }
    if (rentProperty.selectFloor === null) {
      isValid = false;
      rentPropertyError["selectFloor"] = "Please select floor";
    }
    //For Features
    if (rentProperty.postedBy === "") {
      isValid = false;
      rentPropertyError["postedBy"] = "Please select posted by";
    }

    if (rentProperty.isBoundrywallMade === null) {
      isValid = false;
      rentPropertyError["isBoundrywallMade"] = "Please choose an option";
    }
    if (rentProperty.isGatedColony === null) {
      isValid = false;
      rentPropertyError["isGatedColony"] = "Please choose an option";
    }
    if (rentProperty.isCCTVinstalled === null) {
      isValid = false;
      rentPropertyError["isCCTVinstalled"] = "Please choose an option";
    }

    //For propertyImages
    if (rentProperty.exteriorView.length === 0) {
      isValid = false;
      rentPropertyError["exteriorView"] = "Please add Exterior View";
    }
    if (rentProperty?.livingRoomImage?.length === 0) {
      isValid = false;
      rentPropertyError["livingRoomImage"] = "Please add living Room Image";
    }

    // if (rentProperty.area === "") {
    //     isValid = false;
    //     rentPropertyError["area"] = "Please Enter Area";
    // }
    // if (rentProperty.price === "") {
    //     isValid = false;
    //     rentPropertyError["price"] = "Please Enter Price";
    // }
    // if (rentProperty.furnishedStatus === "") {
    //   isValid = false;
    //   rentPropertyError["furnishedStatus"] = "Please enter Furnished Status";
    // }

    // if (rentProperty.Availibilty === "") {
    //   isValid = false;
    //   rentPropertyError["Availibilty"] = "Please enter Availibilty";
    // }

    // if (rentProperty.bedRoomImage.length === 0) {
    //     isValid = false;
    //     rentPropertyError["bedRoomImage"] = "Please add bedRoom Image";
    // }
    // if (rentProperty.kitchenImage.length === 0) {
    //     isValid = false;
    //     rentPropertyError["kitchenImage"] = "Please add kitchen Image";
    // }
    // if (rentProperty.bathroomImage.length === 0) {
    //     isValid = false;
    //     rentPropertyError["bathroomImage"] = "Please add bathroom Image";
    // }
    // if (rentProperty.commonAreaImage.length === 0) {
    //     isValid = false;
    //     rentPropertyError["commonAreaImage"] = "Please add common Area Image";
    // }
    // if (rentProperty.balconyImage.length === 0) {
    //     isValid = false;
    //     rentPropertyError["balconyImage"] = "Please add balcony Image";
    // }
    // if (rentProperty.isBathroomAttched === null) {
    //   isValid = false;
    //   rentPropertyError["isBathroomAttched"] = "Please choose an option";
    // }
    // if (rentProperty.isBalconyAttched === null) {
    //   isValid = false;
    //   rentPropertyError["isBalconyAttched"] = "Please choose an option";
    // }
    // if (rentProperty.isFurnished === null) {
    //   isValid = false;
    //   rentPropertyError["isFurnished"] = "Please choose an option";
    // }
    // if (rentProperty.isCommonAreaAttched === null) {
    //   isValid = false;
    //   rentPropertyError["isCommonAreaAttched"] = "Please choose an option";
    // }

    setRentPropertyError(rentPropertyError);
    return isValid;
  };

  //handlesubmit
  const submitRentProperty = async (e) => {
    debugger
    e.preventDefault();
    if (validates()) {

      const formData = new FormData();
      rentProperty.exteriorViewB.forEach((file, index) => {
        formData.append(`exteriorView`, file);
      })
      rentProperty.livingRoomImageB.forEach((file, index) => {
        formData.append(`livingRoomImage`, file);
      })
      rentProperty.bedRoomImageB.forEach((file, index) => {
        formData.append(`bedRoomImage`, file);
      })
      rentProperty.kitchenImageB.forEach((file, index) => {
        formData.append(`kitchenImage`, file);
      })
      rentProperty.bathroomImageB.forEach((file, index) => {
        formData.append(`bathroomImage`, file);
      })
      rentProperty.commonAreaImageB.forEach((file, index) => {
        formData.append(`commonAreaImage`, file);
      })
      rentProperty.balconyImageB.forEach((file, index) => {
        formData.append(`balconyImage`, file);
      })
      const dataform = rentProperty;
      formData.append("details", JSON.stringify(dataform));
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      addRentProperty(formData, config)
        .then((res) => {
          if (res.data.status === 1) {
            Swal.fire({
              icon: "success",
              title: "Property Added Successfully",
              text: "Your property has been Added and all changes have been saved.",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK",
            })
            refreshData();
            navigate("/rentDetails");
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops! Something went wrong.",
              text: "We encountered an unexpected error. Please try again later.",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              // cancelButtonColor: '#d33',
              confirmButtonText: "ok",
            });
          }
        })
        .catch((err) => {
          console.log(err, "err in add rent property.");
        });
    }
  };

  //handleUpdate
  const UpdateRentProperty = async (e) => {
    e.preventDefault();
    if (validates()) {
      const formData = new FormData();
      rentProperty?.exteriorViewB && rentProperty?.exteriorViewB?.forEach((file, index) => {
        formData.append(`exteriorView`, file);
      })
      rentProperty?.livingRoomImageB && rentProperty?.livingRoomImageB.forEach((file, index) => {
        formData.append(`livingRoomImage`, file);
      })
      rentProperty.bedRoomImageB && rentProperty?.bedRoomImageB.forEach((file, index) => {
        formData.append(`bedRoomImage`, file);
      })
      rentProperty?.kitchenImageB && rentProperty?.kitchenImageB.forEach((file, index) => {
        formData.append(`kitchenImage`, file);
      })
      rentProperty?.bathroomImageB && rentProperty?.bathroomImageB.forEach((file, index) => {
        formData.append(`bathroomImage`, file)
      })
      rentProperty?.commonAreaImageB && rentProperty?.commonAreaImageB.forEach((file, index) => {
        formData.append(`commonAreaImage`, file);
      })
      rentProperty?.balconyImageB && rentProperty?.balconyImageB.forEach((file, index) => {
        formData.append(`balconyImage`, file);
      })
      const dataform = rentProperty;
      formData.append("details", JSON.stringify(dataform));

      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      updateRentProperty(formData, rentpropertyId, config)
        .then((res) => {
          if (res.data.status === 1) {
            Swal.fire({
              icon: "success",
              title: "Property Updated Successfully",
              text: "Your Property has been updated and all changes have been saved.",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK",
            })
            refreshData();
            navigate("/rentDetails");
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops! Something went wrong.",
              text: "We encountered an unexpected error. Please try again later.",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              // cancelButtonColor: '#d33',
              confirmButtonText: "ok",
            });
          }
        })
        .catch((err) => {
          console.log(err, "err in update rent property.");
        });
    }
  };

  const refreshData = () => {
    setRentProperty({ ...initialRentProperty });
    setPropertyOptions({ ...initialFeatureState });
    setRentPropertyError({ ...initialRentProperty });
  };
  

  return (
    <div>
      {/* {loading && <Loader />} */}
      {fileLoading && <FileLoader />}
      <AdminSidebar />

      <div className="container topNav-m">
        <div className="row">
          {/* <div className="col-md-3 vertical  p-card-content">
            <div class="steps clearfix">
              <ul role="tablist">
                <li
                  role="tab"
                  class="first current"
                  aria-disabled="false"
                  aria-selected="true"
                >
                  <a
                    id="steps-uid-0-t-0"
                    href="#steps-uid-0-h-0"
                    aria-controls="steps-uid-0-p-0"
                  >
                    <span class="current-info audible"> </span>
                    <div class="title">
                      <span class="step-number">1</span>
                      <span class="step-text">Property Details</span>
                    </div>
                  </a>
                </li>
                <li role="tab" class="disabled" aria-disabled="true">
                  <a
                    id="steps-uid-0-t-1"
                    href="#steps-uid-0-h-1"
                    aria-controls="steps-uid-0-p-1"
                  >
                    <div class="title">
                      <span class="step-number">2</span>
                      <span class="step-text">Property Features</span>
                    </div>
                  </a>
                </li>
                <li role="tab" class="disabled" aria-disabled="true">
                  <a
                    id="steps-uid-0-t-2"
                    href="#steps-uid-0-h-2"
                    aria-controls="steps-uid-0-p-2"
                  >
                    <div class="title">
                      <span class="step-number">3</span>
                      <span class="step-text">Property Images</span>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div> */}
          <div className="col-md-12">
            <form className="add-update-form">
              <div className="user__details ">

                <div className="row mt-4">
                  <h2 className="">Add Residential Properties</h2>
                  <div className="content-title pt-3 d-flex justify-content-between align-items-center">
                    <p className="desc">
                      Please enter your infomation and proceed to next step
                    </p>
                    <Button className="add-btn" onClick={() => { navigate("/rentDetails") }}><RemoveRedEyeOutlinedIcon className="me-2" />View Properties</Button>
                  </div>
                  <div className="col-md-6">
                    {/* <div className="input__box p-2">
                      <span className="details">Location*</span>

                      <FormControl variant="outlined" fullWidth size="small">
                        <Select
                          value={rentProperty.LocationId}
                          required
                          onChange={(e) => {
                            const selectedOption = locationData.find(option => option._id === e.target.value);
                            handleLocationChange("Location", { value: selectedOption._id, label: selectedOption.locationName });
                            setRentPropertyError({
                              ...rentPropertyError,
                              Location: "",
                            });
                          }}
                        >
                          {locationData.map((option) => (
                            <MenuItem key={option._id} value={option._id}>
                              {option.locationName}
                            </MenuItem>
                          ))}
                        </Select>
                        <div style={{ color: "red", fontSize: 13 }}>
                          {rentPropertyError.Location}
                        </div>
                      </FormControl>
                    </div> */}
                    <div className="input__box p-2">
                      <span className="details">Location*</span>
                      <Autocomplete
                        value={locationData.find(option => option._id === rentProperty.LocationId) || null}
                        onChange={(event, newValue) => {
                          const selectedOption = newValue || {};
                          handleLocationChange("Location", { value: selectedOption._id, label: selectedOption.locationName });
                          setRentPropertyError({
                            ...rentPropertyError,
                            Location: "",
                          });
                        }}
                        options={locationData}
                        getOptionLabel={(option) => option.locationName || ""}
                        isOptionEqualToValue={(option, value) => option._id === value._id}
                        renderInput={(params) => <TextField {...params} variant="outlined" size="small" required />}
                        PaperProps={{
                          style: {
                            maxHeight: 200,
                            width: 'auto',
                          },
                        }}
                        noOptionsText="No options"
                      />

                      <div style={{ color: "red", fontSize: 13 }}>
                        {rentPropertyError.Location}
                      </div>
                    </div>

                  </div>

                  <div className="col-md-6">
                    {/* <div className="input__box p-2">
                      <span className="details"> Sub Location*</span>

                      <FormControl variant="outlined" fullWidth size="small">
                        <Select
                          value={rentProperty.subLocationId}
                          onChange={(e) => {
                            const selectedOption = subLocationData.find(option => option._id === e.target.value);
                            handleLocationChange("subLocation", { value: selectedOption._id, label: selectedOption.subLocationName });
                            setRentPropertyError({
                              ...rentPropertyError,
                              subLocation: "",
                            });
                          }}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 200,
                                width: 'auto',
                                position: 'absolute',
                              },
                            },
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                            transformOrigin: {
                              vertical: 'top',
                              horizontal: 'left',
                            },
                          }}
                          required
                        >
                          {subLocationData.map((option) => (
                            <MenuItem key={option._id} value={option._id}>
                              {option.subLocationName}
                            </MenuItem>
                          ))}
                        </Select>
                        <div style={{ color: "red", fontSize: 13 }}>
                          {rentPropertyError.subLocation}
                        </div>
                      </FormControl>
                    </div> */}
                    <div className="input__box p-2">
                      <span className="details">Sub Location*</span>

                      <FormControl variant="outlined" fullWidth size="small">
                        <Autocomplete
                          value={subLocationData.find(option => option._id === rentProperty.subLocationId) || null}
                          onChange={(event, newValue) => {
                            if (newValue) {
                              handleLocationChange("subLocation", { value: newValue._id, label: newValue.subLocationName });
                              setRentPropertyError({
                                ...rentPropertyError,
                                subLocation: "",
                              });
                            }
                          }}
                          options={subLocationData}
                          getOptionLabel={(option) => option.subLocationName}
                          isOptionEqualToValue={(option, value) => option._id === value._id}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              // label="Sub Location"
                              size="small"
                              required
                            />
                          )}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 200,
                                width: 'auto',
                                position: 'absolute',
                              },
                            },
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                            transformOrigin: {
                              vertical: 'top',
                              horizontal: 'left',
                            },
                          }}
                        />
                        <div style={{ color: "red", fontSize: 13 }}>
                          {rentPropertyError.subLocation}
                        </div>
                      </FormControl>
                    </div>
                  </div>

                  {/* <div className="col-md-6">
                                <div className="input__box p-2">
                                    <span className="details">Area</span>

                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel>Select Area</InputLabel>

                                        <Select
                                            label="Area"
                                            value={rentProperty.area}
                                            onChange={(e) => { handleLocationChange("area", e.target.value); }}
                                            required
                                        >
                                            {areaOptions.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <div style={{ color: "red", fontSize: 13 }}> */}
                  {/* {formError.area} */}
                  {/* </div>
                                    </FormControl>
                                </div>
                            </div> */}

                  {/* <div className="col-md-6">
                                <div className="input__box p-2">
                                    <span className="details">Price</span>

                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel>Property Price</InputLabel>

                                        <TextField
                                            label="Property Price"
                                            type="Number"
                                            value={rentProperty.price}
                                            onChange={(e) => {
                                                setPrice(e.target.value);
                                                setformError({ ...formError, rent: "" });
                                                onChangeHandler("price", e.target.value);
                                            }}
                                            required
                                          renderValue={(selected) => selected.join(', ')} // Display selected items
                                        ></TextField>
                                        <div style={{ color: "red", fontSize: 13 }}>
                                            {formError.price}
                                        </div>
                                    </FormControl>
                                </div>
                            </div> */}

                  <div className="col-md-6">
                    {/* <div className="input__box p-2">
                      <span className="details">Property Type*</span>

                      <FormControl variant="outlined" fullWidth size="small">
                        <Select
                          value={rentProperty.propertyType}
                          onChange={(e) => {
                            onChangeHandler("propertyType", e.target.value);
                            setRentPropertyError({
                              ...rentPropertyError,
                              propertyType: "",
                            });
                          }}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 200,
                                width: 'auto',
                                position: 'absolute',
                              },
                            },
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                            transformOrigin: {
                              vertical: 'top',
                              horizontal: 'left',
                            },
                          }}
                          required
                        >
                          {propertyTypeOption.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                          
                        </Select>
                        <div style={{ color: "red", fontSize: 13 }}>
                          {rentPropertyError.propertyType}
                        </div>
                      </FormControl>
                    </div> */}
                    {/* <div className="input__box p-2">
                      <span className="details">Property Type*</span>
                      <FormControl variant="outlined" fullWidth size="small">
                        <Autocomplete
                          value={propertyTypeOption.find(option => option.value === rentProperty.propertyType) || null}
                          onChange={(event, newValue) => {
                            if (newValue) {
                              // Directly set the label of the selected property type
                              onChangeHandler("propertyType", newValue.label);
                              setRentPropertyError({
                                ...rentPropertyError,
                                propertyType: "",
                              });
                            }
                          }}
                          options={propertyTypeOption}
                          getOptionLabel={(option) => option.label}
                          isOptionEqualToValue={(option, value) => option.value === value.value}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              size="small"
                              required
                            />
                          )}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 200,
                                width: 'auto',
                                position: 'absolute',
                              },
                            },
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                            transformOrigin: {
                              vertical: 'top',
                              horizontal: 'left',
                            },
                          }}
                        />
                        <div style={{ color: "red", fontSize: 13 }}>
                          {rentPropertyError.propertyType}
                        </div>
                      </FormControl>
                    </div> */}

                    <div className="input__box p-2">
                      <span className="details">Property Type*</span>
                      <FormControl variant="outlined" fullWidth size="small">
                        <Autocomplete
                          value={
                            propertyTypeOption.find(
                              (option) => option.label === rentProperty.propertyType
                            ) || null
                          }
                          onChange={(event, newValue) => {
                            if (newValue) {
                              // Directly set the label of the selected property type
                              onChangeHandler("propertyType", newValue.label);
                              setRentPropertyError({
                                ...rentPropertyError,
                                propertyType: "",
                              });
                            }
                          }}
                          options={propertyTypeOption}
                          getOptionLabel={(option) => option.label}
                          isOptionEqualToValue={(option, value) =>
                            option.label === value.label
                          }
                          renderInput={(params) => (
                            <TextField {...params} variant="outlined" size="small" required />
                          )}
                          PaperProps={{
                            style: {
                              maxHeight: 200,
                              width: "auto",
                            },
                          }}
                        />
                        <div style={{ color: "red", fontSize: 13 }}>
                          {rentPropertyError.propertyType}
                        </div>
                      </FormControl>
                    </div>

                  </div>

                  <div className="col-md-6">
                    <div className="input__box p-2">
                      <span className="details">Property Status*</span>

                      <FormControl variant="outlined" fullWidth size="small">
                        <Select
                          value={rentProperty.PropertyStatus}
                          onChange={(e) => {
                            setRentPropertyError({
                              ...rentPropertyError,
                              PropertyStatus: "",
                            });
                            onChangeHandler("PropertyStatus", e.target.value);
                          }}
                          required
                        >
                          {PropertyStatusOption.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                        <div style={{ color: "red", fontSize: 13 }}>
                          {rentPropertyError.PropertyStatus}
                        </div>
                      </FormControl>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="input__box p-2">
                      <span className="details">Property Age</span>

                      <FormControl variant="outlined" fullWidth size="small">
                        <Select
                          value={rentProperty.age}
                          onChange={(e) => {
                            onChangeHandler("age", e.target.value);
                            setRentPropertyError({
                              ...rentPropertyError,
                              age: "",
                            });
                          }}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 200,
                                width: 'auto',
                                position: 'absolute',
                              },
                            },
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                            transformOrigin: {
                              vertical: 'top',
                              horizontal: 'left',
                            },
                          }}
                          required
                          disabled={
                            rentProperty.PropertyStatus ===
                            "UnderConstruction" ||
                            rentProperty.PropertyStatus === ""
                          }
                        >
                          {PropertyAgeOption.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                        <div style={{ color: "red", fontSize: 13 }}>
                          {rentPropertyError.age}
                        </div>
                      </FormControl>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input__box p-2">
                      <span className="details">Carpet Area*</span>
                      <FormControl variant="outlined" fullWidth size="small">
                        <TextField
                          type="Number"
                          size="small"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                Sq.Ft
                              </InputAdornment>
                            ),
                          }}
                          value={rentProperty.areainSqft}
                          onChange={(e) => {
                            setRentPropertyError({
                              ...rentPropertyError,
                              areainSqft: "",
                            });

                            onChangeHandler("areainSqft", e.target.value);
                          }}
                          required
                        ></TextField>
                        <div style={{ color: "red", fontSize: 13 }}>
                          {rentPropertyError.areainSqft}
                        </div>
                      </FormControl>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input__box p-2">
                      <span className="details">Facing*</span>
                      <FormControl variant="outlined" fullWidth size="small">
                        <Select
                          value={rentProperty.propertyDirection}
                          onChange={(e) => {
                            setRentPropertyError({
                              ...rentPropertyError,
                              propertyDirection: "",
                            });
                            onChangeHandler(
                              "propertyDirection",
                              e.target.value
                            );
                          }}
                          required
                        >
                          {PropertyDoorDirectionOption.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                        <div style={{ color: "red", fontSize: 13 }}>
                          {rentPropertyError.propertyDirection}
                        </div>
                      </FormControl>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="input__box p-2">
                      <span className="details">Purpose Of Property</span>

                      <FormControl variant="outlined" fullWidth size="small">
                        <Select
                          value={rentProperty.purposeOfProperty}
                          onChange={(e) => {
                            onChangeHandler("purposeOfProperty", e.target.value);
                          }}
                          required
                        >
                          {PurposeOfPropertyList.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  {rentProperty.purposeOfProperty === "Rent" || rentProperty.purposeOfProperty === "RentHeavyDeposite" ?
                    <>
                      <div className="col-md-6">
                        {/* <div className="input__box p-2">
                          <span className="details">Security Deposit*</span>

                          <FormControl variant="outlined" fullWidth size="small">
                            <TextField
                              type="Number"
                              size="small"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    ₹
                                  </InputAdornment>
                                ),
                              }}
                              value={rentProperty.SecurityDeposit}
                              onChange={(e) => {
                                setRentPropertyError({
                                  ...rentPropertyError,
                                  SecurityDeposit: "",
                                });
                                onChangeHandler("SecurityDeposit", e.target.value);
                              }}
                              required
                            ></TextField>
                            <div style={{ color: "red", fontSize: 13 }}>
                              {rentPropertyError.SecurityDeposit}
                            </div>
                          </FormControl>
                        </div> */}
                        <div className="input__box p-2">
                          <span className="details">Security Deposit*</span>
                          <FormControl variant="outlined" fullWidth size="small">
                            <TextField
                              type="text"
                              size="small"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    ₹
                                  </InputAdornment>
                                ),
                                inputMode: 'numeric',
                                pattern: "[0-9]*"
                              }}
                              value={rentProperty.SecurityDeposit}
                              onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d*$/.test(value)) {
                                  if (value === "" || parseInt(value) <= 1000000) {
                                    setRentPropertyError({
                                      ...rentPropertyError,
                                      SecurityDeposit: "",
                                    });
                                    onChangeHandler("SecurityDeposit", value);
                                  } else {
                                    setRentPropertyError({
                                      ...rentPropertyError,
                                      SecurityDeposit: "Security Deposit cannot exceed ₹10,00,000",
                                    });
                                  }
                                }
                              }}
                              required
                            />
                            <div style={{ color: "red", fontSize: 13 }}>
                              {rentPropertyError.SecurityDeposit}
                            </div>
                          </FormControl>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input__box p-2">
                          <span className="details">Property Rent*</span>

                          <FormControl variant="outlined" fullWidth size="small">
                            {/* <TextField
                              type="Number"
                              size="small"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    ₹
                                  </InputAdornment>
                                ),
                              }}
                              value={rentProperty.PropertyRent}
                              onChange={(e) => {
                                setRentPropertyError({
                                  ...rentPropertyError,
                                  PropertyRent: "",
                                });
                                onChangeHandler("PropertyRent", e.target.value);
                              }}
                              required
                            ></TextField> */}
                             <TextField
                              type="text"
                              size="small"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    ₹
                                  </InputAdornment>
                                ),
                                inputMode: 'numeric',
                                pattern: "[0-9]*"
                              }}
                              value={rentProperty.PropertyRent}
                              onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d*$/.test(value)) {
                                  if (value === "" || parseInt(value) <=500000) {
                                    setRentPropertyError({
                                      ...rentPropertyError,
                                      PropertyRent: "",
                                    });
                                    onChangeHandler("PropertyRent", value);
                                  } else {
                                    setRentPropertyError({
                                      ...rentPropertyError,
                                      PropertyRent: "Property Rent cannot exceed ₹5,00,000",
                                    });
                                  }
                                }
                              }}
                              required
                            />
                            <div style={{ color: "red", fontSize: 13 }}>
                              {rentPropertyError.PropertyRent}
                            </div>
                          </FormControl>
                        </div>
                      </div>
                    </>
                    :
                    <>
                      <div className="col-md-6">
                        {/* <div className="input__box p-2">
                          <span className="details">Property Cost*</span>
                          <FormControl variant="outlined" fullWidth size="small">
                            <TextField
                              type="Number"
                              size="small"
                              helperText="You may select multiple options from the list."
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    ₹
                                  </InputAdornment>
                                ),
                              }}
                              value={rentProperty.propertyCost}
                              onChange={(e) => {
                                setRentPropertyError({
                                  ...rentPropertyError,
                                  propertyCost: "",
                                });
                                onChangeHandler("propertyCost", e.target.value);
                              }}
                              required
                            ></TextField>
                            <div style={{ color: "red", fontSize: 13 }}>
                              {rentPropertyError.propertyCost}
                            </div>
                          </FormControl>
                        </div> */}
                        <div className="input__box p-2">
                          <span className="details">Property Cost*</span>
                          <FormControl variant="outlined" fullWidth size="small">
                            <TextField
                              type="text"
                              size="small"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    ₹
                                  </InputAdornment>
                                ),
                                inputMode: 'numeric',
                                pattern: "[0-9]*"
                              }}
                              value={rentProperty.propertyCost}
                              onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d*$/.test(value)) {
                                  if (value === "" || parseInt(value) <= 100000000) {
                                    setRentPropertyError({
                                      ...rentPropertyError,
                                      propertyCost: "",
                                    });
                                    onChangeHandler("propertyCost", value);
                                  } else {
                                    setRentPropertyError({
                                      ...rentPropertyError,
                                      propertyCost: "Property Cost cannot exceed ₹100,000,000",
                                    });
                                  }
                                }
                              }}
                              required
                            />
                            <div style={{ color: "red", fontSize: 13 }}>
                              {rentPropertyError.propertyCost}
                            </div>
                          </FormControl>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input__box p-2">
                          <span className="details">Booking Amount*</span>

                          <FormControl variant="outlined" fullWidth size="small">
                            {/* <TextField
                              type="Number"
                              size="small"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    ₹
                                  </InputAdornment>
                                ),
                              }}
                              value={rentProperty.bookingAmount}
                              onChange={(e) => {
                                setRentPropertyError({
                                  ...rentPropertyError,
                                  bookingAmount: "",
                                });
                                onChangeHandler("bookingAmount", e.target.value);
                              }}
                              required
                            ></TextField> */}
                            <TextField
                              type="text"
                              size="small"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    ₹
                                  </InputAdornment>
                                ),
                                inputMode: 'numeric',
                                pattern: "[0-9]*"
                              }}
                              value={rentProperty.bookingAmount}
                              onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d*$/.test(value)) {
                                  if (value === "" || parseInt(value) <= 5000000) {
                                    setRentPropertyError({
                                      ...rentPropertyError,
                                      bookingAmount: "",
                                    });
                                    onChangeHandler("bookingAmount", value);
                                  } else {
                                    setRentPropertyError({
                                      ...rentPropertyError,
                                      bookingAmount: "Booking Amount cannot exceed ₹50,00,000",
                                    });
                                  }
                                }
                              }}
                              required
                            />
                            <div style={{ color: "red", fontSize: 13 }}>
                              {rentPropertyError.bookingAmount}
                            </div>
                          </FormControl>
                        </div>
                      </div>
                    </>
                  }

                  <div className="col-md-6">
                    <div className="input__box p-2">
                      <span className="details">Furnished Status</span>

                      <FormControl variant="outlined" fullWidth size="small">
                        <Select
                          value={rentProperty.furnishedStatus}
                          onChange={(e) => {
                            onFurnishedChangeHandler(e.target.value);
                            setRentPropertyError({
                              ...rentPropertyError,
                              furnishedStatus: "",
                            });
                          }}
                          required
                        >
                          {FurnishedStatusOption.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                        <div style={{ color: "red", fontSize: 13 }}>
                          {rentPropertyError.furnishedStatus}
                        </div>
                      </FormControl>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <div className="input__box p-2">
                      <span className="details">Available From*</span>

                      <FormControl variant="outlined" fullWidth size="small">

                        <Autocomplete
                          value={MonthsOption.find(option => option.value === rentProperty.AvailableMonth) || null}
                          onChange={(event, newValue) => {
                            setRentPropertyError({
                              ...rentPropertyError,
                              AvailableMonth: "",
                            });
                            if (newValue) {
                              onChangeHandler("AvailableMonth", newValue.value);
                            }
                          }}
                          options={MonthsOption}
                          getOptionLabel={(option) => option.label}
                          isOptionEqualToValue={(option, value) => option.value === value.value}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              size="small"
                              required
                            />
                          )}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 100,
                                width: 'auto',
                                position: 'absolute',
                              },
                            },
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                            transformOrigin: {
                              vertical: 'top',
                              horizontal: 'left',
                            },
                          }}
                        />
                        <div style={{ color: "red", fontSize: 13 }}>
                          {rentPropertyError.AvailableMonth}
                        </div>
                      </FormControl>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="input__box p-2">
                      <span className="details">&nbsp;</span>

                      <FormControl variant="outlined" fullWidth size="small">
                        <Select
                          value={rentProperty.AvailableYear}
                          onChange={(e) => {
                            setRentPropertyError({
                              ...rentPropertyError,
                              AvailableYear: "",
                            });
                            onChangeHandler("AvailableYear", e.target.value);
                          }}
                          required
                        >
                          {YearsOption.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                        <div style={{ color: "red", fontSize: 13 }}>
                          {rentPropertyError.AvailableYear}
                        </div>
                      </FormControl>
                    </div>
                  </div>

                  {rentProperty.purposeOfProperty === "Buy" ? null :
                    <div className="col-md-3">
                      <div className="input__box p-2">
                        <span className="details">Notice Period*</span>

                        <FormControl variant="outlined" fullWidth size="small">
                          <Select
                            value={rentProperty.noticePeriod}
                            onChange={(e) => {
                              onChangeHandler("noticePeriod", e.target.value);
                              setRentPropertyError({
                                ...rentPropertyError,
                                noticePeriod: "",
                              });
                            }}
                            required
                          >
                            {NoticePeriodOption.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                          <div style={{ color: "red", fontSize: 13 }}>
                            {rentPropertyError.noticePeriod}
                          </div>
                        </FormControl>
                      </div>
                    </div>
                  }

                {rentProperty.purposeOfProperty === "Buy" ? null :
                  <div className="col-md-3">
                  <div className="input__box p-2">
                    <span className="details"> Heavy Deposite</span>
                
                    <FormControl component="fieldset">
                      <RadioGroup
                        value={rentProperty.heavyDeposite}
                        onChange={(e) => {
                          onChangeHandler("heavyDeposite", e.target.value);
                        }}
                        row 
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio size="small" />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio size="small" />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
                
                }
                    
                  {/* <div className="col-md-6">
                    <div className="input__box p-2">
                      <span className="details">Notice Period*</span>

                      <FormControl variant="outlined" fullWidth size="small">
                        <Select
                          value={rentProperty.noticePeriod}
                          onChange={(e) => {
                            onChangeHandler("noticePeriod", e.target.value);
                            setRentPropertyError({
                              ...rentPropertyError,
                              noticePeriod: "",
                            });
                          }}
                          required
                        >
                          {NoticePeriodOption.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                        <div style={{ color: "red", fontSize: 13 }}>
                          {rentPropertyError.noticePeriod}
                        </div>
                      </FormControl>
                    </div>
                  </div> */}

                  {/* <div className="col-md-6">
  <div className="input__box p-2">
    <span className="details">Amenities*</span>
    <FormControl variant="outlined" fullWidth size="small">
      <Autocomplete
        multiple
        value={rentProperty.Amenities || []}
        onChange={(event, newValue) => {
          const amenitiesLabels = [
            ...new Set([
              ...rentProperty.Amenities,
              ...newValue.filter((option) => option && option.label).map((option) => option.label),
            ]),
          ];

          onChangeHandler("Amenities", amenitiesLabels); 
          setRentPropertyError({
            ...rentPropertyError,
            Amenities: "",
          });
        }}
        options={amenitiesOption || []}
        getOptionLabel={(option) => option?.label || ""}
        isOptionEqualToValue={(option, value) => {
          return option?.value === value?.value;
        }}
        disableCloseOnSelect
        renderOption={(props, option, { selected }) => (
          <MenuItem {...props}>
            <Checkbox
              checked={selected}
              style={{ marginRight: 8 }}
            />
            {option?.label || "Unknown"}
          </MenuItem>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            required
          />
        )}
      />
      <div style={{ color: "red", fontSize: 13 }}>
        {rentPropertyError.Amenities}
      </div>
    </FormControl>
  </div>
</div> */}


                  <div className="col-md-6">
                    <div className="input__box p-2">
                      <span className="details">Amenities*</span>
                      <FormControl variant="outlined" fullWidth size="small">
                        <Autocomplete
                          multiple
                          value={amenitiesOption.filter(option =>
                            rentProperty.Amenities.includes(option.label)
                          )}
                          onChange={(event, newValue) => {
                            const selectedAmenities = newValue.map(option => option.label);

                            onChangeHandler("Amenities", selectedAmenities);
                            setRentPropertyError({
                              ...rentPropertyError,
                              Amenities: "",
                            });
                          }}
                          options={amenitiesOption}
                          getOptionLabel={(option) => option?.label || ""}
                          isOptionEqualToValue={(option, value) => {
                            return option?.value === value?.value;
                          }}
                          disableCloseOnSelect
                          renderOption={(props, option, { selected }) => (
                            <MenuItem {...props} key={option.value}>
                              <Checkbox
                                checked={selected}
                                style={{ marginRight: 8 }}
                              />
                              {option.label}
                            </MenuItem>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              size="small"
                              required
                            />
                          )}
                        />
                        <div style={{ color: "red", fontSize: 13 }}>
                          {rentPropertyError.Amenities}
                        </div>
                      </FormControl>
                    </div>
                  </div>


                  {/* <div className="col-md-6">
                  <div className="input__box p-2">
                      <span className="details">Amenities*</span>
                      <FormControl variant="outlined" fullWidth size="small">
                        <Autocomplete
                          multiple
                          value={rentProperty.Amenities || []}  // Ensure this is an array
                          onChange={(event, newValue) => {
                            onChangeHandler("Amenities", newValue);  // Update with the array of selected options
                            setRentPropertyError({
                              ...rentPropertyError,
                              Amenities: "",
                            });
                          }}
                          options={amenitiesOption}
                          getOptionLabel={(option) => option.label}
                          isOptionEqualToValue={(option, value) => option.value === value.value}
                          disableCloseOnSelect  // This keeps the dropdown open after selection
                          renderOption={(props, option, { selected }) => (
                            <MenuItem {...props}>
                              <Checkbox
                                checked={selected}
                                style={{ marginRight: 8 }}
                              />
                              {option.label}
                            </MenuItem>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              size="small"
                              required
                            />
                          )}
                        />
                        <div style={{ color: "red", fontSize: 13 }}>
                          {rentPropertyError.Amenities}
                        </div>
                      </FormControl>
                    </div>
                  </div> */}

                  <div className="col-md-12">
                    <div className="input__box p-2">
                      <span className="details">Select Bedrooms*</span>
                      <div className="wall-selector">
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((value) => (
                          <div
                            name="selectNosofBedrooms"
                            key={value}
                            className={`brick ${rentProperty.selectNosofBedrooms === value
                              ? "selected2"
                              : ""
                              }`}
                            onClick={() => {
                              setRentPropertyError({
                                ...rentPropertyError,
                                selectNosofBedrooms: "",
                              });
                              onChangeHandler("selectNosofBedrooms", value);
                            }}
                          >
                            {value}
                          </div>
                        ))}
                      </div>
                      <div style={{ color: "red", fontSize: 13 }}>
                        {rentPropertyError.selectNosofBedrooms}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input__box p-2">
                      <span className="details">Select Balconies*</span>
                      <div className="wall-selector">
                        {[0, 1, 2, 3, 4, 5].map((value) => (
                          <div
                            key={value}
                            name="selectNosofBalconies"
                            className={`brick ${rentProperty.selectNosofBalconies === value
                              ? "selected2"
                              : ""
                              }`}
                            onClick={() => {
                              onChangeHandler("selectNosofBalconies", value);
                              setRentPropertyError({
                                ...rentPropertyError,
                                selectNosofBalconies: "",
                              });
                            }}
                          >
                            {value}
                          </div>
                        ))}
                      </div>
                      <div style={{ color: "red", fontSize: 13 }}>
                        {rentPropertyError.selectNosofBalconies}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input__box p-2">
                      <span className="details">Select Bathrooms*</span>
                      <div className="wall-selector">
                        {[1, 2, 3].map((value) => (
                          <div
                            name="selectNosofBathrooms"
                            key={value}
                            className={`brick ${rentProperty.selectNosofBathrooms === value
                              ? "selected2"
                              : ""
                              }`}
                            onClick={() => {
                              onChangeHandler("selectNosofBathrooms", value);
                              setRentPropertyError({
                                ...rentPropertyError,
                                selectNosofBathrooms: "",
                              });
                            }}
                          >
                            {value}
                          </div>
                        ))}
                      </div>
                      <div style={{ color: "red", fontSize: 13 }}>
                        {rentPropertyError.selectNosofBathrooms}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 pb-3">
                    <div className="input__box">
                      <span className="details">Total Floor*</span>

                      <div className="d-flex">
                        <IconButton
                          disabled={currentTotalPage === 1}
                          onClick={() =>
                            handlePageChange(currentTotalPage - 1, "total")
                          }
                        >
                          <ArrowBackIosNewIcon />
                        </IconButton>
                        <div className="wall-selector">
                          {renderTotalBricks()}
                        </div>

                        <IconButton
                          disabled={currentTotalPage * itemsPerPage >= 50}
                          onClick={() =>
                            handlePageChange(currentTotalPage + 1, "total")
                          }
                        >
                          <ArrowForwardIosIcon />
                        </IconButton>
                      </div>
                      <div style={{ color: "red", fontSize: 13 }}>
                        {rentPropertyError.totalFloor}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="input__box ">
                      <span className="details">Select Floor*</span>

                      <div className="d-flex">
                        <IconButton
                          disabled={currentSelectPage === 1}
                          onClick={() =>
                            handlePageChange(currentSelectPage - 1, "select")
                          }
                        >
                          <ArrowBackIosNewIcon />
                        </IconButton>
                        <div className="wall-selector">
                          {renderSelectedBricks()}
                        </div>

                        <IconButton
                          disabled={currentSelectPage * itemsPerPage >= 60}
                          onClick={() =>
                            handlePageChange(currentSelectPage + 1, "select")
                          }
                        >
                          <ArrowForwardIosIcon />
                        </IconButton>
                      </div>
                      <div style={{ color: "red", fontSize: 13 }}>
                        {rentPropertyError.selectFloor}
                      </div>
                    </div>
                  </div>

                  <div className="row ">
                    <div className="col user-details mt-4">
                      <h2> Property Features</h2>
                      <p class="desc"></p>
                    </div>
                  </div>

                  {/* <div className="col-md-6">
                    <div className="gender__details">
                      <span
                        className="gender__title"
                        style={{ minWidth: "40%" }}
                      >
                        Posted By*
                      </span>
                      <span style={{ width: "60%" }}>
                        <div
                          className="form-check"
                          style={{
                            display: "inline-block",
                            marginRight: "52px",
                          }}
                        >
                          <input
                            className="form-check-input"
                            type="radio"
                            name="postedby"
                            id="postedby1"
                            value="Owner"
                            checked={rentProperty.postedBy === "Owner"}
                            onChange={(e) => {
                              setRentPropertyError({
                                ...rentPropertyError,
                                postedBy: "",
                              });
                              onChangeHandler("postedBy", e.target.value);
                            }}
                          // checked
                          />
                          <label
                            className="form-check-label"
                            htmlFor="postedby1"
                          >
                            Owner
                          </label>
                        </div>
                        <div
                          className="form-check"
                          style={{ display: "inline-block" }}
                        >
                          <input
                            className="form-check-input"
                            type="radio"
                            name="postedby"
                            id="postedby2"
                            value="Agent"
                            checked={rentProperty.postedBy === "Agent"}
                            onChange={(e) => {
                              onChangeHandler("postedBy", e.target.value);
                              setRentPropertyError({
                                ...rentPropertyError,
                                postedBy: "",
                              });
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="postedby2"
                          >
                            Agent
                          </label>
                        </div>
                        <div style={{ color: "red", fontSize: 13 }}>
                          {rentPropertyError.postedBy}
                        </div>
                      </span>
                    </div>
                  </div> */}

                  {/* <div className="col-md-6">
                    <div className="gender__details">
                      <span
                        className="gender__title"
                        style={{ minWidth: "40%" }}
                      >
                        Posted By*
                      </span>
                      <div
                        className="form-check"
                        style={{
                          display: "inline-block",
                          marginRight: "52px",
                        }}
                      >
                        {Auth?.user?.userData?.role}
                      </div>
                      <input
                        type="hidden"
                        name="role"
                        onChange={(e) =>
                          onChangeHandler({ role: e.target.value })
                        }
                      />
                    </div>
                  </div> */}


                  {/* <div className="col-md-6">
                <div className="gender__details">
                  <span className="gender__title" style={{ minWidth: "40%" }}>
                    Property Availability
                  </span>
                  <span style={{ width: "55%" }}>
                    <div
                      className="form-check"
                      style={{ display: "inline-block", marginRight: "30px" }}
                    >
                      <input
                        className="form-check-input"
                        type="radio"
                        name="propertystatus"
                        id="propertystatus1"
                        value="Available"
                        checked={rentProperty.Availibilty === "Available"}
                        onChange={(e) => {
                          onChangeHandler("Availibilty", e.target.value);
                          setRentPropertyError({
                            ...rentPropertyError,
                            Availibilty: "",
                          });
                        }}
                        // checked
                      />
                      <label
                        className="form-check-label"
                        htmlFor="propertystatus1"
                      >
                        Available
                      </label>
                    </div>
                    <div
                      className="form-check"
                      style={{ display: "inline-block" }}
                    >
                      <input
                        className="form-check-input"
                        type="radio"
                        name="propertystatus"
                        id="propertystatus2"
                        value="Not Available"
                        checked={rentProperty.Availibilty === "Not Available"}
                        onChange={(e) => {
                          onChangeHandler("Availibilty", e.target.value);
                          setRentPropertyError({
                            ...rentPropertyError,
                            Availibilty: "",
                          });
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="propertystatus2"
                      >
                        Not Available
                      </label>
                    </div>
                    <div style={{ color: "red", fontSize: 13 }}>
                      {rentPropertyError.Availibilty}
                    </div>
                  </span>
                </div>
              </div> */}

                  {Object.keys(propertyOptions).map((property) => (
                    <div className="col-md-6 " key={property}>
                      <div className="gender__details">
                        <span
                          className="gender__title"
                          style={{ minWidth: "40%" }}
                        >
                          {propertyOptionsMapping[property]}*
                        </span>
                        <span style={{ width: "45%" }}>
                          <div
                            className="form-check"
                            style={{
                              display: "inline-block",
                              marginRight: "77px",
                            }}
                          >
                            <input
                              className="form-check-input"
                              type="radio"
                              name={property}
                              id={`${property}1`}
                              checked={propertyOptions[property] === true}
                              onChange={() => {
                                handlePropertyFeatureChange(property, true);
                                setRentPropertyError({
                                  ...rentPropertyError,
                                  [property]: "",
                                });
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`${property}1`}
                            >
                              YES
                            </label>
                          </div>
                          <div
                            className="form-check"
                            style={{ display: "inline-block" }}
                          >
                            <input
                              className="form-check-input"
                              type="radio"
                              name={property}
                              id={`${property}2`}
                              checked={propertyOptions[property] === false}
                              onChange={() => {
                                handlePropertyFeatureChange(property, false);
                                setRentPropertyError({
                                  ...rentPropertyError,
                                  [property]: "",
                                });
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`${property}2`}
                            >
                              No
                            </label>
                          </div>
                          <div style={{ color: "red", fontSize: 13 }}>
                            {rentPropertyError[property]}
                          </div>
                        </span>
                      </div>
                    </div>
                  ))}

                  <div className="row">
                    <div className="col user__details mt-4">
                      <h2>Property Images</h2>
                      <p class="desc"></p>
                    </div>
                  </div>

                  {propertyImages.map((property, index) => (
                    <div className="col-md-6" key={index}>
                      <div className="input__box ">
                        <span
                          className="details "
                          style={{
                            color:
                              rentProperty[property.name]?.length > 0
                                ? "green"
                                : "gray",
                          }}
                        >
                          {property.label}
                        </span>

                        <FormControl variant="outlined" fullWidth size="small">
                          <input
                            id={property.name}
                            accept="image/*"
                            type="file"
                            onChange={(event) => {
                              handleImageChange(event, property.name);
                              setRentPropertyError({
                                ...rentPropertyError,
                                [property.name]: "",

                              });
                            }}
                            name={rentProperty[property.name]}
                            ref={property.ref}

                          />
                          <div className="upload-img">
                            {rentProperty[property.name]?.length > 0 &&
                              rentProperty[property.name].map(
                                (image, imageIndex) => (

                                  <div className="files-list">
                                    <a
                                      href={image.name}
                                      target="_blank"
                                      rel="noopener noreferrer"

                                    >
                                      <img
                                        src={image.name}

                                        alt="Property"
                                      />
                                    </a>
                                    <DeleteOutlineIcon
                                      className="delete-button "
                                      onClick={() => {

                                        removeImage(image, property.name, imageIndex, property.ref);

                                      }}
                                    />
                                  </div>

                                )
                              )}
                          </div>
                        </FormControl>
                        <div style={{ color: "red", fontSize: 13 }}>
                          {rentPropertyError[property.name]}
                        </div>
                      </div>
                    </div>
                  ))}

                  <h2 className="mt-4">Property Description</h2>
                  <p className="desc"></p>
                  <TextField
                    id="outlined-multiline-flexible"
                    label="Description"
                    multiline
                    maxRows={4}
                    name="DescriptionAboutResidentialProperty"
                    value={rentProperty.DescriptionAboutResidentialProperty}
                    onChange={(e) => {
                      onChangeHandler(
                        "DescriptionAboutResidentialProperty",
                        e.target.value
                      );
                      setRentPropertyError({
                        ...rentPropertyError,
                        DescriptionAboutResidentialProperty: "",
                      });
                    }}

                  />
                </div>
                <div className="form-group d-flex justify-content-center mt-4 gap-3">
                  {rentpropertyId === 0 ? (
                    <div className=" d-flex  gap-3">

                      <button
                        type="submit"
                        value="Submit"
                        className="btn-red"
                        onClick={submitRentProperty}
                      >
                        Submit
                      </button>

                      <button
                        value="Clear"
                        className="btn-grey"
                        onClick={refreshData}
                      >
                        Clear
                      </button>

                    </div>
                  ) : (
                    <button
                      type="submit"
                      value="Update"
                      className="btn-red"
                      onClick={UpdateRentProperty}
                    >
                      Update
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewRent;
