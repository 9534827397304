import React, { useEffect, useState } from "react";
import './customerreview.css';
import { useNavigate } from "react-router-dom";
//@mui packages
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import { TabContext, TabList } from "@mui/lab";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
//react packages
import { MdOutlineCabin } from "react-icons/md";
import swal from "sweetalert";
//icons...
import { FaCommentDots } from "react-icons/fa";
import SquareFootOutlinedIcon from "@mui/icons-material/SquareFootOutlined";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import ChairOutlinedIcon from "@mui/icons-material/ChairOutlined";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import CorporateFareOutlinedIcon from "@mui/icons-material/CorporateFareOutlined";
import EmojiTransportationOutlinedIcon from "@mui/icons-material/EmojiTransportationOutlined";
// custom components
import FilterProperty from "../../Admin/FilterProperty";
import Topnavigation from "../../../Components/Navigation/TopNavigation";
import AdminSidebar from "../../../Components/Sidebar/AdminSidebar";
import { settings } from "../../../Config/Settings";
//Api
import { getAllReviewbyUserId } from "../../../Api/ReviewAPI";
//Auth
import Auth from "../../../Config/helper/Auth";
import PageviewIcon from '@mui/icons-material/Pageview';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Tooltip from '@mui/material/Tooltip';
import { Container } from "react-bootstrap";


const ReviewProperty = () => {
  const navigate = useNavigate()
  let userId = Auth?.getUserDetails();

  const [reviewData, setReviewData] = useState([]);
  const [residentialReviews, setResidentialReviews] = useState([])
  const [commercialReviews, setCommercialReviews] = useState([])
  const [expandedCommentIndex, setExpandedCommentIndex] = useState(null);
  const [value, setValue] = useState("1");

  useEffect(() => {
    getAllReviews(value)
  }, []);

  const getAllReviews = (newvalue) => {
    const type = newvalue === "1" ? "Residential" :"Commercial"
    getAllReviewbyUserId(userId._id, type)
      .then((res) => {
        if (res.status === 200) {
          let reviewData = res?.data?.reviewData;
            setReviewData(reviewData);
        } else if (res.status === 400) {
          swal("data not found");
          setReviewData([]);
        }
      })
      .catch((err) => {
        console.log(err, "error in get Review Property");
      });
  }

  // const getAllReviews = ()=>{
  //   getAllReviewbyUserId(userId._id)
  //     .then((res) => {
  //       if (res.status === 200) {
  //         debugger;
  //         let reviewData = res?.data?.reviewData;
  //         setReviewData(reviewData);
  //       } else if (res.data.status === 400) {
  //         swal("data not found");
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err, "error in get Review Property");
  //     });
  // }

  // const formatCash = (n) => {
  //   if (n < 1e3) return n;
  //   if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
  //   if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
  //   if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
  //   if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
  // };
  const formatCash = (n) => {
    if (n < 1e5) return n;
    if (n >= 1e5 && n < 1e7) return +(n / 1e5).toFixed(1) + "L";
    if (n >= 1e7) return +(n / 1e7).toFixed(1) + "Cr";
  };

  const renderStars = (rating) => {
    const stars = [];
    for (let i = 0; i < rating; i++) {
      stars.push(<span key={i} className="star">&#9733;</span>);
    }
    return stars;
  };

  const handleExpandClick = (index) => {
    setExpandedCommentIndex(expandedCommentIndex === index ? null : index);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    getAllReviews(newValue)
    // GetAllRentProperty();
  };

  return (
    <>
      <AdminSidebar />
      <div className="container topNav-m">
        <div className="text-center p-2">
          <h5>Review Properties</h5>
        </div>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              centered
            >
              <Tab label="Residential" value="1" />
              <Tab label="Commercial" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1" className="d-flex justify-content-center">
            <Container>
            <FilterProperty
                userId={userId._id}
                propertyData={reviewData}
                propertyType={"Residential"}
              // isVerified={isVerifiedDatas}
              />
              <div className="review-grid">
                {reviewData &&
                  reviewData.map((listData, ind) => {

                    return (
                      <>
                        <div class="review-card" key={ind}>
                          <img src={`${listData?.reviews[0]?.propertyData?.exteriorView[0]?.name}`} alt="Property" className="property-image" />
                          <h2 className="property-name">{listData?.reviews[0]?.propertyData?.propertyType}</h2>
                          <div className="review-header">
                            <div className="avatar">
                              {listData?.reviews[0]?.UserId?.profileImage[0]?.name ? (
                                <img
                                  src={`${listData?.reviews[0]?.UserId?.profileImage[0]?.name}`}
                                  alt="User Profile"
                                  className="avatar"
                                  style={{
                                    width: '35px',
                                    height: '35px',
                                    borderRadius: '50%',
                                    backgroundColor: '#BD1550',
                                    marginRight: '10px',
                                  }}
                                />
                              ) : (
                                <img
                                  src="https://api.equireal.co.in/default_profile_image.png"
                                  alt="Default Profile"
                                  className="avatar"
                                  style={{
                                    width: '35px',
                                    height: '35px',
                                    borderRadius: '50%',
                                    backgroundColor: '#BD1550',
                                    marginRight: '10px',
                                  }}
                                />
                              )}
                            </div>

                            <div>
                              <h3 className="name">{listData?.reviews[0]?.UserId?.fullName}</h3>
                              <div className="rating-r">{renderStars(listData?.reviews[0]?.Rating)} {listData?.reviews[0]?.Rating}/5</div>
                            </div>
                          </div>
                          <p className="comment">
                            <FaCommentDots />  {listData?.reviews[0]?.Comment}
                          </p>
                          {/* <Tooltip title="Total Reviews" arrow>
                        <span className="total-reviews"> ({listData?.reviews?.length})</span>
                      </Tooltip> */}
                          {/* <button
                      
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                        }}
                        onClick={() =>
                          navigate(
                            `/MorePropertyReview/${listData?.PropertyId}`,
                            {
                              state: {
                                yourData: listData,
                              },
                            }
                          )
                        }
                      >
                        <Tooltip title="View More Reviews" arrow>
                        <span class="hover-underline-animation"> Read AllReview </span>
                        </Tooltip>
                      </button> */}
                          <div className="d-flex justify-content-end">
                            <button
                              className="cta"
                              onClick={() =>
                                navigate(
                                  `/MorePropertyReview/${listData?.PropertyId}`,
                                  {
                                    state: {
                                      yourData: listData,
                                      yourDataType: listData?.reviews[0]?.propertyData?.propertyType,
                                    },
                                  }
                                )
                              }
                              style={{ alignItems: 'right' }}
                            >
                              <span className="hover-underline-animation" style={{ fontSize: '10px' }}> All Reviews </span>
                              <svg
                                id="arrow-horizontal"
                                xmlns="http://www.w3.org/2000/svg"
                                width="30"
                                height="10"
                                viewBox="0 0 46 16"
                              >
                                <path
                                  id="Path_10"
                                  data-name="Path 10"
                                  d="M8,0,6.545,1.455l5.506,5.506H-30V9.039H12.052L6.545,14.545,8,16l8-8Z"
                                  transform="translate(30)"
                                ></path>
                              </svg>
                            </button>
                          </div>
                        </div>
                      </>
                    )
                  }
                  )}
              </div>
            </Container>
          </TabPanel>

          <TabPanel value="2" className="d-flex justify-content-center">
            <Container>
              <FilterProperty
                userId={userId._id}
                propertyData={reviewData}
                propertyType={"Commercial"}
              // isVerified={isVerifiedDatas}
              />
              <div className="review-grid">
                {reviewData.length === 0 ? <p className="errormsg">No data found</p>:
                  reviewData.map((listData, ind) => {

                    return (
                      <>
                        <div class="review-card" key={ind}>
                          <img src={`${listData?.reviews[0]?.propertyData?.exteriorView[0]?.name}`} alt="Property" className="property-image" />
                          <h2 className="property-name">{listData?.reviews[0]?.propertyData?.propertyType}</h2>
                          <div className="review-header">
                            <div className="avatar">
                              {listData?.reviews[0]?.UserId?.profileImage[0]?.name ? (
                                <img
                                  src={`${listData?.reviews[0]?.UserId?.profileImage[0]?.name}`}
                                  alt="User Profile"
                                  className="avatar"
                                  style={{
                                    width: '35px',
                                    height: '35px',
                                    borderRadius: '50%',
                                    backgroundColor: '#BD1550',
                                    marginRight: '10px',
                                  }}
                                />
                              ) : (
                                <img
                                  src="https://api.equireal.co.in/default_profile_image.png"
                                  alt="Default Profile"
                                  className="avatar"
                                  style={{
                                    width: '35px',
                                    height: '35px',
                                    borderRadius: '50%',
                                    backgroundColor: '#BD1550',
                                    marginRight: '10px',
                                  }}
                                />
                              )}
                            </div>

                            <div>
                              <h3 className="name">{listData?.reviews[0]?.UserId?.fullName}</h3>
                              <div className="rating-r">{renderStars(listData?.reviews[0]?.Rating)} {listData?.reviews[0]?.Rating}/5</div>
                            </div>
                          </div>
                          <p className="comment">
                            <FaCommentDots />  {listData?.reviews[0]?.Comment}
                          </p>
                          {/* <Tooltip title="Total Reviews" arrow>
                        <span className="total-reviews"> ({listData?.reviews?.length})</span>
                      </Tooltip> */}
                          {/* <button
                      
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                        }}
                        onClick={() =>
                          navigate(
                            `/MorePropertyReview/${listData?.PropertyId}`,
                            {
                              state: {
                                yourData: listData,
                              },
                            }
                          )
                        }
                      >
                        <Tooltip title="View More Reviews" arrow>
                        <span class="hover-underline-animation"> Read AllReview </span>
                        </Tooltip>
                      </button> */}
                          <div className="d-flex justify-content-end">
                            <button
                              className="cta"
                              onClick={() =>
                                navigate(
                                  `/MorePropertyReview/${listData?.PropertyId}`,
                                  {
                                    state: {
                                      yourData: listData,
                                      yourDataType: listData?.reviews[0]?.propertyData?.propertyType,
                                    },
                                  }
                                )
                              }
                              style={{ alignItems: 'right' }}
                            >
                              <span className="hover-underline-animation" style={{ fontSize: '10px' }}> All Reviews </span>
                              <svg
                                id="arrow-horizontal"
                                xmlns="http://www.w3.org/2000/svg"
                                width="30"
                                height="10"
                                viewBox="0 0 46 16"
                              >
                                <path
                                  id="Path_10"
                                  data-name="Path 10"
                                  d="M8,0,6.545,1.455l5.506,5.506H-30V9.039H12.052L6.545,14.545,8,16l8-8Z"
                                  transform="translate(30)"
                                ></path>
                              </svg>
                            </button>
                          </div>
                        </div>
                      </>
                    )
                  }
                  )}
              </div>
            </Container>
          </TabPanel>
        </TabContext>

      </div>
    </>
    //   <>
    //   <AdminSidebar />
    //   <div className="container topNav-m">
    //     <div className="text-center p-2">
    //       <h5>Review Properties</h5>
    //     </div>
    //     <div className="review-grid">
    //       {reviewData &&
    //         reviewData.map((listData, ind) => {
    //           const isExpanded = expandedCommentIndex === ind;
    //           const commentToDisplay = isExpanded ? listData.reviews : listData.reviews.slice(0, 1);

    //           return (
    //             <div className="review-card" key={ind}>
    //               <img src={`${listData?.reviews[0]?.propertyData?.exteriorView[0]?.name}`} alt="Property" className="property-image" />
    //               <h2 className="property-name">{listData?.reviews[0]?.propertyData?.propertyType}</h2>
    //               <div className="review-header">
    //                 <div className="avatar">
    //                   {listData?.reviews[0]?.UserId?.profileImage[0]?.name ? (
    //                     <img
    //                       src={`${listData?.reviews[0]?.UserId?.profileImage[0]?.name}`}
    //                       alt="User Profile"
    //                       className="avatar"
    //                       style={{
    //                         width: '35px',
    //                         height: '35px',
    //                         borderRadius: '50%',
    //                         backgroundColor: '#BD1550',
    //                         marginRight: '10px',
    //                       }}
    //                     />
    //                   ) : (
    //                     <img
    //                       src="https://api.equireal.co.in/default_profile_image.png"
    //                       alt="Default Profile"
    //                       className="avatar"
    //                       style={{
    //                         width: '35px',
    //                         height: '35px',
    //                         borderRadius: '50%',
    //                         backgroundColor: '#BD1550',
    //                         marginRight: '10px',
    //                       }}
    //                     />
    //                   )}
    //                 </div>

    //                 <div>
    //                   <h3 className="name">{listData?.reviews[0]?.UserId?.fullName}</h3>
    //                   <div className="rating-r">{renderStars(listData?.reviews[0]?.Rating)} {listData?.reviews[0]?.Rating}/5</div>
    //                 </div>
    //               </div>
    //               {commentToDisplay.map((review, idx) => (
    //                 <p key={idx} className="comment">{review.Comment}</p>
    //               ))}
    //               <div className="expand-icon" onClick={() => handleExpandClick(ind)}>
    //                 {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
    //               </div>
    //             </div>
    //           );
    //         })}
    //     </div>
    //   </div>
    // </>
  );
};

export default ReviewProperty;

{/* <div className="mt-4 d-flex justify-content-center">
        <Paper
          sx={{
            width: "70%",
            marginTop: "10rem",
            overflow: "hidden",
            justifyContent: "center",
          }}
        >
          <div className="text-center p-2">
            <h5>Review Properties</h5>
          </div>
          {reviewData &&
            reviewData.map((listData, ind) => {
              console.log(listData, "listData");
              const capitalizeFirstLetter = (string) => {
                return string.replace(/\b\w/g, function (char) {
                  return char.toUpperCase();
                });
              };
              return (
                <>
                  <div

                    className="p-card-content"
                    onClick={() =>
                      navigate(
                        `/fulltab/${capitalizeFirstLetter(listData.propertyData.type)}/${listData.propertyData._id}`,
                        {
                          state: {
                            yourData: listData.propertyData,
                            propertyType: listData.propertyType,
                          },
                        }
                      )
                    }
                  >
                    <div className="p-card">
                      <div className="p-img">
                        <img
                          src={`${listData?.propertyData?.exteriorView[0]?.name}`}
                          alt="Property"
                        />
                      </div>

                      <div className="p-info">
                        <div className="p-tle">
                          <div>
                            <h4>All Properties for Rent</h4>
                          </div>
                        </div>

                        <div className="pr-info">
                          <ul>
                            <li>
                              <ChairOutlinedIcon />
                              <h6>{listData?.propertyData?.furnishedStatus}</h6>
                            </li>
                            <li>
                              <MdOutlineCabin />
                              <h6> {listData?.propertyData?.propertyType}</h6>
                            </li>
                            <li>
                              <PeopleOutlineOutlinedIcon />
                              <h6>{listData?.propertyData?.postedBy}</h6>
                            </li>

                            <li>
                              <CorporateFareOutlinedIcon />
                              <h6>
                                {" "}
                                {listData?.propertyData?.selectFloor} of {listData?.propertyData?.totalFloor}
                                th Floor
                              </h6>
                            </li>
                            <li>
                              <EmojiTransportationOutlinedIcon />
                              <h6> {listData?.propertyData?.Location}</h6>
                            </li>
                            <li>
                              <SquareFootOutlinedIcon />
                              <h6> {listData?.propertyData?.areainSqft} Sq.Ft</h6>
                            </li>
                          </ul>
                        </div>
                        <div className="d-flex justify-content-end">
                          <button
                            class="cta"
                            onClick={() =>
                              navigate(
                                `/fulltab/${capitalizeFirstLetter(listData.propertyData.type)}/${listData.propertyData._id}`,
                                {
                                  state: {
                                    yourData: listData.propertyData,
                                    propertyType: listData.propertyType,
                                  },
                                }
                              )
                            }
                          >
                            <span class="hover-underline-animation"> View More </span>
                            <svg
                              id="arrow-horizontal"
                              xmlns="http://www.w3.org/2000/svg"
                              width="30"
                              height="10"
                              viewBox="0 0 46 16"
                            >
                              <path
                                id="Path_10"
                                data-name="Path 10"
                                d="M8,0,6.545,1.455l5.506,5.506H-30V9.039H12.052L6.545,14.545,8,16l8-8Z"
                                transform="translate(30)"
                              ></path>
                            </svg>
                          </button>
                        </div>
                      </div>

                      <div className="p-pri">
                        <div>
                          <h4>₹{formatCash(listData?.propertyData?.purposeOfProperty === "Buy" ? listData?.propertyData?.propertyCost : listData?.propertyData?.PropertyRent)}/-</h4>
                          <p>{listData?.propertyData?.purposeOfProperty}</p>
                        </div>

                        <div>
                          <h4>₹{formatCash(listData?.propertyData?.purposeOfProperty === "Buy" ? listData?.propertyData?.bookingAmount : listData?.propertyData?.SecurityDeposit)}/-</h4>
                          <p>{listData?.propertyData?.purposeOfProperty === "Buy" ? "Booking Price" : "Security Deposit"}</p>
                        </div>

                          {/* <div
                        className="d-flex justify-content-end"
                        onClick={() =>
                          navigate(`/fulltab/${listData.propertyType}/${listData._id}`, {
                            state: {
                              yourData: listData,
                              propertyType:listData.propertyType,
                            },
                          })
                        }
                      >
                        <button class="cta">
                          <span class="hover-underline-animation">
                            {" "}
                            View More{" "}
                          </span>
                          <svg
                            id="arrow-horizontal"
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            height="10"
                            viewBox="0 0 46 16"
                          >
                            <path
                              id="Path_10"
                              data-name="Path 10"
                              d="M8,0,6.545,1.455l5.506,5.506H-30V9.039H12.052L6.545,14.545,8,16l8-8Z"
                              transform="translate(30)"
                            ></path>
                          </svg>
                        </button>
                        <ArrowForwardIosTwoToneIcon />
                      </div>                       
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
        </Paper>
      </div> */}


{
  /* <TableContainer sx={{ maxHeight: 540 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                <TableCell>
                   Sr.No
                  </TableCell>
                  <TableCell>
                   Property Type
                  </TableCell>
                  <TableCell>
                   Property Name
                  </TableCell>
                  <TableCell>
                   Comment
                  </TableCell>
                  <TableCell align="center" width={200}>
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reviewData?.map((data, index)=>(
                  <TableRow key={index}>
                  <TableCell>
                    {index + 1}
                  </TableCell>
                   <TableCell>
                    {data.PropertyId}
                   </TableCell>
                   <TableCell>
                   </TableCell>
                    <TableCell>
                    {expandedComments[index]
                        ? data.Comment
                        : `${data.Comment.substring(0, 50)}`}
                      {data.Comment.length > 50 && (
                        <a
                          href="#!"
                          onClick={() => handleToggleExpand(index)}
                          style={{ marginLeft: "5px", color: "blue", cursor: "pointer" }}
                        >
                          {expandedComments[index] ? "Show Less" : "View More"}
                        </a>
                      )}
                    </TableCell>
                    <TableCell>
                      
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15, 50]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */
}
