import Content from "./Content/Content";
import "./App.css";
import "font-awesome/css/font-awesome.min.css";
// Bootstrap css
import 'bootstrap/dist/css/bootstrap.min.css';

//custom css files
import "./Assets/css/style.css";
import "./Assets/css/adminstyle.css";


import { BrowserRouter } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <div>
        <Content />
      </div>
    </BrowserRouter>
  );
}

export default App;
