/* eslint-disable no-useless-escape */
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// api calls
import { LoginUser } from "../../Api/AuthAPI";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Swal from "sweetalert2";

const defaultTheme = createTheme();

export default function Login() {
  const Navigate = useNavigate();
  // Register states
  const [newUser, setNewUser] = useState({
    email: "",
    password: "",
  });
  const [newUserErr, setNewUserErr] = useState({});

  // validate signup form
  const validation = () => {
    const emailRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    let newUserErr = {};
    let isValid = true;

    if (newUser.email === "" || newUser.email === null) {
      newUserErr["email"] = "Please enter email!";
      isValid = false;
    } else if (emailRegex.test(newUser.email) === false) {
      newUserErr["email"] = "please enter valid email";
      isValid = false;
    }

    setNewUserErr(newUserErr);
    return isValid;
  };

  // handle change for form
  const handleChangeReg = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setNewUser({ ...newUser, [name]: value });
    if (name === "email" && value !== null) {
      setNewUserErr({ ...newUserErr, email: "" });
    }

    if (name === "password" && value !== null) {
      setNewUserErr({ ...newUserErr, password: "" });
    }
  };
  const handleLogin = (e) => {
    e.preventDefault();
    let data = {
      email: newUser.email,
      password: newUser.password,
    };
    const isValid = validation();
    if (isValid) {
      LoginUser(data)
        .then((res) => {
          let details = {
            userData: { ...res.data.userData },
            token: res.data.token,
          };
          Swal.fire({
            icon: "success",
            title: "Login Successful",
            showConfirmButton: true,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "ok",
          });
          localStorage.setItem("User", JSON.stringify(details));
          if (
            res.data.userData.role === "Owner" ||
            res.data.userData.role === "Agent"
          ) {
            window.location.href = "/dashboard";
          } else if (res.data.userData.role === "Admin") {
            window.location.href = "/adminDashboard";
          } else {
            window.location.href = "/";
          }
        })
        .catch((err) => {
          if (err && err.response) {
            Swal.fire({
              title: "Warning",
              text: err.response.data.message,
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "ok",
            });
          } else {
            console.log(err, "error in login");
          }
        });
    }
  };
  return (
    // <div>
    //   <section className="mt-2">
    //     <div class="container h-100">
    //       <div class="row h-100">
    //         <div class="">
    //           <div class="align-middle">
    //             <div class="text-center mt-4">
    //               <h1 class="h2">Login</h1>
    //             </div>

    //             {/* <div class="card">
    //                   <div class="card-body">
    //                     <div class="md-12"> */}
    //             <form className="container mt-3 mb-3" autoComplete="off">
    //               <Row className="mb-3">
    //                 <Form.Group
    //                   controlId="formBasicEmail"
    //                   className="col col-sm-12"
    //                 >
    //                   <Form.Label>Email</Form.Label>
    //                   <InputGroup>
    //                     <Form.Control
    //                       aria-label="Email Address"
    //                       aria-describedby="basic-addon2"
    //                       type="email"
    //                       name="email"
    //                       onChange={handleChangeReg}
    //                       value={newUser.email}
    //                     />
    //                   </InputGroup>
    //                   {newUserErr ? (
    //                     <div
    //                       className="mt-2"
    //                       style={{ fontSize: 12, color: "red" }}
    //                     >
    //                       {newUserErr.email}
    //                     </div>
    //                   ) : null}
    //                 </Form.Group>
    //               </Row>
    //               <Row className="mb-3">
    //                 <Form.Group
    //                   controlId="formBasicMobile"
    //                   className="col col-sm-12"
    //                 >
    //                   <Form.Label> Password</Form.Label>
    //                   <InputGroup>
    //                     <Form.Control
    //                       aria-label="Password"
    //                       type={showPass ? "text" : "password"}
    //                       aria-describedby="basic-addon1"
    //                       className="form-control"
    //                       name="password"
    //                       onChange={handleChangeReg}
    //                       value={newUser.password}
    //                     />

    //                     <InputGroup.Text
    //                       id="basic-addon1"
    //                       className="showPass"
    //                       onMouseDown={handleMouseDown}
    //                       onMouseUp={handleMouseUp}
    //                     >
    //                       {" "}
    //                       {showPass === true ? (
    //                         <VisibilityIcon />
    //                       ) : (
    //                         <VisibilityOffIcon />
    //                       )}
    //                     </InputGroup.Text>
    //                   </InputGroup>
    //                   {newUserErr ? (
    //                     <div
    //                       className="mt-2"
    //                       style={{ fontSize: 12, color: "red" }}
    //                     >
    //                       {newUserErr.password}
    //                     </div>
    //                   ) : null}
    //                 </Form.Group>
    //               </Row>

    //               <div className="d-flex justify-content-center mx-4 mb-3 mb-lg-4 mt-4">
    //                 <button
    //                   className="btn btn-primary btn-lg"
    //                   onClick={handleLogin}
    //                 >
    //                   Login
    //                 </button>
    //               </div>
    //               {/* <div className="regi-a row justify-content-center mb-4">
    //                 <Col sm={12} className="text-center m-0">
    //                   <p className="fgt-psw">
    //                     New on our Platform ? {""}
    //                     <Link to="/signup" className="fgt-psw fw-bold">
    //                       {" "}
    //                       Create an Account!
    //                     </Link>
    //                   </p>
    //                 </Col>
    //               </div> */}
    //             </form>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </section>
    // </div>
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" className="login-box ">
        <CssBaseline />
       
          
          <Box component="form" onSubmit={handleLogin} noValidate>
            <TextField
              margin="normal"
              required
              fullWidth
              variant="standard"
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              onChange={handleChangeReg}
              value={newUser.email}
            />
            <TextField
              margin="normal"
              required
              variant="standard"
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={handleChangeReg}
              value={newUser.password}
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              className="btn-red"
              sx={{ mt: 5, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container className="login-terms d-flex justify-content-end">
              <Grid item>
                <Link
                  href="/forgetPassword"
                  variant="body2"
                  to="/forgetPassword"
                >
                  Forgot password?
                </Link>
              </Grid>
              {/* <Grid item>
               
                  "Don't have an account? <Link to={"/signup"} variant="body2"> Sign Up"
                </Link>
              </Grid> */}
            </Grid>
          </Box>
        
        
      </Container>
    </ThemeProvider>
  );
}
