import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
//@mui packages
import Userimg from "../../Assets/user.png";
import { Container, Row, Col } from "react-bootstrap";
import "./builderreviewproperty.css";
//icons
import CottageOutlinedIcon from '@mui/icons-material/CottageOutlined';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import ReviewsOutlinedIcon from '@mui/icons-material/ReviewsOutlined';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import BusinessIcon from '@mui/icons-material/Business';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
//custom components
import AdminSidebar from "../../Components/Sidebar/AdminSidebar";
//Api
import { getBuilderDashboardValue } from "../../Api/BuilderProjectAPI";
//Auth
import Auth from "../../Config/helper/Auth";

const BuilderDashboard = () => {
    const userId = Auth?.user?.userData?._id
    const [allCount, setAllCount] = useState([])

    useEffect(() => {
        getBuilderDashboardValue(userId)
        .then((res)=>{
            if(res.data.status === 1){
                setAllCount(res.data.data);
            }else{
                console.log('error in builder dashboard');
            }
        }).catch((err)=>{
            console.log(err,"error in builder dashboard");
        })
    }, [])

    return (
        <>
            <AdminSidebar />

            {/* import * as React from 'react'; */}

            <Container className="topNav-m">
                <Row>
                   
                        <div class="dashboard ">

                            {/* <div class="tile">
                                <div class="tile-icon">
                                    <HomeWorkIcon />
                                    <div class="count">15</div>
                                </div>
                                <div class="tile-content">
                                    <h2>Active Projects                </h2>
                                    <p>Total projects <span class="highlight">active</span></p>
                                </div>
                            </div>
                            <div class="tile">
                                <div class="tile-icon">
                                    <BusinessIcon />
                                    <div class="count">32</div>
                                </div>
                                <div class="tile-content">
                                    <h2>Completed Projects</h2>
                                    <p>Current <span class="highlight">occupied</span> units</p>
                                </div>
                            </div> */}
                        <Link to="/builderProjectDetails">
                            <div class="tile">
                                <div class="tile-icon">
                                    <LocationCityIcon />
                                    <div class="count">{allCount?.constructionCount ? allCount?.constructionCount : 0}</div>
                                </div>
                                <div class="tile-content">
                                    <h2>Total Project</h2>
                                    <p>Total projects we <span class="highlight">owned</span> </p>
                                </div>
                            </div>
                        </Link>
                        <Link to="/ReviewPropertyBuilder">
                            <div class="tile">
                                <div class="tile-icon">
                                    <ReviewsOutlinedIcon />
                                    <div class="count">{allCount?.overallRating ? allCount?.overallRating : 0}</div>
                                </div>
                                <div class="tile-content">
                                    <h2>Reviews</h2>
                                    <p><span class="highlight">Monthly</span> rental revenue</p>
                                </div>
                            </div>
                        </Link>
                            {/* <div class="tile">
                                <div class="tile-icon">
                                    <WorkspacePremiumIcon />
                                    <div class="count">3</div>
                                </div>
                                <div class="tile-content">
                                    <h2>Certification</h2>
                                    <p>Total commercial properties <span class="highlight">owned</span></p>
                                </div>
                            </div> */}


                        </div>
                    

                </Row>
            </Container>


        </>
    );
};

export default BuilderDashboard;

// review ui
{/* <Col md={4} sm={12} className="dash-notification">
                        <div className="dash-box">
                            <h4>Customer Review</h4>
                            <div className="dash-review-box">


                                <div className=" dash-review">
                                    <div className="user-info">
                                        <img
                                            src={Userimg}
                                            alt="Profile"
                                            className="profile-photo"
                                        />
                                        <div className="user-details">
                                            <p>Name</p>
                                            <div className=" rating ">stars</div>
                                        </div>
                                    </div>

                                    <div>
                                        <p className="comment p-1">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                        </p>
                                        <div className="review-date">12 July, 2024</div>
                                    </div>
                                </div>
                                <div className=" dash-review">
                                    <div className="user-info">
                                        <img
                                            src={Userimg}
                                            alt="Profile"
                                            className="profile-photo"
                                        />
                                        <div className="user-details">
                                            <p>Name</p>
                                            <div className=" rating ">stars</div>
                                        </div>
                                    </div>

                                    <div>
                                        <p className="comment p-1">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                        </p>
                                        <div className="review-date">12 July, 2024</div>
                                    </div>
                                </div>
                                <div className=" dash-review">
                                    <div className="user-info">
                                        <img
                                            src={Userimg}
                                            alt="Profile"
                                            className="profile-photo"
                                        />
                                        <div className="user-details">
                                            <p>Name</p>
                                            <div className=" rating ">stars</div>
                                        </div>
                                    </div>

                                    <div>
                                        <p className="comment p-1">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                        </p>
                                        <div className="review-date">12 July, 2024</div>
                                    </div>
                                </div>
                                <div className=" dash-review">
                                    <div className="user-info">
                                        <img
                                            src={Userimg}
                                            alt="Profile"
                                            className="profile-photo"
                                        />
                                        <div className="user-details">
                                            <p>Name</p>
                                            <div className=" rating ">stars</div>
                                        </div>
                                    </div>

                                    <div>
                                        <p className="comment p-1">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                        </p>
                                        <div className="review-date">12 July, 2024</div>
                                    </div>
                                </div>
                                <div className=" dash-review">
                                    <div className="user-info">
                                        <img
                                            src={Userimg}
                                            alt="Profile"
                                            className="profile-photo"
                                        />
                                        <div className="user-details">
                                            <p>Name</p>
                                            <div className=" rating ">stars</div>
                                        </div>
                                    </div>

                                    <div>
                                        <p className="comment p-1">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                        </p>
                                        <div className="review-date">12 July, 2024</div>
                                    </div>
                                </div>
                                <div className=" dash-review">
                                    <div className="user-info">
                                        <img
                                            src={Userimg}
                                            alt="Profile"
                                            className="profile-photo"
                                        />
                                        <div className="user-details">
                                            <p>Name</p>
                                            <div className=" rating ">stars</div>
                                        </div>
                                    </div>

                                    <div>
                                        <p className="comment p-1">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                        </p>
                                        <div className="review-date">12 July, 2024</div>
                                    </div>
                                </div>
                                <div className=" dash-review">
                                    <div className="user-info">
                                        <img
                                            src={Userimg}
                                            alt="Profile"
                                            className="profile-photo"
                                        />
                                        <div className="user-details">
                                            <p>Name</p>
                                            <div className=" rating ">stars</div>
                                        </div>
                                    </div>

                                    <div>
                                        <p className="comment">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                        </p>
                                        <div className="review-date">12 July, 2024</div>
                                    </div>
                                </div>
                                <div className=" dash-review">
                                    <div className="user-info">
                                        <img
                                            src={Userimg}
                                            alt="Profile"
                                            className="profile-photo"
                                        />
                                        <div className="user-details">
                                            <p>Name</p>
                                            <div className=" rating ">stars</div>
                                        </div>
                                    </div>

                                    <div>
                                        <p className="comment">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                        </p>
                                        <div className="review-date">12 July, 2024</div>
                                    </div>
                                </div>
                            </div>
                            <div className="pt-3 pb-2">View all reviews</div>
                        </div>
                      
                    </Col> */}
