/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// mui packages & icons
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Grid from "@mui/material/Grid";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ReactPaginate from "react-paginate";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import "../../Admin/pagination.css";
//Custom components
import PropertyCard from "../../../Components/Common/PropertyCard";
import PropertyService from "../../../Components/Common/PropertyService";

// Api
import {
  getAllRentProperty,
  filterAllResidential,
} from "../../../Api/RentpropertyAPI";

function RentProperty({
  buyRent,
  location,
  propertyType,
  minBudget,
  maxBudget,
  minArea,
  maxArea,
  noOfBedrooms,
}) {
  const navigate = useNavigate();

  const [ResidentialData, setResidentialData] = useState([]);
  const [allResidentialData, setAllResidentialData] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const itemsPerPage = 3;
  const pageCount = Math.ceil(ResidentialData.length / itemsPerPage);
  const offset = pageNumber * itemsPerPage;
  const currentPageData = ResidentialData.slice(offset, offset + itemsPerPage);

  //css 
  const noDataMessageStyle = {
    textAlign: "center",
    padding: "20px",
    backgroundColor: "#f8d7da",
    color: "#721c24",
    border: "1px solid #f5c6cb",
    borderRadius: "5px",
    margin: "10px",
  };

  // slider setting code
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: ResidentialData.length >= 3 ? 3 : ResidentialData.length,
    slidesToScroll: 3,
    autoplay: true,
    pauseOnHover: true,
    autoplaySpeed: 4000,
    cssEase: "linear",
    arrows: ResidentialData.length >= 3 ? true : false,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  // Function to shuffle an properties Data
  const shuffleArray = (array) => {
    const newArray = [...array];
    for (let i = newArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
    return newArray;
  };

  // useEffects
  useEffect(() => {
    getResidentialPropertyAll();
  }, []);

  // useEffect(() => {
  //   const currentTime = new Date().getTime();

  //   const storedShuffleTime = localStorage.getItem("lastShuffleTime");
  //   const storedShuffledData = localStorage.getItem("shuffledData");
  //   if (storedShuffleTime && storedShuffledData && currentTime - storedShuffleTime < 30* 1000) {
  //     setResidentialData(JSON.parse(storedShuffledData));
  //     setAllResidentialData(JSON.parse(storedShuffledData));
  //   } else {
  //     getResidentialPropertyAll();
  //   }
  // }, []);

  // const GetAllRentProperty = () => {
  //   if (
  //     location ||
  //     propertyType ||
  //     minBudget ||
  //     maxBudget ||
  //     minArea ||
  //     maxArea ||
  //     noOfBedrooms
  //   ) {
  //     let dataToSend = {
  //       Location: location,
  //       propertyType: propertyType,
  //       minBudget: Number(minBudget),
  //       maxBudget: Number(maxBudget),
  //       minArea: Number(minArea),
  //       maxArea: Number(maxArea),
  //       selectNosofBedrooms: Number(noOfBedrooms),
  //     };
  //     filterAllResidential(dataToSend)
  //       .then((res) => {
  //         if (res.data.status === 200) {
  //           let details = res.data.Data;
  //           setResidentialData(details);
  //         } else {
  //           setResidentialData([]);
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err, "error in get all rent property.");
  //       });
  //   } else {
  //     getAllRentProperty()
  //       .then((res) => {
  //         if (res.data.status === 1) {
  //           let details = res.data.newRentProprtyDetails;

  //           // verified status filter
  //           // let updateStatus = details.filter(
  //           //   (item) => item.isVerified === "Verified"
  //           // );
  //           setResidentialData(details);
  //           setAllResidentialData(details);
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err, "error in get all rent property.");
  //       });
  //   }
  // };

  const getResidentialPropertyAll = () => {
    getAllRentProperty()
      .then((res) => { 
        if (res.data.status === 1) {
          let details = res.data.newRentProprtyDetails.filter((verifiedData)=> verifiedData.isVerified === "Verified");
          setResidentialData(details);
          setAllResidentialData(details);
        }
      })
      .catch((err) => {
        console.log(err, "error in get all rent property.");
      });
  };

  // viewmore fulltab
  const AllProperties = (data) => {
    console.log(data, "skjdfls")
    navigate("/filterProperties", {
      state: { properties: data, propertyType: "Residential" },
    });
  };


  const handlePageChange = ({ selected }) => {
    setPageNumber(selected);
  };


  return (
    <>
      <div className='flex-grow-1'>
        <div id="feat-properties">
          <div className="container">
            <div
              className="content-box wow animated fadeIn"
              data-wow-duration=".5s"
              data-wow-delay=".2s"
            >
              <div className="content-title ">
                <div className="content-name">
                  <h3>Residential Properties</h3>

                  {/* {ResidentialData.length >= 3 && (
                    <div
                      className=""
                      style={{ cursor: "pointer" }}
                      onClick={() => AllProperties(ResidentialData)}
                    >
                      All Properties
                      <ArrowForwardIcon />
                    </div>
                  )} */}
                </div>
                <hr className="content-title-underline" />
              </div>
              <div className="container">

                {ResidentialData.length === 0 ? (
                  <p className="errormsg">No data found</p>
                ) : (
                  <>
                    <Slider {...settings}>
                      {/* <Grid container spacing={1}>
                        <Grid container item spacing={3}> */}
                       
                          {ResidentialData.map((list) => (

                            <PropertyCard
                              auction={list}
                              propertyType="Residential"
                            />

                          ))}
                         
                        {/* </Grid>
                      </Grid> */}
                    </Slider>
                    {/* <div className="container">
                   <ReactPaginate
                     previousLabel={<NavigateBeforeIcon />}
                     nextLabel={<NavigateNextIcon />}
                     breakLabel={"..."}
                     pageCount={pageCount}
                     onPageChange={handlePageChange}
                     containerClassName={"pagination"}
                     activeClassName={"activepagination"}
                     pageClassName={"pagination-item"}
                   />
                  </div> */}
                  </>
                )}
              </div>
              {/* <div>
                <PropertyService />
              </div> */}
            </div>
          </div>
        </div>
      </div>

      {/* <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Payment Method</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Choose Which type of payment Method you want to use!
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => payCryptoCurrency(Amount)}>
            Crypto currency
          </Button>
          <Button variant="primary" onClick={() => payAmount(Amount)}>
            Fiat currency
          </Button>
        </Modal.Footer>
      </Modal> */}
    </>
  );
}

export default RentProperty;
