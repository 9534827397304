import React, { useEffect, useState } from "react";
//@mui packages
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Select, MenuItem, TextField } from "@mui/material";
import ReactPaginate from "react-paginate";
import IconButton from "@mui/material/IconButton";
import { Modal } from "react-bootstrap";
import "./pagination.css";
//icons
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
//custom components
import Loader from "../../Components/Common/Loader";
import AdminSidebar from "../../Components/Sidebar/AdminSidebar";
//api
import { allUserRoleList, updateAgentStatus } from "../../Api/AuthAPI";

const ListedAgents = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [listData, setListData] = useState([]);
  const [selectedValues, setSelectedValues] = useState({});
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [checkStatus, setCheckStatus] = useState(null);

  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const filteredData = listData.filter(
    (data) =>
      data.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      data.email.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const itemsPerPage = 10;
  const pageCount = Math.ceil(filteredData.length / itemsPerPage);
  const offset = page * itemsPerPage;
  const currentPageData = filteredData.slice(offset, offset + itemsPerPage);

  useEffect(() => {
    getAgentList();
  }, []);

  const columns = [
    { id: "id", label: "Sr. No.", minWidth: 20 },
    {
      id: "profileImage",
      label: "Profile Image",
      minWidth: 100,
    },
    { id: "fullName", label: "Agent Name", minWidth: 100 },
    {
      id: "email",
      label: "Email",
      minWidth: 100,
    },
    {
      id: "contact",
      label: "Contact Number",
      minWidth: 100,
    },
    {
      id: "agentStatus",
      label: "Agent Status",
      minWidth: 100,
    },
  ];

  const verificationStatus = [
    { value: "Normal", label: "Normal" },
    { value: "Agent", label: "Agent" },
    { value: "Certified Agent", label: "Certified Agent" },
    { value: "Rejected", label: "Rejected" },
  ];

  const handleChangePage = ({ selected }) => {
    setPage(selected);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getAgentList = () => {
    allUserRoleList("Agent")
      .then((res) => {
        let result = res.data.Profiles;
        setListData(result);

        const initialSelectedValues = {};
        result.forEach((item, index) => {
          initialSelectedValues[index] = item.agentStatus;
        });

        setSelectedValues(initialSelectedValues);
      })
      .catch((e) => {
        console.log("====================================");
        console.log(e, "error in agent List");
        console.log("====================================");
      });
  };

  useEffect(() => {
    const initialSelectedValues = {};
    listData.forEach((item, index) => {
      initialSelectedValues[index] = item.agentStatus;
    });

    setCheckStatus(initialSelectedValues);
  }, [listData]);

  const updateStatus = async (userID, status) => {
    let updateData = {
      agentStatus: status,
    };
    setLoading(true);
    updateAgentStatus(userID, updateData)
      .then((res) => {
        if (res.data.status === 1) {
          //  let data = [...listData];
          //  data[i]["agentStatus"] = status;
          //  setListData(data);
          setLoading(false);
        }
      })
      .catch((e) => {
        console.log("====================================");
        console.log(e);
        console.log("====================================");
      });
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  const handleImageModalOpen = (imageUrl) => {
    setSelectedImage(imageUrl);
    setImageModalOpen(true);
  };

  const handleImageModalClose = () => {
    setImageModalOpen(false);
    setSelectedImage("");
  };


  return (
    <div>
      <AdminSidebar />
      <div className="topNav-m">
        <div className="container">
          <div className=" content-title">
            <h3>Listed Agents</h3>
            <hr className="content-title-underline" />
          </div>

          <div className="d-flex justify-content-end mb-2">
            <input
              type="text"
              label="Search"
              variant="outlined"
              value={searchTerm}
              onChange={handleSearchChange}
              size="small"
              style={{ marginBottom: "20px" }}
            />
          </div>

          <Paper >
            {loading && <Loader />}
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <>
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                      </>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentPageData.length > 0 ?
                    currentPageData.map((listData, i) => {
                      const serialNumber = offset + i + 1;
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1}>
                          <TableCell>{serialNumber}</TableCell>
                          <TableCell>
                            <IconButton
                              className="ownerImg"
                              onClick={() =>
                                handleImageModalOpen(
                                  `${listData.profileImage[0]?.name || 'https://api.equireal.co.in/default_profile_image.png'}`
                                )
                              }
                            >
                              <img
                                src={listData.profileImage[0]?.name || 'https://api.equireal.co.in/default_profile_image.png'}
                                alt="not available"
                              />

                              {/* <PreviewIcon color="primary" /> */}
                            </IconButton>
                          </TableCell>
                          <TableCell>{listData.fullName}</TableCell>
                          <TableCell>{listData.email}</TableCell>
                          <TableCell>{listData.phone}</TableCell>

                          <TableCell>
                            {/* {verificationStatus.map((option,index) => (
                          <div class="cat search-sel" key={index}>
                          <label>
                            <input
                              type="checkbox"
                              checked={option.value === listData.agentStatus}
                              onChange={(e) => updateStatus(listData._id, option.value, i)}
                            />
                            <span>{option.value}</span>
                          </label>
                        </div>
                          ))} */}
                            <Select
                              size="small"
                              value={selectedValues[i]}
                              fullWidth
                              onChange={(e) => {
                                setSelectedValues({
                                  ...selectedValues,
                                  [i]: e.target.value,
                                });
                                updateStatus(listData._id, e.target.value);
                              }}
                            >
                              {verificationStatus.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </TableCell>
                        </TableRow>
                      );
                    })
                    :
                    <TableRow>
                      Data not found
                    </TableRow>
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <ReactPaginate
              previousLabel={<NavigateBeforeIcon />}
              nextLabel={<NavigateNextIcon />}
              breakLabel={"..."}
              pageCount={pageCount}
              onPageChange={handleChangePage}
              containerClassName={"pagination"}
              activeClassName={"activepagination"}
              pageClassName={"pagination-item"}
            />
          </Paper>
        </div>
      </div>


      <Modal
        show={imageModalOpen}
        onHide={handleImageModalClose}
        centered
        className="modal-lg "
      >
        <Modal.Header closeButton>
          <Modal.Title className="loginTitle">Profile Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src={selectedImage}
            alt="Preview"
            style={{ width: "100%", height: "auto" }}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ListedAgents;
