import axios from "axios";
import { settings } from "../Config/Settings";

const getCommercialProperty = () => {
  return axios({
    method: "GET",
    url: `${settings.API_URL}/RentCommercialProperty/getallrentCommercialProperty`,
  });
};

const getCommercialPropertyByUserId = (userId) => {
  return axios({
    method: "GET",
    url: `${settings.API_URL}/RentCommercialProperty/getallRentCommercialPropertyByUser/${userId}`,
  });
};

const addCommercials = (data) => {
  return axios({
    method: "POST",
    url: `${settings.API_URL}/RentCommercialProperty/AddCommercialRentProperty`,
    data,
  });
};

const updateCommercials = (data, id) => {
  return axios({
    method: "PATCH",
    url: `${settings.API_URL}/RentCommercialProperty/updateRentCommercialProperty/${id}`,
    data,
  });
};

const deleteCommercials = (id) => {
  return axios({
    method: "PATCH",
    url: `${settings.API_URL}/RentCommercialProperty/deleteRentCommercialProperty/${id}`,
  });
};

const filterByCity = (Location) => {
  return axios({
    method: "GET",
    url: `${settings.API_URL}/RentCommercialProperty/getrentcommercialPropertybyLocation/${Location}`,
  });
};

const filterBySubLocation = (subLocation) => {
  return axios({
    method: "GET",
    url: `${settings.API_URL}/RentCommercialProperty/getrentcommercialPropertybySubLocation/${subLocation}`,
  });
};

const filterByProperty = (propertyType) => {
  return axios({
    method: "GET",
    url: `${settings.API_URL}/RentCommercialProperty/getcommercialPropertyByTypesofProperty/${propertyType}`,
  });
};
//"inkdkl"
const filterByPoster = (posterType) => {
  return axios({
    method: "GET",
    url: `${settings.API_URL}/RentCommercialProperty/getrentcommercialPropertybyPoster/${posterType}`,
  });
};
const filterByNoofSeats = (noofSeats) => {
  return axios({
    method: "GET",
    url: `${settings.API_URL}/RentCommercialProperty/getrentcommercialPropertybyNosOfSeats/${noofSeats}`,
  });
};
const filterByNoofCabins = (noofCabins) => {
  return axios({
    method: "GET",
    url: `${settings.API_URL}/RentCommercialProperty/getrentcommercialPropertybyNosOfCabins/${noofCabins}`,
  });
};
const filterByPropertyRent = (propertyRent) => {
  return axios({
    method: "GET",
    url: `${settings.API_URL}/RentCommercialProperty/getrentcommercialPropertybyPropertyRent/${propertyRent}`,
  });
};

const filterByAllData = (data) => {
  return axios({
    method: "POST",
    url: `${settings.API_URL}/RentCommercialProperty/filtercomertials`,
    data: data,
  });
};

const CommercialPropertyDetails = (id) => {
  return axios({
    method: "GET",
    url: `${settings.API_URL}/RentCommercialProperty/getrentcommercialPropertyById/${id}`,
  });
};

export {
  getCommercialProperty,
  addCommercials,
  updateCommercials,
  deleteCommercials,
  filterByCity,
  filterBySubLocation,
  filterByProperty,
  filterByPoster,
  filterByNoofSeats,
  filterByNoofCabins,
  filterByPropertyRent,
  filterByAllData,
  getCommercialPropertyByUserId,
  CommercialPropertyDetails,
};
