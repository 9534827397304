import React from "react";
import { TextField, FormControl, Select, MenuItem } from "@mui/material";
import { titleOptions } from "../../../../modules/helpers/constant";
export default function Tenant({ onChangeHandler, state, stateError }) {
  return (
    <>
      <div className="mt-4 p-2">
        <h2>Licensee Details</h2>

        <div className="p-2">
          <div className="row">
            <div className="col-sm-2">
              <FormControl variant="outlined" fullWidth>
                <Select
                  value={state.LicenseeTitle}
                  required
                  // label="Title"
                  size="small"
                  onChange={(e) => {
                    onChangeHandler("LicenseeTitle", e.target.value);
                  }}
                >
                  {titleOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                <div style={{ color: "red", fontSize: 13 }}>
                  {stateError.LicenseeTitle}
                </div>
              </FormControl>
            </div>

            <div className="col-sm-10">
              <FormControl variant="outlined" fullWidth>
                <TextField
                  label="Licensee's Name"
                  type="text"
                  size="small"
                  value={state.LicenseeName}
                  onChange={(e) => {
                    onChangeHandler("LicenseeName", e.target.value);
                  }}
                  required
                ></TextField>
                <div style={{ color: "red", fontSize: 13 }}>
                  {stateError.LicenseeName}
                </div>
              </FormControl>
            </div>
          </div>
        </div>
        {/* <div className="row p-2">
          <div className="p-2 col-sm-5">
            <FormControl variant="outlined" fullWidth>
              <TextField
                label="Mobile No"
                type="text"
                size="small"
                value={state.tenantMobileNo}
                onChange={(e) => {
                  onChangeHandler("tenantMobileNo", e.target.value);
                }}
                required
              ></TextField>
              <div style={{ color: "red", fontSize: 13 }}>
                {stateError.tenantMobileNo}
              </div>
            </FormControl>
          </div>

          <div className="p-2 col-sm-7">
            <FormControl variant="outlined" fullWidth>
              <TextField
                autoComplete="off"
                label="Email"
                type="text"
                size="small"
                value={state.tenantEmail}
                onChange={(e) => {
                  onChangeHandler("tenantEmail", e.target.value);
                }}
                required
              ></TextField>
              <div style={{ color: "red", fontSize: 13 }}>
                {stateError.tenantEmail}
              </div>
            </FormControl>
          </div>
        </div> */}

        <div className="p-2">
          <FormControl variant="outlined" fullWidth>
            <TextField
              label="Permanent Address"
              type="text"
              size="small"
              value={state.LicenseeAddress}
              onChange={(e) => {
                onChangeHandler("LicenseeAddress", e.target.value);
              }}
              required
            ></TextField>
            <div style={{ color: "red", fontSize: 13 }}>
              {stateError.LicenseeAddress}
            </div>
          </FormControl>
        </div>

        <div className="row p-2">
          <div className="p-2 col-sm-4">
            <FormControl variant="outlined" fullWidth>
              <TextField
                label="Location"
                type="text"
                size="small"
                value={state.LicenseeLocation}
                onChange={(e) => {
                  onChangeHandler("LicenseeLocation", e.target.value);
                }}
                required
              ></TextField>
              <div style={{ color: "red", fontSize: 13 }}>
                {stateError.LicenseeLocation}
              </div>
            </FormControl>
          </div>

          <div className="p-2 col-sm-4">
            <FormControl variant="outlined" fullWidth>
              <TextField
                label="SubLocation"
                type="text"
                size="small"
                value={state.LicenseeSubLocation}
                onChange={(e) => {
                  onChangeHandler("LicenseeSubLocation", e.target.value);
                }}
                required
              ></TextField>
              <div style={{ color: "red", fontSize: 13 }}>
                {stateError.LicenseeSubLocation}
              </div>
            </FormControl>
          </div>

          <div className="p-2 col-sm-4">
            <FormControl variant="outlined" fullWidth>
              <TextField
                label="PinCode"
                type="text"
                size="small"
                value={state.LicenseePinCode}
                onChange={(e) => {
                  onChangeHandler("LicenseePinCode", e.target.value);
                }}
                required
              ></TextField>
              <div style={{ color: "red", fontSize: 13 }}>
                {stateError.LicenseePinCode}
              </div>
            </FormControl>
          </div>
        </div>
      </div>
    </>
  );
}
