// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-row {
    /* background-color: rgb(247, 186, 186); */
    /* height: 200px; */
    margin-top: 5px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}


.table-image {
    width: 100%;
}

.price-tag {
    color: rgb(255, 60, 60);
    font-size: 1.2rem;
    font-weight: 600;
}

.tabarea {
    /* background-color: antiquewhite; */
    /* height: 700px; */
    overflow: hidden;
    overflow-y: scroll;
}

.desc-tag {
    color: gray;
    font-size: 0.9rem;
}`, "",{"version":3,"sources":["webpack://./src/Pages/ProfileMenu/ESTATE/EstateList.css"],"names":[],"mappings":"AAAA;IACI,0CAA0C;IAC1C,mBAAmB;IACnB,eAAe;IACf,iDAAiD;AACrD;;;AAGA;IACI,WAAW;AACf;;AAEA;IACI,uBAAuB;IACvB,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,oCAAoC;IACpC,mBAAmB;IACnB,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,iBAAiB;AACrB","sourcesContent":[".table-row {\n    /* background-color: rgb(247, 186, 186); */\n    /* height: 200px; */\n    margin-top: 5px;\n    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;\n}\n\n\n.table-image {\n    width: 100%;\n}\n\n.price-tag {\n    color: rgb(255, 60, 60);\n    font-size: 1.2rem;\n    font-weight: 600;\n}\n\n.tabarea {\n    /* background-color: antiquewhite; */\n    /* height: 700px; */\n    overflow: hidden;\n    overflow-y: scroll;\n}\n\n.desc-tag {\n    color: gray;\n    font-size: 0.9rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
