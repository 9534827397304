import React from 'react'
import searchpropertyImg from "../../Assets/search-property.png";
import postpropertyImg from "../../Assets/post-property.png";

export const ListYourProperty = () => {
  return (
    
      <div id="buy-sell-property" className="content-box">

            <div className="col-12 d-flex w-100 ">
              <div
                className="red-bg  wow animated fadeIn"
                data-wow-duration="1s"
                data-wow-delay=".5s"
              >
                <div
                  className="red-img wow animated fadeInRight"
                  data-wow-duration="1s"
                  data-wow-delay="1.5s"
                >
                  <img
                    src={searchpropertyImg}
                    className="img-fluid"
                    alt=""
                  />
                </div>
                <div
                  className="red-content wow animated fadeInLeft"
                  data-wow-duration="1s"
                  data-wow-delay="1.5s"
                >
                  <h3 className='text-light'>Find Property</h3>
                  <p className='text-light'>
                    Select from thousands of options <br />
                    without brokerage.
                  </p>
                  <input type="button" value="Find Now" className="btn-org" />
                </div>
              </div>
            {/* </div>
            <div className="col-md-6 col-sm-6 mb-2"> */}
              <div
                className="blue-bg wow animated fadeIn"
                data-wow-duration="1s"
                data-wow-delay=".5s"
              >
                <div
                  className="blue-content  wow animated fadeInRight"
                  data-wow-duration="1s"
                  data-wow-delay="1.5s"
                >
                  <h3 className='text-light'> List your Property</h3>
                  <p className='text-light'>
                    For Free.
                    <br />
                    Without any brokerage.
                  </p>
                  <input type="button" value="Post Now" className="btn-org" />
                </div>
                <div
                  className="blue-img wow animated fadeInLeft"
                  data-wow-duration="1s"
                  data-wow-delay="1.5s"
                >
                  <img
                    src={postpropertyImg}
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
     
  )
}
