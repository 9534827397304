import React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Topnavigation from "../../Components/Navigation/TopNavigation";
import Footer from "../../Components/Layouts/Footer";
import Register from "./RegisterUser";
import Login from "./Login";

export default function NewMainUser() {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div>
      <Topnavigation />
      <div className="container-fluid ">
        <div className="row">
          <div className="col-md-12 login-bg ">
            <Box className="bg-white" width={"500px"}>
              <TabContext value={value} className="login-box">
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                    indicatorColor="secondary"
                    textColor="secondary"
                    variant="fullWidth"
                  >
                    <Tab label="Sign In" value="1" />
                    <Tab label="Sign up" value="2" />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <Login />
                </TabPanel>
                <TabPanel value="2">
                  <Register />
                </TabPanel>
              </TabContext>
            </Box>
            {/* <hr className="content-title-underline" /> */}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
