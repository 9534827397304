import React, { useEffect, useRef, useState } from "react";
import AdminSidebar from "../../Components/Sidebar/AdminSidebar";
import { Button } from "react-bootstrap";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Select from "react-select";
import { FormControl, TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { BsFillFileEarmarkPdfFill } from "react-icons/bs";
import Switch from "react-switch";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditIcon from "@mui/icons-material/Edit";
import Swal from "sweetalert2";
import {
  AddAminities,
  getAminitiesData,
  updateAmenitiesStatusById,
  updateAminities,
} from "../../Api/AuthAPI";
import ReactPaginate from "react-paginate";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import "./pagination.css";

const AmenitiesMaster = () => {
  const fileInputRef = useRef(null);
  const [aminities, setAminities] = useState("");
  const [selPropertyType, setSelPropertyType] = useState([]);
  const [aminitiesData, setAminitiesData] = useState([]);
  const [aminitiesId, setAminitiesId] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [checked, setChecked] = useState("");
  const [formError, setFormError] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [iconImages, setIconImages] = useState([]);
  const [showIconImages, setShowIconsImages] = useState([]);
  const itemsPerPage = 5;

  const filteredData = aminitiesData.filter((data) =>
    data.AmenitiesName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    data.PropertyType.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const pageCount = Math.ceil(aminitiesData.length / itemsPerPage);
  const offset = pageNumber * itemsPerPage;
  const currentPageData = filteredData.slice(offset, offset + itemsPerPage);

  const propertyType = [
    { label: "Residential", value: "Residential" },
    { label: "Commercial", value: "Commercial" },
    { label: "Construction", value: "Construction" },
    { label: "All", value: "All" },
  ];

  const ActiveDeactiveOption = [
    { label: "Active", value: "true" },
    { label: "Deactive", value: "false" },
  ];

  useEffect(() => {
    getAminitiesDataList();
  }, []);

  const getAminitiesDataList = () => {
    getAminitiesData()
      .then((res) => {
        if (res.status === 200) {
          const AminititesResult = res.data.AmenitiesData;
          setAminitiesData(AminititesResult);
        } else {
          Swal.fire({
            icon: "warning",
            title: "Aminitites Data Not Found !",
            showConfirmButton: true,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "ok",
          });
        }
      })
      .catch((err) => {
        console.log(err, "error in Amnitites Details");
      });
  };


  const handlePropertyType = (selected) => {
    setSelPropertyType(selected);
    setFormError({ ...formError, propertyType: "" });
  };

  const fileSelectHandler = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const fileURL = URL.createObjectURL(selectedFile);
      setIconImages(selectedFile);
      let iconsimg = { name: fileURL, newName: selectedFile.name }
      setShowIconsImages(iconsimg);
    }
  }

  const handleButtonClick = () => {
    fileInputRef.current.click();
    // if (inputName === 'uploadImage') {
    //   if (projectData.imageTitle.trim() === '') {
    //     setFormError({
    //       ...formError,
    //       imageTitle: 'Please enter image title .',
    //     });
    //     return;
    //   }
    //   fileInputRef.current.click();
    // }
  }

  const handleToggle = (checked, amenitiesData) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "Do you want to change the amenities status?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, change it!",
      cancelButtonText: "No, cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        const activeId = checked ? true : false;

        const data = {
          AmenitiesStatus: activeId,
        };

        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };

        updateAmenitiesStatusById(data, amenitiesData._id, config)
          .then((res) => {
            if (res.status === 200) {
              getAminitiesDataList();
            } else {
              setChecked("");
            }
          })
          .catch((err) => {
            console.error("Error in amenities:", err);
          });
      }
    });
  };


  // const handleToggle = (checked, animitiesData) => {

  //   Swal.fire({
  //     icon: "warning",
  //     title: "Are you sure?",
  //     text: "Do you want to change the amenities status?",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Yes, change it!",
  //     cancelButtonText: "No, cancel",
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       const formData = new FormData();
  //       const activeId = checked === true ? true : false;

  //       const Data = {
  //         // AmenitiesName: animitiesData.AmenitiesName,
  //         AmenitiesStatus: activeId,
  //         // PropertyType: animitiesData.PropertyType,
  //       };

  //       formData.append("details", JSON.stringify(Data))
  //       const config = {
  //         headers: {
  //           "content-type": "multipart/form-data",
  //         },
  //       };
  //       updateAmenitiesStatusById(formData, animitiesData._id, config)
  //         .then((res) => {
  //           if (res.status === 200) {
  //             getAminitiesDataList();
  //           } else {
  //             setChecked("");
  //           }
  //         })
  //         .catch((err) => {
  //           console.log(err, "error in aminities");
  //         });
  //     }
  //   });
  // };

  const errHandles = () => {
    let formError = {};
    let isValid = true;
    if (aminities === "") {
      isValid = false;
      formError["aminities"] = "Please Enter Aminities Name";
    }
    if (selPropertyType.length <= 0) {
      isValid = false;
      formError["propertyType"] = "Please Select Property Type";
    }
    // if(selStatus.length <= 0){
    //   isValid = false;
    //   formError["selStatus"] = "Please Select Status";
    // }

    setFormError(formError);
    return isValid;
  };

  const handleClear = () => {
    setAminities("");
    setSelPropertyType([]);
    setIsEditing(false);
    setShowIconsImages([]);
  };
  const SubmitAminities = (e) => {

    e.preventDefault();
    const formData = new FormData();
    let isValid = errHandles();
    if (isValid) {
      formData.append('icon', iconImages);
      const aminitiesData = {
        AmenitiesName: aminities,
        PropertyType: selPropertyType.value,
        icon: showIconImages
      };
      formData.append("details", JSON.stringify(aminitiesData))

      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      AddAminities(formData, config)
        .then((res) => {
          if (res.status === 200) {
            Swal.fire({
              icon: "success",
              title: "Aminities Added Successfully",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "ok",
            });
            handleClear();
            getAminitiesDataList();
          } else if (res.status === 201) {
            Swal.fire({
              icon: "warning",
              title: "Aminity Already Exist",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "ok",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Aminities Not Added. Please try again!",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "ok",
            });
          }
        })
        .catch((err) => {
          console.log(err, "error in aminities master");
        });
    }
  };

  const OnEdit = (aminitiesData) => {
    setAminities(aminitiesData.AmenitiesName);
    setSelPropertyType({
      label: aminitiesData.PropertyType,
      value: aminitiesData.PropertyType,
    });
    setAminitiesId(aminitiesData._id);
    setIsEditing(true);
  };

  const UpdateAminities = (e) => {
    e.preventDefault();
    const formData = new FormData();
    let isValid = errHandles();
    if (isValid) {
      formData.append('icon', iconImages);
      let updateAminitiesData = {
        AmenitiesName: aminities,
        PropertyType: selPropertyType.value,
        icon: showIconImages
      };
      formData.append("details", JSON.stringify(updateAminitiesData))
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      updateAminities(formData, aminitiesId, config)
        .then((res) => {
          if (res.status === 200) {
            Swal.fire({
              icon: "success",
              title: "Aminities Updated Successfully",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "ok",
            });
            handleClear();
            getAminitiesDataList();
          } else if (res.status === 201) {
            Swal.fire({
              icon: "warning",
              title: "Aminity Already Exist",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "ok",
            });
          } else {
            Swal.fire({
              icon: "success",
              title: "Aminities Not Updated! Please try again.",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "ok",
            });
          }
        })
        .catch((err) => {
          console.log(err, "error in update location");
        });
    }
  };

  const handlePageChange = ({ selected }) => {
    setPageNumber(selected);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPageNumber(0);
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <>
      <div>
        <AdminSidebar />
        <div className="container topNav-m">
          <div className=" content-title">

            <h3>Amenities Master </h3>
            <hr className="content-title-underline" />
          </div>
          <div className="container mt-2">
            <form className="box-2">
              <div className="row">
                <h5 className="m-0">{!isEditing ? 'Add' : 'Update'} Amenities</h5>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <div>
                    <span className="details"> Aminities Name</span>
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        // label="Aminities"
                        // variant="outlined"
                        autoComplete="off"
                        size="small"
                        className="form-control"
                        type="text"
                        name="aminities"
                        value={aminities}
                        onChange={(e) => {
                          setAminities(e.target.value);
                          setFormError({ ...formError, aminities: "" });
                        }}
                      // required

                      //   renderValue={(selected) => selected.join(', ')} // Display selected items
                      ></TextField>

                      <div style={{ color: "red", fontSize: 13 }}>
                        {formError.aminities}
                      </div>
                    </FormControl>
                  </div>
                </div>

                <div className="col-md-3">
                  <div>
                    <span className="details">Property Type</span>

                    {/* <FormControl variant="outlined" fullWidth> */}
                    <Select
                      name="propertyType"
                      value={selPropertyType}
                      onChange={handlePropertyType}
                      options={propertyType}
                      size="small"
                      classNamePrefix={"my-custom-react-select"}
                    />

                    <div style={{ color: "red", fontSize: 13 }}>
                      {formError.propertyType}
                    </div>
                    {/* </FormControl> */}
                  </div>
                </div>

                <div className="col-md-3">

                  {/* <span className="details"></span> */}
                  <div style={{ marginTop: "16px" }}>
                    <input
                      accept="image/jpeg"
                      type="file"
                      name="uploadImage"
                      size="small"
                      ref={fileInputRef}
                      onChange={fileSelectHandler}
                      style={{ display: 'none' }}
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      className="add-builder"
                      startIcon={<BsFillFileEarmarkPdfFill />}
                      onClick={() => handleButtonClick()}
                    >
                      Add Icon
                    </Button>

                  </div>
                  <div style={{ color: "red", fontSize: 13 }}>
                    {formError.propertyType}
                  </div>

                </div>
                {/* <div className="col-md-1 mt-2">
                  <img
                    src={showIconImages.name}
                    alt="icon"
                    width="50"
                    height="50"
                  />
                </div> */}


                <div className="col-md-2 mt-2">
                  <div>
                    {!isEditing ? (
                      <Button
                        value="Submit"
                        className="btn-red"
                        onClick={(e) => SubmitAminities(e)}
                      >
                        Submit
                      </Button>
                    ) : (
                      <Button
                        value="Submit"
                        className="btn-grey"
                        onClick={(e) => UpdateAminities(e)}
                      >
                        Update
                      </Button>
                    )}
                  </div>
                </div>

              </div>
            </form>
          </div>
        </div>
        <div>
          <div
            className="container mt-5 justify-content-center"
            style={{ marginTop: "10rem" }}
          >
            <div className="d-flex justify-content-end mb-2">
              <input
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={handleSearchChange}
                style={{ padding: "5px", width: "250px" }}
              />
            </div>
            <Paper
              sx={{
                width: "80%",
                overflow: "hidden",
                justifyContent: "center",
              }}
            >
              <TableContainer sx={{ maxHeight: 700, overflowX: "auto" }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Sr.No.</TableCell>
                      <TableCell>Amenities Name</TableCell>
                      <TableCell>PropertyType</TableCell>
                      <TableCell>Image Icon</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {currentPageData.map((dataAminitites, index) => {
                      const serialNumber = offset + index + 1;
                      return (
                        <TableRow key={index}>
                          <TableCell>{serialNumber}</TableCell>
                          <TableCell>{capitalizeFirstLetter(dataAminitites.AmenitiesName)}</TableCell>
                          <TableCell>{dataAminitites.PropertyType}</TableCell>
                          <TableCell>
                            <IconButton className="ownerImg">
                              <img
                                src={
                                  dataAminitites?.icon?.[0]?.name ||
                                  'https://api.equireal.co.in/default_profile_image.png'
                                }
                                alt="not available"
                              />
                            </IconButton>
                          </TableCell>
                          <TableCell>
                            <Switch
                              onChange={(e) => handleToggle(e, dataAminitites)}
                              checked={dataAminitites.AmenitiesStatus}
                              onColor="#1c8cba"
                              offColor="#D3D3D3"
                              uncheckedIcon={false}
                              checkedIcon={false}
                              width={40}
                              height={20}
                              handleDiameter={16}
                            />
                          </TableCell>
                          <TableCell>
                            <IconButton
                              disabled={
                                dataAminitites.AmenitiesStatus === false
                                  ? true
                                  : false
                              }
                            >
                              <EditIcon
                                color="primary"
                                onClick={() => OnEdit(dataAminitites)}
                              />
                            </IconButton>

                            {/* <IconButton aria-label="settings">
                            <DeleteOutlineOutlinedIcon
                              color="error"
                              onClick={() => DeleteLocation(datalocation._id)}
                            />
                          </IconButton> */}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                <div className="container">
                  <ReactPaginate
                    previousLabel={<NavigateBeforeIcon />}
                    nextLabel={<NavigateNextIcon />}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    onPageChange={handlePageChange}
                    containerClassName={"pagination"}
                    activeClassName={"activepagination"}
                    pageClassName={"pagination-item"}
                  />
                </div>
              </TableContainer>
            </Paper>
          </div>
        </div>
      </div>
    </>
  );
};

export default AmenitiesMaster;
