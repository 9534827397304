/* eslint-disable no-useless-escape */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Card, Form, Container, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { addRentReceipt } from "../../Api/RentpropertyAPI";
import Swal from "sweetalert2";
import Topnavigation from "../../Components/Navigation/TopNavigation";
import Auth from "../../Config/helper/Auth";
import AdminSidebar from "../../Components/Sidebar/AdminSidebar";
const GenReceipt = () => {
  const loginData = Auth.getUserDetails();
  const navigate = useNavigate();
  const [receiptData, setReceiptData] = useState({
    ownerName: "",
    tenantName: "",
    address: "",
    amount: "",
    panNo: "",
    ownerEmail: "",
    tenantEmail: "",
  });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [formError, setFormError] = useState([]);

  function formatDate(date) {
    if (date instanceof Date && !isNaN(date)) {
      return date.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });
    } else {
      console.error("Invalid date provided");
      return "";
    }
  }

  const handleInput = (e) => {
    const { name, value } = e.target;
    setReceiptData({ ...receiptData, [name]: value });
    setFormError({ ...formError, [name]: "" });
  };

  const handleClear = () => {
    setReceiptData({
      ownerName: "",
      tenantName: "",
      address: "",
      amount: "",
      panNo: "",
      ownerEmail: "",
      tenantEmail: "",
    });
    setStartDate(null);
    setEndDate(null);
    setFormError([]);
  };

  const Validation = () => {
    const validEmail =
      /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const regex = /^[a-zA-Z\s]+$/;

    let isValid = false;
    let Error = {};
    if (receiptData.ownerName === "") {
      Error["ownerName"] = "Please Enter Owner Name";
      isValid = true;
    } else if (!regex.test(receiptData.ownerName)) {
      Error["ownerName"] = "Owner Name should only contain alphabets";
      isValid = true;
    }
    if (receiptData.tenantName === "") {
      Error["tenantName"] = "Please Enter Tenant Name";
      isValid = true;
    } else if (!regex.test(receiptData.tenantName)) {
      Error["tenantName"] = "Tenant Name should only contain alphabets";
      isValid = true;
    }
    if (receiptData.address === "") {
      Error["address"] = "Please Enter Address";
    }
    if (receiptData.amount === "") {
      Error["amount"] = "Please Enter amount";
      isValid = true;
    }
    if (receiptData.panNo === "") {
      Error["panNo"] = "Please Enter PAN No";
      isValid = true;
    }
    if (
      receiptData.ownerEmail !== "" &&
      !validEmail.test(receiptData.ownerEmail)
    ) {
      Error["ownerEmail"] = "Please Enter Valid Email";
    }
    if (
      receiptData.tenantEmail !== "" &&
      !validEmail.test(receiptData.tenantEmail)
    ) {
      Error["tenantEmail"] = "Please Enter Valid Email";
    }
    if (!startDate) {
      Error["startDate"] = "Please Select Start Date";
      isValid = true;
    }
    if (!endDate) {
      Error["endDate"] = "Please Select End Date";
      isValid = true;
    } else if (startDate > endDate) {
      Error["endDate"] = "Please Select end Date greater than start Date";
      isValid = true;
    }

    setFormError(Error);
    return isValid;
  };

  const handleGenerate = () => {
    const isValid = Validation();
    if (isValid === false) {
      let data = {
        ownerName: receiptData.ownerName,
        tenantName: receiptData.tenantName,
        Address: receiptData.address,
        rentAmount: receiptData.amount,
        ownerPanNo: receiptData.panNo,
        ownerEmail: receiptData.ownerEmail,
        tentantEmail: receiptData.tenantEmail,
        recieptFromDate: formatDate(startDate),
        recieptToDate: formatDate(endDate),
      };
      addRentReceipt(data)
        .then((res) => {
          const receiptId = res.data.receiptId;
          Swal.fire({
            icon: "success",
            title: "Receipt Added Successfully",
            showConfirmButton: true,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "ok",
          });
          handleClear();
          navigate("/receiptdetails", { state: { receiptId: receiptId } });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      {loginData?.role === "Agent" || loginData?.role === "Owner" ? (
        <AdminSidebar />
      ) : (
        <Topnavigation />
      )}
      <div className="gen-receipt-bg">
        <div className="topNav-m">
          <Container className="gen-receipt-box">
            <Row>
              <Col md={6} sm={12} className="pt-5 pe-5">
                <h2>Generate FREE Rent Receipts</h2>
                <p>
                  Looking to claim HRA tax exemption? Easily generate your rent
                  receipts by filling in the details below. It's a quick,
                  hassle-free process.
                </p>
              </Col>
              <Col md={6} sm={12} className="m-top1">
                <Container>
                  <Form className="w-100 box">
                    <Row>
                      <Col sm={6}>
                        <Form.Group>
                          <Form.Label>
                            Owner Name{" "}
                            <span style={{ color: "red", fontSize: "14px" }}>
                              *
                            </span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Owner Name"
                            value={receiptData.ownerName.trimStart()}
                            name="ownerName"
                            onChange={handleInput}
                            className="txtfield"
                            autoComplete="off"
                          />
                        </Form.Group>
                        <div>
                          {formError.ownerName && (
                            <span style={{ color: "red", fontSize: "12px" }}>
                              {formError.ownerName}
                            </span>
                          )}
                        </div>
                      </Col>
                      <Col sm={6}>
                        <Form.Group>
                          <Form.Label>
                            Tenant Name{" "}
                            <span style={{ color: "red", fontSize: "14px" }}>
                              *
                            </span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Tenant Name"
                            value={receiptData.tenantName.trimStart()}
                            name="tenantName"
                            onChange={handleInput}
                            autoComplete="off"
                          />
                          <div>
                            {formError.tenantName && (
                              <span style={{ color: "red", fontSize: "12px" }}>
                                {formError.tenantName}
                              </span>
                            )}
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={6}>
                        <Form.Group>
                          <Form.Label>Owner Email</Form.Label>

                          <Form.Control
                            type="text"
                            placeholder="Owner Email"
                            value={receiptData.ownerEmail}
                            name="ownerEmail"
                            onChange={handleInput}
                            autoComplete="off"
                          />
                        </Form.Group>
                        {formError.ownerEmail && (
                          <span style={{ color: "red", fontSize: "12px" }}>
                            {formError.ownerEmail}
                          </span>
                        )}
                      </Col>
                      <Col sm={6}>
                        <Form.Group>
                          <Form.Label>Tenant Email</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Tenant Email"
                            value={receiptData.tenantEmail}
                            name="tenantEmail"
                            onChange={handleInput}
                            autoComplete="off"
                          />
                        </Form.Group>
                        {formError.tenantEmail && (
                          <span style={{ color: "red", fontSize: "12px" }}>
                            {formError.tenantEmail}
                          </span>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <Form.Group>
                          <Form.Label>Address</Form.Label>
                          <Form.Control
                            as="textarea"
                            type="text"
                            placeholder="Enter Address"
                            value={receiptData.address}
                            name="address"
                            rows={4}
                            onChange={handleInput}
                            autoComplete="off"
                          />
                        </Form.Group>
                        {formError.address && (
                          <span style={{ color: "red", fontSize: "12px" }}>
                            {formError.address}
                          </span>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={6}>
                        <Form.Group>
                          <Form.Label>
                            Amount{" "}
                            <span style={{ color: "red", fontSize: "14px" }}>
                              *
                            </span>
                          </Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Amount"
                            value={receiptData.amount}
                            name="amount"
                            onChange={handleInput}
                            autoComplete="off"
                            onKeyDown={(evt) =>
                              ["e", "E", "+", "-"].includes(evt.key) &&
                              evt.preventDefault()
                            }
                          />
                          <div>
                            {formError.amount && (
                              <span style={{ color: "red", fontSize: "12px" }}>
                                {formError.amount}
                              </span>
                            )}
                          </div>
                        </Form.Group>
                      </Col>
                      <Col sm={6}>
                        <Form.Group>
                          <Form.Label>PAN No.</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="PAN No."
                            value={receiptData.panNo}
                            name="panNo"
                            onChange={handleInput}
                            autoComplete="off"
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm={6}>
                        <Form.Group>
                          <Form.Label>
                            Start Date{" "}
                            <span style={{ color: "red", fontSize: "14px" }}>
                              *
                            </span>
                          </Form.Label>

                          <DatePicker
                            className="form-control"
                            placeholder="Select Date"
                            selected={startDate}
                            onChange={(date) => {
                              setStartDate(date);
                              setFormError({ ...formError, startDate: null });
                            }}
                          />
                          <div>
                            {formError.startDate && (
                              <span style={{ color: "red", fontSize: "12px" }}>
                                {formError.startDate}
                              </span>
                            )}
                          </div>
                        </Form.Group>
                      </Col>
                      <Col sm={6}>
                        <Form.Group>
                          <Form.Label>
                            End Date{" "}
                            <span style={{ color: "red", fontSize: "14px" }}>
                              *
                            </span>
                          </Form.Label>
                          <DatePicker
                            className="form-control"
                            placeholder="Select Date"
                            selected={endDate}
                            onChange={(date) => {
                              setEndDate(date);
                              setFormError({ ...formError, endDate: null });
                            }}
                          />
                          <div>
                            {formError.endDate && (
                              <span style={{ color: "red", fontSize: "12px" }}>
                                {formError.endDate}
                              </span>
                            )}
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="text-center">
                        <Button onClick={handleGenerate} className="btn-red">
                          Generate Now
                        </Button>
                        <Button onClick={handleClear} className="btn-grey">
                          Reset
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Container>
              </Col>
            </Row>
            {/* <div className="text-center">
            <h3>Generate FREE Rent Receipts</h3>
            <p>Need rent receipts to claim HRA tax exemption? Generate it now instantly by simply filling in the details below. It’s easy, quick, and hassle-free.
</p>
          </div>
          
        <Card className="d-flex align-items-center justify-content-center m-4 w-50 p-3">
          
          <div >
            <Form>
              <Row className="">
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label>Owner Name <span style={{color:'red', fontSize:'14px'}}>*</span></Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Owner Name"
                      value={receiptData.ownerName.trimStart()}
                      name="ownerName"
                      className=".txtfield"
                      onChange={handleInput}
                      autoComplete="off"
                    />
                  </Form.Group>
                  <div>
                  {formError.ownerName && <span style={{color:'red', fontSize:'12px'}}>{formError.ownerName}</span>}
                  </div>
                </Col>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label>Tenant Name <span style={{color:'red', fontSize:'14px'}}>*</span></Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Tenant Name"
                      value={receiptData.tenantName.trimStart()}
                      name="tenantName"
                      onChange={handleInput}
                      autoComplete="off"
                    />
                    <div>
                   {formError.tenantName && <span style={{color:'red', fontSize:'12px'}}>{formError.tenantName}</span>}
                  </div>
                  </Form.Group>
                </Col>
              </Row>
              <Row   >
                <Col sm={12}>
                  <Form.Group>
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      as="textarea"
                      type="text"
                      placeholder="Enter Address"
                      value={receiptData.address}
                      name="address"
                      onChange={handleInput}
                      autoComplete="off"
                    />
                  </Form.Group>
                  {formError.address && <span style={{color:'red', fontSize:'12px'}}>{formError.address}</span>}
                </Col>
              </Row>
              <Row   >
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label>Amount <span style={{color:'red', fontSize:'14px'}}>*</span></Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Amount"
                      value={receiptData.amount}
                      name="amount"
                      onChange={handleInput}
                      autoComplete="off"
                      onKeyDown={(evt) =>
                        ["e", "E", "+", "-"].includes(evt.key) &&
                        evt.preventDefault()
                      }
                    />
                 <div>
                  {formError.amount && <span style={{color:'red', fontSize:'12px'}}>{formError.amount}</span>}
                  </div>
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label>PAN No.</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="PAN No."
                      value={receiptData.panNo}
                      name="panNo"
                      onChange={handleInput}
                      autoComplete="off"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row   >
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label>Owner Email</Form.Label>

                    <Form.Control
                      type="text"
                      placeholder="Owner Email"
                      value={receiptData.ownerEmail}
                      name="ownerEmail"
                      onChange={handleInput}
                      autoComplete="off"
                    />
                  </Form.Group>
                  {formError.ownerEmail && <span style={{color:'red', fontSize:'12px'}}>{formError.ownerEmail}</span>}
                </Col>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label>Tenant Email</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Tenant Email"
                      value={receiptData.tenantEmail}
                      name="tenantEmail"
                      onChange={handleInput}
                      autoComplete="off"
                    />
                  </Form.Group>
                  {formError.tenantEmail && <span style={{color:'red', fontSize:'12px'}}>{formError.tenantEmail}</span>}
                </Col>
              </Row>
              <Row   >
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label>Start Date <span style={{color:'red', fontSize:'14px'}}>*</span></Form.Label>

                    <DatePicker
                      className="form-control"
                      placeholder="Select Date"
                      selected={startDate}
                      onChange={(date) => {setStartDate(date);
                        setFormError({ ...formError, startDate: null})
                      }}
                    />
                    <div>
                    {formError.startDate && <span style={{color:'red', fontSize:'12px'}}>{formError.startDate}</span>}
                    </div>
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label>End Date <span style={{color:'red', fontSize:'14px'}}>*</span></Form.Label>
                    <DatePicker
                      className="form-control"
                      placeholder="Select Date"
                      selected={endDate}
                      onChange={(date) => {setEndDate(date);
                        setFormError({ ...formError, endDate: null})
                        }}
                    />
                    <div>
                  {formError.endDate && <span style={{color:'red', fontSize:'12px'}}>{formError.endDate}</span>}
                  </div>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </div>
          <div className="d-flex justify-content-center">
            <Button variant="primary" onClick={handleGenerate}>Generate</Button>
            <Button variant="secondary" onClick={handleClear}>
              Clear
            </Button>
          </div>
        </Card> */}
          </Container>
        </div>
      </div>
    </>
  );
};

export default GenReceipt;
