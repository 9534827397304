
import React from "react";
import AdminSidebar from "./Sidebar/AdminSidebar";

import { Col, Container, Row } from "react-bootstrap";
import ReactImageGallery from "react-image-gallery";
import Paper from "@mui/material/Paper";

import "../Pages/Admin/BuilderProjectDetails"
import { IoLocationSharp } from "react-icons/io5";
import Chip from '@mui/material/Chip';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Tooltip from '@mui/material/Tooltip';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import pdf from "../../src/Assets/pdf.svg";

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Demo = () => {
  const projectImages = [
    {
      original: `https://images.pexels.com/photos/1370704/pexels-photo-1370704.jpeg?cs=srgb&dl=pexels-jessica-bryant-592135-1370704.jpg&fm=jpg`,
      thumbnail: ``,
    },
  ];
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  return (
    <>
      <AdminSidebar />
      <Container className="topNav-m">
        <div className="project-details">
          <div className="projheader">
            <div className="img-block">
              <div className="img-gallery">
                <ReactImageGallery
                  items={projectImages}
                  showIndex={"true"}
                  autoPlay={"true"}
                  className="img-project"
                />
              </div>
            </div>
          </div>
        </div>
        <Row className="pt-4">
          <Col md={6} sm={12}>
            <div className="d-flex gap-5">
              <div className="b-det">
                <span class="badge badge-light">Premium Project</span>
                <h1>Project Title</h1>
                <p>By Builder Name</p>
                <p className="proj-info__address"><LocationOnIcon /> Ghatkopar East, Mumbai</p>
                <p>Possession by Dec,2025
                  <Tooltip title=" This data is fetched &amp; verified by RERA" placement="right" arrow
                    slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: 'offset',
                            options: {
                              offset: [0, -14],
                            },
                          },
                        ],
                      },
                    }}>
                    <InfoTwoToneIcon fontSize="12" />
                  </Tooltip>
                </p>
              </div>

            </div>


          </Col>
          <Col md={6} sm={12}>
            <div className=" py-3">
              <div>
                <h4 className="fw-bold"> ₹ 2.49 Cr - ₹ 5.15 Cr</h4>
                <p>2,3,4 BHK Flats <span class="badge badge-warning">284 units availabe</span> </p>
              </div>
              <div className=" d-pdf "><button className="add-builder"> Brochure</button>
                <button className="add-builder">Reviews</button>
              </div>
            </div>

          </Col>
        </Row>
        <Row>
          <Col className="py-4">
            <h3>About Project Title</h3>
            <div className="pr-about">
              <div className="pr-card">Project Size
                <span>3 Acre</span>
              </div>
              <div className="pr-card">Launch Date

                <span> Oct 21</span>
              </div>
              <div className="pr-card">Total Units

                <span>282</span>
              </div>
              <div className="pr-card">Total Towers

                <span>2</span>
              </div>
              <div className="pr-card">BHK
                <span>1,2,3</span>
              </div>

            </div>
          </Col>
        </Row>

        <Row>
          <Col className="py-3">
            <h3>
              Properties in Projec title

            </h3>

            <Box sx={{ width: '100%' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                  <Tab label="Buy" {...a11yProps(0)} />
                  <Tab label="Rent" {...a11yProps(1)} />

                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                Item One
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                Item Two
              </CustomTabPanel>

            </Box>
          </Col>
        </Row>

        <Row>
          <Col className="py-3">
            <h3>Project Title Floor Plan & Units</h3>
            <div className="b-floorplan-box">
              <div className="b-floorplan-card">
                <div className="b-floorplan-content">
                  <h5>2 BHK Flat</h5>
                  <p>860 Sq-ft - 1076 Sq-ft</p>

                  <p> Sale:<span> ₹2.49 Cr - ₹3.49 Cr</span></p>
                  <p>Rent: -- --</p>
                </div>
                <div className="b-floorplan-img">

                </div>

              </div> <div className="b-floorplan-card">
                <div className="b-floorplan-content">
                  <h5>2 BHK Flat</h5>
                  <p>860 Sq-ft - 1076 Sq-ft</p>

                  <p> Sale:<span> ₹2.49 Cr - ₹3.49 Cr</span></p>
                  <p>Rent: -- --</p>
                </div>
                <div className="b-floorplan-img">

                </div>

              </div> <div className="b-floorplan-card">
                <div className="b-floorplan-content">
                  <h5>2 BHK Flat</h5>
                  <p>860 Sq-ft - 1076 Sq-ft</p>

                  <p> Sale:<span> ₹2.49 Cr - ₹3.49 Cr</span></p>
                  <p>Rent: -- --</p>
                </div>
                <div className="b-floorplan-img">

                </div>

              </div> <div className="b-floorplan-card">
                <div className="b-floorplan-content">
                  <h5>2 BHK Flat</h5>
                  <p>860 Sq-ft - 1076 Sq-ft</p>

                  <p> Sale:<span> ₹2.49 Cr - ₹3.49 Cr</span></p>
                  <p>Rent: -- --</p>
                </div>
                <div className="b-floorplan-img">

                </div>

              </div>


            </div>
          </Col>
        </Row>
        <Row>
          <Col className="py-4">
            <h3>Available Certificates</h3>
            <div className="b-fcert-box">
              <div className="b-fcert-card">

                <h5>Commencement Certificate</h5>
                <img src={pdf} alt />
              </div>

            </div>
          </Col>
        </Row>
        <Row>
          <Col className="py-4">
            <h3>Amenities of Project Title</h3>
            <div className="b-amenities-box">
              <div className="b-amenities-card">
                Jogging and strolling track
              </div>
              <div className="b-amenities-card">
                Jogging and strolling track
              </div>
              <div className="b-amenities-card">
                Jogging and strolling track
              </div>
              <div className="b-amenities-card">
                Jogging and strolling track
              </div>
              <div className="b-amenities-card">
                Jogging and strolling track
              </div>
              <div className="b-amenities-card">
                Jogging and strolling track
              </div>
              <div className="b-amenities-card">
                Jogging and strolling track
              </div>
              <div className="b-amenities-card">
                Jogging and strolling track
              </div>
              <div className="b-amenities-card">
                Jogging and strolling track
              </div>

            </div>
          </Col>
        </Row>
        <Row>
          <Col className="py-4">
            <h3>Reviews & Ratings</h3>
          </Col>
        </Row>

      </Container>
    </>
  )
}

export default Demo