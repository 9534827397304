import React, { useEffect, useState } from 'react';
//@materila ui
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import { FormControl, TextField } from "@mui/material";
//react packages
import Select from "react-select";
import ReactPaginate from "react-paginate";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import "./pagination.css";
//icons
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
//other packages
import Swal from 'sweetalert2';
//custom components
import AdminSidebar from '../../Components/Sidebar/AdminSidebar';
// api
import { AddPropertyType, DeletePropertyType, getPropertytypeData, updatePropertyType } from '../../Api/AuthAPI';

const PropertyTypeMaster = () => {
    const [isEditing, setIsEditing] = useState(false);
    const [areatype, setareaType] = useState("");
    const [selPropertyType, setSelPropertyType] = useState([]);
    const [propertyTypeData, setPropertyTypeData] = useState([]);
    const [propertyTypeId, setPropertyTypeId] = useState([]);
    const [formError, setFormError] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [pageNumber, setPageNumber] = useState(0);
    const itemsPerPage = 5;

    //search functinality
    const filteredData = propertyTypeData.filter((data) =>
        data.AreaType.toLowerCase().includes(searchTerm.toLowerCase()) ||
        data.PropertyType.toLowerCase().includes(searchTerm.toLowerCase())
    )
    const offset = pageNumber * itemsPerPage;
    const pageCount = Math.ceil(propertyTypeData.length / itemsPerPage);
    const currentPageData = filteredData.slice(offset, offset + itemsPerPage);


    const propertyType = [
        { label: "Residential", value: "Residential" },
        { label: "Commercial", value: "Commercial" },
        { label: "Construction", value: "Construction" },
    ];

    useEffect(() => {
        getPropertyTypeList()
    }, [])

    const getPropertyTypeList = () => {
        getPropertytypeData()
            .then((res) => {
                if (res.status === 200) {
                    const propertyTypeData = res.data.areaTypeMasterData;
                    setPropertyTypeData(propertyTypeData);
                } else {
                    Swal.fire({
                        icon: "warning",
                        title: "PropertyType Data Not Found !",
                        showConfirmButton: true,
                        confirmButtonColor: "#3085d6",
                        confirmButtonText: "ok",
                    });
                }
            }).catch((err) => {
                console.log(err, "error in get Property type List");
            })
    }

    //handleChange
    const handlePropertyType = (selected) => {
        setSelPropertyType(selected);
        setFormError({ ...formError, propertyType: "" });
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        setPageNumber(0);
    };

    const handlePageChange = ({ selected }) => {
        setPageNumber(selected);
    };

    const handleClear = () => {
        setareaType("");
        setSelPropertyType([]);
        setIsEditing(false);
    };

    //validation
    const errHandles = () => {
        let formError = {}
        let isValid = true;
        if (areatype === "") {
            isValid = false;
            formError["areatype"] = "Please Enter areaType";
        }
        if (selPropertyType.length <= 0) {
            isValid = false;
            formError["propertyType"] = "Please Select Property Type";
        }
        setFormError(formError)
        return isValid;
    }

    const SubmitPropertyType = (e) => {
        e.preventDefault();
        let isValid = errHandles();
        if (isValid) {
            const propertyTypeData = {
                AreaType: areatype,
                PropertyNames: selPropertyType.value
            }
            AddPropertyType(propertyTypeData)
                .then((res) => {
                    if (res.status === 201) {
                        Swal.fire({
                            icon: "success",
                            title: "Property Type created successfully.",
                            showConfirmButton: true,
                            confirmButtonColor: "#3085d6",
                            confirmButtonText: "ok",
                        });
                        handleClear();
                        getPropertyTypeList()
                    } else if (res.status === 400) {
                        Swal.fire({
                            icon: "warning",
                            title: "Aminity Already Exist",
                            showConfirmButton: true,
                            confirmButtonColor: "#3085d6",
                            confirmButtonText: "ok",
                        });
                    }
                }).catch((err) => {
                    console.log(err, "error in property type master");
                })
        }

    }

    // Edit property type
    const OnEdit = (typeData) => {
        setareaType(typeData.AreaType)
        setSelPropertyType({
            label: typeData.PropertyName,
            value: typeData.PropertyName,
        });
        setPropertyTypeId(typeData._id)
        setIsEditing(true);
    }

    //update property type
    const UpdatePropertyType = (e) => {
        e.preventDefault();
        let isValid = errHandles();
        if (isValid) {
            const propertyTypeData = {
                AreaType: areatype,
                PropertyNames: selPropertyType.value
            }
            updatePropertyType(propertyTypeData, propertyTypeId)
                .then((res) => {
                    if (res.status === 200) {
                        Swal.fire({
                            icon: "success",
                            title: "PropertyType Updated Successfully",
                            showConfirmButton: true,
                            confirmButtonColor: "#3085d6",
                            confirmButtonText: "ok",
                        });
                        handleClear();
                        getPropertyTypeList();
                    } else {
                        Swal.fire({
                            icon: "success",
                            title: "Property Type Not Updated! Please try again.",
                            showConfirmButton: true,
                            confirmButtonColor: "#3085d6",
                            confirmButtonText: "ok",
                        });
                    }
                }).catch((err) => {
                    console.log(err, "error in propertytype update");
                })
        }

    }
    //delete property type
    const DeleteType = (id) => {
        Swal.fire({
            icon: "warning",
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, cancel",
        }).then((result) => {
            if (result.isConfirmed) {
                DeletePropertyType(id)
                    .then((res) => {
                        if (res.status === 200) {
                            Swal.fire({
                                icon: "success",
                                title: "Property Type deleted successfully.",
                                showConfirmButton: true,
                                confirmButtonColor: "#3085d6",
                                confirmButtonText: "ok",
                            });
                            getPropertyTypeList();
                        } else {
                            Swal.fire({
                                icon: "warning",
                                title: "Property Type Not Deleted! Please try again.",
                                showConfirmButton: true,
                                confirmButtonColor: "#3085d6",
                                confirmButtonText: "ok",
                            });
                        }
                    })
                    .catch((err) => {
                        console.log(err, "error in property type delete");
                    });
            } else {
                Swal.fire({
                    icon: "info",
                    title: "Your Data is safe!",
                    showConfirmButton: true,
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "ok",
                });
            }
        });
    }

    return (
        <>
            <div>
                <AdminSidebar />
                <div className="container topNav-m">
                    <div className=" content-title">
                        <h3>PropertyType Master </h3>
                        <hr className="content-title-underline" />
                    </div>
                    <div className="container mt-2">
                        <form className="box-2">
                            <div className="row">
                                <h5 className="m-0">{!isEditing ? 'Add' : 'Update'} PropertyType</h5>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                    <div>
                                        <span className="details"> areaType</span>
                                        <FormControl variant="outlined" fullWidth>
                                            <TextField
                                                autoComplete="off"
                                                size="small"
                                                className="form-control"
                                                type="text"
                                                name="areatype"
                                                value={areatype}
                                                onChange={(e) => {
                                                    setareaType(e.target.value);
                                                    setFormError({ ...formError, areatype: "" });
                                                }}

                                            ></TextField>

                                            <div style={{ color: "red", fontSize: 13 }}>
                                                {formError.areatype}
                                            </div>
                                        </FormControl>
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div>
                                        <span className="details">PropertyType</span>
                                        <Select
                                            name="propertyType"
                                            value={selPropertyType}
                                            onChange={handlePropertyType}
                                            options={propertyType}
                                            size="small"
                                            classNamePrefix={"my-custom-react-select"}
                                        />
                                        <div style={{ color: "red", fontSize: 13 }}>
                                            {formError.propertyType}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3 ">
                                    <p className="m-0">&nbsp;</p>
                                    <div>
                                        {!isEditing ? (
                                            <button
                                                value="Submit"
                                                className="btn-red"
                                                onClick={(e) => SubmitPropertyType(e)}
                                            >
                                                Submit
                                            </button>
                                        ) : (
                                            <button
                                                value="Submit"
                                                className="btn-grey"
                                                onClick={(e) => UpdatePropertyType(e)}
                                            >
                                                Update
                                            </button>
                                        )}
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>


                {/* get property Data */}
                <div>
                    <div
                        className="container mt-5 justify-content-center"
                        style={{ marginTop: "10rem" }}
                    >
                        <div className="d-flex justify-content-end mb-2">
                            <input
                                type="text"
                                placeholder="Search"
                                value={searchTerm}
                                onChange={handleSearchChange}
                                style={{ padding: "5px", width: "250px" }}
                            />
                        </div>
                        <Paper
                            sx={{
                                width: "80%",
                                overflow: "hidden",
                                justifyContent: "center",
                            }}
                        >
                            <TableContainer sx={{ maxHeight: 700, overflowX: "auto" }}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Sr.No.</TableCell>
                                            <TableCell>PropertyType</TableCell>
                                            <TableCell>Property Name</TableCell>
                                            <TableCell>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {currentPageData && currentPageData.map((typelist, index) => {
                                            const serialNumber = offset + index + 1;
                                            return (
                                                <>
                                                    <TableRow key={index}>
                                                        <TableCell>{serialNumber}</TableCell>
                                                        <TableCell>{typelist.AreaType}</TableCell>
                                                        <TableCell>{typelist.PropertyNames}</TableCell>

                                                        <TableCell>
                                                            <IconButton>
                                                                <EditIcon
                                                                    color="primary"
                                                                    onClick={() => OnEdit(typelist)}
                                                                />
                                                            </IconButton>
                                                            <IconButton>
                                                                <DeleteOutlineOutlinedIcon
                                                                    color="error"
                                                                    onClick={() => DeleteType(typelist._id)}
                                                                />
                                                            </IconButton>

                                                        </TableCell>
                                                    </TableRow>
                                                </>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                                <div className="container">
                                    <ReactPaginate
                                        previousLabel={<NavigateBeforeIcon />}
                                        nextLabel={<NavigateNextIcon />}
                                        breakLabel={"..."}
                                        pageCount={pageCount}
                                        onPageChange={handlePageChange}
                                        containerClassName={"pagination"}
                                        activeClassName={"activepagination"}
                                        pageClassName={"pagination-item"}
                                    />
                                </div>

                            </TableContainer>
                        </Paper>


                    </div>
                </div>
            </div>
        </>
    )
}

export default PropertyTypeMaster;