const locationOptions = [
  { value: "Mumbai", label: "Mumbai" },
  { value: "Pune", label: "Pune" },
  { value: "Goa", label: "Goa" },
  { value: "Gujarat", label: "Gujarat" },
  // Add more options as needed
];

const subLocationOptions = [
  { value: "Borivali", label: "Borivali" },
  { value: "kharadi", label: "kharadi" },
  { value: "Panjim", label: "Panjim" },
  { value: "Surat", label: "Surat" },
  // Add more options as needed
];

const areaOptions = [
  { value: "Sub Location 1", label: "Sub Location 1" },
  { value: "Sub Location 2", label: "Sub Location 2" },
  // Add more options as needed
];
const locationsData = {
  Mumbai: [
    {
      label: "Andheri",
      value: "Andheri",
      areas: [
        "Aram Nagar",
        "Amboli",
        "Chakala",
        "D.N. Nagar",
        "Four Bungalows",
        "JB Nagar",
        "Kajuwadi",
        "Lokhandwala",
        "Marol",
        "Mogra Village",
        "Model Town",
        "Poonam Nagar",
        "Saki Naka",
        "Seven Bungalows",
        "Versova",
      ],
    },
    {
      label: "Bandra",
      value: "Bandra",
      areas: [
        "Bandra Kurla Complex",
        "Bandstand Promenade",
        "Gandhi Nagar",
        "M I G Colony",
        "Kherwadi",
        "Bharat Nagar",
        "Land's End",
        "Pali Hill",
        "Old Town",
        "Bandra Reclamation",
        "Chapel Avenue",
        "KC Marg",
        "Carter Road",
        "Kala Nagar",
        "Naupada",
      ],
    },
    {
      label: "Borivali",
      value: "Borivali",
      areas: [
        "I.C. Colony",
        "L.I.C. Colony aka Jeevan Bhima Nagar",
        "Eksar Colony",
        "Shimpoli",
        "Gorai",
        "Kora Kendra",
        "Vazira Naka",
        "Babhai",
        "Chikuwadi",
        "Yogi Nagar",
        "Borivali (East)",
        "Devipada",
        "Magathane",
        "Nancy Colony",
        "Sukurwadi",
      ],
    },
    {
      label: "Dahisar",
      value: "Dahisar",
      areas: [
        "NL Complex",
        "Mandapeshwar Caves",
        "Northern Heights",
        "Shakti Nagar",
        "Anand Nagar",
        "Ketkipada",
        "Anand Park",
        "Krishna Colony",
        "Rawalpada",
        "Ashok Van",
        "Balaji Colony",
        "Ekta Colony",
        "Maratha Nagar",
        "Konkani Pada",
        "CS Complex",
      ],
    },
    {
      label: "Vile Parle",
      value: "Vile Parle",
      areas: ["Irla", "Nehru Nagar", "Sahar"],
    },
    {
      label: "Dadar",
      value: "Dadar",
      areas: [
        "Hindu Colony",
        "Shivaji Park Residential Zone",
        "Parsi Colony",
        "Naigaon",
      ],
    },
  ],
  Pune: [
    {
      label: "Hadpsar",
      value: "Hadpsar",
      areas: [
        "Gadital (Bus Depot)",
        "Tukai Tekadi",
        "Bekarainagar (Bus Depot)",
        "Satavwadi",
        "Malwadi",
        "Gondhale Nagar",
        "Manjri Budruk (BK)",
        "Nandini SpringField",
        "Manjri Greens",
      ],
    },
    {
      label: "Aundh",
      value: "Aundh",
      areas: [
        "Gol Market",
        "I.T.I Aundh",
        "Breaman Chowk",
        "Ojha Mall",
        "Banesh Road",
      ],
    },
    {
      label: "Kharadi",
      value: "Kharadi",
      areas: [
        "Mundhwa",
        "Patilvasti",
        "Tulja Bhavani Nagar",
        "Wadgaon",
        "Chandan Nagar",
      ],
    },
    {
      label: "Chichwad",
      value: "Chichwad",
      areas: ["ShahuNagar", "Chinchewadi", "Nigdi", "Akurdi", "Pradhikaran"],
    },
    {
      label: "Koregaon Park",
      value: "Koregaon Park",
      areas: [
        "Iricen Railway Colony",
        "N main Road",
        "Pingle Chowk",
        "S Main Road",
        "GICC Office",
      ],
    },
    {
      label: "Shivaji Nagar",
      value: "Shivaji Nagar",
      areas: [
        "Deccan Bus Stop",
        "Shukravar Peth",
        "Swargate",
        "Mukund Nagar",
        "Dattawadi",
      ],
    },
    {
      label: "Pimpri",
      value: "Pimpri",
      areas: [
        "Pimpri Chowk",
        "KBS Road",
        "Morwadi ct Road",
        "Kalewadi",
        "Yashopuram Road",
      ],
    },
  ],
  Nagpur: [
    {
      label: "Wardhaman Nagar",
      value: "Wardhaman Nagar",
      areas: [
        "Middle Ring Road",
        "Vaishnavdevi Chawk",
        "S.R.Patel.Marg",
        "Bhandara Road",
      ],
    },
    {
      label: "Dharampeth",
      value: "Dharampeth",
      areas: [
        "Main Dharampeth Road",
        "Zenda Chwk",
        "Budha Vihar Marg",
        "LIT Road",
        "Nawab Well lane",
      ],
    },
    {
      label: "Ramdespeth",
      value: "Ramdespeth",
      areas: [
        "Lokmat Bhavan",
        "Dr N.L.Soni Marg",
        "Khare Marg",
        "Canel Road Square",
        "Kanipura Chowk",
      ],
    },
    {
      label: "Mankapur",
      value: "Mankapur",
      areas: ["Gorwada", "Jafar Nagar", "Baba Farid Nagar", "Azka Road"],
    },
    {
      label: "Ravi Nagar",
      value: "Ravi Nagar",
      areas: ["AmbaZari", "Shrunagar", "NTCA Office", "cp clb Road"],
    },
  ],
  Nashik: [
    {
      label: "Pathardi",
      value: "Pathardi",
      areas: [
        "Pandav nagari",
        "MIDC ambad",
        "tiradshet",
        "cidco",
        "belgaon Dhaga",
      ],
    },
    {
      label: "Deolali",
      value: "Deolali",
      areas: [
        "Gandhi Nagar",
        "Dada Saheb Falke Colony",
        "Muktidham Mandir",
        "Wadala Gaon",
        "Tagore Nagar",
      ],
    },
    {
      label: "Indira Nagar",
      value: "Indira Nagar",
      areas: [
        "Siddhivinayak Society Road",
        "Pethe Nagar",
        "Sant Charwak Chowk",
        "Mahatma Phule Chowk",
        "Sant Charwak Chowk",
      ],
    },
    {
      label: "Nashik Road",
      value: "Nashik Road",
      areas: [
        "Panchak",
        "Samata Nagar",
        "Pawar Wadi",
        "Eklahara Road",
        "Dwarka",
        "Vidhate Nagar",
        "Pune Road",
        "Canada Corner",
        "Panchwati",
        "Adgaon Naka",
        "Hirawadi",
        "Ayodhya Nagari",
      ],
    },
    { label: "Ambad Gaon", value: "Ambad Gaon", areas: ["u", "k"] },
  ],
};

const priceOptions = [
  { value: "1lakh-2lakh", label: "1lakh-2lakh" },
  { value: "3lakh-4lakh", label: "3lakh-4lakh" },
  // Add more options as needed
];
const amenitiesOption = [
  { value: "Water", label: "Water" },
  { value: "Light", label: "Light" },
  { value: "Lifts", label: "Lifts" },
  { value: "PlayGround Area", label: "PlayGround Area" },
];
const PropertyStatusOption = [
  { value: "UnderConstruction", label: "UnderConstruction" },
  { value: "Ready to Move", label: "Ready to Move" },
];

const PropertyTypeOption = [
  { value: "Flat / Apartment", label: "Flat / Apartment" },
  { value: "Residential House", label: "Residential House" },
  { value: "Villa", label: "Villa" },
  { value: "PentHouse", label: "PentHouse" },
  { value: "Studio Apartment", label: "Studio Apartment" },
];

const PropertyDoorDirectionOption = [
  { value: "North", label: "North" },
  { value: "South", label: "South" },
  { value: "East", label: "East" },
  { value: "West", label: "West" },
  { value: "Not Sure", label: "Not Sure" },
];
const FurnishedStatusOption = [
  { value: "Furnished", label: "Furnished" },
  { value: "Unfurnished", label: "Unfurnished" },
  { value: "Semi-Furnished", label: "Semi-Furnished" },
];
const NoticePeriodOption = [
  { value: "1 Week", label: "1 Week" },
  { value: "15 Days", label: "15 Days" },
  { value: "1 Month", label: "1 Month" },
  { value: "2 Month", label: "2 Month" },
  { value: "More Than 2 Months", label: "More Than 2 Months" },
  { value: "No Notice Period", label: "No Notice Period" },
];
const MonthsOption = [
  { value: "January", label: "January" },
  { value: "February", label: "February" },
  { value: "March", label: "March" },
  { value: "April", label: "April" },
  { value: "May", label: "May" },
  { value: "June", label: "June" },
  { value: "July", label: "July" },
  { value: "August", label: "August" },
  { value: "September", label: "September" },
  { value: "October", label: "October" },
  { value: "November", label: "November" },
  { value: "December", label: "December" },
];
const PurposeOfPropertyList=[
  { value: 'Buy', label: 'Buy' },
  { value: 'Rent', label: 'Rent' },
  // { value: 'RentHeavyDeposite', label: 'Rent with heavy deposite' }
];

// const PropertyAgeOption = [
//   { value: '1+', label: '1+' },
//   { value: '2+', label: '2+' },
//   { value: '3+', label: '3+' },
// ]
// Repeat the pattern for other dropdowns
const PropertyAgeOption = [];

for (let i = 1; i <= 100; i++) {
  const value = i === 1 ? "1+" : `${i}+`;
  const label = `${i}+`;

  PropertyAgeOption.push({ value, label });
}

const currentYear = new Date().getFullYear();
const nextTenYears = Array.from(
  { length: 11 },
  (_, index) => currentYear + index
);

const YearsOption = nextTenYears.map((year) => ({ value: year, label: year }));

const PropertyType = [
  { value: "Office Space", label: "Office Space" },
  { value: "Shop Showroom", label: "Shop Showroom" },
  { value: "Warehouse /Godown", label: "Warehouse /Godown" },
];

const FurnitureStatus = [
  { value: "Furnished", label: "Furnished" },
  { value: "Semi-Furnished", label: "Semi-Furnished" },
  { value: "Non-Furniture", label: "Non-Furniture" },
];

const titleOptions = [
  { value: "Mr", label: "Mr" },
  { value: "Ms", label: "Ms" },
  { value: "Mrs", label: "Mrs" },
];

const commercialPropertyTypeOptions = [
  { value: "Warehouse", label: "Warehouse" },
  { value: "Office Space", label: "Office Space" },
  { value: "Shop/showrooms", label: "Shop/showrooms" },
  { value: "Commercial land", label: "Commercial land" },
];

const commercialPosterType = [
  { value: "Owner", label: "Owner" },
  { value: "Agent", label: "Agent" },
  { value: "Builder", label: "Builder" },
];

const noticePeriod = [
  { value: "1 Month", label: "1 Month" },
  { value: "2 Month", label: "2 Month" },
  { value: "3 Months", label: "3 Months" },
  { value: "4 Months", label: "4 Months" },
  { value: "5 Months", label: "5 Months" },
  { value: "6 Months", label: "6 Months" },
];

const AgreementDur = [
  { value: "6 Month", label: "6 Month" },
  { value: "12 Month", label: "12 Month" },
  { value: "18 Months", label: "18 Months" },
  { value: "24 Months", label: "24 Months" },
  { value: "30 Months", label: "30 Months" },
  { value: "36 Months", label: "36 Months" },
  { value: "42 Months", label: "42 Months" },
  { value: "48 Months", label: "48 Months" },
  { value: "54 Months", label: "54 Months" },
  { value: "60 Months", label: "60 Months" },
];

const rentpayment = [
  { label: "1st of every month", value: "1st of every month" },
  { label: "2nd of every month", value: "2nd of every month" },
  { value: "3rd of every month", label: "3rd of every month" },
  { value: "4th of every month", label: "4th of every month" },
  { value: "5th of every month", label: "5th of every month" },
  { value: "6th of every month", label: "6th of every month" },
  { value: "8th of every month", label: "8th of every month" },
  { value: "9th of every month", label: "9th of every month" },
  { value: "10th of every month", label: "10th of every month" },
  { value: "11th of every month", label: "11th of every months" },
  { value: "12th of every month", label: "12th of every months" },
  { value: "13th of every month", label: "13th of every month" },
  { value: "14th of every month", label: "14th of every month" },
  { value: "15th of every month", label: "15th of every month" },
  { value: "16th of every month", label: "16th of every month" },
  { value: "17th of every month", label: "17th of every month" },
  { value: "18th of every month", label: "18th of every month" },
  { value: "19th of every month", label: "19th of every month" },
  { value: "20th of every month", label: "20th of every month" },
  { value: "21th of every month", label: "21th of every month" },
  { value: "22th of every month", label: "22th of every month" },
  { value: "23th of every month", label: "23th of every month" },
  { value: "24th of every month", label: "24th of every month" },
  { value: "25th of every month", label: "25th of every month" },
  { value: "26th of every month", label: "26th of every month" },
  { value: "27th of every month", label: "27th of every month" },
  { value: "28th of every month", label: "28th of every month" },
  { value: "29th of every month", label: "29th of every month" },
  { value: "30th of every month", label: "30th of every month" },
  { value: "31st of every month", label: "31th of every month" },
];
const PropertyAreaList = [
  {
    id: 1,
    value: "1 Bhk",
    label: "1 Bhk",
  },
  {
    id: 2,
    value: "2 Bhk",
    label: "2 Bhk",
  },
  {
    id: 3,
    value: "3 Bhk",
    label: "3 Bhk",
  },
  {
    id: 4,
    value: "4 Bhk",
    label: "4 Bhk",
  },

  {
    id: 5,
    value: "GlobalhillView",
    label: "Global HillView",
  },
  {
    id: 6,
    value: "Construction",
    label: "Construction",
  },
  {
    id: 7,
    value: "HighStreet",
    label: "High Street",
  },
];
const noOfCabinsList= [
  {
    name: "1+",
    checked: false,
    value: "1",
  },
  {
    name: "2+",
    checked: false,
    value: "2",
  },
  {
    name: "3+",
    checked: false,
    value: "3",
  },
  {
    name: "4+",
    checked: false,
    value: "4",
  },
  {
    name: "5+",
    checked: false,
    value: "5",
  },
]

const noOfBedroomsList =[
  {
    name: "1 BHK",
    checked: false,
    value: "1",
  },
  {
    name: "2 BHK",
    checked: false,
    value: "2",
  },
  {
    name: "3 BHK",
    checked: false,
    value: "3",
  },
  {
    name: "4 BHK",
    checked: false,
    value: "4",
  },
  {
    name: "4+ BHK",
    checked: false,
    value: "5",
  },
]

const postedByList =[
  {
    name: "Owner",
    checked: false,
    value: "Owner",
  },
  {
    name: "Agent",
    checked: false,
    value: "Agent",
  },
]

const noOfFloorsList= [
  {
    name: "Basement",
    checked: false,
    value: "Basement",
  },
  {
    name: "Ground",
    checked: false,
    value: "Ground",
  },
  {
    name: "1-4",
    checked: false,
    value: "[1,2,3,4]",
  },
  {
    name: "5-8",
    checked: false,
    value: "[5,6,7,8]",
  },
  {
    name: "9-12",
    checked: false,
    value: "[9,10,11,12]",
  },
  {
    name: "13-16",
    checked: false,
    value: "[13,14,15,16]",
  },
  {
    name: "16+",
    checked: false,
    value: "[17,18,19,20]",
  },
]
const furnishingList=[
  {
    name: "Full",
    checked: false,
    value: "Furnished",
  },
  {
    name: "Semi",
    checked: false,
    value: "Semi-Furnished",
  },
  {
    name: "None",
    checked: false,
    value: "Unfurnished",
  },
]
const date = new Date();
const currentMonth = date.toLocaleString("default", { month: "long" });
const availabilityList=[
  {
    name: "Immediate",
    checked: false,
    value: currentMonth,
  },
  {
    name: "Within 15 days",
    checked: false,
    value: "Within 15 days",
  },
  {
    name: "Within 30 days",
    checked: false,
    value: "Within 30 days",
  },
  {
    name: "After 45 days",
    checked: false,
    value: "After 45 days",
  },
]
export {
  locationOptions,
  subLocationOptions,
  areaOptions,
  priceOptions,
  amenitiesOption,
  PropertyTypeOption,
  PropertyAgeOption,
  locationsData,
  PropertyStatusOption,
  PropertyDoorDirectionOption,
  FurnishedStatusOption,
  NoticePeriodOption,
  MonthsOption,
  YearsOption,
  PropertyType,
  FurnitureStatus,
  titleOptions,
  commercialPropertyTypeOptions,
  commercialPosterType,
  AgreementDur,
  rentpayment,
  noticePeriod,
  PropertyAreaList,
  PurposeOfPropertyList,
  noOfCabinsList,
  noOfBedroomsList,
  postedByList,
  noOfFloorsList,
  furnishingList,
  availabilityList
};
