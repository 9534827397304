/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";

import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import {
  // locationOptions,
  // subLocationOptions,
  // areaOptions,
  locationsData,
  amenitiesOption,
  PropertyTypeOption,
  PropertyAgeOption,
} from "../../../modules/helpers/constant";

import { IpfsImgUpload } from "../../../modules/helpers/Ipfs";
import { settings } from "../../../Config/Settings";
import Swal from "sweetalert2";
import { useLocation, useNavigate } from "react-router-dom";

import { addproject, updateproject } from "../../../Api/ProjectAPI";
import AdminSidebar from "../../../Components/Sidebar/AdminSidebar";
import { getLocationData } from "../../../Api/AuthAPI";
function AddProject() {
  const inputRefrence1 = useRef(null);
  const { state } = useLocation();
  const navigate = useNavigate();
  const [selectedImage1, setSelectedImage1] = useState(null);
  const [selectedImage2, setSelectedImage2] = useState(null);
  const [selectedImage3, setSelectedImage3] = useState(null);
  const [selectedImage4, setSelectedImage4] = useState(null);
  const [location, setLocation] = useState("");
  const [subLocation, setSubLocation] = useState("");
  const [area, setArea] = useState("");
  const [price, setPrice] = useState("");
  const [amenities, setAmenities] = useState([]);
  const [propertyType, setPropertyType] = useState("");
  const [age, setAge] = useState("");
  const [selectedPostedby, setSelectedPostedby] = useState("");
  const [projectId, setProjectId] = useState(0);
  const [locationData, setLocationData] = useState([]);
  const [formError, setformError] = useState([]);

  useEffect(() => {
    if (state && Object.keys(state).length !== 0) {
      setProjectId(state._id);
      setLocation(state.Location);
      setSubLocation(state.subLocation);
      setArea(state.Area);
      setPrice(state.Price);
      setAmenities(state.Amenities);
      setPropertyType(state.propertyType);
      setAge(state.propertyAge);
      setSelectedImage1(state.propertyImg1);
      setSelectedImage2(state.propertyImg2);
      setSelectedImage3(state.propertyImg3);
      setSelectedImage4(state.propertyImg4);
      setSelectedPostedby(state.postedBy);
      navigate(location.pathname, { replace: true });
    }
  }, []);

  const validates = () => {
    let formError = {};
    let isValid = true;

    if (location === "") {
      isValid = false;
      formError["location"] = "Please Enter Location";
    }

    if (subLocation === "") {
      isValid = false;
      formError["subLocation"] = "Please Enter SubLocation";
    }
    if (area === "") {
      isValid = false;
      formError["area"] = "Please Enter Area";
    }
    if (price === "") {
      isValid = false;
      formError["price"] = "Please Enter Price";
    }
    if (amenities.length < 1) {
      isValid = false;
      formError["amenities"] = "Please Enter Aminities";
    }

    if (propertyType === "") {
      isValid = false;
      formError["propertyType"] = "Please Enter Property Type";
    }
    if (age === "") {
      isValid = false;
      formError["age"] = "Please Enter Property Age";
    }
    if (selectedPostedby === "") {
      isValid = false;
      formError["selectedPostedby"] = "Please Select PostedBy";
    }

    if (selectedImage1 === null) {
      isValid = false;
      formError["selectedImage1"] = "Please Select Image";
    }
    // if (selectedImage2 === null) {
    //   isValid = false;
    //   formError["selectedImage2"] = "Please Select Image";
    // }

    // if (selectedImage3 === null) {
    //   isValid = false;
    //   formError["selectedImage3"] = "Please Select Image";
    // }
    // if (selectedImage4 === null) {
    //   isValid = false;
    //   formError["selectedImage4"] = "Please Select Image";
    // }

    setformError(formError);
    return isValid;
  };

  const handleImageChange1 = async (event) => {
    const imageFile = await IpfsImgUpload(event.target.files[0]);
    setSelectedImage1(imageFile);
    setformError({ ...formError, selectedImage1: "" });
  };

  const handleImageChange2 = async (event) => {
    const imageFile = await IpfsImgUpload(event.target.files[0]);
    setSelectedImage2(imageFile);
    setformError({ ...formError, selectedImage2: "" });
  };
  const handleImageChange3 = async (event) => {
    const imageFile = await IpfsImgUpload(event.target.files[0]);
    setSelectedImage3(imageFile);
    setformError({ ...formError, selectedImage3: "" });
  };
  const handleImageChange4 = async (event) => {
    const imageFile = await IpfsImgUpload(event.target.files[0]);
    setSelectedImage4(imageFile);
    setformError({ ...formError, selectedImage4: "" });
  };

  const handleLocationChange = (event) => {
    // setLocation(event.target.value);
    setformError({ ...formError, location: "" });
    const newLocation = event.target.value;
    setLocation(newLocation);
    setSubLocation(""); // Reset subLocation when location changes
    setArea(""); // Reset area when location changes
  };

  const handlesubLocationChange = (event) => {
    // setSubLocation(event.target.value);
    setformError({ ...formError, subLocation: "" });
    const newSubLocation = event.target.value;
    setSubLocation(newSubLocation);
    setArea("");
  };
  const handleAreaChange = (event) => {
    setArea(event.target.value);
    setformError({ ...formError, area: "" });
  };

  const handleAmenitiesChange = (event) => {
    setAmenities(event.target.value);
    setformError({ ...formError, amenities: "" });
  };
  const handleTypeChange = (event) => {
    if (event.target.value === "UnderConstruction") {
      setAge("");
    }
    setPropertyType(event.target.value);
    setformError({ ...formError, propertyType: "" });
  };
  const handleAgeChange = (event) => {
    setAge(event.target.value);
    setformError({ ...formError, age: "" });
  };

  const handleRadioButton = (event) => {
    setSelectedPostedby(event.target.value);
    setformError({ ...formError, selectedPostedby: "" });
  };

  const SubmitProject = async (e) => {
    e.preventDefault();
    if (validates()) {
      // let image1 = await IpfsImgUpload(selectedImage1);
      // let image2 = await IpfsImgUpload(selectedImage2);
      // let image3 = await IpfsImgUpload(selectedImage3);
      // let image4 = await IpfsImgUpload(selectedImage4);
      const propertyData = {
        Location: location,
        subLocation: subLocation,
        Area: area,
        Price: price,
        Amenities: amenities,
        propertyType: propertyType,
        propertyAge: age,
        propertyImg1: selectedImage1,
        propertyImg2: selectedImage2,
        propertyImg3: selectedImage3,
        propertyImg4: selectedImage4,
        postedBy: selectedPostedby,
      };
      addproject(propertyData)
        .then((res) => {
          if (res.data.status === 1) {
            Swal.fire({
              icon: "success",
              title: "Project added Successfully",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              // cancelButtonColor: '#d33',
              confirmButtonText: "ok",
            });
            refreshData();
          } else {
            Swal.fire({
              icon: "error",
              title: "Something went wrong.",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              // cancelButtonColor: '#d33',
              confirmButtonText: "ok",
            });
          }
        })
        .catch((err) => {
          console.log(err, "err in add project");
        });
    }
  };
  const UpdateProject = async (e) => {
    e.preventDefault();
    if (validates()) {
      // let image1 =
      //   selectedImage1.name !== undefined
      //     ? await IpfsImgUpload(selectedImage1)
      //     : selectedImage1;
      // let image2 =
      //   selectedImage2.name !== undefined
      //     ? await IpfsImgUpload(selectedImage2)
      //     : selectedImage2;
      // let image3 =
      //   selectedImage3.name !== undefined
      //     ? await IpfsImgUpload(selectedImage3)
      //     : selectedImage3;
      // let image4 =
      //   selectedImage4.name !== undefined
      //     ? await IpfsImgUpload(selectedImage4)
      //     : selectedImage4;
      let data = {
        Location: location,
        subLocation: subLocation,
        Area: area,
        Price: price,
        Amenities: amenities,
        propertyType: propertyType,
        propertyAge: age,
        propertyImg1: selectedImage1,
        propertyImg2: selectedImage2,
        propertyImg3: selectedImage3,
        propertyImg4: selectedImage4,
        postedBy: selectedPostedby,
      };
      updateproject(data, projectId)
        .then((res) => {
          if (res.data.status === 1) {
            Swal.fire({
              icon: "success",
              title: "Project Updated Successfully",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              // cancelButtonColor: '#d33',
              confirmButtonText: "ok",
            });
            refreshData();
          } else {
            Swal.fire({
              icon: "error",
              title: "Something went wrong.",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              // cancelButtonColor: '#d33',
              confirmButtonText: "ok",
            });
          }
        })
        .catch((err) => {
          console.log(err, "err in update rent property.");
        });
    }
  };

  const refreshData = () => {
    setLocation("");
    setSubLocation("");
    setArea("");
    setAge("");
    setAmenities([]);
    setPrice("");
    setSelectedImage1(null);
    setSelectedImage2(null);
    setSelectedImage3(null);
    setSelectedImage4(null);
    setPropertyType("");
    setSelectedPostedby("");
    setProjectId(0);
    inputRefrence1.current.value = null;
  };

  useEffect(()=>{
    getLocationData()
    .then((res)=>{
      
      if(res.status === 200){
        let locationData = res.data.locations;
        setLocationData(locationData)
      }
    }).catch((err)=>{
         console.log(err,"error in location get call find property")
    })
  },[])

  const locationOptions = Object.keys(locationsData).map((location) => ({
    label: location,
    value: location,
  }));

  const subLocationOptions = location
    ? locationsData[location].map((subLocation) => ({
        label: subLocation.label,
        value: subLocation.value,
      }))
    : [];

  const areaOptions = subLocation
    ? locationsData[location]
        .find((item) => item.value === subLocation)
        .areas.map((area) => ({
          label: area,
          value: area,
        }))
    : [];

  return (
    <div>
      {/* <AdminHeader /> */}
      <AdminSidebar />

      <div className="container mt-2">
        <div className="text-center mb-3">
          <h2 className="font-weight-bold">Add Project</h2>
        </div>
        <form className="add-update-form">
          <div className="user__details p-4 m-2">
            <div className="row p-2">
              <div className="col-md-6">
                <div className="input__box">
                  <span className="details"> Location</span>

                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>select Location</InputLabel>

                    <Select
                      label="Location"
                      name="Location"
                      value={location}
                      required
                      onChange={handleLocationChange}
                    >
                      {locationData.map((option) => (
                        <MenuItem key={option._id} value={option.locationName}>
                          {option.locationName}
                        </MenuItem>
                      ))}
                    </Select>
                    <div style={{ color: "red", fontSize: 13 }}>
                      {formError.location}
                    </div>
                  </FormControl>
                </div>
              </div>
              <div className="col-md-6">
                <div className="input__box">
                  <span className="details"> SubLocation</span>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>Select SubLocation</InputLabel>
                    <Select
                      label="Phone Number"
                      value={subLocation}
                      onChange={handlesubLocationChange}
                      required
                    >
                      {subLocationOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <div style={{ color: "red", fontSize: 13 }}>
                      {formError.subLocation}
                    </div>
                  </FormControl>
                </div>
              </div>
              <div className="col-md-6">
                <div className="input__box">
                  <span className="details">Area</span>

                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>Select Area</InputLabel>

                    <Select
                      label="Phone Number"
                      value={area}
                      onChange={handleAreaChange}
                      required
                    >
                      {areaOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <div style={{ color: "red", fontSize: 13 }}>
                      {formError.area}
                    </div>
                  </FormControl>
                </div>
              </div>
              <div className="col-md-6">
                <div className="input__box">
                  <span className="details">Price</span>
                  <FormControl variant="outlined" fullWidth>
                    {/* <InputLabel>Property Price</InputLabel> */}

                    <TextField
                      label="Price"
                      type="Number"
                      value={price}
                      onChange={(e) => {
                        setPrice(e.target.value);
                        setformError({ ...formError, price: "" });
                      }}
                      required
                      //   renderValue={(selected) => selected.join(', ')} // Display selected items
                    ></TextField>
                    <div style={{ color: "red", fontSize: 13 }}>
                      {formError.price}
                    </div>
                  </FormControl>
                </div>
              </div>
              <div className="col-md-6">
                <div className="input__box">
                  <span className="details">Amenities</span>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>Select Amenities</InputLabel>
                    <Select
                      label="Amenities"
                      multiple
                      value={amenities}
                      onChange={handleAmenitiesChange}
                      required
                      // renderValue={(selected) => selected.join(', ')} // Display selected items
                    >
                      {amenitiesOption.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <div style={{ color: "red", fontSize: 13 }}>
                      {formError.amenities}
                    </div>
                  </FormControl>
                </div>
              </div>
              <div className="col-md-6">
                <div className="input__box">
                  <span className="details">Type</span>

                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>Type</InputLabel>

                    <Select
                      label="Phone Number"
                      value={propertyType}
                      onChange={handleTypeChange}
                      required
                    >
                      {PropertyTypeOption.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <div style={{ color: "red", fontSize: 13 }}>
                      {formError.propertyType}
                    </div>
                  </FormControl>
                </div>
              </div>
              <div className="col-md-6">
                <div className="input__box p-2">
                  <span className="details">Property Image1</span>

                  <FormControl variant="outlined" fullWidth>
                    <input
                      accept="image/*"
                      type="file"
                      onChange={handleImageChange1}
                      required
                      ref={inputRefrence1}
                    />

                    {selectedImage1 !== null && (
                      <a
                        href={`${settings.IMAGE_URL}/${selectedImage1}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Click here to view IPFS content
                      </a>
                    )}
                  </FormControl>
                  <div style={{ color: "red", fontSize: 13 }}>
                    {formError.selectedImage1}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="input__box p-2">
                  <span className="details">Property Image2</span>

                  <FormControl variant="outlined" fullWidth>
                    <input
                      accept="image/*"
                      type="file"
                      onChange={handleImageChange2}
                      required
                      ref={inputRefrence1}
                    />
                    {/* {selectedImage2 && (
                  <div>
                    <p>Image Preview:</p>

                    <img
                      src={
                        selectedImage2.name !== undefined
                          ? URL.createObjectURL(selectedImage2)
                          : `${settings.IMAGE_URL}/${selectedImage2}`
                      }
                      alt="Preview"
                      height="100"
                      width="100"
                    />
                  </div>
                )} */}
                    {selectedImage2 !== null && (
                      <a
                        href={`${settings.IMAGE_URL}/${selectedImage2}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Click here to view IPFS content
                      </a>
                    )}
                  </FormControl>
                  <div style={{ color: "red", fontSize: 13 }}>
                    {formError.selectedImage2}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="input__box p-2">
                  <span className="details">Property Image3</span>

                  <FormControl variant="outlined" fullWidth>
                    <input
                      accept="image/*"
                      type="file"
                      onChange={handleImageChange3}
                      required
                      ref={inputRefrence1}
                    />
                    {/* {selectedImage3 && (
                  <div>
                    <p>Image Preview:</p>

                    <img
                      src={
                        selectedImage3.name !== undefined
                          ? URL.createObjectURL(selectedImage3)
                          : `${settings.IMAGE_URL}/${selectedImage3}`
                      }
                      alt="Preview"
                      height="100"
                      width="100"
                    />
                  </div>
                )} */}
                    {selectedImage3 !== null && (
                      <a
                        href={`${settings.IMAGE_URL}/${selectedImage3}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Click here to view IPFS content
                      </a>
                    )}
                  </FormControl>
                  <div style={{ color: "red", fontSize: 13 }}>
                    {formError.selectedImage3}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="input__box p-2">
                  <span className="details">Property Image4</span>

                  <FormControl variant="outlined" fullWidth>
                    <input
                      accept="image/*"
                      type="file"
                      onChange={handleImageChange4}
                      required
                      ref={inputRefrence1}
                    />
                    {/* {selectedImage4 && (
                  <div>
                    <p>Image Preview:</p>

                    <img
                      src={
                        selectedImage4.name !== undefined
                          ? URL.createObjectURL(selectedImage4)
                          : `${settings.IMAGE_URL}/${selectedImage4}`
                      }
                      alt="Preview"
                      height="100"
                      width="100"
                    />
                  </div>
                )} */}
                    {selectedImage4 !== null && (
                      <a
                        href={`${settings.IMAGE_URL}/${selectedImage4}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Click here to view IPFS content
                      </a>
                    )}
                  </FormControl>
                  <div style={{ color: "red", fontSize: 13 }}>
                    {formError.selectedImage4}
                  </div>
                </div>
              </div>{" "}
              <div className="col-md-6">
                <div className="input__box">
                  <span className="details">Property Age</span>

                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>Age</InputLabel>

                    <Select
                      label="Phone Number"
                      value={age}
                      onChange={handleAgeChange}
                      // required
                      disabled={
                        propertyType === "UnderConstruction" ||
                        propertyType === ""
                      }
                    >
                      {PropertyAgeOption.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <div style={{ color: "red", fontSize: 13 }}>
                      {formError.age}
                    </div>
                  </FormControl>
                </div>
              </div>
              <div className="col-md-6">
                {/* <div className="gender__details"> */}
                <span className="gender__title">Posted By</span>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="postedby"
                    id="postedby1"
                    value="Owner"
                    checked={selectedPostedby === "Owner"}
                    onChange={handleRadioButton}
                    // checked
                  />
                  <label className="form-check-label" htmlFor="postedby1">
                    Owner
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="postedby"
                    id="postedby2"
                    value="Agent"
                    checked={selectedPostedby === "Agent"}
                    onChange={handleRadioButton}
                  />
                  <label className="form-check-label" htmlFor="postedby2">
                    Agent
                  </label>
                </div>
                {/* </div> */}
                <div style={{ color: "red", fontSize: 13 }}>
                  {formError.selectedPostedby}
                </div>
              </div>
            </div>
            <div className="form-group d-flex justify-content-center mt-4">
              {projectId === 0 ? (
                <button
                  type="submit"
                  value="Submit"
                  className="btn btn-primary"
                  onClick={SubmitProject}
                >
                  Submit
                </button>
              ) : (
                <button
                  type="submit"
                  value="Update"
                  className="btn btn-primary"
                  onClick={UpdateProject}
                >
                  Update
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddProject;
