import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import { Toast, ToastContainer } from "react-bootstrap";
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import {
    PropertyAreaList,
} from "../../modules/helpers/constant";
import { getLocationData, getPropertytypeByTypeData } from "../../Api/AuthAPI";
import { Autocomplete, TextField } from "@mui/material";
import Auth from "../../Config/helper/Auth";
import Select from 'react-select';


const NewProjectSearchProperty = (props) => {
    const userRole = Auth?.getUserDetails().role;

    const [newProjectLocation, setNewProjectLocation] = useState("");
    const [propertyTypeProjects, setPropertyTypeProject] = useState([]);
    const [minProjectPrice, setMinProjectPrice] = useState(null);
    const [maxProjectPrice, setMaxProjectPrice] = useState(null);
    const [locationData, setLocationData] = useState([]);
    const [anchAreaPrice, setAnchAreaPrice] = useState(null);
    const [propertyAreaList, setPropertyAreaList] = useState([])
    const target = useRef(null);
    let navigate = useNavigate();
    const [error, setError] = useState({});
    const [show, setShow] = useState(false);
    const [showToast, setShowToast] = useState(false);

    const openPriceR = Boolean(anchAreaPrice);
    const newProjectPriceId = openPriceR ? "simple-popover" : undefined;

    useEffect(() => {
        getpropertyType("Construction")
    }, [])

    const getpropertyType = (key) => {
        getPropertytypeByTypeData(key)
            .then((res) => {
                if (res.status === 200) {
                    let result = res.data.data;
                    let list = result.map((data) => ({ label: data.AreaType, value: data._id }));
                    setPropertyAreaList(list);
                }
            }).catch((err) => {
                console.log(err, "error in property get data");
            })
    }

    const validate = () => {
        let error = {};
        let isValid = true;
        if (newProjectLocation === "" || newProjectLocation === null) {
            isValid = false;
            error["newProjectLocation"] = "Please Enter Location";
        }
        if (!isValid) {
            setError(error);
            setShowToast(true);
        }
        return isValid;
    };

    const handleSearch = (key) => {
        if (validate()) {
            navigate("/filterProperties", {
                state: {

                    userId: userRole === "Admin" ? null : props.userId._id,
                    // userId: props.userId._id,
                    propertyType: key,
                    location:
                        key === "Construction" && newProjectLocation,
                    areaTypes:
                        key === "Construction" && propertyTypeProjects.label,
                    minPrice:
                        key === "Construction" && minProjectPrice,
                    maxPrice:
                        key === "Construction" && maxProjectPrice,
                    isVerified:
                        key === "Construction" && props.isVerified,


                },
            });
        }

    };


    useEffect(() => {
        getLocationData()
            .then((res) => {
                if (res.status === 200) {
                    let locationData = res.data.locations;
                    setLocationData(locationData);
                } else {
                    setLocationData([])
                }
            })
            .catch((err) => {
                console.log(err, "error in location get call find property");
                setLocationData([])
            });
    }, []);



    const resetData = () => {
        setShowToast(false);
        setError({});
        setNewProjectLocation("");
        setPropertyTypeProject([]);
        setMinProjectPrice(null);
        setMaxProjectPrice(null);
        setAnchAreaPrice(null)

    };

    const handleClose = () => {
        setAnchAreaPrice(null);
    };



    return (

        <div className="row d-flex justify-content-center">
            <div className="col-sm-8">
                <div className="builder-search-box position-relative ">


                    <div className="builder-search ">
                        <h2 className="tabs-heading">Projects</h2>
                        {/* <select
                                    ref={target}
                                    name="city"
                                    id="city"
                                    className="txtfield"
                                    value={newProjectLocation}
                                    onChange={(e) => {
                                        setShow(false);
                                        setNewProjectLocation(e.target.value);
                                        setError({
                                            ...error,
                                            newProjectLocation: "",
                                        });
                                    }}
                                >
                                    <option value="" disabled selected>
                                        Location
                                    </option>
                                    {locationData.map((option) => (
                                        <option
                                            key={option._id}
                                            value={option.locationName}
                                        >
                                            {option.locationName}
                                        </option>
                                    ))}
                                </select> */}
                        <Autocomplete
                            value={newProjectLocation}
                            onChange={(event, newValue) => {
                                if (typeof newValue === 'string') {
                                    setNewProjectLocation(newValue);
                                } else if (newValue && newValue.inputValue) {
                                    setNewProjectLocation(newValue.inputValue);
                                } else {
                                    setNewProjectLocation(newValue);
                                }
                                setError({ ...error, newProjectLocation: '' });
                            }}
                            options={locationData.map((option) => option.locationName)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Location"
                                    variant="outlined"
                                    size="small"
                                />
                            )}
                            style={{ width: 300 }}
                        />
                        <ToastContainer position="top-center">
                            <Toast
                                show={showToast}
                                onClose={() => setShowToast(false)}
                                delay={2000}
                                autohide
                                className="bg-light text-danger"
                            >
                                <Toast.Body>
                                    {Object.values(error).join(". ")}
                                </Toast.Body>
                            </Toast>
                        </ToastContainer>

                        <Select
                            defaultValue={propertyTypeProjects}
                            onChange={setPropertyTypeProject}
                            options={propertyAreaList}

                        />
                        {/* <select
                                    name="propertyTypeProjects"
                                    id="propertyTypeProjects"
                                    className="txtfield "
                                    value={propertyTypeProjects}
                                    onChange={(e) => {
                                        setPropertyTypeProject(e.target.value);
                                        setError({
                                            ...error,
                                            propertyTypeProjects: "",
                                        });
                                    }}
                                >
                                    <option value="" disabled selected>
                                        Property Type
                                    </option>
                                    {propertyAreaList.map((option) => (
                                        <option
                                            key={option.value}
                                            value={option.label}
                                        >
                                            {option.label}
                                        </option>
                                    ))}
                                </select> */}
                        <div>
                            <button
                                aria-describedby={newProjectPriceId}
                                variant="contained"
                                onClick={(e) =>
                                    setAnchAreaPrice(e.currentTarget)
                                }
                                className="txtfield w-150"
                            >
                                {minProjectPrice === null &&
                                    maxProjectPrice === null
                                    ? "Price"
                                    : `${minProjectPrice} - ${maxProjectPrice}`}
                            </button>
                            <Popover
                                id={newProjectPriceId}
                                open={openPriceR}
                                anchorEl={anchAreaPrice}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                            >
                                <div className="row p-2">
                                    <div className="col-md-6">
                                        <input
                                            type="number"
                                            id="min"
                                            name="Min"
                                            className="txtfield m-1"
                                            placeholder="Min Price"
                                            onChange={(e) =>
                                                setMinProjectPrice(e.target.value)
                                            }
                                            value={minProjectPrice}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            type="number"
                                            id="max"
                                            name="Max"
                                            className="txtfield m-1"
                                            placeholder="Max Price"
                                            onChange={(e) =>
                                                setMaxProjectPrice(e.target.value)
                                            }
                                            value={maxProjectPrice}
                                        />
                                    </div>
                                </div>
                            </Popover>
                        </div>

                        <Button onClick={() => handleSearch("Construction")} className="search-btn w-25">Search</Button>
                        <div className="clear-btn-box">
                            <Button onClick={() => resetData()} className="clear-btn"><ClearOutlinedIcon /> Clear</Button>

                        </div>
                    </div>


                </div>

            </div>
        </div>
    );
};
export default NewProjectSearchProperty