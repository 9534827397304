import React from "react";
import * as MdIcons from "react-icons/md";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";
import * as FaIcons from "react-icons/fa";
import { TbListDetails } from "react-icons/tb";
import { MdFreeCancellation } from "react-icons/md";
import { RiVerifiedBadgeFill } from "react-icons/ri";
import { RiPassPendingFill } from "react-icons/ri";
import { BsBuildingsFill } from "react-icons/bs";
import { SiElectronbuilder } from "react-icons/si";
import { AiFillPropertySafety } from "react-icons/ai";
import { SiCplusplusbuilder } from "react-icons/si";
import { GrDocumentUser } from "react-icons/gr";
import { MdOutlinePreview } from "react-icons/md";
import { IoLocationSharp } from "react-icons/io5";
import { GrServices } from "react-icons/gr";
import { MdReviews } from "react-icons/md";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { FaClipboardQuestion } from "react-icons/fa6";
import Auth from "../../Config/helper/Auth";
import { CgProfile } from "react-icons/cg";
import SortIcon from '@mui/icons-material/Sort';
var userRole = Auth?.getUserRole();

export const SidebarData =
  userRole === "Admin"
    ? [
      {
        title: "Dashboard",
        icon: <RiIcons.RiHomeOfficeLine color="#15171c" title="Dashboard" />,
        path: "/adminDashboard",
      },
      {
        title: "Properties",
        icon: (
          <MdIcons.MdOutlineRealEstateAgent
            color="#15171c"
            title="Properties"
          />
        ),
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        // path: "/properties",
        subNav: [
          {
            title: "Pending Properties",
            path: "/properties",
            icon: <RiPassPendingFill color="#15171c" />,
            cName: "sub-nav",
          },
          {
            title: "Verified Properties",
            path: "/verifiedProperties",
            icon: <RiVerifiedBadgeFill color="#15171c" />,
            cName: "sub-nav",
          },
          {
            title: "Rejected Properties",
            path: "/rejectedProperties",
            icon: <MdFreeCancellation color="#15171c" />,
            cName: "sub-nav",
          },
        ],
      },
      // {
      //   title: "Payment Details",
      //   icon: <MdIcons.MdPayments color="#15171c" />,
      //   path: "/paymentList",
      // },
      {
        title: "Payment Details",
        icon: <MdIcons.MdPayments color="#15171c" title="Payment Details" />,
        path: "/receiptList",
      },
      {
        title: "Listed Owners",
        icon: <FaIcons.FaUserTie color="#15171c" title="Listed Owners" />,
        path: "/ownerList",
      },
      {
        title: "Listed Agents",
        icon: <FaIcons.FaHouseUser color="#15171c" title="Listed Agents" />,
        path: "/agentList",
      },
      // {
      //   title: "Receipt",
      //   icon: <LiaFileInvoiceDollarSolid color="#15171c" />,
      //   path: "/receiptList",
      // },
      {
        title: "Enquiry Details",
        icon: <FaClipboardQuestion color="#15171c" title="Enquiry Details" />,
        path: "/enquieyDetails",
      },
      {
        title: "Location Master",
        icon: (
          <IoLocationSharp color="#15171c" title="Manage Location Master" />
        ),
        path: "/locationMaster",
      },
      {
        title: "Amenities Master",
        icon: <GrServices color="#15171c" title="Manage Amenities " />,
        path: "/amenitiesMaster",
      },
      {
        title: "Review Master",
        icon: <MdReviews color="#15171c" />,
        path: "/blockRequest",
      },
      {
        title: "PropertyType Master",
        icon: <AiFillPropertySafety color="#15171c" />,
        path: "/propertytypeMaster",
      },
      {
        title: "Builder Projects",
        icon: (<SiElectronbuilder
          color="#15171c"
          title="projects" />
        ),
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        subNav: [
          {
            title: "Pending Projects",
            path: "/pendingProjects",
            icon: <RiPassPendingFill color="#15171c" />,
            cName: "sub-nav",
          },
          {
            title: "Verified Projects",
            path: "/verifiedProjects",
            icon: <RiVerifiedBadgeFill color="#15171c" />,
            cName: "sub-nav",
          },
          {
            title: "Rejected Projects",
            path: "/rejectedProjects",
            icon: <MdFreeCancellation color="#15171c" />,
            cName: "sub-nav",
          },
        ],
      }
    ]
    : userRole === "Builder" ?
      [
      
        {
          title: "My Profile ",
          icon: <CgProfile color="#15171c" />,
          path: "/myprofile",
        },
        {
          title: "Dashboard",
          icon: <RiIcons.RiHomeOfficeLine color="#15171c" />,
          path: "/dashboardbuild",
        },
        {
          title: "Builder Project",
          icon: <SiCplusplusbuilder color="#15171c" />,
          iconClosed: <RiIcons.RiArrowDownSFill />,
          iconOpened: <RiIcons.RiArrowUpSFill />,

          subNav: [
            {
              title: "Post New Project",
              path: "/addBuilderProject",
              icon: <IoIcons.IoIosPaper color="#15171c" />,
              cName: "sub-nav",
            },
            {
              title: "Project List",
              path: "/builderProjectDetails",
              icon: <TbListDetails color="#15171c" />,
              cName: "sub-nav",
            },
          ],
        },
        {
          title: "Review Property",
          icon: <MdOutlinePreview color="#15171c" />,
          iconClosed: <RiIcons.RiArrowDownSFill />,
          iconOpened: <RiIcons.RiArrowUpSFill />,

          subNav: [
            {
              title: "Review",
              path: "/ReviewPropertyBuilder",
              icon: <IoIcons.IoIosPaper color="#15171c" />,
              cName: "sub-nav",
            },
          ],
        },
        {
          title: "Enquiry Details ",
          icon: <FaClipboardQuestion color="#15171c" title="Enquiry Details" />,
          path: "/EnquiryDetailsBuilderList",
        },

      ]
      :userRole === "Buyer"?
      [
        {
          title: "My Profile ",
          icon: <CgProfile color="#15171c" />,
          path: "/myprofile",
        },
        {
          title:"Short List",
          icon:<SortIcon color="#15171c" />,
          path:"/ShortlistProperties"
        }
      ]:
      [
        {
          title: "My Profile ",
          icon: <CgProfile color="#15171c" />,
          path: "/myprofile",
        },
        {
          title: "Dashboard",
          icon: <RiIcons.RiHomeOfficeLine color="#15171c" />,
          path: "/dashboard",
        },
        // {
        //   title: "Property Masters",
        //   icon: <BiBuildings color="#15171c" />,
        //   iconClosed: <RiIcons.RiArrowDownSFill />,
        //   iconOpened: <RiIcons.RiArrowUpSFill />,

        //   subNav: [
        //     {
        //       title: "Add Property",
        //       path: "/AddProperty",
        //       icon: <IoIcons.IoIosPaper color="#15171c" />,
        //     },
        //     {
        //       title: "Property Details",
        //       path: "/PropertyDetails",
        //       icon: <TbListDetails color="#15171c" />,
        //     },
        //   ],
        // },
        // {
        //   title: "Project Masters",
        //   icon: <AiIcons.AiOutlineProject color="#15171c" />,
        //   iconClosed: <RiIcons.RiArrowDownSFill />,
        //   iconOpened: <RiIcons.RiArrowUpSFill />,

        //   subNav: [
        //     {
        //       title: "Add Project",
        //       path: "/addProject",
        //       icon: <IoIcons.IoIosPaper color="#15171c" />,
        //       cName: "sub-nav",
        //     },
        //     {
        //       title: "Project Details",
        //       path: "/projectDetails",
        //       icon: <TbListDetails color="#15171c" />,
        //       cName: "sub-nav",
        //     },
        //   ],
        // },
        {
          title: "Residential Properties",
          icon: <MdIcons.MdOutlineCorporateFare color="#15171c" />,
          iconClosed: <RiIcons.RiArrowDownSFill />,
          iconOpened: <RiIcons.RiArrowUpSFill />,

          subNav: [
            {
              title: "Post New Properties",
              path: "/newRent",
              icon: <IoIcons.IoIosPaper color="#15171c" />,
              cName: "sub-nav",
            },
            {
              title: "Properties List",
              path: "/rentDetails",
              icon: <TbListDetails color="#15171c" />,
              cName: "sub-nav",
            },
          ],
        },
        {
          title: "Commercial Properties",
          icon: <BsBuildingsFill color="#15171c" />,
          iconClosed: <RiIcons.RiArrowDownSFill />,
          iconOpened: <RiIcons.RiArrowUpSFill />,

          subNav: [
            {
              title: "Post New Properties",
              path: "/addComm",
              icon: <IoIcons.IoIosPaper color="#15171c" />,
              cName: "sub-nav",
            },
            {
              title: "Properties List",
              path: "/commDetails",
              icon: <TbListDetails color="#15171c" />,
              cName: "sub-nav",
            },
          ],
        },
        {
          title: "Agreement ",
          icon: <GrDocumentUser color="#15171c" />,
          iconClosed: <RiIcons.RiArrowDownSFill />,
          iconOpened: <RiIcons.RiArrowUpSFill />,

          subNav: [
            {
              title: "Add Agreement",
              path: "/agreement",
              icon: <IoIcons.IoIosPaper color="#15171c" />,
              cName: "sub-nav",
            },
            {
              title: "Agreement List",
              path: "/listagreement",
              icon: <TbListDetails color="#15171c" />,
              cName: "sub-nav",
            },
          ],
        },

        {
          title: "Review Property",
          icon: <MdOutlinePreview color="#15171c" />,
          iconClosed: <RiIcons.RiArrowDownSFill />,
          iconOpened: <RiIcons.RiArrowUpSFill />,

          subNav: [
            {
              title: "Review",
              path: "/reviewproperty",
              icon: <IoIcons.IoIosPaper color="#15171c" />,
              cName: "sub-nav",
            },
          ],
        },
        {
          title: "Enquiry Details ",
          icon: <FaClipboardQuestion color="#15171c" title=" Enquiry Details" />,
          path: "/EnquieyDetailsOwnerList",
        },
      ];
