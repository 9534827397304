import axios from "axios";
import { settings } from "../Config/Settings";

const addproperty = (data) => {
  return axios({
    method: "POST",
    url: `${settings.API_URL}/property/addProperty`,
    data: data,
  });
};

const getallproperty = () => {
  return axios({
    method: "GET",
    url: `${settings.API_URL}/property/getProperty`,
  });
};

const updateproperty = (data, propertyId) => {
  return axios({
    method: "PATCH",
    url: `${settings.API_URL}/property/updateProperty/${propertyId}`,
    data: data,
  });
};

const deleteproperty = (propertyId) => {
  return axios({
    method: "DELETE",
    url: `${settings.API_URL}/property/deleteProperty/${propertyId}`,
  });
};

const getOwnerDashbaordValues = (id) => {
  return axios({
    method: "GET",
    url: `${settings.API_URL}/RentCommercialProperty/dashboardOwner/${id}`,
  });
};
const getOwnerEnquiryDetailsListByid = (userId) => {
  return axios({
    method: "GET",
    url: `${settings.API_URL}/user/getDetailsByid/${userId}`,
  });
};

// /RentCommercialProperty/dashboardOwner/662b9e2ddbb2e3ffb21f5ff2

export { addproperty, getallproperty, updateproperty, deleteproperty,getOwnerDashbaordValues,getOwnerEnquiryDetailsListByid};
