import React, { useState } from "react";
import contactUs from "../../Assets/contactus.jpg";
export const ContactUs = () => {
  const [data, setData] = useState({
    id: "",
    email: "",
    phone: "",
    errorMessage: "",
  });
  const [name, setName] = useState("");

  const onValueChange = (e) => {
    const arr = e.target.value.split(" ");
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    const str2 = arr.join(" ");
    setName(str2);
  };

  const onInputChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const submitRecord = (e) => {
    e.preventDefault();

    const record = {
      name: name,
      email: data.email,
      phone: data.phone,
    };
    if (data.errorMessage === "") {
      if (name.trim().length === 0) {
        setData({ errorMessage: "Data should not be blank" });
        // setMessage("Data should not be blank")
      } else {
        // setMessage("")
        setData({ errorMessage: "" });
      }
    }
  };

  const onReset = () => {
    setName("");
    setData({ email: "", phone: "", errorMessage: "" });
  };
  return (
    <div>
      <div id="talktous">
        <div
          className="container  wow animated fadeIn"
          data-wow-duration="1s"
          data-wow-delay=".5s"
        >
          <div className="content-title">
            <div className="content-name">
              <h3>Talk to us</h3>
            </div>
            <hr className="content-title-underline" />
          </div>
          <div className="content-box">
            <div className="row">
              <div
                className="col-md-5 col-sm-12  wow animated fadeInUp"
                data-wow-duration="1s"
                data-wow-delay=".5s"
              >
                <img
                  // src="assets/images/talktous.png"
                  src={contactUs}
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div
                className="col-md-7 col-sm-12 d-flex justify-content-center wow animated fadeInDown"
                data-wow-duration="1s"
                data-wow-delay=".5s"
              >
                <div>
                  <h5>Get in touch with us to Sell or Rent Your Projects</h5>
                  <div className="talktous-form">
                    <label htmlFor="fname">Name</label>
                    <input
                      type="text"
                      id="fname"
                      name="name"
                      value={name}
                      onChange={(e) => {
                        onValueChange(e);
                      }}
                      placeholder="Your name.."
                    />

                    <label htmlFor="email">Email</label>
                    <input
                      type="text"
                      id="email"
                      name="email"
                      value={data.email}
                      onChange={(e) => {
                        onInputChange(e);
                      }}
                      placeholder="Your email.."
                    />
                    <label htmlFor="email">Phone</label>
                    <input
                      type="text"
                      id="phone"
                      name="phone"
                      value={data.phone}
                      onChange={(e) => {
                        onInputChange(e);
                      }}
                      placeholder="Your phone number.."
                    />
                    <input
                      type="submit"
                      value="Contact with our expert"
                      onClick={(e) => {
                        submitRecord(e);
                      }}
                    />
                    <hr />
                    <h2>OR</h2>
                    <div className="border-gradient border-gradient-purple">
                      <h4> Call +91 0000000000</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
