import React, { useEffect, useState } from "react";
//mui & react packages
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import { TabContext, TabList } from "@mui/lab";
import { Container } from "react-bootstrap";
//custom components
import AdminSidebar from "../../Components/Sidebar/AdminSidebar";
import RentProperty from "./RentProperty";
// api
import { getAllRentProperty } from "../../Api/RentpropertyAPI";
import { getCommercialProperty } from "../../Api/Commercial";

const VerifiedProperties = () => {
  const [value, setValue] = useState("3");
  const [data, setData] = useState([]);
  const [cdata, setcdata] = useState([]);

  useEffect(() => {
    GetAllRentProperty();
  }, [value]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    GetAllRentProperty();
  };

  const GetAllRentProperty = () => {
    if (value === "3") {
      getAllRentProperty()
        .then((res) => {
          if (res.data.status === 1) {
            let details = res.data.newRentProprtyDetails;
            let verifiedDetails = details.filter(
              (detail) => detail.isVerified === "Verified"
            );
            setData(verifiedDetails);
          }
        })
        .catch((err) => {
          console.log(err, "error in get all rent property.");
        });
    } else {
      getCommercialProperty()
        .then((res) => {
          if (res.data.status === 1) {
            let details = res.data.RentCommercialPropertyDetails;
            let verifiedDetails = details.filter(
              (detail) => detail.isVerified === "Verified"
            );
            setcdata(verifiedDetails);
          }
        })
        .catch((err) => {
          console.log(err, "error in get all commercial property.");
        });
    }
  };


  return (
    <>
      <div>
        <AdminSidebar />
      </div>

      <>
        <div className="topNav-m ">
          <Container>
            <div className=" content-title">
              <h3>Verified Properties</h3>
              <hr className="content-title-underline" />
            </div>
          </Container>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                centered
              >

                <Tab label="Rent" value="3" />
                <Tab label="Commercial" value="4" />
              </TabList>
            </Box>

            <TabPanel value="3" className="d-flex justify-content-center">
              <RentProperty propertyData={data} propertyType="Residential" isVerifiedDatas="Verified" GetAllRentProperty={GetAllRentProperty} />
            </TabPanel>
            <TabPanel value="4" className="d-flex justify-content-center">
              <RentProperty propertyData={cdata} propertyType="Commercial" isVerifiedDatas="Verified" getCommercialProperty={GetAllRentProperty} />
            </TabPanel>
          </TabContext>
        </div>
      </>
    </>
  );
};

export default VerifiedProperties;
