/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import "./PostCommentStyle.css";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";
import { addReview } from "../../Api/ReviewAPI";

const PostComment = ({ propertiesId, updatePostData }) => {
  const location = useLocation();
  const [comment, setComment] = useState("");
  const [rating, setRating] = useState(null);
  const [error, setError] = useState({});
  const [userData, setUserData] = useState(null);
  const [propertyId, setPropertyId] = useState("");
  const starRatingRef = useRef(null);

  useEffect(() => {
    const userDataString = localStorage.getItem("User");
    if (userDataString) {
      const parsedUserData = JSON.parse(userDataString);
      setUserData(parsedUserData.userData);
    }
    setPropertyId(location.state?.yourData._id);
  }, []);

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleRatingChange = (newRating) => {
    setRating(newRating);
  };

  const validates = () => {
    let formError = {};
    let isValid = true;

    if (comment === "") {
      isValid = false;
      formError["comment"] = "please enter comment";
    }

    if (rating === null) {
      isValid = false;
      formError["rating"] = "please give rating";
    }

    setError(formError);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validate = validates();
    if (validate) {
      const data = {
        UserId: userData._id,
        Rating: rating,
        Comment: comment,
        PropertyId: location.state?.yourData?._id,
        propertyOwnerId:location.state?.yourData?.userId,
        PropertyType: location.state?.propertyType,
      };
      await addReview(data)
        .then((res) => {
          if (res.data.status === 1) {
            Swal.fire({
              icon: "success",
              title: "Review Added Successfully!",
              text: "Thank you for your feedback. Your review helps us improve our services.",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Great!",
            });
            updatePostData(data);
            setComment("");
            setRating(null);
            setError({});
          } else {
            Swal.fire({
              icon: "error",
              title: "Something went wrong!",
              text: "An unexpected error has occurred. Please try again or contact support if the problem persists.",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK",
            });
          }
        })
        .catch((err) => {
          console.log(err, "err in add review.");
        });
    }
  };

  return (
    <div className="post-comment-container">
      <div className="post-comment-box">
        <h2 className="m-0">Post a Comment</h2>
        <form onSubmit={handleSubmit} className="mt-2 d-flex flex-column gap-2 p-0">
          <div>
            <label htmlFor="comment">Comment:</label>
            <div className="form-floating">
              <textarea
                type="text"
                id="comment"
                value={comment}
                onChange={handleCommentChange}
                className="input-comment"
                placeholder="Leave a comment here"
                aria-multiline
                required
                rows={2}
              />
            </div>
          </div>
          {error.comment && <div className="error">{error.comment}</div>}
          <div className="form-group d-flex gap-3" ref={starRatingRef}>
            <label>Rating:</label>
            <div className="star-rating">
              {[1, 2, 3, 4, 5].map((star) => {
                return (
                  <span
                    key={star}
                    className={rating >= star ? "filled" : "empty"}
                    onClick={() => handleRatingChange(star)}
                  >
                    &#9733;
                  </span>
                );
              })}
              {/* {[...Array(5)].map( (star, index) => {
                const currentRating = index + 1
                return(
                  <label>
                    <input
                      type='radio'
                      name='rating'
                      value={currentRating}
                      onClick={() => setRating(currentRating)}
                      style={{ display: "none" }}
                    />
                    <FaStar 
                      className={currentRating <= (hover || rating) ? 'filled' : 'empty'}
                      size={20}
                      color={currentRating <= (hover || rating) ? "#ffc107" : "#e4e5e9"}
                      onMouseEnter={() => setHover(currentRating)}
                      onMouseLeave={() => setHover(null)}
                      />
                  </label>
                )
              })} */}
              {error.rating && <div className="error">{error.rating}</div>}
            </div>
          </div>
          <div className="text-center">
            <button type="submit" className="btn-red w-150">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PostComment;
