/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import {
  deleteCommercials,
  getCommercialPropertyByUserId,
} from "../../../Api/Commercial";
import { settings } from "../../../Config/Settings";
import IconButton from "@mui/material/IconButton";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SquareFootOutlinedIcon from "@mui/icons-material/SquareFootOutlined";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import ChairOutlinedIcon from "@mui/icons-material/ChairOutlined";
import { MdOutlineCabin } from "react-icons/md";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import CorporateFareOutlinedIcon from "@mui/icons-material/CorporateFareOutlined";
import EmojiTransportationOutlinedIcon from "@mui/icons-material/EmojiTransportationOutlined";
import { Modal } from "react-bootstrap";
import ImageGallery from "react-image-gallery";
import PreviewIcon from "@mui/icons-material/Preview";
import { TextField, FormControl, Button } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import Auth from "../../../Config/helper/Auth";
import AdminSidebar from "../../../Components/Sidebar/AdminSidebar";
import FilterProperty from "../../Admin/FilterProperty";
import AddIcon from '@mui/icons-material/Add';


const CommercialDetails = () => {
  const navigate = useNavigate();
  const userId = Auth.getUserDetails()._id;

  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedItem, setSelectedItemId] = useState(null);
  const [commercialPreview, setCommercialPreview] = useState(false);
  const [imageView, setImageView] = useState(false);
  const [imagesData, setImagesData] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    GetAllCommProperty();
  }, []);

  const GetAllCommProperty = () => {
    getCommercialPropertyByUserId(userId)
      .then((res) => {
        if (res.data.status === 1) {
          let details = res.data.commercialDetails;
          setData(details);
        } else {
          setData([]);
        }
      })
      .catch((err) => {
        console.log(err, "error in get all commercial property.");
      });
  };

  const handleOpenImage = (data) => {
    setImageView(true);
    const images = [
      {
        original: data.exteriorView[0]
          ? `${data?.exteriorView[0]?.name}`
          : "",
        thumbnail: data.exteriorView[0]
          ? `${data?.exteriorView[0]?.name}`
          : "",
        thumbnailLabel: "Exterior View",
      },
      {
        original: data.InteririorView[0]
          ? `${data?.InteririorView[0]?.name}`
          : "",
        thumbnail: data.InteririorView[0]
          ? `${data?.InteririorView[0]?.name}`
          : "",
        thumbnailLabel: "Interior View",
      },

      {
        original: data.CabinView[0]
          ? `${data?.CabinView[0]?.name}`
          : "",
        thumbnail: data.CabinView[0]
          ? `${data?.CabinView[0]?.name}`
          : "",
        thumbnailLabel: "Cabin View",
      },
      {
        original: data.PantryView[0]
          ? `${data?.PantryView[0]?.name}`
          : "",
        thumbnail: data.PantryView[0]
          ? `${data?.PantryView[0]?.name}`
          : "",
        thumbnailLabel: "Pantry View",
      },
    ];
    setImagesData(images);
  };

  const DeleteCommProperty = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You are about to delete this record.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it",
      cancelButtonText: "No, cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteCommercials(id)
          .then((res) => {
            if (res.data.status === 1) {
              Swal.fire({
                icon: "success",
                title: "Commercial property deleted successfully.",
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                // cancelButtonColor: '#d33',
                confirmButtonText: "ok",
              });
              GetAllCommProperty();
            } else {
              Swal.fire({
                icon: "error",
                title: "Something went wrong.",
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                // cancelButtonColor: '#d33',
                confirmButtonText: "ok",
              });
            }
          })
          .catch((err) => {
            console.log(err, "err in delete commercial property.");
          });
      }
    });
  };

  const handlePreview = (id) => {
    data.map((commercialData, index) => {
      if (id === index) {
        setSelectedItemId(commercialData);
      }
    });
    setCommercialPreview(true);
  };
  const handleClose = () => {
    setCommercialPreview(false);
    setImageView(false);
  };

  const formatCash = (n) => {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e5) return +(n / 1e3).toFixed(1) + "K";
    if (n >= 1e5 && n < 1e6) return +(n / 1e5).toFixed(1) + "L";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
  };

  const columns = [
    { id: "id", label: "Sr. No.", minWidth: 70, align: "center" },
    {
      id: "propertyType",
      label: "Property Type",
      minWidth: 160,
      align: "center",
    },
    {
      id: "ComplexName",
      label: "Complex Name",
      minWidth: 160,
      align: "center",
    },
    // {
    //   id: "PropertyStatus",
    //   label: "Status",
    //   minWidth: 120,
    //   align: "center",
    // },
    {
      id: "PostedBy",
      label: "Posted by",
      minWidth: 120,
      align: "center",
    },
    {
      id: "isVerified",
      label: "Verification Status",
      minWidth: 140,
      align: "center",
    },
  ];


  // const filteredData =
  //   data && data
  //     ? data.filter(
  //         ({ _id, ComplexName, propertyType, isVerified, PostedBy }) =>
  //           (_id && _id.toLowerCase().includes(searchQuery.toLowerCase())) ||
  //           (ComplexName &&
  //             ComplexName.toLowerCase().includes(searchQuery.toLowerCase())) ||
  //           (propertyType &&
  //             propertyType.toLowerCase().includes(searchQuery.toLowerCase())) ||
  //           (isVerified &&
  //             isVerified.toLowerCase().includes(searchQuery.toLowerCase())) ||
  //           (PostedBy &&
  //             PostedBy.toLowerCase().includes(searchQuery.toLowerCase()))
  //       )
  //     : [];


  const filteredData = data ?
      data.filter((item) => {
        return(
        (item._id && item.
          _id.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (item.ComplexName &&
          item.ComplexName.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (item.propertyType &&
          item.propertyType.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (item.isVerified &&
          item.isVerified.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (item.PostedBy &&
          item.PostedBy.toLowerCase().includes(searchQuery.toLowerCase()))
        );
      })
      :[];

  return (
    <>
      <AdminSidebar />
      <div className="topNav-m">
      <div className="text-center p-2 mt-2">
          <h5>Commercial Properties</h5>
        </div>
      <div className="mt-0 d-flex justify-content-center">
      <Paper
            sx={{
              width: "70%",
              marginTop: "0rem",
              overflow: "hidden",
              justifyContent: "center",
            }}
          >
             <FilterProperty 
                 userId={userId}
                 propertyData={data}
                 propertyType={"Commercial"}
                // isVerified={isVerifiedDatas}
               />
         <div className="d-flex justify-content-end mb-3" ><Button className="add-btn" onClick={() => { navigate("/addComm") }}><AddIcon className="me-2" />Add Properties</Button></div>

            {data && data.map((listData, ind) => {
              return (
                <div
                  className="p-card-content"
                  // onClick={() =>
                  //   navigate(
                  //     `/fulltab/${listData?.type}/${listData?._id}`,
                  //     {
                  //       state: {
                  //         yourData: listData,
                  //         propertyType: listData?.propertyType,
                  //       },
                  //     }
                  //   )
                  // }
                >

                  <div className="p-card">
                    <div className="p-img">
                      <img
                        onClick={() => {
                         handleOpenImage(listData);
                        }}
                        src={`${listData?.exteriorView[0]?.name || "https://api.equireal.co.in/defaultProperty.jpg"}`}
                        alt="Property"
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}

                      />
                    </div>

                    <div className="p-info">
                      <div className="p-tle">
                        <div>
                          <h4>Properties for Commercial</h4>
                        </div>
                      </div>

                      <div className="pr-info">
                        <ul>
                          <li>
                            <ChairOutlinedIcon />
                            <h6>{listData?.furnishedStatus}</h6>
                          </li>
                          <li>
                            <MdOutlineCabin />
                            <h6> {listData?.propertyType}</h6>
                          </li>
                          <li>
                            <PeopleOutlineOutlinedIcon />
                            <h6>{listData?.ComplexName}</h6>
                          </li>

                          <li>
                            <CorporateFareOutlinedIcon />
                            <h6>
                              {" "}
                              {listData?.selectFloor} of {listData?.totalFloor}
                              th Floor
                            </h6>
                          </li>
                          <li>
                            <EmojiTransportationOutlinedIcon />
                            <h6> {listData?.Location}</h6>
                          </li>
                          <li>
                            <SquareFootOutlinedIcon />
                            <h6> {listData?.carpetArea} Sq.Ft</h6>
                          </li>
                        </ul>
                      </div>
                      <div className="d-flex justify-content-end">
                        <button
                          class="cta"
                          onClick={() =>
                            navigate(
                              // `/fulltab/${listData.propertyData.type}/${listData.propertyData._id}`,
                              `/fulltab/${"Commercial"}/${listData?._id}`,
                              {
                                state: {
                                  yourData: listData,
                                  propertyType: "Commercial",
                                },
                              }
                            )
                          }
                        >
                          <span class="hover-underline-animation"> View More </span>
                          <svg
                            id="arrow-horizontal"
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            height="10"
                            viewBox="0 0 46 16"
                          >
                            <path
                              id="Path_10"
                              data-name="Path 10"
                              d="M8,0,6.545,1.455l5.506,5.506H-30V9.039H12.052L6.545,14.545,8,16l8-8Z"
                              transform="translate(30)"
                            ></path>
                          </svg>
                        </button>
                        {/* <ArrowForwardIosTwoToneIcon /> */}
                      </div>
                    </div>

                    <div className="p-pri">
                      <div>
                        <h4>₹{formatCash(listData?.purposeOfProperty === "Buy" ? listData?.propertyCost : listData?.PropertyRent)}/-</h4>
                        <p>{listData?.purposeOfProperty}</p>
                      </div>

                      <div>
                        <h4>₹{formatCash(listData?.purposeOfProperty === "Buy" ? listData?.bookingAmount : listData?.SecurityDeposit)}/-</h4>
                        <p>{listData?.purposeOfProperty === "Buy" ? "Booking Price" : "Security Deposit"}</p>
                      </div>

                      <div>
                        {/* <IconButton>
                          <PreviewIcon
                            onClick={() => {
                              handleOpenImage(data);
                            }}
                          />
                        </IconButton> */}
                        <IconButton>
                          <VisibilityIcon
                            onClick={() => handlePreview(ind)}
                          />
                        </IconButton>
                        <IconButton
                          onClick={() =>
                            navigate("/addComm", {
                              state: listData,
                            })
                          }
                        >
                          <ModeEditIcon color="success" />
                        </IconButton>
                        <IconButton
                          onClick={() => DeleteCommProperty(listData._id)}
                        >
                          <DeleteIcon color="error" />
                        </IconButton>
                      </div>
                    </div>
                  </div>
                </div>

              )

            })

            }
          </Paper>
      </div>
      </div>

      {/* details preview */}
      <Modal
        className="topNav-m "
        show={commercialPreview}
        onHide={() => handleClose()}
        disableBackdropClick={false}
        disableEscapeKeyDown={false}
      >
        <Modal.Header closeButton className="headerBlue">
          <Modal.Title
            className=" text-center loginTitle"
            style={{ margin: "0 auto" }}
          >
            <h5>Commercial Details</h5>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="pl-5 pr-5  pb-4 LoginContainer LoginModal">
            {selectedItem && selectedItem ? (
              <>
                <div className="transactionData">
                  <div className="transactionDataTitle">Complex Name : </div>
                  <div className="transactionDataValue">
                    {selectedItem.ComplexName?selectedItem.ComplexName:"-"}
                    {}
                  </div>
                </div>
                <div className="transactionData odd">
                  <div className="transactionDataTitle">Carpet Area : </div>
                  <div className="transactionDataValue">
                    {selectedItem.carpetArea?selectedItem.carpetArea:"-"}
                  </div>
                </div>
                <div className="transactionData">
                  <div className="transactionDataTitle">
                    Furnishing Staus :{" "}
                  </div>
                  <div className="transactionDataValue">
                    {selectedItem.furnishedStatus?selectedItem.furnishedStatus:"-"}
                  </div>
                </div>
                <div className="transactionData odd">
                  <div className="transactionDataTitle">No of cabins : </div>
                  <div className="transactionDataValue">
                    {selectedItem.NosOfCabins?selectedItem.NosOfCabins:"-"}
                  </div>
                </div>
                <div className="transactionData">
                  <div className="transactionDataTitle">No of Seats : </div>
                  <div className="transactionDataValue">
                    {selectedItem.NosOfSeats?selectedItem.NosOfSeats:"-"}
                  </div>
                </div>
                <div className="transactionData odd">
                  <div className="transactionDataTitle">Pantry : </div>
                  <div className="transactionDataValue">
                    {selectedItem.isPantryAvailable !== undefined ? selectedItem.isPantryAvailable.toString() : '-'}
                </div>
                </div>
                <div className="transactionData">
                  <div className="transactionDataTitle">Washroom : </div>
                  <div className="transactionDataValue">
                    {selectedItem.Washrooms?selectedItem.Washrooms:"-"}
                  </div>
                </div>
                <div className="transactionData">
                  <div className="transactionDataTitle">Overlooking : </div>
                  <div className="transactionDataValue">{"-"}</div>
                </div>

                <div className="transactionData">
                  <div className="transactionDataTitle">Select floor : </div>
                  <div className="transactionDataValue">
                    {selectedItem.selectFloor?selectedItem.selectFloor:"-"}
                  </div>
                </div>
                <div className="transactionData">
                  <div className="transactionDataTitle">Total floor : </div>
                  <div className="transactionDataValue">
                    {selectedItem.totalFloor?selectedItem.totalFloor:"-"}
                  </div>
                </div>
                <div className="transactionData">
                  <div className="transactionDataTitle">Property Rent : </div>
                  <div className="transactionDataValue">
                    {selectedItem.PropertyRent?selectedItem.PropertyRent:"-"}
                  </div>
                </div>
                <div className="transactionData">
                  <div className="transactionDataTitle">
                    Security Deposit :{" "}
                  </div>
                  <div className="transactionDataValue">
                    {selectedItem.SecurityDeposit?selectedItem.SecurityDeposit:"-"}
                  </div>
                </div>
                <div className="transactionData">
                  <div className="transactionDataTitle">LEED : </div>
                  <div className="transactionDataValue">{"-"}</div>
                </div>
                <div className="transactionData">
                  <div className="transactionDataTitle">lifts : </div>
                  <div className="transactionDataValue">
                    {selectedItem.NosLifts?selectedItem.NosLifts:"-"}
                  </div>
                </div>
                <div className="transactionData">
                  <div className="transactionDataTitle">Lock in period : </div>
                  <div className="transactionDataValue">
                    {selectedItem.LockinPeriod?selectedItem.LockinPeriod:"-"}
                  </div>
                </div>
                <div className="transactionData">
                  <div className="transactionDataTitle">
                    water availibility :{" "}
                  </div>
                  <div className="transactionDataValue">
                    {selectedItem.Availibilty?selectedItem.Availibilty:"-"}
                  </div>
                </div>
                <div className="transactionData">
                  <div className="transactionDataTitle">Electricity : </div>
                  <div className="transactionDataValue">{"-"}</div>
                </div>
                <div className="transactionData">
                  <div className="transactionDataTitle">Ideal for : </div>
                  <div className="transactionDataValue">
                    {selectedItem.idealFor?selectedItem.idealFor:"-"}
                  </div>
                </div>
                <div className="transactionData">
                  <div className="transactionDataTitle">Loading : </div>
                  <div className="transactionDataValue">
                    {selectedItem.Loading?selectedItem.Loading:"-"}
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </Modal.Body>
      </Modal>

      {/* image preview */}
      <Modal
        className="topNav-m "
        show={imageView}
        onHide={() => handleClose()}
        disableBackdropClick={false}
        disableEscapeKeyDown={false}
      
      >
        <Modal.Header closeButton className="headerBlue">
          <Modal.Title
            className=" text-center loginTitle"
            style={{ margin: "0 auto" }}
          >
            <h5 className="text-center">Property Images</h5>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="pl-5 pr-5  pb-4 LoginContainer LoginModal">
            <ImageGallery items={imagesData} />
          </div>
        </Modal.Body>
      </Modal>
      {/* <div className="feat-properties">
        <div className="container mt-2">
          <div
            className="content-box wow animated fadeIn"
            data-wow-duration="1s"
            data-wow-delay="1s"
          >
            <div className="content-title">
              <div className="content-name">
                <h3>Commercial Property List</h3>
              </div>
              <hr className="content-title-underline" />
            </div>

            <div className="container">
              <div className="row mb-3">
                {data &&
                  data.map((list, ind) => (
                    <div className="Property_Details-card">
                      <div className="property">
                        <div className="property-wrapper-home_detials">
                          <img
                            src={`${settings.IMAGE_URL}/${list.propertyImg1}`}
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                        <div className="property-info">
                          <p className="property-name">
                            Made for usage, commonly searched for. Fork, like
                            and use it. Just move the carousel div above the col
                            containing the text for left alignment of images
                          </p>
                          <div className="projectlist-infodesc">
                            <div className="row p-2">
                              <div className="col-md-6">
                                <span className="propertyInfoTitle">Price</span>
                              </div>
                              <div className="col-md-6">
                                <p className="property-price_details">
                                  &#x20B9;
                                  {new Intl.NumberFormat("en-IN", {
                                    minimumFractionDigits: 2,
                                  }).format(
                                    list.Price === undefined ? 0 : list.Price
                                  )}
                                </p>
                              </div>
                            </div>
                            <div className="row p-2">
                              <div className="col-md-6">
                                <span className="propertyInfoTitle">
                                  Security Deposit
                                </span>
                              </div>
                              <div className="col-md-6">
                                <p className="property-price_details">
                                  &#x20B9;
                                  {new Intl.NumberFormat("en-IN", {
                                    minimumFractionDigits: 2,
                                  }).format(
                                    list.SecurityDeposit === undefined
                                      ? 0
                                      : list.SecurityDeposit
                                  )}
                                </p>
                              </div>
                            </div>

                            <div className="row p-2">
                              <div className="col-md-6">
                                <span className="propertyInfoTitle">Rent</span>
                              </div>
                              <div className="col-md-6">
                                <p className="property-price_details">
                                  &#x20B9;
                                  {new Intl.NumberFormat("en-IN", {
                                    minimumFractionDigits: 2,
                                  }).format(
                                    list.PropertyRent === undefined
                                      ? 0
                                      : list.PropertyRent
                                  )}
                                </p>
                              </div>
                            </div>

                            <div className="row p-2">
                              <div className="col-md-6">
                                <span className="propertyInfoTitle">
                                  Furnished
                                </span>
                              </div>
                              <div className="col-md-6">
                                <p className="property-price_details">
                                  Semi Furnished
                                </p>
                              </div>
                            </div>

                            <div className="row p-2">
                              <div className="col-md-6">
                                <span className="propertyInfoTitle">
                                  Location
                                </span>
                              </div>
                              <div className="col-md-6">
                                <p className="property-price_details">
                                  {list.Location}
                                </p>
                              </div>
                            </div>

                            <div className="row p-2">
                              <div className="col-md-6">
                                <span className="propertyInfoTitle">
                                  SubLocation
                                </span>
                              </div>
                              <div className="col-md-6">
                                <p className="property-price_details">
                                  {list.subLocation}
                                </p>
                              </div>
                            </div>

                            <div className="row p-2">
                              <div className="col-md-6">
                                <span className="propertyInfoTitle">
                                  Posted by
                                </span>
                              </div>
                              <div className="col-md-6">
                                <p className="property-price_details">
                                  {list.PostedBy}
                                </p>
                              </div>
                            </div>

                            <div className="row p-2">
                              <div className="col-md-6">
                                <span className="propertyInfoTitle">
                                  Status
                                </span>
                              </div>
                              <div className="col-md-6">
                                <p className="property-price_details">
                                  {list.PropertyStatus}
                                </p>
                              </div>
                            </div>
                            <div className="row p-2">
                              <div className="col-md-6">
                                <span className="propertyInfoTitle">
                                  Carpet Area{" "}
                                </span>
                              </div>
                              <div className="col-md-6">
                                <p className="property-price_details">
                                  {list.carpetArea}
                                </p>
                              </div>
                            </div>

                            <div className="row p-2">
                              <div className="col-md-6">
                                <span className="propertyInfoTitle">Other</span>
                              </div>
                              <div className="col-md-6">
                                <p className="property-price_details">-</p>
                              </div>
                            </div>

                            <div className="property-footer">
                              <Button
                                onClick={() =>
                                  navigate("/addComm", {
                                    state: list,
                                  })
                                }
                              >
                                Update
                              </Button>
                              <Button
                                onClick={() => DeleteCommProperty(list._id)}
                              >
                                Delete
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default CommercialDetails;

{/* <Paper
sx={{ width: "70%", overflow: "hidden", justifyContent: "center" }}
>
<div className="text-center p-2 mt-2">
  <h5>Commercial Properties</h5>
</div>
<div className="search-header p-2">
  <FormControl variant="outlined">
    <TextField
      id="outlined-search"
      className="blackClear"
      label="Search..."
      type="search"
      variant="outlined"
      value={searchQuery}
      onChange={(e) => setSearchQuery(e.target.value)}
    ></TextField>
  </FormControl>
</div>
<TableContainer sx={{ maxHeight: 540 }}>
  <Table stickyHeader aria-label="sticky table">
    <TableHead>
      <TableRow>
        {columns.map((column) => (
          <>
            <TableCell
              key={column.id}
              align={column.align}
              style={{ minWidth: column.minWidth }}
            >
              {column.label}
            </TableCell>
          </>
        ))}
        <TableCell align="center">Image</TableCell>
        <TableCell align="center" width={200}>
          Action
        </TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {selectedItem === "" ? (
        data
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((listData, i) => {
            return (
              <TableRow key={i}>
                <TableCell align="center">{i + 1}</TableCell>
                <TableCell align="center">
                  {listData.propertyType}
                </TableCell>
                <TableCell align="center">
                  {listData.ComplexName}
                </TableCell>
          
                <TableCell align="center">
                  {listData.postedBy
                    ? listData.postedBy
                    : listData.PostedBy}
                </TableCell>
                <TableCell align="center">
                  <span
                    style={{
                      color:
                        listData.isVerified === "Verified"
                          ? "green"
                          : listData.isVerified === "pending"
                          ? "orange"
                          : "red",
                      fontWeight: "bold",
                    }}
                  >
                    {listData.isVerified
                      ? listData.isVerified
                      : "pending"}
                  </span>
                </TableCell>
                <TableCell align="center">
                  <IconButton>
                    <PreviewIcon
                      onClick={() => {
                        handleOpenImage(listData);
                      }}
                    />
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  <IconButton>
                    <VisibilityIcon
                      onClick={() => handlePreview(i)}
                    />
                  </IconButton>
                  <IconButton
                    onClick={() =>
                      navigate("/addComm", {
                        state: listData,
                      })
                    }
                  >
                    <ModeEditIcon color="success" />
                  </IconButton>
                  <IconButton
                    onClick={() => DeleteCommProperty(listData._id)}
                  >
                    <DeleteIcon color="error" />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })
      ) : filteredData.length === 0 ? (
        <p>Data not display</p>
      ) : (
        filteredData
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((data1, index) => {
            return (
              <TableRow key={index}>
                <TableCell align="center">{index + 1}</TableCell>
                <TableCell align="center">
                  {data1.propertyType ? data1.propertyType : "-"}
                </TableCell>
                <TableCell align="center">
                  {data1.ComplexName ? data1.ComplexName : "-"}
                </TableCell>
                
                <TableCell align="center">
                  {data1.PostedBy ? data1.PostedBy : "-"}
                </TableCell>
                <TableCell align="center">
                  <span
                    style={{
                      color:
                        data1.isVerified === "Verified"
                          ? "green"
                          : data1.isVerified === "pending"
                          ? "orange"
                          : "red",
                      fontWeight: "bold",
                    }}
                  >
                    {data1.isVerified ? data1.isVerified : "pending"}
                  </span>
                </TableCell>
                <TableCell align="center">
                  <IconButton>
                    <PreviewIcon
                      onClick={() => {
                        handleOpenImage(data1);
                      }}
                    />
                  </IconButton>
                </TableCell>

                <TableCell align="center">
                  <IconButton>
                    <VisibilityIcon
                      onClick={() => handlePreview(index)}
                    />
                  </IconButton>
                  <IconButton
                    onClick={() =>
                      navigate("/addComm", {
                        state: data1,
                      })
                    }
                  >
                    <ModeEditIcon color="success" />
                  </IconButton>
                  <IconButton
                    onClick={() => DeleteCommProperty(data1._id)}
                  >
                    <DeleteIcon color="error" />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })
      )}
    </TableBody>
  </Table>
</TableContainer>

<TablePagination
  rowsPerPageOptions={[5, 10, 15, 50]}
  component="div"
  count={data.length}
  rowsPerPage={rowsPerPage}
  page={page}
  onPageChange={handleChangePage}
  onRowsPerPageChange={handleChangeRowsPerPage}
/>
</Paper> */}
