/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, Container, Button } from "react-bootstrap";
import Swal from "sweetalert2";
import { getAllRentReceipt } from "../../Api/RentpropertyAPI";
import { useLocation } from "react-router-dom";
import ReactToPrint from "react-to-print";
import Topnavigation from "../../Components/Navigation/TopNavigation";
import Auth from "../../Config/helper/Auth";
import AdminSidebar from "../../Components/Sidebar/AdminSidebar";

const ReceiptDetails = () => {
  const loginData = Auth.getUserDetails();
  const componentRef = useRef();
  const location = useLocation();
  const receiptId = location.state;
  const [allReceiptData, setAllReceiptData] = useState([]);

  useEffect(() => {
    getAllReceipt();
  }, []);

  const getAllReceipt = () => {
    getAllRentReceipt(receiptId.receiptId)
      .then((res) => {
        if (res.status === 200) {
          let receiptData = res.data.rentReceiptDetails;
          setAllReceiptData(receiptData);
        } else {
          Swal.fire({
            icon: "warning",
            title: "Data not found",
            showConfirmButton: true,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "ok",
          });
        }
      })
      .catch((err) => {
        console.log(err, "error in rent receipt");
      });
  };
  return (
    <>
      {loginData?.role === "Agent" || loginData?.role === "Owner" ? (
        <AdminSidebar />
      ) : (
        <Topnavigation />
      )}
      <div
        className="d-flex justify-content-center"
        style={{ marginTop: "7rem" }}
      >
        <ReactToPrint
          trigger={() => <Button variant="danger">Print Receipt</Button>}
          content={() => componentRef.current}
        />
      </div>
      <Row className="mt-4 justify-content-start" ref={componentRef}>
        {allReceiptData.map((newData, index) => (
          <Col key={index} md={4} className="mb-3">
            <Container>
              <Card className="d-flex justify-content-center w-100 p-3">
                <div className="d-flex align-items-center justify-content-center">
                  <h6>Provisional</h6>
                </div>
                <Row className="mt-5">
                  <Col md={6}>
                    <b>RENT RECEIPT :</b> <span>{newData.receiptMonth}</span>
                  </Col>
                  <Col md={6}>
                    <b>Receipt No :</b> <span>{index + 1}</span>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={6}>Generated on ClearTax</Col>
                  <Col md={6}>
                    <b>Date :</b> <span>{newData.recieptToDate}</span>
                  </Col>
                </Row>
                <Row className="mt-5">
                  <p>
                    Received sum of <b>INR Rs.{newData.rentAmount}</b> from{" "}
                    <b>{newData.tenantName}</b> towards the rent of property
                    located at <b>{newData.Address}</b> for the period from{" "}
                    {newData.recieptFromDate} to {newData.recieptToDate}
                  </p>
                </Row>
                <Row className="mt-5">
                  <Col md={6}>
                    {newData.ownerName} <span>(LandLord)</span>
                  </Col>
                </Row>
              </Card>
            </Container>
          </Col>
        ))}
      </Row>
    </>
  );
};
export default ReceiptDetails;
