import React from "react";
import { TextField, FormControl, Select, MenuItem } from "@mui/material";
import { titleOptions } from "../../../../modules/helpers/constant";
import DatePickerInput from "../../../../Components/Common/DatePickerInput";
import dayjs from "dayjs";

export default function BasicDetails({ onChangeHandler, state, stateError }) {
  return (
    <>
      <div className="mt-4 p-2">
        <h2>Licensor Details</h2>

        <div className="p-2">
          <div className="row">
            <div className="col-sm-2">
              <FormControl variant="outlined" fullWidth>
                <Select
                  value={state.licensorTitle}
                  required
                  // label="Title"
                  size="small"
                  onChange={(e) => {
                    onChangeHandler("licensorTitle", e.target.value);
                  }}
                >
                  {titleOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                <div style={{ color: "red", fontSize: 13 }}>
                  {stateError.licensorTitle}
                </div>
              </FormControl>
            </div>
            <div className="col-sm-5">
              <FormControl variant="outlined" fullWidth>
                <TextField
                  label="Licensor's Name"
                  type="text"
                  size="small"
                  value={state.licensorName}
                  onChange={(e) => {
                    onChangeHandler("licensorName", e.target.value);
                  }}
                  required
                ></TextField>
                <div style={{ color: "red", fontSize: 13 }}>
                  {stateError.licensorName}
                </div>
              </FormControl>
            </div>
            <div className="col-sm-5">
              <FormControl fullWidth variant="outlined">
                <DatePickerInput
                  selDate={dayjs(state.aggrRegistrationDate)}
                  placeholderName="Agreement Registration Date"
                  size="small"
                  handleChange={(date) => {
                    onChangeHandler("aggrRegistrationDate", date);
                  }}
                />
                <div style={{ color: "red", fontSize: 13 }}>
                  {stateError.aggrRegistrationDate}
                </div>
              </FormControl>
            </div>
          </div>
        </div>

        {/* <div className="row p-2">
          <div className="p-2 col-sm-5">
            <FormControl variant="outlined" fullWidth>
              <TextField
                label="Mobile No"
                type="text"
                size="small"
                value={state.ownerMobileNo}
                onChange={(e) => {
                  onChangeHandler("ownerMobileNo", e.target.value);
                }}
                required
              ></TextField>
              <div style={{ color: "red", fontSize: 13 }}>
                {stateError.ownerMobileNo}
              </div>
            </FormControl>
          </div>
          <div className="p-2 col-sm-7">
            <FormControl variant="outlined" fullWidth>
              <TextField
                autoComplete="off"
                label="Email (Optional)"
                type="text"
                size="small"
                value={state.ownerEmail}
                onChange={(e) => {
                  onChangeHandler("ownerEmail", e.target.value);
                }}
              ></TextField>
              <div style={{ color: "red", fontSize: 13 }}>
                {stateError.ownerEmail}
              </div>
            </FormControl>
          </div>
        </div> */}

        <div className="p-2">
          <FormControl variant="outlined" fullWidth>
            <TextField
              label="Permanent Address"
              type="text"
              size="small"
              value={state.licensorAddress}
              onChange={(e) => {
                onChangeHandler("licensorAddress", e.target.value);
              }}
              required
            ></TextField>
            <div style={{ color: "red", fontSize: 13 }}>
              {stateError.licensorAddress}
            </div>
          </FormControl>
        </div>
        <div className="row p-2">
          <div className="p-2 col-sm-4">
            {/* <span>Location</span> */}
            <FormControl variant="outlined" fullWidth>
              <TextField
                label="Location"
                // placeholder="Location"
                type="text"
                size="small"
                value={state.licensorLocation}
                onChange={(e) => {
                  onChangeHandler("licensorLocation", e.target.value);
                }}
                required
                autoComplete="off"
              ></TextField>
              <div style={{ color: "red", fontSize: 13 }}>
                {stateError.licensorLocation}
              </div>
            </FormControl>
          </div>
          <div className="p-2 col-sm-4">
            {/* <span>SubLocation</span> */}
            <FormControl variant="outlined" fullWidth>
              <TextField
                label="SubLocation"
                // placeholder="SubLocation"
                type="text"
                size="small"
                value={state.licensorSubLocation}
                onChange={(e) => {
                  onChangeHandler("licensorSubLocation", e.target.value);
                }}
                required
              ></TextField>
              <div style={{ color: "red", fontSize: 13 }}>
                {stateError.licensorSubLocation}
              </div>
            </FormControl>
          </div>
          <div className="p-2 col-sm-4">
            {/* <span>PinCode</span> */}
            <FormControl variant="outlined" fullWidth>
              <TextField
                label="PinCode"
                // placeholder="PinCode"
                type="text"
                size="small"
                value={state.licensorPinCode}
                onChange={(e) => {
                  onChangeHandler("licensorPinCode", e.target.value);
                }}
                required
              ></TextField>
              <div style={{ color: "red", fontSize: 13 }}>
                {stateError.licensorPinCode}
              </div>
            </FormControl>
          </div>
        </div>
      </div>
    </>
  );
}
