import React from 'react'
import './RadioButtonBox.css';

export default function RadioButtonBox({ label, value, checked, onChange }) {
    return (
        <>
            <div className={`radio-button-box ${checked ? 'checked' : ''}`} onClick={() => onChange(value)}>
                {label}
            </div>
        </>
    )
}
