import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import UserImage from "../../Assets/user.png";
import { Col, Row } from "react-bootstrap";
import { format } from "date-fns";
import AdminSidebar from "../../Components/Sidebar/AdminSidebar";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { IoMdArrowRoundBack } from "react-icons/io";
import { emphasize, styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";

const MoreReviewPropertyBuilder = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [yourData, setYourData] = useState([]);
    const [yourDataType, setYourType] = useState([]);

    useEffect(() => {
        setYourData(location.state?.yourData || []);
        setYourType(location.state?.yourDataNameOfProject || []);
    }, [location.state]);


    const renderStars = (star) => {
        const stars = [];
        for (let i = 0; i < star; i++) {
            stars.push(
                <span key={i} className="star">
                    &#9733;
                </span>
            );
        }
        return stars;
    };

    const formatDate = (dateString) => {
        if (dateString) {
            const date = new Date(dateString);
            return format(date, "dd MMMM yyyy");
        }
        return "";
    };

    const StyledBreadcrumb = styled(Chip)(({ theme }) => {
        const backgroundColor =
            theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[800];
        return {
            backgroundColor,
            height: theme.spacing(3),
            color: theme.palette.text.primary,
            fontWeight: theme.typography.fontWeightRegular,
            "&:hover, &:focus": {
                backgroundColor: emphasize(backgroundColor, 0.06),
            },
            "&:active": {
                boxShadow: theme.shadows[1],
                backgroundColor: emphasize(backgroundColor, 0.12),
            },
        };
    });

    const handleBackClick = () => {
        navigate(-1);
    };

    return (
        <>
            <AdminSidebar />

            <div className="container topNav-m">
                <div>
                    <Breadcrumbs aria-label="breadcrumb" onClick={handleBackClick}>
                        <StyledBreadcrumb
                            component="a"
                            // href="/"
                            icon={<IoMdArrowRoundBack fontSize="20px" />}
                            label="Back"
                        />
                    </Breadcrumbs>
                </div>
                <h3>All Reviews & Ratings</h3>
                <h5> For {yourDataType} Project. </h5>
                <div className="review-grid" >
                    {yourData.reviews && yourData.reviews.map((item, index) => (
                        <div key={index} className="customer-review">
                            <div className="user-info">
                                <img
                                    src={item?.UserId?.profileImage?.[0]?.name ? item?.UserId?.profileImage?.[0]?.name : UserImage}
                                    alt="Profile"
                                    className="profile-photo"
                                />
                                <div className="user-details">
                                    <p>{item?.UserId?.fullName}</p>
                                    <div className="rating ps-2">{renderStars(item?.Rating)}</div>
                                </div>
                            </div>
                            <div className="comment-section">
                                <p className="comment">
                                    {item?.Comment}
                                </p>
                                <div className="review-date">
                                    {formatDate(item?.createdAt)}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <style jsx>{`
                .container {
                    padding: 20px;
                }

                .review-grid {
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    gap: 20px;
                }

                .customer-review {
                    background-color: #fff;
                    border: 1px solid #ddd;
                    border-radius: 8px;
                    padding: 16px;
                    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
                    transition: box-shadow 0.3s ease;
                }

                .customer-review:hover {
                    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
                }

                .user-info {
                    display: flex;
                    align-items: center;
                }

                .profile-photo {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    margin-right: 16px;
                }

                .user-details {
                    flex-grow: 1;
                }

                .user-details p {
                    margin: 0;
                    font-weight: bold;
                }

                .rating {
                    color: #FFD700;
                }

                .comment-section {
                    margin-top: 16px;
                }

                .comment {
                    margin: 0 0 8px;
                }

                .review-date {
                    font-size: 0.875rem;
                    color: #888;
                }
            `}</style>
        </>
    );
}

export default MoreReviewPropertyBuilder

