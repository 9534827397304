/* eslint-disable no-useless-escape */
import React, { useState } from "react";
// api calls
import { RegisterUser } from "../../Api/AuthAPI";

// alert
import Swal from "sweetalert2";

// material ui components
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import TextField from "@mui/material/TextField";

// react bootstrap components
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Topnavigation from "../../Components/Navigation/TopNavigation";

import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { InputLabel } from "@material-ui/core";
// currency dropdown list
const currencies = [
  {
    value: "USA",
    label: "USA +1",
  },
  {
    value: "IND",
    label: "IND +91",
  },
  {
    value: "ARE",
    label: "ARE +971",
  },
  {
    value: "GBR",
    label: "GBR +44",
  },
];

const SignupNew = () => {
  // Register states
  const [newUser, setNewUser] = useState({
    fullName: "",
    // username: "",
    email: "",
    phone: "",
    password: "",
    cpassword: "",
    role: "",
    // cPassword: "",
    // CEA_Number: null,
  });

  const [newUserErr, setNewUserErr] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowCPassword = () => setShowCPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  // handle change for form
  const handleChangeReg = (e) => {
    const name = e.target.name;
    const value = e.target.value.trimStart();
    setNewUser({ ...newUser, [name]: value });
    setNewUserErr({ ...newUserErr, [name]: "" });
    if (name === "fullName" && value !== null && value !== "") {
      setNewUserErr({ ...newUserErr, fullName: "" });
    }
    // if (name === "username" && value !== null && value !== "") {
    //   setNewUserErr({ ...newUserErr, username: "" });
    // }
    if (name === "email" && value !== null && value !== "") {
      setNewUserErr({ ...newUserErr, email: "" });
    }
    if (name === "phone" && value !== null && value !== "") {
      setNewUserErr({ ...newUserErr, phone: "" });
    }
    if (name === "password" && value !== null && value !== "") {
      setNewUserErr({ ...newUserErr, password: "" });
    }
    
    if (name === "cpassword" && value !== null && value !== "") {
      setNewUserErr({ ...newUserErr, cPassword: "" });
    }
  };

  const handleClear = () => {
    setNewUser({
      fullName: "",
      // username: "",
      email: "",
      phone: "",
      // CEA_Number: null,
      // idProof: "",
      password: "",
      // cPassword: "",
      role: "",
    });
    setNewUserErr({});
  };

  // validation
  const validation = () => {
    const emailRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const passRgex =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    const phoneRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    // let spaceAndLetter = /^[a-z]+$/i;

    let newUserErr = {};
    let isValid = true;
    if (newUser.fullName === "" || newUser.fullName === null) {
      newUserErr["fullName"] = "Please enter full name!";
      isValid = false;
    }
    // if (newUser.username === "" || newUser.username === null) {
    //   newUserErr["username"] = "Please enter username!";
    //   isValid = false;
    // } else if (spaceAndLetter.test(newUser.username) === false) {
    //   newUserErr["username"] =
    //     "Please enter alphabetic characters and no spaces";
    //   isValid = false;
    // }
    if (newUser.email === "" || newUser.email === null) {
      newUserErr["email"] = "Please enter email!";
      isValid = false;
    } else if (emailRegex.test(newUser.email) === false) {
      newUserErr["email"] = "please enter valid email";
      isValid = false;
    }
    if (newUser.phone === "" || newUser.phone === null) {
      newUserErr["phone"] = "Please enter phone!";
      isValid = false;
    } else if (phoneRegex.test(newUser.phone) === false) {
      newUserErr["phone"] = "Please enter valid phone!";
      isValid = false;
    }
    if (newUser.cpassword === "" || newUser.cpassword === null) {
      newUserErr["cpassword"] = "Please enter confirm password!";
      isValid = false;
    }
    if (newUser.password && newUser.cpassword) {
      if (newUser.password !== newUser.cpassword) {
        newUserErr["cpassword"] = "Password is not matching!";
        isValid = false;
      }
    }
    if (newUser.password === "" || newUser.password === null) {
      newUserErr["password"] = "Please enter password!";
      isValid = false;
    } else if (passRgex.test(newUser.password) === false) {
      newUserErr["password"] =
        "Password must combination of Number,Capital letter, special character and min length is 8 !";
      isValid = false;
    }
    
    if (newUser.role === "") {
      newUserErr["role"] = "Please select role";
      isValid = false;
    }

    setNewUserErr(newUserErr);
    return isValid;
  };

  const handleRegSubmit = (e) => {
    let data = {
      email: newUser.email,
      password: newUser.password,
      role: newUser.role,
      fullName: newUser.fullName,
      // userName: newUser.username,
      phone: newUser.phone,
      // profileImage: profileImage,
      // CEA_Number: newUser.CEA_Number,
    };
    e.preventDefault();
    const isValid = validation();
    if (isValid) {
      RegisterUser(data)
        .then((res) => {
          if (res.data.status === 200) {
            Swal.fire({
              title: "Registration Successful",
              text: "A verification email has been sent. Please check your inbox.",
              icon: "success",
              timer: 5000,
            });
            handleClear();
            window.location.href = "/login";
          } else {
            Swal.fire({
              title: "Warning",
              text: res.data.message,
            });
          }
        })
        .catch((err) => {
          if (err && err.response) {
            if (err.response.status === 400) {
              Swal.fire({
                title: "Warning",
                text: err.response.data.message,
              });
            }
          } else {
            console.log("====================================");
            console.log(err, "error");
            console.log("====================================");
          }
        });
    }
  };

  return (
    <>
      <section className="lg-bcgrnd">
        <Container>
          <Topnavigation />
          <Row>
            <Col md={6}>
              <div className="lg-card">
                <div className="">
                  <p className="lg-heading">Sign Up</p>
                </div>
                <div>
                  <FormControl>
                    <p
                      className="lg-lbl-txt mb-0"
                      id="demo-row-radio-buttons-group-label"
                    >
                      I am
                    </p>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={newUser.role}
                      onChange={handleChangeReg}
                    >
                      <FormControlLabel
                        value="Buyer"
                        control={<Radio />}
                        label="Buyer"
                        name="role"
                      />
                      <FormControlLabel
                        value="tenant"
                        control={<Radio />}
                        label="Tenant"
                        name="role"
                      />
                      <FormControlLabel
                        value="Agent"
                        control={<Radio />}
                        label=" Agent"
                        name="role"
                      />
                      <FormControlLabel
                        value="Owner"
                        control={<Radio />}
                        label="Owner"
                        name="role"
                      />
                      <FormControlLabel
                        value="Builder"
                        control={<Radio />}
                        label="Builder"
                        name="role"
                      />
                    </RadioGroup>
                    {newUserErr ? (
                      <div
                        className="mt-2"
                        style={{ fontSize: 12, color: "red" }}
                      >
                        {newUserErr.role}
                      </div>
                    ) : null}
                  </FormControl>
                </div>

                <div className="row pt-2">
                  <Form.Group className="col-md-12 mb-3">
                    <TextField
                      id="standard-basic"
                      label="Full Name"
                      variant="standard"
                      color="grey"
                      name="fullName"
                      onChange={handleChangeReg}
                      value={newUser.fullName}
                      required
                      fullWidth
                      autoComplete="off"
                    />
                    {newUserErr ? (
                      <div
                        className="mt-2"
                        style={{ fontSize: 12, color: "red" }}
                      >
                        {newUserErr.fullName}
                      </div>
                    ) : null}
                  </Form.Group>
                  <Form.Group className="col-md-12 mb-3">
                    <TextField
                      id="standard-basic"
                      label="Email"
                      variant="standard"
                      color="grey"
                      name="email"
                      autoComplete="new-password"
                      onChange={handleChangeReg}
                      value={newUser.email}
                      fullWidth
                    />
                    {newUserErr ? (
                      <div
                        className="mt-2"
                        style={{ fontSize: 12, color: "red" }}
                      >
                        {newUserErr.email}
                      </div>
                    ) : null}
                  </Form.Group>
                  <Form.Group className="col-md-12 mb-3">
                    {/* <TextField
                      id="standard-basic"
                      label="Password"
                      variant="standard"
                      color="grey"
                      type="password"
                      name="password"
                      onChange={handleChangeReg}
                      value={newUser.password}
                      fullWidth
                      autoComplete="off"
                    /> */}
                    <TextField
                      fullWidth
                      color="grey"
                      variant="standard"
                      id="standard-basic"
                      type={showPassword ? "text" : "password"}
                      name="password"
                      label="Password"
                      onChange={handleChangeReg}
                      value={newUser.password}
                      InputProps={{
                      endAdornment:(
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    />
                    {newUserErr ? (
                      <div
                        className="mt-2"
                        style={{ fontSize: 12, color: "red" }}
                      >
                        {newUserErr.password}
                      </div>
                    ) : null}
                  </Form.Group>

                  <Form.Group>
                  <TextField
                      fullWidth
                      color="grey"
                      variant="standard"
                      id="standard-basic"
                      type={showCPassword ? "text" : "password"}
                      name="cpassword"
                      label="Confirm Password"
                      onChange={handleChangeReg}
                      value={newUser.cpassword}
                      InputProps={{
                      endAdornment:(
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle confirm password visibility"
                            onClick={handleClickShowCPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showCPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    />
                    {newUserErr ? (
                      <div
                        className="mt-2"
                        style={{ fontSize: 12, color: "red" }}
                      >
                        {newUserErr.cpassword}
                      </div>
                    ) : null}
                  </Form.Group>
                  <Form.Group className="col-md-4">
                    <TextField
                      color="grey"
                      fullWidth
                      id="standard-select-currency-native"
                      select
                      label="Country"
                      //   defaultValue="EUR"
                      SelectProps={{
                        native: true,
                      }}
                      variant="standard"
                    >
                      {currencies.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </TextField>
                  </Form.Group>
                  <Form.Group className="col-md-8">
                    <TextField
                      id="standard-basic"
                      label="Mobile Number"
                      variant="standard"
                      color="grey"
                      fullWidth
                      name="phone"
                      onChange={handleChangeReg}
                      value={newUser.phone}
                      autoComplete="off"
                    />
                    {newUserErr ? (
                      <div
                        className="mt-2"
                        style={{ fontSize: 12, color: "red" }}
                      >
                        {newUserErr.phone}
                      </div>
                    ) : null}
                  </Form.Group>
                </div>
                <div className="py-4">
                  <Button
                    variant="danger"
                    type="submit"
                    onClick={handleRegSubmit}
                    className="w-100 m-0"
                  >
                    <b>SIGN UP</b>
                  </Button>
                </div>
                <div className="text-center">
                  <p>
                    Already registered?{" "}
                    <Link to={"/login"} className="lg-a-link">
                      Login Now
                    </Link>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default SignupNew;
