import { create } from "ipfs-http-client";
const IpfsClient = async () => {
  const auth =
    "Basic " +
    btoa(
      process.env.REACT_APP_PROJECT_ID +
        ":" +
        process.env.REACT_APP_PROJECT_SECRET
    );
  const ipfs = await create({
    host: "ipfs.infura.io",
    protocol: "https",
    port: 5001,
    headers: {
      authorization: auth,
    },
  });
  return ipfs;
};
const IpfsImgUpload = async (file) => {
  try {
    let ipfs = await IpfsClient();
    let fileBuffer = await file.arrayBuffer();
    let result = await ipfs.add(fileBuffer);
    return result.path;
  } catch (error) {
    console.error("Error adding image to IPFS:", error);
  }
};
export { IpfsImgUpload };