import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
//material ui and react
import { Toast, ToastContainer } from "react-bootstrap";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete, TextField } from "@mui/material";
//api
import { getLocationData, getPropertytypeByTypeData } from "../../Api/AuthAPI";
import Select from 'react-select';


const FilterProperties = ({ userId, propertyType, isVerified }) => {
  let navigate = useNavigate();

  const [error, setError] = useState({});
  const [showToast, setShowToast] = useState(false);
  const [resiSelectedOption, setResiSelectedOption] = useState("Buy");

  const [locationResidential, setLocationResidential] = useState("");
  const [locationData, setLocationData] = useState([]);

  const [noOfBedrooms, setNoOfBedrooms] = useState(null);
  const [propertyTypeResidential, setPropertyTypeResidential] = useState("");
  const [minBudgetResidential, setMinBudgetResidential] = useState(null);
  const [maxBudgetResidential, setMaxBudgetResidential] = useState(null);
  const [minAreaResidential, setMinAreaResidential] = useState(null);
  const [maxAreaResidential, setMaxAreaResidential] = useState(null);

  // popover for residential
  const [anchBudgetResidential, setAnchBudgetResidential] = useState(null);
  const [anchAreaResidential, setAnchAreaResidential] = useState(null);

  const [locationCommercial, setLocationCommercial] = useState("");
  const [propertyTypeCommercial, setPropertyTypeCommercial] = useState("");
  const [minBudgetCommercial, setMinBudgetCommercial] = useState(null);
  const [maxBudgetCommercial, setMaxBudgetCommercial] = useState(null);
  const [minAreaCommercial, setMinAreaCommercial] = useState(null);
  const [maxAreaCommercial, setMaxAreaCommercial] = useState(null);
  const [noOfSeats, setNoOfSeats] = useState(null);
  const [noOfCabins, setNoOfCabins] = useState(null);

  // popover for commercial
  const [anchBudgetCommercial, setAnchBudgetCommercial] = useState(null);
  const [anchAreaCommercial, setAnchAreaCommercial] = useState(null);

  // commercial popover ids
  const openBudgetC = Boolean(anchBudgetCommercial);
  const commercialBudgetId = openBudgetC ? "simple-popover" : undefined;

  const openAreaC = Boolean(anchAreaCommercial);
  const commercialAreaId = openAreaC ? "simple-popover" : undefined;

  // residential popover ids
  const openBudgetR = Boolean(anchBudgetResidential);
  const residentialBudgetId = openBudgetR ? "simple-popover" : undefined;
  const openAreaR = Boolean(anchAreaResidential);
  const residentialAreaId = openAreaR ? "simple-popover" : undefined;
  const [PropertyTypeOption, setPropertyTypeOption] = useState([]);

  useEffect(() => {
    getpropertyType()
    getLocation()
  }, [])

  //getpropertyType
  const getpropertyType = () => {
    getPropertytypeByTypeData(propertyType)
      .then((res) => {
        if (res.status === 200) {
          let result = res.data.data;
          let list = result.map((data) => ({ label: data.AreaType, value: data._id }));
          setPropertyTypeOption(list);
        }
      }).catch((err) => {
        console.log(err, "error in property get data");
      })
  }

  const getLocation = () => {
    getLocationData()
      .then((res) => {
        if (res.status === 200) {
          let locationData = res.data.locations;
          setLocationData(locationData);
        }
      })
      .catch((err) => {
        console.log(err, "error in location get call find property");
      });
  }

  const handleClose = () => {
    setAnchAreaCommercial(null);
    setAnchBudgetCommercial(null);
    setAnchAreaResidential(null);
    setAnchBudgetResidential(null);
  };

  const handleChangeDropdownResi = (event) => {
    setResiSelectedOption(event.target.value);
  };

  const resetData = () => {
    setResiSelectedOption("Buy");
    setLocationCommercial("");
    setPropertyTypeCommercial("");
    setNoOfSeats("");
    setNoOfCabins("");
    setMinAreaCommercial(null);
    setMaxAreaCommercial(null);
    setMinBudgetCommercial(null);
    setMaxBudgetCommercial(null);

    setLocationResidential("");
    setPropertyTypeResidential("");
    setNoOfBedrooms("");
    setMinAreaResidential(null);
    setMaxAreaResidential(null);
    setMinBudgetResidential(null);
    setMaxBudgetResidential(null);

  };

  const validate = () => {
    let error = {};
    let isValid = true;
    if (propertyType === "Residential") {
      if (locationResidential === "" || locationResidential === null) {
        isValid = false;
        error["locationResidential"] = "Please Enter Location";
      }
    } else {
      if (locationCommercial === "" || locationCommercial === null) {
        isValid = false;
        error["locationCommercial"] = "Please Enter Location";
      }
    }
    if (!isValid) {
      setError(error);
      setShowToast(true);
    }
    return isValid;
  };

  const handleSearch = (key) => {
    if (validate()) {
      navigate("/filterProperties", {
        state: {
          userId: userId,
          propertyType: key,
          purposeOfProperty: resiSelectedOption,
          location:
            key === "Residential" ? locationResidential : locationCommercial,
          propertyTypes:
            key === "Residential" ? propertyTypeResidential.label : propertyTypeCommercial.label,
          minBudget:
            key === "Residential" ? minBudgetResidential : minBudgetCommercial,
          maxBudget:
            key === "Residential" ? maxBudgetResidential : maxBudgetCommercial,
          noOfBedrooms: key === "Residential" && noOfBedrooms,
          minArea:
            key === "Residential" ? minAreaResidential : minAreaCommercial,
          maxArea:
            key === "Residential" ? maxAreaResidential : maxAreaCommercial,
          isVerified: isVerified,
        },
      });
    }
  };

  return (
    <>

      <div className="row d-flex justify-content-center">
        <div className="col-sm-9">
          <div className="builder-search-box position-relative ">
            <div className="builder-search ">
              {propertyType === "Residential" ? (
                <div className="">
                  <h2 className="tabs-heading">Residential</h2>

                  <div className="clear-btn-box"><Button onClick={() => resetData()} className="clear-btn">Clear</Button></div>

                  <div className="d-flex justify-content-evenly align-items-center w-100 gap-1">

                    <select
                      className="txtfield m-1"
                      id="transactionType"
                      value={resiSelectedOption}
                      onChange={(e) => handleChangeDropdownResi(e)}
                    >
                      <option value="Buy">Buy</option>
                      <option value="Rent">Rent</option>
                    </select>

                    {/* <select
                              ref={target}
                              name="city"
                              id="city"
                              className="txtfield m-1"
                              value={locationResidential}
                              onChange={(e) => {
                                setShow(false);
                                setLocationResidential(e.target.value);
                                setError({
                                  ...error,
                                  locationResidential: "",
                                });
                              }}
                            >
                              <option value="" disabled selected>
                                Location
                              </option>
                              {locationData.map((option) => (
                                <option
                                  key={option._id}
                                  value={option.locationName}
                                >
                                  {option.locationName}
                                </option>
                              ))}
                            </select> */}

                    <Autocomplete
                      value={locationResidential}
                      onChange={(event, newValue) => {
                        if (typeof newValue === 'string') {
                          setLocationResidential(newValue);
                        } else if (newValue && newValue.inputValue) {
                          setLocationResidential(newValue.inputValue);
                        } else {
                          setLocationResidential(newValue);
                        }
                      }}
                      options={locationData.map((option) => option.locationName)}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Location"
                          variant="outlined"
                          size="small"
                        />
                      )}
                      style={{ width: 300 }}
                    />
                    <ToastContainer position="top-center">
                      <Toast
                        show={showToast}
                        onClose={() => setShowToast(false)}
                        delay={2000}
                        autohide
                        className="error-msg"
                      >
                        <Toast.Body>
                          {Object.values(error).join(". ")}
                        </Toast.Body>
                      </Toast>
                    </ToastContainer>

                    {/* <input
                      type="number"
                      id="noOfRooms"
                      name="noOfRooms"
                      className="txtfield m-1"
                      placeholder="No. Of BHK"
                      onChange={(e) => setNoOfBedrooms(e.target.value)}
                      value={noOfBedrooms}
                    /> */}


                    <Select
                      className="w-100"
                      defaultValue={propertyTypeResidential}
                      onChange={setPropertyTypeResidential}
                      options={PropertyTypeOption}

                    />

                    {/* <select
                      name="propertytype"
                      id="propertytypeId"
                      className="txtfield m-1"
                      value={propertyTypeResidential}
                      onChange={(e) => {
                        setPropertyTypeResidential(e.target.value);
                        setError({
                          ...error,
                          propertyTypeResidential: "",
                        });
                      }}
                    >
                      <option value="" disabled selected>
                        Select Property Type
                      </option>
                      {PropertyTypeOption.map((option) => (
                        <option key={option.value} value={option.label}>
                          {option.label}
                        </option>
                      ))}
                    </select> */}

                    <div>
                      <button
                        aria-describedby={residentialBudgetId}
                        variant="contained"
                        onClick={(e) => {
                          setAnchBudgetResidential(e.currentTarget);
                        }}
                        className="txtfield m-1"
                      >
                        {minBudgetResidential === null &&
                          maxBudgetResidential === null
                          ? "Budget"
                          : `${minBudgetResidential} - ${maxBudgetResidential}`}
                      </button>
                      <Popover
                        id={residentialBudgetId}
                        open={openBudgetR}
                        anchorEl={anchBudgetResidential}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        <div className="row p-2">
                          <div className="col-md-6">
                            <input
                              type="number"
                              id="min"
                              name="Min"
                              className="txtfield m-1"
                              placeholder="Min Budget"
                              onChange={(e) =>
                                setMinBudgetResidential(e.target.value)
                              }
                              value={minBudgetResidential}
                            />
                          </div>
                          <div className="col-md-6">
                            <input
                              type="number"
                              id="max"
                              name="Max"
                              className="txtfield m-1"
                              placeholder="Max Budget"
                              onChange={(e) =>
                                setMaxBudgetResidential(e.target.value)
                              }
                              value={maxBudgetResidential}
                            />
                          </div>
                        </div>
                      </Popover>
                    </div>

                    <div>
                      <button
                        aria-describedby={residentialAreaId}
                        variant="contained"
                        onClick={(e) =>
                          setAnchAreaResidential(e.currentTarget)
                        }
                        className="txtfield m-1"
                      >
                        {minAreaResidential === null &&
                          maxAreaResidential === null
                          ? "Area"
                          : `${minAreaResidential} - ${maxAreaResidential}`}
                      </button>
                      <Popover
                        id={residentialAreaId}
                        open={openAreaR}
                        anchorEl={anchAreaResidential}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        <div className="row p-2">
                          <div className="col-md-6">
                            <input
                              type="number"
                              id="min"
                              name="Min"
                              className="txtfield m-1"
                              placeholder="Min Area"
                              onChange={(e) =>
                                setMinAreaResidential(e.target.value)
                              }
                              value={minAreaResidential}
                            />
                          </div>
                          <div className="col-md-6">
                            <input
                              type="number"
                              id="max"
                              name="Max"
                              className="txtfield m-1"
                              placeholder="Max Area"
                              onChange={(e) =>
                                setMaxAreaResidential(e.target.value)
                              }
                              value={maxAreaResidential}
                            />
                          </div>
                        </div>
                      </Popover>
                    </div>

                    <div className="mx-2">
                      <Button
                        onClick={() => handleSearch("Residential")} className="search-btn"
                      >
                        <SearchIcon />
                        Search
                      </Button>
                    </div>
                  </div>
                </div>

              ) : (
                <div className="">
                  <h2 className="tabs-heading">Commercial</h2>
                  <div className="clear-btn-box"><Button onClick={() => resetData()} className="clear-btn">Clear</Button></div>

                  <div className="d-flex justify-content-evenly align-items-center w-100 gap-1">
                    <select
                      className="txtfield"
                      id="transactionType"
                      value={resiSelectedOption}
                      onChange={handleChangeDropdownResi}
                    >
                      <option value="Buy">Buy</option>
                      <option value="Rent">Rent</option>
                    </select>

                    {/* <select
                              ref={target}
                              name="city"
                              id="city"
                              className="txtfield"
                              value={locationCommercial}
                              onChange={(e) => {
                                setShow(false);
                                setError({
                                  ...error,
                                  locationCommercial: "",
                                });
                                setLocationCommercial(e.target.value);
                              }}
                            >
                              <option value="" disabled selected>
                                Location
                              </option>
                              {locationData.map((option) => (
                                <option
                                  key={option._id}
                                  value={option.locationName}
                                >
                                  {option.locationName}
                                </option>
                              ))}
                            </select> */}

                    <Autocomplete
                      value={locationCommercial}
                      onChange={(event, newValue) => {
                        if (typeof newValue === 'string') {
                          setLocationCommercial(newValue);
                        } else if (newValue && newValue.inputValue) {
                          setLocationCommercial(newValue.inputValue);
                        } else {
                          setLocationCommercial(newValue);
                        }
                      }}
                      options={locationData.map((option) => option.locationName)}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Location"
                          variant="outlined"
                          size="small"
                        />
                      )}
                      style={{ width: 300 }}
                    />
                    <ToastContainer position="top-center">
                      <Toast
                        show={showToast}
                        onClose={() => setShowToast(false)}
                        delay={2000}
                        autohide
                        className="error-msg"
                      >
                        <Toast.Body>
                          {Object.values(error).join(". ")}
                        </Toast.Body>
                      </Toast>
                    </ToastContainer>


                    <Select
                      className="w-100"
                      defaultValue={propertyTypeCommercial}
                      onChange={setPropertyTypeCommercial}
                      options={PropertyTypeOption}

                    />

                    {/* <select
                      name="propertyType"
                      id="propertyType"
                      className="txtfield"
                      value={propertyTypeCommercial}
                      onChange={(e) => {
                        setPropertyTypeCommercial(e.target.value);
                        setError({
                          ...error,
                          propertyTypeResidential: "",
                        });
                      }}
                    >
                      <option value="" disabled selected>
                        Property Type
                      </option>
                      {PropertyTypeOption.map((option) => (
                        <option key={option.value} value={option.label}>
                          {option.label}
                        </option>
                      ))}
                    </select> */}


                    {/* <input
                      type="number"
                      id="noOfSeats"
                      name="noOfSeats"
                      className="txtfield"
                      placeholder="Seats"
                      value={noOfSeats}
                      onChange={(e) => setNoOfSeats(e.target.value)}
                    />
                    <input
                      type="number"
                      id="noOfCabins"
                      name="noOfCabins"
                      className="txtfield"
                      placeholder="Cabins"
                      value={noOfCabins}
                      onChange={(e) => setNoOfCabins(e.target.value)}
                    /> */}

                    {/* area */}
                    <div>
                      <button
                        aria-describedby={commercialAreaId}
                        variant="contained"
                        onClick={(e) =>
                          setAnchAreaCommercial(e.currentTarget)
                        }
                        className="txtfield "
                      >
                        {minAreaCommercial === null &&
                          maxAreaCommercial === null
                          ? "Area"
                          : `${minAreaCommercial} - ${maxAreaCommercial}`}
                      </button>
                      <Popover
                        id={commercialAreaId}
                        open={openAreaC}
                        anchorEl={anchAreaCommercial}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        <div className="row p-2">
                          <div className="col-md-6">
                            <input
                              type="number"
                              id="min"
                              name="MinArea"
                              className="txtfield m-1"
                              placeholder="Min Area "
                              onChange={(e) =>
                                setMinAreaCommercial(e.target.value)
                              }
                              value={minAreaCommercial}
                            />
                          </div>
                          <div className="col-md-6">
                            <input
                              type="number"
                              id="max"
                              name="MaxArea"
                              className="txtfield m-1"
                              placeholder="Max Area"
                              onChange={(e) =>
                                setMaxAreaCommercial(e.target.value)
                              }
                              value={maxAreaCommercial}
                            />
                          </div>
                        </div>
                      </Popover>
                    </div>

                    {/* budget */}
                    <div>
                      <button
                        aria-describedby={commercialBudgetId}
                        variant="contained"
                        onClick={(e) =>
                          setAnchBudgetCommercial(e.currentTarget)
                        }
                        className="txtfield"
                      >
                        {minBudgetCommercial === null &&
                          maxBudgetCommercial === null
                          ? "Budget"
                          : `${minBudgetCommercial} - ${maxBudgetCommercial}`}
                      </button>
                      <Popover
                        id={commercialBudgetId}
                        open={openBudgetC}
                        anchorEl={anchBudgetCommercial}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        <div className="row p-2">
                          <div className="col-md-6">
                            <input
                              type="number"
                              id="min"
                              name="Min"
                              className="txtfield"
                              placeholder="Min Budget"
                              onChange={(e) =>
                                setMinBudgetCommercial(e.target.value)
                              }
                              value={minBudgetCommercial}
                            />
                          </div>
                          <div className="col-md-6">
                            <input
                              type="number"
                              id="max"
                              name="Max"
                              className="txtfield "
                              placeholder="Max Budget"
                              onChange={(e) =>
                                setMaxBudgetCommercial(e.target.value)
                              }
                              value={maxBudgetCommercial}
                            />
                          </div>
                        </div>
                      </Popover>
                    </div>

                    <div className="mx-2">
                      <Button onClick={() => handleSearch("Commercial")} className="search-btn">
                        <SearchIcon />
                        Search
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FilterProperties;
