/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import Topnavigation from "../../Components/Navigation/TopNavigation";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import { MdCameraAlt } from "react-icons/md";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

// api calls
import { RegisterUser } from "../../Api/AuthAPI";
import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { IpfsImgUpload } from "../../modules/helpers/Ipfs";
import { settings } from "../../Config/Settings";

const defaultTheme = createTheme();
const roleOptions = [
  { value: "Owner", label: "Owner" },
  { value: "tenant", label: "tenant" },
  { value: "Buyer", label: "Buyer" },
  { value: "Seller", label: "Seller" },
  { value: "Agent", label: "Agent" },
];

export default function Register() {
  const Navigate = useNavigate();

  // Register states
  const [newUser, setNewUser] = useState({
    fullName: "",
    username: "",
    email: "",
    phone: "",
    password: "",
    cpassword: "",
    CEA_Number: null,
  });
  const [newUserErr, setNewUserErr] = useState({});
  const [role, setRole] = useState([]);

  const [error, setError] = useState([]);
  const [loading, setLoading] = useState(false);
  const [profileImage, setProfileImage] = useState([]);
  const [profilePreview, setProfilePreview] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [showCPassword, setShowCPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowCPassword = () => setShowCPassword((showc) => !showc);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  // handle change for role

  const handleClear = () => {
    setNewUser({
      fullName: "",
      username: "",
      email: "",
      phone: "",
      CEA_Number: null,
      idProof: "",
      password: "",
      cpassword: "",
    });
    setNewUserErr({});
    setRole([]);
    setError([]);
  };
  const handleChangeRole = (e) => {
    setRole(e.target.value);
    setError({ ...error, role: "" });
  };
  // validate signup form
  const validation = () => {
    const emailRegex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const passRgex =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    const phoneRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    let spaceAndLetter = /^[a-z]+$/i;

    let newUserErr = {};
    let errors = {};
    let isValid = true;
    if (newUser.fullName === "" || newUser.fullName === null) {
      newUserErr["fullName"] = "Please enter full name!";
      isValid = false;
    }
    if (newUser.username === "" || newUser.username === null) {
      newUserErr["username"] = "Please enter username!";
      isValid = false;
    } else if (spaceAndLetter.test(newUser.username) === false) {
      newUserErr["username"] =
        "Please enter alphabetic characters and no spaces";
      isValid = false;
    }
    if (newUser.email === "" || newUser.email === null) {
      newUserErr["email"] = "Please enter email!";
      isValid = false;
    } else if (emailRegex.test(newUser.email) === false) {
      newUserErr["email"] = "please enter valid email";
      isValid = false;
    }
    if (newUser.phone === "" || newUser.phone === null) {
      newUserErr["phone"] = "Please enter phone!";
      isValid = false;
    } else if (phoneRegex.test(newUser.phone) === false) {
      newUserErr["phone"] = "Please enter valid phone!";
      isValid = false;
    }
    if (newUser.password && newUser.cpassword) {
      if (newUser.password !== newUser.cpassword) {
        newUserErr["cpassword"] = "Password is not matching!";
        isValid = false;
      }
    }
    if (newUser.password === "" || newUser.password === null) {
      newUserErr["password"] = "Please enter password!";
      isValid = false;
    } else if (passRgex.test(newUser.password) === false) {
      newUserErr["password"] =
        "Password must combination of Number,Capital letter, special character and min length is 8 !";
      isValid = false;
    }
    if (newUser.cpassword === "" || newUser.cpassword === null) {
      newUserErr["cpassword"] = "Please enter password again!";
      isValid = false;
    }
    if (role.length === 0) {
      errors["role"] = "Please select role";
      isValid = false;
    }

    setNewUserErr(newUserErr);
    setError(errors);
    return isValid;
  };

  // handle change for form
  const handleChangeReg = (e) => {
    const name = e.target.name;
    const value = e.target.value.trimStart();
    setNewUser({ ...newUser, [name]: value });
    if (name === "fullName" && value !== null && value !== "") {
      setNewUserErr({ ...newUserErr, fullName: "" });
    }
    if (name === "username" && value !== null && value !== "") {
      setNewUserErr({ ...newUserErr, username: "" });
    }
    if (name === "email" && value !== null && value !== "") {
      setNewUserErr({ ...newUserErr, email: "" });
    }
    if (name === "phone" && value !== null && value !== "") {
      setNewUserErr({ ...newUserErr, phone: "" });
    }
    if (name === "password" && value !== null && value !== "") {
      setNewUserErr({ ...newUserErr, password: "" });
    }
    if (name === "cpassword" && value !== null && value !== "") {
      setNewUserErr({ ...newUserErr, cpassword: "" });
    }
  };

  const onImageChange = async (event, name) => {
    if (event.target.files && event.target.files[0]) {
      let file = event.target.files[0];
      const imageValue = await IpfsImgUpload(file);
      const updatedProfileImage = [
        ...profileImage,
        { name: file.name, value: imageValue },
      ];
      let profileImages = `${settings.IMAGE_URL}/${imageValue}`;
      setProfileImage(updatedProfileImage);
      setProfilePreview(profileImages);
    }
  };
  // submit signup form
  const handleRegSubmit = (e) => {
    setLoading(true);
    let data = {
      email: newUser.email,
      password: newUser.password,
      role: role,
      fullName: newUser.fullName,
      userName: newUser.username,
      phone: newUser.phone,
      profileImage: profileImage,
      CEA_Number: newUser.CEA_Number,
    };
    e.preventDefault();
    const isValid = validation();
    if (isValid) {
      RegisterUser(data)
        .then((res) => {
          setLoading(false);
          if (res.status === 200) {
            setLoading(false);
            Swal.fire("Registration Successful", "success");
            handleClear();
            window.location.href = "/auth";
          } else {
            setLoading(false);
            Swal.fire({
              title: "Warning",
              text: res.data.message,
            });
          }
        })
        .catch((err) => {
          setLoading(false);
          if (err && err.response) {
            if (err.response.status === 400) {
              Swal.fire({
                title: "Warning",
                text: err.response.data.message,
              });
            }
          } else {
            console.log("====================================");
            console.log(err, "error");
            console.log("====================================");
          }
        });
    }
  };

  return (
    <>
      <ThemeProvider theme={defaultTheme}>
        <Container component="main" className="login-box ">
          <CssBaseline />
          <Box>
            {/* <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign up
            </Typography> */}
            <Box
              component="form"
              noValidate
              onSubmit={handleRegSubmit}
              // sx={{ mt: 3 }}
            >
              <Grid container spacing={2} bgcolor={"#fff"}>
                <Grid item xs={12}>
                  <TextField
                    name="fullName"
                    required
                    variant="standard"
                    fullWidth
                    id="fullName"
                    label="Full Name"
                    onChange={handleChangeReg}
                    value={newUser.fullName}
                  />
                  {newUserErr ? (
                    <div
                      className="mt-2"
                      style={{ fontSize: 12, color: "red" }}
                    >
                      {newUserErr.fullName}
                    </div>
                  ) : null}
                </Grid>

                {/* <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    variant="standard"
                    id="username"
                    label="Username"
                    name="username"
                    onChange={handleChangeReg}
                    value={newUser.username}
                  />
                  {newUserErr ? (
                    <div
                      className="mt-2"
                      style={{ fontSize: 12, color: "red" }}
                    >
                      {newUserErr.username}
                    </div>
                  ) : null}
                </Grid> */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    variant="standard"
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    onChange={handleChangeReg}
                    value={newUser.email}
                  />
                  {newUserErr ? (
                    <div
                      className="mt-2"
                      style={{ fontSize: 12, color: "red" }}
                    >
                      {newUserErr.email}
                    </div>
                  ) : null}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    id="phone"
                    variant="standard"
                    label="Mobile No."
                    name="phone"
                    autoComplete="phone"
                    onChange={handleChangeReg}
                    value={newUser.phone}
                  />
                  {newUserErr ? (
                    <div
                      className="mt-2"
                      style={{ fontSize: 12, color: "red" }}
                    >
                      {newUserErr.phone}
                    </div>
                  ) : null}
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>Role</InputLabel>
                    <Select
                      value={role}
                      required
                      label="Role"
                      variant="standard"
                      name="role"
                      onChange={handleChangeRole}
                    >
                      {roleOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {error ? (
                      <div
                        className="mt-2"
                        style={{ fontSize: 12, color: "red" }}
                      >
                        {error.role}
                      </div>
                    ) : null}
                  </FormControl>
                </Grid>

                {/* <Grid item xs={12} sm={12}>
                  <TextField
                    required
                    fullWidth
                    id="CEA_Number"
                    label="CEA Number"
                    name="CEA_Number"
                    variant="standard"
                    autoComplete="CEA_Number"
                    onChange={handleChangeReg}
                    value={newUser.CEA_Number}
                    disabled={role === "Agent" ? false : true}
                  />
                  {newUserErr ? (
                    <div
                      className="mt-2"
                      style={{ fontSize: 12, color: "red" }}
                    >
                      {newUserErr.CEA_Number}
                    </div>
                  ) : null}
                </Grid> */}
                <Grid item xs={12} sm={6}>
                  <FormControl variant="standard">
                    <InputLabel htmlFor="standard-adornment-password">
                      Password
                    </InputLabel>
                    <Input
                      id="standard-adornment-password"
                      type={showPassword ? "text" : "password"}
                      name="password"
                      label="Password"
                      onChange={handleChangeReg}
                      value={newUser.password}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {newUserErr ? (
                      <div
                        className="mt-2"
                        style={{ fontSize: 12, color: "red" }}
                      >
                        {newUserErr.password}
                      </div>
                    ) : null}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="standard">
                    <InputLabel htmlFor="standard-adornment-cpassword">
                      Confirm Password
                    </InputLabel>
                    <Input
                      id="standard-adornment-cpassword"
                      type={showCPassword ? "text" : "password"}
                      name="cpassword"
                      label="Confirm Password"
                      onChange={handleChangeReg}
                      value={newUser.cpassword}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle confirm password visibility"
                            onClick={handleClickShowCPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showCPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {newUserErr ? (
                      <div
                        className="mt-2"
                        style={{ fontSize: 12, color: "red" }}
                      >
                        {newUserErr.cpassword}
                      </div>
                    ) : null}
                  </FormControl>
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                className="btn-red"
                sx={{ mt: 5, mb: 2 }}
              >
                Sign Up
              </Button>
              {/* <Grid container justifyContent="flex-end">
                <Grid item className="login-terms">
                 
                    Already have an account?  <Link to={"/Login"} variant="body2">Sign in
                  </Link>
                </Grid>
              </Grid> */}
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </>
  );
}
