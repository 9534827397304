import React, { useState } from "react";
//@material UI
import { TextField, FormControl } from "@mui/material";
//icons
import IconButton from "@mui/material/IconButton";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
//custom components
import RadioButtonGroup from "../../../../Components/Common/RadioButtonGroup";

export default function Property({ onChangeHandler, state, stateError }) {
  const itemsPerPage = 10;

  const [currentSelectPage, setCurrentSelectPage] = useState(1);

  const renderSelectedBricks = () => {
    const floors = [];
    if (currentSelectPage === 1) {
      floors.push("Ground", "Lower Basement", "Upper Basement");
    } else {
      const start = (currentSelectPage - 2) * itemsPerPage + 1;
      const end = Math.min(start + itemsPerPage - 1, 50);

      for (let i = start; i <= end; i++) {
        floors.push(i);
      }
    }

    const bricks = floors.map((floor, index) => (
      <div
        key={index}
        className={`brick ${state.selectFloor === floor ? "selected2" : ""}`}
        onClick={() => {
          // setRentPropertyError({ ...rentPropertyError, selectFloor: "" });
          onChangeHandler("selectFloor", floor);
        }}
      >
        {floor}
      </div>
    ));

    return bricks;
  };
  const handlePageChange = (newPage, name) => {
    if (name === "select") {
      setCurrentSelectPage(newPage);
    }
  };

  //propertyType
  const property = [
    { label: "Residential", value: "Residential" },
    { label: "Commercial", value: "Commercial" },
  ];

  const otherRooms =
    state.property === "Residential"
      ? [
        { label: "Pooja Room", value: "Pooja Room" },
        { label: "Servant Room", value: "Servant Room" },
        { label: "Store Room", value: "Store Room" },
        { label: "Study Room", value: "Study Room" },
        { label: "Other", value: "Other" },
      ]
      : [
        { label: "Store Room", value: "Store Room" },
        { label: "Other", value: "Other" },
      ];

  const roomDetails =
    state.property === "Residential"
      ? ["1 BHK", "2 BHK", "3 BHK", "4 BHK", "5 BHK", "6 BHK", "7 BHK"]
      : [
        "1 Room",
        "2 Room",
        "3 Room",
        "4 Room",
        "5 Room",
        "6 Room",
        "7 Room",
        // "8 Room",
        // "9 Room",
        // "10 Room",
      ];

  //handleChange
  const handleOptionChange = (group, value) => {
    onChangeHandler(group, value);
  };


  return (
    <>
      <div className="mt-4 p-2">
        <h2>Property Details</h2>

        <div className="mt-2">
          <RadioButtonGroup
            groupName="property"
            options={property}
            onChangeHandler={handleOptionChange}
          />
          <div style={{ color: "red", fontSize: 12 }}>
            {stateError.property}
          </div>
        </div>

        {/* <div className="mt-1 p-2">
          <span>Select Floor</span>
          <div className="p-1">
            <div className="d-flex">
              <IconButton
                disabled={currentSelectPage === 1}
                onClick={() => handlePageChange(currentSelectPage - 1, "select")}
              >
                <ArrowBackIosNewIcon />
              </IconButton>
              <div className="wall-selector">{renderSelectedBricks()}</div>
              <IconButton
                disabled={currentSelectPage * itemsPerPage >= 60}
                onClick={() => handlePageChange(currentSelectPage + 1, "select")}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            </div>
            <div style={{ color: "red", fontSize: 13 }}>
              {stateError.selectFloor}
            </div>
          </div>
        </div> */}

        <div className="mt-1 p-2">
          <span>Room Details</span>
          <div className="wall-selector">
            {roomDetails.map((value) => (
              <div
                key={value}
                name="room"
                className={`brick ${state.room === value ? "selected2" : ""}`}
                onClick={() => {
                  onChangeHandler("room", value);
                }}
              >
                {value}
              </div>
            ))}
          </div>
          <div style={{ color: "red", fontSize: 13 }}>{stateError.room}</div>
        </div>

        {/* depends on select floor and  room details */}
        {state.room !== ""? (
          // && state.selectFloor !== "" 
          <div>
            <div className="row p-2">
              {state.property === "Residential" ? (
                <>
                  <div className="col-sm-4 p-2">
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        inputProps={{
                          min: 1,
                        }}
                        label="Bedrooms"
                        type="number"
                        size="small"
                        value={state.noOfBedroom}
                        onChange={(e) => {
                          onChangeHandler("noOfBedroom", e.target.value);
                        }}
                        required
                      ></TextField>
                      <div style={{ color: "red", fontSize: 13 }}>
                        {stateError.noOfBedroom}
                      </div>
                    </FormControl>
                  </div>

                  <div className="col-sm-4 p-2">
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        inputProps={{
                          min: 1,
                        }}
                        label="kitchenroom"
                        type="number"
                        size="small"
                        value={state.kitchenroom}
                        onChange={(e) => {
                          onChangeHandler("kitchenroom", e.target.value);
                        }}
                        required
                      ></TextField>
                      <div style={{ color: "red", fontSize: 13 }}>
                        {stateError.kitchenroom}
                      </div>
                    </FormControl>
                  </div>

                  <div className="col-sm-4 p-2">
                    <FormControl variant="outlined" fullWidth>
                      <TextField
                        inputProps={{
                          min: 1,
                        }}
                        label="drawing & dinning room"
                        type="number"
                        size="small"
                        value={state.drawingdinningroom}
                        onChange={(e) => {
                          onChangeHandler("drawingdinningroom", e.target.value);
                        }}
                        required
                      ></TextField>
                      <div style={{ color: "red", fontSize: 13 }}>
                        {stateError.drawingdinningroom}
                      </div>
                    </FormControl>
                  </div>
                </>
              ) : null}

              <div className="col-sm-4 p-2">
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    inputProps={{
                      min: 1,
                    }}
                    size="small"
                    label="Bathrooms"
                    type="number"
                    value={state.noOfBathroom}
                    onChange={(e) => {
                      onChangeHandler("noOfBathroom", e.target.value);
                    }}
                    required
                  ></TextField>
                  <div style={{ color: "red", fontSize: 13 }}>
                    {stateError.noOfBathroom}
                  </div>
                </FormControl>
              </div>

              {/* <div className="col-sm-4 p-2">
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    inputProps={{
                      min: 1,
                    }}
                    size="small"
                    label="Balconies"
                    type="number"
                    value={state.noOfBalcony}
                    onChange={(e) => {
                      onChangeHandler("noOfBalcony", e.target.value);
                    }}
                    required
                  ></TextField>
                  <div style={{ color: "red", fontSize: 13 }}>
                    {stateError.noOfBalcony}
                  </div>
                </FormControl>
              </div> */}
              
            </div>
            
            {/* <div>
             <div style={{ minWidth: "40%" }}>Other Rooms</div>
              <RadioButtonGroup
                groupName="otherRoom"
                options={otherRooms}
                onChangeHandler={handleOptionChange}
              />
            </div> */}

            {/* <div className="mt-2" >
            <div style={{ minWidth: "40%" }}>Parking Space</div>
              <input
                className="form-check-input"
                type="radio"
                name="parkingSpace"
                id="parkingSpace1"
                value="YES"
                checked={state.parkingSpace}
                onChange={(e) => {
                  onChangeHandler("parkingSpace", e.target.value);
                }}
              />
              <label className="form-check-label" htmlFor="parkingSpace1">
                YES
              </label>

              <div style={{ display: "inline-block", marginLeft:"10px" }}>
                <input
                  className="form-check-input"
                  type="radio"
                  name="parkingSpace"
                  id="parkingSpace2"
                  value="NO"
                  checked={state.parkingSpace === "NO"}
                  onChange={(e) => {
                    onChangeHandler("parkingSpace", e.target.value);
                  }}
                />
                <label className="form-check-label" htmlFor="parkingSpace2">
                  NO
                </label>
              </div>
              <div style={{ color: "red", fontSize: 13 }}>
                {stateError.parkingSpace}
              </div>
            </div> */}
            
            <div className="row p-2">
            <div className="p-2 col-sm-4">
              <FormControl variant="outlined" fullWidth>
                <TextField
                  label="Property Area"
                  type="number"
                  value={state.squareFeet}
                  size="small"
                  onChange={(e) => {
                    onChangeHandler("squareFeet", e.target.value);
                  }}
                  required
                ></TextField>
                <div style={{ color: "red", fontSize: 13 }}>
                  {stateError.squareFeet}
                </div>
              </FormControl>
            </div>

            <div className="p-2 col-sm-4">
              <FormControl variant="outlined" fullWidth>
                <TextField
                  label="C.S.NO"
                  type="number"
                  value={state.csNo}
                  size="small"
                  onChange={(e) => {
                    onChangeHandler("csNo", e.target.value);
                  }}
                  required
                ></TextField>
                <div style={{ color: "red", fontSize: 13 }}>
                  {stateError.csNo}
                </div>
              </FormControl>
            </div>
            </div>

            <div className="p-2">
              <FormControl variant="outlined" fullWidth>
                <TextField
                  label="Property Address"
                  type="text"
                  size="small"
                  value={state.propertyAddress}
                  onChange={(e) => {
                    onChangeHandler("propertyAddress", e.target.value);
                  }}
                  required
                ></TextField>
                <div style={{ color: "red", fontSize: 13 }}>
                  {stateError.propertyAddress}
                </div>
              </FormControl>
            </div>

            <div className="row p-2 ">
              <div className="p-2 col-sm-4">
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    label="Location"
                    type="text"
                    size="small"
                    value={state.propertyLocation}
                    onChange={(e) => {
                      onChangeHandler("propertyLocation", e.target.value);
                    }}
                    required
                  ></TextField>
                  <div style={{ color: "red", fontSize: 13 }}>
                    {stateError.propertyLocation}
                  </div>
                </FormControl>
              </div>
              <div className="p-2 col-sm-4">
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    label="SubLocation"
                    type="text"
                    size="small"
                    value={state.propertySubLocation}
                    onChange={(e) => {
                      onChangeHandler("propertySubLocation", e.target.value);
                    }}
                    required
                  ></TextField>
                  <div style={{ color: "red", fontSize: 13 }}>
                    {stateError.propertySubLocation}
                  </div>
                </FormControl>
              </div>
              <div className="p-2 col-sm-4">
                <FormControl variant="outlined" fullWidth>
                  <TextField
                    label="PinCode"
                    type="text"
                    size="small"
                    value={state.propertyPinCode}
                    onChange={(e) => {
                      onChangeHandler("propertyPinCode", e.target.value);
                    }}
                    required
                  ></TextField>
                  <div style={{ color: "red", fontSize: 13 }}>
                    {stateError.propertyPinCode}
                  </div>
                </FormControl>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
}
