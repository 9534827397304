import React from "react";
import Footer from "../../Components/Layouts/Footer";
import { ListYourProperty } from "./ListYourProperty";
import { VideoTour } from "./VideoTour";
import { ContactUs } from "./ContactUs";
import { Downloadapp } from "./Downloadapp";
import { Testimonial } from "./Testimonial";
import { FindProperty } from "./FindProperty";
import AdminSidebar from "../../Components/Sidebar/AdminSidebar";
import PropertyService from "../../Components/Common/PropertyService";


export default function HomePage() {
  return (
    <div>
      <AdminSidebar />
      <FindProperty />

      <ListYourProperty />
      <PropertyService />
      <VideoTour />
      <Testimonial />
      <Downloadapp />
      <ContactUs />
      <Footer />
    </div>
  );
}
