import axios from "axios";
import { settings } from "../Config/Settings";

const OrderPayment = (data) => {
  return axios({
    method: "POST",
    url: `${settings.API_URL}/paymentRoute/orders`,
    data: data,
  });
};

const AgreementPayment = (data) => {
  return axios({
    method: "POST",
    url: `${settings.API_URL}/paymentRoute/agrement`,
    data: data,
  });
};

const VerifyPayment = (data) => {
  return axios({
    method: "POST",
    url: `${settings.API_URL}/paymentRoute/verify`,
    data: data,
  });
};

const getPaymentStatus = (id) => {
  return axios({
    method: "GET",
    url: `${settings.API_URL}/paymentRoute/getPayments/${id}`,
  });
};

export { OrderPayment, AgreementPayment, VerifyPayment, getPaymentStatus };
