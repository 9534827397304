import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { deleteLocation } from "../../Api/AuthAPI";
import Swal from "sweetalert2";
import ReactPaginate from "react-paginate";
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import IconButton from "@mui/material/IconButton";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditIcon from "@mui/icons-material/Edit";
const LocationMasterDetails = ({ onEdit, locationData,getLocationDataList }) => {

  const [pageNumber, setPageNumber] = useState(0);
  const [searchTerm, setSearchTerm] = useState("")
  const itemsPerPage = 5;
  

  const filteredData = locationData.filter((location) =>
  location.locationName.toLowerCase().includes(searchTerm.toLowerCase()) ||
  location.subLocation.some((sub) =>
    sub.subLocationName.toLowerCase().includes(searchTerm.toLowerCase())
  )
);

  const pageCount = Math.ceil(locationData.length / itemsPerPage);
  const offset = pageNumber * itemsPerPage;
  const currentPageData = filteredData.slice(offset, offset + itemsPerPage);

  const DeleteLocation = (locationId) => {
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteLocation(locationId)
          .then((res) => {
            if (res.status === 200) {
              Swal.fire({
                icon: "success",
                title: "Location deleted successfully.",
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "ok",
              });
              getLocationDataList();
            } else {
              Swal.fire({
                icon: "warning",
                title: "Location Not Deleted! Please try again.",
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "ok",
              });
            }
          })
          .catch((err) => {
            console.log(err, "error in location delete");
          });
      } else {
        Swal.fire({
          icon: "info",
          title: "Your Location Data is safe!",
          showConfirmButton: true,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "ok",
        });
      }
    });
  };

  const handlePageChange = ({ selected }) => {
    setPageNumber(selected);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPageNumber(0);
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <>
      <div
        className="container mt-5 justify-content-center mb-5"
        style={{ marginTop: "10rem" }}
      >
         <div className="d-flex justify-content-end mb-2">
            <input
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearchChange}
              style={{ padding: "5px", width: "250px" }}
            />
          </div>

        <Paper
          sx={{ width: "80%", overflow: "hidden", justifyContent: "center" }}
        >
          {/* <div className="container mt-2">
            <div className="text-center mt-3">
              <h2 className="font-weight-bold">Location Master Details</h2>
            </div>
          </div> */}

          <TableContainer sx={{ maxHeight: 700, overflowX: "auto" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>Sr.No.</TableCell>
                  <TableCell>Location</TableCell>
                  <TableCell>Sub Location</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentPageData.map((datalocation, index) => {
                const serialNumber = offset + index + 1;
                return(
                <TableRow key={index}>
                    <TableCell>{serialNumber}</TableCell>
                    <TableCell>{capitalizeFirstLetter(datalocation.locationName)}</TableCell>
                    <TableCell>
                      {datalocation.subLocation.map((d) => capitalizeFirstLetter(d.subLocationName)).join(", ")}
                    </TableCell>
                    <TableCell>
                      <IconButton>
                        <EditIcon
                          color="primary"
                          onClick={() => onEdit(datalocation)}
                        />
                      </IconButton>

                      <IconButton aria-label="settings">
                        <DeleteOutlineOutlinedIcon
                          color="error"
                          onClick={() => DeleteLocation(datalocation._id)}
                        />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )})}
              </TableBody>
            </Table>
            <div className="container">
                <ReactPaginate
                  previousLabel={<NavigateBeforeIcon />}
                  nextLabel={<NavigateNextIcon />}
                  breakLabel={"..."}
                  pageCount={pageCount}
                  onPageChange={handlePageChange}
                  containerClassName={"pagination"}
                  activeClassName={"activepagination"}
                  pageClassName={"pagination-item"}
                />
            </div>
          </TableContainer>
        </Paper>
      </div>
    </>
  );
};

export default LocationMasterDetails;
