import React, { useEffect, useState } from "react";
//@mui packages
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import { Button, Container, Modal } from "react-bootstrap";
import "./pagination.css";
//icons
import VisibilityIcon from "@mui/icons-material/Visibility";
import PreviewIcon from "@mui/icons-material/Preview";
import ReactPaginate from "react-paginate";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
//custom components
import Loader from "../../Components/Common/Loader";
import AdminSidebar from "../../Components/Sidebar/AdminSidebar";
// api
import { allUserRoleList, updateAgentStatus } from "../../Api/AuthAPI";

const ListedOwners = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [listData, setListData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [viewModel, setViewModel] = useState(false);
  const [profileData, setProfileData] = useState([]);

  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const itemsPerPage = 10;

  const filteredListData = listData.filter(
    (data) =>
      data.fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      data.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      data.ownerStatus.toLowerCase().includes(searchTerm.toLowerCase()) ||
      data.phone.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const pageCount = Math.ceil(filteredListData.length / itemsPerPage);
  const offset = page * itemsPerPage;
  const currentPageData = filteredListData.slice(offset, offset + itemsPerPage);

  useEffect(() => {
    getAgentList();
  }, []);

  const columns = [
    { id: "id", label: "Sr. No.", minWidth: 20 },
    {
      id: "profileImage",
      label: "Profile Image",
      minWidth: 100,
    },
    { id: "fullName", label: "Owner Name", minWidth: 100 },
    {
      id: "email",
      label: "Email",
      minWidth: 100,
    },
    {
      id: "contact",
      label: "Contact Number",
      minWidth: 100,
    },

    {
      id: "status",
      label: "Status",
      minWidth: 100,
    },
  ];
  const handleChangePage = ({ selected }) => {
    setPage(selected);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getAgentList = () => {
    setLoading(true);
    allUserRoleList("Owner")
      .then((res) => {
        let result = res.data.Profiles;
        setListData(result);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.log("====================================");
        console.log(e, "error in owner List");
        console.log("====================================");
      });
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const OnView = (profileData) => {
    setViewModel(true);
    setProfileData(profileData);
  };
  const handleClose = () => {
    setViewModel(false);
  };

  const verificationStatus = [
    { value: "Block", label: "Block" },
    { value: "Unblock", label: "Unblock" },
  ];

  const updateStatus = async (userID, status, i) => {
    let updateData = {
      ownerStatus: status,
    };
    setLoading(true);
    updateAgentStatus(userID, updateData)
      .then((res) => {
        if (res.data.status === 1) {
          let data = [...listData];
          data[i]["ownerStatus"] = status;
          setListData(data);
          setLoading(false);
        }
      })
      .catch((e) => {
        console.log("====================================");
        console.log(e);
        console.log("====================================");
      });
  };

  const handleImageModalOpen = (imageUrl) => {
    setSelectedImage(imageUrl);
    setImageModalOpen(true);
  };

  const handleImageModalClose = () => {
    setImageModalOpen(false);
    setSelectedImage("");
  };

  return (
    <>
      <Container>
        <AdminSidebar />
        <div className="topNav-m">
          <div className=" content-title">
            <h3>Listed Owner</h3>
            <hr className="content-title-underline" />
          </div>
          <div className="d-flex justify-content-end mb-2">
            <input
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearchChange}
              style={{ padding: "5px", width: "250px" }}
            />
          </div>

          <Paper>
            {loading && <Loader />}
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <>
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                      </>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>

                  {currentPageData?.length > 0 ?
                    currentPageData.map((listData, i) => {
                      const serialNumber = offset + i + 1;
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1}>
                          <TableCell>{serialNumber}</TableCell>
                          <TableCell>
                            <IconButton
                              className="ownerImg"
                              onClick={() =>
                                handleImageModalOpen(
                                  `${listData.profileImage[0]?.name || 'https://api.equireal.co.in/default_profile_image.png'}`
                                )
                              }
                            >
                              <img
                                src={listData.profileImage[0]?.name || 'https://api.equireal.co.in/default_profile_image.png'}
                                alt="not available"
                              />

                            </IconButton>
                          </TableCell>
                          <TableCell>{listData.fullName}</TableCell>
                          <TableCell>{listData.email}</TableCell>
                          <TableCell>{listData.phone}</TableCell>

                          <TableCell>
                            <div className="d-flex gap-2">
                              {verificationStatus.map((option, index) => (
                                <div class="containerblockedradio " key={index}>
                                  <form>
                                    <label
                                      className={
                                        option.value === "Block"
                                          ? "blocklabel"
                                          : "unblocklabel"
                                      }
                                    >
                                      <input
                                        name="radio"
                                        type="radio"
                                        checked={
                                          option.value === listData.ownerStatus
                                        }
                                        onChange={(e) =>
                                          updateStatus(
                                            listData._id,
                                            option.value,
                                            i
                                          )
                                        }
                                      />
                                      <span>{option.value}</span>
                                    </label>
                                  </form>
                                </div>
                              ))}
                            </div>
                          </TableCell>

                          {/* <TableCell>
                            <IconButton>
                              <VisibilityIcon
                                color="primary"
                                onClick={() => OnView(listData)}
                              />
                            </IconButton>
                          </TableCell> */}
                        </TableRow>
                      );
                    })
                    :
                    <TableRow>
                      Data not found
                    </TableRow>
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <ReactPaginate
              previousLabel={<NavigateBeforeIcon />}
              nextLabel={<NavigateNextIcon />}
              breakLabel={"..."}
              pageCount={pageCount}
              onPageChange={handleChangePage}
              containerClassName={"pagination"}
              activeClassName={"activepagination"}
              pageClassName={"pagination-item"}
            />
          </Paper>
        </div>
      </Container>

      <Modal
        show={imageModalOpen}
        onHide={handleImageModalClose}
        centered
        className="modal-lg "
      >
        <Modal.Header closeButton>
          <Modal.Title className="loginTitle">Profile Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src={selectedImage}
            alt="Preview"
            style={{ width: "100%", height: "auto" }}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ListedOwners;
