import React, { useEffect, useRef, useState } from "react";
// import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { SidebarData } from "./Sidebar";
import SubMenu from "./Submenu";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { CgProfile } from "react-icons/cg";
//icons
import * as AiIcons from "react-icons/ai";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import { FaPowerOff } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import Auth from "../../Config/helper/Auth";
import { ethers } from "ethers";
import MetaMaskOnboarding from "@metamask/onboarding";
import { NavDropdown, Button, Navbar, Nav } from "react-bootstrap";
import { Tooltip } from "@mui/material";
import logoImage from "../../Assets/equireal-logo.png"
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

// const Navs = styled.div`
//   background: #fff;
//   height: 80px;
//   display: flex;
//   justify-content: flex-start;
//   align-items: center;
// `;

// const NavIcon = styled(Link)`
//   margin: 0 2rem;
//   font-size: 1.6rem;
//   height: 80px;
//   display: flex;
//   justify-content: flex-end;

//   align-items: center;
// `;

// const SidebarNav = styled.nav`
//   background: #fff;
//   width: 300px;
//   height: 100vh;
//   display: flex;
//   justify-content: center;
//   position: fixed;
//   top: 0;
//   left: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
//   transition: 350ms;
//   z-index: 10;
//   box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
//     rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
//   z-index: 100;
//   border-right: 0px solid #ccc;
// `;

// const SidebarWrap = styled.div`
//   width: 100%;
// `;

const AdminSidebar = () => {
  const theme = useTheme();
  // const [open, setOpen] = React.useState(false);
  const [open, setOpen] = useState(false);
  const drawerRef = useRef(null);

  const handleClickOutside = (event) => {
    if (drawerRef.current && !drawerRef.current.contains(event.target)) {
      handleDrawerClose();
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const forwarderOrigin = "http://localhost:3000";
  const onboarding = new MetaMaskOnboarding({ forwarderOrigin });

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const Navigate = useNavigate();
  const loginData = Auth.getUserDetails();
  const [sidebar, setSidebar] = useState(false);
  const [account, setAccount] = useState(null);

  const showSidebar = () => setSidebar(!sidebar);

  const handleLogout = () => {
    Auth.logout();
    Navigate("/login");
  };

  const accountUpdate = async (account) => {
    setAccount(ethers.getAddress(account));
  };

  const handleLogin = () => {
    if (window.ethereum && window.ethereum.isMetaMask) {
      console.log("MetaMask Here!");
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((result) => {
          // setLogged(true);
          accountUpdate(result[0]);
        })
        .catch((error) => {
          console.log("Could not detect Account");
        });
    } else {
      console.log("Need to install MetaMask");
      onboarding.startOnboarding();
    }
  };
  const location = useLocation();
  const showSidebarOnPage = location.pathname === "/myprofile";
  const showSidebarOnShortlistProperties = location.pathname === "/ShortlistProperties";

  return (
    <>
      {/* <IconContext.Provider value={{ color: "#fff" }}>
        <Navbar className="equi-navbar " collapseOnSelect fixed="top">
          <div className="d-flex align-items-center px-2">
            <img
              src="../assets/images/equireal-logo.png"
              alt="EquiReal"
              className="admin-logo img-fluid"
              onClick={() => {
                Navigate("/");
              }}
              style={{ cursor: "pointer" }}
            />
            {!loginData ||
            loginData?.role === "Owner" ||
            loginData?.role === "Admin" ||
            loginData?.role === "Agent" ? null : (
              <Navbar.Brand>
                <button
                  class="dashboardpass"
                  onClick={() => {
                    Navigate("/myprofile");
                  }}
                >
                  DASHBOARD
                </button>
              </Navbar.Brand>
            )}

            {loginData?.role === "Admin" ||
            loginData?.role === "Agent" ||
            loginData?.role === "Owner" ? (
              <NavIcon to="#">
                <MenuIcon onClick={showSidebar} color="#15171c" />
              </NavIcon>
            ) : (
              <span></span>
            )}
          </div>
          <div className="d-flex align-items-center px-2">
            {loginData?.role === "Agent" || loginData?.role === "Owner" ? (
              <div>
                <button
                  className="post-property"
                  onClick={() => {
                    Navigate("/genreceipt");
                  }}
                >
                  Generate Receipt
                </button>
              </div>
            ) : null}
            &nbsp;
            {!loginData ? (
              <Link className="nav-link mt-1" to={"/signup"}>
                SIGN UP
              </Link>
            ) : (
              // <NavDropdown
              //   title={loginData && loginData.fullName}
              //   id="collasible-nav-dropdown"
              //   drop="start"
              // >
              //   <NavDropdown.Item
              //     onClick={() => {
              //       Navigate("/myprofile");
              //     }}
              //   >
              //     EDIT PROFILE
              //   </NavDropdown.Item>
              //   <NavDropdown.Item
              //     onClick={() => {
              //       Navigate("/myprofile");
              //     }}
              //   >
              //     MY LISTING
              //   </NavDropdown.Item>

              //   <Link onClick={() => handleLogout()}>
              //     <Button className="mt-2">Sign Out</Button>
              //   </Link>
              // </NavDropdown>
              <>
                <Nav.Link>{loginData && loginData.fullName}</Nav.Link>
                <button className="SignOut" onClick={() => handleLogout()}>
                  <span>Sign Out</span>
                  <svg
                    width="25"
                    height="34"
                    viewBox="0 0 74 74"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="37"
                      cy="37"
                      r="35.5"
                      stroke="black"
                      stroke-width="3"
                    ></circle>
                    <path
                      d="M25 35.5C24.1716 35.5 23.5 36.1716 23.5 37C23.5 37.8284 24.1716 38.5 25 38.5V35.5ZM49.0607 38.0607C49.6464 37.4749 49.6464 36.5251 49.0607 35.9393L39.5147 26.3934C38.9289 25.8076 37.9792 25.8076 37.3934 26.3934C36.8076 26.9792 36.8076 27.9289 37.3934 28.5147L45.8787 37L37.3934 45.4853C36.8076 46.0711 36.8076 47.0208 37.3934 47.6066C37.9792 48.1924 38.9289 48.1924 39.5147 47.6066L49.0607 38.0607ZM25 38.5L48 38.5V35.5L25 35.5V38.5Z"
                      fill="black"
                    ></path>
                  </svg>
                </button>
              </>
            )}
          </div>
        </Navbar>

        <SidebarNav sidebar={sidebar} className="topbanner">
          <SidebarWrap>
            <NavIcon to="#">
              <AiIcons.AiOutlineClose onClick={showSidebar} color="#15171c" />
            </NavIcon>
            {SidebarData.map((item, index) => {
              return <SubMenu item={item} key={index} />;
            })}
          </SidebarWrap>
        </SidebarNav>
      </IconContext.Provider> */}
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="fixed" open={open} color="default">
          <Toolbar className="d-flex justify-content-between">
            <div>
              {loginData?.role !== "Buyer" && loginData?.role !== undefined ?
               ( <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  sx={{
                    marginRight: 5,
                    ...(open && { display: "none" }),
                  }}
                >
                  <MenuIcon />
                </IconButton>
                ): showSidebarOnPage || showSidebarOnShortlistProperties ? (
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    sx={{
                      marginRight: 5,
                      ...(open && { display: "none" }),
                    }}
                  >
                    <MenuIcon />
                  </IconButton>
                ) : null}
              <img
                src={logoImage}
                alt="EquiReal"
                className="admin-logo img-fluid"
                onClick={() => {
                  Navigate("/");
                }}
                style={{ cursor: "pointer" }}
              />
            </div>
            <div>
              <div className="d-flex align-items-center px-2">
                {loginData?.role === "Buyer" || loginData?.role === "Tenant" ? (
                  <>

                    <button className="post-property" onClick={handleLogin}>
                      Connect Wallet
                    </button>


                  </>
                ) : null}
                {loginData?.role === "Agent" || loginData?.role === "Owner" ? (
                  <div>
                    <button
                      className="post-property"
                      onClick={() => {
                        Navigate("/genreceipt");
                      }}
                    >
                      Generate Receipt
                    </button>
                  </div>
                ) : null}
                &nbsp;
                {!loginData ? (
                  <Link className="nav-link mt-1" to={"/login"}>
                    SIGN UP
                  </Link>
                ) : (
                  // <NavDropdown
                  //   title={loginData && loginData.fullName}
                  //   id="collasible-nav-dropdown"
                  //   drop="start"
                  // >
                  //   <NavDropdown.Item
                  //     onClick={() => {
                  //       Navigate("/myprofile");
                  //     }}
                  //   >
                  //     EDIT PROFILE
                  //   </NavDropdown.Item>
                  //   <NavDropdown.Item
                  //     onClick={() => {
                  //       Navigate("/myprofile");
                  //     }}
                  //   >
                  //     MY LISTING
                  //   </NavDropdown.Item>

                  //   <Link onClick={() => handleLogout()}>
                  //     <Button className="mt-2">Sign Out</Button>
                  //   </Link>
                  // </NavDropdown>
                  <>
                    <Tooltip title="profile">
                      <IconButton onClick={() => {
                        Navigate("/myprofile");
                      }}>
                        {loginData?.role === "Admin" ? null :<CgProfile fontSize={25} />}
                      </IconButton>
                    </Tooltip>
                    <Nav.Link>
                      <span style={{ fontSize: '12px' }}>
                        {loginData && loginData.fullName} ({loginData && loginData.role})
                      </span>
                      {/* <br/>
                    <span style={{ fontSize: '10px' }}>
                      {loginData && loginData.role}
                    </span> */}
                    </Nav.Link>

                    <Tooltip title="LOGOUT">
                      <IconButton onClick={() => handleLogout()}>

                        <FaPowerOff color="red" fontSize={20} />
                        {/* <ExitToAppOutlinedIcon /> */}
                      </IconButton>
                    </Tooltip>
                    {/* <button className="SignOut" onClick={() => handleLogout()}>
                  <span>Sign Out</span>
                  <svg
                    width="25"
                    height="34"
                    viewBox="0 0 74 74"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="37"
                      cy="37"
                      r="35.5"
                      stroke="black"
                      stroke-width="3"
                    ></circle>
                    <path
                      d="M25 35.5C24.1716 35.5 23.5 36.1716 23.5 37C23.5 37.8284 24.1716 38.5 25 38.5V35.5ZM49.0607 38.0607C49.6464 37.4749 49.6464 36.5251 49.0607 35.9393L39.5147 26.3934C38.9289 25.8076 37.9792 25.8076 37.3934 26.3934C36.8076 26.9792 36.8076 27.9289 37.3934 28.5147L45.8787 37L37.3934 45.4853C36.8076 46.0711 36.8076 47.0208 37.3934 47.6066C37.9792 48.1924 38.9289 48.1924 39.5147 47.6066L49.0607 38.0607ZM25 38.5L48 38.5V35.5L25 35.5V38.5Z"
                      fill="black"
                    ></path>
                  </svg>
                </button> */}
                  </>
                )}
              </div>
            </div>
          </Toolbar>
        </AppBar>
        {loginData?.role !== "Buyer" && loginData?.role !== undefined ?
          <Drawer variant="permanent" open={open} ref={drawerRef}>
            <DrawerHeader>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === "rtl" ? (
                  <ChevronRightIcon />
                ) : (
                  <ChevronLeftIcon />
                )}
              </IconButton>
            </DrawerHeader>
            <div className="sidenav">
              {SidebarData.map((item, index) => (
                <SubMenu item={item} key={index} />
              ))}
            </div>
          </Drawer>
          : showSidebarOnPage || showSidebarOnShortlistProperties ? (
            <Drawer variant="permanent" open={open} ref={drawerRef}>
              <DrawerHeader>
                <IconButton onClick={handleDrawerClose}>
                  {theme.direction === "rtl" ? (
                    <ChevronRightIcon />
                  ) : (
                    <ChevronLeftIcon />
                  )}
                </IconButton>
              </DrawerHeader>
              <div className="sidenav">
                {SidebarData.map((item, index) => (
                  <SubMenu item={item} key={index} />
                ))}
              </div>
            </Drawer>
          ) : null}
        {/* {loginData?.role === "Buyer" || loginData?.role === undefined ? null :
          <Drawer variant="permanent" open={open}> */}
        {/* <DrawerHeader>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === "rtl" ? (
                  <ChevronRightIcon />
                ) : (
                  <ChevronLeftIcon />
                )}
              </IconButton>
            </DrawerHeader>
            <div className="sidenav">
              {SidebarData.map((item, index) => {
                return <SubMenu item={item} key={index} />;
              })}
            </div> */}
        {/*         
      <List>
          <ListItem disablePadding>
            <ListItemButton href= "/adminDashboard">
              <ListItemIcon title="Dashboard">
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton  href="/properties">
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary="Properties" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton  href="/receiptList">
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary="Payment Details" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton  href="/ownerList">
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary="Listed Owners" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton  href="/agentList">
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary="Listed Agents" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton  href="/enquieyDetails">
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary="Enquiry Details" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton  href="/locationMaster">
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary="Location Master" />
            </ListItemButton>
          </ListItem>

            <ListItem disablePadding>
            <ListItemButton  href="/amenitiesMaster">
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary="Amenities Master" />
            </ListItemButton>
          </ListItem>
            <ListItem disablePadding>
            <ListItemButton  href="/properties">
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary="Properties" />
            </ListItemButton>
          </ListItem>
        </List> */}
        {/* </Drawer>
        } */}
      </Box>
    </>
  );
};

export default AdminSidebar;
