import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//@mui packages
import Paper from "@mui/material/Paper";

//react packages
import { MdOutlineCabin } from "react-icons/md";
import swal from "sweetalert";
//icons...
import SquareFootOutlinedIcon from "@mui/icons-material/SquareFootOutlined";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import AdminSidebar from "../../Components/Sidebar/AdminSidebar";
import moment from "moment";
import Auth from "../../Config/helper/Auth";
import { getBuilderReviewByUserId } from "../../Api/BuilderProjectAPI";
import BedOutlinedIcon from "@mui/icons-material/BedOutlined";
import { MdMiscellaneousServices } from "react-icons/md";
import { MdOutlineAdUnits } from "react-icons/md";
import { FaCommentDots } from "react-icons/fa";

import './builderreviewproperty.css';
import NewProjectSearchProperty from "../Admin/NewProjectSearchProperty";

const ReviewPropertyBuilder = () => {

  const navigate = useNavigate()
  let userId = Auth?.getUserDetails();

  const [reviewData, setReviewData] = useState([]);

  useEffect(() => {
    getBuilderReviewByUserId(userId._id)
      .then((res) => {

        if (res.status === 200) {
          let reviewData = res?.data?.reviewData;
          setReviewData(reviewData);
        } else if (res.data.status === 400) {
          swal("data not found");
        }
      })
      .catch((err) => {
        console.log(err, "error in get Review Property");
      });
  }, []);

  // const formatCash = (n) => {
  //   if (n < 1e3) return n;
  //   if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
  //   if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
  //   if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
  //   if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
  // };

  const formatCash = (n) => {
    if (n < 1e5) return n;  // Less than 1 lakh
    if (n >= 1e5 && n < 1e7) return +(n / 1e5).toFixed(1) + "L";  // Lakh
    if (n >= 1e7) return +(n / 1e7).toFixed(1) + "Cr";  // Crore
  };

  const renderStars = (rating) => {
    const stars = [];
    for (let i = 0; i < rating; i++) {
      stars.push(<span key={i} className="star">&#9733;</span>);
    }
    return stars;
  };


  return (
    <>
      <AdminSidebar />

      {/* <div className="mt-4 d-flex justify-content-center">
        <Paper
          sx={{
            width: "70%",
            marginTop: "10rem",
            overflow: "hidden",
            justifyContent: "center",
          }}
        >
          <div className="text-center p-2">
            <h5>Review Properties</h5>
          </div>
            {reviewData &&
              reviewData.map((listData, ind) => {
                return (
                  <>
                    <div
                      className="p-card-content"
                      onClick={() =>
                        navigate(`/builderProjectFulltab/${listData?.ProjectData?._id}`, {
                          state: {projectData: listData?.ProjectData},
                        })
                      }
                    >
                      <div className="p-card">
                        <div className="p-img">
                          <img
                            src={`${listData?.ProjectData?.photos[0]?.projectImages[0]?.name}`}
                            alt="Image not available"
                          />
                        </div>

                        <div className="p-info">
                          <div className="p-tle">
                            <div>
                              <h4>
                                {listData?.ProjectData?.nameOfProject} Project
                              </h4>
                            </div>
                          </div>
                          <div className="pr-info">
                            <ul>
                              <li>
                                <PeopleOutlineOutlinedIcon />
                                <h6>{listData?.ProjectData?.propertyOwner}</h6>
                              </li>
                              <li>
                                <BedOutlinedIcon />
                                <h6> {listData?.ProjectData?.areaType[0]}</h6>
                              </li>
                              <li>
                                <MdOutlineAdUnits />
                                <h6>
                                  <h6> {listData?.ProjectData?.totalUnits} units</h6>
                                </h6>
                              </li>
                              <li>
                                <MdMiscellaneousServices />
                                <h6>
                                  <h6>{listData?.ProjectData?.Amenities.join(', ')}</h6>
                                </h6>
                              </li>
                            </ul>
                          </div>
                          <div className="d-flex justify-content-end">
                            <button
                              class="cta"
                              onClick={() =>
                                navigate(`/builderProjectFulltab/${listData?.ProjectData?._id}`, {
                                  state: {projectData: listData?.ProjectData},
                                })
                              }
                            >
                              <span class="hover-underline-animation"> View More </span>
                              <svg
                                id="arrow-horizontal"
                                xmlns="http://www.w3.org/2000/svg"
                                width="30"
                                height="10"
                                viewBox="0 0 46 16"
                              >
                                <path
                                  id="Path_10"
                                  data-name="Path 10"
                                  d="M8,0,6.545,1.455l5.506,5.506H-30V9.039H12.052L6.545,14.545,8,16l8-8Z"
                                  transform="translate(30)"
                                ></path>
                              </svg>
                            </button>
                          </div>
                        </div>
                        <div className="p-pri2">
                          <div>
                            <h4>₹{formatCash(listData?.ProjectData?.minPrice)}/- To  ₹{formatCash(listData?.ProjectData?.maxPrice)}/-</h4>
                            <p>Project Price</p>
                          </div>

                          <div>
                            <h4>{moment(listData?.ProjectData?.launchDate).format("DD/MM/YYYY")}</h4>
                            <p>
                              <p href="">Launch Date</p>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
         
        </Paper>
      </div> */}

      <div className="container topNav-m">

        <div className="text-center p-2">
          <h5>Review Properties</h5>
        </div>
        <div>
          <NewProjectSearchProperty userId={userId} />
        </div>
        <div
          className="review-grids"
        >
          {reviewData &&
            reviewData.map((listData, ind) => {
              return (
                <div
                  className="review-card"
                  key={ind}
                >
                  {listData?.reviews[0]?.ProjectData?.photos[0]?.projectImages[0]?.name ?
                    (<img
                      src={`${listData?.reviews[0]?.ProjectData?.photos[0]?.projectImages[0]?.name}`}
                      alt="Property"
                      className="property-image"
                    />) 
                    :
                    (
                      <img
                        src="https://api.equireal.co.in/defaultProperty.jpg"
                        alt="Property"
                        className="property-image"
                      />
                    )
                  }

                  <h2
                    className="property-name"
                  >
                    {listData?.ProjectData?.nameOfProject}
                  </h2>
                  <div
                    className="review-header" >
                    <div
                      className="avatar"
                    >
                      {listData?.reviews[0]?.UserId?.profileImage[0]?.name ? (
                        <img
                          src={`${listData?.reviews[0]?.UserId?.profileImage[0]?.name}`}
                          alt="User Profile"
                          className="avatar"
                          style={{
                            width: '35px',
                            height: '35px',
                            borderRadius: '50%',
                            backgroundColor: '#BD1550',
                            marginRight: '10px',
                          }}
                        />
                      ) : (
                        <img
                          src="https://api.equireal.co.in/default_profile_image.png"
                          alt="Default Profile"
                          className="avatar"
                          style={{
                            width: '35px',
                            height: '35px',
                            borderRadius: '50%',
                            backgroundColor: '#BD1550',
                            marginRight: '10px',
                          }}
                        />
                      )}
                    </div>
                    <div>
                      <h3 className="name">
                        {listData?.reviews[0]?.UserId?.fullName}
                      </h3>
                      <div
                        className="rating-r">
                        {renderStars(listData?.reviews[0]?.Rating)} {listData?.reviews[0]?.Rating}/5
                      </div>
                    </div>
                  </div>
                  <p className="comment">

                    <FaCommentDots /> {listData?.reviews[0]?.Comment}

                  </p>

                  <div className="d-flex justify-content-end">
                    <button
                      className="cta"
                      onClick={() =>
                        navigate(
                          `/MoreReviewPropertyBuilder/${listData?.PropertyId}`,
                          {
                            state: {
                              yourData: listData,
                              yourDataNameOfProject: listData?.reviews[0]?.ProjectData?.nameOfProject,
                            },
                          }
                        )
                      }
                      style={{ alignItems: 'right' }}
                    >
                      <span class="hover-underline-animation" style={{ fontSize: '10px' }}> All Reviews </span>
                      <svg
                        id="arrow-horizontal"
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="10"
                        viewBox="0 0 46 16"
                      >
                        <path
                          id="Path_10"
                          data-name="Path 10"
                          d="M8,0,6.545,1.455l5.506,5.506H-30V9.039H12.052L6.545,14.545,8,16l8-8Z"
                          transform="translate(30)"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </div>
              );
            })}
        </div>
      </div>



      {/* <div className="mt-4 d-flex justify-content-center">
        <Paper
          sx={{
            width: "70%",
            marginTop: "10rem",
            overflow: "hidden",
            justifyContent: "center",
          }}
        >
          <div className="text-center p-2">
            <h5>Review Properties</h5>
          </div>
          {reviewData &&
            reviewData.map((listData, ind) => {
              return (
                <div
                  key={ind}
                  className="p-card-content"
                  onClick={() =>
                    navigate(
                      `/builderProjectFulltab/${listData?.ProjectData?._id}`,
                      {
                        state: { projectData: listData?.ProjectData },
                      }
                    )
                  }
                >
                  <div className="p-card d-flex">
                    <div className="p-img">
                      <img
                        src={`${listData?.ProjectData?.photos[0]?.projectImages[0]?.name}`}
                        alt="Image not available"
                      />
                    </div>

                    <div className="p-info">
                      <div className="p-tle">
                        <h4>{listData?.ProjectData?.nameOfProject} Project</h4>
                      </div>
                      <div className="pr-info">
                        <ul>
                          <li>
                            <PeopleOutlineOutlinedIcon />
                            <h6>{listData?.ProjectData?.propertyOwner}</h6>
                          </li>
                          <li>
                            <BedOutlinedIcon />
                            <h6> {listData?.ProjectData?.areaType[0]}</h6>
                          </li>
                          <li>
                            <MdOutlineAdUnits />
                            <h6>{listData?.ProjectData?.totalUnits} units</h6>
                          </li>
                          <li>
                            <MdMiscellaneousServices />
                            <h6>
                              {listData?.ProjectData?.Amenities.join(", ")}
                            </h6>
                          </li>
                        </ul>
                      </div>
                      <div className="d-flex justify-content-end">
                        <button
                          className="cta"
                          onClick={() =>
                            navigate(
                              `/builderProjectFulltab/${listData?.ProjectData?._id}`,
                              {
                                state: { projectData: listData?.ProjectData },
                              }
                            )
                          }
                        >
                          <span className="hover-underline-animation">
                            View More
                          </span>
                          <svg
                            id="arrow-horizontal"
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            height="10"
                            viewBox="0 0 46 16"
                          >
                            <path
                              id="Path_10"
                              data-name="Path 10"
                              d="M8,0,6.545,1.455l5.506,5.506H-30V9.039H12.052L6.545,14.545,8,16l8-8Z"
                              transform="translate(30)"
                            ></path>
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div className="p-pri">
                      <div>
                        <h4>
                          ₹{formatCash(listData?.ProjectData?.minPrice)} /- To ₹
                          {formatCash(listData?.ProjectData?.maxPrice)} /-
                        </h4>
                        <p>Project Price</p>
                      </div>

                      <div>
                        <h4>
                          {moment(listData?.ProjectData?.launchDate).format(
                            "DD/MM/YYYY"
                          )}
                        </h4>
                        <p>Launch Date</p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </Paper>
      </div> */}
    </>
  )
}

export default ReviewPropertyBuilder