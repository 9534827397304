// Api for buyer/seller
import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import Auth from "../Config/helper/Auth";

const AfterLoginUser = () => {
  const userRole = Auth.getUserDetails();
  const token = Auth.getToken();
  return token &&
    (userRole.role === "tenant" ||
      userRole.role === "Buyer" ||
      userRole.role === "Seller") ? (
    <Outlet />
  ) : (
    <Navigate to="/login" />
  );
};

export default AfterLoginUser;
