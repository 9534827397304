import React, { useEffect, useState } from "react";
import AdminSidebar from "../../Components/Sidebar/AdminSidebar";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import Select from "react-select";
import { FormControl, TextField } from "@mui/material";
import LocationMasterDetails from "../Admin/LocationMasterDetails";
import {
    AddLocation,
    updateLocation,
    getLocationData,
    NewAddLocation,
    NewGetAreaLocations,
    NewUpdateAreaLocation,
} from "../../Api/AuthAPI";
import Swal from "sweetalert2";
import NewLocationMasterDetails from "./NewLocationMasterDetails";

const NewLoctionMaster = () => {
    const [location, setLocation] = useState("");
    const [subLocation, setSubLocation] = useState([]);
    const [areaLocation, setAreaLocation] = useState([]);
    console.log(areaLocation, "areaLocation");

    const [addNewSublocation, setAddNewSublocation] = useState('');
    const [addNewAreaLocation, setAddNewAreaLocation] = useState('');
    const [selectedSubLocation, setSelectedSubLocation] = useState(null);
    const [selectedAreaLocation, setSelectedAreaLocation] = useState(null);
    const [areaLocationOptions, setAreaLocationOptions] = useState([]);
    const [subLocationOptions, setSubLocationOptions] = useState([]);
    const [formError, setFormError] = useState([]);
    const [locationId, setLocationId] = useState("");
    const [subLocationId, setSubLocationId] = useState("");
    const [isEditing, setIsEditing] = useState(false);
    const [locationData, setLocationData] = useState([]);
    const [subLocationData, setSubLocationData] = useState([]);
    const [iseditsublocation, setIseditsublocation] = useState({});


    useEffect(() => {
        getLocationDataList();
    }, []);

    const getLocationDataList = () => {
        NewGetAreaLocations()
            .then((res) => {
                if (res.status === 200) {
                    const locationResult = res.data.locations;
                    console.log(locationResult, "locationResult");
                    setLocationData(locationResult);
                } else {
                    Swal.fire({
                        icon: "warning",
                        title: "Location Data Not Found !",
                        showConfirmButton: true,
                        confirmButtonColor: "#3085d6",
                        confirmButtonText: "ok",
                    });
                }
            })
            .catch((err) => {
                console.log(err, "error in locationDetails");
            });
    };
    const errHandles = () => {
        let formError = {};
        let isValid = true;
        if (location === "") {
            isValid = false;
            formError["location"] = "Please Enter Location";
        }
        if (subLocation === "") {
            isValid = false;
            formError["subLocation"] = "Please Enter SubLocation";
        }
        if (areaLocation === "") {
            isValid = false;
            formError["areaLocation"] = "Please Enter AreaLocation";
        }

        setFormError(formError);
        return isValid;
    };
    // const handleClear = () => {
    //     setFormError([]);
    //     setSubLocation("");
    //     setSelectedAreaLocation("");
    //     setLocation("");
    //     setIsEditing(false);
    // };

    const handleClear = () => {
        setFormError([]);
        setSubLocation("");
        setSelectedAreaLocation("");
        setAreaLocationOptions([]);
        setLocation("");
        setIsEditing(false);
    };





    const SubmitLocation = (e) => {
        e.preventDefault();

        let isValid = errHandles();

        if (isValid) {
            const locationData = {
                locationName: location,
                subLocationName: subLocation.map((subLoc, index) => {
                    return {
                        subLocationName: subLoc,
                        areaNames: areaLocation,
                    };
                }),
            };

            NewAddLocation(locationData)
                .then((res) => {
                    if (res.status === 200) {
                        Swal.fire({
                            icon: "success",
                            title: "Location Added Successfully",
                            showConfirmButton: true,
                            confirmButtonColor: "#3085d6",
                            confirmButtonText: "ok",
                        });
                        handleClear();
                        getLocationDataList();
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Location Not Added. Please try again!",
                            showConfirmButton: true,
                            confirmButtonColor: "#3085d6",
                            confirmButtonText: "ok",
                        });
                    }
                })
                .catch((err) => {
                    Swal.fire({
                        icon: "error",
                        title: err.response.data.error || 'An error occurred',
                        showConfirmButton: true,
                        confirmButtonColor: "#3085d6",
                        confirmButtonText: "ok",
                    });
                    console.log(err, "err in add Location");
                });
        }
    };


    // const handleEdit = (location) => {
    //     setLocation(location.locationName);
    //     setSubLocation(location.subLocation.map((d) => d.subLocationName));
    //     setLocationId(location._id);
    //     setSubLocationId(location.subLocation.map((d) => d._id));
    //     const formattedOptions = location.subLocation.map((subLoc) => ({
    //         label: subLoc.subLocationName,
    //         value: subLoc._id,
    //     }));
    //     setSubLocationOptions(formattedOptions);

    //     const formattedAreaLocationOptions = location.subLocation.flatMap((subLoc) =>
    //         subLoc.locationArea.map((area) => ({
    //             label: area.areaName,
    //             value: area._id,
    //         }))
    //     );
    //     setAreaLocationOptions(formattedAreaLocationOptions);        
    //     setIsEditing(true);
    // };

    const handleEdit = (location) => {
        setLocation(location.locationName);
        setSubLocation(location.subLocation.map((d) => d.subLocationName));
        setLocationId(location._id);

        const formattedOptions = location.subLocation.map((subLoc) => ({
            label: subLoc.subLocationName,
            value: subLoc._id,
            areaNames: subLoc.locationArea.map(area => ({
                label: area.areaName,
                value: area._id,
            }))
        }));
        setSubLocationOptions(formattedOptions);
        setIsEditing(true);
    };


    const handleSubLocationChange = (selectedOption) => {
        setSelectedSubLocation(selectedOption);
        setIseditsublocation(selectedOption);
    
        if (selectedOption && selectedOption.areaNames) {
            setAreaLocationOptions(selectedOption.areaNames.map((area, index) => ({
                ...area,
                index
            })));
        } else {
            setAreaLocationOptions([]);
        }
    };
    

    // const UpdateLocation = (e) => {
    //     e.preventDefault();

    //     let isValid = errHandles();
    //     if (isValid) {
    //         const updatedSubLocationData = subLocationOptions.map((option, index) => ({
    //             subLocationName: option.label,
    //             id: option.value,
    //             areaNames: areaLocationOptions.filter(area => area.index === index).map(area => area.label),
    //         }));

    //         const sanitizedAddNewSublocation = Array.isArray(addNewSublocation)
    //             ? addNewSublocation
    //             : (typeof addNewSublocation === 'string' ? addNewSublocation.split(',').map(name => name.trim()) : []);

    //         const newSubLocations = sanitizedAddNewSublocation.map((name) => ({
    //             subLocationName: name.trim(),
    //             areaNames: Array.isArray(addNewAreaLocation)
    //                 ? addNewAreaLocation.map((area) => area.trim())
    //                 : [], // Default to an empty array if it's not an array
    //         }));

    //         const updateLocationData = {
    //             locationId,
    //             locationName: location,
    //             subLocation: [...updatedSubLocationData, ...newSubLocations],
    //         };

    //         NewUpdateAreaLocation(updateLocationData)
    //             .then((res) => {
    //                 if (res.status === 200) {
    //                     Swal.fire({
    //                         icon: "success",
    //                         title: "Location Updated Successfully",
    //                         showConfirmButton: true,
    //                         confirmButtonColor: "#3085d6",
    //                         confirmButtonText: "ok",
    //                     });
    //                     handleClear();
    //                     getLocationDataList();
    //                 } else {
    //                     Swal.fire({
    //                         icon: "error",
    //                         title: "Location Not Updated! Please try again.",
    //                         showConfirmButton: true,
    //                         confirmButtonColor: "#3085d6",
    //                         confirmButtonText: "ok",
    //                     });
    //                 }
    //             })
    //             .catch((err) => {
    //                 console.error(err);
    //                 Swal.fire({
    //                     icon: "error",
    //                     title: err.response?.data?.error || 'An error occurred',
    //                     showConfirmButton: true,
    //                     confirmButtonColor: "#3085d6",
    //                     confirmButtonText: "ok",
    //                 });
    //             });
    //     }
    // };

    const UpdateLocation = (e) => {
        e.preventDefault();
    
        let isValid = errHandles();
        if (isValid) {
            // Map the current subLocationOptions to include areas
            const updatedSubLocationData = subLocationOptions.map((option, index) => {
                // Filter the area names for the current sub-location
                const areaNames = areaLocationOptions
                    .filter(area => area.index === index)
                    .map(area => ({
                        areaName: area.label,
                        _id: area.value || undefined // Retain the existing ID or leave it undefined if new
                    }));
    
                return {
                    subLocationName: option.label,
                    id: option.value,
                    locationArea: areaNames
                };
            });
    
            // Merge new sub-locations into the existing ones by checking if they already exist
            const mergedSubLocations = updatedSubLocationData.map((updatedSubLocation) => {
                const existingSubLocation = subLocation.find(subLoc => subLoc._id === updatedSubLocation.id);
                if (existingSubLocation) {
                    // Update the existing sub-location with new data
                    existingSubLocation.subLocationName = updatedSubLocation.subLocationName;
                    existingSubLocation.locationArea = updatedSubLocation.locationArea;
                    return existingSubLocation;
                } else {
                    // Add new sub-location if it doesn't exist
                    return {
                        _id: updatedSubLocation.id,
                        subLocationName: updatedSubLocation.subLocationName,
                        locationArea: updatedSubLocation.locationArea
                    };
                }
            });
    
            // Sanitize and add any new sub-locations
            const sanitizedAddNewSublocation = Array.isArray(addNewSublocation)
                ? addNewSublocation
                : (typeof addNewSublocation === 'string' ? addNewSublocation.split(',').map(name => name.trim()) : []);
    
            const newSubLocations = sanitizedAddNewSublocation.map((name) => ({
                subLocationName: name.trim(),
                locationArea: Array.isArray(addNewAreaLocation)
                    ? addNewAreaLocation.map((area) => ({
                        areaName: area.trim(),
                        _id: undefined // New area locations won't have an ID yet
                    }))
                    : [],
            }));
    
            // Construct the final update data payload
            const updateLocationData = {
                locationId,
                locationName: location,
                subLocation: [...mergedSubLocations, ...newSubLocations], // Merge existing with new
            };
    
            // Call the API to update the location
            NewUpdateAreaLocation(updateLocationData)
                .then((res) => {
                    if (res.status === 200) {
                        Swal.fire({
                            icon: "success",
                            title: "Location Updated Successfully",
                            showConfirmButton: true,
                            confirmButtonColor: "#3085d6",
                            confirmButtonText: "ok",
                        });
                        handleClear();
                        getLocationDataList();
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Location Not Updated! Please try again.",
                            showConfirmButton: true,
                            confirmButtonColor: "#3085d6",
                            confirmButtonText: "ok",
                        });
                    }
                })
                .catch((err) => {
                    console.error(err);
                    Swal.fire({
                        icon: "error",
                        title: err.response?.data?.error || 'An error occurred',
                        showConfirmButton: true,
                        confirmButtonColor: "#3085d6",
                        confirmButtonText: "ok",
                    });
                });
        }
    };
    







    return (
        <div>
            <AdminSidebar />
            <div
                className="container topNav-m"
            >
                <div className=" content-title">
                    <h3>Location</h3>
                    <hr className="content-title-underline" />
                </div>
                <div className="container mt-2">
                    <form className="box-2">
                        <div className="row">
                            <h5 className="m-0">{!isEditing ? 'Add' : 'Update'} Location</h5>
                        </div>
                        <div className="row ">
                            <div className="col-md-3">
                                <div >
                                    <span className="details"> Location</span>
                                    <FormControl variant="outlined" fullWidth>
                                        <TextField
                                            // label="Location"
                                            // variant="outlined"
                                            autoComplete="off"
                                            className="form-control"
                                            type="text"
                                            size="small"
                                            name="location"
                                            value={location}
                                            onChange={(e) => {
                                                setLocation(e.target.value);
                                                setFormError({ ...formError, location: "" });
                                            }}
                                        ></TextField>
                                        <div style={{ color: "red", fontSize: 13 }}>
                                            {formError.location}
                                        </div>
                                    </FormControl>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div >
                                    <span className="details">{isEditing ? 'Select' : null} SubLocation</span>
                                    <FormControl variant="outlined" fullWidth>
                                        {isEditing ? (
                                            <>
                                                {/* <Select
                                                    options={subLocationOptions.map((option, index) => ({ ...option, index }))}
                                                    value={selectedSubLocation}
                                                    size="small"
                                                    classNamePrefix="my-custom-react-select"
                                                    styles={{
                                                        menu: (provided) => ({
                                                            ...provided,
                                                            zIndex: 9999,
                                                            position: 'absolute',
                                                            marginTop: '5px',
                                                        }),
                                                        control: (provided) => ({
                                                            ...provided,
                                                            zIndex: 1,
                                                        }),
                                                    }}
                                                    onChange={(selectedOption) => {
                                                        setSelectedSubLocation(selectedOption);
                                                        setIseditsublocation(selectedOption);
                                                    }}
                                                /> */}

                                                <Select
                                                    options={subLocationOptions.map((option, index) => ({ ...option, index }))}
                                                    value={selectedSubLocation}
                                                    size="small"
                                                    classNamePrefix="my-custom-react-select"
                                                    styles={{
                                                        menu: (provided) => ({
                                                            ...provided,
                                                            zIndex: 9999,
                                                            position: 'absolute',
                                                            marginTop: '5px',
                                                        }),
                                                        control: (provided) => ({
                                                            ...provided,
                                                            zIndex: 1,
                                                        }),
                                                    }}
                                                    onChange={handleSubLocationChange}
                                                />

                                            </>
                                        ) : (
                                            <TextField
                                                autoComplete="off"
                                                className="form-control"
                                                type="text"
                                                size="small"
                                                name="subLocation"
                                                value={subLocation}
                                                onChange={(e) => {
                                                    setSubLocation(e.target.value.split(',').map(s => s.trim()));
                                                    setFormError({ ...formError, subLocation: "" });
                                                }}
                                            ></TextField>
                                        )}
                                        <div style={{ color: "red", fontSize: 13 }}>
                                            {formError.subLocation}
                                        </div>
                                    </FormControl>
                                </div>
                            </div>

                            {isEditing ? (
                                <div className="col-md-3">
                                    {selectedSubLocation ?
                                        // <div >
                                        //     <span className="details">Edit SubLocation</span>
                                        //     <div>
                                        //         <TextField
                                        //             autoComplete="off"
                                        //             className="form-control"
                                        //             type="text"
                                        //             name="sublocation"
                                        //             size="small"
                                        //             value={
                                        //                 Object.keys(iseditsublocation).length !== 0
                                        //                     ? subLocationOptions[iseditsublocation?.index]
                                        //                         .label
                                        //                     : ""
                                        //             }
                                        //             onChange={(e) => {
                                        //                 let newArr = [...subLocationOptions];
                                        //                 newArr[iseditsublocation?.index]["label"] =
                                        //                     e.target.value.split(',').map(s => s.trim());
                                        //                 setSubLocationOptions(newArr);
                                        //             }}
                                        //         ></TextField>
                                        //     </div>

                                        // </div>
                                        <div>
                                            <span className="details">Edit SubLocation</span>
                                            <div>
                                                <TextField
                                                    autoComplete="off"
                                                    className="form-control"
                                                    type="text"
                                                    name="sublocation"
                                                    size="small"
                                                    value={iseditsublocation && Object.keys(iseditsublocation).length !== 0
                                                        ? subLocationOptions[iseditsublocation?.index].label
                                                        : ""}
                                                    onChange={(e) => {
                                                        let newArr = [...subLocationOptions];
                                                        newArr[iseditsublocation?.index]["label"] = e.target.value.split(',').map(s => s.trim());
                                                        setSubLocationOptions(newArr);
                                                    }}
                                                ></TextField>
                                            </div>
                                        </div>
                                        : null}
                                    {selectedSubLocation ? null :
                                        <div >
                                            <span className="details">Add new SubLocation</span>

                                            <div>
                                                <TextField
                                                    autoComplete="off"
                                                    className="form-control"
                                                    type="text"
                                                    size="small"
                                                    name="subLocation"
                                                    value={addNewSublocation}
                                                    onChange={(e) => {
                                                        setAddNewSublocation(e.target.value.split(',').map(s => s.trim()));
                                                        setFormError({ ...formError, subLocation: "" });
                                                    }}
                                                >

                                                </TextField>
                                            </div>

                                        </div>
                                    }
                                </div>

                            ) : null}
                            <div className="col-md-3">
                                <div >
                                    <span className="details"> Area Location</span>

                                    <FormControl variant="outlined" fullWidth>
                                        {isEditing ? (
                                            // <>
                                            //     <Select
                                            //         options={areaLocationOptions.map((option, index) => ({ ...option, index }))}
                                            //         value={selectedAreaLocation}
                                            //         size="small"
                                            //         classNamePrefix="my-custom-react-select"
                                            //         styles={{
                                            //             menu: (provided) => ({
                                            //                 ...provided,
                                            //                 zIndex: 9999,
                                            //                 position: 'absolute',
                                            //                 marginTop: '5px',
                                            //             }),
                                            //             control: (provided) => ({
                                            //                 ...provided,
                                            //                 zIndex: 1,
                                            //             }),
                                            //         }}
                                            //         onChange={(selectedOptions) => {
                                            //             setSelectedAreaLocation(selectedOptions);
                                            //             setIseditsublocation(selectedOptions);
                                            //         }}
                                            //     />
                                            // </>
                                            <Select
                                                options={areaLocationOptions.map((option, index) => ({ ...option, index }))}
                                                value={selectedAreaLocation}
                                                size="small"
                                                classNamePrefix="my-custom-react-select"
                                                styles={{
                                                    menu: (provided) => ({
                                                        ...provided,
                                                        zIndex: 9999,
                                                        position: 'absolute',
                                                        marginTop: '5px',
                                                    }),
                                                    control: (provided) => ({
                                                        ...provided,
                                                        zIndex: 1,
                                                    }),
                                                }}
                                                onChange={(selectedOption) => {
                                                    setSelectedAreaLocation(selectedOption);
                                                }}
                                            />

                                        ) : (
                                            <TextField
                                                autoComplete="off"
                                                className="form-control"
                                                type="text"
                                                size="small"
                                                name="areaLocation"
                                                value={selectedAreaLocation}
                                                onChange={(e) => {
                                                    setAreaLocation(e.target.value.split(',').map(s => s.trim()));
                                                    setFormError({ ...formError, areaLocation: "" });
                                                }}
                                            ></TextField>
                                        )}
                                        <div style={{ color: "red", fontSize: 13 }}>
                                            {formError.areaLocation}
                                        </div>
                                    </FormControl>
                                </div>
                            </div>
                            {isEditing ? (
                                <div className="col-md-3">
                                    {selectedAreaLocation ?
                                        // <div >
                                        //     <span className="details">Edit AreaLocation</span>
                                        //     <div>
                                        //         <TextField
                                        //             autoComplete="off"
                                        //             className="form-control"
                                        //             type="text"
                                        //             name="newAreaLocation"
                                        //             size="small"
                                        //             value={
                                        //                 Object.keys(iseditsublocation).length !== 0
                                        //                     ? areaLocationOptions[iseditsublocation?.index]
                                        //                         .label
                                        //                     : ""
                                        //             }
                                        //             onChange={(e) => {
                                        //                 let newArr = [...areaLocationOptions];
                                        //                 newArr[iseditsublocation?.index]["label"] =
                                        //                     e.target.value.split(',').map(s => s.trim());
                                        //                 setAreaLocationOptions(newArr);
                                        //             }}
                                        //         ></TextField>
                                        //     </div>

                                        // </div>
                                        <div>
                                            <span className="details">Edit AreaLocation</span>
                                            <div>
                                                <TextField
                                                    autoComplete="off"
                                                    className="form-control"
                                                    type="text"
                                                    name="newAreaLocation"
                                                    size="small"
                                                    value={iseditsublocation && Object.keys(iseditsublocation).length !== 0
                                                        ? areaLocationOptions[iseditsublocation?.index].label
                                                        : ""}
                                                    onChange={(e) => {
                                                        let newArr = [...areaLocationOptions];
                                                        newArr[iseditsublocation?.index]["label"] = e.target.value.split(',').map(s => s.trim());
                                                        setAreaLocationOptions(newArr);
                                                    }}
                                                ></TextField>
                                            </div>
                                        </div>
                                        : null}
                                    {selectedAreaLocation ? null :
                                        <div >
                                            <span className="details">Add New AreaLocation</span>
                                            <div>
                                                <TextField
                                                    autoComplete="off"
                                                    className="form-control"
                                                    type="text"
                                                    size="small"
                                                    name="newAreaLocation"
                                                    value={addNewAreaLocation}
                                                    onChange={(e) => {
                                                        setAddNewAreaLocation(e.target.value.split(',').map(s => s.trim()));
                                                        setFormError({ ...formError, newAreaLocation: "" });
                                                    }}
                                                >
                                                </TextField>
                                            </div>
                                        </div>
                                    }
                                </div>
                            ) : null}
                            <div className="col-md-3 ">
                                <div >
                                    <p className="m-0">&nbsp;</p>
                                    {!isEditing ? (
                                        <button
                                            value="Submit"
                                            className="btn btn-primary"
                                            onClick={(e) => SubmitLocation(e)}
                                        >
                                            Submit
                                        </button>
                                    ) : (
                                        <button
                                            value="Submit"
                                            className="btn btn-primary"
                                            onClick={(e) => UpdateLocation(e)}
                                        >
                                            Update
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>

                    </form>
                </div>

            </div>

            <div>
                <NewLocationMasterDetails
                    onEdit={handleEdit}
                    locationData={locationData}
                    getLocationDataList={getLocationDataList}
                />
            </div>
        </div>
    );
};

export default NewLoctionMaster;

