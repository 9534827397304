import axios from "axios";
import { settings } from "../Config/Settings";

const addproject = (data) => {
  return axios({
    method: "POST",
    url: `${settings.API_URL}/project/addProject`,
    data: data,
  });
};

const getallproject = () => {
  return axios({
    method: "GET",
    url: `${settings.API_URL}/project/getProject`,
  });
};

const updateproject = (data, projectId) => {
  return axios({
    method: "PATCH",
    url: `${settings.API_URL}/project/updateProject/${projectId}`,
    data: data,
  });
};

const deleteproject = (projectId) => {
  return axios({
    method: "DELETE",
    url: `${settings.API_URL}/project/deleteProject/${projectId}`,
  });
};

export { addproject, getallproject, updateproject, deleteproject };
