// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-header{
    padding: 10px;
    background-color: white;
    font-size: 30px;
    font-weight: 500;
    border-bottom:2px #ccc double;
}
.card-body{
    padding: 30px auto;
}
.card-footer{
    text-align: center;
}
.card-footer button, .btn{
        background-color: #3535c2;
    color: #fff;
    border-radius: 5px;
    margin: auto 10px;
    /* padding: 10px 15px !important; */
    font-size: 14px;
    width: 150px;
}
.card-footer button:hover, .btn:hover{
    background-color: #b31f1f;
    transition:ease 1s ;
    -webkit-transition:ease 1s ;
    -moz-transition:ease 1s ;
    -ms-transition:ease 1s ;
    -o-transition:ease 1s ;
}
`, "",{"version":3,"sources":["webpack://./src/Assets/css/adminstyle.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,eAAe;IACf,gBAAgB;IAChB,6BAA6B;AACjC;AACA;IACI,kBAAkB;AACtB;AACA;IACI,kBAAkB;AACtB;AACA;QACQ,yBAAyB;IAC7B,WAAW;IACX,kBAAkB;IAClB,iBAAiB;IACjB,mCAAmC;IACnC,eAAe;IACf,YAAY;AAChB;AACA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,2BAA2B;IAC3B,wBAAwB;IACxB,uBAAuB;IACvB,sBAAsB;AAC1B","sourcesContent":[".card-header{\n    padding: 10px;\n    background-color: white;\n    font-size: 30px;\n    font-weight: 500;\n    border-bottom:2px #ccc double;\n}\n.card-body{\n    padding: 30px auto;\n}\n.card-footer{\n    text-align: center;\n}\n.card-footer button, .btn{\n        background-color: #3535c2;\n    color: #fff;\n    border-radius: 5px;\n    margin: auto 10px;\n    /* padding: 10px 15px !important; */\n    font-size: 14px;\n    width: 150px;\n}\n.card-footer button:hover, .btn:hover{\n    background-color: #b31f1f;\n    transition:ease 1s ;\n    -webkit-transition:ease 1s ;\n    -moz-transition:ease 1s ;\n    -ms-transition:ease 1s ;\n    -o-transition:ease 1s ;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
