import axios from "axios";
import { settings } from "../Config/Settings";

const AddAgreement = (data) => {
  return axios({
    method: "POST",
    url: `${settings.API_URL}/RentAgreement/AddnewAgreementProperty`,
    data: data,
  });
};

const UpdateAgreement = (data, id)=>{
  return axios({
    method: "PUT",
    url:`${settings.API_URL}/RentAgreement/updaterentAgreementbyId/${id}`,
    data: data,
  })
}

const listOfAgreement = () => {
  return axios({
    method: "GET",
    url: `${settings.API_URL}/RentAgreement/getRentAgreement`,
  });
};

const deleteAgreement = (id) => {
  return axios({
    method: "DELETE",
    url: `${settings.API_URL}/RentAgreement/deleterentAgreementbyId/${id}`,
  });
};

export { AddAgreement, UpdateAgreement, listOfAgreement, deleteAgreement };
