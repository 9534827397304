import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";

import { wishPropertyList } from "../../Api/WishlistAPI";
import { settings } from "../../Config/Settings";
import Auth from "../../Config/helper/Auth";
import { IconButton, Tooltip } from "@material-ui/core";

import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import SquareFootOutlinedIcon from "@mui/icons-material/SquareFootOutlined";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import ChairOutlinedIcon from "@mui/icons-material/ChairOutlined";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import CorporateFareOutlinedIcon from "@mui/icons-material/CorporateFareOutlined";
import EmojiTransportationOutlinedIcon from "@mui/icons-material/EmojiTransportationOutlined";
import { MdOutlineAdUnits, MdOutlineCabin } from "react-icons/md";
import BedOutlinedIcon from "@mui/icons-material/BedOutlined";
import { MdMiscellaneousServices } from "react-icons/md";
import moment from 'moment';


// social icons and share
import {
  FacebookIcon,
  WhatsappShareButton,
  TwitterShareButton,
  EmailShareButton,
  TwitterIcon,
  WhatsappIcon,
  FacebookShareButton,
  EmailIcon,
} from "react-share";
import { Navigate, useNavigate } from "react-router-dom";
import { LuTableProperties } from "react-icons/lu";
import AdminSidebar from "../../Components/Sidebar/AdminSidebar";
const ShortlistProperties = () => {
  const navigate = useNavigate();
  const [projectData, setProjectData] = useState([]);
  const [showSocial, setShowSocial] = useState(false);
  const [socialUrl, setSocialUrl] = useState("");
  const NumberSeperator = (input) => {
    return new Intl.NumberFormat("en-IN", {
      minimumFractionDigits: 2,
    }).format(input);
  };
  const GetAllProject = () => {
    let userId = Auth?.getUserDetails()?._id;

    wishPropertyList(userId)
      .then((res) => {
        if (res.data.status === true) {
          let details = res.data.properties;
          setProjectData(details);
        }
      })
      .catch((err) => {
        console.log(err, "error in get all rent property.");
      });
  };

  useEffect(() => {
    GetAllProject();
  }, []);

  const formatCash = (n) => {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
    if (n >= 1e7) return +(n / 1e7).toFixed(1) + "Cr"; // Crore

  };

  return (
    <>
      <AdminSidebar />
      <div className="container topNav-m">
        <Container>

          <div className="profile-header">
            <p className="profile-heading">Shortlists</p>
          </div>
          {projectData &&
            projectData.map((listData, ind) => {
              return (

                <div
                  className="p-card-content"
                  onClick={() => {
                    if (listData.nameOfProject) {
                      navigate(`/fulltab/${"Construction"}/${listData?._id}`, {
                        state: {
                          yourData: listData,
                          propertyType: "Construction",
                        },
                      });
                    } else {
                      navigate(`/fulltab/${listData.propertyType}/${listData._id}`, {
                        state: {
                          yourData: listData,
                          propertyType: listData.propertyType,
                        },
                      });
                    }
                  }
                  }
                >
                  <div className="p-card">
                    <div className="p-img">
                      <img
                        src={listData?.exteriorView
                          ? listData.exteriorView[0].name
                          : listData?.photos[0]?.projectImages[0]?.name}
                        alt="Property"
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                      />
                    </div>

                    <div className="p-info">
                      <div className="p-tle">
                        <div>
                          {listData.nameOfProject ?
                            <h4>{listData?.nameOfProject} Project</h4> :
                            <h4>Residential Properties For {listData?.purposeOfProperty}</h4>}
                        </div>

                        <div className="p-info">
                          <div className="p-tle">
                            <div>
                              {listData.nameOfProject ?
                                <h4>{listData?.nameOfProject} Project</h4> :
                                <h4>Residential Properties For {listData?.purposeOfProperty}</h4>}
                            </div>

                            {/* <div className="d-flex">
                    <Tooltip
                      title="Share"
                      slotProps={{
                        popper: {
                          modifiers: [
                            {
                              name: "offset",
                              options: {
                                offset: [0, -14],
                              },
                            },
                          ],
                        },
                      }}
                    >
                      <IconButton
                        onClick={() => {
                          setShowSocial(true);
                          setSocialUrl(
                            `https://equireal.co.in/fulltab/${"Commercial"}/${
                              listData._id
                            }`
                          );
                        }}
                      >
                        <ShareOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  </div> */}
                          </div>

                          {/* details */}
                          <div
                            className="pr-info"
                          // onClick={() =>
                          //   navigate(`/fulltab/${"Commercial"}/${listData._id}`, {
                          //     state: {
                          //       yourData: listData,
                          //       propertyType: "Commercial",
                          //     },
                          //   })
                          // }
                          >
                            {listData.nameOfProject ?
                              <ul>
                                <li>
                                  <PeopleOutlineOutlinedIcon />
                                  <h6>{listData?.propertyOwner}</h6>
                                </li>
                                <li>
                                  <BedOutlinedIcon />
                                  <h6> {listData?.areaType[0]}</h6>
                                </li>
                                <li>
                                  <MdOutlineAdUnits />
                                  <h6>
                                    <h6> {listData?.totalUnits} units</h6>
                                  </h6>
                                </li>
                                <li>
                                  <MdMiscellaneousServices />
                                  <h6>
                                    <h6>{listData?.Amenities.join(', ')}</h6>
                                  </h6>
                                </li>
                              </ul> :
                              <ul>
                                <li>
                                  <LuTableProperties />
                                  <h6>{listData.propertyType}</h6>
                                </li>

                                <li>
                                  <ChairOutlinedIcon />
                                  <h6>{listData.furnishedStatus[0]}</h6>
                                </li>
                                <li>
                                  <BedOutlinedIcon />
                                  <h6> {listData.selectNosofBedrooms} BHK</h6>
                                </li>

                                <li>
                                  <CorporateFareOutlinedIcon />
                                  <h6>
                                    {" "}
                                    {listData.selectFloor} of {listData.totalFloor}
                                    th Floor
                                  </h6>
                                </li>
                                <li>
                                  {" "}
                                  <SquareFootOutlinedIcon />
                                  <h6> {listData.areainSqft} Sq.Ft</h6>
                                </li>

                                <li>
                                  <EmojiTransportationOutlinedIcon />
                                  <h6> {listData.PropertyStatus}</h6>
                                </li>
                                {/* <li>
                      <MdMiscellaneousServices />
                        <h6>{listData.Amenities.join(" ,")}</h6>
                      </li> */}
                              </ul>
                            }

                          </div>
                        </div>

                        <div className="p-pri">
                          {listData.nameOfProject ?
                            <>
                              <div>
                                <h4>₹{formatCash(listData?.maxPrice)}/-</h4>
                                <p>
                                  <p href="">Project Price</p>
                                </p>
                              </div>
                              <div>
                                <h4>{moment(listData?.launchDate).format("DD/MM/YYYY")}</h4>
                                <p>
                                  <p href="">Launch Date</p>
                                </p>
                              </div>
                            </>
                            :
                            <>
                              {listData.purposeOfProperty === "Buy" ?
                                <>
                                  <div>
                                    <h4>₹{formatCash(listData.propertyCost)}/-</h4>
                                    <p>property Cost</p>
                                  </div>
                                  <div>
                                    <h4>₹{formatCash(listData.bookingAmount)}/-</h4>
                                    <p>Booking Amount</p>
                                  </div>
                                </>
                                :
                                <>
                                  <div>
                                    <h4>₹{formatCash(listData.PropertyRent)}/-</h4>
                                    <p>Rent</p>
                                  </div>
                                  <div>
                                    <h4>₹{formatCash(listData.SecurityDeposit)}/-</h4>
                                    <p>Security Deposit</p>
                                  </div>
                                </>
                              }
                            </>
                          }


                          {/* <div
                    className="d-flex justify-content-end"
                    onClick={() =>
                      navigate(`/fulltab/${listData.propertyType}/${listData._id}`, {
                        state: {
                          yourData: listData,
                          propertyType:listData.propertyType,
                        },
                      })
                    }
                  >
                    <button class="cta">
                      <span class="hover-underline-animation"> View More </span>
                      <svg
                        id="arrow-horizontal"
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="10"
                        viewBox="0 0 46 16"
                      >
                        <path
                          id="Path_10"
                          data-name="Path 10"
                          d="M8,0,6.545,1.455l5.506,5.506H-30V9.039H12.052L6.545,14.545,8,16l8-8Z"
                          transform="translate(30)"
                        ></path>
                      </svg>
                    </button>
                    <ArrowForwardIosTwoToneIcon />
                </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </Container>

      </div>
    </>
  );
};

export default ShortlistProperties;
