import React, { useState } from "react";
import dayjs from "dayjs";
//react and mui packages
import {
  FormControl,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  InputLabel,
} from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

//custom components
import {
  AgreementDur,
  rentpayment,
  noticePeriod,
} from "../../../../modules/helpers/constant";
import RadioButtonGroup from "../../../../Components/Common/RadioButtonGroup";
import DatePickerInput from "../../../../Components/Common/DatePickerInput";

export default function ContractDetails({
  onChangeHandler,
  state,
  stateError,
}) {
  const [checked, setChecked] = useState(false);
  const handleChange1 = (event) => {
    setChecked(event.target.checked);
  };

  // const property = [
  //   { label: "1st of every month", value: "1st of every month" },
  //   { label: "2nd of every month", value: "2nd of every month" },
  // ];

  return (
    <>
      <div className="mt-4 p-2">
        <h2>Contract Details</h2>
        <div className="p-2">
          <div className="row">
            <div className="col-sm-6">
              <FormControl fullWidth variant="outlined">
                <DatePickerInput
                  selDate={dayjs(state.aggrStartDate)}
                  placeholderName="Agreement Start Date"
                  size="small"
                  handleChange={(date) => {
                    onChangeHandler("aggrStartDate", date);
                  }}
                />
                <div style={{ color: "red", fontSize: 13 }}>
                  {stateError.aggrStartDate}
                </div>
              </FormControl>
            </div>
            <div className="col-sm-6">
              <FormControl fullWidth variant="outlined">
                <DatePickerInput
                  selDate={dayjs(state.aggrEndDate)}
                  placeholderName="Agreement End Date"
                  size="small"
                  handleChange={(date) => {
                    onChangeHandler("aggrEndDate", date);
                  }}
                />
                <div style={{ color: "red", fontSize: 13 }}>
                  {stateError.aggrEndDate}
                </div>
              </FormControl>
            </div>
          </div>

          <div className="row mt-2 p-1">
            <div className="p-2 col-sm-6">
              <FormControl variant="outlined" fullWidth>
                <InputLabel>Agreement Duration</InputLabel>
                <Select
                  value={state.agreementDuration}
                  required
                  size="small"
                  label="Agreement Duration"
                  onChange={(e) => {
                    onChangeHandler("agreementDuration", e.target.value);
                  }}
                >
                  {AgreementDur.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                <div style={{ color: "red", fontSize: 13 }}>
                  {stateError.agreementDuration}
                </div>
              </FormControl>
            </div>

            <div className="p-2 col-sm-6">
              <FormControl variant="outlined" fullWidth>
                <InputLabel>Notice Period</InputLabel>
                <Select
                  value={state.noticePeriod}
                  required
                  size="small"
                  label="Notice Period"
                  onChange={(e) => {
                    onChangeHandler("noticePeriod", e.target.value);
                  }}
                >
                  {noticePeriod.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                <div style={{ color: "red", fontSize: 13 }}>
                  {stateError.noticePeriod}
                </div>
              </FormControl>
            </div>
          </div>



          <div className="row p-2">
            <div className="p-2 col-sm-6">
              <FormControl variant="outlined" fullWidth>
                <TextField
                  label="Monthly Rent Amount"
                  // placeholder="Monthly Rent Amount"
                  type="Number"
                  size="small"
                  value={state.MRentAmount}
                  onChange={(e) => {
                    onChangeHandler("MRentAmount", e.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CurrencyRupeeIcon />
                      </InputAdornment>
                    ),
                  }}
                  required
                ></TextField>
                <div style={{ color: "red", fontSize: 13 }}>
                  {stateError.MRentAmount}
                </div>
              </FormControl>
            </div>

            <div className="p-2 col-sm-6">
              <FormControl variant="outlined" fullWidth>
                <TextField
                  label="Maintenances charges"
                  type="Number"
                  size="small"
                  value={state.MCharges}
                  onChange={(e) => {
                    onChangeHandler("MCharges", e.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CurrencyRupeeIcon />
                      </InputAdornment>
                    ),
                  }}
                  required
                ></TextField>
                <div style={{ color: "red", fontSize: 13 }}>
                  {stateError.MCharges}
                </div>
              </FormControl>
            </div>
          </div>

          <div className="row p-2">
            <div className="p-2 col-sm-6">
              <FormControl variant="outlined" fullWidth>
                <TextField
                  label="Security Deposit"
                  type="Number"
                  size="small"
                  value={state.securityDeposit}
                  onChange={(e) => {
                    onChangeHandler("securityDeposit", e.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CurrencyRupeeIcon />
                      </InputAdornment>
                    ),
                  }}
                  required
                ></TextField>
                <div style={{ color: "red", fontSize: 13 }}>
                  {stateError.securityDeposit}
                </div>
              </FormControl>
            </div>
            <div className="p-2 col-sm-6">
              <FormControl variant="outlined" fullWidth>
                <TextField
                  label="Percentage of rent"
                  type="Number"
                  size="small"
                  value={state.rentpercentage}
                  onChange={(e) => {
                    onChangeHandler("rentpercentage", e.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CurrencyRupeeIcon />
                      </InputAdornment>
                    ),
                  }}
                  required
                ></TextField>
                <div style={{ color: "red", fontSize: 13 }}>
                  {stateError.rentpercentage}
                </div>
              </FormControl>
            </div>
          </div>

          <div className="row p-2">
            <h5>Rent Payment Date</h5>
            <div className="col-sm-6">
              {/* <RadioButtonGroup
                groupName="rentPaymentDate"
                options={property}
                onChangeHandler={(group, value) => {
                  onChangeHandler(group, value);
                }}
              /> */}

              <div className="col-sm-6">
                <FormControl variant="outlined" fullWidth>
                  <InputLabel>Others</InputLabel>
                  <Select
                    value={state.rentPaymentDate}
                    required
                    label="Others"
                    onChange={(e) => {
                      onChangeHandler("rentPaymentDate", e.target.value);
                    }}
                  >
                    {rentpayment.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <div style={{ color: "red", fontSize: 13 }}>
                    {stateError.rentPaymentDate}
                  </div>
                </FormControl>
              </div>
            </div>
          </div>

          <div>
            <FormControlLabel
              control={
                <Checkbox
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                  checked={checked[0]}
                  onChange={handleChange1}
                />
              }
              label="Other Terms & Clauses"
            />

            {checked && (
              <FormControl variant="outlined" fullWidth>
                <TextField
                  // label="City"
                  placeholder="Terms & Clauses"
                  type="text"
                  size="small"
                  value={state.terms}
                  onChange={(e) => {
                    onChangeHandler("terms", e.target.value);
                  }}
                  required
                ></TextField>
              </FormControl>
            )}
          </div>

        </div>
      </div>
    </>
  );
}
