import React, { useState } from "react";
import Card from "@mui/material/Card";

import {
  Button,
  TextField,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import Topnavigation from "../../Components/Navigation/TopNavigation";
import swal from "sweetalert";
import { forgetPassword, resetPassword } from "../../Api/AuthAPI";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";

const customTheme = (outerTheme) =>
  createTheme({
    palette: {
      mode: outerTheme.palette.mode,
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "--TextField-brandBorderColor": "#E0E3E7",
            "--TextField-brandBorderHoverColor": "#B2BAC2",
            "--TextField-brandBorderFocusedColor": "#6F7E8C",
            "& label.Mui-focused": {
              color: "var(--TextField-brandBorderFocusedColor)",
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: "var(--TextField-brandBorderColor)",
          },
          root: {
            [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: "var(--TextField-brandBorderHoverColor)",
            },
            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: "var(--TextField-brandBorderFocusedColor)",
            },
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            "&::before, &::after": {
              borderBottom: "2px solid var(--TextField-brandBorderColor)",
            },
            "&:hover:not(.Mui-disabled, .Mui-error):before": {
              borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
            },
            "&.Mui-focused:after": {
              borderBottom:
                "2px solid var(--TextField-brandBorderFocusedColor)",
            },
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            "&::before": {
              borderBottom: "2px solid var(--TextField-brandBorderColor)",
            },
            "&:hover:not(.Mui-disabled, .Mui-error):before": {
              borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
            },
            "&.Mui-focused:after": {
              borderBottom:
                "2px solid var(--TextField-brandBorderFocusedColor)",
            },
          },
        },
      },
    },
  });

const ResetPassword = () => {
  const { token } = useParams();
  // console.log(token,"token...")
  const outerTheme = useTheme();
  const [password, setPassword] = useState("");
  const [cpassword, setCPassword] = useState("");
  const [formError, setFormError] = useState({ password: "", cpassword: ""});

  const handlePasswordChange = () => {
    if (!password) {
      setFormError({ password: "Please enter password" });
      return;
    }
    if (password !== cpassword) {
      setFormError({ cpassword: "Please enter a valid password" });
      return;
    }else{
      let data ={
        password:password,
        token:`b6bad147136c218be8c37929fc18f90d1ac84bf9`
      };
      resetPassword(data)
      .then((res)=>{
        if(res.status === 200){
          Swal.fire({
            icon:"success",
            title:"Password Reset Successfully.",
            showConfirmButton: true,
            confirmButtonColor:"#3085d6",
            confirmButtonText:"ok",
          })
        }else{
          Swal.fire({
            icon:"warning",
            title:"Password not reset. Please Retry!",
            showConfirmButton: true,
            confirmButtonColor:"#3085d6",
            confirmButtonText:"ok",
          })
        }
       
      })
      .catch((err)=>{
        console.log(err, 'error in reset password');
        Swal.fire({
          icon:"warning",
          title:"Password not reset. Please Retry!",
          showConfirmButton: true,
          confirmButtonColor:"#3085d6",
          confirmButtonText:"ok",
        })
      })
    }
  };

  return (
    <>
    <Topnavigation/>
    <div className="d-flex justify-content-center align-content-center mt-5 align-items-center">
      <Card sx={{ maxWidth: 600 }}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Reset your password?
          </Typography>
          <div className="p-4">
            <ThemeProvider theme={customTheme(outerTheme)}>
              <label>New Password</label>
              <TextField
                placeholder="enter new password"
                type="password"
                fullWidth
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setFormError((prevErrors) => ({
                    ...prevErrors,
                    password: "",
                  }));
                }}
                error={!!formError.password}
                helperText={formError.password}
              />
              <div className="mt-2">
               <label>Confirm Password</label>
              <TextField
                placeholder="enter confirm password"
                type="password"
                fullWidth
                value={cpassword}
                onChange={(e) => {
                  setCPassword(e.target.value);
                  setFormError((prevErrors) => ({
                    ...prevErrors,
                    cpassword: "",
                  }));
                }}
                error={!!formError.cpassword}
                helperText={formError.cpassword}
              />
              </div>
            </ThemeProvider>
          </div>
        </CardContent>
        <CardActions>
          <Button
            fullWidth
            variant="outlined"
            type="submit"
            onClick={handlePasswordChange}
          >
            Submit
          </Button>
        </CardActions>
      </Card>
    </div>
    </>
  );
};

export default ResetPassword;
