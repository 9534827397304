import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import Auth from "../Config/helper/Auth";

const AfterLoginMainAdmin = () => {
  const userRole = Auth.getUserDetails();
  const token = Auth.getToken();
  return token && userRole.role === "Admin" ? (
    <Outlet />
  ) : (
    <Navigate to="/login" />
  );
};

export default AfterLoginMainAdmin;
