import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// @mui packages
import { Button } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";
//custom components
import Topnavigation from "../../../Components/Navigation/TopNavigation";
import Preview from "./Preview";
import BasicDetails from "./Forms/BasicDetails";
import Tenant from "./Forms/Tenant";
import Property from "./Forms/Property";
import FurnishingDetails from "./Forms/FurnishingDetails";
import ContractDetails from "./Forms/ContractDetails";
import ReceiptModal from "./ReceiptPdf";
//css
import "./Agreement.css";
//Api
import { AddAgreement, UpdateAgreement } from "../../../Api/AgreementAPI";
import { AgreementPayment, VerifyPayment } from "../../../Api/Payment";
import AdminSidebar from "../../../Components/Sidebar/AdminSidebar";
import useEnhancedEffect from "@mui/material/utils/useEnhancedEffect";

const initialState = {
  // form 1
  licensorTitle: "",
  licensorName: "",
  licensorLocation: "",
  licensorSubLocation: "",
  licensorAddress: "",
  aggrRegistrationDate: null,
  // licensorMobileNo: "",
  // licensorEmail: "",
  licensorPinCode: "",

  // form 2
  LicenseeTitle: "",
  LicenseeName: "",
  LicenseeLocation: "",
  LicenseeSubLocation: "",
  LicenseeAddress: "",
  // LicenseeMobileNo: "",
  // LicenseeEmail: "",
  LicenseePinCode: "",

  // form 3
  property: null,
  selectFloor: "",
  room: "",
  otherRoom: "",
  propertyLocation: "",
  propertySubLocation: "",
  propertyAddress: "",
  propertyPinCode: "",
  noOfBalcony: null,
  noOfBathroom: null,
  noOfBedroom: null,
  parkingSpace: "",
  squareFeet: "",
  csNo: "",

  // new fields
  kitchenroom: "",
  drawingdinningroom: "",
  // form 4
  furnishedStatus: "",
  //   furnishingItem: null,

  furnishingItem: {
    AC: 0,
    WashingMachine: 0,
    Sofa: 0,
    Bed: 0,
    Wardrobe: 0,
    Table: 0,
    Geyser: 0,
    Stove: 0,
    Fans: 0,
    Tubelights: 0,
  },
  // form 5
  aggrStartDate: null,
  agreementDuration: "",
  rentPaymentDate: "",
  MRentAmount: null,
  MCharges: null,
  securityDeposit: null,
  noticePeriod: null,
  terms: null,
};

export default function Agreement() {
  const navigate = useNavigate();
  const location = useLocation();
  const rowData = location.state;
  const [state, setState] = useState({ ...initialState });
  console.log(state, "state")

  const [stateError, setStateError] = useState({ ...initialState });
  const [currentForm, setCurrentForm] = useState(1);
  const [makePayment, setMakePayment] = useState(false);
  const [nextModalOpen, setNextModalOpen] = useState(false);
  const [Amount, setAmount] = useState(
    state.property === "Non-Residental" ? 1 : 1
  );
  const [showReceiptModal, setShowReceiptModal] = useState(false);
  const [paymentData, setPaymentData] = useState([]);
  const [agreementId, setAgreementId] = useState('');
  let totalSteps = 4;



  useEffect(() => {
    if (rowData) {

      setState((prevState) => ({
        ...prevState,
        licensorTitle: rowData.OwnersGenderTitle,
        licensorName: rowData.OwnersFullName,
        licensorMobileNo: rowData.OwnersPhoneNumber,
        licensorLocation: rowData.OwnersCity,
        licensorSubLocation: rowData.OwnersState,
        licensorEmail: rowData.OwnesEmailAddress,
        aggrRegistrationDate: rowData.aggrRegistrationDate,
        propertyLocation: rowData.propertyLocation,
        propertySubLocation: rowData.propertySubLocation,
        rentpercentage: rowData.rentpercentage,
        csNo: rowData.csNo,

        licensorAddress: rowData.OwnersPermanantAddressLine1,
        licensorPinCode: rowData.OwnerPincode,
        LicenseeTitle: rowData.TenantsGenderTitle,
        LicenseeName: rowData.TenantFullName,
        LicenseeMobileNo: rowData.TenantPhoneNumber,
        LicenseeLocation: rowData.TenantsCity,
        LicenseeSubLocation: rowData.TenantState,
        LicenseeAddress: rowData.TenantPermanantAddressLine1,
        LicenseeEmail: rowData.TenantEmailAddress,
        LicenseePinCode: rowData.TenantPincode,

        // form 3
        property: rowData.PropertyType,
        selectFloor: rowData.selectFloorNumberofProperty,
        room: rowData.SelectnosofRoomsforProperty,
        // otherRoom: rowData.,
        propertyCity: rowData.cityofRentedProperty,
        propertyState: rowData.StateofRentedProperty,
        propertyAddress: rowData.AddressOfRentedPropertyLine1,
        propertyPinCode: rowData.pincodeOfRentedProperty,

        noOfBalcony: rowData.SelectnosofBalcony,
        noOfBathroom: rowData.SelectnosofBathrooms,
        noOfBedroom: rowData.SelectnosofBedrooms,
        parkingSpace: rowData.isParkingAvailable,
        squareFeet: rowData.PropertCarpetyArea,

        furnishedStatus: rowData.furnishStatus,
        //   furnishingItem: null,

        furnishingItem: {
          AC: rowData.nosOfAirConditioner,
          WashingMachine: rowData.nosOfWashingMachine,
          Sofa: rowData.nosOfSofa,
          Bed: rowData.nosOfBed,
          Wardrobe: rowData.nosOfWardrobe,
          Table: rowData.nosOfTable,
          Geyser: rowData.nosOfgeyser,
          Stove: rowData.nosofSteve,
          Fans: rowData.nosofFans,
          Tubelights: rowData.nosofTubelights,
        },
        // form 5
        aggrStartDate: rowData.AgreementStartDate,
        agreementDuration: rowData.AgreementDuration,
        rentPaymentDate: rowData.MonthlyRentPaymentDate,
        MRentAmount: rowData.MonthlyRentAmount,
        MCharges: rowData.MonthlyMaintainceCharges,
        securityDeposit: rowData.SecurityDeposit,
        noticePeriod: rowData.NoticePeriod,
        kitchenroom: rowData.kitchenroom,
        drawingdinningroom: rowData.drawingdinningroom,

        aggrEndDate: rowData.AgreementEndDate,


        // terms: rowData.,
      }));
      setAgreementId(rowData._id);
    }
  }, [rowData]);

  const onChangeHandler = (name, value) => {

    setState({ ...state, [name]: value });
    setStateError({ ...stateError, [name]: "" });
  };

  const clearData = () => {
    setState({
      // form 1
      licensorTitle: "",
      licensorName: "",
      aggrRegistrationDate: null,
      licensorLocation: "",
      licensorSubLocation: "",
      licensorAddress: "",
      // licensorEmail: "",
      // licensorMobileNo: "",
      licensorPinCode: "",

      // form 2
      LicenseeTitle: "",
      LicenseeName: "",
      LicenseeLocation: "",
      LicenseeSubLocation: "",
      LicenseeAddress: "",
      // LicenseeMobileNo: "",
      // LicenseeEmail: "",
      LicenseePinCode: "",

      // form 3
      property: null,
      selectFloor: "",
      room: "",
      propertyCity: "",
      propertyState: "",
      propertyAddress: "",
      propertyPinCode: "",
      noOfBalcony: null,
      noOfBathroom: null,
      noOfBedroom: null,
      parkingSpace: "",
      squareFeet: "",

      // form 4
      furnishedStatus: "",
      //   furnishingItem: null,

      furnishingItem: {
        AC: 0,
        WashingMachine: 0,
        Sofa: 0,
        Bed: 0,
        Wardrobe: 0,
        Table: 0,
        Geyser: 0,
        Stove: 0,
        Fans: 0,
        Tubelights: 0,
      },
      // form 5
      aggrStartDate: null,
      agreementDuration: "",
      rentPaymentDate: "",
      MRentAmount: null,
      MCharges: null,
      securityDeposit: null,
      noticePeriod: null,
      terms: null,
    });
  };

  const handlePayment = () => {
    if (isFormValid()) {
      if (currentForm < totalSteps) {
        setCurrentForm(currentForm + 1);
      } else {
        handleNext();
        // setMakePayment(true);
      }
    }
  };

  const handleNext = () => {
    if (isFormValid()) {
      if (currentForm < totalSteps) {
        setCurrentForm(currentForm + 1);
      } else {


        let agreementData = {
          licensorTitle: state.licensorTitle,
          licensorName: state.licensorName,
          // OwnersPhoneNumber: state.licensorMobileNo,
          // OwnesEmailAddress: state.licensorEmail,
          licensorAddress: state.licensorAddress,
          licensorPinCode: state.licensorPinCode,
          licensorLocation: state.licensorLocation,
          licensorSubLocation: state.licensorSubLocation,

          LicenseeTitle: state.LicenseeTitle,
          LicenseeName: state.LicenseeName,
          // TenantEmailAddress: state.LicenseeEmail,
          // TenantPhoneNumber: state.LicenseeMobileNo,
          LicenseeAddress: state.LicenseeAddress,
          LicenseePinCode: state.LicenseePinCode,
          LicenseeLocation: state.LicenseeLocation,
          LicenseeSubLocation: state.LicenseeSubLocation,

          // isPropertyResidential: state.property === "Residental" ? true : false,
          // isPropertyCommercial: state.property !== "Residental" ? true : false,
          property: state.property,
          selectFloor: state.selectFloor,
          room: state.room,

          noOfBathroom: Number(state.noOfBathroom),
          noOfBalcony: Number(state.noOfBalcony),
          noOfBedroom: state.noOfBedroom,
          squareFeet: Number(state.squareFeet),

          parkingSpace: state.parkingSpace === "NO" ? false : true,

          propertyAddress: state.propertyAddress,
          propertyPinCode: Number(state.propertyPinCode),
          propertyCity: state.propertyCity,
          propertyState: state.propertyState,

          // nosofFans: Number(state.furnishingItem.Fans),
          // nosofTubelights: Number(state.furnishingItem.Tubelights),
          // nosOfAirConditioner: Number(state.furnishingItem.AC),
          // nosOfWashingMachine: Number(state.furnishingItem.WashingMachine),
          // nosOfSofa: Number(state.furnishingItem.Sofa),
          // nosOfBed: Number(state.furnishingItem.Bed),
          // nosOfWardrobe: Number(state.furnishingItem.Wardrobe),
          // nosOfTable: Number(state.furnishingItem.Table),
          // nosOfgeyser: Number(state.furnishingItem.Geyser),
          // nosofSteve: Number(state.furnishingItem.Stove),

          aggrStartDate: new Date(state.aggrStartDate).toISOString(),
          agreementDuration: state.agreementDuration,

          rentPaymentDate: state.rentPaymentDate,

          MRentAmount: Number(state.MRentAmount),
          MCharges: Number(state.MCharges),

          securityDeposit: Number(state.securityDeposit),
          noticePeriod: state.noticePeriod,
          furnishedStatus: state.furnishedStatus,
          aggrRegistrationDate: new Date(state.aggrRegistrationDate).toISOString(),
          propertyLocation: state.propertyLocation,
          propertySubLocation: state.propertySubLocation,
          rentpercentage: state.rentpercentage,
          MRentAmount: state.MRentAmount,
          csNo: state.csNo,
          kitchenroom: state.kitchenroom,
          drawingdinningroom: state.drawingdinningroom,
          aggrEndDate: new Date(state.aggrEndDate).toISOString(),
        };


        let data = {
          OwnersGenderTitle: state.licensorTitle,
          OwnersFullName: state.licensorName,
          // OwnersPhoneNumber: state.licensorMobileNo,
          // OwnesEmailAddress: state.licensorEmail,
          OwnersPermanantAddressLine1: state.licensorAddress,
          OwnerPincode: state.licensorPinCode,
          OwnersCity: state.licensorLocation,
          OwnersState: state.licensorSubLocation,

          TenantsGenderTitle: state.LicenseeTitle,
          TenantFullName: state.LicenseeName,
          // TenantEmailAddress: state.LicenseeEmail,
          // TenantPhoneNumber: state.LicenseeMobileNo,
          TenantPermanantAddressLine1: state.LicenseeAddress,
          TenantPincode: state.LicenseePinCode,
          TenantsCity: state.LicenseeLocation,
          TenantState: state.LicenseeSubLocation,

          // isPropertyResidential: state.property === "Residental" ? true : false,
          // isPropertyCommercial: state.property !== "Residental" ? true : false,
          PropertyType: state.property,
          selectFloorNumberofProperty: state.selectFloor,
          SelectnosofRoomsforProperty: state.room,

          SelectnosofBathrooms: Number(state.noOfBathroom),
          SelectnosofBalcony: Number(state.noOfBalcony),
          SelectnosofBedrooms: state.noOfBedroom,
          PropertCarpetyArea: Number(state.squareFeet),

          isParkingAvailable: state.parkingSpace === "NO" ? false : true,

          AddressOfRentedPropertyLine1: state.propertyAddress,
          pincodeOfRentedProperty: Number(state.propertyPinCode),
          cityofRentedProperty: state.propertyCity,
          StateofRentedProperty: state.propertyState,

          nosofFans: Number(state.furnishingItem.Fans),
          nosofTubelights: Number(state.furnishingItem.Tubelights),
          nosOfAirConditioner: Number(state.furnishingItem.AC),
          nosOfWashingMachine: Number(state.furnishingItem.WashingMachine),
          nosOfSofa: Number(state.furnishingItem.Sofa),
          nosOfBed: Number(state.furnishingItem.Bed),
          nosOfWardrobe: Number(state.furnishingItem.Wardrobe),
          nosOfTable: Number(state.furnishingItem.Table),
          nosOfgeyser: Number(state.furnishingItem.Geyser),
          nosofSteve: Number(state.furnishingItem.Stove),

          AgreementStartDate: new Date(state.aggrStartDate).toISOString(),
          AgreementDuration: state.agreementDuration,

          MonthlyRentPaymentDate: state.rentPaymentDate,

          MonthlyRentAmount: Number(state.MRentAmount),
          MonthlyMaintainceCharges: Number(state.MCharges),

          SecurityDeposit: Number(state.securityDeposit),
          NoticePeriod: state.noticePeriod,
          furnishStatus: state.furnishedStatus,
          aggrRegistrationDate: new Date(state.aggrRegistrationDate).toISOString(),
          propertyLocation: state.propertyLocation,
          propertySubLocation: state.propertySubLocation,
          rentpercentage: state.rentpercentage,
          MRentAmount: state.MRentAmount,
          csNo: state.csNo,
          kitchenroom: state.kitchenroom,
          drawingdinningroom: state.drawingdinningroom,
          AgreementEndDate: new Date(state.aggrEndDate).toISOString(),
        };

        agreementId ?

          UpdateAgreement(data, agreementId)

            .then((res) => {
              if (res.data.status === 1) {
                setCurrentForm(1);
                setNextModalOpen(true);
                setState(agreementData);
                Swal.fire({
                  icon: "success",
                  title: "Updated Successfully.",
                  showConfirmButton: true,
                  confirmButtonColor: "#3085d6",
                  // cancelButtonColor: '#d33',
                  confirmButtonText: "ok",
                });
              } else {
                Swal.fire({
                  icon: "error",
                  title: "Something went wrong.",
                  showConfirmButton: true,
                  confirmButtonColor: "#3085d6",
                  // cancelButtonColor: '#d33',
                  confirmButtonText: "ok",
                });
              }
            }).catch((err) => {
              console.log(err, 'error in agreement update')
            })
          :
          AddAgreement(data)
            .then((res) => {
              if (res.data.status === 1) {
                setCurrentForm(1);
                setNextModalOpen(true);
                setState(agreementData);
                Swal.fire({
                  icon: "success",
                  title: "Agreement Added Successfully",
                  text: "Your agreement has been Added and all changes have been saved.",
                  showConfirmButton: true,
                  confirmButtonColor: "#3085d6",
                  // cancelButtonColor: '#d33',
                  confirmButtonText: "ok",
                });
              } else {
                Swal.fire({
                  icon: "error",
                  title: "Oops! Something went wrong.",
                  text: "We encountered an unexpected error. Please try again later.",
                  showConfirmButton: true,
                  confirmButtonColor: "#3085d6",
                  // cancelButtonColor: '#d33',
                  confirmButtonText: "ok",
                });
              }
            })
            .catch((e) => {
              console.log("====================================");
              console.log(e, "error in adding agreement");
              console.log("====================================");
            });
      }
    }
  };


  const isFormValid = () => {
    const emailregex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const phoneregex = /^[6-9]\d{9}$/gi;
    const pincoderegex = /^(\d{6})$/;
    const onlyText = /^[a-zA-Z\s]+$/;

    let formError = {};
    let isValid = true;
    // Check if all fields have values
    if (currentForm === 1) {

      if (state.licensorTitle === "") {
        isValid = false;
        formError["licensorTitle"] = "Please Select title";
      }
      if (state.licensorName === "") {
        isValid = false;
        formError["licensorName"] = "Please Enter Licensor's Name";
      } else if (onlyText.test(state.licensorName) === false) {
        isValid = false;
        formError["licensorName"] = "Licensor's Name only contains Alphabets";
      }

      if (state.aggrRegistrationDate === null) {
        isValid = false;
        formError["aggrRegistrationDate"] = "Please Select Agreement Registration Date";
      }

      // if (state.ownerMobileNo === "") {
      //   isValid = false;
      //   formError["ownerMobileNo"] = "Please Enter Owners's Mobile";
      // } else if (phoneregex.test(Number(state.ownerMobileNo)) === false) {
      //   formError["ownerMobileNo"] =
      //     "Please enter 10 digit valid mobile number.";
      //   isValid = false;
      // }

      // if (state.ownerEmail === "") {
      //   isValid = false;
      //   formError["ownerEmail"] = "Please Enter  Email";
      // } else if (emailregex.test(state.ownerEmail) === false) {
      //   formError["ownerEmail"] = "Please enter valid email id.";
      //   isValid = false;
      // }


      if (state.licensorAddress === "") {
        isValid = false;
        formError["licensorAddress"] = "Please Enter Address";
      }

      if (state.licensorLocation === "") {
        isValid = false;
        formError["licensorLocation"] = "Please Enter Location";
      } else if (onlyText.test(state.licensorLocation) === false) {
        isValid = false;
        formError["licensorLocation"] = "Please Enter Valid Location Name";
      }

      if (state.licensorSubLocation === "") {
        isValid = false;
        formError["licensorSubLocation"] = "Please Enter SubLocation";
      } else if (onlyText.test(state.licensorSubLocation) === false) {
        isValid = false;
        formError["licensorSubLocation"] = "Please Enter Valid SubLocation Name";
      }

      if (state.licensorPinCode === "") {
        isValid = false;
        formError["licensorPinCode"] = "Please Enter PinCode";
      } else if (pincoderegex.test(Number(state.licensorPinCode)) === false) {
        formError["licensorPinCode"] = "Invalid PIN code.";
        isValid = false;
      }

      setStateError(formError);
      return isValid;
    } else if (currentForm === 2) {
      if (state.LicenseeTitle === "") {
        isValid = false;
        formError["LicenseeTitle"] = "Please Select title";
      }
      if (state.LicenseeName === "") {
        isValid = false;
        formError["LicenseeName"] = "Please Enter Licensee's Name";
      } else if (onlyText.test(state.LicenseeName) === false) {
        isValid = false;
        formError["LicenseeName"] = "Licensee's Name only contains Alphabets";
      }
      // if (state.tenantMobileNo === "") {
      //   isValid = false;
      //   formError["tenantMobileNo"] = "Please Enter  Mobile";
      // } else if (phoneregex.test(Number(state.tenantMobileNo)) === false) {
      //   formError["tenantMobileNo"] =
      //     "Please enter 10 digit valid mobile number.";
      //   isValid = false;
      // }

      // if (state.tenantEmail === "") {
      //   isValid = false;
      //   formError["tenantEmail"] = "Please Enter  Email";
      // } else if (emailregex.test(state.tenantEmail) === false) {
      //   formError["tenantEmail"] = "Please enter valid email id.";
      //   isValid = false;
      // }

      if (state.LicenseeAddress === "") {
        isValid = false;
        formError["LicenseeAddress"] = "Please Enter  Address";
      }

      if (state.LicenseeLocation === "") {
        isValid = false;
        formError["LicenseeLocation"] = "Please Enter Location";
      } else if (onlyText.test(state.LicenseeLocation) === false) {
        isValid = false;
        formError["LicenseeLocation"] = "Please Enter Valid Location Name";
      }

      if (state.LicenseeSubLocation === "") {
        isValid = false;
        formError["LicenseeSubLocation"] = "Please Enter SubLocation";
      } else if (onlyText.test(state.LicenseeSubLocation) === false) {
        isValid = false;
        formError["LicenseeSubLocation"] = "Please Enter Valid SubLocation Name";
      }

      if (state.LicenseePinCode === "") {
        isValid = false;
        formError["LicenseePinCode"] = "Please Enter PinCode";
      } else if (pincoderegex.test(Number(state.LicenseePinCode)) === false) {
        formError["LicenseePinCode"] = "Invalid PIN code.";
        isValid = false;
      }
      setStateError(formError);
      return isValid;
    } else if (currentForm === 3) {
      if (state.property === null) {
        isValid = false;
        formError["property"] = "Please Select Property type";
      }

      // if (state.selectFloor === "") {
      //   isValid = false;
      //   formError["selectFloor"] = "Please Select Floor";
      // }

      if (state.room === "") {
        isValid = false;
        formError["room"] = "Please Select Room Area";
      }

      if (state.propertyCity === "") {
        isValid = false;
        formError["propertyCity"] = "Please Enter City";
      }

      if (state.propertyState === "") {
        isValid = false;
        formError["propertyState"] = "Please Enter State";
      }
      if (state.propertyAddress === "") {
        isValid = false;
        formError["propertyAddress"] = "Please Enter Address";
      }
      if(state.propertyLocation === "" ){
        isValid = false;
        formError["propertyLocation"] = "Please Enter Property Location"
      }
      if(state.propertySubLocation === "" ){
        isValid = false;
        formError["propertySubLocation"] = "Please Enter Property SubLocation"
      }

      if (state.propertyPinCode === "") {
        isValid = false;
        formError["propertyPinCode"] = "Please Enter PinCode";
      } else if (pincoderegex.test(Number(state.propertyPinCode)) === false) {
        formError["propertyPinCode"] = "Invalid PIN code.";
        isValid = false;
      }

      // if (state.noOfBedroom === null) {
      //   isValid = false;
      //   formError["noOfBedroom"] = "Please Enter No. of Bedrooms";
      // }
      if (state.property !== "Commercial" && state.noOfBedroom === null) {
        isValid = false;
        formError["noOfBedroom"] = "Please Enter No. of Bedrooms";
      }
     

      if (state.noOfBathroom === null) {
        isValid = false;
        formError["noOfBathroom"] = "Please Enter No. of Bathrooms";
      }
      // if (state.noOfBalcony === null) {
      //   isValid = false;
      //   formError["noOfBalcony"] = "Please Enter No. of Balconies";
      // }
      // if (state.parkingSpace === "") {
      //   isValid = false;
      //   formError["parkingSpace"] = "Please Select Parking Status";
      // }
      if (state.squareFeet === "") {
        isValid = false;
        formError["squareFeet"] = "Please Enter Property Area";
      }
      setStateError(formError);
      return isValid;
    }
    //  else if (currentForm === 4) {
    //   if (state.furnishedStatus === "") {
    //     isValid = false;
    //     formError["furnishedStatus"] = "Please Select Furnishing Status";
    //   }

    //   setStateError(formError);
    //   return isValid;
    // }
    else if (currentForm === 4) {
      if (state.aggrStartDate === null) {
        isValid = false;
        formError["aggrStartDate"] = "Please Select Agreement Start Date";
      }
      if (state.aggrStartDate === null) {
        isValid = false;
        formError["aggrEndDate"] = "Please Select End Start Date";
      }
      if (state.agreementDuration === "") {
        isValid = false;
        formError["agreementDuration"] = "Please Select Agreement Duration";
      }
      if (state.rentPaymentDate === "") {
        isValid = false;
        formError["rentPaymentDate"] = "Please Select Rent Payment Date";
      }
      if (state.MRentAmount === null) {
        isValid = false;
        formError["MRentAmount"] = "Please Enter Monthly Rent Amount";
      }
      if (state.MCharges === null) {
        isValid = false;
        formError["MCharges"] = "Please Enter Maintenances charges";
      }
      if (state.securityDeposit === null) {
        isValid = false;
        formError["securityDeposit"] = "Please Enter Security Deposit";
      }
      if (state.securityDeposit === null) {
        isValid = false;
        formError["rentpercentage"] = "Please Enter Percentage of rent";
      }
      if (state.noticePeriod === null) {
        isValid = false;
        formError["noticePeriod"] = "Please Enter Notice Period";
      }
      setStateError(formError);
      return isValid;
    }
  };

  const handlePrevious = () => {
    setCurrentForm(currentForm - 1);
  };
  const payAmount = () => {
    const data = {
      amount: Amount,
    };
    AgreementPayment(data)
      .then((res) => {
        handleRazorpay(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleRazorpay = (data) => {
    const option = {
      key: "rzp_test_ftfKAFOFwJu6pH",
      amount: data.amount,
      currency: data.currency,
      name: "Real Estate",
      description: "Test Transaction",
      image: "https://example.com/your_logo",
      order_id: data.id,

      handler: function (response) {
        let paymentData = {
          orderId: response.razorpay_order_id,
          paymentID: response.razorpay_payment_id,
          amount: data.amount,
          razorpaySignature: response.razorpay_signature,
        };
        VerifyPayment(paymentData)
          .then((res) => {
            if (res.data.code === 200) {
              handleClose();
              Swal.fire({
                icon: 'success',
                title: 'Payment Successful!',
                text: 'Your payment has been processed successfully. Thank you for your purchase!',
                showConfirmButton: true,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'OK',
                background: '#f4f4f4',
                iconColor: '#4caf50',
              });
              setNextModalOpen(true);
              handleNext();
              setPaymentData(paymentData);
              setShowReceiptModal(true);
            } else if (res.data.code === 400) {
              Swal.fire({
                icon: "error",
                title: "Payment Already Processed",
                text: "It seems this payment has already been completed. Please check your records or contact support if you need further assistance.",
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Okay",
              });
            }
          })
          .catch((err) => {
            console.log(err, "error in buy");
          });
      },
    };
    const paymentObject = new window.Razorpay(option);
    paymentObject.open();
  };
  const handleClose = () => setMakePayment(false);
  const handleClose2 = () => setNextModalOpen(false);
  const handleCloseReceipt = () => setShowReceiptModal(false);

  // const handleChangeAmount = (e) => {
  //   const { name, value } = e.target;
  //   setAmount(value);
  // };
  const AgrementPdf = () => {

    navigate("/agreementPdf", { state: state, clearData });
  };
  const handleButtonClick = (formnumber) => {
    setCurrentForm(formnumber);
  };

  return (
    <>
      {/* <Topnavigation /> */}
      <AdminSidebar />
      <div className="topNav-m">
        <div className="main-container row ">
          <div className="preview col-sm-2">
            <div className="agreement-menu">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => handleButtonClick(1)}
              >
                Licensor Details
              </button>
              <button
                type="button"
                className="btn btn-light "
                onClick={() => handleButtonClick(2)}
              >
                Licensee Details
              </button>
              <button
                type="button"
                className="btn btn-light "
                onClick={() => handleButtonClick(3)}
              >
                Property Details
              </button>
              {/* <button
              type="button"
              className="btn btn-light "
              onClick={() => handleButtonClick(4)}
            >
              Furnishing Details
            </button> */}
              <button
                type="button"
                className="btn btn-light "
                onClick={() => handleButtonClick(4)}
              >
                Contract Details
              </button>
            </div>
          </div>
          <div className="agreement-form col-sm-5 px-5 ">
            {currentForm === 1 && (
              <BasicDetails
                onChangeHandler={onChangeHandler}
                state={state}
                stateError={stateError}
              />
            )}
            {currentForm === 2 && (
              <Tenant
                onChangeHandler={onChangeHandler}
                state={state}
                stateError={stateError}
              />
            )}
            {currentForm === 3 && (
              <Property
                onChangeHandler={onChangeHandler}
                state={state}
                stateError={stateError}
              />
            )}
            {/* {currentForm === 4 && (
            <FurnishingDetails
              onChangeHandler={onChangeHandler}
              state={state}
              stateError={stateError}
            />
          )} */}
            {currentForm === 4 && (
              <ContractDetails
                onChangeHandler={onChangeHandler}
                state={state}
                stateError={stateError}
              />
            )}

            <div className="button-cont">
              <Button onClick={handlePrevious} disabled={currentForm === 1} className="btn-prev">
                Previous
              </Button>
              <Button onClick={handlePayment} className="btn-next">
                {agreementId ? (currentForm < totalSteps ? "Next" : "Update") : (currentForm < totalSteps ? "Next" : "Submit")}
              </Button>
            </div>
          </div>
          <div className="preview col-sm-5">
            <h5 className="text-center mt-4">LEAVE & LICENSE AGREEMENT</h5>
            <Preview state={state} />
          </div>
        </div>
      </div>

      <div>
        <Modal
          show={makePayment}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Payment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div>
                <label>Amount</label>
              </div>
              <div className="col-md-6">
                <input
                  type="number"
                  value={Amount}
                  name="amount"
                // onChange={handleChangeAmount}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => payAmount()}>
              Payment
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <div>
        <Modal
          show={nextModalOpen}
          onHide={handleClose2}
          backdrop="static"
          keyboard={false}
          centered

        >
          <Modal.Header closeButton>
            <Modal.Title> </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <h2>DownLoad Agreement</h2>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {/* <Button variant="secondary" onClick={() => handleNext()}>
              Submit
            </Button> */}
            <Button variant="secondary" onClick={() => AgrementPdf()}>
              DownLoad Agreement
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <div>
        {showReceiptModal && (
          <ReceiptModal
            showModal={showReceiptModal}
            paymentData={paymentData}
            onClose={handleCloseReceipt}


          />
        )}
      </div>
    </>
  );
}
