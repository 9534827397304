/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//react and material ui packages
import { Container } from "react-bootstrap";
//icons
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import DomainIcon from "@mui/icons-material/Domain";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import SquareFootOutlinedIcon from "@mui/icons-material/SquareFootOutlined";
import ChairOutlinedIcon from "@mui/icons-material/ChairOutlined";
import CorporateFareOutlinedIcon from "@mui/icons-material/CorporateFareOutlined";
import EmojiTransportationOutlinedIcon from "@mui/icons-material/EmojiTransportationOutlined";
import { MdOutlineCabin } from "react-icons/md";
import { LuTableProperties } from "react-icons/lu";
//custom componets
import FilterProperty from "./FilterProperty";
//api
import { updateStatusofCommercialProperty, updateStatusOfProperty } from "../../Api/RentpropertyAPI";

export default function RentProperty(props) {
  const Navigate = useNavigate();
  const { propertyData = [], propertyType, isVerifiedDatas,GetAllRentProperty, getCommercialProperty } = props;
  const [listData, setListData] = useState([]);
  const [selectedValues, setSelectedValues] = useState({});
  const [loading, setLoading] = useState(false);

  const verificationStatus = [
    { value: "Verified", label: "Verified", spanclass: "verifiedspanlook" },
    { value: "Rejected", label: "Rejected", spanclass: 'rejectedspanlook' },
  ];
  useEffect(() => {
    if (Object.values(selectedValues).includes("pending")) {
      const filteredData = propertyData.filter(
        (property) => property.isVerified === "pending"
      );
      setListData(filteredData);
    } else {
      setListData(propertyData);
    }
  }, [propertyData, propertyType, selectedValues]);

  useEffect(() => {
    const initialSelectedValues = {};
    propertyData.forEach((item, index) => {
      initialSelectedValues[index] = item.isVerified;
    });

    setSelectedValues(initialSelectedValues);
  }, [propertyData]);

  const updateStatus = async (propertyId, status, i) => {
    let updateData = {
      isVerified: status,
    };
    setLoading(true);
    if(propertyType === "Residential"){

    
    await updateStatusOfProperty(propertyId, updateData)
      .then((res) => {
        if (res.data.status === 1) {
          let newData = [...listData];
          newData[i]["isVerified"] = status;
          setListData(newData);
          setLoading(false);
          GetAllRentProperty()
        }
      })
      .catch((e) => {
        setLoading(false);
        console.log("====================================");
        console.log(e, "error in updating verification status");
        console.log("====================================");
      });
    }else{
      await updateStatusofCommercialProperty(propertyId, updateData)
      .then((res)=>{
        if(res.data.status === 1){
          let newData = [...listData];
          newData[i]["isVerified"] = status;
          setListData(newData);
          setLoading(false);
          getCommercialProperty()
        }
      }) 
      .catch((e) => {
        setLoading(false);
        console.log("====================================");
        console.log(e, "error in updating verification status");
        console.log("====================================");
      });
    }


  };

  const formatCash = (n) => {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
  };

  return (
    <>
      <Container>
        <FilterProperty
          propertyData={propertyData}
          propertyType={propertyType}
          isVerified={isVerifiedDatas}
        />
        <div className="a-card-content">
          {listData.map((propertiesData, i) => {
            return (
              <>
                <div>
                  <div className="a-card">
                    <div className="a-img">
                      <img
                        src={`${propertiesData?.exteriorView[0]?.name}`}
                        alt="Property"
                      />
                    </div>

                    <div className="p-info">
                      <div className="p-tle">
                        <div>
                          <h4>{propertyType} Properties for {propertiesData.purposeOfProperty}</h4>
                        </div>
                      </div>

                      <div className="a-info">
                        <ul>
                          <li>
                            <LuTableProperties />
                            <h6>{propertiesData.propertyType}</h6>
                          </li>
                          <li>
                            <EmojiTransportationOutlinedIcon />
                            <h6> {propertiesData.Location}</h6>
                          </li>
                          <li>
                            <CorporateFareOutlinedIcon />
                            <h6>
                              {" "}
                              {propertiesData.selectFloor}
                            </h6>
                          </li>
                          {propertyType === "Residential" ?
                            (
                              <>
                                <li>
                                  <SquareFootOutlinedIcon />
                                  <h6> {propertiesData.areainSqft} Sq.Ft</h6>
                                </li>
                                <li>
                                  <ChairOutlinedIcon />
                                  <h6> {propertiesData?.propertyDirection}</h6>
                                </li>

                                <li>
                                  <MdOutlineCabin />
                                  <h6> {propertiesData.postedBy}</h6>
                                </li>
                              </>
                            )
                            :
                            <>
                              <li>
                                <SquareFootOutlinedIcon />
                                <h6> {propertiesData.carpetArea} Sq.Ft</h6>
                              </li>
                              <li>
                                <MdOutlineCabin />
                                <h6> {propertiesData.ComplexName}</h6>
                              </li>
                              <li>
                                <ChairOutlinedIcon />
                                <h6> {propertiesData?.PostedBy}</h6>
                              </li>
                            </>}
                        </ul>
                      </div>
                    </div>

                    {propertiesData.purposeOfProperty == "Rent" ?
                      <div className="p-pri2">
                        <div>
                          <h4>₹{formatCash(propertiesData.PropertyRent)}/-</h4>
                          <p>Rent</p>
                        </div>

                        <div>
                          <h4>₹{formatCash(propertiesData.SecurityDeposit)}/-</h4>
                          <p>Security Deposit</p>
                        </div>
                      </div> :
                      <div className="p-pri2">
                        <div>
                          <h4>₹{formatCash(propertiesData.propertyCost)}/-</h4>
                          <p> Property Cost</p>
                        </div>
                        <div>
                          <h4>₹{formatCash(propertiesData.bookingAmount)}/-</h4>
                          <p>Booking Amount</p>
                        </div>
                      </div>
                    }
                    {console.log(propertiesData.isVerified)}

                    <div className="text-center ">
                      {verificationStatus.map((option, index) => (
                        <div key={index} className="cat1 search-sel1">
                          <label>
                            <input
                              type="checkbox"
                              checked={
                                option.value === propertiesData.isVerified
                              }
                              onChange={(e) =>
                                updateStatus(
                                  propertiesData._id,
                                  option.value,
                                  i
                                )
                              }
                            />
                            <span className={option.spanclass}>{option.value}</span>
                          </label>
                        </div>
                      ))}
                    </div>

                    <div className="text-center">
                      <a
                        className="view-more-link"
                        onClick={() =>
                          Navigate(
                            `/fulltab/${propertyType}/${propertiesData._id}`,
                            {
                              state: {
                                yourData: propertiesData,
                                propertyType: propertyType,
                              },
                            }
                          )
                        }
                      >
                        View More
                      </a>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </Container>
    </>
  );
}
