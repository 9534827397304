import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import './recommendedprojectCard.css'
import { recommendedPropertiesData } from '../../Api/RentpropertyAPI';
const RecommendedProject = (props) => {
    let { propertyType, Location } = props;

    const [recommendedData, setRecommendedData] = useState([]);
    const [recomLocation, setRecomLocation] = useState("");

    useEffect(() => {
        setRecomLocation(Location)
        const recommdedData = {
            modalName: propertyType,
            locationName: Location[0]
        }
        recommendedPropertiesData(recommdedData)
            .then((res) => {
                if (res.data.status === true) {
                    setRecommendedData(res.data.data)
                } else {
                    setRecommendedData([])
                }
            }).catch((err) => {
                console.log(err, "error in recommended Properties");
            })
    }, [props])

    const formatCash = (n) => {
        if (n < 1e3) return n;
        if (n >= 1e3 && n < 1e5) return +(n / 1e3).toFixed(1) + "K";
        if (n >= 1e5 && n < 1e7) return +(n / 1e5).toFixed(1) + "L";
        if (n >= 1e7 && n < 1e9) return +(n / 1e7).toFixed(1) + "cr";
        if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
        if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
    };

    return (
        <>

            <Row>
                <div className="content-title">
                    <div className=" content-name">
                        <h3>Recommended Properties</h3>
                        <p class="sub-title css-s8hh0l" data-q="sub-title">Most sought-after {propertyType === "Construction" ? "projects" : "Properties"} in {recomLocation}</p>

                    </div>
                    <hr className="content-title-underline" />
                </div>

                {recommendedData.length > 0 ?
                 recommendedData.map((recomData) => (
                    <>
                      {recomData.propertyName === "Construction" ?
                        <Col md={6} sm={12} className='p-3'>

                            <div class="w-100">
                                <div class="image css-1vqtwli">
                                    <img class="property-image css-1gjydsh" alt="Oblong Vega 4" decoding="async" fetchpriority="low" src="https://housing-images.n7net.in/4f2250e8/d7505f03b98d3d1cf7f081f55401e790/v0/medium/oblong_vega_4-mogappair-chennai-oblong_realties_pvt_ltd.jpeg" />
                                </div>
                                <div class="recomend css-poh5ib p-3">
                                    <div class="css-13o7eu2">
                                        <a class="css-0" target="_blank" href="/in/buy/projects/page/298142-oblong-vega-4-by-oblong-realties-pvt-ltd-in-mogappair?fltcnt=46204a6f-9501-4f46-ac97-869314e51b22">
                                            <div class="name css-1kop8ej">{recomData?.nameOfProject}</div>
                                        </a>
                                    </div>
                                    <div class="developer css-1ocb6y4">by {recomData?.propertyOwner}</div>
                                    <div class="title css-4rw15f">{recomData?.areaType[0]}</div>
                                    <div class="address css-19lhbwg" data-q="address">{recomData?.subLocation}, {recomData?.Location}</div>
                                    <div class="price css-zskpky ">
                                        <span class="property-price"></span>₹{formatCash(recomData?.minPrice)} - ₹{formatCash(recomData?.maxPrice)}</div>
                                </div>
                            </div>
                        </Col>
                      :
                        <Col md={6} sm={12} className='p-3'>

                            <div class="w-100">
                                <div class="image css-1vqtwli">
                                    <img class="property-image css-1gjydsh" alt="Oblong Vega 4" decoding="async" fetchpriority="low" src={recomData?.exteriorView[0].name} />
                                </div>
                                <div class="recomend css-poh5ib p-3">
                                    <div class="css-13o7eu2">
                                        <a class="css-0" target="_blank" href="/in/buy/projects/page/298142-oblong-vega-4-by-oblong-realties-pvt-ltd-in-mogappair?fltcnt=46204a6f-9501-4f46-ac97-869314e51b22">
                                            <div class="name css-1kop8ej">{recomData?.selectNosofBedrooms} BHK , {recomData?.propertyType}</div>
                                        </a>
                                    </div>
                                    <div class="developer css-1ocb6y4">at {recomData?.subLocation}, {recomData?.Location}</div>
                                    <div class="address css-19lhbwg" data-q="address">{recomData.propertyName === "Residential" ? recomData?.areainSqft : recomData?.carpetArea} SqFt.</div>
                                    {/* <div class="title css-4rw15f">{recomData?.Amenities[0]}</div> */}
                                    <div class="price css-zskpky ">
                                        <span class="property-price"></span>₹{recomData?.purposeOfProperty === "Rent" ? recomData?.PropertyRent : recomData?.propertyCost}</div>
                                </div>
                            </div>
                        </Col>
                     }
                    </>
                ))
            :
            <p>Data not found</p>
            }
            </Row>

        </>
    )
}

export default RecommendedProject;