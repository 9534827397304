import React, { useState } from "react";
import RadioButtonBox from "./RadioButtonBox";

export default function RadioButtonGroup({
  groupName,
  options,
  onChangeHandler,
}) {
  const [selectedValue, setSelectedValue] = useState(null);

  const handleOptionChange = (value) => {
    setSelectedValue(value);
    onChangeHandler(groupName, value);
  };
  return (
    <div className="radio-button-group">
      {options.map((option) => (
        <RadioButtonBox
          key={option.value}
          label={option.label}
          value={option.value}
          checked={option.value === selectedValue}
          onChange={handleOptionChange}
        />
      ))}
    </div>
  );
}
