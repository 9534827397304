import React from "react";

export const VideoTour = () => {
  return (
    <div>
      <div id="video-tour">
        <div className="content-box">
          <div
            className="video-bg  wow animated fadeIn"
            data-wow-duration="1s"
            data-wow-delay=".5s"
          >
            <div className="container">
              <div className="row">
                <div
                  className="col-md-5 video-content  wow animated fadeInLeft"
                  data-wow-duration="1s"
                  data-wow-delay=".5s"
                >
                  <h2> Video Tour</h2>
                  <p>
                    Lorem Ipsum is simply dummy text of printing and type
                    setting industry. Lorem Ipsum been industry standard dummy
                    text ever since, when an unknown printer took a galley of
                    type and scrambled it to make a type specimen book. It has
                    survived not only five centuries, but also the leap into
                    electronic type setting.
                  </p>
                </div>
                <div
                  className="col-md-7  wow animated fadeInRight"
                  data-wow-duration="1s"
                  data-wow-delay=".5s"
                >
                  <iframe
                    className="iframe"
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/wspkmUSW50c"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
