import React, { useEffect, useState } from "react";
import AdminSidebar from "../../Components/Sidebar/AdminSidebar";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Select from "react-select";
import { FormControl, TextField } from "@mui/material";
import LocationMasterDetails from "../Admin/LocationMasterDetails";
import {
  AddLocation,
  updateLocation,
  getLocationData,
} from "../../Api/AuthAPI";
import Swal from "sweetalert2";
const CustomOption = ({ onButtonClick, data, index, ...rest }) => {
  return (
    <div
      key={index}
      onClick={(event) => {
        event.preventDefault();
        onButtonClick(data, index);
      }}
    >
      {rest.children}
    </div>
  );
};
const LocationMaster = () => {
  const [location, setLocation] = useState("");
  const [subLocation, setSubLocation] = useState([]);
  const [addNewSublocation, setAddNewSublocation] = useState('');
  const [selectedSubLocation, setSelectedSubLocation] = useState(null);
  const [subLocationOptions, setSubLocationOptions] = useState([]);
  const [formError, setFormError] = useState([]);
  const [locationId, setLocationId] = useState("");
  const [subLocationId, setSubLocationId] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [locationData, setLocationData] = useState([]);
  const [subLocationData, setSubLocationData] = useState([]);
  const [iseditsublocation, setIseditsublocation] = useState({});

  useEffect(() => {
    getLocationDataList();
  }, []);

  const getLocationDataList = () => {
    getLocationData()
      .then((res) => {
        if (res.status === 200) {
          const locationResult = res.data.locations;
          setLocationData(locationResult);
        } else {
          Swal.fire({
            icon: "warning",
            title: "Location Data Not Found !",
            showConfirmButton: true,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "ok",
          });
        }
      })
      .catch((err) => {
        console.log(err, "error in locationDetails");
      });
  };

  const errHandles = () => {
    let formError = {};
    let isValid = true;
    if (location === "") {
      isValid = false;
      formError["location"] = "Please Enter Location";
    }
    if (subLocation === "") {
      isValid = false;
      formError["subLocation"] = "Please Enter SubLocation";
    }

    setFormError(formError);
    return isValid;
  };

  const handleClear = () => {
    setFormError([]);
    setSubLocation("");
    setLocation("");
    setIsEditing(false);
  };

  const SubmitLocation = (e) => {
    e.preventDefault();
    let isValid = errHandles();
    if (isValid) {
      const locationData = {
        locationName: location,
        subLocationName: subLocation,
      };
      AddLocation(locationData)
        .then((res) => {

          if (res.status === 200) {
            Swal.fire({
              icon: "success",
              title: "Location Added Successfully",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "ok",
            });
            handleClear();
            getLocationDataList();
          } else {
            Swal.fire({
              icon: "error",
              title: "Location Not Added. Please try again!",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "ok",
            });
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: err.response.data.error,
            showConfirmButton: true,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "ok",
          });
          console.log(err, "err in add Location");
        });
    }
  };

  const handleEdit = (location) => {
    setLocation(location.locationName);
    setSubLocation(location.subLocation.map((d) => d.subLocationName));
    setLocationId(location._id);
    setSubLocationId(location.subLocation.map((d) => d._id));
    const formattedOptions = location.subLocation.map((subLoc) => ({
      label: subLoc.subLocationName,
      value: subLoc._id,
    }));
    setSubLocationOptions(formattedOptions);
    // setSubLocationData(location.subLocation);
    setIsEditing(true);
  };

  const UpdateLocation = (e) => {
    let newsubLocationOptions = subLocationOptions;
    let newaddNewSublocation = addNewSublocation;
    const subLocationData = [
      ...newsubLocationOptions.map((data) => ({

        subLocationName: Array.isArray(data.label) ? data.label[0] : data.label,
        id: data.value,
      })),
      ...(newaddNewSublocation ? newaddNewSublocation.map((name) => ({
        subLocationName: name.trim(),
      })) : ''),
    ];

    e.preventDefault();
    let isValid = errHandles();
    if (isValid) {
      let updateLocationData = {
        locationId: locationId,
        locationName: location,
        subLocation: subLocationData,

      };
      updateLocation(updateLocationData)
        .then((res) => {
          if (res.status === 200) {
            Swal.fire({
              icon: "success",
              title: "Location Updated Successfully",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "ok",
            });
            handleClear();
            getLocationDataList();
            setAddNewSublocation('');
            setSelectedSubLocation(null);
            setSubLocation([])
          } else {
            Swal.fire({
              icon: "success",
              title: "Location Not Updated! Please try again.",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "ok",
            });
          }
        })
        .catch((err) => {
          console.log(err, "error in update location");
        });
    }
  };

  return (
    <div>
      <AdminSidebar />
      <div
        className="container topNav-m"
      >
        <div className=" content-title">
          <h3>Location</h3>
          <hr className="content-title-underline" />
        </div>
        {/* className="container mt-6 d-flex justify-content-center" */}

        <div className="container mt-2">
          {/* <div className="text-center mt-3">
              <h2 className="font-weight-bold">Add Location</h2>
            </div> */}

          <form className="box-2">
            <div className="row">
              <h5 className="m-0">{!isEditing ? 'Add' : 'Update'} Location</h5>
            </div>
            <div className="row ">
              <div className="col-md-3">
                <div >
                  <span className="details"> Location</span>

                  <FormControl variant="outlined" fullWidth>
                    <TextField
                      // label="Location"
                      // variant="outlined"
                      autoComplete="off"
                      className="form-control"
                      type="text"
                      size="small"
                      name="location"
                      value={location}
                      onChange={(e) => {
                        setLocation(e.target.value);
                        setFormError({ ...formError, location: "" });
                      }}
                    // required

                    //   renderValue={(selected) => selected.join(', ')} // Display selected items
                    ></TextField>

                    <div style={{ color: "red", fontSize: 13 }}>
                      {formError.location}
                    </div>
                  </FormControl>
                </div>
              </div>

              <div className="col-md-3">
                <div >
                  <span className="details">{isEditing ? 'Select' : null} SubLocation</span>

                  <FormControl variant="outlined" fullWidth>
                    {isEditing ? (
                      <>
                        {/* <Select
                              options={subLocationOptions.map(
                                (option, index) => ({ ...option, index })
                              )}
                              value={selectedSubLocation}
                              size="small"
                              classNamePrefix={"my-custom-react-select"}
                              onChange={(selectedOption) => {
                                setSelectedSubLocation(selectedOption)
                                setIseditsublocation(selectedOption);
                                
                              }}
                            /> */}
                        <Select
                          options={subLocationOptions.map((option, index) => ({ ...option, index }))}
                          value={selectedSubLocation}
                          size="small"
                          classNamePrefix="my-custom-react-select"
                          styles={{
                            menu: (provided) => ({
                              ...provided,
                              zIndex: 9999,
                              position: 'absolute', 
                              marginTop: '5px', 
                            }),
                            control: (provided) => ({
                              ...provided,
                              zIndex: 1, 
                            }),
                          }}
                          onChange={(selectedOption) => {
                            setSelectedSubLocation(selectedOption);
                            setIseditsublocation(selectedOption);
                          }}
                        />


                      </>
                    ) : (
                      <TextField
                        autoComplete="off"
                        className="form-control"
                        type="text"
                        size="small"
                        name="subLocation"
                        value={subLocation}
                        onChange={(e) => {
                          setSubLocation(e.target.value.split(',').map(s => s.trim()));
                          setFormError({ ...formError, subLocation: "" });
                        }}
                      ></TextField>
                    )}
                    <div style={{ color: "red", fontSize: 13 }}>
                      {formError.subLocation}
                    </div>
                  </FormControl>
                </div>
              </div>
              {isEditing ? (
                <div className="col-md-3">
                  {selectedSubLocation ?
                    <div >
                      <span className="details">Edit SubLocation</span>
                      <div>
                        <TextField
                          autoComplete="off"
                          className="form-control"
                          type="text"
                          name="sublocation"
                          size="small"
                          value={
                            Object.keys(iseditsublocation).length !== 0
                              ? subLocationOptions[iseditsublocation?.index]
                                .label
                              : ""
                          }
                          onChange={(e) => {
                            let newArr = [...subLocationOptions];
                            newArr[iseditsublocation?.index]["label"] =
                              e.target.value.split(',').map(s => s.trim());
                            setSubLocationOptions(newArr);
                          }}
                        ></TextField>
                      </div>

                    </div>
                    : null}
                  {selectedSubLocation ? null :
                    <div >
                      <span className="details">Add new SubLocation</span>

                      <div>
                        <TextField
                          autoComplete="off"
                          className="form-control"
                          type="text"
                          size="small"
                          name="subLocation"
                          value={addNewSublocation}
                          onChange={(e) => {
                            setAddNewSublocation(e.target.value.split(',').map(s => s.trim()));
                            setFormError({ ...formError, subLocation: "" });
                          }}
                        >

                        </TextField>
                      </div>

                    </div>
                  }
                </div>

              ) : null}

              <div className="col-md-3 ">
                <div >
                  <p className="m-0">&nbsp;</p>
                  {!isEditing ? (
                    <button
                      value="Submit"
                      className="btn btn-primary"
                      onClick={(e) => SubmitLocation(e)}
                    >
                      Submit
                    </button>
                  ) : (
                    <button
                      value="Submit"
                      className="btn btn-primary"
                      onClick={(e) => UpdateLocation(e)}
                    >
                      Update
                    </button>
                  )}
                </div>
              </div>
            </div>

          </form>
        </div>

      </div>

      <div>
        <LocationMasterDetails
          onEdit={handleEdit}
          locationData={locationData}
          getLocationDataList={getLocationDataList}
        />
      </div>
    </div>
  );
};

export default LocationMaster;
