import axios from "axios";
import { settings } from "../Config/Settings";

const addProprtyToWish = (data) => {
  return axios({
    method: "POST",
    url: `${settings.API_URL}/user/addtowish`,
    data: data,
  });
};

const getwishlist = (userId) => {
  return axios({
    method: "GET",
    url: `${settings.API_URL}/user/getuserwish/${userId}`,
  });
};

const wishPropertyList = (userId) => {
  return axios({
    method: "GET",
    url: `${settings.API_URL}/user/listproperties/${userId}`,
  });
};

export { addProprtyToWish, getwishlist, wishPropertyList };
