import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
//muipackage

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
// others
import "./Dashboard.css";
import imager from "../../../Assets/property-not-found.png";
//icons
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import CottageOutlinedIcon from '@mui/icons-material/CottageOutlined';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import ReviewsOutlinedIcon from '@mui/icons-material/ReviewsOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import CurrencyRupeeOutlinedIcon from '@mui/icons-material/CurrencyRupeeOutlined';
//custom components
import AdminSidebar from "../../../Components/Sidebar/AdminSidebar";
import Auth from "../../../Config/helper/Auth";
//api
import { getOwnerDashbaordValues } from "../../../Api/PropertyAPI";

const Dashboard = () => {
  const Navigate = useNavigate();
  const userId = Auth?.user?.userData?._id
  const [allCount, setAllCount] = useState([])

  useEffect(() => {
    getOwnerDashbaordValues(userId)
      .then((res) => {
        if (res.data.status === 1) {
          setAllCount(res.data.data)
        } else {
          console.log("error in owner dashboard")
        }
      }).catch((err) => {
        console.log(err, "error in owner dashboard");
      })
  }, [])

  const displayDetails = (name) => {
    if (name === "Rent") {
      Navigate("/rentDetails");
    } else if (name === "Project") {
      Navigate("/projectDetails");
    } else if (name === "Property") {
      Navigate("/propertyDetails");
    } else if (name === "Commercial") {
      Navigate("/commDetails");
    }
  };
  return (
    <>
      {/* <AdminHeader/> */}
      <AdminSidebar />
      <h1 className="text-center mt-5">Welcome</h1>
      {/* import * as React from 'react'; */}

      <div class="dashboard">
        {/* <div class="tile">
          <div class="tile-icon">
            <CottageOutlinedIcon />
            <div class="count">{allCount?.rentCount ? allCount?.rentCount : 0}</div>
          </div>
          <div class="tile-content">
            <h2>Residential Properties</h2>
            <p>Total residential properties <span class="highlight">owned</span></p>
          </div>
        </div> */}
        <Link to="/rentDetails">
          <div className="tile">
            <div className="tile-icon">
              <CottageOutlinedIcon />
              <div className="count">{allCount?.rentCount ? allCount?.rentCount : 0}</div>
            </div>
            <div className="tile-content">
              <h2>Residential Properties</h2>
              <p>Total residential properties <span className="highlight">owned</span></p>
            </div>
          </div>
        </Link>
        {/* <div class="tile">
          <div class="tile-icon">
            <PeopleOutlinedIcon />
            <div class="count">32</div>
          </div>
          <div class="tile-content">
            <h2>Tenants</h2>
            <p>Current <span class="highlight">occupied</span> units</p>
          </div>
        </div> */}
        {/* <div class="tile">
          <div class="tile-icon">
            <CurrencyRupeeOutlinedIcon />
            <div class="count">₹25,450</div>
          </div>
          <div class="tile-content">
            <h2>Income</h2>
            <p><span class="highlight">Monthly</span> rental revenue</p>
          </div>
        </div> */}
      <Link to="/commDetails">
        <div class="tile">
          <div class="tile-icon">
            <ApartmentOutlinedIcon />
            <div class="count">{allCount?.commercialCount ? allCount?.commercialCount : 0}</div>
          </div>
          <div class="tile-content">
            <h2>Commercial Properties</h2>
            <p>Total commercial properties <span class="highlight">owned</span></p>
          </div>
        </div>
      </Link>
      <Link to="/listagreement">
        <div class="tile">
          <div class="tile-icon">
            <AssignmentOutlinedIcon />
            <div class="count">{allCount?.aggrementCount ? allCount?.aggrementCount : 0}</div>
          </div>
          <div class="tile-content">
            <h2>Agreement</h2>
            <p><span class="highlight">Total</span> requests</p>
          </div>
        </div>
      </Link>
      <Link to="/reviewproperty">
        <div class="tile">
          <div class="tile-icon">
            <ReviewsOutlinedIcon />
            <div class="count">{allCount?.overallRating ? allCount?.overallRating : 0}</div>
          </div>
          <div class="tile-content">
            <h2>Reviews</h2>
            <p><span class="highlight">Average</span> rating</p>
          </div>
        </div>
      </Link>

      </div>

      {/* <div className="card-container">
        <Card sx={{ maxWidth: 400, height: 600 }}>
          <CardMedia sx={{ height: 300 }} image={imager} title="green iguana" />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Rent Details
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Residential property details typically include key information
              such as the address, square footage, number of bedrooms and
              bathrooms, amenities, and any special features like a backyard or
              garage. These details provide a snapshot of the property's
              attributes and help potential buyers or renters make informed
              decisions.
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              title="Go To Rent Section"
              style={{ width: "100%" }}
              onClick={() => {
                displayDetails("Rent");
              }}
              className="text-center mt-4"
            >
              <ArrowCircleRightIcon style={{ fontSize: "3rem" }} />
            </Button>
          </CardActions>
        </Card>
        <Card sx={{ maxWidth: 400, height: 600 }}>
          <CardMedia sx={{ height: 300 }} image={imager} title="green iguana" />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Commercial Details
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Commercial property details encompass essential information like
              location, square footage, zoning regulations, available amenities,
              and lease terms. These details serve as crucial insights for
              potential tenants or buyers, facilitating informed decisions
              tailored to their business needs and goals.
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              title="Go To Commercial Section"
              style={{ width: "100%" }}
              onClick={() => {
                displayDetails("Commercial");
              }}
              className="text-center mt-4"
            >
              <ArrowCircleRightIcon style={{ fontSize: "3rem" }} />
            </Button>
          </CardActions>
        </Card>
      </div> */}
    </>
  );
};

export default Dashboard;
