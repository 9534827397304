/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

// @mui packages
import { Button, TextField, Select, Box, Autocomplete } from "@mui/material";

import Drawer from '@mui/material/Drawer';

import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { emphasize, styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Slider from "@mui/material/Slider";
import { FormControl, MenuItem } from "@material-ui/core";
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';

//bootstrap packages
import { Container, Row, Col } from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';
// icon
import { IoMdArrowRoundBack } from "react-icons/io";

// navbar
import Topnavigation from "../../Components/Navigation/TopNavigation";

// api
import { filterByAllData, getCommercialProperty } from "../../Api/Commercial";
import {
  filterAllResidential,
  getAllRentProperty,
} from "../../Api/RentpropertyAPI";
import { getAminitiesData, getLocationData, getPropertytypeByTypeData } from "../../Api/AuthAPI";
import { filterConstructionProject, getAllProjectDetails } from "../../Api/BuilderProjectAPI";
//Auth
import Auth from "../../Config/helper/Auth";

// custom component
import Footer from "../../Components/Layouts/Footer";
import ResidentialFilter from "./ResidentialFilter";
import CommercialFilter from "./CommercialFilter";
import {
  PropertyAreaList,
  PropertyTypeOption,
  PurposeOfPropertyList,
  availabilityList,
  commercialPropertyTypeOptions,
  furnishingList,
  locationOptions,
  noOfBedroomsList,
  noOfCabinsList,
  noOfFloorsList,
  postedByList
} from "../../modules/helpers/constant";
import FilterNewProject from "./FilterNewProject";
import RecommendedProject from "../BuilderProject/RecommendedProject";

// style
const StyledBreadcrumb = styled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor,
    height: theme.spacing(3),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(backgroundColor, 0.12),
    },
  };
});

const FilterProperties = (props) => {
  const locationData = useLocation();
  const navigate = useNavigate();

  const [yourData, setYourData] = useState();

  const [useContactDetails, setUseContactDetails] = useState({
    Name: "",
    email: "",
    Phone: "",
  });

  // commercial property
  const [commercialData, setCommercialData] = useState([]);
  const [allCommercialData, setAllCommercialData] = useState([]);
  const [commercialAminities, setCommercialAminities] = useState([]);

  // Residential property
  const [residentialAminities, setResidentialAminities] = useState([]);
  const [ResidentialData, setResidentialData] = useState([]);
  const [allResidentialData, setAllResidentialData] = useState([]);

  // new project
  const [AllProjectData, setAllProjectData] = useState([]);
  const [allPropertyData, setAllPropertyData] = useState([]);

  const [noOfBedrooms, setNoOfBedrooms] = useState(noOfBedroomsList);
  const [postedBy, setPostedBy] = useState(postedByList);
  const [noOfCabins, setNoOfCabins] = useState(noOfCabinsList);

  const [noOfSeats, setNoOfSeats] = useState(0);
  const [noOfFloors, setNoOfFloors] = useState(noOfFloorsList);
  const [furnishing, setFurnishing] = useState(furnishingList);
  const [availability, setAvailability] = useState(availabilityList);



  const [purposeOfProperty, setPurposeOfProperty] = useState("Buy");
  const [propertyLocation, setPropertyLocation] = useState([]);
  const [propertyType, setPropertyType] = useState([]);
  const [propertyPrice, setPropertyPrice] = useState([0, 1000000]);
  const [propertyArea, setPropertyArea] = useState([0, 20000]);
  const [locationList, setLocationList] = useState([]);
  const minDistance = 10;

  //new project
  const [noOfProjectBHK, setNoOfProjectBHK] = useState([
    {
      name: "1 BHK",
      checked: false,
      value: "1",
    },
    {
      name: "2 BHK",
      checked: false,
      value: "2",
    },
    {
      name: "3 BHK",
      checked: false,
      value: "3",
    },
    {
      name: "4 BHK",
      checked: false,
      value: "4",
    },
  ])
  const [projectPrice, setProjectPrice] = useState([0, 10000000000]);
  const [areaType, setAreaType] = useState([]);
  const [projectAmenities, setProjectAmenities] = useState([])
  const [propertyOptions, setPropertyOptions] = useState([])
  const [error, setError] = useState([]);

  useEffect(() => {
    getLocationData()
      .then((res) => {
        if (res.status === 200) {
          let locationData = res.data.locations;
          setLocationList(locationData);
        } else {
          setLocationList([]);
        }
      })
      .catch((err) => {
        console.log(err, "error in location get call find property");
      });
  }, []);

  useEffect(() => {

    getAminitiesData()
      .then((res) => {
        if (res.status === 200) {
          const aminitiesResedentialData = res.data.AmenitiesData;
          const commercialAminitiesData = aminitiesResedentialData.filter(
            (aminititesData) =>
              aminititesData.PropertyType === "Commercial" ||
              aminititesData.PropertyType === "All"
          );

          const residentialAminitiesData = aminitiesResedentialData.filter(
            (aminititesData) =>
              aminititesData.PropertyType === "Residential" ||
              aminititesData.PropertyType === "All"
          );
          setCommercialAminities(commercialAminitiesData);
          setResidentialAminities(residentialAminitiesData);
          setProjectAmenities(residentialAminitiesData)
        }
      })
      .catch((err) => {
        console.log(err, "error in aminities get call in filterproperties");
      });
  }, []);

  const handleChange1 = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setPropertyPrice([
        Math.min(newValue[0], propertyPrice[1] - minDistance),
        propertyPrice[1],
      ]);
    } else {
      setPropertyPrice([
        propertyPrice[0],
        Math.max(newValue[1], propertyPrice[0] + minDistance),
      ]);
    }
  };

  const handleProjectChange1 = (event, newValue, activeThumb) => {

    if (!Array.isArray(newValue)) {
      return;
    }
    if (activeThumb === 0) {
      setProjectPrice([
        Math.min(newValue[0], projectPrice[1] - minDistance),
        projectPrice[1],
      ])
    } else {
      setProjectPrice([
        projectPrice[0],
        Math.max(newValue[1], projectPrice[0] + minDistance)
      ])
    }
  }

  const handleChangeArea = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setPropertyArea([
        Math.min(newValue[0], propertyArea[1] - minDistance),
        propertyArea[1],
      ]);
    } else {
      setPropertyArea([
        propertyArea[0],
        Math.max(newValue[1], propertyArea[0] + minDistance),
      ]);
    }
  };

  // shuffled Data code
  // const shuffleArray = (array) => {
  //   for (let i = array.length - 1; i > 0; i--) {
  //     const j = Math.floor(Math.random() * (i + 1));
  //     [array[i], array[j]] = [array[j], array[i]]; // Swap elements
  //   }
  //   return array;
  // };

  // useEffect(() => {
  //   if (yourData?.propertyType === "Commercial") {
  //     setShuffledData(shuffleArray([...commercialData]));
  //   } else {
  //     setShuffledData(shuffleArray([...allPropertyData]));
  //   }
  // }, [commercialData, allPropertyData]);

  useEffect(() => {
    // getAllProperties();
    FilterPropertyData();
  }, [
    yourData,
    noOfBedrooms,
    availability,
    furnishing,
    noOfFloors,
    residentialAminities,
    commercialAminities,
    postedBy,
    noOfCabins,
    noOfSeats,
    propertyLocation,
    propertyType,
    propertyPrice,
    propertyArea,
    noOfFloors,
    //new project
    noOfProjectBHK,
    areaType,
    projectPrice,
    projectAmenities,
    purposeOfProperty,
  ]);
  useEffect(() => {
    getpropertyType()
    setYourData(locationData.state);
    setNoOfBedrooms((prevState) =>
      prevState.map((item) =>
        item.value === locationData.state?.noOfBedrooms
          ? { ...item, checked: true }
          : item
      )
    );
    let purposeOfPropertyData = []
    let locationName = [];
    let propertyType = [];
    let addPropertyPrice = [
      locationData.state?.minBudget,
      locationData.state?.maxBudget,
    ];
    let addPropertyArea = [
      locationData.state?.minArea,
      locationData.state?.maxArea,
    ];
    if (locationData?.state?.location) {
      locationName = locationName.concat(locationData?.state?.location);
    }
    setPropertyLocation(locationName);

    if (locationData?.state?.propertyTypes) {
      propertyType = propertyType.concat(locationData?.state?.propertyTypes);
    }
    if (locationData?.state?.purposeOfProperty) {
      purposeOfPropertyData = purposeOfPropertyData.concat(locationData?.state?.purposeOfProperty);
    }
    setPurposeOfProperty(purposeOfPropertyData);

    setPropertyType(propertyType);

    // setPropertyPrice(
    //   JSON.stringify(addPropertyPrice) === JSON.stringify([null, null])
    //     ? propertyPrice
    //     : addPropertyPrice
    // );
    // setPropertyArea(
    //   JSON.stringify(addPropertyArea) === JSON.stringify([null, null])
    //     ? propertyPrice
    //     : addPropertyArea
    // );
    setPropertyArea(
      JSON.stringify(addPropertyArea) === JSON.stringify([null, null])
        ? propertyArea
        : addPropertyArea
    );


    // new project

    let addProjectPrice = [
      locationData.state?.minPrice,
      locationData.state?.maxPrice,
    ];

    let areaType = [];

    if (locationData?.state?.areaTypes) {
      areaType = areaType.concat(locationData.state.areaTypes);
    }

    setAreaType(areaType);
    // setNoOfProjectBHK((prevState) => prevState.map((item) =>
    //   item.value === locationData.state?.noOfProjectBHK ? { ...item, checked: true } : item
    // ))
    setProjectPrice(JSON.stringify(addProjectPrice) === JSON.stringify([null, null])
      ? projectPrice : addProjectPrice
    )

    setPropertyPrice(JSON.stringify(addPropertyPrice) === JSON.stringify([null, null])
      ? propertyPrice : addPropertyPrice
    );
  }, [locationData.state]);

  const getpropertyType = () => {
    let properynewType = locationData?.state?.propertyType
    getPropertytypeByTypeData(properynewType)
      .then((res) => {
        if (res.status === 200) {
          let result = res.data.data;
          let list = result.map((data) => ({ label: data.AreaType, value: data._id }));
          setPropertyOptions(list);
        }
      }).catch((err) => {
        console.log(err, "error in property get data");
      })
  }

  const handleClose = () => {
    setUseContactDetails({
      Name: "",
      email: "",
      Phone: "",
    });
    setError([]);
  };

  const handleCheckBox = (event, index) => {
    const checked = event.target.checked;
    setNoOfBedrooms((items) => {
      return [
        ...items.slice(0, index),

        { ...items[index], checked },

        ...items.slice(index + 1),
      ];
    });
  };

  const handleCabins = (event, index) => {
    const checked = event.target.checked;
    setNoOfCabins((items) => {
      return [
        ...items.slice(0, index),

        { ...items[index], checked },

        ...items.slice(index + 1),
      ];
    });
  };
  //handleAvailibility
  const handleAvailibility = (event, index) => {
    const checked = event.target.checked;
    setAvailability((items) => {
      return [
        ...items.slice(0, index),

        { ...items[index], checked },

        ...items.slice(index + 1),
      ];
    });
  };
  //handleFurnishing
  const handleFurnishing = (event, index) => {
    const checked = event.target.checked;
    setFurnishing((items) => {
      return [
        ...items.slice(0, index),

        { ...items[index], checked },

        ...items.slice(index + 1),
      ];
    });
  };

  //handleFloor
  const handleFloor = (event, index) => {
    const checked = event.target.checked;
    setNoOfFloors((items) => {
      return [
        ...items.slice(0, index),

        { ...items[index], checked },

        ...items.slice(index + 1),
      ];
    });
  };
  // handleAminities
  const handleAmenities = (event, index, type) => {
    const checked = event.target.checked;

    if (type === "Residential") {
      setResidentialAminities((prevAmenities) => {
        const updatedAmenities = [...prevAmenities];
        updatedAmenities[index].AmenitiesChecked = checked;
        return updatedAmenities;
      })
    } else if (type === "Commercial") {
      setCommercialAminities((prevAmenities) => {
        const updatedAmenities = [...prevAmenities];
        updatedAmenities[index].AmenitiesChecked = checked;
        return updatedAmenities;
      })
    } else {
      setProjectAmenities((prevAmenities) => {
        const updatedAmenities = [...prevAmenities];
        updatedAmenities[index].AmenitiesChecked = checked;
        return updatedAmenities;
      })
    }


  };

  const handlePostedBy = (event, index) => {
    const checked = event.target.checked;
    setPostedBy((items) => {
      return [
        ...items.slice(0, index),

        { ...items[index], checked },

        ...items.slice(index + 1),
      ];
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUseContactDetails({ ...useContactDetails, [name]: value });

    if (name === "Name" && value !== null && value !== "") {
      setError({
        ...error,
        Name: "Name field can't be left blank. Please enter your name!",
      });
    }

    if (name === "email" && value !== null && value !== "") {
      setError({
        ...error,
        email: "Email ID field can't be left blank. Please enter",
      });
    }
    if (name === "Phone" && value !== null && value !== "") {
      setError({
        ...error,
        Phone: "Please enter a 10-digit Mobile number!",
      });
    }
  };

  const validation = () => {
    let errors = {};
    let isValid = true;

    if (useContactDetails.Name === "" || useContactDetails.Name === null) {
      isValid = false;
      errors["Name"] =
        "Name field can't be left blank. Please enter your name!";
    }

    if (useContactDetails.email === "" || useContactDetails.email === null) {
      isValid = false;
      errors["email"] = "Email ID field can't be left blank. Please enter!";
    }

    if (useContactDetails.Phone === "" || useContactDetails.Phone === null) {
      isValid = false;
      errors["Phone"] = "Please enter a 10-digit Mobile number!";
    }
    setError(errors);
    return isValid;
  };

  const submitContactDetails = (e) => {
    e.preventDefault();
    const isValid = validation();
    if (isValid) {
    }
  };

  function parseIfJson(item) {
    try {
      return JSON.parse(item);
    } catch (e) {
      return item;
    }
  }

  const FilterPropertyData = async () => {
    const totalBedrooms = noOfBedrooms
      .filter((item) => item.checked)
      .map((item) => Number(item.name.match(/\d+/)[0]));
    const availabilityData = availability
      .filter((item) => item.checked)
      .map((item) => item.value);

    const FurnishingData = furnishing
      .filter((item) => item.checked)
      .map((item) => item.value);

    const NumberOfFloors = noOfFloors
      .filter((item) => item.checked)
      .map((item) => item.value);

    const residentialAminitiesData = residentialAminities
      .filter((item) => item.AmenitiesChecked)
      .map((item) => item.AmenitiesName);

    const commercialAminitiesData = commercialAminities
      .filter((item) => item.AmenitiesChecked)
      .map((item) => item.AmenitiesName);

    const postBY = postedBy
      .filter((item) => item.checked)
      .map((item) => item.value);

    const NoOfFloors = noOfFloors
      .filter((item) => item.checked)
      .map((item) => item.value);

    //new project
    const projectAmenitiesData = projectAmenities
      .filter((item) => item.AmenitiesChecked)
      .map((item) => item.AmenitiesName)


    // Convert the string element to an array
    // let numbersArray = JSON.parse(NoOfFloors[1]);

    // Convert string elements to arrays of numbers
    // let combinedFloor = [
    //   NoOfFloors[0],
    //   NoOfFloors[1],
    //   ...NoOfFloors.slice(2).map(JSON.parse).flat(),
    // ];

    // Filter out empty values and parse JSON strings
    let parsedFloor = NoOfFloors.filter((item) => item !== "").map(parseIfJson);

    // Combine the arrays
    let combinedFloor = parsedFloor.reduce((acc, curr) => acc.concat(curr), []);

    const Totalcabins = noOfCabins
      .filter((item) => item.checked)
      .map((item) => Number(item.name.match(/\d+/)[0]));

    if (yourData?.propertyType === "Residential") {
      let dataToSend = {
        purposeOfProperty: purposeOfProperty.length === 0 || (purposeOfProperty.length === 1 && purposeOfProperty[0] === null) ||
          purposeOfProperty[0] === undefined ? null : purposeOfProperty,
        Location:
          propertyLocation.length === 0 ||
            (propertyLocation.length === 1 && propertyLocation[0] === null) ||
            propertyLocation[0] === undefined
            ? null
            : propertyLocation,

        // Location: yourData?.location,
        propertyType:
          propertyType.length === 0 ||
            (propertyType.length === 1 && propertyType[0] === null) ||
            propertyType[0] === undefined
            ? null
            : propertyType,

        // minPrice: propertyPrice[0],
        // maxPrice: propertyPrice[1],

        // minBPrice: propertyPrice[0],
        // maxBPrice: propertyPrice[1],

        minArea: propertyArea[0],
        maxArea: propertyArea[1],
        selectNosofBedrooms: totalBedrooms.length === 0 ? null : totalBedrooms,
        Availibilty: availabilityData.length === 0 ? null : availabilityData,
        furnishedStatus: FurnishingData.length === 0 ? null : FurnishingData,
        // selectFloor: NumberOfFloors.length === 0 ? null : NumberOfFloors,
        Amenities:
          residentialAminitiesData.length === 0
            ? null
            : residentialAminitiesData,
        postedBy: postBY.length === 0 ? null : postBY,
        selectFloor: combinedFloor.length === 0 ? null : combinedFloor,
      };
      if (purposeOfProperty[0] === "Buy" || purposeOfProperty === "Buy") {
        dataToSend.minBPrice = propertyPrice[0];
        dataToSend.maxBPrice = propertyPrice[1];
      } else {
        dataToSend.minPrice = propertyPrice[0];
        dataToSend.maxPrice = propertyPrice[1];
      }
      // if (purposeOfProperty === "Buy") {
      //   dataToSend.minPrice = propertyPrice[0];
      //   dataToSend.maxPrice = propertyPrice[1];
      // } else {
      //   dataToSend.minBPrice = propertyPrice[0];
      //   dataToSend.maxBPrice = propertyPrice[1];
      // }

      await filterAllResidential(dataToSend)
        .then((res) => {
          // if (res.data.status === 200) {
          //   setAllPropertyData(res.data.Data);
          // } else {
          //   setAllPropertyData([]);
          // }
          if (res.data.status === 200) {
            let details = res.data.Data
            let verifiedDetails = details.filter((detail) => detail?.isVerified === yourData?.isVerified)
            if (yourData?.isVerified === null || yourData?.isVerified === undefined) {
              setAllPropertyData(details);
            } else if (verifiedDetails.length > 0) {
              setAllPropertyData(verifiedDetails);
            } else {
              setAllPropertyData([])
            }
          } else if (res.data.status === 404) {
            setAllPropertyData([])
          }
        })
        .catch((err) => {
          console.log(err, "error in residential filter property data");
        });
    } else if (yourData?.propertyType === "Commercial") {
      let dataToSend = {
        purposeOfProperty: purposeOfProperty.length === 0 || (purposeOfProperty.length === 1 && purposeOfProperty[0] === null) ||
          purposeOfProperty[0] === undefined ? null : purposeOfProperty,
        Location:
          propertyLocation.length === 0 ||
            (propertyLocation.length === 1 && propertyLocation[0] === null) ||
            propertyLocation[0] === undefined
            ? null
            : propertyLocation,
        propertyType:
          propertyType.length === 0 ||
            (propertyType.length === 1 && propertyType[0] === null) ||
            propertyType[0] === undefined
            ? null
            : propertyType,
        // minPrice: propertyPrice[0],
        // maxPrice: propertyPrice[1],
        minArea: propertyArea[0],
        maxArea: propertyArea[1],
        furnishedStatus: FurnishingData.length === 0 ? null : FurnishingData,
        postedBy: postBY.length === 0 ? null : postBY,
        Amenities:
          commercialAminitiesData.length === 0
            ? null
            : commercialAminitiesData,
        NosOfCabins: Totalcabins.length === 0 ? null : Totalcabins,
      };

      if (purposeOfProperty[0] === "Buy" || purposeOfProperty === "Buy") {
        dataToSend.minBPrice = propertyPrice[0];
        dataToSend.maxBPrice = propertyPrice[1];
      } else {
        dataToSend.minPrice = propertyPrice[0];
        dataToSend.maxPrice = propertyPrice[1];
      }
      await filterByAllData(dataToSend)
        .then((res) => {
          // if (res.data.status === 200) {
          //   setCommercialData(res.data.Data);
          // } else {
          //   setCommercialData([]);
          // }
          if (res.data.status === 200) {
            let details = res.data.Data
            let verifiedDetails = details.filter((detail) => detail?.isVerified === yourData?.isVerified)
            if (yourData?.isVerified === null || yourData?.isVerified === undefined) {
              setCommercialData(details);
            } else if (verifiedDetails.length > 0) {
              setCommercialData(verifiedDetails);
            } else {
              setCommercialData([])
            }
          } else if (res.data.status === 404) {
            setCommercialData([])
          }
        })
        .catch((err) => {
          console.log(err, "error in commercial filter property data");
        });
    } else {
      let dataToSend = {
        Location:
          propertyLocation.length === 0 ||
            (propertyLocation.length === 1 && propertyLocation[0] === null) ||
            propertyLocation[0] === undefined ? null : propertyLocation,
        //  noofProjectBHK: property
        areaType: areaType.length === 0 ||
          (areaType.length === 1 && areaType[0] === null) ||
          areaType[0] === undefined ? null : areaType,
        minPrice: projectPrice[0],
        maxPrice: projectPrice[1],
        Amenities: projectAmenitiesData.length === 0 ? null : projectAmenitiesData,
        userId: locationData.state.userId
      }
      await filterConstructionProject(dataToSend)
        .then((res) => {
          if (res.data.status === 200) {

            let details = res.data.Data
            let verifiedDetails = details.filter((detail) => detail.isVerified === yourData.isVerified)
            if (yourData.isVerified === null || yourData.isVerified === undefined) {
              setAllProjectData(details);
            } else if (verifiedDetails.length > 0) {
              setAllProjectData(verifiedDetails);
            } else {
              setAllProjectData([])
            }
          } else if (res.data.status === 404) {
            setAllProjectData([])
          }
        }).catch((err) => {
          console.log(err, "error in filter of project");
        })
    }
  };

  function valuetext(value) {
    return `${value}°C`;
  }

  const getAllProperties = () => {
    if (yourData?.propertyType === "Commercial") {
      getCommercialProperty()
        .then((res) => {
          if (res.data.status === 1) {
            let details = res.data.RentCommercialPropertyDetails;
            setCommercialData(details);
            setAllCommercialData(details);
            // setAllPropertyData(details);
          }
        })
        .catch((err) => {
          console.log(err, "error in get all commercial property.");
        });
    } else if (yourData?.propertyType === "Residential") {
      getAllRentProperty()
        .then((res) => {
          if (res.data.status === 1) {
            let details = res.data.newRentProprtyDetails;

            // verified status filter
            // let updateStatus = details.filter(
            //   (item) => item.isVerified === "Verified"
            // );
            setResidentialData(details);
            setAllResidentialData(details);
            // setAllPropertyData(details);
          }
        })
        .catch((err) => {
          console.log(err, "error in get all rent property.");
        });
    } else {

      getAllProjectDetails()
        .then((res) => {
          if (res.data.status === 1) {
            let details = res.data
            console.log(details, "details of builder get call")
          }
        }).catch((err) => {
          console.log(err, "error in get projects");
        })
    }
  };


  // const propertyOptions =
  //   yourData?.propertyType === "Commercial"
  //     ? commercialPropertyTypeOptions
  //     : PropertyTypeOption;

  const projectAreaTypeOptions = PropertyAreaList;

  const NumberSeparator = (input) => {
    return new Intl.NumberFormat("en-IN", {
      minimumFractionDigits: 2,
    }).format(input);
  };

  const resetData = () => {
    setPurposeOfProperty("Buy");
    setPropertyLocation([]);
    setPropertyType([]);
    setPropertyPrice([0, 1000000]);
    setPropertyArea([0, 20000]);
    setNoOfBedrooms([
      {
        name: "1 BHK",
        checked: false,
        value: "1",
      },
      {
        name: "2 BHK",
        checked: false,
        value: "2",
      },
      {
        name: "3 BHK",
        checked: false,
        value: "3",
      },
      {
        name: "4 BHK",
        checked: false,
        value: "4",
      },
      {
        name: "4+ BHK",
        checked: false,
        value: "5",
      },
    ]);
    setPostedBy([
      {
        name: "Owner",
        checked: false,
        value: "Owner",
      },
      {
        name: "Agent",
        checked: false,
        value: "Agent",
      },
    ]);
    setNoOfCabins([
      {
        name: "1+",
        checked: false,
        value: "1",
      },
      {
        name: "2+",
        checked: false,
        value: "2",
      },
      {
        name: "3+",
        checked: false,
        value: "3",
      },
      {
        name: "4+",
        checked: false,
        value: "4",
      },
      {
        name: "5+",
        checked: false,
        value: "5",
      },
    ]);
    setNoOfFloors([
      {
        name: "Lower Basement",
        checked: false,
        value: "Basement",
      },
      {
        name: "Ground",
        checked: false,
        value: "Ground",
      },
      {
        name: "1-4",
        checked: false,
        value: "[1,2,3,4]",
      },
      {
        name: "5-8",
        checked: false,
        value: "[5,6,7,8]",
      },
      {
        name: "9-12",
        checked: false,
        value: "[9,10,11,12]",
      },
      {
        name: "13-16",
        checked: false,
        value: "[13,14,15,16]",
      },
      {
        name: "16+",
        checked: false,
        value: "[17,18,19,20]",
      },
    ]);

    setProjectPrice([0, 10000000000]);
    setAreaType([]);
    // setProjectAmenities([]);
  };

  const handleFloors = (event, index) => {
    const checked = event.target.checked;
    setNoOfFloors((items) => {
      return [
        ...items.slice(0, index),

        { ...items[index], checked },

        ...items.slice(index + 1),
      ];
    });
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  const formatCash = (n) => {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e5) return +(n / 1e3).toFixed(1) + "K";
    if (n >= 1e5 && n < 1e6) return +(n / 1e5).toFixed(1) + "L";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
  };


  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const DrawerList = (
    <Box sx={{ width: 350 }} role="presentation" onClick={toggleDrawer(false)} className="topNav-m p-4">
     <div className="search-content">
              <div className="search-filter">
                <div className="filters">
                  Filters
                  <Button
                    variant="text"
                    className="btn-clear"
                    onClick={() => resetData()}
                  >
                    Clear
                  </Button>
                </div>

                {/* purpose of property */}
                {yourData?.propertyType === "Construction" ? null :
                  <div class="cat-bhk">
                    <h5>purposeOfProperty</h5>
                    <FormControl variant="outlined" fullWidth size="small">
                      <Select
                        // multiple
                        size="small"
                        value={purposeOfProperty}
                        onChange={(e) => {
                          setPurposeOfProperty(e.target.value);
                        }}
                        required
                      >
                        {PurposeOfPropertyList.map((option, i) => (
                          <MenuItem
                            key={i}
                            value={option.value}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                }
                <div className="cat-bhk">
                  <h5>Location</h5>
                  <Autocomplete
                    multiple
                    options={locationList.filter(location =>
                      !propertyLocation.includes(
                        typeof location === 'string' ? location : location.locationName
                      )
                    )}
                    getOptionLabel={(option) =>
                      typeof option === 'string' ? option : option.locationName || ''
                    }
                    value={propertyLocation}
                    onChange={(event, newValue) => {
                      const locationNames = newValue.map((location) =>
                        typeof location === 'string' ? location : location.locationName
                      ).filter(Boolean);
                      const uniqueLocations = [...new Set(locationNames)];
                      setPropertyLocation(uniqueLocations);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        InputProps={{
                          ...params.InputProps,
                          style: {
                            padding: '4px 8px',
                            fontSize: '14px',
                          },
                        }}
                      />
                    )}
                    PaperComponent={({ children }) => (
                      <div
                        style={{
                          maxHeight: 200,
                          width: '100%', 
                          position: 'absolute',
                          top: 'calc(100% + 8px)', 
                          left: 0,
                          zIndex: 9999,
                          overflowY: 'auto',
                          border: '1px solid #ccc',
                          borderRadius: '4px',
                          backgroundColor: '#fff',
                        }}
                      >
                        {children}
                      </div>
                    )}
                  />
                </div>
                {yourData?.propertyType === "Construction" ?
                  <div className="cat-bhk">
                    <h5>Area Type</h5>
                    <FormControl variant="outlined" fullWidth size="small">
                      <Autocomplete
                        multiple
                        size="small"
                        value={areaType}
                        onChange={(event, newValue) => {
                          setAreaType(newValue);
                        }}
                        options={propertyOptions.map((option) => option.label)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                          // label="Search Property Type"
                          // placeholder="Select property type"
                          />
                        )}
                        renderOption={(props, option) => (
                          <MenuItem {...props} key={option} value={option}>
                            {option}
                          </MenuItem>
                        )}
                      />
                    </FormControl>
                  </div>
                  :
                  <div className="cat-bhk">
                    <h5>Property Type</h5>
                    <Autocomplete
                      multiple
                      size="small"
                      value={propertyType}
                      onChange={(event, newValue) => setPropertyType(newValue)}
                      options={propertyOptions.map((option) => option.label)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                        // label="Search Property Type"
                        // placeholder="Select property type"
                        />
                      )}
                      renderOption={(props, option) => (
                        <MenuItem {...props} key={option} value={option}>
                          {option}
                        </MenuItem>
                      )}
                    />
                  </div>

                }
                {/* price */}
                {yourData?.propertyType === "Construction" ?
                  <div>
                    <h5>
                      Price Range <span>₹{formatCash(projectPrice[0])} to ₹{formatCash(projectPrice[1])}</span>
                    </h5>

                    <Slider
                      getAriaLabel={() => "Minimum distance"}
                      value={projectPrice}
                      // defaultValue={5000}
                      max={10000000000}
                      min={0}
                      onChange={handleProjectChange1}
                      valueLabelDisplay="auto"
                      getAriaValueText={NumberSeparator}
                      disableSwap
                      step={5000}
                    />
                  </div>
                  :
                  <>
                    <div>
                      <h5>Price Range <span>₹{formatCash(propertyPrice[0])} to ₹{formatCash(propertyPrice[1])}</span></h5>
                      <Slider
                        getAriaLabel={() => "Minimum distance"}
                        value={propertyPrice}
                        max={1000000}
                        min={0}
                        onChange={handleChange1}
                        valueLabelDisplay="auto"
                        getAriaValueText={NumberSeparator}
                        disableSwap
                        step={5000}
                      />
                    </div>
                    <div>
                      <h5>Area Range <span>{propertyArea[0]} SqFt to {propertyArea[1]} SqFt</span></h5>
                      <Slider
                        value={propertyArea}
                        defaultValue={20000}
                        max={20000}
                        min={0}
                        onChange={handleChangeArea}
                        valueLabelDisplay="auto"
                        getAriaValueText={NumberSeparator}
                        disableSwap
                        step={100}
                      />
                    </div>
                  </>
                }
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Advance Filters</Accordion.Header>
                    <Accordion.Body>
                      <div>
                        <h5>Amenities</h5>
                        {yourData?.propertyType === "Commercial" ? (
                          commercialAminities.map((cdata, index) => (
                            <div class="boxes" key={`amenity-${index}`}>
                              <input
                                type="checkbox"
                                id={`amenity-${index}`}
                                checked={cdata.AmenitiesChecked}
                                onChange={(e) => handleAmenities(e, index, "Commercial")}
                              />
                              <label for={`amenity-${index}`}>{cdata.AmenitiesName}</label>
                            </div>
                          ))

                        ) : (
                          residentialAminities.map((rdata, index) => (
                            <div class="boxes" key={`amenity-${index}`}>
                              <input
                                type="checkbox"
                                id={`amenity-${index}`}
                                checked={rdata.AmenitiesChecked}
                                onChange={(e) => handleAmenities(e, index, "Residential")}
                              />
                              <label for={`amenity-${index}`}>{rdata.AmenitiesName}</label>
                            </div>
                          ))
                        )}

                        {/* posted by */}
                        {yourData?.propertyType === "Construction" ? null :
                          <div>
                            <h5>Posted By</h5>
                            {postedBy.map((item, index) => (
                              <div className="cat search-sel" key={index}>
                                <label>
                                  <input
                                    type="checkbox"
                                    checked={item.checked}
                                    onChange={(e) => handlePostedBy(e, index)}
                                  />
                                  <span>{item.name}</span>
                                </label>
                              </div>
                            ))}
                          </div>
                        }
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>


            </div>
    </Box>
  );
  return (
    <div className="topNav-m">
      <Topnavigation />

      <Container>
        <Row>
          <Col>
            {/*  Breadcrumbs*/}
            <div className="p-3">
              <Breadcrumbs aria-label="breadcrumb" onClick={handleBackClick}>
                <StyledBreadcrumb
                  component="a"
                  // href="/"
                  icon={<IoMdArrowRoundBack fontSize="20px" />}
                  label="Back"
                />
                {yourData?.propertyType === "Construction" ?
                  <StyledBreadcrumb
                    component="a"
                    label={
                      `Project in ${yourData?.location ? yourData?.location : "All Locations"}`
                    }
                  />
                  :
                  <StyledBreadcrumb
                    component="a"
                    label={
                      `Flats for ${yourData?.purposeOfProperty ? yourData?.purposeOfProperty : "All Properties"} in ${yourData?.location ? yourData?.location : "All Locations"}`
                    }
                  />
                }
                {/* <StyledBreadcrumb
                  label=" 2 bhk Flat for Rent in Vashi"
                  deleteIcon={<ExpandMoreIcon />}
                /> */}
              </Breadcrumbs>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={3} sm={12}>
            {/* <div className="d-flex justify-content-end pe-3">
              <Button onClick={toggleDrawer(true)} className="search-btn">Filters <FilterListOutlinedIcon /></Button>
              <Drawer open={open} onClose={toggleDrawer(false)}>
                {DrawerList}
              </Drawer>
            </div> */}

            <div className="search-content">
              <div className="search-filter">
                <div className="filters">
                  Filters
                  <Button
                    variant="text"
                    className="btn-clear"
                    onClick={() => resetData()}
                  >
                    Clear
                  </Button>
                </div>

                {/* purpose of property */}
                {yourData?.propertyType === "Construction" ? null :
                  <div class="cat-bhk">
                    <h5>purposeOfProperty</h5>
                    <FormControl variant="outlined" fullWidth size="small">
                      <Select
                        // multiple
                        size="small"
                        value={purposeOfProperty}
                        onChange={(e) => {
                          setPurposeOfProperty(e.target.value);
                        }}
                        required
                      // renderValue={(selected) => selected.join(", ")} // Display selected items
                      >
                        {PurposeOfPropertyList.map((option, i) => (
                          <MenuItem
                            key={i}
                            value={option.value}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                }

                {/* Location */}
                {/* <div class="cat-bhk">
                    <h5>Location</h5>
                    <FormControl variant="outlined" fullWidth size="small">
                      <Select
                        // label="Amenities"
                        multiple
                        size="small"
                        value={propertyLocation}
                        onChange={(e) => {
                          setPropertyLocation(e.target.value);
                        }}
                        required
                      // renderValue={(selected) => selected.join(", ")} // Display selected items
                      >
                        {locationList.map((option) => (
                          <MenuItem
                            key={option._id}
                            value={option.locationName}
                          >
                            {option.locationName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div> */}

                {/* <div class="cat-bhk">
                    <h5>Location</h5>
                    <FormControl variant="outlined" fullWidth size="small">
                      <Select
                        className="txtfield "
                        multiple
                        size="small"
                        value={propertyLocation}
                        onChange={(e) => setPropertyLocation(e.target.value)}
                        required
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 200,
                              width: 'auto',
                              position: 'absolute',
                            },
                          },
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                          },
                        }}
                      >
                        {locationList.map((option) => (
                          <MenuItem key={option._id} className="locationNameSelete" value={option.locationName}>
                            {option.locationName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div> */}

                <div className="cat-bhk">
                  <h5>Location</h5>
                  <Autocomplete
                    multiple
                    options={locationList.filter(location =>
                      !propertyLocation.includes(
                        typeof location === 'string' ? location : location.locationName
                      )
                    )}
                    getOptionLabel={(option) =>
                      typeof option === 'string' ? option : option.locationName || ''
                    }
                    value={propertyLocation}
                    onChange={(event, newValue) => {
                      // Normalize the value to ensure it's an array of strings
                      const locationNames = newValue.map((location) =>
                        typeof location === 'string' ? location : location.locationName
                      ).filter(Boolean); // Filter out empty or invalid entries

                      // Remove duplicates
                      const uniqueLocations = [...new Set(locationNames)];

                      // Update the state with the formatted array of unique location names
                      setPropertyLocation(uniqueLocations);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        InputProps={{
                          ...params.InputProps,
                          style: {
                            padding: '4px 8px',
                            fontSize: '14px',
                          },
                        }}
                      />
                    )}
                    PaperComponent={({ children }) => (
                      <div
                        style={{
                          maxHeight: 200,
                          width: '100%', // Set to 100% to match the input field width
                          position: 'absolute',
                          top: 'calc(100% + 8px)', // Position below the input field with some margin
                          left: 0,
                          zIndex: 9999,
                          overflowY: 'auto',
                          border: '1px solid #ccc',
                          borderRadius: '4px',
                          backgroundColor: '#fff',
                        }}
                      >
                        {children}
                      </div>
                    )}
                  />
                </div>




                {/* <div className="cat-bhk">
                    <h5>Location</h5>
                    <Autocomplete
                      multiple
                      options={locationList}
                      getOptionLabel={(option) =>
                        typeof option === 'string' ? option : option.locationName || ''
                      }
                      value={propertyLocation}
                      onChange={(event, newValue) => {
                        // Normalize the value to ensure it's an array of strings
                        const locationNames = newValue.map((location) =>
                          typeof location === 'string' ? location : location.locationName
                        ).filter(Boolean); // Filter out empty or invalid entries

                        // Update the state with the formatted array of location names
                        setPropertyLocation(locationNames);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          size="small"
                          InputProps={{
                            ...params.InputProps,
                            style: {
                              padding: '4px 8px',
                              fontSize: '14px',
                            },
                          }}
                        />
                      )}
                      PaperComponent={({ children }) => (
                        <div
                          style={{
                            maxHeight: 200,
                            width: '200px',
                            position: 'absolute',
                            top: '100%',
                            left: 0,
                            zIndex: 9999,
                            overflowY: 'auto',
                            border: '1px solid #ccc',
                            borderRadius: '4px',
                            backgroundColor: '#fff',
                          }}
                        >
                          {children}
                        </div>
                      )}
                    />
                  </div> */}

                {yourData?.propertyType === "Construction" ?
                  // <div class="cat-bhk">
                  //   <h5>Area Type</h5>
                  //   <FormControl variant="outlined" fullWidth size="small">
                  //     <Select
                  //       multiple
                  //       size="small"
                  //       value={areaType}
                  //       onChange={(e) => {
                  //         setAreaType(e.target.value);
                  //       }}
                  //       required
                  //     >
                  //       {propertyOptions.map((option) => (
                  //         <MenuItem key={option.value} value={option.label}>
                  //           {option.label}
                  //         </MenuItem>
                  //       ))}
                  //     </Select>
                  //   </FormControl>
                  // </div>
                  <div className="cat-bhk">
                    <h5>Area Type</h5>
                    <FormControl variant="outlined" fullWidth size="small">
                      {/* <Autocomplete
                          multiple
                          options={propertyOptions}
                          getOptionLabel={(option) => option.label}
                          value={areaType}
                          onChange={(event, newValue) => {
                            setAreaType(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField {...params} variant="outlined" size="small" required />
                          )}
                        /> */}
                      <Autocomplete
                        multiple
                        size="small"
                        value={areaType}
                        onChange={(event, newValue) => {
                          setAreaType(newValue);
                        }}
                        options={propertyOptions.map((option) => option.label)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                          // label="Search Property Type"
                          // placeholder="Select property type"
                          />
                        )}
                        renderOption={(props, option) => (
                          <MenuItem {...props} key={option} value={option}>
                            {option}
                          </MenuItem>
                        )}
                      />
                    </FormControl>
                  </div>

                  :
                  // <div class="cat-bhk">
                  //   <h5>Property Type</h5>
                  //   <FormControl variant="outlined" fullWidth size="small">
                  //     <Select
                  //       // label="Amenities"
                  //       multiple
                  //       size="small"
                  //       value={propertyType}
                  //       onChange={(e) => {
                  //         setPropertyType(e.target.value);
                  //       }}
                  //       required
                  //     // renderValue={(selected) => selected.join(", ")} // Display selected items
                  //     >
                  //       {propertyOptions.map((option) => (
                  //         <MenuItem key={option.value} value={option.label}>
                  //           {option.label}
                  //         </MenuItem>
                  //       ))}
                  //     </Select>
                  //   </FormControl>
                  // </div>
                  <div className="cat-bhk">
                    <h5>Property Type</h5>
                    <Autocomplete
                      multiple
                      size="small"
                      value={propertyType}
                      onChange={(event, newValue) => setPropertyType(newValue)}
                      options={propertyOptions.map((option) => option.label)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                        // label="Search Property Type"
                        // placeholder="Select property type"
                        />
                      )}
                      renderOption={(props, option) => (
                        <MenuItem {...props} key={option} value={option}>
                          {option}
                        </MenuItem>
                      )}
                    />
                  </div>

                }



                {/* price */}
                {yourData?.propertyType === "Construction" ?
                  <div>
                    {/* <h5>Price Range <span>{projectPrice[0]} to ₹{projectPrice[1]}</span></h5> */}
                    <h5>
                      Price Range <span>₹{formatCash(projectPrice[0])} to ₹{formatCash(projectPrice[1])}</span>
                    </h5>

                    <Slider
                      getAriaLabel={() => "Minimum distance"}
                      value={projectPrice}
                      // defaultValue={5000}
                      max={10000000000}
                      min={0}
                      onChange={handleProjectChange1}
                      valueLabelDisplay="auto"
                      getAriaValueText={NumberSeparator}
                      disableSwap
                      step={5000}
                    />
                    {/* <span> Min ₹{NumberSeparator(projectPrice.minPrice ? projectPrice.minPrice: 0)} </span>   <span>Max ₹{NumberSeparator(projectPrice.maxPrice ? projectPrice.maxPrice:0)}</span> */}
                  </div>
                  :
                  <>
                    <div>
                      {/* <h5>Price Range <span>₹5k to ₹10lacs</span></h5> */}
                      <h5>Price Range <span>₹{formatCash(propertyPrice[0])} to ₹{formatCash(propertyPrice[1])}</span></h5>
                      <Slider
                        getAriaLabel={() => "Minimum distance"}
                        value={propertyPrice}
                        // defaultValue={5000}
                        max={1000000}
                        min={0}
                        onChange={handleChange1}
                        valueLabelDisplay="auto"
                        getAriaValueText={NumberSeparator}
                        disableSwap
                        step={5000}
                      />
                      {/* <span> Min ₹{NumberSeparator(projectPrice.minPrice ? projectPrice.minPrice : 0)} </span>   <span>Max ₹{NumberSeparator(projectPrice.maxPrice ? projectPrice.maxPrice: 0)}</span> */}
                    </div>


                    {/* <div>
                        <h5>Area Range <span>₹0 SqFt to 20000 SqFt</span></h5>
                        <Slider
                          getAriaLabel={() => "Minimum distance"}
                          value={propertyArea}
                          defaultValue={20000}
                          max={20000}
                          min={0}
                          onChange={handleChangeArea}
                          valueLabelDisplay="on"
                          getAriaValueText={NumberSeparator}
                          disableSwap
                          step={100}
                        /> */}
                    {/* <div><input >100</input></div> */}
                    {/* </div> */}

                    <div>
                      <h5>Area Range <span>{propertyArea[0]} SqFt to {propertyArea[1]} SqFt</span></h5>
                      <Slider
                        value={propertyArea}
                        defaultValue={20000}
                        max={20000}
                        min={0}
                        onChange={handleChangeArea}
                        valueLabelDisplay="auto"
                        getAriaValueText={NumberSeparator}
                        disableSwap
                        step={100}
                      />
                    </div>
                  </>
                }
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Advance Filters</Accordion.Header>
                    <Accordion.Body>
                      {/* no of bhk */}
                      {/* <div class="cat-bhk">
                          {yourData?.propertyType === "Commercial" ? (
                            <>
                              <h5>Cabins</h5>
                              {noOfCabins.map((item, index) => (
                                <div class="cat search-sel" key={index}>
                                  <label>
                                    <input
                                      type="checkbox"
                                      checked={item.checked}
                                      onChange={(e) => handleCabins(e, index)}
                                    />
                                    <span>{item.name}</span>
                                  </label>
                                </div>
                              ))}
                            </>

                          ) : null

                            yourData?.propertyType === "Residential" ? (
                              <>
                                <h5>BHK Type</h5>
                                {noOfBedrooms.map((item, index) => (
                                  <div class="cat search-sel" key={index}>
                                    <label>
                                      <input
                                        type="checkbox"
                                        checked={item.checked}
                                        onChange={(e) => handleCheckBox(e, index)}
                                      />
                                      <span>{item.name}</span>
                                    </label>
                                  </div>
                                ))}
                              </>
                            )
                          }
                        </div> */}

                      {/* property availability */}
                      {/* <div>
                    <h5>Availability</h5>
                    {availability.map((item, index)=>(
                    <div class="boxes" key={index}>
                      <input
                       type="checkbox"
                       id={index}
                       checked={item.checked}
                       onChange={(e)=>handleAvailibility(e,index)} />
                      <label for={index}>{item.name}</label>
                    </div>
                    ))}
                  </div> */}

                      {/* Preferred Tenants */}
                      {/* <div>
                    <h5>Preferred Tenants</h5>
                    <div class="boxes">
                      <input type="checkbox" id="PreTen-1" />
                      <label for="PreTen-1">Family</label>

                      <input type="checkbox" id="PreTen-2" />
                      <label for="PreTen-2">Company </label>

                      <input type="checkbox" id="PreTen-3" />
                      <label for="PreTen-3">Bachelor Male</label>
                      <input type="checkbox" id="PreTen-4" />
                      <label for="PreTen-4">Bachelor Female</label>
                    </div>
                  </div> */}

                      {/* Furnishing status */}
                      {/* {yourData?.propertyType === "Construction" ? null :
                          <div>
                            <h5>Furnishing</h5>
                            {furnishing.map((item, index) => (
                              <div class="boxes" key={index}>
                                <input
                                  type="checkbox"
                                  id={index}
                                  checked={item.checked}
                                  onChange={(e) => handleFurnishing(e, index)}
                                />
                                <label for={index}>{item.name}</label>
                              </div>
                            ))}
                          </div>
                        } */}

                      {/* <div class="cat-bhk">
                    <h5>Ratings</h5>
                    <Box
                      sx={{
                        width: 200,
                        display: "flex",
                        alignItems: "center",
                      }}
                      onClick={() => setRatings(4)}
                    >
                      <Rating
                        name="read-only"
                        readOnly
                        value={4}
                        size="small"
                      />
                      &up
                    </Box>
                    <Box
                      sx={{
                        width: 200,
                        display: "flex",
                        alignItems: "center",
                      }}
                      onClick={() => setRatings(3)}
                    >
                      <Rating
                        name="read-only"
                        readOnly
                        value={3}
                        size="small"
                      />
                      &up
                    </Box>
                    <Box
                      sx={{
                        width: 200,
                        display: "flex",
                        alignItems: "center",
                      }}
                      onClick={() => setRatings(2)}
                    >
                      <Rating
                        name="read-only"
                        readOnly
                        value={2}
                        size="small"
                      />
                      &up
                    </Box>
                    <Box
                      sx={{
                        width: 200,
                        display: "flex",
                        alignItems: "center",
                      }}
                      onClick={() => setRatings(1)}
                    >
                      <Rating
                        name="read-only"
                        readOnly
                        value={1}
                        size="small"
                      />
                      &up
                    </Box>
                  </div> */}

                      {/* floors */}
                      {/* {yourData?.propertyType === "Residential" && (
                          <div class="cat-bhk">
                            <h5>Floor</h5>
                            {noOfFloors.map((item, index) => (
                              <div class="cat search-sel" key={index}>
                                <label>
                                  <input
                                    type="checkbox"
                                    checked={item.checked}
                                    onChange={(e) => handleFloor(e, index)}
                                  />
                                  <span>{item.name}</span>
                                </label>
                              </div>
                            ))}
                            <div class="cat search-sel">
                        <label>
                          <input type="checkbox" value="1" />
                          <span>Ground</span>
                        </label>
                      </div>
                      <div class="cat search-sel">
                        <label>
                          <input type="checkbox" value="1" />
                          <span>1-4</span>
                        </label>
                      </div>
                      <div class="cat search-sel">
                        <label>
                          <input type="checkbox" value="1" />
                          <span>5-8</span>
                        </label>
                      </div>
                      <div class="cat search-sel">
                        <label>
                          <input type="checkbox" value="1" />
                          <span>9-12</span>
                        </label>
                      </div>
                      <div class="cat search-sel">
                        <label>
                          <input type="checkbox" value="1" />
                          <span>13-16</span>
                        </label>
                      </div>
                      <div class="cat search-sel">
                        <label>
                          <input type="checkbox" value="1" />
                          <span>16+</span>
                        </label>
                      </div>
                          </div>
                        )} */}

                      {/* Amenities */}
                      <div>
                        <h5>Amenities</h5>
                        {yourData?.propertyType === "Commercial" ? (
                          commercialAminities.map((cdata, index) => (
                            <div class="boxes" key={`amenity-${index}`}>
                              <input
                                type="checkbox"
                                id={`amenity-${index}`}
                                checked={cdata.AmenitiesChecked}
                                onChange={(e) => handleAmenities(e, index, "Commercial")}
                              />
                              <label for={`amenity-${index}`}>{cdata.AmenitiesName}</label>
                            </div>
                          ))

                        ) : (
                          residentialAminities.map((rdata, index) => (
                            <div class="boxes" key={`amenity-${index}`}>
                              <input
                                type="checkbox"
                                id={`amenity-${index}`}
                                checked={rdata.AmenitiesChecked}
                                onChange={(e) => handleAmenities(e, index, "Residential")}
                              />
                              <label for={`amenity-${index}`}>{rdata.AmenitiesName}</label>
                            </div>
                          ))
                        )}

                        {/* posted by */}
                        {yourData?.propertyType === "Construction" ? null :
                          <div>
                            <h5>Posted By</h5>
                            {postedBy.map((item, index) => (
                              <div className="cat search-sel" key={index}>
                                <label>
                                  <input
                                    type="checkbox"
                                    checked={item.checked}
                                    onChange={(e) => handlePostedBy(e, index)}
                                  />
                                  <span>{item.name}</span>
                                </label>
                              </div>
                            ))}
                          </div>
                        }
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>


            </div>

            {/* end of filter section */}

            {/* card section start */}
          </Col>
          <Col sm={12} md={12} lg={9}>



            {yourData?.propertyType === "Commercial" ? (

              <CommercialFilter allPropertyData={commercialData} Location={propertyLocation} />
            ) : yourData?.propertyType === "Residential" ? (
              <ResidentialFilter allPropertyData={allPropertyData} Location={propertyLocation} />
            ) :
              <FilterNewProject allProjectData={AllProjectData} Location={propertyLocation} />
            }


          </Col>
          {/* <RecommendedProject/> */}
        </Row>

      </Container>

      {/* footer */}
      <Footer />
    </div>
  );
};

export default FilterProperties;
