// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css-1x77jbq {
    border: 1px solid rgb(229, 229, 229);
    background-color: rgb(255, 255, 255);
    border-radius: 4px;
    overflow: hidden;
    transition: all 0.5s ease 0s;
    cursor: pointer;
    position: relative;
    height: auto;
    display: flex;
    margin: 0px 20px 12px 0px;
    width: 328px;
    padding: 0px;
    min-height: 152px;
}

.css-1x77jbq .image {
    width: 136px;
    height: 152px;
}

.css-1x77jbq .info {
    width: calc(100% - 136px);
    padding-top: 15px;
}

.css-1x77jbq .info {
    width: calc(100% - 102px);
    padding: 5px 5px 5px 0px;
    margin-left: 12px;
    overflow: hidden;
}

.css-poh5ib {
    position: relative;
    padding: 10px 16px 16px;
}

.css-13o7eu2 {
    display: block;
}

.css-1x77jbq .developer,
.css-1x77jbq .address {
    font-size: 12.5px;
}

.css-1x77jbq .title {
    font-size: 12.5px;
}

.css-1x77jbq .developer,
.css-1x77jbq .address {
    font-size: 12.5px;
}

.css-1x77jbq .price {
    font-size: 14.5px;
}

.css-xyzbyw {
    font-size: 30.5px;
    line-height: 30px;
}

.css-s8hh0l {
    margin-top: 13px;
    line-height: 18px;
    color: #747474;
}`, "",{"version":3,"sources":["webpack://./src/Pages/BuilderProject/recommendedprojectCard.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,oCAAoC;IACpC,kBAAkB;IAClB,gBAAgB;IAChB,4BAA4B;IAC5B,eAAe;IACf,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,yBAAyB;IACzB,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;IACzB,wBAAwB;IACxB,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,uBAAuB;AAC3B;;AAEA;IACI,cAAc;AAClB;;AAEA;;IAEI,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;;IAEI,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,cAAc;AAClB","sourcesContent":[".css-1x77jbq {\n    border: 1px solid rgb(229, 229, 229);\n    background-color: rgb(255, 255, 255);\n    border-radius: 4px;\n    overflow: hidden;\n    transition: all 0.5s ease 0s;\n    cursor: pointer;\n    position: relative;\n    height: auto;\n    display: flex;\n    margin: 0px 20px 12px 0px;\n    width: 328px;\n    padding: 0px;\n    min-height: 152px;\n}\n\n.css-1x77jbq .image {\n    width: 136px;\n    height: 152px;\n}\n\n.css-1x77jbq .info {\n    width: calc(100% - 136px);\n    padding-top: 15px;\n}\n\n.css-1x77jbq .info {\n    width: calc(100% - 102px);\n    padding: 5px 5px 5px 0px;\n    margin-left: 12px;\n    overflow: hidden;\n}\n\n.css-poh5ib {\n    position: relative;\n    padding: 10px 16px 16px;\n}\n\n.css-13o7eu2 {\n    display: block;\n}\n\n.css-1x77jbq .developer,\n.css-1x77jbq .address {\n    font-size: 12.5px;\n}\n\n.css-1x77jbq .title {\n    font-size: 12.5px;\n}\n\n.css-1x77jbq .developer,\n.css-1x77jbq .address {\n    font-size: 12.5px;\n}\n\n.css-1x77jbq .price {\n    font-size: 14.5px;\n}\n\n.css-xyzbyw {\n    font-size: 30.5px;\n    line-height: 30px;\n}\n\n.css-s8hh0l {\n    margin-top: 13px;\n    line-height: 18px;\n    color: #747474;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
