import React from "react";
import "./propertyService.css";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
const PropertyService = () => {
  return (
    <>
    <div className="container content-box">
      <div className="content-title ">
        <div className="content-name">
          <h3>Property Services</h3>


          <div
            className=""


          >
            All Services
            <ArrowForwardIcon />
          </div>

        </div>
        <hr className="content-title-underline" />
      </div>
      <div className="service_home_container">
        <section className="service_home_section">
         
          <div className="service_props_service">
            <div className="service_props_slider">
              <div className="service_swiper">
                <div className="service_swiper_container">
                  <div className="service_swiper_wrapper">
                    <div className="service_swiper_slide">
                      <div className="service_card_shadow">
                        <a href='/genreceipt' className='service_href'>
                          <div className="service_card_graphics">
                            <img
                              className="custom-lazy"
                              data-src="https://cdn.staticmb.com/magicservicestatic/images/revamp/mbhome-web/property-services/graphic-zeroDp.jpg"
                              src="https://cdn.staticmb.com/magicservicestatic/images/revamp/mbhome-web/property-services/graphic-zeroDp.jpg"
                              width="294"
                              height="112"
                              alt=""
                            />
                          </div>
                          <div className='service_card_content'>
                            <div class="service_card_content_title">RECEIPT</div>
                          </div>

                        </a>
                      </div>

                      <div className="service_card_shadow">
                        <a href='/agreement' className='service_href' style={{ outline: "none" }}>
                          <div className="service_card_graphics">
                            <img
                              className="custom-lazy"
                              data-src="https://cdn.staticmb.com/magicservicestatic/images/revamp/mbhome-web/property-services/graphic-zeroDp.jpg"
                              src="https://cdn.staticmb.com/magicservicestatic/images/revamp/mbhome-web/property-services/graphic-rental-agreement.jpg"
                              width="294"
                              height="112"
                              alt=""
                            />
                          </div>
                          <div className='service_card_content'>
                            <div class="service_card_content_title">Agreement</div>
                          </div>

                        </a>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      </div>
    </>
  );
};

export default PropertyService;
