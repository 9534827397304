// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-cont {
    display: flex;
    align-items: center;
    /* min-height: 50vh; */
    /* background-color: antiquewhite; */
    padding: 0 10%;
}

.image-div {
    min-width: 60%;
}

.inner-image {
    width: 100%;
}

.full-image {
    width: 100%;
    height: auto;

}


.app-container {
    text-align: center;
}

.property-container {
    display: flex;
    justify-content: space-between;
    max-width: 800px;
    margin: 0 auto;
}

.property-box {
    flex: 1 1;
    border: 1px solid #ccc;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.horizontal-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.property-box {
    background-color: #fff;
    padding: 10px;
    border: 1px solid #ccc;
    transition: background-color 0.3s;
   
}

.check:hover {
    background-color: rgb(204, 253, 204);
    cursor: pointer;
   
}

.cross:hover {
    background-color: rgb(253, 169, 169);
    cursor: pointer;
}

.carousel-item img {
    height: 600px
}`, "",{"version":3,"sources":["webpack://./src/Components/Common/ViewMore.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,oCAAoC;IACpC,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;IACX,YAAY;;AAEhB;;;AAGA;IACI,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,SAAO;IACP,sBAAsB;IACtB,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,6BAA6B;AACjC;;AAEA;IACI,sBAAsB;IACtB,aAAa;IACb,sBAAsB;IACtB,iCAAiC;;AAErC;;AAEA;IACI,oCAAoC;IACpC,eAAe;;AAEnB;;AAEA;IACI,oCAAoC;IACpC,eAAe;AACnB;;AAEA;IACI;AACJ","sourcesContent":[".main-cont {\n    display: flex;\n    align-items: center;\n    /* min-height: 50vh; */\n    /* background-color: antiquewhite; */\n    padding: 0 10%;\n}\n\n.image-div {\n    min-width: 60%;\n}\n\n.inner-image {\n    width: 100%;\n}\n\n.full-image {\n    width: 100%;\n    height: auto;\n\n}\n\n\n.app-container {\n    text-align: center;\n}\n\n.property-container {\n    display: flex;\n    justify-content: space-between;\n    max-width: 800px;\n    margin: 0 auto;\n}\n\n.property-box {\n    flex: 1;\n    border: 1px solid #ccc;\n    padding: 10px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.horizontal-grid {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: space-around;\n}\n\n.property-box {\n    background-color: #fff;\n    padding: 10px;\n    border: 1px solid #ccc;\n    transition: background-color 0.3s;\n   \n}\n\n.check:hover {\n    background-color: rgb(204, 253, 204);\n    cursor: pointer;\n   \n}\n\n.cross:hover {\n    background-color: rgb(253, 169, 169);\n    cursor: pointer;\n}\n\n.carousel-item img {\n    height: 600px\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
