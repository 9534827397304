import React, { useState } from "react";
import RadioButtonGroup from "../../../../Components/Common/RadioButtonGroup";

// components
import { Unstable_NumberInput as NumberInput } from "@mui/base/Unstable_NumberInput";
import { styled } from "@mui/system";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import WindPowerIcon from "@mui/icons-material/WindPower";
import EmojiObjectsIcon from "@mui/icons-material/EmojiObjects";
import LocalLaundryServiceIcon from "@mui/icons-material/LocalLaundryService";
import ChairIcon from "@mui/icons-material/Chair";
import BedroomParentIcon from "@mui/icons-material/BedroomParent";
import TableRestaurantIcon from "@mui/icons-material/TableRestaurant";
import HotTubIcon from "@mui/icons-material/HotTub";
import GasMeterIcon from "@mui/icons-material/GasMeter";

export default function FurnishingDetails({
  onChangeHandler,
  state,
  stateError,
}) {
  const property = [
    { label: "UnFurnished", value: "UnFurnished" },
    { label: "Semi-Furnished", value: "Semi-Furnished" },
    { label: "Furnished", value: "Furnished" },
  ];

  const [selItem, setSelItem] = useState("");

  const FurnishingItem = [
    { icon: <WindPowerIcon />, value: "AC" },
    {
      icon: <LocalLaundryServiceIcon />,
      value: "WashingMachine",
    },
    { icon: <ChairIcon />, value: "Sofa" },
    { icon: <BedroomParentIcon />, value: "Bed" },
    { icon: <WindPowerIcon />, value: "Wardrobe" },
    { icon: <TableRestaurantIcon />, value: "Table" },
    { icon: <HotTubIcon />, value: "Geyser" },
    { icon: <GasMeterIcon />, value: "Stove" },
    {
      icon: <WindPowerIcon />,
      value: "Fans",
    },
    { icon: <EmojiObjectsIcon />, value: "Tubelights" },
  ];

  // Filter items based on the selected option
  const filteredItems = FurnishingItem.filter((item) => {
    if (selItem === "UnFurnished") {
      return item.value === "Fans" || item.value === "Tubelights";
    } else if (selItem === "Semi-Furnished") {
      // Add other conditions for Semi-furnished option if needed
      return true; // Include all items for Semi-furnished for now
    } else if (selItem === "Furnished") {
      // Add other conditions for Furnished option if needed
      return true; // Include all items for Furnished for now
    }
    return false;
  });
  const handleFurnishingItemChange = (itemName, newValue) => {
    // Assuming onChangeHandler takes the property name and new value as arguments
    // onChangeHandler(item[itemName], newValue);
    onChangeHandler("furnishingItem", {
      ...state.furnishingItem,
      [itemName]: newValue,
    });
  };
  return (
    <>
      <div className="mt-4 p-2">
        <h2>Furnishing Status</h2>

        <RadioButtonGroup
          groupName="furnishedStatus"
          options={property}
          onChangeHandler={(group, value) => {
            onChangeHandler(group, value);
            setSelItem(value);
          }}
        />
        <div style={{ color: "red", fontSize: 13 }}>
          {stateError.furnishedStatus}
        </div>
      </div>

      <div>
        <h5> Adding Furnishing items are optional</h5>
        <div className="mt-3 p-2">
          {filteredItems.map((list, i) => {
            return (
              <div className="row p-2">
                <div className="col-sm-4">
                  <span className="icon" style={{ marginRight: "8px" }}>
                    {list.icon}
                  </span>
                  <span className="value">{list.value}</span>
                </div>
                <div className="col-sm-4">
                  <NumberInput
                    slots={{
                      root: StyledInputRoot,
                      input: StyledInput,
                      incrementButton: StyledButton,
                      decrementButton: StyledButton,
                    }}
                    slotProps={{
                      incrementButton: {
                        children: <AddIcon fontSize="small" />,
                        className: "increment",
                      },
                      decrementButton: {
                        children: <RemoveIcon fontSize="small" />,
                      },

                      input: {
                        // this exactly the same as onInputChange above
                        onChange: (event) => {
                          handleFurnishingItemChange(
                            list.value,
                            Number(event.target.value)
                          );
                        },
                      },
                    }}
                    aria-label="Quantity Input"
                    min={0}
                    max={99}
                    value={state.furnishingItem[list.value]}
                    defaultValue={0}
                    onChange={(event, newValue) => {
                      handleFurnishingItemChange(list.value, newValue);
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

const blue = {
  100: "#daecff",
  200: "#b6daff",
  300: "#66b2ff",
  400: "#3399ff",
  500: "#007fff",
  600: "#0072e5",
  700: "#0059B2",
  800: "#004c99",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const StyledInputRoot = styled("div")(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 400;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[500]};
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
`
);

const StyledInput = styled("input")(
  ({ theme }) => `
  font-size: 0.875rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.375;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  box-shadow: 0px 2px 4px ${
    theme.palette.mode === "dark" ? "rgba(0,0,0, 0.5)" : "rgba(0,0,0, 0.05)"
  };
  border-radius: 8px;
  margin: 0 8px;
  padding: 10px 12px;
  outline: 0;
  min-width: 0;
  width: 4rem;
  text-align: center;

  &:hover {
    border-color: ${blue[400]};
  }

  &:focus {
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${
      theme.palette.mode === "dark" ? blue[700] : blue[200]
    };
  }

  &:focus-visible {
    outline: 0;
  }
`
);

const StyledButton = styled("button")(
  ({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  line-height: 1.5;
  border: 1px solid;
  border-radius: 999px;
  border-color: ${theme.palette.mode === "dark" ? grey[800] : grey[200]};
  background: ${theme.palette.mode === "dark" ? grey[900] : grey[50]};
  color: ${theme.palette.mode === "dark" ? grey[200] : grey[900]};
  width: 32px;
  height: 32px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  &:hover {
    cursor: pointer;
    background: ${theme.palette.mode === "dark" ? blue[700] : blue[500]};
    border-color: ${theme.palette.mode === "dark" ? blue[500] : blue[400]};
    color: ${grey[50]};
  }

  &:focus-visible {
    outline: 0;
  }

  &.increment {
    order: 1;
  }
`
);
