import React, { useEffect, useState } from "react";
// mui packages and bootstrap
import Swal from "sweetalert2";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import { Container } from "react-bootstrap";
import { Modal } from "react-bootstrap";
//icons
import "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { IconButton } from "@mui/material";
import { MdDoubleArrow } from "react-icons/md";
import { MdOutlineKeyboardDoubleArrowLeft } from "react-icons/md";
//custom components
import Loader from "../../Components/Common/Loader";
import AdminSidebar from "../../Components/Sidebar/AdminSidebar";
//api
import { getAllRentReceiptId } from "../../Api/RentpropertyAPI";


const ReceiptAdmin = () => {
  const [allReceiptData, setAllReceiptData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [viewPaymentModal, setViewPaymentModal] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    getAllReceipt();
  }, []);

  const getAllReceipt = () => {
    setLoading(true)
    getAllRentReceiptId()
      .then((res) => {
        setLoading(false)
        if (res.status === 200) {
          let receiptData = res.data.rentReceiptDetails;
          setAllReceiptData(receiptData);
        } else {
          Swal.fire({
            icon: "warning",
            title: "Data not found",
            showConfirmButton: true,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "ok",
          });
        }
      })
      .catch((err) => {
        console.log(err, "error in rent receipt");
      });
  };

  const columns = [
    { id: "Sr No.", label: "Sr No.", minWidth: 10 },
    { id: "Owner Name", label: "ownerName" },
    { id: "Tenant Name", label: "tenantName" },
    { id: "receiptNo", label: "Receipt No" },
    { id: "Date", label: "Date" },
    { id: "Rent Amount", label: "rentAmount" },
    {
      id: "view",
      label: "View Details",
      minWidth: 100,
    },
  ];

  function createData(
    id,
    ownerName,
    ownerEmail,
    tenantName,
    tentantEmail,
    ownerPanNo,
    Address,
    recieptFromDate,
    recieptToDate,
    rentAmount
  ) {
    return {
      id,
      ownerName,
      ownerEmail,
      tenantName,
      tentantEmail,
      ownerPanNo,
      Address,
      recieptFromDate,
      recieptToDate,
      rentAmount,
    };
  }

  const filterData = (data, searchTerm) => {
    return data.filter((item) => {
      return (
        item.ownerName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.ownerEmail.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.tenantName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.tentantEmail.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.ownerPanNo.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.Address.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.recieptFromDate.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.recieptToDate.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.rentAmount.toString().toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
  };

  const filteredReceiptData = filterData(allReceiptData, searchTerm);

  const rows = allReceiptData.map((list, i) => {
    return createData(
      i + 1,
      list.ownerNameownerEmail,
      list.tenantName,
      list.tentantEmail,
      list.ownerPanNo,
      list.Address,
      list.recieptFromDate,
      list.recieptToDate,
      list.rentAmount
    );
  });

  const OnView = (paymentData, i) => {
    setViewPaymentModal(true);
    setPaymentDetails(paymentData);
  }
  const handleClose = () => {
    setViewPaymentModal(false);
  }

  return (
    <>
      <Container>
        <AdminSidebar />

        <div className="topNav-m">
          <div className=" content-title">
            <h3>Payment Details</h3>
            <hr className="content-title-underline" />
          </div>
          <div className="d-flex justify-content-end mb-2">
            <input
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearchChange}
              style={{ padding: "5px", width: "250px" }}
            />
          </div>
          <Paper>
            {loading && <Loader />}
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow></TableRow>
                  {columns.map((column) => (
                    <>
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    </>
                  ))}
                </TableHead>
                <TableBody>

                  {filteredReceiptData?.length > 0 ?

                    filteredReceiptData
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((data, i) => {
                        const serialNumber = page * rowsPerPage + i + 1;
                        return (
                          <TableRow key={i}>
                            <TableCell>{serialNumber}</TableCell>
                            <TableCell>{data.ownerName}</TableCell>
                            <TableCell>{data.tenantName}</TableCell>
                            <TableCell>
                              {data.receiptNo ? data.receiptNo : "-"}
                            </TableCell>
                            <TableCell>{data.recieptFromDate}</TableCell>
                            <TableCell>{data.rentAmount}</TableCell>
                            <TableCell>
                              <IconButton>
                                <VisibilityIcon
                                  color="primary"
                                  onClick={() => OnView(data, i)}
                                />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    :
                    <TableRow style={{ textAlign: "center" }}>
                      Data not found
                    </TableRow>
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>

        {paymentDetails && (
          <Modal show={viewPaymentModal} onHide={handleClose} centered className="modal-lg ">
            <Modal.Header closeButton className="headerBlue">
              <Modal.Title className="loginTitle">
                <h5 className="m-0">Payment Details</h5>
              </Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <div className="pl-5 pr-5  pb-4 d-flex">
                <>
                  <div className="modal-enquiry-details">
                    <div className="transactionData ">
                      <div className="transactionDataTitle">Owner Email : </div>
                      <div className="transactionDataValue">
                        {paymentDetails?.ownerEmail}
                      </div>
                    </div>
                    <div className="transactionData">
                      <div className="transactionDataTitle">Tenant Email : </div>
                      <div className="transactionDataValue">
                        {paymentDetails?.tentantEmail}
                      </div>
                    </div>
                    <div className="transactionData ">
                      <div className="transactionDataTitle">Address : </div>
                      <div className="transactionDataValue">
                        {paymentDetails?.Address}
                      </div>
                      {/* <div className="transactionDataValue">
                {expandedComments[paymentIndex]
                            ? paymentDetails?.Address
                            : `${paymentDetails?.Address.substring(0, 50)}`}
                          {paymentDetails?.Address.length > 50 && (
                            <a
                              href="#!"
                              onClick={() => handleToggleExpand(paymentIndex)}
                              style={{
                                marginLeft: "5px",
                                color: "blue",
                                cursor: "pointer",
                              }}
                            >
                              {expandedComments[paymentIndex]? <MdOutlineKeyboardDoubleArrowLeft fontSize={18} fontWeight={700}/> : <MdDoubleArrow fontSize={18}/>}
                            </a>
                          )}
                </div> */}
                    </div>
                    <div className="transactionData ">
                      <div className="transactionDataTitle">PAN Number : </div>
                      <div className="transactionDataValue">
                        {paymentDetails?.ownerPanNo}
                      </div>
                    </div>
                    <div className="transactionData ">
                      <div className="transactionDataTitle">Receipt Id : </div>
                      <div className="transactionDataValue">
                        {paymentDetails?.receiptId}
                      </div>
                    </div>

                    <div className="transactionData ">
                      <div className="transactionDataTitle">From Date : </div>
                      <div className="transactionDataValue">
                        {paymentDetails?.recieptFromDate}
                      </div>
                    </div>

                    <div className="transactionData ">
                      <div className="transactionDataTitle">To Date : </div>
                      <div className="transactionDataValue">
                        {paymentDetails?.recieptToDate}
                      </div>
                    </div>
                  </div>
                </>
              </div>
            </Modal.Body>
          </Modal>
        )}
      </Container>
    </>
  );
};

export default ReceiptAdmin;
