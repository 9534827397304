import React, { useEffect, useRef, useState } from "react";
import "./Agreement.css";
import Stamp from "../../../Assets/Stamp.jpg";
import moment from "moment";
import html2canvas from "html2pdf.js";
import jsPDF from "jspdf";

function numberToWords(num) {
  const belowTwenty = ["", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine", "Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];
  const tens = ["", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];
  const units = ["", "Thousand", "Lakh", "Crore"];

  if (num === 0) return "Zero";

  function helper(n) {
      if (n < 20) return belowTwenty[n];
      else if (n < 100) return tens[Math.floor(n / 10)] + (n % 10 ? " " + belowTwenty[n % 10] : "");
      else return belowTwenty[Math.floor(n / 100)] + " Hundred" + (n % 100 ? " " + helper(n % 100) : "");
  }

  let word = "";
  let unitIndex = 0;

  while (num > 0) {
      let chunk;
      if (unitIndex === 1 || unitIndex === 2) { 
          chunk = num % 100;
          num = Math.floor(num / 100);
      } else { 
          chunk = num % 1000;
          num = Math.floor(num / 1000);
      }
      if (chunk !== 0) {
          word = helper(chunk) + (units[unitIndex] ? " " + units[unitIndex] : "") + (word ? " " + word : "");
      }
      unitIndex++;
  }

  return word.trim();
}


export default function Preview(props) {
  const mRentAmountInWords = numberToWords(props?.state?.MRentAmount);
  const securityDepositInWords = numberToWords(props?.state?.securityDeposit);
    console.log( props?.state , "props..djkagreement..");
  const pdfRef = useRef();
  const [date, setDate] = useState();
  const [getDates ,setGetDate ] = useState();
  const [getRegistrationDate , setGetRegistrationDate] = useState();
  const [getaggrStartDate , setGetaggrStartDate] = useState();

  const formatDate = () => {
    const date = new Date();
    const options = { day: "numeric", month: "long", year: "numeric" };
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      date
    );
    return formattedDate;
  };
  useEffect(() => {
    setDate(formatDate());
  }, []);

  let getDate = props?.state?.aggrStartDate
    ? (props?.state?.aggrStartDate).toString()
    : null;
  const inputString = props?.state?.rentPaymentDate
    ? props?.state?.rentPaymentDate
    : null;
  const match = inputString ? inputString.match(/\d+(st|nd|rd|th)/) : null;
console.log(props?.state,"props?.state");


const getFormattedDateTime = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${day}-${month}-${year}`;
};

useEffect(() => {
  if(props?.state?.aggrEndDate){
    const modDate  =  getFormattedDateTime(props?.state?.aggrEndDate)
    setGetDate(modDate);
    console.log(modDate,"mod");
    
  }
 if (props?.state?.aggrRegistrationDate) {
  const modDateaggrRegistrationDate = getFormattedDateTime(props?.state?.aggrRegistrationDate)
  setGetRegistrationDate(modDateaggrRegistrationDate);
  console.log(modDateaggrRegistrationDate,"modDateaggrRegistrationDate");
  
 } 
  if (props?.state?.aggrStartDate ){
    const modDateaggrStartDate = getFormattedDateTime(props?.state?.aggrStartDate) 
    setGetaggrStartDate(modDateaggrStartDate)
    console.log(modDateaggrStartDate,"modDateaggrStartDate");
    
 }
  
}, [props?.state]);




  return (
    <>
   
      <div className="preview-container">     
        {/* <div>
          <img src={Stamp} alt="stamp" className="stamp-image" />
        </div> */}
        <div >
          THIS AGREEMENT is executed at <b>{props?.state?.licensorLocation ? props?.state?.licensorLocation :" ______ "}</b> this day,
          the <b>{props?.state?.aggrRegistrationDate ? getRegistrationDate : " ______ "}</b> between <b>{props?.state?.licensorTitle ? props?.state?.licensorTitle  : " ______ "}. {props?.state?.licensorName ? props?.state?.licensorName :" ______ "}</b> owner of {" "}
          <b>{props?.state?.licensorAddress ? props?.state?.licensorAddress: " ______ "}, {props?.state?.licensorSubLocation ? props?.state?.licensorSubLocation : " ______ "}, {props?.state?.licensorLocation ? props?.state?.licensorLocation : " ______ "}, {props?.state?.licensorPinCode ? props?.state?.licensorPinCode :" ______ "}</b>.
          (hereinafter called "the Licensor") of the First Part which expression shall unless it be
          repugnant to the context or meaning thereof mean and include their respective
          heirs, executors, administrators and assigns and <b>{props?.state?.LicenseeTitle ? props?.state?.LicenseeTitle: " ______ "}. {props?.state?.LicenseeName ? props?.state?.LicenseeName: " ______ "}</b> (hereinafter called “the Licensee”)
          residing at  <b>{props?.state?.LicenseeAddress ? props?.state?.LicenseeAddress : " ______ "}, {props?.state?.LicenseeSubLocation ? props?.state?.LicenseeSubLocation :" ______ "}, {props?.state?.LicenseeLocation ? props?.state?.LicenseeLocation :" ______ "}, {props?.state?.LicenseePinCode ? props?.state?.LicenseePinCode : " ______ "}</b>.
          which expression shall mean and include legal heirs, successor, legal representatives executors,
          nominee and assignees of the OTHER PART.
        </div>
        <br></br>
        <div>
          WHEREAS the Licensor has declared that he is the owner and in absolute possession of  <b>{props?.state?.propertyAddress ? props?.state?.propertyAddress : " ______ "}, {props?.state?.propertySubLocation ? props?.state?.propertySubLocation :" ______ "}, {props?.state?.propertyLocation ? props?.state?.propertyLocation: " ______ "}, {props?.state?.propertyPinCode ? props?.state?.propertyPinCode: " ______ "}</b>.
          having an area of <b> {props?.state?.squareFeet ? props?.state?.squareFeet: " ______ "} sq.feet </b> Super – comprising of <b> {props?.state?.noOfBedroom}</b> Bed Rooms with, <b> {props?.state?.noOfBathroom}</b> Common Bath Room and Toilet, <b>{props?.state?.drawingdinningroom}</b> drawing-dining room, <b>{props?.state?.kitchenroom}</b> kitchen,
          (Which flat is hereinafter referred to as "the Licensed Premises") bearing the <b> C.S.NO.{props?.state?.csNo ? props?.state?.csNo : " ______ "} </b> of <b>{props?.state?.propertySubLocation ? props?.state?.propertySubLocation :" ______ "}</b> Sewree Division and that the said premises is free from all encumbrances and that the Licensor has an absolute right,
          title and interest in the said premises including the right to give on Leave and License basis.
        </div>
        <br></br>
        <div>
          AND WHEREAS the Licensee being temporarily in need of premises for residential accommodation has approached the Licensor and requested the Licensor to enter into a Leave and License Agreement for residential use of the Licensed Premises by him & his family.
        </div>
        <br></br>
        <div>
          AND WHEREAS it has been arranged that the Licensee shall with the Leave and License of the Licensor have the rights to use the licensed premises upon the terms and conditions hereinafter mentioned.
        </div>
        <br></br>
        <div>
          <b>NOW IT IS HEREBY AGREED by and between the parties hereto as follows:</b>
        </div>
        <br></br>
        <div>
          1. The <b>{props?.state?.licensorName ? props?.state?.licensorName :" ______ "}</b> agree to lease to the <b>{props?.state?.LicenseeName ? props?.state?.LicenseeName: " ______ "}</b>, THE PREMISES regarding which the <b>{props?.state?.LicenseeName ? props?.state?.LicenseeName: " ______ "}</b> has been duly satisfied themselves, for a period commencing on <b>{props?.state?.aggrStartDate ? getaggrStartDate : " ______ "}</b> for a term of <b>{props?.state?.agreementDuration} </b> ending on <b>{props?.state?.aggrEndDate ? getDates : " ______ "}</b> for the monthly rent of {props?.state?.MRentAmount ? <b>{props?.state?.MRentAmount}/- (Rupees {mRentAmountInWords} Only).</b> :  " ______ "}The Licensee will enhance the rent by <b style={{ color: "red" }}>{props?.state?.rentpercentage}%</b> after the expiry of <b>{props?.state?.agreementDuration} </b> on the non-deposit portion  The <b>{props?.state?.LicenseeName ? props?.state?.LicenseeName: " ______ "}</b> shall have a right to use and enjoy in common with other residents entitled thereto the entrances, staircases, landings, corridors and passages in the building and the right of ingress to and engress from the <b>PREMISES</b> so far as the same are necessary for the enjoyment of the PREMISES by the <b>{props?.state?.LicenseeName ? props?.state?.LicenseeName: " ______ "}.</b>
        </div>
        <br></br>
        <div>
          2. The Licensee have the right to terminate the agreement by giving a notice of termination of the period of one month at least, and or in lieu of this notice it would become liable to pay and surrender an amount equal to one month rent. However in no circumstances the Licensee will vacate the said premises on or before <b>{props?.state?.noticePeriod} </b> i.e 6 months initial lock in period + 1 months notice period.  However after <b>{props?.state?.noticePeriod}</b> either of the party has the option to terminate the agreement after giving one months notice.
        </div>
        <br></br>
        <div>
          3. For the due performance of the terms of this agreement, the Licensee shall also deposit a <b>sum of Rs.</b> {props?.state?.securityDeposit ? <b>{props?.state?.securityDeposit}/- (Rupees {securityDepositInWords} Only).</b> :  " ______RS "}. The complete {props?.state?.securityDeposit ? <b>{props?.state?.securityDeposit}/- (Rupees {securityDepositInWords} Only).</b> :  " ______RS "}.  shall be refunded by the Licensor to the Licensee without interest only after handing over peaceful and vacant possession of the said premises, in accordance with this agreement, in perfect condition & after deducting dues, if any, incurred during the occupation of the flat by the Licensee.
        </div>
        <br></br>
        <div>
          4. The Licensee shall pay to the Licensor a monthly rent of <b> Rs{props?.state?.MRentAmount}/- </b>{mRentAmountInWords ? mRentAmountInWords : null} per month for the permissive use of the said flat.  The said monthly compensation shall be paid by the Licensee on or before the 15th day of each month. Monthly rent receipt for the above monthly rent shall be provided by the Licensor to the Licensee.
        </div>
        <br></br>
        <div>
          5. The Licensee further agrees and undertakes to pay all utilities i.e. <b style={{ color: "red" }}>electricity, gas, telephone bills </b> etc. & any other incidental charges consumed in the occupancy of the Licensee after the receipt of the bill from the concerned authorities without any delay or default. If any of the utilities supply gets disconnected due to non-payment or delay in payment or misuse, then the Licensee shall be bound to get the same restored instantly at his own cost.
        </div>
        <br></br>
        <div>
          6. The Licensee shall not make any alterations, modifications or 	additions in or to the licensed premises without prior permission in writing of the Licensor.
        </div>
        <br></br>
        <div>
          7. A duplicate key of the main entrance door of the licensed premises shall be given by the Licensor to licensee for his convenience. The Licensee shall not alter or suffer to be altered the existing locking devices on the said main entrance door of the licensed premises without prior written permission of the Licensor.
        </div>
        <br></br>
        <div>
          8. In the event of the License committing any breach of the terms and conditions herein contained and failing within 15 days to remedy or make good such breach on receipt of notice in writing from the Licensor specifying the breach complained of the Licensor shall be entitled to revoke this license in which event the Licensee shall vacate and give charge of the licensed premises to the Licensor within 15 days of such revocation.
        </div>
        <br></br>
        <div>
          9. It is agreed by and between the parties hereto that earlier expiration or earlier determination for any reason whatsoever of the said License Agreement the Licensor shall refund (but without interest) the said <b style={{ color: "red" }}>sum of Rs. 150000/-</b>(Rupees One Lack Fifty Thousand Only). to the Licensee after the Licensee vacate the Licensed Premises along with his articles, goods and chattels and giving charge thereof to the Licensor after deducting there from any unpaid amount due and payable by the Licensee in respect of electricity, water, gas, and telephone charges subject to proper verification of the building condition.
        </div>
        <br></br>
        <div>
          10. If applicable, the Licensor will obtain and NOC (No Objection Certificate) from the Society for giving on Leave and License the said licensed premises.
        </div>
        <br></br>
        <div>
          11. The Licensor shall observe and perform all the terms, conditions, Agreements, covenants and provisions on which the Licensor holds the Licensed premises and shall also observe and perform the Rules, Regulations and Bye-laws for the time being and from time to time in force of the said licensed premises and shall discharge all his liabilities (including rates, taxes, assessments, maintenance and other outgoings and non-occupancy charges) and all other amounts payable in respect of the licensed premises to the society or any other authority.
        </div>
        <br></br>
        <div>
          12. The Licensee hereby agrees and confirms that he shall use the licensed premises with due care and caution and observe the terms, conditions and provisions contained in this Agreement  in so far as they relate to the use of the licensed premises and shall not claim any tenancy sub-tenancy or other right or interest therein.
        </div>
        <br></br>
        <div>
          13. Nothing herein contained shall be construed as creating any right, interest, easement, tenancy or sub- tenancy in favour of the Licensee or over or upon the licensed premise or transferring any interest therein in favour of the Licensee other than the permissive right of use hereby granted.

          The Licensor shall be dejure in exclusive right of use hereby granted. The Licensor shall be dejure in exclusive possession and full charge and control of the licensed premises at all times which the de-fasto possession shall remain with the License. It is on the faith of the express assurance given and representations made by the Licensee as above and believing the same to be true that the Licensor has in good faith entered into this License Agreement with the Licensee.
        </div>
        <br></br>
        <div>
          14. Not to bring or store in or about the said flat any goods or merchandise of the hazardous or combustible nature or objectionable prohibited materials.
        </div>
        <br></br>
        <div>
          15. Not to use the premises for any purpose other than the specified one.
        </div>
        <br></br>
        <div>
          16. Not to object to or obstruct the Licensor from inspecting the said flat after fixing a prior appointment in that behalf.
        </div>
        <br></br>
        <div>
          17. Not to hold the Licensor responsible for any loss or damage suffered by the Licensee on account of manmade or natural calamities, disasters, theft, fire or other destruction caused to or in the Licensed premises.
        </div>
        <br></br>
        <div>
          18. Keep the Licensor always indemnified against any demand or demands or loss or damage and incidental costs, charges and expenses that may result on account of the negligence, default or any act of omission or commission on the part of the licensee and /or his servants in the said flat.
        </div>
        <br></br>
        <div>
          19. The Licensor shall not be responsible or liable for any loss or injury caused due to any reasons whatsoever in the said flat or in the said building to the Licensor or persons visiting the said flat whether voluntarily or at the invitation of the Licensee.
        </div>
        <br></br>
        <div>
          20. No pets will be allowed in the Apartment.
        </div>
        <br></br>
        <div>
          21. The Licensee shall pay to the cable TV & Internet charges used by him.
        </div>
        <br></br>
        <div>
          22. The parties hereto acknowledge, declare and confirm that this Agreement represents the entire Agreement between them regarding the subject matter hereof and no alterations, additions or modifications hereto shall be valid and binding unless the same are reduced in writing and signed by both the parties.
        </div>
        <br></br>
        <div>
          23. The cost of registration shall be borne equally by both parties.
        </div>
        <br></br>
        <div>
          24. The Cost of stamp Paper shall be borne equally by both parties.
        </div>
        <br></br>
        <div>
          25. Disputes, if any, shall be settled under Mumbai jurisdiction only.
        </div>
        <br></br>
        <div>
          26. Licensee cannot obtain ration card on Licensed premise.
        </div>
        <br></br>
        <div>
          <b>IN WITNESS WHEREOF the parties hereto have executed this Agreement (in duplicate) the day and year first written. </b>
        </div>
        <br></br>
        <div>
          <b>
            SIGNED & DELIVERED by <br></br>

            the within named Licensor <br></br>

            Mr. Pankaj Kadam <br></br>

            in the presence of <br></br>

            1)							<br></br>
            2)						  <br></br>
          </b>
        </div>
        <br></br>
        <div>
          <b>
          SIGNED & DELIVERED FOR AND <br></br>

          the within named Licensee <br></br>

          Mr. Mithilesh Bandivdekar		 <br></br>

          In the presence of 			<br></br>

          1)							<br></br>

          2)							<br></br>
          </b>
        </div>
      </div>

    </>
  );
}

{/* <div className="preview-container">
<div>
  <img src={Stamp} alt="stamp" className="stamp-image" />
</div>
<div>
  This agreement made on this {date} between
  <span className="field-text">
    {props?.state?.ownerName !== ""
      ? ` ${props?.state?.ownerName} `
      : " ______ "}
  </span>
  , with as Mobile Number
  <span className="field-text">
    {" "}
    {props?.state?.ownerMobileNo !== ""
      ? props?.state?.ownerMobileNo
      : " ______ "}
  </span>{" "}
  , residing at
  {props?.state?.ownerAddress !== "" ||
  props?.state?.ownerCity !== "" ||
  props?.state?.ownerState !== "" ||
  props?.state?.ownerPinCode !== "" ? (
    <>
      <span className="field-text">
        {props?.state?.ownerAddress !== ""
          ? ` ${props?.state?.ownerAddress} , `
          : ""}
      </span>
      <span className="field-text">
        {props?.state?.ownerCity !== ""
          ? ` ${props?.state?.ownerCity} , `
          : ""}
      </span>
      <span className="field-text">
        {props?.state?.ownerState !== ""
          ? ` ${props?.state?.ownerState} , `
          : ""}
      </span>
      <span className="field-text">
        {props?.state?.ownerPinCode !== ""
          ? ` ${props?.state?.ownerPinCode} , `
          : ""}
      </span>
    </>
  ) : (
    " ______ "
  )}{" "}
  hereinafter referred to as the 'LESSOR' of the One Part AND
  <span className="field-text">
    {" "}
    {props?.state?.tenantName !== ""
      ? props?.state?.tenantName
      : " ______ "}
  </span>{" "}
  with
  <span className="field-text">
    {" "}
    {props?.state?.tenantMobileNo !== ""
      ? props?.state?.tenantMobileNo
      : " ______ "}
  </span>{" "}
  as Mobile Number , residing at
  {props?.state?.tenantAddress !== "" ||
  props?.state?.tenantCity !== "" ||
  props?.state?.tenantState !== "" ||
  props?.state?.tenantPinCode !== "" ? (
    <>
      <span className="field-text">
        {props?.state?.tenantAddress !== ""
          ? ` ${props?.state?.tenantAddress} , `
          : ""}
      </span>
      <span className="field-text">
        {props?.state?.tenantCity !== ""
          ? ` ${props?.state?.tenantCity} , `
          : ""}
      </span>
      <span className="field-text">
        {props?.state?.tenantState !== ""
          ? ` ${props?.state?.tenantState} , `
          : ""}
      </span>
      <span className="field-text">
        {props?.state?.tenantPinCode !== ""
          ? ` ${props?.state?.tenantPinCode} , `
          : ""}
      </span>
    </>
  ) : (
    " ______ "
  )}{" "}
  hereinafter referred to as the 'LESSEE(s)' of the other Part;
  <br />
  <br />
  WHEREAS the Lessor is the lawful owner of, and otherwise well
  sufficiently entitled to
  {props?.state?.propertyAddress !== "" ||
  props?.state?.propertyCity !== "" ||
  props?.state?.propertyState !== "" ||
  props?.state?.propertyPinCode !== "" ? (
    <>
      <span className="field-text">
        {props?.state?.propertyAddress !== ""
          ? ` ${props?.state?.propertyAddress} , `
          : ""}
      </span>
      <span className="field-text">
        {props?.state?.propertyCity !== ""
          ? ` ${props?.state?.propertyCity} , `
          : ""}
      </span>
      <span className="field-text">
        {props?.state?.propertyState !== ""
          ? ` ${props?.state?.propertyState} , `
          : ""}
      </span>
      <span className="field-text">
        {props?.state?.propertyPinCode !== ""
          ? ` ${props?.state?.propertyPinCode} , `
          : ""}
      </span>
    </>
  ) : (
    " ______ "
  )}
  and comprising of
  <span className="field-text">
    {props?.state?.room !== "" ? ` ${props?.state?.room} ` : " ______ "}
  </span>{" "}
  including
  
  {props?.state?.noOfBedroom !== null ||
  props?.state?.noOfBathroom !== null ||
  props?.state?.noOfBalcony !== null ? (
    <>
      <span className="field-text">
        {props?.state?.noOfBedroom !== null
          ? ` ${props?.state?.noOfBedroom} Bedroom , `
          : ""}
      </span>
      <span className="field-text">
        {props?.state?.noOfBathroom !== null
          ? ` ${props?.state?.noOfBathroom} Bathroom , `
          : ""}
      </span>
      <span className="field-text">
        {props?.state?.noOfBalcony !== null
          ? ` ${props?.state?.noOfBalcony} Balcony `
          : ""}
      </span>
    </>
  ) : (
    " ______ "
  )}
  present in Floor{" "}
  <span className="field-text">
    {props?.state?.selectFloor !== ""
      ? props?.state?.selectFloor
      : "______ "}
  </span>
  <span className="field-text">
    {props?.state?.parkingSpace === "YES" ||props?.state?.parkingSpace === true
      ? " With Parking "
      : props?.state?.parkingSpace === "NO" ||props?.state?.parkingSpace === false  
      ? " Without Parking "
      : ""}
  </span>
  <span>
    {" "}
    {props?.state?.squareFeet !== "" ? "with an Area of " : ""}
    <span className="field-text">
      {props?.state?.squareFeet !== ""
        ? `${props?.state?.squareFeet} Square Feet `
        : ""}
    </span>
  </span>
  hereinafter referred to as the 'said premises'.
  <br />
  <br />
  AND WHEREAS at the request of the Lessee, the Lessor has agreed to let
  the said premises to the tenant for a term of
  <span className="field-text">
    {" "}
    {props?.state?.agreementDuration !== ""
      ? props?.state?.agreementDuration
      : " ______ "}{" "}
  </span>
  commencing from{" "}
  <span className="field-text">
    {props?.state?.aggrStartDate !== null
      ? moment(getDate).format("DD/MM/YYYY")
      : " ______ "}
  </span>{" "}
  in the manner hereinafter appearing.
  <br />
  <br />
  NOW THIS AGREEMENT WITNESSETH AND IT IS HEREBY AGREED BY AND BETWEEN
  THE PARTIES AS UNDER:
  <br />
  <br />
  That the Lessor hereby grant to the Lessee, the right to enter and use
  and remain in the said premises along with the existing fixtures and
  fittings listed in Annexure 1 to this Agreement and that the Lessee
  shall be entitled to peacefully possess and enjoy possession of the
  said premises for{" "}
  <span className="field-text">
    {props?.state?.property !== null
      ? props?.state?.property
      : "______ "}
  </span>{" "}
  use, and the other rights herein.
  <br />
  <br />
  That the lease hereby granted shall, unless cancelled earlier under
  any provision of this Agreement, remain in force for a period of{" "}
  <span className="field-text">
    {" "}
    {props?.state?.agreementDuration !== ""
      ? props?.state?.agreementDuration
      : " ______ "}{" "}
  </span>
  <br />
  <br />
  That the Lessee will have the option to terminate this lease by giving{" "}
  <span className="field-text">
    {props?.state?.noticePeriod !== null
      ? props?.state?.noticePeriod
      : "______ "}
    's notice
  </span>{" "}
  in writing to the Lessor.
  <br />
  <br />
  That the Lessee shall have no right to create any sub-lease or assign
  or transfer in any manner the lease or give to any one the possession
  of the said premises or any part thereof.
  <br />
  <br />
  That the Lessee shall use the said premises only for residential
  purposes.
  <br />
  <br />
  That the Lessor shall, before handing over the said premises, ensure
  the working of sanitary, electrical and water supply connections and
  other fittings pertaining to the said premises. It is agreed that it
  shall be the responsibility of the Lessor for their return in the
  working condition at the time of re-possession of the said premises,
  subject to normal wear and tear.
  <br />
  <br />
  That the Lessee is not authorized to make any alteration in the
  construction of the said premises.
  <br />
  <br />
  That the day-to-day repair jobs shall be affected by the Lessee at his
  own cost, and any major repairs, either structural or to the
  electrical or water connection, plumbing leaks, water seepage shall be
  attended to by the Lessor. In the event of the Lessor failing to carry
  out the repairs on receiving notice from the Lessee, the Lessee shall
  undertake the necessary repairs and the Lessor will be liable to
  immediately reimburse costs incurred by the Lessee.
  <br />
  <br />
  That the Lessor or its duly authorized agent shall have the right to
  enter or upon the said premises or any part thereof at a mutually
  arranged convenient time for the purpose of inspection.
  <br />
  <br />
  That in consideration of use of the said premises the Lessee agrees
  that he shall pay to the Lessor during the period of this agreement, a
  monthly rent at the rate of
  <span className="field-text">
    {" "}
    ₹
    {props?.state?.MRentAmount !== null
      ? props?.state?.MRentAmount
      : "______ "}
  </span>
  . The amount will be paid in advance on or before the date of{" "}
  <span className="field-text">{match && match[0]}</span> of every
  English calendar month. That the Lessee shall pay to the Landlord a
  monthly maintenance charge of{" "}
  <span className="field-text">
    {" "}
    ₹
    {props?.state?.MCharges !== null
      ? props?.state?.MCharges
      : "______ "}
  </span>{" "}
  towards maintenance of property.
  <br />
  <br />
  It is hereby agreed that in the event of default in payment of the
  rent for a consecutive period of three months the lessor shall be
  entitled to terminate the lease.
  <br />
  <br />
  That the Lessee has paid to the Lessor a sum of{" "}
  <span className="field-text">
    {" "}
    ₹
    {props?.state?.securityDeposit !== null
      ? props?.state?.securityDeposit
      : "______ "}
  </span>{" "}
  as deposit, free of interest. The said deposit shall be returned to
  the Lessee simultaneously with the Lessee vacating the said premises.
  In the event of failure on the part of the Lessor to refund the said
  deposit amount to the Lessee as aforesaid, the Lessee shall be
  entitled to continue to use and occupy the said premises without
  payment of any rent until the Lessor refunds the said amount.
  <br />
  <br />
  That the Lessor shall be responsible for the payment of all taxes and
  levies pertaining to the said premises including but not limited to
  House Tax, Property Tax, other cesses, if any, and any other statutory
  taxes, levied by the Government or Governmental Departments. During
  the term of this Agreement, the Lessor shall comply with all rules,
  regulations and requirements of any statutory authority, local, state,
  and central government, and governmental departments in relation to
  the said premises.
  <br />
  <br />
  <span className="field-text">
    {" "}
    {props?.state?.terms !== "" ? props?.state?.terms : null}
  </span>
  <br />
  <br />
  IN WITNESS WHEREOF, the parties hereto have set their hands on the day
  and year first here in above mentioned.
  <br />
  <br />
  Agreed & Accepted by the Lessor{" "}
  <span className="field-text">
    {props?.state?.ownerName !== ""
      ? props?.state?.ownerName
      : "______ "}
  </span>
  <br />
  <br />
  Agreed & Accepted by the Lessee{" "}
  <span className="field-text">
    {props?.state?.tenantName !== ""
      ? props?.state?.tenantName
      : "______ "}
  </span>
  <br />
  <br />
  WITNESS
  <br />
  <br />
  1. --------
  <br />
  <br />
  2. --------
  <div className="text-center">ANNEXURE I</div>
  List of fixtures and fittings provided in
  {props?.state?.propertyAddress !== "" ||
  props?.state?.propertyCity !== "" ||
  props?.state?.propertyState !== "" ||
  props?.state?.propertyPinCode !== "" ? (
    <>
      <span className="field-text">
        {props?.state?.propertyAddress !== ""
          ? ` ${props?.state?.propertyAddress} , `
          : ""}
      </span>
      <span className="field-text">
        {props?.state?.propertyCity !== ""
          ? ` ${props?.state?.propertyCity} , `
          : ""}
      </span>
      <span className="field-text">
        {props?.state?.propertyState !== ""
          ? ` ${props?.state?.propertyState} , `
          : ""}
      </span>
      <span className="field-text">
        {props?.state?.propertyPinCode !== ""
          ? ` ${props?.state?.propertyPinCode}`
          : ""}
      </span>
    </>
  ) : (
    " ______ "
  )}
  <br />
  <div className="mt-3">
    <span className="field-text">
      {props?.state?.furnishedStatus !== ""
        ? props?.state?.furnishedStatus
        : ""}
    </span>
    <div className="mt-3">
      <ol>
        {Object.entries(props?.state?.furnishingItem).map(
          ([itemName, quantity]) =>
            quantity > 0 && (
              <li className="field-text">
                {itemName} - {quantity}
              </li>
            )
        )}
      </ol>
    </div>
  </div>
</div>
</div> */}
