import axios from "axios";
import { settings } from "../Config/Settings";

const addBuilderProject = (data) => {
  return axios({
    method: "POST",
    url: `${settings.API_URL}/construction/createProject`,
    data: data,
  });
};

const getAllProjectDetails = () => {
  return axios({
    method: "GET",
    url: `${settings.API_URL}/construction/getAllConstructionProjects`,
  });
};

const getAllProjectDetailsByUserId = (userId) => {
  return axios({
    method: "GET",
    url: `${settings.API_URL}/construction/getConstructionProjectByUserId/${userId}`
  })
}

const updateBuilderProject = (data, id) => {
  return axios({
    method: "PUT",
    url: `${settings.API_URL}/construction/updateConstructionProjectById/${id}`,
    data: data,
  });
};
const deleteBuilderProject = (id) => {
  return axios({
    method: "PATCH",
    url: `${settings.API_URL}/construction/deleteConstructionProjectById/${id}`,
  });
};

const getDocumentType = () => {
  return axios({
    method: "GET",
    url: `${settings.API_URL}/construction/getAllDocumentType`,
  });
};

const getCertificatebyDocumenttypeID = (docId, projectId) => {
  return axios({
    method: "GET",
    url: `${settings.API_URL}/construction/getCertificatiesByDocId/${docId}/${projectId}`,
  });
};

const deleteImages = (data) => {
  return axios({
    method: "DELETE",
    url: `${settings.API_URL}/construction/deleteImageData`,
    data: data,
  })
}

const getBuilderProjectByPropertyId = (id) => {
  return axios({
    method: "GET",
    url: `${settings.API_URL}/construction/getConstructionProjectByProperty/${id}`
  })
}
const getReviewsByUserID = (userId) => {
  return axios({
    method: "GET",
    url: `${settings.API_URL}/review/getBuilderReviewByUserId/${userId}`
  })
}

const filterConstructionProject = (data) => {
  return axios({
    method: "POST",
    url: `${settings.API_URL}/construction/filterConstructionProject`,
    data: data,
  })
}

const updateStatusOfProject = (id, data) => {
  return axios({
    method: "PATCH",
    url: `${settings.API_URL}/construction/updateBuilderPropertystaus/${id}`,
    data: data,
  });
};

const getBuilderReviewByUserId = (userId) => {
  return axios({
    method: "GET",
    url: `${settings.API_URL}/review/getBuilderReviewByUserId/${userId}`,
  });
};

const getBuilderEnquiryDetailsListByid = (userId) => {
  return axios({
    method: "GET",
    url: `${settings.API_URL}/user/getDetailsByid/${userId}`,
  });
};
const getBuilderDashboardValue= (userId) => {
  return axios({
    method: "GET",
    url: `${settings.API_URL}/construction/dashboardBuilder/${userId}`,
  });
};

export {
  deleteBuilderProject,
  addBuilderProject,
  getAllProjectDetails,
  getAllProjectDetailsByUserId,
  updateBuilderProject,
  getDocumentType,
  getCertificatebyDocumenttypeID,
  deleteImages,
  getBuilderProjectByPropertyId,
  getReviewsByUserID,
  filterConstructionProject,
  updateStatusOfProject,
  getBuilderReviewByUserId,
  getBuilderEnquiryDetailsListByid,
  getBuilderDashboardValue
};
