import React, { useEffect, useState } from "react";
import AdminSidebar from "../../Components/Sidebar/AdminSidebar";
import { useNavigate } from "react-router-dom";
import "./Dashboard.css";
import { agentCounts, paymentCount, propertiesCount, receiptCount } from "../../Api/AuthAPI";
import { Col, Row, Container } from "react-bootstrap";
import ProgressBar from 'react-bootstrap/ProgressBar';

const Dashboard = () => {
  const navigate = useNavigate();
  const [Agents, setAgents] = useState({});
  const [propertiesCounts, setPropertiesCounts] = useState([]);
  const [paymentCounts, setPaymentCounts] = useState(0);
  const [receiptCounts, setReceiptCounts] = useState(0);

  useEffect(() => {
    getUserCounts();
    getPropertyCounts();
    getPaymentCounts();
    getReceiptCounts();
  }, []);

  const getUserCounts = () => {
    agentCounts()
      .then((res) => {
        if(res.data.status === 1){
          setAgents(res.data.data);
        }else{
          setAgents([]);
        }
      })
      .catch((err) => {
        console.log("====================================");
        console.log(err);
        console.log("====================================");
      });
  };

  const getPropertyCounts = () =>{
    propertiesCount()
    .then((res) =>{
      if(res.data.status === 1){
        setPropertiesCounts(res.data.data);
      }else{
        setPropertiesCounts([]);
      }
    }).catch((err)=>{
      console.log(err, "error in get property counts");
    })
  }

const getPaymentCounts = () =>{
  paymentCount()
  .then((res)=>{
    if(res.data.status === 1){
     setPaymentCounts(res.data.data.paymentTotals);
    }else{
      setPaymentCounts(0);
    }
  }).catch((err)=>{
    console.log(err,"error in payment count..")
  })
}

const getReceiptCounts = () =>{
  receiptCount()
  .then((res)=>{
    if(res.data.status === 1){
      setReceiptCounts(res.data.data.reciptTotals);
    }else{
      setReceiptCounts(0);
    }
    console.log(res,"receiptCount...")
  }).catch((err)=>{
    console.log(err,"err in receiptCount...")
  })
}

  const handleReceipt = () => {
    navigate("/receiptList");
  };

  return (
    <>
      <div>
        <AdminSidebar />
      </div>
      <Container className="topNav-m ">
        <Row>
          <Col className="d-admin-pg-hd">
          <div className=" content-title">
              <h3>Welcome Back!</h3>
              <hr className="content-title-underline" />
            </div>
           
          </Col>
        </Row>
        <Row>
          <Col md={4} sm={12} className="d-admin-card">

            <div className=" d-admin-hd">
              <h6 >All Properties</h6>
              <h2 > {propertiesCounts.totalCount ? propertiesCounts.totalCount : 0}</h2>
            </div>
            <ProgressBar>
              <ProgressBar striped variant="success" now={(propertiesCounts.totalVerifiedCount / propertiesCounts.totalCount) * 100} key={1} />
              <ProgressBar striped variant="warning" now={(propertiesCounts.totalPendingCount / propertiesCounts.totalCount) * 100} key={2} />
              <ProgressBar striped variant="danger" now={(propertiesCounts.totalRejectedCount / propertiesCounts.totalCount) * 100} key={2} />
            </ProgressBar>
            <div>
              <ul>
                <li>Verified Properties <span>{propertiesCounts.totalVerifiedCount ? propertiesCounts.totalVerifiedCount : 0}</span></li>
                <li>Pending Properties <span>{propertiesCounts.totalPendingCount ? propertiesCounts.totalPendingCount : 0}</span></li>
                <li>Rejected Properties <span>{propertiesCounts.totalRejectedCount ? propertiesCounts.totalRejectedCount : 0}</span></li>
              </ul>

            </div>
          </Col>

          <Col md={4} sm={12} className="d-admin-card">
            <div className=" d-admin-hd">
              <h6>All Agents</h6>
              <h2>
                {/* <i className="fa fa-credit-card f-left"></i> */}
            
                  {Number(Agents.totalAgentCount ? Agents.totalAgentCount : 0)}
              
              </h2>
            </div>
            
            <ProgressBar>
              <ProgressBar striped variant="success" now={(Agents.CretifiedAgentCount / Agents.totalAgentCount) * 100} key={1} title="Certified Agents" />
              <ProgressBar striped variant="info" now={(Agents.NormalAgentCount / Agents.totalAgentCount) * 100} key={2} />
              <ProgressBar striped variant="warning" now={(Agents.pendingNewAgentCount / Agents.totalAgentCount) * 100} key={3} />
              <ProgressBar striped variant="danger" now={(Agents.RejectCount / Agents.totalAgentCount) * 100} key={4} />
            </ProgressBar>
            <ul>
              <li>Certified Agents  <span>{Agents.CretifiedAgentCount ? Agents.CretifiedAgentCount : 0}</span></li>
              <li>Non-Certified Agents  <span>{Agents.NormalAgentCount ? Agents.NormalAgentCount : 0}</span></li>
              <li>Pending Agents  <span>{Agents.pendingNewAgentCount ? Agents.pendingNewAgentCount : 0}</span></li>
              <li>Rejected Agents  <span>{Agents.RejectCount ? Agents.RejectCount : 0}</span></li>
            </ul>


          </Col>
          <Col md={3} sm={12} className=" m-2 mt-4">
            <div className="card bg-c-pink order-card">
              <div className="card-block">
                <h6 className="m-b-20">Payments</h6>
                <h2 className="text-end">
                  {/* <i className="fa fa-credit-card f-left"></i> */}
                  <span>{paymentCounts}</span>
                </h2>
              </div>
            </div>
            <div className="card bg-c-green order-card">
              <div className="card-block">
                <h6 className="m-b-20">Receipt</h6>
                <h2 className="text-end">
                  {/* <i className="fa fa-cart-plus f-left"></i> */}
                  <span>{receiptCounts}</span>
                </h2>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      {/* <div className="container  mt-4">
        <div className="row">
          <div className="col-md-4 col-xl-3">
            <div className="card bg-c-blue order-card">
              <div className="card-block">
                <h6 className="m-b-20">All Properties</h6>
                <h2 className="text-end">
                  <i className="fa fa-cart-plus f-left"></i>
                  <span>486</span>
                </h2>
              </div>
            </div>
          </div>

          <div className="col-md-4 col-xl-3">
            <div className="card bg-c-green order-card">
              <div className="card-block">
                <h6 className="m-b-20">Verified Properties</h6>
                <h2 className="text-end">
                  <i className="fa fa-rocket f-left"></i>
                  <span>486</span>
                </h2>
              </div>
            </div>
          </div>

          <div className="col-md-4 col-xl-3">
            <div className="card bg-c-yellow order-card">
              <div className="card-block">
                <h6 className="m-b-20">Rejected Properties</h6>
                <h2 className="text-end">
                  <i className="fa fa-refresh f-left"></i>
                  <span>486</span>
                </h2>
              </div>
            </div>
          </div>

          <div className="col-md-4 col-xl-3">
            <div className="card bg-c-pink order-card">
              <div className="card-block">
                <h6 className="m-b-20">Payments</h6>
                <h2 className="text-end">
                  <i className="fa fa-credit-card f-left"></i>
                  <span>486</span>
                </h2>
              </div>
            </div>
          </div>

          <div className="col-md-4 col-xl-3">
            <div className="card bg-c-violet order-card">
              <div className="card-block">
                <h6 className="m-b-20">All Agents</h6>
                <h2 className="text-end">
                  <i className="fa fa-credit-card f-left"></i>
                  <span>
                    {Number(Agents.CretifiedAgentCount) +
                      Number(Agents.NormalAgentCount) +
                      Number(Agents.RejectCount) +
                      Number(Agents.NewAgentCount)}
                  </span>
                </h2>
              </div>
            </div>
          </div>

          <div className="col-md-4 col-xl-3">
            <div className="card bg-c-pgreen order-card">
              <div className="card-block">
                <h6 className="m-b-20">Certified Agent</h6>
                <h2 className="text-end">
                  <i className="fa fa-credit-card f-left"></i>
                  <span>{Agents.CretifiedAgentCount}</span>
                </h2>
              </div>
            </div>
          </div>

          <div className="col-md-4 col-xl-3">
            <div className="card bg-c-brown order-card">
              <div className="card-block">
                <h6 className="m-b-20">Agent</h6>
                <h2 className="text-end">
                  <i className="fa fa-credit-card f-left"></i>
                  <span>{Agents.NormalAgentCount}</span>
                </h2>
              </div>
            </div>
          </div>

          <div className="col-md-4 col-xl-3">
            <div className="card bg-c-skyblue order-card">
              <div className="card-block">
                <h6 className="m-b-20">Rejected Agent</h6>
                <h2 className="text-end">
                  <i className="fa fa-credit-card f-left"></i>
                  <span>{Agents.RejectCount}</span>
                </h2>
              </div>
            </div>
          </div>

          <div className="col-md-4 col-xl-3" onClick={handleReceipt}>
            <div className="card bg-c-green order-card">
              <div className="card-block">
                <h6 className="m-b-20">Receipt</h6>
                <h2 className="text-end">
                  <i className="fa fa-cart-plus f-left"></i>
                  <span>17</span>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Dashboard;
