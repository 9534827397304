import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// @mui packages
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
// bootstrap packages
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Toast, ToastContainer } from "react-bootstrap";
// custom components
import RentProperty from "../OwnerAgent/Rent/RentProperty";
import Commercial from "../OwnerAgent/Commercial/Commercial";
import NewProject from "../OwnerAgent/NewProject/NewProject";
import {
  locationsData,
  // commercialPropertyTypeOptions,
  // PropertyTypeOption,
  PropertyAreaList,
} from "../../modules/helpers/constant";
// Api
import { getLocationData, getPropertytypeByTypeData } from "../../Api/AuthAPI";
import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete, TextField } from "@mui/material";
import Select from 'react-select';


export const FindProperty = () => {
  let navigate = useNavigate();
  const target = useRef(null);

  const [propertyTab, setPropertyTab] = useState("Residential");

  const [propertyTypeOption, setPropertyTypeOption] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [resiSelectedOption, setResiSelectedOption] = useState("Buy");

  const [locationCommercial, setLocationCommercial] = useState("");
  const [propertyTypeCommercial, setPropertyTypeCommercial] = useState("");

  const [minBudgetCommercial, setMinBudgetCommercial] = useState(null);
  const [maxBudgetCommercial, setMaxBudgetCommercial] = useState(null);
  const [minAreaCommercial, setMinAreaCommercial] = useState(null);
  const [maxAreaCommercial, setMaxAreaCommercial] = useState(null);

  const [noOfSeats, setNoOfSeats] = useState(null);
  const [noOfCabins, setNoOfCabins] = useState("");

  // popover for commercial
  const [anchBudgetCommercial, setAnchBudgetCommercial] = useState(null);
  const [anchAreaCommercial, setAnchAreaCommercial] = useState(null);

  // commercial popover ids for bugdet
  const openBudgetC = Boolean(anchBudgetCommercial);
  const commercialBudgetId = openBudgetC ? "simple-popover" : undefined;
  // commercial popover ids for area
  const openAreaC = Boolean(anchAreaCommercial);
  const commercialAreaId = openAreaC ? "simple-popover" : undefined;

  // residential
  const [locationResidential, setLocationResidential] = useState("");
  const [propertyTypeResidential, setPropertyTypeResidential] = useState("");
  const [minBudgetResidential, setMinBudgetResidential] = useState(null);
  const [maxBudgetResidential, setMaxBudgetResidential] = useState(null);
  const [minAreaResidential, setMinAreaResidential] = useState(null);
  const [maxAreaResidential, setMaxAreaResidential] = useState(null);
  const [noOfBedrooms, setNoOfBedrooms] = useState(null);

  // popover for residential
  const [anchBudgetResidential, setAnchBudgetResidential] = useState(null);
  const [anchAreaResidential, setAnchAreaResidential] = useState(null);

  // residential popover ids
  const openBudgetR = Boolean(anchBudgetResidential);
  const residentialBudgetId = openBudgetR ? "simple-popover" : undefined;

  const openAreaR = Boolean(anchAreaResidential);
  const residentialAreaId = openAreaR ? "simple-popover" : undefined;

  //new Project
  const [newProjectLocation, setNewProjectLocation] = useState("");
  const [propertyTypeProjects, setPropertyTypeProject] = useState([]);
  const [minProjectPrice, setMinProjectPrice] = useState(null);
  const [maxProjectPrice, setMaxProjectPrice] = useState(null);
  const [anchAreaPrice, setAnchAreaPrice] = useState(null);

  const openPriceR = Boolean(anchAreaPrice);
  const newProjectPriceId = openPriceR ? "simple-popover" : undefined;

  // error
  const [error, setError] = useState({});
  const [show, setShow] = useState(false);

  // get PropertyTypes of commercial
  // const commercialProperty = commercialPropertyTypeOptions.map(
  //   (propertyType) => ({vi
  //     label: propertyType.label,
  //     value: propertyType.value,
  //   })
  // );

  //useEffects
  useEffect(() => {
    getLocationData()
      .then((res) => {
        if (res.status === 200) {
          let locationData = res.data.locations;
          setLocationData(locationData);
        } else {
          setLocationData([])
        }
      })
      .catch((err) => {
        console.log(err, "error in location get call find property");
        setLocationData([])
      });
  }, []);



  useEffect(() => {
    getpropertyType(propertyTab)
  }, [])

  const getpropertyType = (key) => {
    getPropertytypeByTypeData(key)
      .then((res) => {
        if (res.status === 200) {
          let result = res.data.data;
          let list = result.map((data) => ({ label: data.AreaType, value: data._id }));
          setPropertyTypeOption(list);
        }
      }).catch((err) => {
        console.log(err, "error in property get data");
      })
  }

  // All handle Change
  const handleClose = () => {
    setAnchAreaCommercial(null);
    setAnchBudgetCommercial(null);
    setAnchAreaResidential(null);
    setAnchBudgetResidential(null);
    setAnchAreaPrice(null);
  };

  const handleSelect = (key) => {
    resetData();
    setPropertyTab(key);
    window.history.pushState(null, null, `/${key}Section`);
    getpropertyType(key)
  };

  const handleChangeDropdownResi = (event) => {
    setResiSelectedOption(event.target.value);
  };

  // reset All fields handleclear
  const resetData = () => {
    //commercial
    setShowToast(false);
    setResiSelectedOption("Buy");
    setError({});

    setLocationCommercial("");
    setPropertyTypeCommercial("");
    setMinBudgetCommercial(null);
    setMaxBudgetCommercial(null);
    setMinAreaCommercial(null);
    setMaxAreaCommercial(null);
    setNoOfSeats(null);
    setNoOfCabins("");

    setAnchBudgetCommercial(null);
    setAnchAreaCommercial(null);

    //residential
    setLocationResidential("");
    setPropertyTypeResidential("");
    setMinBudgetResidential(null);
    setMaxBudgetResidential(null);
    setMinAreaResidential(null);
    setMaxAreaResidential(null);
    setNoOfBedrooms("");

    setAnchBudgetResidential(null);
    setAnchAreaResidential(null);

    setNewProjectLocation("");
    setPropertyTypeProject([]);
    setMinProjectPrice(null);
    setMaxProjectPrice(null);

    //newproject
    setAnchAreaPrice(null)
  };

  //Validation For only Location
  const validate = () => {
    let error = {};
    let isValid = true;
    if (propertyTab === "Residential") {
      // if (propertyTypeResidential === "") {
      //   isValid = false;
      //   error["propertyType"] = "please Enter Property Type";
      // }

      if (locationResidential === "" || locationResidential === null) {
        isValid = false;
        error["locationResidential"] = "Please Enter Location";
      }
    } else if (propertyTab === "Construction") {
      if (newProjectLocation === "" || newProjectLocation === null) {
        isValid = false;
        error["newProjectLocation"] = "Please Enter Location";
      }
    } else {
      if (locationCommercial === "" || locationCommercial === null) {
        isValid = false;
        error["locationCommercial"] = "Please Enter Location";
      }
    }
    if (!isValid) {
      setError(error);
      setShowToast(true);
    }
    return isValid;
  };

  // handleSearch for filter
  const handleSearch = (key) => {
    if (validate()) {
      navigate("/filterProperties", {
        state: {
          propertyType: key,
          purposeOfProperty: resiSelectedOption,

          location:
            key === "Residential"
              ? locationResidential
              : key === "Commercial" ? locationCommercial
                : newProjectLocation,

          propertyTypes:
            key === "Residential"
              ? propertyTypeResidential.label
              : propertyTypeCommercial.label,

          areaTypes:
            key === "Construction" && propertyTypeProjects.label,

          //residential && commercial
          minBudget:
            key === "Residential" ? minBudgetResidential : minBudgetCommercial,
          maxBudget:
            key === "Residential" ? maxBudgetResidential : maxBudgetCommercial,
          noOfBedrooms: key === "Residential" && noOfBedrooms,
          minArea:
            key === "Residential" ? minAreaResidential : minAreaCommercial,
          maxArea:
            key === "Residential" ? maxAreaResidential : maxAreaCommercial,
          // new project
          minPrice: key === "Construction" && minProjectPrice,
          maxPrice: key === "Construction" && maxProjectPrice,
        },
      });
    }
  };
  const tabWrapperRef = useRef(null);

  const handleKeyDown = (event) => {
    const tabKeys = ['Residential', 'Commercial', 'Construction'];
    let currentIndex = tabKeys.indexOf(propertyTab);

    console.log(event.key, "event.key"); // Log the key being pressed

    if (event.key === 'ArrowRight') {
      currentIndex = (currentIndex + 1) % tabKeys.length;
      setPropertyTab(tabKeys[currentIndex]);
      event.preventDefault(); // Prevent default action for ArrowRight
    } else if (event.key === 'ArrowLeft') {
      currentIndex = (currentIndex - 1 + tabKeys.length) % tabKeys.length;
      setPropertyTab(tabKeys[currentIndex]);
      event.preventDefault(); // Prevent default action for ArrowLeft
    } else if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      // Prevent default action for ArrowUp and ArrowDown
      event.preventDefault();
    }
  };

  useEffect(() => {
    const tabWrapper = tabWrapperRef.current;
    if (tabWrapper) {
      tabWrapper.focus();
    }

  }, [handleKeyDown]);

  return (
    <div className="flex-grow-1">
      <div id="home">
        <div className="bannerbg">
          <div className="content-box">
            <div
              className="banner-title wow animated fadeInRight"
              data-wow-duration=".5s"
              data-wow-delay="0s"
            >
              <h1>
                Easy way to find a perfect <span>property</span>
              </h1>
              <div className="underline"></div>
            </div>
            <br />

            {/* login data condition remove */}
            {
              <div className="row d-flex justify-content-center">
                <div
                  className="col-xl-6 col-md-8 col-sm-12"
                  ref={tabWrapperRef}
                  tabIndex="0"
                  onKeyDown={handleKeyDown}
                  style={{ outline: 'none' }}
                >
                  <Tabs
                    defaultActiveKey="Residential"
                    id="uncontrolled-tab-example"
                    className="mb-1 d-flex justify-content-center home-tabs"
                    activeKey={propertyTab}
                    onSelect={(key) => handleSelect(key)}
                  >

                    <Tab eventKey="Residential" title="Residential">
                      <div className="">
                        <div class="content">
                          <section className="bg-tabs">
                            <div className="bg-content">
                              <h2 className="tabs-heading">Residential</h2>
                              <div className="clear-btn-box"><Button onClick={() => resetData()} className="clear-btn">Clear</Button></div>
                              <div className="d-flex justify-content-evenly align-items-center w-100 gap-1">
                                <select
                                  className="txtfield m-1 w-150"
                                  id="transactionType"
                                  value={resiSelectedOption}
                                  onChange={(e) => handleChangeDropdownResi(e)}
                                >
                                  <option value="Buy">Buy</option>
                                  <option value="Rent">Rent</option>
                                </select>

                                {/* <select
                                  ref={target}
                                  name="city"
                                  id="city"
                                  className="txtfield m-1"
                                  value={locationResidential}
                                  onChange={(e) => {
                                    setShow(false);
                                    setLocationResidential(e.target.value);
                                    setError({
                                      ...error,
                                      locationResidential: "",
                                    });
                                  }}
                                >
                                  <option value="" disabled selected>
                                    Location
                                  </option>
                                  {locationData.map((option) => (
                                    <option
                                      key={option._id}
                                      value={option.locationName}
                                    >
                                      {option.locationName}
                                    </option>
                                  ))}
                                </select> */}

                                <Autocomplete
                                  value={locationResidential}
                                  className="w-200"
                                  onChange={(event, newValue) => {
                                    if (typeof newValue === 'string') {
                                      setLocationResidential(newValue);
                                    } else if (newValue && newValue.inputValue) {
                                      setLocationResidential(newValue.inputValue);
                                    } else {
                                      setLocationResidential(newValue);
                                    }
                                    setError({ ...error, locationResidential: '' });
                                  }}
                                  options={locationData.map((option) => option.locationName)}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder="Location"
                                      variant="outlined"
                                      size="small"
                                      className="w-200"
                                    />
                                  )}

                                />
                                <ToastContainer position="top-center">
                                  <Toast
                                    show={showToast}
                                    onClose={() => setShowToast(false)}
                                    delay={2000}
                                    autohide
                                    className="error-msg"
                                  >
                                    <Toast.Body>
                                      {Object.values(error).join(". ")}
                                    </Toast.Body>
                                  </Toast>
                                </ToastContainer>

                                {/* <input
                                  type="number"
                                  id="noOfRooms"
                                  name="noOfRooms"
                                  className="txtfield m-1"
                                  placeholder="No. Of BHK"
                                  onChange={(e) =>
                                    setNoOfBedrooms(e.target.value)
                                  }
                                  value={noOfBedrooms}
                                /> */}

                                {/* <select
                                  name="city"
                                  id="city"
                                  className="txtfield m-1"
                                  value={propertyTypeResidential}
                                  onChange={(e) => {
                                    setPropertyTypeResidential(e.target.value);
                                    setError({
                                      ...error,
                                      propertyTypeResidential: "",
                                    });
                                  }}
                                >
                                  <option value="" disabled selected>
                                    Select Property Type
                                  </option>
                                  {propertyTypeOption.map((option) => (
                                    <option
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </option>
                                  ))}
                                </select> */}

                                <Select
                                  defaultValue={propertyTypeResidential}
                                  onChange={setPropertyTypeResidential}
                                  options={propertyTypeOption}
                                />

                                {/* <Select
                                  name="propertyType"
                                  id="propertyType"
                                  className="txtfield w-200"
                                  value={propertyTypeResidential}
                                  options={propertyTypeOption.map((option) => option.label)}
                                  onChange={(e) => {
                                    setPropertyTypeResidential(e.target.value);
                                  }}
                                >
                                  <option value="" disabled selected>
                                    Property Type
                                  </option>
                                  {propertyTypeOption.map((option) => (
                                    <option key={option.value} value={option.label}>
                                      {option.label}
                                    </option>
                                  ))}
                                </Select> */}


                                <div>
                                  <button
                                    aria-describedby={residentialBudgetId}
                                    variant="contained"
                                    onClick={(e) => {
                                      setAnchBudgetResidential(e.currentTarget);
                                    }}
                                    className="txtfield m-1"
                                  >
                                    {minBudgetResidential === null &&
                                      maxBudgetResidential === null
                                      ? "Budget"
                                      : `${minBudgetResidential} - ${maxBudgetResidential}`}
                                  </button>
                                  <Popover
                                    id={residentialBudgetId}
                                    open={openBudgetR}
                                    anchorEl={anchBudgetResidential}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "left",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "left",
                                    }}
                                  >
                                    <div className="d-flex gap-3 p-2">
                                      <div className="">
                                        <input
                                          type="number"
                                          id="min"
                                          name="Min"
                                          className="txtfield w-150"
                                          placeholder="Min Budget"
                                          onChange={(e) =>
                                            setMinBudgetResidential(
                                              e.target.value
                                            )
                                          }
                                          value={minBudgetResidential}
                                        />
                                      </div>
                                      <div className="">
                                        <input
                                          type="number"
                                          id="max"
                                          name="Max"
                                          className="txtfield w-150"
                                          placeholder="Max Budget"
                                          onChange={(e) =>
                                            setMaxBudgetResidential(
                                              e.target.value
                                            )
                                          }
                                          value={maxBudgetResidential}
                                        />
                                      </div>
                                    </div>
                                  </Popover>
                                </div>

                                <div>
                                  <button
                                    aria-describedby={residentialAreaId}
                                    variant="contained"
                                    onClick={(e) =>
                                      setAnchAreaResidential(e.currentTarget)
                                    }
                                    className="txtfield m-1"
                                  >
                                    {minAreaResidential === null &&
                                      maxAreaResidential === null
                                      ? "Area"
                                      : `${minAreaResidential} - ${maxAreaResidential}`}
                                  </button>
                                  <Popover
                                    id={residentialAreaId}
                                    open={openAreaR}
                                    anchorEl={anchAreaResidential}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "left",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "left",
                                    }}
                                  >
                                    <div className="d-flex gap-3 p-2">
                                      <div className="">
                                        <input
                                          type="number"
                                          id="min"
                                          name="Min"
                                          className="txtfield w-150"
                                          placeholder="Min Area"
                                          onChange={(e) =>
                                            setMinAreaResidential(e.target.value)
                                          }
                                          value={minAreaResidential}
                                        />
                                      </div>
                                      <div className="">
                                        <input
                                          type="number"
                                          id="max"
                                          name="Max"
                                          className="txtfield w-150"
                                          placeholder="Max Area"
                                          onChange={(e) =>
                                            setMaxAreaResidential(e.target.value)
                                          }
                                          value={maxAreaResidential}
                                        />
                                      </div>
                                    </div>
                                  </Popover>
                                </div>
                                <div className="mx-2">
                                  <Button
                                    onClick={() => handleSearch("Residential")} className="search-btn"
                                  >
                                    <SearchIcon />
                                    Search
                                  </Button>
                                  {/* <Button onClick={() => resetData()}>Clear</Button> */}
                                </div>

                              </div>
                            </div>


                          </section>
                        </div>
                      </div>
                    </Tab>

                    <Tab eventKey="Commercial" title="Commercial">
                      <div className="">
                        <div class="content">
                          <section className="bg-tabs">
                            <div className="bg-content">
                              <h2 className="tabs-heading">Commercial</h2>
                              <div className="clear-btn-box"><Button onClick={() => resetData()} className="clear-btn">Clear</Button></div>
                              <div className="d-flex gap-1 justify-content-evenly align-items-center w-100">

                                <select
                                  className="txtfield w-150"
                                  id="transactionType"
                                  value={resiSelectedOption}
                                  onChange={handleChangeDropdownResi}
                                >
                                  <option value="Buy">Buy</option>
                                  <option value="Rent">Rent</option>
                                </select>

                                {/* <select
                                  ref={target}
                                  name="city"
                                  id="city"
                                  className="txtfield"
                                  value={locationCommercial}
                                  onChange={(e) => {
                                    setShow(false);
                                    setError({
                                      ...error,
                                      locationCommercial: "",
                                    });
                                    setLocationCommercial(e.target.value);
                                  }}
                                >
                                  <option value="" disabled selected>
                                    Location
                                  </option>
                                  {locationData.map((option) => (
                                    <option
                                      key={option._id}
                                      value={option.locationName}
                                    >
                                      {option.locationName}
                                    </option>
                                  ))}
                                </select> */}

                                <Autocomplete
                                  value={locationCommercial}

                                  onChange={(event, newValue) => {
                                    if (typeof newValue === 'string') {
                                      setLocationCommercial(newValue);
                                    } else if (newValue && newValue.inputValue) {
                                      setLocationCommercial(newValue.inputValue);
                                    } else {
                                      setLocationCommercial(newValue);
                                    }
                                    setError({ ...error, locationCommercial: '' });
                                  }}
                                  options={locationData.map((option) => option.locationName)}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      placeholder="Location"
                                      variant="outlined"
                                      size="small"
                                      className="w-200"
                                    />
                                  )}

                                />


                                {/* <Autocomplete
                                  value={newProjectLocation}
                                  onChange={(event, newValue) => {
                                    if (typeof newValue === 'string') {
                                      setNewProjectLocation(newValue);
                                    } else if (newValue && newValue.inputValue) {
                                      setNewProjectLocation(newValue.inputValue);
                                    } else {
                                      setNewProjectLocation(newValue);
                                    }
                                    setError({ ...error, newProjectLocation: '' });
                                  }}
                                  options={locationData.map((option) => option.locationName)}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Location"
                                      variant="outlined"
                                      size="small"
                                      
                                    />
                                  )}
                                  style={{ width: 300 }}
                                /> */}

                                <ToastContainer position="top-center">
                                  <Toast
                                    show={showToast}
                                    onClose={() => setShowToast(false)}
                                    delay={2000}
                                    autohide
                                    className="error-msg"
                                  >
                                    <Toast.Body>
                                      {Object.values(error).join(". ")}
                                    </Toast.Body>
                                  </Toast>
                                </ToastContainer>

                                {/* <select
                                  name="propertyType"
                                  id="propertyType"
                                  className="txtfield"
                                  value={propertyTypeCommercial}
                                  onChange={(e) =>
                                    setPropertyTypeCommercial(e.target.value)
                                  }
                                >
                                  <option value="" disabled selected>
                                    Property Type
                                  </option>
                                  {propertyTypeOption.map((option) => (
                                    <option
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </option>
                                  ))}
                                </select> */}

                                <Select
                                  defaultValue={propertyTypeCommercial}
                                  onChange={setPropertyTypeCommercial}
                                  options={propertyTypeOption}
                                />

                                {/* <select
                                  name="propertyType"
                                  id="propertyType"
                                  className="txtfield"
                                  value={propertyTypeCommercial}
                                  onChange={(e) =>
                                    setPropertyTypeCommercial(e.target.value)
                                  }
                                >
                                  <option value="" disabled selected>
                                    Property Type
                                  </option>
                                  {propertyTypeOption.map((option) => (
                                    <option key={option.value} value={option.label}>
                                      {option.label}
                                    </option>
                                  ))}
                                </select> */}


                                {/* <input
                                  type="number"
                                  id="noOfSeats"
                                  name="noOfSeats"
                                  className="txtfield"
                                  placeholder="Seats"
                                  value={noOfSeats}
                                  onChange={(e) => setNoOfSeats(e.target.value)}
                                /> */}

                                {/* <input
                                  type="number"
                                  id="noOfCabins"
                                  name="noOfCabins"
                                  className="txtfield"
                                  placeholder="Cabins"
                                  value={noOfCabins}
                                  onChange={(e) =>
                                    setNoOfCabins(e.target.value)
                                  }
                                /> */}



                                {/* budget */}
                                <div>
                                  <button
                                    aria-describedby={commercialBudgetId}
                                    variant="contained"
                                    onClick={(e) =>
                                      setAnchBudgetCommercial(e.currentTarget)
                                    }
                                    className="txtfield"
                                  >
                                    {minBudgetCommercial === null &&
                                      maxBudgetCommercial === null
                                      ? "Budget"
                                      : `${minBudgetCommercial} - ${maxBudgetCommercial}`}
                                  </button>
                                  <Popover
                                    id={commercialBudgetId}
                                    open={openBudgetC}
                                    anchorEl={anchBudgetCommercial}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "left",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "left",
                                    }}
                                  >
                                    <div className="d-flex gap-3 p-2">
                                      <div className="">
                                        <input
                                          type="number"
                                          id="min"
                                          name="Min"
                                          className="txtfield w-150"
                                          placeholder="Min Budget"
                                          onChange={(e) =>
                                            setMinBudgetCommercial(
                                              e.target.value
                                            )
                                          }
                                          value={minBudgetCommercial}
                                        />
                                      </div>
                                      <div className="">
                                        <input
                                          type="number"
                                          id="max"
                                          name="Max"
                                          className="txtfield w-150"
                                          placeholder="Max Budget"
                                          onChange={(e) =>
                                            setMaxBudgetCommercial(
                                              e.target.value
                                            )
                                          }
                                          value={maxBudgetCommercial}
                                        />
                                      </div>
                                    </div>
                                  </Popover>
                                </div>

                                {/* area */}
                                <div>
                                  <button
                                    aria-describedby={commercialAreaId}
                                    variant="contained"
                                    onClick={(e) =>
                                      setAnchAreaCommercial(e.currentTarget)
                                    }
                                    className="txtfield "
                                  >
                                    {minAreaCommercial === null &&
                                      maxAreaCommercial === null
                                      ? "Area"
                                      : `${minAreaCommercial} - ${maxAreaCommercial}`}
                                  </button>
                                  <Popover
                                    id={commercialAreaId}
                                    open={openAreaC}
                                    anchorEl={anchAreaCommercial}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "left",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "left",
                                    }}
                                  >
                                    <div className="d-flex gap-3 p-2">
                                      <div className="">
                                        <input
                                          type="number"
                                          id="min"
                                          name="MinArea"
                                          className="txtfield w-150"
                                          placeholder="Min Area "
                                          onChange={(e) =>
                                            setMinAreaCommercial(e.target.value)
                                          }
                                          value={minAreaCommercial}
                                        />
                                      </div>
                                      <div className="">
                                        <input
                                          type="number"
                                          id="max"
                                          name="MaxArea"
                                          className="txtfield w-150"
                                          placeholder="Max Area"
                                          onChange={(e) =>
                                            setMaxAreaCommercial(e.target.value)
                                          }
                                          value={maxAreaCommercial}
                                        />
                                      </div>
                                    </div>
                                  </Popover>
                                </div>

                                <div className="d-flex gap-1">
                                  <Button
                                    onClick={() => handleSearch("Commercial")} className="search-btn"
                                  >
                                    <SearchIcon />   Search
                                  </Button>
                                  {/* <Button onClick={() => resetData()} className="clear-btn">Clear</Button> */}
                                </div>
                              </div>
                            </div>

                            <div>

                            </div>
                          </section>
                        </div>
                      </div>
                    </Tab>

                    <Tab eventKey="Construction" title="Project">
                      <div className="">
                        <div class="content">
                          <section className="bg-tabs">
                            <div className="bg-content">
                              <h2 className="tabs-heading">Project</h2>
                              <div className="clear-btn-box"><Button onClick={() => resetData()} className="clear-btn">Clear</Button></div>
                              <div className="d-flex justify-content-evenly align-items-center w-100 gap-1">
                                {/* <select
                                  ref={target}
                                  name="city"
                                  id="city"
                                  className="txtfield m-1"
                                  value={newProjectLocation}
                                  onChange={(e) => {
                                    setShow(false);
                                    setNewProjectLocation(e.target.value);
                                    setError({
                                      ...error,
                                      newProjectLocation: "",
                                    });
                                  }}
                                >
                                  <option value="" disabled selected>
                                    Location
                                  </option>
                                  {locationData.map((option) => (
                                    <option
                                      key={option._id}
                                      value={option.locationName}
                                    >
                                      {option.locationName}
                                    </option>
                                  ))}
                                </select> */}
                                <Autocomplete
                                  value={newProjectLocation}
                                  onChange={(event, newValue) => {
                                    if (typeof newValue === 'string') {
                                      setNewProjectLocation(newValue);
                                    } else if (newValue && newValue.inputValue) {
                                      setNewProjectLocation(newValue.inputValue);
                                    } else {
                                      setNewProjectLocation(newValue);
                                    }
                                    setError({ ...error, newProjectLocation: '' });
                                  }}
                                  options={locationData.map((option) => option.locationName)}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Location"
                                      variant="outlined"
                                      size="small"
                                      className="w-200"
                                    />
                                  )}

                                />


                                <ToastContainer position="top-center">
                                  <Toast
                                    show={showToast}
                                    onClose={() => setShowToast(false)}
                                    delay={2000}
                                    autohide
                                    className="error-msg"
                                  >
                                    <Toast.Body>
                                      {Object.values(error).join(". ")}
                                    </Toast.Body>
                                  </Toast>
                                </ToastContainer>

                                <Select
                                  defaultValue={propertyTypeProjects}
                                  onChange={setPropertyTypeProject}
                                  options={propertyTypeOption}
                                />

                                {/* <select
                                  name="propertyTypeProjects"
                                  id="propertyTypeProjects"
                                  className="txtfield m-1"
                                  value={propertyTypeProjects}
                                  onChange={(e) => {
                                    // const selectedValue = e.target.value;
                                    // const selectedLabel = propertyTypeOption.find(option => option.value === selectedValue)?.label || "";
                                    setPropertyTypeProject(e.target.value);
                                    setError({
                                      ...error,
                                      propertyTypeProjects: "",
                                    });
                                  }}
                                >
                                  <option value="" disabled selected>
                                    Property Type
                                  </option>
                                  {propertyTypeOption.map((option) => (
                                    <option
                                      key={option.value}
                                      value={option.label}
                                    >
                                      {option.label}
                                    </option>
                                  ))}
                                </select> */}

                                <div>
                                  <button
                                    aria-describedby={newProjectPriceId}
                                    variant="contained"
                                    onClick={(e) =>
                                      setAnchAreaPrice(e.currentTarget)
                                    }
                                    className="txtfield w-150"
                                  >
                                    {minProjectPrice === null &&
                                      maxProjectPrice === null
                                      ? "Price"
                                      : `${minProjectPrice} - ${maxProjectPrice}`}
                                  </button>
                                  <Popover
                                    id={newProjectPriceId}
                                    open={openPriceR}
                                    anchorEl={anchAreaPrice}
                                    onClose={handleClose}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "left",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "left",
                                    }}
                                  >
                                    <div className="d-flex gap-3 p-2">
                                      <div className="">
                                        <input
                                          type="number"
                                          id="min"
                                          name="Min"
                                          className="txtfield w-150"
                                          placeholder="Min Price"
                                          onChange={(e) =>
                                            setMinProjectPrice(e.target.value)
                                          }
                                          value={minProjectPrice}
                                        />
                                      </div>
                                      <div className="">
                                        <input
                                          type="number"
                                          id="max"
                                          name="Max"
                                          className="txtfield w-150"
                                          placeholder="Max Price"
                                          onChange={(e) =>
                                            setMaxProjectPrice(e.target.value)
                                          }
                                          value={maxProjectPrice}
                                        />
                                      </div>
                                    </div>
                                  </Popover>
                                </div>
                                <div className="mx-2">
                                  <Button
                                    onClick={() => handleSearch("Construction")} className="search-btn"
                                  >
                                    <SearchIcon />   Search
                                  </Button>
                                  {/* <Button onClick={() => resetData()}>Clear</Button> */}
                                </div>
                              </div>
                            </div>

                          </section>
                        </div>
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            }
          </div>
        </div>

        {propertyTab === "Residential" ? (
          <RentProperty
            location={locationResidential}
            propertyType={propertyTypeResidential}
            minBudget={minBudgetResidential}
            maxBudget={maxBudgetResidential}
            noOfBedrooms={noOfBedrooms}
            minArea={minAreaResidential}
            maxArea={maxAreaResidential}
          />
        ) : propertyTab === "Commercial" ? (
          <Commercial
            location={locationCommercial}
            propertyType={propertyTypeCommercial}
            noofSeats={noOfSeats}
            noofCabins={noOfCabins}
            minArea={minAreaCommercial}
            maxArea={maxAreaCommercial}
            minPrice={minBudgetCommercial}
            maxPrice={maxBudgetCommercial}
          />
        ) : propertyTab === "Construction" ? (
          <NewProject
            location={newProjectLocation}
            propertyType={propertyTypeProjects}
            minPrice={minProjectPrice}
            maxPrice={maxProjectPrice}
          />
        ) : null}
      </div>
    </div>
  );
};
