import React from "react";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Form, InputGroup } from "react-bootstrap";

export default function DatePickerInput({
  selDate,
  disable,
  isInvalid,
  errorMsg,
  minData,
  maxDate,
  isDisableWeekend,
  markDatesList,
  handleChange,
  placeholderName,
}) {
  // const handleChange = (value) => {
  //   const formattedDate = dayjs(value).format("MM/DD/YYYY");
  //   setDate(formattedDate);
  // };

  const disableWeekends = (date) => {
    const day = new Date(date).getDay();
    const isDisable = markDatesList.some(
      (d) => dayjs(d).format("MM/DD/YYYY") === dayjs(date).format("MM/DD/YYYY")
    );
    return day === 0 || day === 6 || isDisable;
  };
  return (
    <>
      <div>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <InputGroup>
            <DatePicker
              label={placeholderName}
              className={`form-control dtpickerwrap ${
                isInvalid ? "is-invalid" : ""
              }`}
              value={selDate ? dayjs(selDate) : null}
              shouldDisableDate={isDisableWeekend ? disableWeekends : undefined}
              minDate={minData}
              maxDate={maxDate}
              onChange={(date) => handleChange(date)}
              disabled={disable}
              i
            />
            {isInvalid && (
              <Form.Control.Feedback type="invalid">
                {errorMsg}
              </Form.Control.Feedback>
            )}
          </InputGroup>
        </LocalizationProvider>
      </div>
    </>
  );
}
