/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

//@mui packages table
import { TextField, FormControl } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";

// icons
import IconButton from "@mui/material/IconButton";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PreviewIcon from "@mui/icons-material/Preview";
import FilterProperty from "../../Admin/FilterProperty";
import SquareFootOutlinedIcon from "@mui/icons-material/SquareFootOutlined";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import ChairOutlinedIcon from "@mui/icons-material/ChairOutlined";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import CorporateFareOutlinedIcon from "@mui/icons-material/CorporateFareOutlined";
import EmojiTransportationOutlinedIcon from "@mui/icons-material/EmojiTransportationOutlined";
// bootstrap
import { Modal } from "react-bootstrap";

//other packages
import ImageGallery from "react-image-gallery";
import Swal from "sweetalert2";
import { MdOutlineCabin } from "react-icons/md";
// custom components
import AdminSidebar from "../../../Components/Sidebar/AdminSidebar";
import { settings } from "../../../Config/Settings";
import Auth from "../../../Config/helper/Auth";

//Api's
import {
  deleteRentProperty,
  getAllRentPropertyByUser,
} from "../../../Api/RentpropertyAPI";
import AddIcon from '@mui/icons-material/Add';
import { Button } from "@mui/material";


function RentDetails() {
  const navigate = useNavigate();
  const userId = Auth.getUserDetails()._id;

  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedItem, setSelectedItemId] = useState(null);
  const [imageView, setImageView] = useState(false);
  const [imagesData, setImagesData] = useState([]);
  const [residentialPreview, setResidentialPreview] = useState(false);

  //pagination states
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const formatCash = (n) => {
    if (n < 1e5) return n;
    if (n >= 1e5 && n < 1e7) return +(n / 1e5).toFixed(1) + "L";
    if (n >= 1e7) return +(n / 1e7).toFixed(1) + "Cr";
  };
  const columns = [
    { id: "id", label: "Sr. No.", minWidth: 40, align: "center" },
    {
      id: "propertyType",
      label: "Property Type",
      minWidth: 170,
      align: "center",
    },
    {
      id: "PropertyStatus",
      label: "Status",
      minWidth: 170,
      align: "center",
    },
    // {
    //   id: "PostedBy",
    //   label: "Posted by",
    //   minWidth: 170,
    //   align: "center",
    // },
    {
      id: "isVerified",
      label: "Verification Status",
      minWidth: 170,
      align: "center",
    },
  ];

  // search functinality
  const filteredData = data
    ? data.filter((item) => {
      return (
        (item._id &&
          item._id.toLowerCase().includes(searchQuery.toLowerCase())) ||
        (item.propertyType &&
          item.propertyType
            .toLowerCase()
            .includes(searchQuery.toLowerCase())) ||
        (item.PropertyStatus &&
          item.PropertyStatus.toLowerCase().includes(
            searchQuery.toLowerCase()
          )) ||
        (item.isVerified &&
          item.isVerified.toLowerCase().includes(searchQuery.toLowerCase()))
      );
    })
    : [];

  //useEffects
  useEffect(() => {
    GetAllRentProperty();
  }, []);

  //get calls
  const GetAllRentProperty = () => {
    getAllRentPropertyByUser(userId)
      .then((res) => {
        if (res.data.status === 1) {
          let details = res.data.rentDetails;
          setData(details);
        } else {
          console.log("data not found");
        }
      })
      .catch((err) => {
        console.log(err, "error in get all rent property.");
      });
  };

  //handleChange for pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // image handlechange
  const handleOpenImage = (data) => {
    setImageView(true);
    const images = [
      {
        original: data.exteriorView[0]
          ? `${data?.exteriorView[0]?.name}`
          : "",
        thumbnail: data.exteriorView[0]
          ? `${data?.exteriorView[0]?.name}`
          : "",
        thumbnailLabel: "Exterior View",
      },
      {
        original: data.balconyImage[0]
          ? `${data?.balconyImage[0]?.name}`
          : "",
        thumbnail: data.balconyImage[0]
          ? `${data?.balconyImage[0]?.name}`
          : "",
        thumbnailLabel: "Balcony Images",
      },

      {
        original: data.bathroomImage[0]
          ? `${data?.bathroomImage[0]?.name}`
          : "",
        thumbnail: data.bathroomImage[0]
          ? `${data?.bathroomImage[0]?.name}`
          : "",
        thumbnailLabel: "Bathroom Images",
      },
      {
        original: data.bedRoomImage[0]
          ? `${data?.bedRoomImage[0]?.name}`
          : "",
        thumbnail: data.bedRoomImage[0]
          ? `${data?.bedRoomImage[0]?.name}`
          : "",
        thumbnailLabel: "Bedroom Images",
      },

      {
        original: data.kitchenImage[0]
          ? `${data?.kitchenImage[0]?.name}`
          : "",
        thumbnail: data.kitchenImage[0]
          ? `${data?.kitchenImage[0]?.name}`
          : "",
        thumbnailLabel: "Kitchen Images",
      },
      {
        original: data.livingRoomImage[0]
          ? `${data?.livingRoomImage[0]?.name}`
          : "",
        thumbnail: data.livingRoomImage[0]
          ? `${data?.livingRoomImage[0]?.name}`
          : "",
        thumbnailLabel: "Living Room Images",
      },
      {
        original: data.commonAreaImage[0]
          ? `${data?.commonAreaImage[0]?.name}`
          : "",
        thumbnail: data.commonAreaImage[0]
          ? `${data?.commonAreaImage[0]?.name}`
          : "",
        thumbnailLabel: "Common Images",
      },
    ];
    setImagesData(images);
  };

  const handlePreview = (id) => {
    data.map((ResidentialData, index) => {
      if (id === index) {
        console.log(ResidentialData, "Property Description");
        setSelectedItemId(ResidentialData);
      }
    });
    setResidentialPreview(true);
    setIsPopupOpen(true);

  };

  // handleClose
  const handleClose = () => {
    setResidentialPreview(false);
    setImageView(false);
  };

  // delete 
  const DeleteRentProperty = (rentpropertyid) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You are about to delete this record.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it",
      cancelButtonText: "No, cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteRentProperty(rentpropertyid)
          .then((res) => {
            if (res.data.status === 1) {
              Swal.fire({
                icon: "success",
                title: "Rent property deleted successfully.",
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                // cancelButtonColor: '#d33',
                confirmButtonText: "ok",
              });
              GetAllRentProperty();
            } else {
              Swal.fire({
                icon: "error",
                title: "Something went wrong.",
                showConfirmButton: true,
                confirmButtonColor: "#3085d6",
                // cancelButtonColor: '#d33',
                confirmButtonText: "ok",
              });
            }
          })
          .catch((err) => {
            console.log(err, "err in delete rent property.");
          });
      }
    });
  };

  return (
    <>
      <AdminSidebar />
      <div className="topNav-m">
        <div className="text-center p-2 mt-2">
          <h5>Residential Properties</h5>
        </div>
        <div className="mt-0 d-flex justify-content-center">

          <Paper
            sx={{
              width: "70%",
              marginTop: "0rem",
              overflow: "hidden",
              justifyContent: "center",
            }}
          >
            <FilterProperty
              userId={userId}
              propertyData={data}
              propertyType={"Residential"}
            // isVerified={isVerifiedDatas}
            />
            <div className="d-flex justify-content-end mb-3" ><Button className="add-btn" onClick={() => { navigate("/newRent") }}><AddIcon className="me-2" />Add Properties</Button></div>

            {data && data.map((listData, ind) => {
              return (
                <>

                  <div
                    className="p-card-content"
                  // onClick={() =>
                  //   navigate(
                  //     `/fulltab/${listData?.type}/${listData?._id}`,
                  //     {
                  //       state: {
                  //         yourData: listData,
                  //         propertyType: listData?.propertyType,
                  //       },
                  //     }
                  //   )
                  // }
                  >

                    <div className="p-card">
                      <div className="p-img">
                        <img
                          onClick={() => {
                            handleOpenImage(listData);
                          }}
                          src={`${listData?.exteriorView[0]?.name || "https://api.equireal.co.in/defaultProperty.jpg"}`}
                          alt="Property"
                          style={{ width: "100%", height: "100%", objectFit: "cover" }}
                        />
                      </div>

                      <div className="p-info">
                        <div className="p-tle">
                          <div>
                            <h4>Properties for Residential</h4>
                          </div>
                        </div>

                        <div className="pr-info">
                          <ul>
                            <li>
                              <ChairOutlinedIcon />
                              <h6>{listData?.furnishedStatus}</h6>
                            </li>
                            <li>
                              <MdOutlineCabin />
                              <h6> {listData?.propertyType}</h6>
                            </li>
                            <li>
                              <PeopleOutlineOutlinedIcon />
                              <h6>{listData?.postedBy}</h6>
                            </li>

                            <li>
                              <CorporateFareOutlinedIcon />
                              <h6>
                                {" "}
                                {listData?.selectFloor} of {listData?.totalFloor}
                                th Floor
                              </h6>
                            </li>
                            <li>
                              <EmojiTransportationOutlinedIcon />
                              <h6> {listData?.Location}</h6>
                            </li>
                            <li>
                              <SquareFootOutlinedIcon />
                              <h6> {listData?.areainSqft} Sq.Ft</h6>
                            </li>
                          </ul>
                        </div>
                        <div className="d-flex justify-content-end">
                          <button
                            class="cta"
                            onClick={() =>
                              navigate(
                                // `/fulltab/${listData.propertyData.type}/${listData.propertyData._id}`,
                                `/fulltab/${"Residential"}/${listData?._id}`,
                                {
                                  state: {
                                    yourData: listData,
                                    propertyType: "Residential",
                                  },
                                }
                              )
                            }
                          >
                            <span class="hover-underline-animation"> View More </span>
                            <svg
                              id="arrow-horizontal"
                              xmlns="http://www.w3.org/2000/svg"
                              width="30"
                              height="10"
                              viewBox="0 0 46 16"
                            >
                              <path
                                id="Path_10"
                                data-name="Path 10"
                                d="M8,0,6.545,1.455l5.506,5.506H-30V9.039H12.052L6.545,14.545,8,16l8-8Z"
                                transform="translate(30)"
                              ></path>
                            </svg>
                          </button>
                          {/* <ArrowForwardIosTwoToneIcon /> */}
                        </div>
                      </div>

                      <div className="p-pri">
                        <div>
                          <h4>₹{formatCash(listData?.purposeOfProperty === "Buy" ? listData?.propertyCost : listData?.PropertyRent)}/-</h4>
                          <p>{listData?.purposeOfProperty}</p>
                        </div>

                        <div>
                          <h4>₹{formatCash(listData?.purposeOfProperty === "Buy" ? listData?.bookingAmount : listData?.SecurityDeposit)}/-</h4>
                          <p>{listData?.purposeOfProperty === "Buy" ? "Booking Price" : "Security Deposit"}</p>
                        </div>

                        <div>
                          {/* <IconButton>
                          <PreviewIcon
                            onClick={() => {
                              handleOpenImage(data);
                            }}
                          />
                        </IconButton> */}
                          <IconButton>
                            <VisibilityIcon
                              onClick={() => handlePreview(ind)}
                            />
                          </IconButton>
                          <IconButton
                            onClick={() =>
                              navigate("/newRent", {
                                state: listData,
                              })
                            }
                          >
                            <ModeEditIcon color="success" />
                          </IconButton>
                          <IconButton
                            onClick={() => DeleteRentProperty(listData._id)}
                          >
                            <DeleteIcon color="error" />
                          </IconButton>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )

            })

            }
          </Paper>
        </div>

        <Modal
          className="topNav-m modal-lg "
          show={residentialPreview}
          onHide={() => handleClose()}
          disableBackdropClick={false}
          disableEscapeKeyDown={false}
        >
          <Modal.Header closeButton className="headerBlue">
            <Modal.Title
              className=" text-center loginTitle"
              style={{ margin: "0 auto" }}
            >
              <h5>Residential Details</h5>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="pl-5 pr-5  pb-4 LoginContainer LoginModal">
              {selectedItem && selectedItem ? (
                <>
                  <div>
                    <img
                      src={`${settings.IMAGE_URL}/${selectedItem.propertyImg1}`}
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  {/* <div className="transactionData">
                  <div className="transactionDataTitle">Complex Name : </div>
                  <div className="transactionDataValue">
                    {selectedItem.ComplexName}
                  </div>
                </div> */}
                  <div className="transactionData">
                    <div className="transactionDataTitle">Carpet Area : </div>
                    <div className="transactionDataValue">
                      {selectedItem.areainSqft ? selectedItem.areainSqft : "-"}
                    </div>
                  </div>
                  <div className="transactionData">
                    <div className="transactionDataTitle">
                      Furnishing Staus :{" "}
                    </div>
                    <div className="transactionDataValue">
                      {selectedItem.furnishedStatus ? selectedItem.furnishedStatus : "-"}
                    </div>
                  </div>
                  {/* <div className="transactionData odd">
                  <div className="transactionDataTitle">No of cabins : </div>
                  <div className="transactionDataValue">
                    {selectedItem.NosOfCabins}
                  </div>
                </div> */}
                  <div className="transactionData">
                    <div className="transactionDataTitle">No of Bedrooms : </div>
                    <div className="transactionDataValue">
                      {selectedItem.selectNosofBedrooms ? selectedItem.selectNosofBedrooms : "-"}
                    </div>
                  </div>
                  <div className="transactionData">
                    <div className="transactionDataTitle">No of Balconies : </div>
                    <div className="transactionDataValue">
                      {selectedItem.selectNosofBalconies ? selectedItem.selectNosofBalconies : "-"}
                    </div>
                  </div>
                  <div className="transactionData">
                    <div className="transactionDataTitle">Washroom : </div>
                    <div className="transactionDataValue">
                      {selectedItem.selectNosofBathrooms ? selectedItem.selectNosofBathrooms : "-"}
                    </div>
                  </div>
                  {/* <div className="transactionData">
                  <div className="transactionDataTitle">Overlooking : </div>
                  <div className="transactionDataValue">{"-"}</div>
                </div> */}

                  <div className="transactionData">
                    <div className="transactionDataTitle">Select floor : </div>
                    <div className="transactionDataValue">
                      {selectedItem.selectFloor ? selectedItem.selectFloor : "-"}
                    </div>
                  </div>
                  <div className="transactionData">
                    <div className="transactionDataTitle">Total floor : </div>
                    <div className="transactionDataValue">
                      {selectedItem.totalFloor ? selectedItem.totalFloor : "-"}
                    </div>
                  </div>
                  <div className="transactionData">
                    <div className="transactionDataTitle">Purpose of Property : </div>
                    <div className="transactionDataValue">
                      {selectedItem.purposeOfProperty ? selectedItem.purposeOfProperty : "-"
                      }
                    </div>
                  </div>
                  {selectedItem.PropertyRent ?
                    <>
                      <div className="transactionData">
                        <div className="transactionDataTitle">Property Rent : </div>
                        <div className="transactionDataValue">
                          {selectedItem.PropertyRent ? selectedItem.PropertyRent : "-"}
                        </div>
                      </div>
                      <div className="transactionData">
                        <div className="transactionDataTitle">
                          Security Deposit :{" "}
                        </div>
                        <div className="transactionDataValue">
                          {selectedItem.SecurityDeposit ? selectedItem.SecurityDeposit : "-"}
                        </div>
                      </div>
                    </>
                    :
                    <>
                      <div className="transactionData">
                        <div className="transactionDataTitle">Property Cost : </div>
                        <div className="transactionDataValue">
                          {selectedItem.propertyCost ? selectedItem.propertyCost : "-"}
                        </div>
                      </div>
                      <div className="transactionData">
                        <div className="transactionDataTitle">
                          Booking Amount :{" "}
                        </div>
                        <div className="transactionDataValue">
                          {selectedItem.bookingAmount ? selectedItem.bookingAmount : "-"}
                        </div>
                      </div>
                    </>
                  }
                  {/* <div className="transactionData">
                  <div className="transactionDataTitle">LEED : </div>
                  <div className="transactionDataValue">{"-"}</div>
                </div>
                <div className="transactionData">
                  <div className="transactionDataTitle">lifts : </div>
                  <div className="transactionDataValue">
                    {selectedItem.NosLifts}
                  </div>
                </div> */}
                  <div className="transactionData">
                    <div className="transactionDataTitle">Notice period : </div>
                    <div className="transactionDataValue">
                      {selectedItem.noticePeriod}
                    </div>
                  </div>

                  <div className="transactionData">
                    <div className="transactionDataTitle">Location : </div>
                    <div className="transactionDataValue">
                      {selectedItem.Location ? selectedItem.Location : "-"}
                    </div>
                  </div>
                  <div className="transactionData">
                    <div className="transactionDataTitle">Sub Location : </div>
                    <div className="transactionDataValue">
                      {selectedItem.subLocation ? selectedItem.subLocation : "-"}
                    </div>
                  </div>
                  <div className="transactionData">
                    <div className="transactionDataTitle"> CCTV installed : </div>
                    <div className="transactionDataValue">
                      {selectedItem.isCCTVinstalled !== undefined ? selectedItem.isCCTVinstalled.toString() : '-'}
                    </div>
                  </div>
                  <div className="transactionData">
                    <div className="transactionDataTitle">Property Direction : </div>
                    <div className="transactionDataValue">
                      {selectedItem.propertyDirection ? selectedItem.propertyDirection : "-"}
                    </div>
                  </div>
                  <div className="transactionData">
                    <div className="transactionDataTitle">Available Year: </div>
                    <div className="transactionDataValue">
                      {selectedItem.AvailableYear ? selectedItem.AvailableYear : "-"}
                    </div>
                  </div>
                  <div className="transactionData">
                    <div className="transactionDataTitle">Available Month : </div>
                    <div className="transactionDataValue">
                      {selectedItem.AvailableMonth ? selectedItem.AvailableMonth : "-"}
                    </div>
                  </div>
                  <div className="transactionData">
                    <div className="transactionDataTitle">status Property : </div>
                    <div className="transactionDataValue">
                      {selectedItem.statusProperty ? selectedItem.statusProperty : "-"}
                    </div>
                  </div>


                  {/* <div className="transactionData">
                  <div className="transactionDataTitle">
                    water availibility :{" "}
                  </div>
                  <div className="transactionDataValue">
                    {selectedItem.Availibilty}
                  </div>
                </div>
                <div className="transactionData">
                  <div className="transactionDataTitle">Electricity : </div>
                  <div className="transactionDataValue">{"-"}</div>
                </div>
                <div className="transactionData">
                  <div className="transactionDataTitle">Ideal for : </div>
                  <div className="transactionDataValue">
                    {selectedItem.idealFor}
                  </div>
                </div>
                <div className="transactionData">
                  <div className="transactionDataTitle">Loading : </div>
                  <div className="transactionDataValue">
                    {selectedItem.Loading}
                  </div>
                </div> */}
                </>
              ) : null}
            </div>
          </Modal.Body>
        </Modal>

        {/* image preview */}
        <Modal
          className="topNav-m"
          show={imageView}
          onHide={() => handleClose()}
          disableBackdropClick={false}
          disableEscapeKeyDown={false}
          centered
        >
          <Modal.Header closeButton className="headerBlue">
            <Modal.Title
              className=" text-center loginTitle"
              style={{ margin: "0 auto" }}
            >
              <h5 className="text-center">Property Images</h5>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="pl-5 pr-5  pb-4 LoginContainer LoginModal">
              <ImageGallery items={imagesData} />
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default RentDetails;


{/* <Paper
sx={{ width: "70%", overflow: "hidden", justifyContent: "center" }}
>
<div className="text-center p-2 mt-2">
  <h5>Residential Properties</h5>
</div>

<div className="search-header p-2">
  <FormControl variant="outlined">
    <TextField
      id="outlined-search"
      className="blackClear"
      label="Search..."
      type="search"
      variant="outlined"
      autoComplete="off"
      value={searchQuery}
      onChange={(e) => setSearchQuery(e.target.value)}
    ></TextField>
  </FormControl>
</div>
<TableContainer sx={{ maxHeight: 440 }}>
  <Table stickyHeader aria-label="sticky table">
    <TableHead>
      <TableRow>
        {columns.map((column) => (
          <>
            <TableCell
              key={column.id}
              align={column.align}
              style={{ minWidth: column.minWidth }}
            >
              {column.label}
            </TableCell>
          </>
        ))}
        <TableCell align="center">Image</TableCell>
        <TableCell align="center">Action</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {selectedItem === "" ? (
        data &&
        data
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((data1, index) => {
            return (
              <TableRow key={index}>
                <TableCell align="center">{index + 1}</TableCell>
                <TableCell>
                  {data1.propertyType ? data1.propertyType : "-"}
                </TableCell>
                <TableCell align="center">
                  {data1.PropertyStatus ? data1.PropertyStatus : "-"}
                </TableCell>
               
                <TableCell align="center">
                  <span
                    style={{
                      color:
                        data1.isVerified === "Verified"
                          ? "green"
                          : data1.isVerified === "pending"
                            ? "orange"
                            : "red",
                      fontWeight: "bold",
                    }}
                  >
                    {data1.isVerified ? data1.isVerified : "pending"}
                  </span>
                </TableCell>
                <TableCell align="center">
                  <IconButton>
                    <PreviewIcon
                      onClick={() => {
                        handleOpenImage(data1);
                      }}
                    />
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  <IconButton>
                    <VisibilityIcon
                      onClick={() => handlePreview(index)}
                    />
                  </IconButton>
                  <IconButton
                    onClick={() =>
                      navigate("/newRent", {
                        state: data1,
                      })
                    }
                  >
                    <ModeEditIcon color="success" />
                  </IconButton>
                  <IconButton
                    onClick={() => DeleteRentProperty(data1._id)}
                  >
                    <DeleteIcon color="error" />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })
      ) : filteredData.length === 0 ? (
        <p>Data not display</p>
      ) : (
        filteredData
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((data1, index) => {
            const serialNumber = (pageNumber - 1) * limit + index + 1;
            return (
              <TableRow key={index}>
                <TableCell align="center">{index + 1}</TableCell>

                <TableCell align="center">
                  {data1.propertyType ? data1.propertyType : "-"}
                </TableCell>
                <TableCell align="center">
                  {data1.PropertyStatus ? data1.PropertyStatus : "-"}
                </TableCell>
               
                <TableCell align="center">
                  <span
                    style={{
                      color:
                        data1.isVerified === "Verified"
                          ? "green"
                          : data1.isVerified === "pending"
                            ? "orange"
                            : "red",
                      fontWeight: "bold",
                    }}
                  >
                    {data1.isVerified ? data1.isVerified : "pending"}
                  </span>
                </TableCell>
                <TableCell align="center">
                  <IconButton>
                    <PreviewIcon
                      onClick={() => {
                        handleOpenImage(data1);
                      }}
                    />
                  </IconButton>
                </TableCell>
                <TableCell align="center">
                  <IconButton>
                    <VisibilityIcon
                      onClick={() => handlePreview(index)}
                    />
                  </IconButton>
                  <IconButton
                    onClick={() =>
                      navigate("/newRent", {
                        state: data1,
                      })
                    }
                  >
                    <ModeEditIcon color="success" />
                  </IconButton>
                  <IconButton
                    onClick={() => DeleteRentProperty(data1._id)}
                  >
                    <DeleteIcon color="error" />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })
      )}
    </TableBody>
  </Table>
</TableContainer>

<TablePagination
  rowsPerPageOptions={[5, 10, 15, 50]}
  component="div"
  count={data.length}
  rowsPerPage={rowsPerPage}
  page={page}
  onPageChange={handleChangePage}
  onRowsPerPageChange={handleChangeRowsPerPage}
/>
</Paper> */}
